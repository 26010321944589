<template>
  <div>{{ ActDescBtn }}
    <table style="position: absolute;" width="100%" :height="this.windowSize.height-8"
      :max-height="this.windowSize.height-8">
      <tr max-height="48px" height="48px">
        <ContenidoBase :titulo="'Registro de Gastos Menores'" :descripcion="'Registre los Gastos Menores'"
          :EstadoBtn="EstadoBtn" @abrirBarraTablaDinamica="abrirTabla" @MethodNuevo="newwnuevo" @MethodGuardar="save"
          @MethodEliminar="NotifMessage" @MethodAnular="NotifMessage" @MethodImprimir="imprimirList"
          @MethodVeercuenta="vercuenta" />
      </tr>

      <tr>
        <!-- BarraPrimaria --------------------------------------------------------------------------------->
        <td width="109" :max-height="this.windowSize.height-96" v-show="BarraTablaDinamicaAprir">

          <div class="overflow-y-auto "
            v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px'  }">

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <center>

                  <v-btn-toggle>
                    <v-btn small color="yellow accent-4" @click.prevent="actualiza_tb_ver"><i style="font-size: 25px;"
                        class="fa fa-eye"> </i>
                      ver
                    </v-btn>

                    <v-btn color="teal darken-2" dark text small
                      @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                      <i style="font-size: 25px;" class="fa fa-search"> </i>
                      Filtrar
                    </v-btn>


                  </v-btn-toggle>


                </center>


                <BarraDinamicaLimitePlus :PropsNombre="'Gastos Menores'" PropsSearch
                  :PropsSeachNombre="'Búsqueda por secuencia'" :TbPaginator="TbPaginator" :TbTotalItem="TbTotalItem"
                  :PropsList="ListItems" @CargarPaginacion="CargarPaginacion" @EmitDAta="datostabla" :headers="[
                  { text: 'Código', value: 'gastosmenoresPK.secuencia' },
                 /* { text: 'Nombre', value: 'nombre' },
                  { text: 'Balance', value: 'balancecliente.balance' }*/
                  ]" :ContListSlot="[{Text:'Secuencia',NomValue:'gastosmenoresPK',Nv2:'secuencia', Ctsl:2, Type:0, State:true},
                  {Text:'Documento',NomValue:'documentocontable', Nv2:'descripcion', Ctsl:2, Type:0, State:true},
                  {Text:'concepto',NomValue:'concepto', Ctsl:1, Type:0, State:true},
                  {Text:'Fecha',NomValue:'nuevaFecha', Ctsl:1, Type:1, State:true},
                  {Text:'Activo',NomValue:'activa', Ctsl:1, Type:0.1, State:true},
                  ]" />

              </template>
            </v-tooltip>


          </div>
        </td>
        <!-- fn BarraPrimaria ---------------------------------------------------------------------------------->
        <!-- contenido central ------------------------------------------------------------------------------ -->
        <td :max-height="this.windowSize.height-96">
          <v-app id="inspire" class="overflow-y-auto " v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' }">


            <div class="px-2">
              <v-card color="grey lighten-3">
                <div class="px-2">


                  <v-form ref="formGastoMenores" v-model="valid" lazy-validation>
                    <v-row>

                      <!-- columna 1------------------------------------------------- -->
                      <v-col cols="12" md="6" sm="6">

                        <!------------------------------------------ Documentos -->
                        <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
                          item-text="descripcion" :rules="[$rules.required]" v-model="GastoMenores.documentocontable"
                          @blur="SelctCbDocumento" @focus="cargarDocumento" @change="getCrearCont">
                          <template v-slot:prepend>
                            <i style="font-size: 30px;" class="fa fa-book"></i>
                          </template>
                        </v-combobox>


                        <!-- -----------------------------  fecha-->
                        <v-text-field dense outlined type="date" label="Fecha:" v-model="GastoMenores.fecha">

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-calendar"></i>
                          </template>

                        </v-text-field>

                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

<v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
:rules="[$rules.required]" v-model="GastoMenores.moneda" style="font-size:13px;" @focus="cargarMoneda" @blur="SelctCbMoneda"
@change="ChangeMonedaTasa">

<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-money"></i>
</template>

<template v-slot:selection="{ attrs, item, parent, selected }">
<span id="ImputColorDisable">
{{ item.descripcion }} ({{ item.nombrecorto }})
</span>

</template>


<template slot="item" slot-scope="item">
<!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
<v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
<p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
</p>
</v-col>
</template>
</v-combobox>
</div>


                      </v-col>
                      <!-- fn columna 1-------------------------------------------------- -->

                      <!-- columna 2---------------------------------------->
                      <v-col cols="12" md="6" sm="6">


                        <!-------------------------------------------------- Concepto -->
                        <v-textarea autocomplete="off" label="Concepto" rows="4" dense outlined
                          v-model="GastoMenores.concepto">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>

                        <div v-if="this.$store.getters.GetConfiguracionGeneral.config.multimoneda==true">

<VueAutonumeric v-if="GastoMenores.moneda != null" id="formdisabledInput" label="Tasa:" dense outlined
v-model.number="GastoMenores.tasa">
<template v-slot:prepend>
<i style="font-size: 20px;" class="fa fa-usd"></i>
</template>
</VueAutonumeric>



</div>


                      </v-col>
                      <!-- fn columna 2  ------------------------------------>

                    </v-row>

                  </v-form>
                </div>
              </v-card>

              <!--------------------------------------------------- tb_tabla -->
              <v-col cols="12" md="12" sm="12">
                <v-card>

                  <v-toolbar flat color="#BDBDBD" dense>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">

                        <v-btn v-on="on" @click.prevent="addItemGasto" color="green" fab x-small dark>
                          <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                        </v-btn>

                      </template>
                      <span>Agregar Item a la Tabla gasto</span>
                    </v-tooltip>


                    <v-toolbar-title class="font-weight-light">
                      <v-card-title>GASTOS</v-card-title>
                    </v-toolbar-title>


                    <!-- addFact -->

                  </v-toolbar>
                  <v-form v-model="valid" ref="formTabGasto" lazy-validation> 
                  <v-data-table class="elevation-1" dense
                    :headers="[{ text: 'Fecha', value: 'fecha', class: ['no-gutters black--text'] ,width:'auto',sortable:false, class: ['no-gutters black--text'] }, { text: 'Categoría de gastos', value: 'categoria',width:'auto',sortable:false, class: ['no-gutters black--text'] },
                    { text: 'CONCEPTO', value: 'concepto',width:'auto',sortable:false, class: ['no-gutters black--text'] },{ text: 'VALOR', value: 'valor',width:'auto',  align: 'center',sortable:false, class: ['no-gutters black--text'] },{ text: 'ACCIÓN', value: 'action',width:'auto',sortable:false, class: ['no-gutters black--text'] }]"
                    :items="ListGast" :items-per-page="-1">
                    <template v-slot:item.action="{ item }">
                      <!-- <v-btn fab @click.prevent="editFact(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                      <v-btn fab @click.prevent="delGasto(item)" x-small color="red darken-3"><i
                          style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                    </template>


                    <template v-slot:item.fecha="props">

                      <v-text-field dense outlined type="date" label="* Fecha:" v-model="props.item.fecha2" hide-details>
                      </v-text-field>

                      <span style="display:none;">{{ props.item.fecha=props.item.fecha2+"T04:00"}}</span>
                    </template>


                    <template v-slot:item.concepto="props">

                      <v-textarea autocomplete="off" label="" rows="2" dense outlined v-model="props.item.concepto"
                        hide-details></v-textarea>

                    </template>


                    <template v-slot:item.categoria="props">
                      <v-combobox v-model="props.item.categoria" label="" required outlined dense
                        :rules="[$rules.required]" autocomplete="off" :items="RTipoGasto" item-text="descripcion"
                        @focus="CargarRTipopago" hide-details>
                      </v-combobox>
                      <!-- <span>{{currencyFormatter(props.item.valor)}}</span> -->
                    </template>


                    <template v-slot:item.valor="props">

                      <VueAutonumeric :rules="[$rules.required]" autocomplete="off" label="" dense outlined v-model="props.item.valor"
                        @blur="cargarDetalle" hide-details>
                      </VueAutonumeric>

                      <!-- <span>{{currencyFormatter(props.item.valor)}}</span> -->


                    </template>


                    <template v-slot:body.append v-if="windowSize.width >600">
      <tr>
        <td class="border-top-bottom"><strong></strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->
        <td class="border-top-bottom"><strong class="d-flex justify-end"
            style="font-size:15px; color: #00838F;">Total:</strong></td>
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->
        <td class="border-total"><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;">
            {{ currencyFormatter(total) }} </strong></td>
        <td class="border-top-bottom"><strong></strong></td>
        <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.TotalProd.importe)}} </strong></td> -->
        <!-- <td class="border-total" ><strong class="d-flex justify-center" style="font-size:15px; color: #00838F;"> {{currencyFormatter(form.Total.Total)}} </strong></td> -->
        <!-- <td class="border-top-bottom"  ><strong></strong></td> -->
      </tr>
</template>

<template v-slot:footer v-if="windowSize.width<=600">
  <v-list-item style="font-size: 70%;" class="white--text">
    <v-list-item-content>
      <span>TOTAL: {{currencyFormatter(total)  }}</span>
    </v-list-item-content>
  </v-list-item>

</template>
</v-data-table>
</v-form>

<v-data-table class="elevation-1" dense :headers="[{ text: 'NCF', value: 'ncf', class: ['no-gutters black--text'] ,width:'auto',sortable:false, class: ['no-gutters black--text'] }, { text: 'Categoría de gastos', value: 'categoriagastos.descripcion',width:'auto',sortable:false, class: ['no-gutters black--text'] },
{ text: 'VALOR', value: 'valor',width:'auto',  align: 'center',sortable:false, class: ['no-gutters black--text'] }]"
  :items="ListGastMen" :items-per-page="-1">

</v-data-table>


</v-card>
</v-col>
<!--------------------------------------------------- fn tb_tabla  -->


</div>

</v-app>
</td>
<!-- fn contenido central ------------------------------------------------------------------------------------->
<!-- BarraSecundaria ------------------------------------------------------------------------------------------>
<td width="109" :max-height="this.windowSize.height-96" v-show="BarraSecundariaAprir">

  <div class="overflow-y-auto "
    v-bind:style="{  'max-height':this.windowSize.height-96+ 'px' , 'max-width': '325px', 'width': '325px' }">

    <!--
          <BarraBusqueda2
                :url="$hostname + $hName + '/services/caja/findAll'"
                :nombre="'Cajas'"
                :headers="headers"
                :slotname="'item.descripcion'"
                :emitnombre="'emitCaja'"
                :slotlist="slotlist"
                :SeachNombre="'Búsqueda por descripción caja'"
              /> -->
  </div>

</td>
<!-- fn BarraSecundaria --------------------------------------------------------------------------------------->


</tr>
</table>
<!-- Modales -------------------------------------------------------------------------------------------------->
<!-------------------------------------------------------- tb-data-->
<v-dialog label="myAlert" v-model="acti_tb_data" persistent transition="dialog-bottom-transition" fullscreen
  hide-overlay>
  <v-card>
    <v-toolbar dense flat color="#BDBDBD">
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>
      <v-toolbar-title class="font-weight-light">
        REPORTE
      </v-toolbar-title>
      <i style="font-size: 20px;" class="fa fa-file-pdf-o"> </i>

      <v-spacer></v-spacer>
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" dark small color="deep-purple accent-2" @click.prevent="dialogimprimirListFilt=true">
            <i style="font-size: 25px;" class="fa fa-print"> </i>
            Listado
          </v-btn>

        </template>
        <span>Imprimir Listado</span>
      </v-tooltip>
      <v-btn color="grey darken-4" fab small @click="actualiza_tb_ver">
        <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <v-card>
        <v-card-title>
          <v-text-field v-model="Modallfiltro.codigo" @keyup.enter="CargarListTb" label="Búsqueda por secuencia"
            single-line hide-details type="number">
            <template v-slot:prepend-inner>
              <i style="font-size: 35px;" class="fa fa-search"></i>
            </template>
            <template v-slot:append-outer>
              <v-btn color="teal darken-2" dark small
                @click.prevent="dialogFiltros = true, ObjTemTbDinam=JSON.parse(JSON.stringify(Modallfiltro))">
                <i style="font-size: 25px;" class="fa fa-search"> </i>
                Filtros
              </v-btn>
            </template>


          </v-text-field>
        </v-card-title>

        <v-data-table class="TbSize13" :headers="[
                { text: 'Secuencia', value: 'gastosmenoresPK.secuencia',sortable:false, class: ['no-gutters black--text']  },
                { text: 'Documento', value: 'documentocontable.descripcion', sortable:false, class: ['no-gutters black--text']  },
                { text: 'concepto', value: 'concepto', sortable:false, class: ['no-gutters black--text'] },
                { text: 'Fecha', value: 'nuevaFecha', sortable:false, class: ['no-gutters black--text'] },
        ] " dense @pagination="cargarPaginacionAll" :items-per-page="TbPaginator.itemsPerPage" :page="TbPaginator.page"
          :pageCount="TbPaginator.pageCount" :items="ListItems" :search="Modallfiltro.codigo" @click:row="datostabla"
          :server-items-length="TbTotalItem" :footer-props="arrayPropiedases">


          <template v-slot:item.nuevaFecha="props">
            <span>{{ FormatoFecha(props.item.nuevaFecha) }}</span>
          </template>

        </v-data-table>
      </v-card>
    </v-card-text>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------ fn tb-data-->


<!-- ------------------------------------------------------ notif-->
<v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
  <template justify="center" v-slot:activator="{ on }"></template>

  <v-card>
    <v-card-title>
      <h4>
        <i style="font-size: 28px;" class="fa fa-warning"></i>
        CONFIRMACION
        <i style="font-size: 28px;" class="fa fa-warning"></i>
      </h4>
    </v-card-title>
    <hr />

    <v-card-text>
      <h5>{{ notif.nombre }}</h5>
    </v-card-text>

    <hr />
    <v-card-actions>
      <v-btn v-if="notif.Exec='AnularGasto'" @click.prevent="anular(), (notif.estado = !notif.estado)" small>
        <i style="font-size: 20px;" class="fa fa-check"></i> Si
      </v-btn>
      <v-btn @click.prevent="notif.estado = !notif.estado" small>
        <i style="font-size: 20px;" class="fa fa-ban"></i> No
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
<!-- ------------------------------------------------------  fn notif -->

<!------------otras funciones---------------->
<v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true" :timeout="120000"
  top="top">
  {{ aalert.nombre }}
  <v-btn dark text @click="aalert.estado = false">Close</v-btn>
</v-snackbar>


<!-- ------------------------------------------------------ Modal- agregar cuenta-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="acti_Modal_cuenta" persistent
  max-width="80%" max-height="50%">

  <CuentasContables @CuentasContables="CCuentasContables" :RLisCuenta="RLisCuenta" :objeTem="objeTem"
    :Total="TotalCuenta" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal agregar cuenta-->

<!-- ------------------------------------------------------ Modal Filtro -->
<v-dialog transition="fab-transition" label="Modal agregar factura" v-model="dialogFiltros" persistent max-width="90%"
  max-height="80%">
  <v-card>

    <v-toolbar flat color="indigo" dense>

      <i style="font-size: 20px;" class=""> </i>
      <v-toolbar-title style="color:white;" class="font-weight-light"><b>FILTROS</b></v-toolbar-title>
      <i style="font-size: 20px;" class=""> </i>

      <v-spacer></v-spacer>
      <v-btn color="grey darken-4" fab small
        @click="dialogFiltros=false, Modallfiltro=JSON.parse(JSON.stringify(ObjTemTbDinam))">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <v-card-text>
      <br>

      <v-row>


        <!-- -----------------------------  columna-1 -->
        <v-col cols="12" md="6" sm="6">

          <!------------------------------------------ Documentos -->
          <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento" item-text="descripcion"
            :rules="[$rules.required]" v-model="Modallfiltro.documento" @blur="SelctCbFiltroDocumento"
            @focus="cargarDocumento">
            <template v-slot:prepend>
              <i style="font-size: 30px;" class="fa fa-book"></i>
            </template>
          </v-combobox>

          <!----------------------------------------------  Desde -->
          <v-text-field dense outlined type="date" label="Desde*" v-model="Modallfiltro.Desde">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>
          </v-text-field>


        </v-col>
        <!-- ----------------------------- fn columna-1 -->

        <!-- -----------------------------  columna-2 -->
        <v-col cols="12" md="6" sm="6">

          <v-text-field label="Secuencia" autocomplete="off" dense outlined v-model="Modallfiltro.codigo">
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-barcode"></i>
            </template>
          </v-text-field>
          <!-------------------------------------------------- Hasta -->
          <v-text-field dense outlined type="date" label="Hasta*" v-model="Modallfiltro.Hasta">

            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-calendar"></i>
            </template>

          </v-text-field>


        </v-col>
        <!-- ----------------------------- fn columna-2 -->

      </v-row>


    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn-toggle rounded class="" dense>


        <v-btn dark color="#90A4AE" @click.prevent="Limpiarfiltro">
          <i style="font-size: 28px;" class="fa fa-file-o"> </i>
          Limpiar filtro
        </v-btn>

        <v-btn dark color="blue lighten-1" @click.prevent="BuscarFiltros">
          <i style="font-size: 28px;" class="fa fa-search"> </i>
          Buscar
        </v-btn>

      </v-btn-toggle>

    </v-card-actions>

  </v-card>
</v-dialog>
<!-------------------------------------------------------- fn Modal filtro -->

<!-- ------------------------------------------------------ Modal- Reporte Basico-->
<v-dialog transition="fab-transition" label="Modal Reporte Basico" v-model="dialogimprimirList" persistent
  max-width="80%" max-height="50%">


  <ModalReporteBasico @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecion" />

</v-dialog>
<!-- ------------------------------------------------------ fn Modal Reporte Basico-->


<!-- ------------------------------------------------------ Modal- Reportes-->
<v-dialog transition="fab-transition" label="Modal agregar cuenta" v-model="dialogimprimirListFilt" persistent
  max-width="80%" max-height="50%">
  <!----------------------------------------------------------------- Cuerpo Reporte -->
  <v-card color="grey lighten-3">

    <v-toolbar flat color="#BDBDBD" dense style="border: 1px solid #000000;">

      <v-avatar tile size="45">
        <img src="@/assets/iconos_micromarket/custom-reports.png" alt="Reporte">
      </v-avatar>
      <span class="font-weight-light" style="font-size: 20px;"> <b> LISTA REGISTRO DE GASTOS MENORES </b> </span>

      <v-spacer></v-spacer>
      <v-btn color="red" fab small @click.prevent="dialogimprimirListFilt=false">
        <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
      </v-btn>
    </v-toolbar>

    <hr>

    <v-container style="border: 1px solid #000000;">
      <v-form ref="form" @submit.prevent="" v-model="valid" lazy-validation>
        <v-row class="pa-0 ma-0">

          <v-col cols="12" md="12" sm="12">
            <!---------------------------------------------- documento -->
            <v-combobox autocomplete="off" dense outlined label="Documentos:" :items="RDocumento"
              item-text="descripcion" :rules="[$rules.required]" v-model="datosImprecionAll.documento"
              @blur="SelctCbReportDocumento" @focus="cargarDocumento">
              <template v-slot:prepend>
                <i style="font-size: 20px" class="fa fa-book"></i>
              </template>
            </v-combobox>

          </v-col>

          <v-col cols="12" md="6" sm="6">


            <v-text-field dense outlined type="date" label="Desde*" v-model="datosImprecionAll.desde"
              :rules="[$rules.required]" @blur="BlurDesde">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>
            </v-text-field>


          </v-col>

          <v-col cols="12" md="6" sm="6">
            <!-------------------------------------------------- Hasta -->
            <v-text-field dense outlined type="date" label="Hasta*" v-model="datosImprecionAll.hasta"
              :rules="[$rules.required]" @blur="BlurHasta">

              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-calendar"></i>
              </template>

            </v-text-field>


          </v-col>

        </v-row>
      </v-form>
    </v-container>

    <hr>

    <ModalReporteFiltros @DataReporteBasico="DDataReporteBasico" :Datos="datosImprecionAll" />

  </v-card>
  <!-------------------------------------------------------------------------------- Fn Cuerpo Reporte -->
</v-dialog>
<!-- ------------------------------------------------------ fn Modal reportes -->


<!-- fn Modales ----------------------------------------------------------------------------------------------->


</div>
</template>

<script>
import { EventBus } from "../../event-bus";
import BarraBusqueda2 from "../../components/BarraBusquedaDinam";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import ContenidoBase from "../../components/ContenidoBase";
import CuentasContables from "../../components/CuentasContables";
import BarraDinamicaLimite from "../../components/BarraDinamicaLimite";
import ModalReporteBasico from "../../components/ModalReporteBasico";
import ModalReporteFiltros from "../../components/ModalReporteFiltros";
import BarraDinamicaLimitePlus from "../../components/BarraDinamicaLimitePlus";
import { currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva } from "@/js/Funciones.js";

export default {
  components: {
    BarraBusqueda2,
    VueAutonumeric,
    ContenidoBase,
    CuentasContables,
    BarraDinamicaLimite,
    ModalReporteBasico,
    ModalReporteFiltros,
    BarraDinamicaLimitePlus
  },
  created() {
    var json = {
      titulo: "Registro de Gastos Menores",
      descripcion: "Registre los Gastos Menores",
      save: true,
      eliminar: true,
      anular: false,
      nuevo: true,
      imprimir: true,
      accion: [
        "GASGastosMenoresNew",
        "GASGastosMenoresSave",
        "GASGastosMenoresRemove",
        "GASGastosMenoresAnular",
        "GASGastosMenoresPrintList"
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
  },
  mounted() {


    EventBus.$on("abrirMenuizquierdo", this.abrirTabla);
    // EventBus.$on("abrirMenuizquierdo", this.abrirTabla2);
    // EventBus.$on("GASGastosMenoresNew", this.new);
    // EventBus.$on("GASGastosMenoresSave", this.save);
    // EventBus.$on("GASGastosMenoresRemove", this.remove);
    // EventBus.$on("GASGastosMenoresAnular", this.remove);
    // EventBus.$on("GASGastosMenoresPrintList", this.imprimirList);
    EventBus.$on("emitGASGastosMenores", this.datostabla);
    this.cargarTodo();
    this.getMonedaBase()
    EventBus.$on("onResize", this.onResize);
  },

  data() {
    return {

      noComprobante: false,
      ejecucion: true,
      dialogimprimirListFilt: false,

      datosImprecionAll: {
        nombre: 'LISTA REGISTRO DE GASTOS MENORES',
        UrlImprimir: '/gastosmenores/ImprimirGastMenoresAllList',
        UrlExel: '/gastosmenores/ExcelGastMenoresAllList',
        UrlCorreo: '/gastosmenores/CorreoGastMenoresAllList',

        desde: fechaNueva(),
        hasta: fechaNueva(),
        documento: null
      },


      dialogimprimirList: false,
      datosImprecion: {
        nombre: 'REGISTRO DE GASTOS MENORES',
        UrlImprimir: '/gastosmenores/ImprimirGastMenores',
        UrlExel: '/gastosmenores/ExcelGastMenores',
        UrlCorreo: '/gastosmenores/CorreoGastMenores',
        secuencia: 0,
        documento: 0,
        total: 0
      },


      ListaTiposClientes: [],
      ListGastMen: [],
      ListGast: [],
      Rmoneda:[],
      RTipoGasto: [],

      RDocumento: [],

      windowSize: { width: "auto", height: "auto" },
      GastoMenores: {
        gastosmenoresPK: { secuencia: 0, documento: 0 },
        documentocontable: null,
        fecha: fechaNueva(),
        codigo: 0,
        concepto: "",
        activa: true,
        borrado: false,
        gastoMenoresNcfList: [],
        gastosmenoresDetalleList: [],
        contabilidad: null,
        moneda:null,
        tasa:0
      },
      contabilidad: null,
      total: 0,
      objeTem: null,

      acti_Modal_cuenta: false,
      RLisCuenta: [],


      form: {

        RLisCuenta: [],
        TotalCuenta: {
          credito: 0,
          debito: 0

        },
      },


      TotalCuenta: {
        credito: 0,
        debito: 0

      },


      dialogFiltros: false,
      Modallfiltro: {
        documento: null,
        codigo: null,
        Desde: null,
        Hasta: null,
      },


      ObjTemTbDinam: null,
      ListItems: [],
      TbTotalItem: 0,
      TbPaginator: {
        page: 1,
        itemsPerPage: 5,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5
      },
      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },

      EstadoBtn: {
        BarraTablaDinamicaAprir: true,
        nuevo: true,
        guardar: true,
        eliminar: false,
        anular: false,
        imprimir: true,
        vercuenta: false,
        guardartemporal: false,
        restaurartemporal: false,
        importarDato: false,
        cargarProducto: false,
        ImprimirproductoInv: false,
        etiquetaXfactura: false
      },


      text: "",
      valid: true,
      BarraTablaDinamicaAprir: false,
      BarraSecundariaAprir: false,

      headers: [{ text: "Tipo Clientes", value: "descripcion" }],

      slotlist: [
        { dt0: "codigo", dt1: null, dt2: null, dt3: 1, dt4: "Código:" },
        {
          dt0: "descripcion",
          dt1: null,
          dt2: null,
          dt3: 1,
          dt4: "Descripcion:"
        },
        { dt0: "activo", dt1: null, dt2: null, dt3: 0.1, dt4: "Desactivo" },
      ],


      list: [],
      search: "",
      acti_tb_data: false,

      notif: {
        estado: false,
        nombre: "",
        Exec: ""
      },

      aalert: {
        estado: false,
        color: null
      },

    };
  },

  //   destroyed() {
  //     location.reload();
  //   },
  methods: {

    currencyFormatter, FormatoFecha, HoraFormatter,fechaNueva,
    onResize(e) {
      //this.windowSize=e
    },


    cargarDocumento() {

      this.$axios.get(this.$hostname + this.$hName + '/documentosgastos/gastoMenores',
        { headers: this.$store.getters.GetheadersAxios })
        .then(res => (this.RDocumento = res.data))

    },

    SelctCbDocumento() {
      if (this.GastoMenores.documentocontable == null) {

        this.GastoMenores.documentocontable = ''
      }

      if (this.GastoMenores.documentocontable.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.GastoMenores.documentocontable = ''
        return

      }
      this.tieneComprobante();
    },


    SelctCbReportDocumento() {
      if (this.datosImprecionAll.documento == null) {

        this.datosImprecionAll.documento = ''
      }

      if (this.datosImprecionAll.documento.codigo == undefined) {

        //this.alerta("Seleccione un documento", "error");
        this.datosImprecionAll.documento = ''
        return

      }


    },
    tieneComprobante() {
      //this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/"+this.GastoMenores.documentocontable.codigo+"/8/"+this.GastoMenores.documentocontable.operacion.modulo.trim()+"",
      this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/"+this.GastoMenores.documentocontable.codigo+"/"+this.GastoMenores.documentocontable.operacion.modulo.trim()+"",
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        console.log(res.data)
        if (!res.data) {
          this.alerta("No tiene secuencia para este documento", "error");
          this.noComprobante = true;
        } else {
          this.noComprobante = false;
        }
      });
    },
    SelctCbFiltroDocumento() {
      if (this.Modallfiltro.documento == null) {

        this.Modallfiltro.documento = ''
      }

      if (this.Modallfiltro.documento.codigo == undefined) {

        this.alerta("Seleccione un documento", "error");
        this.Modallfiltro.documento = ''
        return

      }

    },

    CargarRTipopago() {
      this.$axios.get(this.$hostname + this.$hName + '/categoriagastos/findallactivo',
        { headers: this.$store.getters.GetheadersAxios }
      ).then(res => {
        this.RTipoGasto = res.data;
      });
    },


    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;

    },
    cargarDetalle() {
      var det = {}
      var list = []
      this.ListGast.forEach(e => {
        if (e.categoria != null) {
          if (list.filter(r => r.categoriagastos.codigo === e.categoria.codigo).length <= 0) {
            var de = {
              gastoMenoresNcfPK: { categoria: 0, secuencia: 0, document: 0 },
              ncf: "",
              borrado: false,
              valor: e.valor,
              categoriagastos: e.categoria
            }
            list.push(de)
          } else {
            var ob = list.filter(r => r.categoriagastos.codigo === e.categoria.codigo)[0]
            ob.valor = ob.valor + e.valor;
          }
        }
      });
      this.ListGastMen = list;
      this.totalDetalle();
    },
    totalDetalle() {
      this.total = 0.00
      this.ListGast.forEach(element => {
        this.total += element.valor;
      });
    },
    async cargarTodo() {
    },
    datostabla(e) {
      this.contabilidad = null
        this.RLisCuenta = []
      this.ListGast = []
      this.GastoMenores = JSON.parse(JSON.stringify(e));
      //this.GastoMenores.fecha = (new Date(e.fecha)).toJSON().split("T")[0]
      this.GastoMenores.fecha = e.nuevaFecha
      this.ListGastMen = e.gastoMenoresNcfList
      e.gastosmenoresDetalleList.forEach(t => {
       /* t.fecha = (new Date(t.fecha)).toJSON().split("T")[0]
        t.fecha2 = (new Date(t.fecha)).toJSON().split("T")[0]*/
        t.fecha =t.nuevaFecha
        t.fecha2 =t.nuevaFecha
        this.ListGast.push(t);
      });
      this.acti_tb_data = false


 var _this = this;
            setTimeout(function () {
              _this.totalDetalle()()
            }, 500);

      var _this = this;
            setTimeout(function () {
              _this.getCrearCont()
            }, 900);


      /*if (e.activa == false) {
      } else {
        this.getCrearCont();
      }*/

    },
    save() {


      if (this.$refs.formGastoMenores.validate()) {
        EventBus.$emit("loading", true);
        if (this.noComprobante) {
          this.alerta("Este documento no posee comprobantes", "error")
          return;
        }

      

if(this.ListGast.length==0){
  this.alerta("Agrege al menos un gasto a la lista", "error")
          return;
}
if (!this.$refs.formTabGasto.validate()) {
  this.alerta("Los campos en rojo de la tabla gastos son requeridos", "error");
  return
}


        if (this.ejecucion == true) {
          this.ejecucion = false;
        if(this.RLisCuenta.length==0){
          this.getCrearCont()
        }

        var _this = this;
          setTimeout(function () {
            _this.contSave()
          }, 900);

        }


      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }


    },


contSave(){
  var OBJ=JSON.parse(JSON.stringify(this.GastoMenores))
          OBJ.fecha=this.GastoMenores.fecha+"T04:00";
          OBJ.gastoMenoresNcfList = this.ListGastMen
          OBJ.gastosmenoresDetalleList = this.ListGast
          OBJ.contabilidad = this.contabilidad

         if(this.RLisCuenta.length>0){
          OBJ.contabilidad.contabilidadDetalleList=this.RLisCuenta
        }

   
          this.$axios
            .post(this.$hostname + this.$hName +
              "/gastosmenores/save", OBJ,
              { headers: this.$store.getters.GetheadersAxios })
            .then(res => {this.alerta("Dato guardado correctamente!", "bien")})
            .catch(error =>{this.alerta("Dato no guardado", "error")});
},


    NotifMessage() {

      if (this.GastoMenores.gastosmenoresPK.secuencia != 0 && this.GastoMenores.gastosmenoresPK.secuencia != null) {

        var notif = {
          estado: true,
          nombre: "¿Seguro que desea anular el Gasto Menor?",
          Exec: "AnularGasto"
        }

        this.Exec_notif(notif)


      } else {
        this.alerta("Seleccione un Gasto Menor para eliminar", "error")
      }


    },

    Exec_notif(e) {

      //console.log(e)
      this.notif = JSON.parse(JSON.stringify(e))

      //this.Elim_notif.estado = !this.Elim_notif.estado;
    },

    eliminar() {

      var jsn = {
        datos: JSON.parse(JSON.stringify(this.GastoMenores)),
        usuario: JSON.parse(JSON.stringify(this.$user))
      }

      if (this.GastoMenores.codigo != 0) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/services/tipocliente/borradoTipoCliente",
            JSON.parse(JSON.stringify(jsn))
          )
          .then(res => {
            this.alerta(res.data.mensage, res.data.estado)
          })
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      }


    },
    anular() {

        var jsn = {
          datos: JSON.parse(JSON.stringify(this.GastoMenores)),
          usuario: JSON.parse(JSON.stringify(this.$user))
        }

        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/gastosmenores/anular",this.GastoMenores,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then(res => this.alerta("Dato eliminado correctamente!", "bien"))
          .catch(error =>
            this.alerta(
              // "hubo un error , por favor verifique el campo descripcion!"
              "Dato no eliminado",
              "error"
            )
          );
      
    },
    imprimirList() {
      this.datosImprecion.secuencia = this.GastoMenores.gastosmenoresPK.secuencia
      this.datosImprecion.documento = this.GastoMenores.gastosmenoresPK.documento
      this.datosImprecion.total = this.ListGastMen.toString().length
      this.dialogimprimirList = true

    },
    DDataReporteBasico(e) {
      this.dialogimprimirList = e
    },

    CCuentasContables(e, t, b) {


      if (e != undefined) {

        if (e.length == 0) {

          this.getCrearCont()

        } else {
          this.RLisCuenta = JSON.parse(JSON.stringify(e));
          this.TotalCuenta = JSON.parse(JSON.stringify(t))
        }
      }

      this.RLisCuenta = JSON.parse(JSON.stringify(e));
      this.form.RLisCuenta = this.RLisCuenta
      this.form.TotalCuenta = JSON.parse(JSON.stringify(t))

      this.acti_Modal_cuenta = b

    },


    vercuenta() {

      if (this.$refs.formGastoMenores.validate()) {
        if(this.RLisCuenta.length==0){
          this.getCrearCont();
        }
        this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
        this.TotalCuenta = JSON.parse(JSON.stringify(this.form.TotalCuenta))
        // this.calcularTotalcuenta(1)
        // setTimeout(this.acti_Modal_cuenta=!this.acti_Modal_cuenta,200);

        this.acti_Modal_cuenta = !this.acti_Modal_cuenta

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }


    },


    getCrearCont() {

      if (this.$refs.formGastoMenores.validate()) {
        console.log("Creado Contabilidad ---")
        if (this.GastoMenores.gastosmenoresPK.secuencia == 0 && this.total > 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/crear-contabilidad-gas/' + this.GastoMenores.documentocontable.codigo + '/' + this.total + '/0/0/0/0',
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });
        }
        if (this.GastoMenores.gastosmenoresPK.secuencia != 0) {
          this.$axios.get(this.$hostname + this.$hName + '/contabilidad/findContabilidad/' + this.GastoMenores.gastosmenoresPK.documento + '/' + this.GastoMenores.gastosmenoresPK.secuencia + '/' + this.GastoMenores.documentocontable.operacion.codigo,
            { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.llenarcuenta(res.data)
            });
        }
      }
    },
    llenarcuenta(e) {
      console.log("llenando contabilidad ");
      if (this.contabilidad === null) {
        this.contabilidad = e;
        this.contabilidad.fecha = this.GastoMenores.fecha;
        this.contabilidad.documento = this.GastoMenores.documentocontable.codigo;
      }
    
      
      this.RLisCuenta =e.contabilidadDetalleList.filter((ee) => ee.hasOwnProperty('cuenta') && ee.cuenta!=null && ee.cuenta.hasOwnProperty('cuenta') );
      // this.RLisCuenta = JSON.parse(JSON.stringify(e.contabilidadDetalleList));
      this.form.RLisCuenta = this.RLisCuenta
      this.objeTem = JSON.parse(JSON.stringify(this.RLisCuenta));
      this.calcularTotalcuenta(1)
    },

    calcularTotalcuenta(e) {

      this.form.TotalCuenta.credito = 0
      this.form.TotalCuenta.debito = 0

      if (e.toString().length > 0) {
        this.RLisCuenta.forEach(element => {

          this.form.TotalCuenta.credito += element.credito
          this.form.TotalCuenta.debito += element.debito
        });
      }

    },


    delGasto(e) {

      const index = this.ListGast.indexOf(e)
      confirm('Desea eliminar este Factura?') && this.ListGast.splice(index, 1)

    },


    addItemGasto() {

      var jsn = {

        fecha: fechaNueva(),
        fecha2: fechaNueva(),
        concepto: "",
        valor: 0,
        codigo: 0,
        categoria: null,
      }

      this.ListGast.push(jsn)

    },


    newwnuevo() {

      this.contabilidad = null
        this.RLisCuenta = []
      this.BarraTablaDinamicaAprir = false
      this.GastoMenores = {
        gastosmenoresPK: { secuencia: 0, documento: 0 },
        documentocontable: null,
        fecha: fechaNueva(),
        codigo: 0,
        concepto: "",
        activa: true,
        borrado: false,
        gastoMenoresNcfList: [],
        gastosmenoresDetalleList: [],
        contabilidad: null,
        moneda:null,
        tasa:0
      }
      this.ListGast = [], this.ListGastMen = [],
      this.total=0
        this.ejecucion = true
      //  EventBus.$emit("actualizaBarraBusqueda2");
      EventBus.$emit("loading", false);
      this.getMonedaBase()

    },


    BlurDesde() {

      if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva()
      }


    },


    BlurHasta() {

      if (this.datosImprecionAll.hasta.toString().length == 0) {
        this.datosImprecionAll.hasta = this.fechaNueva()
      }

    },


    abrirTabla2() {
      // console.log("entro");
      this.BarraSecundariaAprir = !this.BarraSecundariaAprir;
    },

    abrirTabla() {
      this.BarraTablaDinamicaAprir = !this.BarraTablaDinamicaAprir;
      if (this.BarraTablaDinamicaAprir == true) {
        this.Limpiarfiltro()
      }
    },

    actualiza_tb_ver() {

      this.acti_tb_data = !this.acti_tb_data;
      this.Limpiarfiltro()

    },

    Limpiarfiltro() {

      console.log('Entrando Al actualiza_tb_ver ')
      console.log(this.acti_tb_data)

      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      this.Modallfiltro = {
        codigo: null,
        documento: null,
        Desde: null,
        Hasta: null,
        ncf: null
      }

      this.ListItems = []
      this.TbTotalItem = 0

      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },

    BuscarFiltros() {
      var Pg = {
        itemsLength: this.TbPaginator.itemsLength,
        itemsPerPage: this.TbPaginator.itemsPerPage,
        page: 1,
        pageCount: 1,
        pageStart: 0,
        pageStop: this.TbPaginator.itemsPerPage,
      }

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) {
        this.Modallfiltro.codigo = null
      }


      this.TbPaginator = JSON.parse(JSON.stringify(Pg))
      this.CargarListTb()
      this.dialogFiltros = false

    },


    cargarPaginacionAll(e) {

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15
      }
      this.CargarPaginacion(e, this.Modallfiltro.codigo)
    },

    CargarPaginacion(e, s) {

      this.Modallfiltro.codigo = JSON.parse(JSON.stringify(s))

      if (e.itemsPerPage == -1) {
        e.itemsPerPage = 15
      }
      //var pg =
      this.TbPaginator = JSON.parse(JSON.stringify(e))
      this.CargarListTb()

    },

    CargarListTb() {

      if (this.Modallfiltro.codigo != null && this.Modallfiltro.codigo.toString().length == 0) {
        this.Modallfiltro.codigo = null
      }


      var doc = null
      if (this.Modallfiltro.documento != null) {
        doc = this.Modallfiltro.documento.codigo
      }
      var obj = this.TbPaginator.itemsPerPage + "/" + this.TbPaginator.pageStart + "/" + this.Modallfiltro.codigo + "/" + doc + "/" + this.Modallfiltro.Desde + "/" + this.Modallfiltro.Hasta
      this.$axios.get(this.$hostname + this.$hName + '/gastosmenores/findListGastMenoresPlus/' + obj, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.ListItems = res.data.ListItems, this.TbTotalItem = res.data.TbTotalItem
        })
    },


    getMonedaBase(){
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  this.GastoMenores.moneda= MONEDA
  this.GastoMenores.tasa=MONEDA.tasa
},

cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    SelctCbMoneda() {
var _this = this;
      setTimeout(function () {
        if (_this.GastoMenores.moneda == null) {
          _this.GastoMenores.moneda = "";
        }

        if (!_this.GastoMenores.moneda.hasOwnProperty('codigo')) {
          _this.GastoMenores.moneda = "";
          _this.GastoMenores.moneda = null;
          _this.getMonedaBase()
          return;
        }
      }, 300);
},

ChangeMonedaTasa(e) {
  if (typeof e === 'object' && e != null) {
      this.GastoMenores.tasa = JSON.parse(JSON.stringify(e.tasa))
 
  }
},

    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);

        this.newwnuevo();
      }

      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
          EventBus.$emit("loading", false);
        this.ejecucion = true;
      }
    },

    async filtro() {

      await this.$axios
        .get(this.$hostname + this.$hName + "/gastosmenores/findall",
          { headers: this.$store.getters.GetheadersAxios }
        ).then(res => {
          this.list = res.data;
        });
    }
  },


  computed: {

    ActDescBtn() {

      if (this.GastoMenores.gastosmenoresPK.secuencia > 0) {

        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: true,
          imprimir: true,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
        if (!this.GastoMenores.activa) {
          this.EstadoBtn.guardar=false;
          this.EstadoBtn.anular = false;
          this.EstadoBtn.vercuenta = false;
        }
      }


      if (this.GastoMenores.gastosmenoresPK.secuencia <= 0) {
        this.EstadoBtn = {
          BarraTablaDinamicaAprir: true,
          nuevo: true,
          guardar: true,
          eliminar: false,
          anular: false,
          imprimir: false,
          vercuenta: true,
          guardartemporal: false,
          restaurartemporal: false,
          importarDato: false,
          cargarProducto: false,
          ImprimirproductoInv: false,
          etiquetaXfactura: false
        }
      }

      this.windowSize = JSON.parse(JSON.stringify(this.$store.getters.GetwindowSize))

    }

  }


};
</script>

<style lang="scss">

</style>
