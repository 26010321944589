<template>
  <div v-resize="onResize" v-dirkeydown="onkeydownCode"
    v-bind:style="{ 'background-color': '#dd4b39', 'position': 'absolute', 'border': '2px solid #F44336', 'width': '100%', 'height': this.windowSize.height - 5 + 'px' }">
    {{ GetEjecucion }}
    <v-row>
      <!-- columna general 1 -->
      <v-col cols="12" md="6" sm="6">
        <v-container
          v-bind:style="{ 'height': this.windowSize.height - 30 + 'px', 'max-height': this.windowSize.height - 30 + 'px', 'border': '1px solid #000000', 'background-color': '#FFFFFF' }">
          <!--------------------- Informacion cliente & vendedor height:75px;   ------------------------------->
          <v-row style="margin-top:-11px; background-color:#3d6687; min-height:20px">

            <v-tooltip dense top v-if="clienteFactura != null">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" class="ma-0" color="#FFFFFF" outlined close @click:close="NotifMessage('quitarCliente')"
                  small>
                  <v-icon left>mdi-account-outline</v-icon>
                  <span>CLIENTE: </span> <span> {{ clienteFactura.nombre  }}</span>
                </v-chip>
              </template>
              <span>Cliente</span>
            </v-tooltip>

            <v-tooltip dense top v-if="SelectMedicoFactura != null">
              <template v-slot:activator="{ on }">
                <v-chip v-on="on" class="ma-0" color="#FFFFFF" outlined close @click:close="NewNewMedico()" small>
                  <v-icon left>mdi-account-outline</v-icon>
                  MÉDICOS: <span> {{ SelectMedicoFactura.nombre }}</span>
                </v-chip>
              </template>
              <span>Médicos</span>
            </v-tooltip>

            <v-tooltip dense top v-if="Facturasventa.vendedor != null">
              <template v-slot:activator="{ on }">

                <v-chip v-on="on" class="ma-0" color="#FFFFFF" outlined close small @click:close="NewNewVendedores">
                  <v-icon left>mdi-account-outline</v-icon>
                  VENDEDOR: <span> {{ Facturasventa.vendedor.nombre }}</span>
                </v-chip>
              </template>
              <span>vendedor</span>
            </v-tooltip>

          </v-row>

          <!--------------------- Accion & Totales ------------------------------->
          <v-container style="margin-top:-10px;">
            <v-row v-bind:style="{ 'border': '1px solid #000000', 'background-color': '#FFFFFF' }">
              <v-col cols="12" md="2" sm="3" style="border: 1px solid #000000;">
                <!-- Btn-Guaardar temporal -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">

                    <v-btn @click.prevent="OpenDialogGuardartemporal" v-on="on" color="indigo" height="60px" outlined
                      dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <v-avatar tile size="50" color="transparent">
                        <img src="@/assets/icons_pv/extras/guardar.png" alt="guardar ">
                      </v-avatar>
                      <span style="margin-top:5px; margin-left: 5px;" >
                          <b v-bind:style="{ 'font-size': '10px', 'color': '#000000', 'border':`1px solid #000000 `}">F3</b>
                        </span>
                    </v-btn>

                  </template>
                  <span>Guardar temporal</span>
                </v-tooltip>
                <!-- fn Btn-Guardar temporal  -->
                <!-- Btn-limpiar Pantalla -->
                <v-tooltip top>
                  <template v-slot:activator="{ on }">

                    <v-btn @click.prevent="limpiarPuntoVentaCompleto" v-on="on" color="#90A4AE" outlined height="60px"
                      dark>
                      <v-avatar tile size="50" color="transparent">
                        <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                      </v-avatar>
                      <span style="margin-top:5px; margin-left: 5px;" >
                          <b v-bind:style="{ 'font-size': '10px', 'color':'#FFFFFF', 'border':`1px solid #FFFFFF`}">00</b>
                        </span>
                    </v-btn>

                  </template>
                  <span>limpiar Pantalla</span>
                </v-tooltip>
                <!-- fn Btn-Guaardar temporal -->
              </v-col>

              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="10" sm="9" style="border: 1px solid #000000;">
                <v-row no-gutters>
                  <v-col cols="6" md="6" sm="6" style="margin-top:-8px">
                    <h1 id="miniTotal"> <b class="d-flex justify-start">Sub-Total:</b> </h1>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-8px  ">
                    <h1 id="miniTotal"> <b class="d-flex justify-end blue--text">
                        {{ currencyFormatter2(getTasaMonedaSelect(Totales.totalGeneral.subtotal)) }} </b> </h1>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.descuento > 0">
                    <h2 id="miniTotal"> <b class="d-flex justify-start">Descuento:</b> </h2>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.descuento > 0">
                    <!-- <h2 id="miniTotal" class="blue--text" > <b class="d-flex justify-end"> {{currencyFormatter2(Descuentofactura.descuento)}} </b> </h2> -->
                    <h2 id="miniTotal" class="blue--text"> <b class="d-flex justify-end">
                        {{ currencyFormatter2(getTasaMonedaSelect(Totales.totalGeneral.descuento)) }} </b> </h2>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px">
                    <h1 id="miniTotal"> <b class="d-flex justify-start">Itbis:</b> </h1>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px">
                    <h1 id="miniTotal" class="blue--text"> <b class="d-flex justify-end">
                        {{ currencyFormatter2(getTasaMonedaSelect(Totales.totalGeneral.itbis)) }} </b> </h1>
                  </v-col>



                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.Retencion > 0">
                    <h2 id="miniTotal"> <b class="d-flex justify-start">Ret.Gub:</b> </h2>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.Retencion > 0">
                    <!-- <h2 id="miniTotal" class="blue--text" > <b class="d-flex justify-end"> {{currencyFormatter2(Retencionfactura.Retencion)}} </b> </h2> -->
                    <h2 id="miniTotal" class="blue--text"> <b class="d-flex justify-end">
                        {{ currencyFormatter2(getTasaMonedaSelect(Totales.totalGeneral.Retencion)) }} </b> </h2>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.recargo > 0">
                    <h2 id="miniTotal"> <b class="d-flex justify-start">Recargo:</b> </h2>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.recargo > 0">
                    <!-- <h2 id="miniTotal" class="blue--text" > <b class="d-flex justify-end"> {{currencyFormatter2(Recargofactura.recargo)}} </b> </h2> -->
                    <h2 id="miniTotal" class="blue--text"> <b class="d-flex justify-end">
                        {{ currencyFormatter2(getTasaMonedaSelect(Totales.totalGeneral.recargo)) }} </b> </h2>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.cobertura > 0">
                    <h2 id="miniTotal"> <b class="d-flex justify-start">Cobertura:</b> </h2>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-18px" v-if="Totales.totalGeneral.cobertura > 0">
                    <!-- <h2 id="miniTotal" class="blue--text" > <b class="d-flex justify-end"> {{currencyFormatter2(CoberturaSeguro.cobertura)}} </b> </h2> -->
                    <h2 id="miniTotal" class="blue--text"> <b class="d-flex justify-end">
                        {{ currencyFormatter2(getTasaMonedaSelect(Totales.totalGeneral.cobertura)) }} </b> </h2>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" style="margin-top:-05px">
                    <hr style="border:1px solid #000000;">
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-10px">
                    <h1 id="miniTotal"> <b class="d-flex justify-start">TOTAL:</b> </h1>
                  </v-col>

                  <v-col cols="6" md="6" sm="6" style="margin-top:-10px">
                    <h1 id="miniTotal" class="blue--text"> <b class="d-flex justify-end">
                        {{ currencyFormatter2(getTasaMonedaSelect(Totales.totalGeneral.total)) }} </b> </h1>
                  </v-col>

                </v-row>

              </v-col>
            </v-row>
          </v-container>
          <!--------------------- Seleccion de producto & Tabla ------------------------------->
          <div v-show="PanelSelectProducto" style="margin-top:-10px;">
            <center>
              <!-------------------------------------------------- input-Buscar Productos -->
              <div v-show="activoF7==false">

              <v-tooltip top>
                <template v-slot:activator="{ on }">


                  <!-- hide-no-data
                  hide-selected 
                  v-combobox
                  -->
                  
                  <div class="pt-0"> 
                  <v-combobox v-if="$store.getters.GetConfiguracionGeneral.configPv.busquedaFluido==false" ref="RefTextFieldProducto" autocomplete="off" :items="[]" dense outlined label="Buscar:" hide-details
                  v-model="searchProducto" @keyup.enter="SeachFilkeyEnterProd" @focus="CargarRproducto"
                  @keyup="SelectMarcaProductoKeyNofluido" :disabled="disabledTextFieldProducto"
                 color="green"
                  >
                  <!-- color="green" -->
                    <template v-slot:prepend-inner>
                      <i  @click="SeachProducto" style="font-size: 25px;" class="fa fa-search"></i>
                    </template>

                    <template v-slot:append>
                      <i style="font-size: 0px;" class="fa fa-search"></i>
                    </template>


                      </v-combobox>
                    </div>
                  <!-- <v-text-field v-if="$store.getters.GetConfiguracionGeneral.configPv.busquedaFluido==false"  id="formdisabledInput" ref="RefTextFieldProducto" background-color="white" color="grey darken-4"
                    autocomplete="off" v-on="on" v-model="searchProducto" @keyup.enter="SeachFilkeyEnterProd" 
                    @focus="CargarRproducto"  label="Buscar" single-line hide-details outlined dense
                    @keydown="SelectMarcaProductoKeyNofluido"
                    :disabled="disabledTextFieldProducto" >
                    <template v-slot:prepend-inner>
                      <i @click="SeachProducto" style="font-size: 25px;" class="fa fa-search"></i>
                    </template>
                  </v-text-field> -->

                  <v-text-field v-if="$store.getters.GetConfiguracionGeneral.configPv.busquedaFluido==true"  id="formdisabledInput" ref="RefTextFieldProducto" background-color="white" color="grey darken-4"
                    autocomplete="off" v-on="on" v-model="searchProducto" @keyup="SeachFilProducto"
                    @focus="CargarRproducto"  label="Buscar" single-line hide-details outlined dense
                    @keydown="SelectMarcaProducto"
                    :disabled="disabledTextFieldProducto" >
                    <template v-slot:prepend-inner>
                      <i @click="SeachProducto" style="font-size: 25px;" class="fa fa-search"></i>
                    </template>
                  </v-text-field>

                </template>
                <span>Busqueda</span>
              </v-tooltip>
            </div>
              <!-------------------------------------------------- fn input-Buscar Productos-->
<!-------------------------------------------------- input-Buscar Vendedor  single-line-->
<div v-show="activoF7==true">
  <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-text-field ref="RefTextFieldBuscaVendedorCodigo"  background-color="white" color="grey darken-4"
                    autocomplete="off" v-on="on"  @keyup.enter="SeachTextFilVendedorCodigo"
                     label="Buscar vendedor por código"  hide-details outlined dense
                     @blur="blurTextFilVendedorCodigo"
                     v-model="searchVendedorCodigo"
                    >
                    <template v-slot:prepend-inner>
                      <i style="font-size: 25px;" class="fa fa-universal-access"></i>
                    </template>
                  </v-text-field>
                </template>
                <span>Busqueda por el código del vendedor</span>
              </v-tooltip>
</div>
  <!-------------------------------------------------- input-Buscar Vendedor -->

              <!-- #757575 @click:row="addClikProd" item-key="codigo"    -->

              <!-- @item-selected="obtenerDatoSelect" -->
              <!--------------------------------------------------  Header select producto -->
              <v-col style="background-color:#3d6687;" v-show="ProdCompOrSelecProd == false" class="py-0 my-0" cols="12"
                md="12" sm="12">
                <v-row style="border: 1px solid #000000;">
                  <!-- <v-col class="pa-0 ma-0"  cols="2" md="2"> <b :class="`d-flex justify-start white--text`" > Cantidad</b></v-col> -->
                  <v-col class="pa-0 ma-0" cols="6" md="6"> <b :class="`d-flex justify-start white--text`">
                      Producto</b>
                  </v-col>
                  <!-- <v-col class="pa-0 ma-0"  cols="2" md="2"><b :class="`d-flex justify-center white--text`" > Unidad </b></v-col> -->
                  <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`"> Precio </b>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`"> Exit. </b>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`"> Acción </b>
                  </v-col>
                </v-row>
              </v-col>
              <!-------------------------------------------------- fn Header select producto  -->
              <!-------------------------------------------------- tabla-Seleccion de producto -->
              <!-- 285 id="divSelecProd"   v-bind:style="{  'max-height':this.windowSize.height-400+'px','border': '2px solid #000000' }" class="overflow-x-auto"  -->

              <div
                v-bind:style="{ 'height': this.windowSizeCaja.height + 'px', 'max-height': this.windowSizeCaja.height + 'px', 'border': '2px solid #000000' }"
                v-show="ProdCompOrSelecProd == false" class="overflow-x-auto" ref="RefScroll">
                <v-data-table dense hide-default-footer hide-default-header :items-per-page="-1" :single-select="true"
                  item-key="indx" :show-select="true" :headers="[
                    { text: 'CODIGO', value: 'productos.codigo', sortable: false, class: ['no-gutters'] },
                    { text: 'PRODUCTO', value: 'productos.descripcion', sortable: false, class: ['no-gutters'] },
                    { text: 'COSTO', value: 'costo', sortable: false, class: ['no-gutters'] },
                    { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters'] },
                    { text: 'EXISTENCIA', value: 'existencias', sortable: false, align: 'center', class: ['no-gutters'] },
                  ]" :items="RallProducto" class="elevation-1">

                  <!-- PRODUCTO, COSTO, PRECIO, EXISTENCIA :active="selected == props.item"-->

                  <template v-slot:item="props">
                    <span style="display:none;">{{ props.item.indx = RallProducto.indexOf(props.item) }}</span>
                    <tr v-bind:class="classObjProdSelect(props.item)">
                      <!-- @click.prevent="EditarProductoSelect(props.item, false)" -->
                      <td v-ripple="{ class: `indigo--text` }">
                        <span style="display:none;" v-if="!props.item.hasOwnProperty('cantidad')">{{ props.item.cantidad
                                                  = 1
                        }}</span>
                        <v-row>
                          <v-col class="pa-0 ma-0" cols="6" md="6"><span><b>{{ props.item.productos.descripcion }} {{
                            props.item.unidades.descripcion
                          }}</b></span></v-col>
                          <!-- <v-col class="pa-0 ma-0"     cols="2" md="2"><span> <b>X</b> {{ currencyFormatter(props.item.cantidad)}}</span></v-col> -->
                          <!-- <v-col class="pa-0 ma-0"  cols="2" md="2"><span :class="`d-flex justify-center`" >{{props.item.unidades.descripcion}}</span></v-col> -->
                          <v-col class="pa-0 ma-0" cols="2" md="2"><span :class="`d-flex justify-center`"> {{
                            currencyFormatter2(props.item.precio)
                          }}</span></v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"><span :class="`d-flex justify-center`"> {{
                            currencyFormatter2(props.item.existencias)
                          }}</span></v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2">
                            <v-row :class="`d-flex justify-center`">

                              <v-btn :disabled="validarBusProductoBarraReferencia($refs.RefTextFieldProducto.value)==true" @click.prevent="BuscPropiedadProductoSelct(props.item, 'add')" small
                                color="light-blue darken-4"><i style="font-size: 20px;" class="fa fa-plus"></i></v-btn>
                              <v-btn @click.prevent="BuscPropiedadProductoSelct(props.item, 'ver')" small color="grey">
                                <i style="font-size: 20px;" class="fa fa-eye"></i>
                              </v-btn>
                            </v-row>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12">
                                <span v-bind:class="`px-1 `+classObjProdSelectFabricante(props.item)"
                                    style=" font-size:12px; border: 1px dashed  #616161;">
                                    <b>{{FabricanteOrProveedor()}}</b>
                                    {{mostrarFabricanteProveedor(props.item)}}</span>
                              </v-col>
                        </v-row>
                        <!-- <v-list-item-content style="border: 2px solid #000000;"  >  </v-list-item-content> -->
                      </td>
                    </tr>
                  </template>
                  <!--
     <template v-slot:item.productos.descripcion="props">
      <span style="font-size:13px;" > {{props.item.productos.descripcion}}</span>
     </template>-->
                </v-data-table>
              </div>
              <!-------------------------------------------------- fn Seleccion de producto -->

              <!--------------------------------------------------  Header tb compra producto -->
              <v-col style="background-color:#3d6687;" v-show="ProdCompOrSelecProd == true" class="py-0 my-0" cols="12"
                md="12" sm="12">
                <v-row style="border: 1px solid #000000;">
                  <v-col class="pa-0 ma-0" cols="6" md="6"> <b :class="`d-flex justify-start white--text`">
                      Producto</b>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`"> Cantidad</b>
                  </v-col>
                  <!-- <v-col class="pa-0 ma-0"  cols="2" md="2"><b :class="`d-flex justify-center white--text`" > Unidad </b></v-col> -->
                  <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`"> Precio </b>
                  </v-col>
                  <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`"> Valor </b>
                  </v-col>
                </v-row>
              </v-col>
              <!-------------------------------------------------- fn Header tb compra producto  -->
              <!-------------------------------------------------- Tb productos compra -->
              <div
                v-bind:style="{ 'height': this.windowSizeCaja.height + 'px', 'max-height': this.windowSizeCaja.height + 'px', 'border': '2px solid #000000' }"
                v-show="ProdCompOrSelecProd == true" class="overflow-x-auto" ref="RefScrollProductoCompra">
                <v-data-table dense hide-default-footer hide-default-header :items-per-page="-1" :single-select="true"
                  item-key="indx" :show-select="true" :headers="[
                    { text: 'CODIGO', value: 'productos.codigo', sortable: false, class: ['no-gutters'] },
                    { text: 'PRODUCTO', value: 'productos.descripcion', sortable: false, class: ['no-gutters'] },
                    { text: 'COSTO', value: 'costo', sortable: false, class: ['no-gutters'] },
                    { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters'] },
                    { text: 'EXISTENCIA', value: 'existencias', sortable: false, align: 'center', class: ['no-gutters'] },
                  ]
                  " :items="RProductoCompra" class="elevation-1">

                  <!-- <template v-slot:prepend-item>
                      <v-row>
                      
                      <v-col cols="9" md="9" > <b style="font-size: 16px;">Descripción: </b> </v-col>
                      <v-col cols="3" md="3" > <b style="font-size: 16px;">Modulo:</b> </v-col>
                     </v-row>
      </template> -->
                  <!-- PRODUCTO, COSTO, PRECIO, EXISTENCIA :active="selected == props.item"-->

                  <template v-slot:item="props">
                    <span style="display:none;">{{ props.item.indx = RProductoCompra.indexOf(props.item) }}</span>
                    <tr v-bind:class="classObjProdSelect(props.item)">
                      <td @click.prevent="EditarProductoSelect(props.item, true)" v-ripple="{ class: `indigo--text` }">
                        <span style="display:none;" v-if="!props.item.hasOwnProperty('cantidad')">{{ props.item.cantidad= 1}}</span>
                        <span style="display:none;" v-if="!props.item.hasOwnProperty('valor')">{{ props.item.valor = 0}}</span>
                        <v-row>
                          <v-col class="pa-0 ma-0" cols="6" md="6"><span><b>{{ props.item.descripcion }}</b></span>
                          </v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"><span> <b>X</b> {{
                            currencyFormatter(props.item.cantidad)
                          }}</span></v-col>
                          <!-- <v-col class="pa-0 ma-0"  cols="2" md="2"><span :class="`d-flex justify-center`" >{{props.item.unidades.descripcion}}</span></v-col> -->
                          <v-col class="pa-0 ma-0" cols="2" md="2"><span :class="`d-flex justify-center`"> {{
                            currencyFormatter2(getTasaMonedaSelect(props.item.precio))
                          }}</span></v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"><span :class="`d-flex justify-center`">
                              {{ currencyFormatter2(CalcularValorProductoRPCompra(props.item)) }}
                              <!-- {{currencyFormatter2(((props.item.valor + props.item.RecarValor) -props.item.DescValor) - props.item.cobertura)}}-->
                            </span></v-col>
                          <v-col class="pa-0 ma-0" cols="12" md="12">
                            <span v-if="props.item.DescValor > 0" class="px-1"
                              style=" font-size:12px; border: 1px dashed  #616161;"><b> **DESCUENTO:</b>
                              ($ {{ currencyFormatter2(getTasaMonedaSelect(props.item.DescValor)) }})
                              (% {{ currencyFormatter2(getTasaMonedaSelect(props.item.descuento)) }})
                            </span>
                            <span class="px-1" v-if="props.item.recargo > 0"
                              style=" font-size:12px; border: 1px dashed  #616161;"><b> **RECARGO:</b>
                              {{ currencyFormatter2(getTasaMonedaSelect(props.item.RecarValor)) }} </span>
                            <span class="px-1" v-if="props.item.cobertura > 0"
                              style=" font-size:12px; border: 1px dashed  #616161;"><b> **COBERTURA:</b>
                              {{ currencyFormatter2(getTasaMonedaSelect(props.item.cobertura)) }} </span>

                            <span style="display:none;">
                              <!-- {{ props.item.valordescuento = currencyFormatter2(props.item.DescValor), 
                                      props.item.valorRecargo = currencyFormatter2(props.item.RecarValor)
                                    }}-->
                              {{ props.item.valordescuento = props.item.DescValor,
                                props.item.valorRecargo = props.item.RecarValor
                              }}
                            </span>


                            <span class="px-1 blue--text"
                              v-if="props.item.hasOwnProperty('paramOfert') && props.item.paramOfert == true"
                              style=" font-size:12px; border: 1px dashed  #616161;"><b> **OFERTA**</b></span>
                          </v-col>
                          <v-col class="pa-0 ma-0 " cols="12" md="12"
                            v-if="props.item.hasOwnProperty('vendedor') && props.item.vendedor != null">
                            <span class="px-1" style=" font-size:12px; border: 1px dashed  #616161;"><b>Vendedor: </b>{{
                              props.item.vendedor.nombre
                            }}</span>
                          </v-col>
                        </v-row>
                        <!-- <v-list-item-content style="border: 2px solid #000000;"  >  </v-list-item-content> -->
                      </td>
                    </tr>
                  </template>
                  <!--
     <template v-slot:item.productos.descripcion="props">
      <span style="font-size:13px;" > {{props.item.productos.descripcion}}</span>
     </template>-->
                </v-data-table>
              </div>
              <!-------------------------------------------------- fn Tb productos compra-->


            </center>
          </div>
          <!--------------------- fn Seleccion de producto & Tabla ------------------------------->

          <!--------------------- Informacion de producto ------------------------------->
          <!-- class="pa-0 ma-0 py-0 my-0" -->
          <div v-show="PanelInfProduct" style="background-color:#FFFFFF;">
            <div style="max-height:535px; border: 1px solid #000000;" class="overflow-x-auto">
              <v-col cols="12" md="12" sm="12" class="py-0 my-0 pt-1">
                <v-row>
                  <!-- columna 1 informacion producto -->
                  <v-col class="pa-0 ma-0" cols="12" md="3" sm="4">
                    <center>

                      <!-- <v-avatar tile size="113" color="transparent">
                          <img v-if="(productoSeleccionado!=null && productoSeleccionado.hasOwnProperty('productos') && productoSeleccionado.productos != null && productoSeleccionado.productos.hasOwnProperty('foto') && productoSeleccionado.productos.foto==null )" src="@/assets/img/Nofoto.png" alt="ImagenProducto" style="border: 2px solid #757575;">
                        </v-avatar> -->

                      <!-- <v-avatar tile size="113" color="transparent">
                          <img v-if="(productoSeleccionado!=null && productoSeleccionado.hasOwnProperty('productos') && productoSeleccionado.productos != null && productoSeleccionado.productos.hasOwnProperty('foto') && productoSeleccionado.productos.foto!=null && productoSeleccionado.productos.foto.length>0) " :src="productoSeleccionado.productos.foto" alt="ImagenProducto" style="border: 2px solid #757575;">
                        </v-avatar> -->

                      <v-avatar tile size="113" color="transparent">
                        <img v-if="(productoSeleccionado != null && productoSeleccionado.productos != null)"
                          :src="verFotodeProducto(productoSeleccionado.productos)" alt="ImagenProducto"
                          style="border: 2px solid #757575;">
                      </v-avatar>


                    </center>
                  </v-col>

                  <v-col cols="12" md="9" sm="8">
                    <span v-if="productoSeleccionado != null">
                      <v-row>

                        <v-col class="pa-0 ma-0" cols="12" md="12">
                          <span
                            v-if="productoSeleccionado.hasOwnProperty('productos') && productoSeleccionado.productos != null && productoSeleccionado.productos.hasOwnProperty('descripcion')">
                            <!--  <b>{{ productoSeleccionado.productos.descripcion }}</b>-->
                            <b>{{ productoSeleccionado.descripcion }}</b>
                          </span>
                        </v-col>

                        <v-col class="pa-0 ma-0" cols="6" md="6"
                          v-if="productoSeleccionado.hasOwnProperty('productos') && productoSeleccionado.productos != null">
                          <span><b>Codigo: </b> {{ productoSeleccionado.productos.codigo }}<br></span>
                          <span><b>Referencia: </b> {{ productoSeleccionado.productos.referencia }}<br></span>
                          <span><b>Ultima Entrada: </b> {{ productoSeleccionado.productos.existenciamaxima }}<br></span>
                          
                        </v-col>


                        <v-col class="pa-0 ma-0" cols="6" md="6">
                          <span v-if="productoSeleccionado.hasOwnProperty('precio')">
                            <b>Precio: </b> {{ currencyFormatter2(productoSeleccionado.precio) }}
                            <br>
                          </span>
                          <span v-if="productoSeleccionado.hasOwnProperty('existencias')">
                            <b>Existencia: </b> {{ productoSeleccionado.existencias }}
                            <br>
                          </span>

                          <span v-if="productoSeleccionado.hasOwnProperty('productos') && productoSeleccionado.productos != null">
                            <!-- <span v-if="productoSeleccionado.productos.hasOwnProperty('fabricante') && productoSeleccionado.productos.fabricante != null"> 
                              <b>Fabricante: </b> {{ productoSeleccionado.productos.fabricante.nombre }}
                              <br>
                            </span>-->
                            <span>
                              <b>Ubicacion: </b> {{ productoSeleccionado.productos.ubicacion }}
                              <br>
                            </span>
                          </span>

                        </v-col>

                        <v-col class="pa-0 ma-0" cols="12" md="12"
                        v-if="productoSeleccionado.hasOwnProperty('productos') && productoSeleccionado.productos != null">
                        <span><b>{{FabricanteOrProveedor()}} </b> {{mostrarFabricanteProveedor(productoSeleccionado)}}<br></span>
                      </v-col>

<v-col class="pa-0 ma-0" cols="6" md="6">
                          <span v-if="productoSeleccionado.hasOwnProperty('productos')&& productoSeleccionado.productos != null && productoSeleccionado.productos.hasOwnProperty('sustancia') && productoSeleccionado.productos.sustancia!=null && productoSeleccionado.productos.sustancia.length>0">
                            <b>Sustancia: </b> {{ productoSeleccionado.productos.sustancia }}
                            <br>
                          </span>

                        </v-col>

                      </v-row>
                    </span>
                  </v-col>


                  <!-- <v-col cols="12" md="6" sm="6">
           <v-row> 

           </v-row>
          </v-col>  -->

                  <!-- Preci,cantida aquii -->

                </v-row>
              </v-col>
            </div>
            <!-- Existencia de productos almacen  -->
            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0 pt-1">
              <div style="max-height:250px;" class="overflow-x-auto">

                <v-card v-if="UsarLote == false" style="border: 1px solid  #616161;">
                  <v-data-table :headers="[
                    { text: 'ALMACEN', value: 'almacen', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'EXISTENCIA', value: 'existencion', sortable: false, class: ['no-gutters black--text'] },
                  ]" dense :items="ListaProdxAlmacen" :footer-props="arrayPropiedases" :items-per-page="15">

                    <template v-slot:item.almacen="props">
                      <span style="font-size:13px;">{{ props.item.almacen }}</span>
                    </template>

                    <template v-slot:item.existencion="props">
                      <span style="font-size:13px;">{{ props.item.existencion }}</span>
                    </template>

                  </v-data-table>
                </v-card>
                <v-card v-if="UsarLote == true" style="border: 1px solid  #616161;">
                  <v-data-table v-model="selectedListProdxLote" item-key="indx" :show-select="true" :single-select="true"
                    :headers="[
                      { text: 'CÓDIGO', value: 'fechaVencimiento.codigo', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'FECHA VENCIMIENTO', value: 'fechaVencimiento', sortable: false, class: ['no-gutters black--text'] },
                      { text: 'EXISTENCIA', value: 'balance', sortable: false, class: ['no-gutters black--text'] },
                    ]" dense :items="ListaProdxLote" :footer-props="arrayPropiedases" :items-per-page="15"
                    @item-selected="obtenerDatoSelectProdxLote" @click:row="obtenerDatoSelectProdxLoteClickRow">
                    <template v-slot:item.balance="props">
                      <span style="font-size:13px;">{{ props.item.balance }}</span>
                    </template>

                    <template v-slot:item.fechaVencimiento="props">
                      <span style="display:none;">{{ props.item.indx = ListaProdxLote.indexOf(props.item) }}</span>

                      <span style="font-size:13px;" v-if="props.item.fechaVencimiento != null">{{
                        FormatoFecha(props.item.fechaVencimiento.fecha) }}</span>

                    </template>

                  </v-data-table>
                </v-card>


              </div>
            </v-col>
            <!-- bn Existencia de productos almacen  -->

          </div>

          <!--------------------- fn Informacion de producto ------------------------------->

        </v-container>
      </v-col>
      <!-- fn columna general 1 ------------->


      <!-- columna general 2 ---------------->
      <v-col cols="12" md="6" sm="6">
        <div class="overflow-y-auto"
          v-bind:style="{ 'height': this.windowSize.height - 30 + 'px', 'max-height': this.windowSize.height - 30 + 'px', 'border': '1px solid #000000', 'background-color': '#FFFFFF' }">
          <!-- style="border: 2px solid #757575; overflow: auto;  max-height:350px; min-width:100%;"   class="overflow-x-auto" -->

          <!--------------------- Panel 2 Completo Menu opcion , forma de pago y otros  ------------------------------->
          <div v-show="PanelMenuOpcionCompleto">

            <!---------------------  Panel Menu Opcion Botones ------------------------------->
            <div style="max-height:300px;" class="overflow-x-auto" v-show="PanelMenuOpcionBotones">
              <!-- columna  ---------------------->
              <PvMenuOpcionBotones ref="RefPvMenuOpcionBotones" @DataRetornAccion="DataRetornMenuOpcBotonesPermisosPv"
                @DataRetornAccionPadre="DataRetornPadrePermisoPv" :cajaSelect="caja" />
            </div>
            <!--------------------- fn Panel Menu Opcion Botones ------------------------------->

            <!--------------------- Panel Panel-Factura Domicilio  ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto"
              v-show="PanelMenuOpcionFacturaDomicilio">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Factura
                      Domicilio </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Factura Domicilio : </span>
                    Factura Domicilio </i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="cancelarFacturaDomicilio()" color="orange" small dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="AceptarFacturaDomicilio()" color="light-blue darken-4"
                          small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Aceptar Domicilio </span>
                    </v-tooltip>

                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12" v-if="domiciliofactura != null">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row no-gutters>
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <v-text-field id="formdisabledInput" disabled label="Cliente:" autocomplete="off" dense outlined 
                        v-model="domiciliofactura.nombre">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6">
                      <v-text-field label="Télefono:" :rules="[$rules.RulesTel]" dense outlined autocomplete="off"
                        maxlength="13" v-mask="'(###)###-####'" v-model="domiciliofactura.telefonoPrincipal">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-phone"></i>
                        </template>
                      </v-text-field>
                    </v-col>


                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6">
                      <v-combobox autocomplete="off" dense outlined label="Dirección:" :items="RDireccionDomicilio"
                        item-text="descripcion" :rules="[$rules.required]" v-model="domiciliofactura.selctDireccion"
                        @blur="SelctCbDireccionDomicilio" @change="ChangeDireccionDomicilio">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                        </template>
                      </v-combobox>
                    </v-col>


                    <v-col v-if="this.domiciliofactura.selctDireccion != null" class="pa-0 ma-0 py-0 my-0" cols="12"
                      md="12" sm="12">
                      <v-textarea autocomplete="off" label="Dirección:" rows="3" dense outlined
                        v-model="domiciliofactura.direccion" :rules="[$rules.required]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                        </template>
                      </v-textarea>
                    </v-col>


                  </v-row>
                </v-form>
              </v-col>
            </div>
            <!--------------------- fn Panel Factura Domicilio ------------------------------->



            <!--------------------- Panel Panel-Comentario Factura  ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto"
              v-show="PanelMenuOpcionComentarioFactura">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Comentario
                      Factura </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Comentario Factura : </span>
                    Comentario Factura </i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="cancelarComentarioFactura()" color="orange" small dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="NewNewComentarioFactura()" color="grey" small dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>

                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="SalvarComentarioFactura()" color="light-blue darken-4"
                          small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Aceptar la retención y retornar atrás</span>
                    </v-tooltip>


                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12">

                <v-row no-gutters>
                  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6">
                    <v-textarea autocomplete="off" label="comentario:" dense outlined v-model="Facturasventa.comentario">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-commenting"></i>
                      </template>
                    </v-textarea>

                    <v-text-field label="No.orden:" autocomplete="off" dense outlined v-model="Facturasventa.numeroOrden">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                      </template>
                    </v-text-field>



                  </v-col>


                  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6">
                    <v-textarea autocomplete="off" label="Ship To:" dense outlined v-model="Facturasventa.ShipTo">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-ship"></i>
                      </template>
                    </v-textarea>

                    <v-text-field label="Incoterms:" autocomplete="off" dense outlined v-model="Facturasventa.incoterms">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>

              </v-col>
            </div>
            <!--------------------- fn Panel Comentario Factura  ------------------------------->




            <!--------------------- Panel Panel-Moneda factura   ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto"
              v-show="PanelMenuOpcionMonedaFactura">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Moneda
                      Factura </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Moneda Factura : </span>
                    Moneda Factura </i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="cancelarMonedaFactura()" color="orange" small
                          dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar la Moneda a la factura y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="NewNewMonedaFactura()" color="grey" small dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>

                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="SalvarMonedaFactura()"
                          color="light-blue darken-4" small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Aceptar la Moneda y retornar atrás</span>
                    </v-tooltip>


                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12">

                <!-- -----------------------------------------Moneda   @blur="SelctCbMoneda"  -->
                <!-- <v-col   cols="12" md="12" sm="12" class="py-0 my-0"></v-col> -->
                
                <v-combobox autocomplete="off" dense outlined label="Monedas" :items="Rmoneda" item-text="descripcion"
                  :rules="[$rules.required]" v-model="Monedafactura" style="font-size:13px;" @focus="cargarMoneda"
                  >
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-money"></i>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span id="ImputColorDisable">
                      {{ item.descripcion }} ({{ item.nombrecorto }})
                    </span>

                  </template>


                  <template slot="item" slot-scope="item">
                    <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                    <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto }})</b>
                      </p>
                    </v-col>
                  </template>
                </v-combobox>


                <!-- -----------------------------  Tasa Compra -->
                <!-- <v-col   cols="12" md="6" sm="6" class="py-0 my-0"  v-if="Monedafactura!=null" ></v-col> -->
                <v-text-field v-if="Monedafactura != null" id="formdisabledInput" label="Tasa Compra:" dense outlined
                  v-model.number="Monedafactura.tasa">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-usd"></i>
                  </template>
                </v-text-field>


              </v-col>
            </div>
            <!--------------------- fn Panel Moneda factura  ------------------------------->





            <!--------------------- Panel Panel-Retencion factura   ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto"
              v-show="PanelMenuOpcionRetencionFactura">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Retención
                      Factura </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Retención Factura : </span>
                    Retención Factura </i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="cancelarRetencionFactura()" color="orange" small dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar la retención a la factura y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="NewNewRetencionFactura()" color="grey" small dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>

                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="SalvarRetencionFactura()" color="light-blue darken-4"
                          small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Aceptar la retención y retornar atrás</span>
                    </v-tooltip>


                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12">
                <VueAutonumeric label="Retención" dense outlined ref="RefTextFieldRetencionFactura"
                  @keydown.enter="SalvarRetencionFactura()" autocomplete="off" :rules="[$rules.Numericc]"
                  v-model.number="Retencionfactura.Retencion" style="font-size:13px;">
                  <template v-slot:prepend>
                    <i v-if="Retencionfactura.ValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                    <i v-if="Retencionfactura.ValorPorc == false" style="font-size: 20px;" class="fa fa-percent"></i>
                  </template>

                  <template v-slot:append-outer>

                    <v-radio-group dense @change="" style="margin-top: -18%;" v-model="Retencionfactura.ValorPorc" column>
                      <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                      <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                    </v-radio-group>
                  </template>
                </VueAutonumeric>

              </v-col>
            </div>
            <!--------------------- fn Panel Retencion factura  ------------------------------->

            <!--------------------- Panel Panel-recargo factura   ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto"
              v-show="PanelMenuOpcionRecargoFactura">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Recargo
                      Factura </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Recargo Factura : </span>
                    Recargo Factura </i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="cancelarRecargoFactura()" color="orange" small
                          dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar el recargo a la factura y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="NewNewRecargoFactura()" color="grey" small dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>


                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>


                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="SalvarRecargoFactura()"
                          color="light-blue darken-4" small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Aceptar el recargo a la factura y retorna atrás </span>
                    </v-tooltip>

                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12">
                <VueAutonumeric label="recargo" dense outlined ref="RefTextFieldRecargoFactura"
                  @keydown.enter="SalvarRecargoFactura()" autocomplete="off" :rules="[$rules.Numericc]"
                  v-model.number="Recargofactura.recargo" style="font-size:13px;">
                  <template v-slot:prepend>
                    <i v-if="Recargofactura.ValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                    <i v-if="Recargofactura.ValorPorc == false" style="font-size: 20px;" class="fa fa-percent"></i>
                  </template>

                  <template v-slot:append-outer>

                    <v-radio-group dense @change="" style="margin-top: -18%;" v-model="Recargofactura.ValorPorc" column>
                      <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                      <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                    </v-radio-group>
                  </template>
                </VueAutonumeric>
              </v-col>
            </div>
            <!--------------------- fn Panel recargo factura  ------------------------------->




            <!--------------------- Panel Panel Descuento Factura   ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto"
              v-show="PanelMenuOpcionDescuentoFactura">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Descuento
                      Factura </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Descuento Factura : </span>
                    Descuento Factura </i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="cancelarDescuentoFactura()" color="orange" small
                          dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar el descuento a la factura y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="NewNewDescuentoFactura()" color="grey" small
                          dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>


                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="SalvarDescuentoFactura()"
                          color="light-blue darken-4" small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Aceptar el descuento a la factura y retorna atrás </span>
                    </v-tooltip>

                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>
              <!--:rules="[$rules.Numericc]" -->
              <v-col cols="12" md="12" sm="12">
                <v-form v-model="valid" ref="formDescuentoFactura" @submit.prevent="" lazy-validation>
                  <VueAutonumeric label="Descuento" dense outlined ref="RefTextFieldDescuentoFactura"
                    @keydown.enter="SalvarDescuentoFactura()" autocomplete="off"
                    v-model.number="Descuentofactura.descuento" style="font-size:13px;" :rules="[$rules.Numericc]">
                    <template v-slot:prepend>
                      <i v-if="Descuentofactura.ValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                      <i v-if="Descuentofactura.ValorPorc == false" style="font-size: 20px;" class="fa fa-percent"></i>
                    </template>

                    <template v-slot:append-outer>

                      <v-radio-group dense @change="" style="margin-top: -18%;" v-model="Descuentofactura.ValorPorc"
                        column>
                        <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                        <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                      </v-radio-group>
                    </template>
                  </VueAutonumeric>
                </v-form>
              </v-col>
            </div>
            <!--------------------- fn Panel Descuento factura  ------------------------------->



            <!--------------------- Panel selecion vendedor  ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto" v-show="PanelMenuOpcionVendedor">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Selección de
                      Vendedor </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Selección de Vendedor :
                    </span> Se selecciona el vendedor a la factura actual</i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="cancelarVendedores()" color="orange" small dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar el vendedor a la factura y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click.prevent="NewNewVendedores()" color="grey" small dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>


                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>



                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12">

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <!--@change="getChangeClienteBusqueda"  @keyup="CbFilVendedorFactura" -->
                    <v-combobox ref="RefTextFieldBuscaVendedor" v-on="on" autocomplete="off" background-color="white"
                      color="grey darken-4" dense outlined label="buscar Vendedor:" :items="RVendedor" item-text="nombre"
                      style="font-size: 13px;" v-model="Facturasventa.vendedor" @blur="SelctCbVendedorFactura"
                      @keyup.enter="SalvarVendedores" @focus="cargarVendedorFactura" hide-details
                      :rules="[$rules.required]">
                      <template v-slot:prepend-inner>
                        <i style="font-size: 25px;" class="fa fa-search"></i>
                      </template>
                      <!-- margin-top="50px"style="font-size: 14px;" -->
                      <template slot="item" slot-scope="item">
                        <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                        <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0" cols="12"
                          md="12" sm="12">
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                              {{ item.item.nombre }}</b></p>

                          <span class="pa-0 ma-0 py-0 my-0">
                            <span style="border: 1px dashed  #616161;"><b>TEL.: </b>{{ item.item.telefono }} </span>
                            <span style="border: 1px dashed  #616161;"><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                          </span>
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                            {{ item.item.direccion }} </p>
                          <span style="border: 1px dashed  #616161;"
                            v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                              :{{ item.item.ars.nombre }}</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito > 0"><b> TIPO: Cliente
                              Credito</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito == 0"><b>TIPO: Cliente
                              Contado</b></span>
                        </v-col>


                      </template>


                      <template v-slot:append-outer>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">

                            <v-btn @click.prevent="SalvarVendedores" v-on="on" color="indigo lighten-2" outlined small
                              dark>
                              <!-- <i style="font-size: 20px;" class="fa fa-plus-square"></i> -->

                              <v-avatar tile size="28" color="transparent">
                                <img src="@/assets/icons_pv/extras/seleccionar48.png" alt="Asignar Vendedor">
                              </v-avatar>


                            </v-btn>

                          </template>
                          <span>Asignar Vendedor buscado a la factura</span>
                        </v-tooltip>

                      </template>



                    </v-combobox>
                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                    <span> Se selecciona el Vendedor que desea Asignar a la factura.</span>
                  </i>
                </v-tooltip>


              </v-col>
            </div>
            <!--------------------- fn Panel selecion vendedor  ------------------------------->


            <!--------------------- Panel-selecion médico  ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto" v-show="PanelMenuOpcionMedico">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Selección de
                      médico </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Selección de médico : </span>
                    Se selecciona el médico a la factura actual</i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn-toggle>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="cancelarMedico()" color="orange" small dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: No Agregar el médico a la factura y retornar atrás</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn id="boton-mini" v-on="on" @click="NewNewMedico()" color="grey" small dark>

                          <v-avatar tile size="28" color="transparent">
                            <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                          </v-avatar>


                          <i style="font-size: 20px;"> </i>
                          NUEVO
                        </v-btn>
                      </template>
                      <span>NUEVO: Limpia los campo para un nuevo registro</span>
                    </v-tooltip>



                  </v-btn-toggle>

                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12">

                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <!--@change="getChangeClienteBusqueda"  @keyup="CbFilVendedorFactura" -->
                    <v-combobox ref="RefTextFieldBuscaMedico" v-on="on" autocomplete="off" background-color="white"
                      color="grey darken-4" dense outlined label="buscar Medico:" :items="RMedico" item-text="nombre"
                      style="font-size: 13px;" v-model="SelectMedicoFactura" @blur="SelctCbMedicoFactura"
                      @keyup.enter="SalvarMedico" @focus="cargarMedico" hide-details :rules="[$rules.required]">
                      <template v-slot:prepend-inner>
                        <i style="font-size: 25px;" class="fa fa-search"></i>
                      </template>
                      <!-- margin-top="50px"style="font-size: 14px;" -->
                      <template slot="item" slot-scope="item">
                        <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                        <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0" cols="12"
                          md="12" sm="12">
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                              {{ item.item.nombre }}</b></p>

                          <span class="pa-0 ma-0 py-0 my-0">
                            <span style="border: 1px dashed  #616161;"><b>TEL.: </b> (809)342-6240</span>
                            <span style="border: 1px dashed  #616161;"><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                          </span>
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                            {{ item.item.direccion }} </p>
                          <span style="border: 1px dashed  #616161;"
                            v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                              :{{ item.item.ars.nombre }}</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito > 0"><b> TIPO: Cliente
                              Credito</b></span>
                          <span style="border: 1px dashed  #616161;" v-if="item.item.diascredito == 0"><b>TIPO: Cliente
                              Contado</b></span>
                        </v-col>


                      </template>


                      <template v-slot:append-outer>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">

                            <v-btn @click.prevent="SalvarMedico" v-on="on" color="indigo lighten-2" outlined small dark>
                              <!-- <i style="font-size: 20px;" class="fa fa-plus-square"></i> -->

                              <v-avatar tile size="28" color="transparent">
                                <img src="@/assets/icons_pv/extras/seleccionar48.png" alt="Asignar Vendedor">
                              </v-avatar>


                            </v-btn>

                          </template>
                          <span>Asignar médico buscado a la factura</span>
                        </v-tooltip>

                      </template>



                    </v-combobox>
                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                    <span> Se selecciona el médico que desea Asignar a la factura.</span>
                  </i>
                </v-tooltip>


              </v-col>
            </div>
            <!--------------------- fn Panel-selecion médico------------------------------->

            <!--------------------- Panel Forma pago nota de credito  ------------------------------->
            <div style="max-height:300px; min-height:300px " class="overflow-x-auto" v-show="PanelFormaPagoNotaCredito">

              <v-toolbar dark color="#dd4b39" dense>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">

                    <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Forma de
                      Pago Nota crédito </v-toolbar-title>

                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Forma de Pago Nota crédito:
                    </span> Forma de Pago Nota crédito</i>

                </v-tooltip>

                <v-spacer></v-spacer>

                <v-card-actions>
                  <v-btn color="grey darken-4" fab x-small
                    @click="PanelFormaPagoNotaCredito = false, PanelMenuOpcionBotones = true">
                    <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                  </v-btn>
                </v-card-actions>

              </v-toolbar>

              <v-col cols="12" md="12" sm="12">

                <v-text-field id="formdisabledInput" label="Escriba el nombre del cliente:" autocomplete="off" dense
                  outlined :rules="[$rules.required]" v-model="BuscarClienteFormaPagoNC" hide-details
                  @keyup="CbFilNotacreditocontado">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-font"></i>
                  </template>
                </v-text-field>


                <v-data-table v-model="selectedListFPagoNC" dense :headers="[
                  { text: 'CLIENTE', value: 'cliente', sortable: false, class: ['no-gutters black--text'] },
                  { text: 'CEDULA', value: 'cedula', sortable: false, class: ['no-gutters black--text'] },
                  { text: 'VALOR PENDIENTE', value: 'pendiente', sortable: false, align: 'center', class: ['no-gutters black--text '] },
                ]
                " :items="ListFormaPagoNC" item-key="indx" class="elevation-1" :single-select="true"
                  :show-select="true" @item-selected="obtenerDatoSelectFormaPagoNC"
                  @click:row="obtenerDatoSelectFormaPagoNCClickRow" :items-per-page="5">
                  <!-- hide-default-footer -->
                  <template v-slot:item.pendiente="props">
                    <span style="display:none;">{{ props.item.indx = ListFormaPagoNC.indexOf(props.item) }}</span>
                    <span style="font-size:13px;">{{ currencyFormatter(props.item.pendiente) }}</span>
                  </template>



                </v-data-table>



              </v-col>
            </div>
            <!--------------------- fn Panel Forma pago nota de credito  ------------------------------->


            <hr style="border:1px solid #000000;">
            <v-col style="margin-top:-10px" cols="12" md="12" sm="12">
              <center>
                <!--------------------- Panel-forma de pago pv ------------------------------->
                <v-card color="grey lighten-3" v-if="actualizarDecimal">
                  <v-row>
                    <!--  columna 1  @blur="ActualizarProductPago"-->
                        <!-- VueAutonumeric  -->
                    <v-col v-show="PanelFormaPagoColmna1" cols="12" md="6" sm="6">
                      <AutoNumericNuevoWithOptions :disabled="activaConduceCredito == true || this.SoloCxc==true" id="formdisabledInput"
                        ref="RefTextFieldForPagoEfectivo" label="Efectivo:" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.efectivo" @keydown.enter="enterEfectivo" @blur="BlurEfectivoPago"
                        :rules="[$rules.Numericc]" style="font-size: 13px;" @input="calculostotales" hide-details
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}"
                        >
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->

                          <!-- <span ></span> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/efectivo35.png" alt="Efectivo">
                          </v-avatar>

                        </template>
                      </AutoNumericNuevoWithOptions>
                      <!--@blur="ActualizarProductPago"  -->
                      <AutoNumericNuevoWithOptions :disabled="activaConduceCredito == true || this.SoloCxc==true" id="formdisabledInput" class="mt-1"
                        label="Tarjeta:" dense outlined autocomplete="off" v-model.number="Facturasventa.tarjeta"
                        @input="calculostotales" @keydown.enter="enterTarjeta" :rules="[$rules.Numericc]"
                        style="font-size: 13px;" hide-details @focus="OpenPanelFormaPagoTarjeta" @blur="BlurTarjetaPago"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/tarjeta35.png" alt="Tarjeta">
                          </v-avatar>
                        </template>
                      </AutoNumericNuevoWithOptions>
                      <AutoNumericNuevoWithOptions :disabled="activaConduceCredito == true || this.SoloCxc==true" id="formdisabledInput" class="mt-1"
                        label="Cheque:" dense outlined autocomplete="off" v-model.number="Facturasventa.cheques"
                        @input="calculostotales" @keydown.enter="enterCheque" :rules="[$rules.Numericc]"
                        style="font-size: 13px;" hide-details @focus="OpenPanelFormaPagoCheque('Cheque')"
                        @blur="BlurChequesPago"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/cheques35.png" alt="Cheque">
                          </v-avatar>



                        </template>
                      </AutoNumericNuevoWithOptions>


                      <AutoNumericNuevoWithOptions :disabled="activaConduceCredito == true || this.SoloCxc==true" id="formdisabledInput" class="mt-1"
                        label="Transferencia:" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.transferencia" @input="calculostotales"
                        @keydown.enter="enterTransferencia" :rules="[$rules.Numericc]" style="font-size: 13px;"
                        hide-details @focus="OpenPanelFormaPagoCheque('Transferencia')" @blur="BlurTransferenciaPago"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/transferencia35.png" alt="Transferencia">
                          </v-avatar>
                        </template>
                      </AutoNumericNuevoWithOptions>

                    </v-col>

                    <!-- fn columna 1 -->

                    <!--  columna 2  -->
                    <v-col v-show="PanelFormaPagoColmna2" cols="12" md="6" sm="6">

                      <!-- @blur="ActualizarProductPago" -->
                      <AutoNumericNuevoWithOptions :disabled="activaConduceCredito == true" id="formdisabledInput"
                        ref="RefTextFieldForPagoCxc" class="mt-1" label="Crédito (cxc):" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.cxc" @input="calculostotales" @focus="condicionByPago"
                        @keydown.enter="enterCxc" :rules="[$rules.Numericc]" style="font-size: 13px;" hide-details
                        @blur="BlurCxcPago"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/credito35.png" alt="Crédito">
                          </v-avatar>
                        </template>
                      </AutoNumericNuevoWithOptions>
                      <AutoNumericNuevoWithOptions v-show="false" class="mt-1" label="Cupones:" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.cupones" @input="calculostotales" @keydown.enter="enterCupones"
                        :rules="[$rules.Numericc]" style="font-size: 13px;" hide-details @blur="BlurCuponesPago"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}" >
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/cupones35.png" alt="Cupones">
                          </v-avatar>
                        </template>
                      </AutoNumericNuevoWithOptions>
                      <AutoNumericNuevoWithOptions class="mt-1" label="Conduce:" dense outlined autocomplete="off"
                        v-model.number="Facturasventa.valorConduce" @input="calculostotales" @keydown.enter="enterConduce"
                        :rules="[$rules.Numericc]" style="font-size: 13px;" hide-details @blur="BlurConducePago"
                        id="formdisabledInput" :disabled="this.SoloCxc==true"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}" >
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i>  color="transparent"-->
                          <v-avatar @click="btnActivaInactivaConduceCredito" tile size="30">
                            <img v-if="activaConduceCredito == true"
                              src="@/assets/icons_pv/formapago/conduce35activo2.png" alt="conduce">
                            <img v-if="activaConduceCredito == false" src="@/assets/icons_pv/formapago/conduce.png"
                              alt="conduce">

                          </v-avatar>

                        </template>
                      </AutoNumericNuevoWithOptions>

                      <AutoNumericNuevoWithOptions :disabled="activaConduceCredito == true || this.SoloCxc==true" id="formdisabledInput" class="mt-1"
                        label="Nota de crédito:" dense outlined autocomplete="off" v-model.number="Facturasventa.valorNc"
                        @input="calculostotales" @keydown.enter="enterNC" :rules="[$rules.Numericc]"
                        style="font-size: 13px;" hide-details @focus="OpenPanelFormaPagoNotacredito"
                        @blur="BlurNotaCreditoPago" v-on:blur="BlurValorNcPago"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/notacredito35.png" alt="Nota de crédito">
                          </v-avatar>
                        </template>
                      </AutoNumericNuevoWithOptions>
                      
                      <div v-show=" $store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial==true && clienteFactura!= null && clienteFactura.hasOwnProperty('codigo') && clienteFactura.balancePuntos>0" >
                      <AutoNumericNuevoWithOptions :disabled="activaConduceCredito == true || this.SoloCxc==true" id="formdisabledInput" class="mt-1"
                        :label="`Puntos:`" dense outlined autocomplete="off"
                        :suffix="`${getNombreClienteSelectGlobal(clienteFactura)}`"
                        v-model.number="Facturasventa.puntos" @input="calculostotales"
                        @keydown.enter="enterPunto" :rules="[$rules.Numericc]" style="font-size: 13px;"
                        hide-details  @blur="BlurPuntos"
                        v-bind:options="{ 'decimalPlaces': getDecimalPlaces()}">
                        <template v-slot:prepend>
                          <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/formapago/puntos2-35.png" alt="puntos">
                          </v-avatar>
                        </template>
                      </AutoNumericNuevoWithOptions>
                    </div>
                    </v-col>

                    <!-- columna 2- Opcion 2 Tarjeta -->
                    <v-col style="border: 1px dashed  #616161;" v-show="PanelFormaPagoTarjeta" cols="12" md="6" sm="6">

                      <v-form ref="formTarjetaFormPago" v-model="valid" lazy-validation>

                        <v-row>
                          <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <span :class="`d-flex justify-center white--text`"><b>Tarjeta</b></span>
                          </v-col>
                          <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="6"
                            md="6" sm="6">
                            <v-btn color="grey darken-4" fab x-small
                              @click="PanelFormaPagoTarjeta = false, PanelFormaPagoColmna2 = true">
                              <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-text-field style="font-size:13px;" v-model="Facturasventa.numtar" :rules="[$rules.required]"
                          autocomplete="off" label="Número Tarjeta:" required outlined dense class="mt-2" hide-details>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-text-field>

                        <v-combobox autocomplete="off" dense outlined label="Tipo de Tarjeta" :items="RTipoTarjeta"
                          item-text="nombre" :rules="[$rules.required]" v-model="tipoTarjeta" @blur="SelctCbTipoTarjeta"
                          @focus="cargarTipoTarjeta" class="mt-2" hide-details>

                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                          </template>
                        </v-combobox>

                      </v-form>

                    </v-col>
                    <!-- fn columna 2- Opcion 2 Tarjeta  -->

                    <!-- columna 2- Opcion 2 cheque -->
                    <v-col style="border: 1px dashed  #616161;" v-show="PanelFormaPagoCheque" cols="12" md="6" sm="6">
                      <v-row>
                        <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                          <span :class="`d-flex justify-center white--text`"><b>{{ NombreFormaPagoCheqTras }}</b></span>
                        </v-col>
                        <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end" cols="6"
                          md="6" sm="6">
                          <v-btn color="grey darken-4" fab x-small
                            @click="PanelFormaPagoCheque = false, PanelFormaPagoColmna2 = true">
                            <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                          </v-btn>
                        </v-col>
                      </v-row>

                      <v-text-field style="font-size:13px;" v-model="Facturasventa.numeroCheque"
                        :rules="[$rules.required]" autocomplete="off" :label="'Número ' + NombreFormaPagoCheqTras"
                        required outlined dense class="mt-2" hide-details>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                        </template>
                      </v-text-field>

                      <v-combobox autocomplete="off" dense outlined label="Banco" :items="RBanco" item-text="nombre"
                        :rules="[$rules.required]" v-model="bancoCheque" @blur="SelctCbBanco" @focus="cargarBanco"
                        class="mt-2" hide-details>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                        </template>
                      </v-combobox>



                    </v-col>
                    <!-- fn columna 2- Opcion 2 Cheque  -->




                    <!-- fn columna 2  -->

                  </v-row>
                </v-card>
                <!--  fn Panel-forma de pago pv-->
              </center>
            </v-col>
            <!-- fn columna  -->
            <center>

              <v-tooltip dense top>
                <template v-slot:activator="{ on }">
                  <h1 v-on="on" style="border: 2px solid #757575;" block>
                    <span v-if="devuelta < 0" class="red--text">Devuelta: $ ({{ currencyFormatter2(getTasaMonedaSelect(devuelta) * (-1)) }}
                      )</span>
                    <span v-if="devuelta == 0">Devuelta: $ 0.00</span>
                    <span v-if="devuelta > 0" class="green--text">Devuelta: $ {{ currencyFormatter2(getTasaMonedaSelect(devuelta)) }}</span>
                  </h1>
                </template>
                <b><span> <strong style="  background-color:black; font-size:14px;">Letra Negro:</strong> no contiene
                    devuelta ni faltante,
                    <strong style="  background-color:red; font-size:14px;">Letra Rojo:</strong> contiene faltante,
                    <strong style="  background-color:green; font-size:14px;">Letra Verde:</strong> contiene
                    devuelta.</span></b>

              </v-tooltip>
              <!-- Btn-Cobrar -->
              <v-hover v-slot:default="{ hover }" close-delay="200">

                <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                  @click.prevent="realizarCobro" :color="hover ? 'green darken-3' : 'green'"
                  v-ripple="{ class: `white--text` }" :loading="!ejecucion" :disabled="!ejecucion || PanelMenuOpcionMonedaFactura"  >

                  <h2>Cobrar</h2>
                  <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>
                </v-btn>
              </v-hover>
              <!-- fn Btn-Cobrar -->
            </center>
          </div>
          <!--------------------- fn Panel 2 Completo Menu opcion , forma de pago y otros  ------------------------------->

          <!--------------------- panel-Informacion y edicion de producto ------------------------------->
          <div v-show="PanelInfProduct" >
          <div v-if="PanelInfProduct" v-dirkeydown="finalizarPanelInformaciónProductoEsc"> </div>
            <!-- <div style="max-height:345px;" class="overflow-x-auto"></div> -->
            <v-toolbar dark color="#dd4b39" dense>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Información de
                    producto</v-toolbar-title>
                </template>
                <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Información de producto:
                  </span> Modificar Cantidad, Precio, Descuento, Recargo</i>

              </v-tooltip>

              <v-spacer></v-spacer>

              <v-card-actions>
                <v-btn-toggle>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn id="boton-mini" v-on="on" @click="cancelarInfProducto()" color="orange" small dark>
                        <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                        CANCELAR
                      </v-btn>
                    </template>
                    <span>CANCELAR: No Agregar producto a la lista y retornar atrás</span>
                  </v-tooltip>

                  <v-tooltip bottom v-if="this.EditProdCompa == true">
                    <template v-slot:activator="{ on }">
                      <v-btn :disabled="validarBusProductoBarraReferencia($refs.RefTextFieldProducto.value)==true" id="boton-mini" v-on="on" @click="eliminarInfProductoCompra()" color="red lighten-2" small
                        dark>
                        <i style="font-size: 20px;" class="fa fa-trash-o"> </i>
                        ELIMINAR
                      </v-btn>
                    </template>
                    <span>ELIMINAR: Remueve producto de la lista de compra y retornar atrás</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <!-- ValidarProductoOpcionVer(productoSeleccionado) -->
                      <v-btn :disabled="validarBusProductoBarraReferencia($refs.RefTextFieldProducto.value)==true"  id="boton-mini" v-on="on" @click.prevent="EjecutarPermisoPv=false,SalvarProductoCompraEnter()"
                        color="light-blue darken-4" small dark>
                        <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                        SALVAR
                      </v-btn>
                    </template>
                    <span>SALVAR: Agregar producto a la lista</span>
                  </v-tooltip>

                </v-btn-toggle>

              </v-card-actions>

              <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
            </v-toolbar>


            <!-- columna informacion producto -->
            <v-col cols="12" md="12" sm="12">
              <v-container>
                <v-card color="grey lighten-3">
                  <b :class="`d-flex justify-center`"> EDITAR PRODUCTO</b>
                  <v-form v-model="valid" ref="formCampProdc" lazy-validation>
                    <!-- Row 1 edicion producto      ------------------------------------------->

                    <v-row>
                      <v-col cols="12" md="4" sm="4">
                        <VueAutonumeric id="formdisabledInput" label="Cantidad" ref="RefTextFieldProductoCantidad" dense outlined
                          autocomplete="off" @keydown.enter="SalvarProductoCompraEnter"
                          @blur="ValidaRecetaProduControlado" :rules="[$rules.NumNocero]" v-on:blur="verficardatos"
                          v-model.number="productoSeleccionado.cantidad" style="font-size:13px;"
                          @focus="FocusProductoCantidad"
                          :disabled="validarBusProductoBarraReferencia(searchProducto)==true" >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>
                      </v-col>
                        <!--  -->
                      <v-col cols="12" md="4" sm="4">
                        <div style="display:none;">{{ getRulesProductoPrecio() }}</div>
                        <VueAutonumeric label="Precio" id="formdisabledInput"   ref="RefTextFieldProductoPrecio" dense outlined
                          @keydown.enter="enterPrecioProdSelect" autocomplete="off" :rules="boolRuleprodprecio===false?[$rules.required]:[$rules.Numericc]"
                          v-model.number="productoSeleccionado.precio" v-on:blur="verficardatos" style="font-size:13px;"
                          @focus="FocusProductoPrecio" @blur="blurPrecioProductoSelecc"
                          :disabled="validarBusProductoBarraReferencia(searchProducto)==true">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>
                      </v-col>


                      <v-col
                        v-show="$store.getters.GetConfiguracionGeneral.configPv.pedirCobertura == true && clienteFactura != null"
                        cols="12" md="4" sm="4">
                        <VueAutonumeric label="Cobertura" id="formdisabledInput"  dense outlined @keydown.enter="SalvarProductoCompraEnter"
                          autocomplete="off" :rules="[$rules.Numericc]" v-model.number="productoSeleccionado.cobertura"
                          style="font-size:13px;">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>


                      </v-col>
                    </v-row>
                    <!-- Row 2 edicion producto ------------------------------------------->

                    <v-row style="margin-top:-28px;">
                      <!--:suffix="clientTel"-->
                      <v-col cols="12" md="12" sm="12">
                        <v-text-field label="* Descripción producto:" dense outlined autocomplete="off"
                          id="formdisabledInput" style="font-size:13px;" v-model="productoSeleccionado.descripcion"
                          :disabled="!pedirNombre">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-font"></i>
                          </template>
                        </v-text-field>


                        <v-combobox autocomplete="off" dense outlined label="Vendedor:" :items="RVendedor"
                          item-text="nombre" style="font-size: 13px;" v-model="productoSeleccionado.vendedor"
                          @blur="SelctCbVendedorProducto" @focus="cargarVendedorProducto" @keyup="CbFilVendedorProducto"
                          @keydown.enter="SalvarProductoCompraEnter" ref="RefTextFieldProductoVendedor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-combobox>

                      </v-col>


                    </v-row>
                    <!-- Row 3 edicion producto ------------------------------------------->
                    <v-row style="margin-top:-28px;">

                      <v-col cols="12" md="6" sm="6">
                        <VueAutonumeric label="Descuento" ref="RefTextFieldProductoDescuento" dense outlined
                          @keydown.enter="SalvarProductoCompraEnter" autocomplete="off" :rules="[$rules.Numericc]"
                          v-model.number="productoSeleccionado.descuentoKeys" @blur="verficardatos"
                          style="font-size:13px;" @focus="FocusProductoDescuento"
                          id="formdisabledInput" :disabled="validarBusProductoBarraReferencia(searchProducto)==true" >
                          <template v-slot:prepend>
                            <i v-if="productoSeleccionado.ValorPorc == true" style="font-size: 20px;"
                              class="fa fa-usd"></i>
                            <i v-if="productoSeleccionado.ValorPorc == false" style="font-size: 20px;"
                              class="fa fa-percent"></i>
                          </template>

                          <template v-slot:append-outer>

                            <v-radio-group dense @change="ChangeProductoselectValorPorcc" style="margin-top: -18%;"
                              v-on:change="verficardatos" v-model="productoSeleccionado.ValorPorc" column>
                              <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                              <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                            </v-radio-group>

                          </template>
                        </VueAutonumeric>
                      </v-col>


                      <v-col cols="12" md="6" sm="6">
                        <VueAutonumeric label="Recargo" ref="RefTextFieldProductoRecargo" dense outlined
                          @keydown.enter="SalvarProductoCompraEnter" autocomplete="off" :rules="[$rules.Numericc]"
                          v-model.number="productoSeleccionado.recargo" style="font-size:13px;" v-on:blur="verficardatos"
                          @focus="FocusProductoRecargo"
                          id="formdisabledInput" :disabled="validarBusProductoBarraReferencia(searchProducto)==true" >
                          <template v-slot:prepend>
                            <i v-if="productoSeleccionado.ValorPorcRecarg == true" style="font-size: 20px;"
                              class="fa fa-usd"></i>
                            <i v-if="productoSeleccionado.ValorPorcRecarg == false" style="font-size: 20px;"
                              class="fa fa-percent"></i>
                          </template>

                          <template v-slot:append-outer>
                            <v-radio-group dense @change="ChangeProductoselectValorPorcc" style="margin-top: -18%;"
                              v-model="productoSeleccionado.ValorPorcRecarg" column v-on:change="verficardatos">
                              <v-radio label="Valor" color="teal lighten-2" :value="true"></v-radio>
                              <v-radio label="Porciento" color="teal lighten-2" :value="false"></v-radio>
                            </v-radio-group>
                          </template>
                        </VueAutonumeric>


                      </v-col>
                    </v-row>

              <!-- Row 4 edicion producto ------------------------------------------->
                    <v-row style="margin-top:-28px;">
                       
                      <v-col cols="12" md="12" sm="12">
                        <div style="max-height:385px;" class="overflow-x-auto">
                         <!------------------------------------------ Descripcion adicional -->
                    <v-textarea v-if="productoSeleccionado.productos!=null" autocomplete="off" label="Descripción Adicional:"  dense outlined
                        v-model="productoSeleccionado.productos.descripcionAdicional" id="formdisabledInput" disabled auto-grow rows="1" >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-commenting"></i>
                        </template>
                      </v-textarea>
                    </div> 
                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>

                <!-- Productos sustitutos -->
                <v-card v-if="ListProductoSustitutos.length > 0" style="border: 1px solid  #616161;">
                  <v-data-table item-key="indx" :headers="[
                    { text: 'PRODUCTO SUSTITUTO', value: 'prosust', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'EXISTENCIA', value: 'existencias', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'ACCIONES', value: 'agregar', sortable: false, class: ['no-gutters black--text'] },
                  ]" dense :items="ListProductoSustitutos" :footer-props="arrayPropiedases" :items-per-page="15">
                    <template v-slot:item.agregar="props">
                      <v-btn @click.prevent="BuscPropiedadProductoSelct(props.item, 'add')" small
                        color="light-blue darken-4"><i style="font-size: 20px;" class="fa fa-plus"></i></v-btn>
                      <v-btn @click.prevent="BuscPropiedadProductoSelct(props.item, 'ver')" small color="grey">
                        <i style="font-size: 20px;" class="fa fa-eye"></i>
                      </v-btn>
                    </template>

                    <template v-slot:item.prosust="props">
                      <span style="display:none;">{{ props.item.indx = ListProductoSustitutos.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ props.item.productos.descripcion }}</span>
                    </template>

                  </v-data-table>
                </v-card>
                <!-- fn Producto sustitutos-->
              </v-container>
            </v-col>
            <!-- columna informacion producto -->
          </div>
          <!--------------------- fn Informacion y edicion de producto ------------------------------->



        </div>
      </v-col>
      <!-- fn  columna general 2 -->
    </v-row>





    <!-- modales ---------------------------->

    <v-snackbar v-model="aalert.estado" :color="aalert.color" style="margin-top: 20%;" :vertical="true"
      :timeout="aalert.timeout" :right="aalert.right" :top="aalert.top">
      {{ aalert.nombre }}
      <v-btn dark text @click="aalert.estado = false">Close</v-btn>
    </v-snackbar>


    <!-- ------------------------------------------------------Modal Modal-Crear Producto -->
    <v-dialog transition="fab-transition" label="Modal Crear producto" v-model="dialogCrearProduct" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Creacion de producto ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Crear Producto
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Crear Producto: </span> Creación de
              productos</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarCrearProducto()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear el producto</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarCrearProducto()" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y crear el producto </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion producto -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> CREAR PRODUCTO</b>
                <v-form v-model="valid" ref="formCrearProduct" lazy-validation>
                  <!-- Row 1 edicion producto      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                      <v-text-field v-model="unidadesProductos.productos.descripcion" :rules="[$rules.required]"
                        autocomplete="off" label="Nombre:" required outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <VueAutonumeric label="Precio" dense outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="unidadesProductos.precio" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </VueAutonumeric>

                      <VueAutonumeric label="Costo" dense outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="unidadesProductos.costo" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </VueAutonumeric>


                      <v-combobox autocomplete="off" dense outlined label="Impuesto:" :items="RImpuesto"
                        item-text="descripcion" style="font-size: 13px;" v-model="unidadesProductos.productos.impuesto"
                        @blur="SelctCbImpuestoProducto" @focus="cargarImpuestoProducto">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>
                      </v-combobox>
                    </v-col>

                  </v-row>
                  <!-- Row 2 edicion producto ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion producto -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Crear producto-->

    <!-------------------------------------------------------- Modal- Modal-Ver y Asignar mensajero -->
    <v-dialog label="Modal Crear ver y asignar" v-model="dialogMensajero" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Seleccionar y Ver
                Mensajero</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Seleccionar y Ver Mensajero:
              </span> muestra y asigna las facturas a domicilio al mensajero</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="CancelarVerAsignarMensajero" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>


              <!-- <v-tooltip bottom>
    <template v-slot:activator="{ on }">
       <v-btn id="boton-mini" v-on="on" @click="NewMensagero" color="grey"  small dark>

        <v-avatar tile  size="28" color="transparent"  >
          <img
            src="@/assets/icons_pv/extras/limpiarPantalla.png"
            alt="Limpiar"
          >
        </v-avatar>


        <i style="font-size: 20px;" > </i>
        NUEVO </v-btn>
     </template>
              <span>NUEVO: Limpia los campo para un nuevo registro</span>
      </v-tooltip> -->


              <!-- <v-tooltip bottom v-if="VerFacturaDomicilioMensajero==true" >
    <template v-slot:activator="{ on }">
    <v-btn id="boton-mini"  v-on="on" @click="SalvarAsignarMensagero" color="light-blue darken-4" small   dark 
    :disabled="FacturaDomicilioMensajero.ComentarioLeido==false"

    >
    <i style="font-size: 20px;" class="fa fa-plus"></i>
    SALVAR</v-btn>
     </template>
              <span>SALVAR: Guarda las facturas recibida y retornar atrás </span>
      </v-tooltip> -->
              <!-- fab x-small dark  -->


              <v-tooltip bottom v-if="dialogAsignarMensajeros == true">
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarAsignarMensagero" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Guarda las facturas asignadas y retornar atrás </span>
              </v-tooltip>



          
              <v-tooltip bottom v-if="this.$store.getters.GetConfiguracionGeneral.configPv.imprimirlistamensajeros && dialogFacturasRutasMensajeros == true && buscarMensajero!=null && buscarMensajero.hasOwnProperty('nombre')  ">
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="imprmirFacturaRutaMensagero" color="blue" small dark>
                    <i style="font-size: 20px;" class="fa fa-print"></i><!-- fab x-small dark  -->
                    IMPRIMIR
                  </v-btn>
                </template>
                <span>IMPRIMIR: Imprimir facturas en ruta </span>
              </v-tooltip>


              <v-tooltip bottom v-if="dialogCambiarMensajeros == true  && selectedCambiarMensajeros.length>0 && buscarMensajero!=null && buscarMensajero.hasOwnProperty('nombre') ">
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cambiarMensajeroSelecionado" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-retweet"></i><!-- fab x-small dark  -->
                    CAMBIAR
                  </v-btn>
                </template>
                <span>CAMBIAR: cambia el mensajero seleccionado a la factura seleccionada </span>
              </v-tooltip>


              <!-- <v-tooltip bottom v-if="VerFacturaDomicilioMensajero==true" >
    <template v-slot:activator="{ on }">
    <v-btn id="boton-mini"  v-on="on" @click="SalvarAsignarMensagero" color="light-blue darken-4" small   dark 
    :disabled="FacturaDomicilioMensajero.ComentarioLeido==false"

    >
    <i style="font-size: 20px;" class="fa fa-plus"></i>
    SALVAR</v-btn>
     </template>
              <span>SALVAR: Guarda las facturas recibida y retornar atrás </span>
      </v-tooltip> -->


              <!-- FacturaDomicilioMensajero.ComentarioLeido,VerFacturaDomicilioMensajero==true -->



              <div v-if="VerFacturaDomicilioMensajero == true">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click="salvarNoEntregado" color="purple accent-4" small dark
                      :disabled="!FacturaDomicilioMensajero.ComentarioLeido">
                      <i style="font-size: 20px;" class="fa fa-ban"></i>
                      NO ENTREGADA
                    </v-btn>
                  </template>
                  <span>NO ENTREGADA: No se entrego las factura al cliente </span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click="slvarEntregado" color="light-blue darken-4" small dark
                      :disabled="!FacturaDomicilioMensajero.ComentarioLeido">
                      <i style="font-size: 20px;" class="fa fa-check-circle-o"></i>
                      ENTREGADA
                    </v-btn>
                  </template>
                  <span>ENTREGADA: Se entrego las factura al cliente </span>
                </v-tooltip>
              </div>


            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-row>
            <!--------- columna 1 Crear/editar ---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="4" sm="4" class="pa-0 ma-0 py-0 my-0">
              <!-- 285 id="divSelecProd"   v-bind:style="{  'max-height':this.windowSize.height-400+'px','border': '2px solid #000000' }" class="overflow-x-auto"  -->
              <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b>Ver y Seleccionar mensajero</b></span>
              </v-col>

              <v-col cols="12" md="12" sm="12">

                <v-tooltip top>
                  <template v-slot:activator="{ on }">

                    <v-combobox ref="RefTextFieldBuscaCliente" v-on="on" autocomplete="off" background-color="white"
                      color="grey darken-4" dense outlined label="Buscar Mensajero:" :items="Rmensajero"
                      item-text="nombre" style="font-size: 13px;" v-model="buscarMensajero" @blur="SelctCbBuscarMensajero"
                      @change="getChangeBuscarMensajero" @keyup.enter="SeleccionarClienteFactura"
                      @focus="cargarBuscarMensajero" hide-details :rules="[$rules.required]">
                      <template v-slot:prepend-inner>
                        <i style="font-size: 25px;" class="fa fa-search"></i>
                      </template>
                      <!-- margin-top="50px"style="font-size: 14px;" -->
                      <template slot="item" slot-scope="item">
                        <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                        <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0" cols="12"
                          md="12" sm="12">
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                              {{ item.item.nombre }}</b></p>

                          <span class="pa-0 ma-0 py-0 my-0">
                            <span style="border: 1px dashed  #616161;"><b>TEL.: </b>{{ item.item.telefono }} </span>
                            <span style="border: 1px dashed  #616161;"><b>CED: </b> {{ item.item.cedula }}</span>
                          </span>
                          <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b>SUCURSAL: </b>
                            {{ item.item.sucursal.descripcion }} </p>
                          <!-- <span style="border: 1px dashed  #616161;" v-if="item.item.hasOwnProperty('ars')&&item.item.ars!=null"  ><b> ARS :{{item.item.ars.nombre}}</b></span> -->

                        </v-col>


                      </template>


                    </v-combobox>
                  </template>
                  <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                    <span> Se selecciona el mensajero.</span>
                  </i>
                </v-tooltip>

              </v-col>

              <v-form v-model="valid" ref="formAsignarMensagero" lazy-validation>
                <v-row>
                  <!-- -----------------------------  Codigo -->
                  <v-col cols="12" md="12" sm="12" class="py-0 my-0">
                    <v-text-field id="formdisabledInput" label="Codigo:" dense outlined
                      v-model="MensageroSeleccionado.codigo" :disabled="true" hide-details>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- -----------------------------  Nombre -->
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field id="formdisabledInput" label="Nombre:" autocomplete="off" dense outlined
                      :rules="[$rules.required]" v-model="MensageroSeleccionado.nombre" disabled>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i>
                      </template>
                    </v-text-field>

                  </v-col>
                  <!--  :rules="[$rules.required, $rules.RulesCel]"-->
                  <v-col cols="12" md="12" sm="12" class="py-0 my-0 ">
                    <v-text-field v-mask="'###-#######-#'" label="* Cédula:" autocomplete="off" dense outlined
                      maxlength="13"  v-model="MensageroSeleccionado.cedula">

                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                      </template>

                    </v-text-field>
                  </v-col>


                  <!-- ----------------------------- Télefono -->
                  <v-col cols="12" md="12" sm="12" class="py-0 my-0 ">
                    <v-text-field id="formdisabledInput" label="Teléfono:" dense outlined autocomplete="off"
                      maxlength="13" v-mask="'(###)###-####'" v-model="MensageroSeleccionado.telefono" disabled>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-phone"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- ----------------------------- Meta de Envios -->
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field id="formdisabledInput" label="Meta de Envios:" dense outlined
                      v-model.number="MensageroSeleccionado.metadeenvios" disabled>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-flag-checkered"></i>
                      </template>
                    </v-text-field>

                  </v-col>

                </v-row>
              </v-form>


            </v-col>
            <!--------- fn columna 1 Ver  y Asignar---------->

            <!--------- columna 2 Ver  y Asignar ---------->
            <v-col style="border: 1px solid #000000; " cols="12" md="8" sm="8" class="pa-0 ma-0 py-0 my-0">

              <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span v-if="dialogAsignarMensajeros == true" :class="`d-flex justify-center white--text`"><b>Facturas a
                    domicilio</b></span>
                <span v-if="dialogRecibirMensajeros == true" :class="`d-flex justify-center white--text`"><b>Facturas
                    Mensajero</b></span>
                <span v-if="dialogFacturasRutasMensajeros == true" :class="`d-flex justify-center white--text`"><b>Facturas en rutas</b></span>
                <span v-if="this.dialogCambiarMensajeros== true" :class="`d-flex justify-center white--text`"><b>Cambiar mensajero</b></span>
              </v-col>

              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

                <div v-bind:style="{ 'height': this.windowSize.height - 0 + 'px', 'max-height': this.windowSize.height - 0 + 'px' }"
                  class="overflow-x-auto">

                  <!-- tabla Facturas a domicilio  asignar mensajero -->
                 <div v-if="dialogAsignarMensajeros == true" > 

              <v-row>
                <v-col  cols="12" md="5" sm="5">
                  <v-combobox v-model="filtroAsigMensajero.caja" label="caja" required outlined dense autocomplete="off"
                            :items="RCaja" item-text="descripcion" @blur="SelctCbCajaFiltAsigMensajero" @focus="cargarCaja"
                            @change="filterDataTableAsigMensajeroCaja(RFacturasDomicilio)"
                            style="font-size: 13px;" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-cube"></i>
                            </template>
                          </v-combobox>
              </v-col>
              <v-col   cols="12" md="3" sm="3">
                <v-text-field label="No.Fact:" autocomplete="off" dense outlined 
                          v-model="filtroAsigMensajero.fact" placeholder=" " style="font-size: 12px;"
                          hide-details
                         >
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
              </v-col>

              <v-col cols="12" md="4" sm="4">
                <v-text-field label="sector:" dense outlined
                  autocomplete="off"
                    v-model="filtroAsigMensajero.sector"
                    hide-details
                    >
                  <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i> 
                  </template>
                    </v-text-field>
              </v-col>

              <v-col v-bind:style="{ 'font-size': '15px;'}" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span class="px-4"> <strong style="color:#000000;">Cantidad de registro seleccionado:</strong> {{ selectedAsignarMensajero.length}}  </span>
               </v-col>


            </v-row>

                  <!-- Cliente, Dirección,Sector, Telefono, Devuelta, Valor factura -->
                  <v-data-table  v-model="selectedAsignarMensajero" dense :headers="[
                    { text: '#FACT.', value: 'numeroFactura', sortable: false,filter: filterDataTableAsigMensajeroFact , align: 'center', class: ['no-gutters'] },
                    { text: 'CLIENTE', value: 'cliente', sortable: false, class: ['no-gutters'] },
                    { text: 'DIRECCIÓN', value: 'direccion', sortable: false, class: ['no-gutters'] },
                    { text: 'SECTOR', value: 'cliente.sector', sortable: false,filter: filterDataTableAsigMensajeroSecto, class: ['no-gutters'] },
                    { text: 'TELÉFONO', value: 'telefono', sortable: false, class: ['no-gutters'] },
                    { text: 'CAJA', value: 'caja', sortable: false, class: ['no-gutters'] },
                    { text: 'VALOR FACTURA', value: 'valor', sortable: false, align: 'center', class: ['no-gutters'] },
                    { text: 'DEVUELTA', value: 'devuelta', sortable: false, align: 'center', class: ['no-gutters'] },
                  ]
                  " :items="RFacturasDomicilio" item-key="indx" class="elevation-1" :single-select="false"
                    :show-select="true" :items-per-page="15" :footer-props="arrayPropiedases" 
                    @click:row="obtenerDatoSelectFactDomicilioClickRow"
                    >
                    <!-- :items="filterDataTableAsigMensajeroCaja(RFacturasDomicilio)"  -->
                    <template v-slot:item.numeroFactura="props">
                      <span style="font-size:13px;">{{props.item.numeroFactura}}</span>
                    </template>

                    <template v-slot:item.valor="props">
                      <span style="display:none;">{{ props.item.indx = RFacturasDomicilio.indexOf(props.item) }}</span>
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                    </template>

                    <template v-slot:item.cliente="props">
                      <span style="font-size:13px;" v-if="props.item.cliente != null">{{ props.item.cliente.nombre
                      }}</span>
                    </template>

                    <template v-slot:item.telefono="props">

                      <span style="font-size:13px;" v-if="props.item.cliente != null">{{
                        selectTelefonoCliente(props.item.cliente) }}</span>
                    </template>

                    <template v-slot:item.devuelta="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.devuelta) }}</span>
                    </template>

                    <template v-slot:item.direccion="props">
                      <span style="font-size:13px;">{{ props.item.direccion }}</span>
                    </template>
                  </v-data-table>
                  <!-- fn tabla Facturas a domicilio  asignar mensajero -->
                </div>
                  <!-- informacion de fatura domicilio selecionada -->
                  <v-col v-if="FacturaDomicilioMensajero != null && VerFacturaDomicilioMensajero == true"
                    style="border: 1px solid #000000;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FACTURA #: </b>
                      {{ FacturaDomicilioMensajero.facturasventaPK.secuencia }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
                      {{ FormatoFecha(FacturaDomicilioMensajero.fecha) }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NCF : </b>
                      {{ FacturaDomicilioMensajero.ncf }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CLIENTE : </b>
                      {{ FacturaDomicilioMensajero.cliente.nombre }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> VALOR : </b>
                      {{ currencyFormatter(FacturaDomicilioMensajero.valor) }}</p>
                      <p style="border: 1px dashed  #616161;" v-if="FacturaDomicilioMensajero.devuelta>0" class="pa-0 ma-0 py-0 my-0"><b> DEVUELTA : </b>
                      {{ currencyFormatter(FacturaDomicilioMensajero.devuelta) }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> COMENTARIO : </b>
                      {{ FacturaDomicilioMensajero.comentario }}</p>

                       <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FORMA DE PAGO : </b>
                      {{ TipoFacturaDomicilio(FacturaDomicilioMensajero) }}</p>

                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">
                      <v-checkbox class="mt-0" color="teal" hide-details label="" v-model="FacturaDomicilioMensajero.ComentarioLeido"
                        width="50">
                        <template v-slot:label>
                          <strong v-if="FacturaDomicilioMensajero.ComentarioLeido == true"
                            style="color:#000000; font-size:13px;">Comentario Leido: Si</strong>
                          <strong v-if="FacturaDomicilioMensajero.ComentarioLeido == false"
                            style="color:#000000; font-size:13px;">Comentario Leido: No</strong>
                        </template>
                      </v-checkbox>
                      <div style="border: 1px dashed  #616161;" v-if="verificarCambioPagoDomicilio(FacturaDomicilioMensajero)">
 <v-combobox
 class="pt-2"
            autocomplete="off"
            dense
            outlined
            label=" Cambiar forma de pago:"
            :items="RCambioPagoDomicilio"
            @blur="SelctCbCambioPagoDomicilio"
            item-text="descripcion"
            v-model="cambioPagoDomicilio"  
          >
            <template v-slot:prepend>
              <i style="font-size: 20px;" class="fa fa-money"></i>
            </template>
          </v-combobox>
                      </div>

                      <v-data-table :headers="[
                        { text: 'PRODUCTO', value: 'productos.descripcion', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'DESCUENTO', value: 'valordescuento', sortable: false, class: ['no-gutters black--text'] },
                        { text: 'VALOR', value: 'valor', sortable: false, class: ['no-gutters black--text'] },
                      ]" dense :items="FacturaDomicilioMensajero.facturasventaDetalleList" :items-per-page="15" :footer-props="arrayPropiedases">

                        <template v-slot:item.valor="props">
                          <!-- <span style="display:none;">{{ props.item.valor=(props.item.cantidad *props.item.precio) + props.item.valorimpuesto }}</span> -->
                          <!-- <span style="display:none;">{{ props.item.valor=(RedondeoBasico((props.item.cantidad *props.item.precio)-(props.item.valordescuento)))}}</span> -->
                          
                          <span style="font-size:13px;">{{currencyFormatter(props.item.valor=(RedondeoBasico(DevolucionValor(props.item))))}}
                            
                          </span>
                        </template>

                        <template v-slot:item.productos.descripcion="props">
                          <span style="font-size:13px;">{{ props.item.productos.descripcion }}</span>
                        </template>

                        <template v-slot:item.cantidad="props">
                          <span style="font-size:13px;">{{ props.item.cantidad }}</span>
                        </template>

                        <template v-slot:item.precio="props">
                          <span style="font-size:13px;">{{ currencyFormatter(props.item.precio) }}</span>
                        </template>



                      </v-data-table>


                    </p>

                  </v-col>
                  <!--  fn informacion de fatura domicilio selecionada -->


                  <!--  tabla Facturas Mensajero  -->
                  <v-data-table v-if="dialogRecibirMensajeros == true && VerFacturaDomicilioMensajero == false"
                    v-model="selectedRecibirMensajeros" dense :headers="[
                      { text: 'CLIENTE', value: 'cliente.nombre', sortable: false, class: ['no-gutters'] },
                      { text: 'MENSAJERO', value: 'mensajero.nombre', sortable: false, class: ['no-gutters'] },
                      { text: 'FORMA DE PAGO', value: 'formaPago', sortable: false, class: ['no-gutters'] },
                      { text: 'HORA', value: 'horaPedido', sortable: false, class: ['no-gutters'] },
                      { text: 'VALOR A ENTREGAR', value: 'valor', sortable: false, align: 'center', class: ['no-gutters'] },
                    ]
                    " :items="RRecibirMensajeros" item-key="indx" class="elevation-1" :single-select="true"
                    :show-select="true" @item-selected="obtenerDatoSelectRecibirMensajeros"
                    @click:row="obtenerDatoSelectRecibirMensajerosClickRow" :items-per-page="15" >

                    <template v-slot:item.valor="props">
                      <span style="display:none;">{{ props.item.indx = RRecibirMensajeros.indexOf(props.item) }}</span>
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.valor+props.item.devuelta) }}</span>
                    </template>

                    <template v-slot:item.cliente.nombre="props">
                      <span style="font-size:13px;">{{ props.item.cliente.nombre }}</span>
                    </template>

                    <template v-slot:item.formaPago="props">
                      <span style="font-size:13px;">{{ props.item.formaPago }}</span>
                    </template>

                    <template v-slot:item.mensajero.nombre="props">
                      <span style="font-size:13px;">{{ props.item.mensajero.nombre }}</span>
                    </template>

                    <template v-slot:item.devuelta="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.devuelta) }}</span>
                    </template>

                    <template v-slot:item.horaPedido="props">
                      <span style="font-size:13px;">{{ HoraFormatter(props.item.horaPedido) }}</span>
                    </template>


                    <template v-slot:body.append v-if="windowSize.width > 600">
                        <tr>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-top-bottom"><strong class="d-flex justify-end"
                              style="font-size:15px; color: #00838F;">Total:</strong></td>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-total"><strong class="d-flex justify-center"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totalDomicilio.valor) }} </strong>
                          </td>
                        </tr>
                      </template>
                      <template v-slot:footer v-if="windowSize.width <= 600">
                        <v-list-item style="font-size: 70%;" class="white--text">
                          <v-list-item-content>
                            <span>TOTAL A ENTEGAR:{{ currencyFormatter(totalDomicilio.valor) }}</span>
                          </v-list-item-content>
                        </v-list-item>

                      </template>

                  </v-data-table>
                  <!-- fn tabla Facturas Mensajero -->
<div v-if="dialogFacturasRutasMensajeros == true ">

  <v-col  cols="12" md="8" sm="8">
    <v-text-field label="Nombre:" autocomplete="off" dense outlined 
                          v-model="filtroFacturaRutaCliente" placeholder=" " style="font-size: 12px;"
                          hide-details
                         >
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-user-circle"></i>
                          </template>
                        </v-text-field>

              </v-col>
  <!--  tabla Facturas en ruta  -->
  <v-data-table 
                    dense :headers="[
                      { text: 'FECHA', value: 'fecha', sortable: false, class: ['no-gutters'] },
                      { text: 'HORA', value: 'horaPedido', sortable: false, class: ['no-gutters'] },
                      { text: 'CLIENTE', value: 'cliente.nombre', sortable: false,filter: filterDataTableFacturaRutaCliente, class: ['no-gutters'] },
                      { text: 'DIRECCIÓN', value: 'direccion', sortable: false,width:450 ,class: ['no-gutters'] },
                      { text: 'MENSAJERO', value: 'mensajero.nombre', sortable: false,filter: filterDataTableFacturaRutaMensajero, class: ['no-gutters'] },        
                    ]
                    " :items="RFacturasRutasMensajeros" item-key="indx" class="elevation-1" :single-select="true"
                   
                    :items-per-page="15" >


                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                    </template>

                    <template v-slot:item.horaPedido="props">
                      <span style="font-size:13px;">{{ HoraFormatter(props.item.horaPedido) }}</span>
                    </template>

                    <template v-slot:item.cliente.nombre="props">
                      <span style="display:none;">{{ props.item.indx = RFacturasRutasMensajeros.indexOf(props.item) }}</span>
                      <span style="font-size:13px;">{{ props.item.cliente.nombre }}</span>
                    </template>

                    <template v-slot:item.mensajero.nombre="props">
                      <span style="font-size:13px;">{{ props.item.mensajero.nombre }}</span>
                    </template>

                  </v-data-table>
                  <!-- fn tabla Facturas en ruta  -->
</div>


 <!-- tabla cambiar mensajero -->

 <div v-if="dialogCambiarMensajeros == true" > 

<v-row>
  <v-col  cols="12" md="7" sm="7">
    <v-combobox v-model="nuevoMensajeroSelect" label="Seleccione el nuevo Mensajero" required outlined dense autocomplete="off"
              :items="Rmensajero" item-text="nombre" @blur="SelctCbNuevoMensajero" @focus="cargarBuscarMensajero"
              style="font-size: 13px;" hide-details>
              <template v-slot:prepend>
                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
              </template>
            </v-combobox>
</v-col>
<v-col   cols="12" md="3" sm="3">
                <v-text-field label="No.Fact:" autocomplete="off" dense outlined 
                          v-model="filtroAsigMensajero.fact" placeholder=" " style="font-size: 12px;"
                          hide-details
                         >
                          <template v-slot:prepend>
                            <i style="font-size: 18px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
              </v-col>

</v-row>

    <v-data-table  v-model="selectedCambiarMensajeros" dense :headers="[
      { text: '#FACT.', value: 'numeroFactura', sortable: false,filter: filterDataTableAsigMensajeroFact , align: 'center', class: ['no-gutters'] },
      { text: 'CLIENTE', value: 'cliente', sortable: false, class: ['no-gutters'] },
      { text: 'DIRECCIÓN', value: 'direccion',width:250, sortable: false, class: ['no-gutters'] },
      { text: 'SECTOR', value: 'cliente.sector', sortable: false, class: ['no-gutters'] },
      { text: 'TELÉFONO', value: 'telefono', sortable: false, class: ['no-gutters'] },
      { text: 'VALOR FACTURA', value: 'valor', sortable: false, align: 'center', class: ['no-gutters'] },
      { text: 'DEVUELTA', value: 'devuelta', sortable: false, align: 'center', class: ['no-gutters'] },
    ]
    " :items="RRecibirMensajeros" item-key="indx" class="elevation-1" :single-select="false"
      :show-select="true" :items-per-page="15" :footer-props="arrayPropiedases" 
      @click:row="obtenerDatoSelectCambioMensajeroClickRow"
      >

      <template v-slot:item.numeroFactura="props">
        <span style="font-size:13px;">{{props.item.numeroFactura}}</span>
      </template>

      <template v-slot:item.valor="props">
        <span style="display:none;">{{ props.item.indx = RRecibirMensajeros.indexOf(props.item) }}</span>
        <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
      </template>

      <template v-slot:item.cliente="props">
        <span style="font-size:13px;" v-if="props.item.cliente != null">{{ props.item.cliente.nombre
        }}</span>
      </template>

      <template v-slot:item.telefono="props">

        <span style="font-size:13px;" v-if="props.item.cliente != null">{{
          selectTelefonoCliente(props.item.cliente) }}</span>
      </template>

      <template v-slot:item.devuelta="props">
        <span style="font-size:13px;">{{ currencyFormatter(props.item.devuelta) }}</span>
      </template>

      <template v-slot:item.direccion="props">
        <span style="font-size:13px;">{{ props.item.direccion }}</span>
      </template>

    </v-data-table>
    </div>
    <!-- fn tabla cambiar mensajero -->
  



                  <!-- <v-data-table 
     dense
     hide-default-footer
     hide-default-header
     :items-per-page="-1" 
     
          item-key="indx"
          
     :headers="[
     {text: 'CODIGO', value: 'productos.codigo' ,sortable: false, class: ['no-gutters']},
     {text: 'PRODUCTO', value: 'productos.descripcion' ,sortable: false, class: ['no-gutters']},
     {text: 'COSTO', value: 'costo' ,sortable: false, class: ['no-gutters']},
     {text: 'PRECIO', value: 'precio' ,sortable: false, class: ['no-gutters']},
     {text: 'EXISTENCIA', value: 'existencias' ,sortable: false, align:'center', class: ['no-gutters']},     
    ]
    " :items="RHistorialCompracliente" class="elevation-1">

    <template v-slot:item="props"> 
    <span style="display:none;">{{props.item.indx = RHistorialCompracliente.indexOf(props.item)}}</span>
     <tr >
              <td  @click.prevent="EditarProductoSelect(props.item)"  v-ripple="{ class: `indigo--text` }" >
           <span style="display:none;" v-if="!props.item.hasOwnProperty('cantidad')">{{props.item.cantidad=1}}</span>
              <v-row>
              <v-col class="pa-0 ma-0"  cols="12" md="12"><span><b>PRODUCTO: {{props.item.productos.descripcion}} </b></span></v-col>
              <v-col class="pa-0 ma-0"  cols="2" md="2"><span> {{ FormatoFecha(props.item.productos.fechaCreacion)}}</span></v-col>
              <v-col class="pa-0 ma-0"  cols="2" md="2"><span :class="`d-flex justify-center`" > {{ currencyFormatter(props.item.cantidad)}}</span></v-col>
              <v-col class="pa-0 ma-0"  cols="4" md="4"><span :class="`d-flex justify-center`" > {{ currencyFormatter(props.item.precio)}}</span></v-col>
              <v-col class="pa-0 ma-0"  cols="4" md="4"><span :class="`d-flex justify-center`" > {{ props.item.codigobarra}}</span></v-col>
             </v-row>
              </td>
    </tr>

     </template>
    </v-data-table> -->



                </div>
                <!-------------------------------------------------- fn tb Facturas domicilio-->



              </v-col>


            </v-col>
            <!--------- fn columna 2 Ver  y Asignar---------->

          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <!--------------------------------------------------------  fn Modal- Ver  y Asignar mensajero -->




    <!-------------------------------------------------------- Modal- Modal-Crear editar cliente -->
    <v-dialog label="Modal Crear editar cliente  " v-model="dialogCrearEditarCliente" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Crear / Editar
                Cliente</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Crear / Editar Cliente: </span> Crea
              , Modificar cliente y muestra el historial de compra</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="CancelarCrearEditarCliente()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="NewclienteSeleccionado" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarCrearEditarCliente" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Crea o edita cliente y retornar atrás </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-row>
            <!--------- columna 1 Crear/editar ---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <!-- 285 id="divSelecProd"   v-bind:style="{  'max-height':this.windowSize.height-400+'px','border': '2px solid #000000' }" class="overflow-x-auto"  -->
              <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b>Crear y modificar</b></span>
              </v-col>

              <v-col cols="12" md="12" sm="12">
                <v-form ref="formBuscarCliente" v-model="valid" lazy-validation>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox ref="RefTextFieldBuscaCliente" v-on="on" autocomplete="off" background-color="white"
                        color="grey darken-4" dense outlined label="Buscar cliente:" :items="RCliente" item-text="codnom"
                        style="font-size: 13px;" v-model="busquedaCliente" @blur="SelctCbClienteBuscar"
                        @change="getChangeClienteBusqueda(busquedaCliente)" @keyup.enter="SeleccionarClienteFactura"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" hide-details :rules="[$rules.required]" no-filter
                        v-on:change="changeCbTipoComprobante(clienteSeleccionado.tipocomprobante,'RefTextFieldcedrnc')">
                        <template v-slot:prepend-inner>
                          <i style="font-size: 25px;" class="fa fa-search"></i>
                        </template>
                        <!-- margin-top="50px"style="font-size: 14px;" -->
                        <template slot="item" slot-scope="item">
                          <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                          <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>
                            style="border: 1px dashed  #616161;"
                              -->
                          <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0"
                            cols="12" md="12" sm="12">
                            <p class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                                {{ item.item.nombre }}</b></p>

                            <span class="pa-0 ma-0 py-0 my-0">
                              <span><b>TEL.: </b>
                                {{ selectTelefonoCliente(item.item) }}</span>
                              <span><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                            </span>
                            <p v-if="item.item.hasOwnProperty('pasaporte') && item.item.pasaporte != null && item.item.pasaporte.length>0" class="pa-0 ma-0 py-0 my-0"><b>PASAPORTE: </b>{{ item.item.pasaporte }} </p>
                            <p class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                              {{ item.item.direccion }} </p>
                            <span v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                                :{{ item.item.ars.nombre }}</b></span>
                            <span v-if="item.item.diascredito > 0"><b> TIPO:
                                Cliente
                                Credito</b></span>
                            <span v-if="item.item.diascredito == 0"><b>TIPO:
                                Cliente
                                Contado</b></span>
                          </v-col>


                        </template>


                        <template v-slot:append-outer>

                          <v-tooltip top>
                            <template v-slot:activator="{ on }">

                              <v-btn @click.prevent="SeleccionarClienteFactura" v-on="on" color="indigo lighten-2"
                                outlined small dark>
                                <!-- <i style="font-size: 20px;" class="fa fa-plus-square"></i> -->

                                <v-avatar tile size="28" color="transparent">
                                  <img src="@/assets/icons_pv/extras/seleccionar48.png" alt="Asignar cliente">
                                </v-avatar>


                              </v-btn>

                            </template>
                            <span>Asignar cliente buscado a la factura</span>
                          </v-tooltip>

                        </template>



                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea editar.</span>
                    </i>
                  </v-tooltip>

                </v-form>
              </v-col>

              <v-form v-model="valid" ref="formCrearEditarCliente" lazy-validation>
                <v-row>
                  <!-- -----------------------------  Codigo -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-text-field id="formdisabledInput" label="Codigo:" dense outlined
                      v-model="clienteSeleccionado.codigo" :disabled="true" hide-details>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                   <!-- -----------------------------  comentario -->
                   <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-textarea autocomplete="off" label="comentario:" rows="1" dense outlined
                          v-model="clienteSeleccionado.comentario">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-commenting"></i>
                          </template>
                        </v-textarea>
                  </v-col>

                  <!-- -----------------------------  Nombre -->
                  <v-col cols="12" md="6" sm="6">
                    <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                      v-model="clienteSeleccionado.nombre" :disabled="validarDisabledClienteCredito(busquedaCliente)"
                      id="formdisabledInput">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i>
                      </template>
                    </v-text-field>


                  </v-col>

                  <!-- ----------------------------- cedula/rnc -->
                  <v-col cols="12" md="6" sm="6">

                    <v-text-field v-if="cedularequired==false" autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense outlined maxlength="13"
                      :rules="[$rules.RulesCelRncValidar]" v-model="clienteSeleccionado.cedrnc" @blur="MakaraCel"
                      @focus="MakCelRnc = '#############'" v-on:blur="validandoCedRnc(clienteSeleccionado.cedrnc)">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                      </template>
                    </v-text-field>

                    <v-text-field ref="RefTextFieldcedrnc"  v-if="cedularequired==true" autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense outlined maxlength="13"
                      :rules="[$rules.required,$rules.RulesCelRncValidar]" v-model="clienteSeleccionado.cedrnc" @blur="MakaraCel" v-on:blur="validandoCedRnc(clienteSeleccionado.cedrnc)"
                      @focus="MakCelRnc = '#############'">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                      </template>
                    </v-text-field>

                  </v-col>
<!-- -----------------------------  Pasaporte -->
<v-col cols="12" md="6" sm="6" class="py-0 my-0">
  <v-text-field label="Pasaporte:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.pasaporte"
                          maxlength="19">
                          <template v-slot:prepend>
                            <i style="font-size: 20px" class="fa fa-address-card"></i>
                          </template>
                        </v-text-field>

</v-col>


                  <!-- -----------------------------  Direccion -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-textarea autocomplete="off" label="* Dirección:" rows="1" dense outlined
                      v-model="clienteSeleccionado.direccion" :rules="[$rules.required]"
                      :disabled="validarDisabledClienteCredito(busquedaCliente)"
                      id="formdisabledInput">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                      </template>
                    </v-textarea>
                  </v-col>



                  <!-- ----------------------------- Télefono -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0 ">
                    <v-text-field label="Télefono:" dense outlined autocomplete="off" maxlength="13"
                      v-mask="'(###)###-####'" v-model="clienteSeleccionado.telefono"
                      :rules="[$rules.required,$rules.RulesTel]" 
                      >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-phone"></i>
                      </template>
                    </v-text-field>

                  </v-col>

                   <!-- -----------------------------  Direccion 2 -->
                   <!-- :disabled="validarDisabledClienteCredito(busquedaCliente)"  -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-textarea autocomplete="off" label=" Dirección2:" rows="1" dense outlined
                      v-model="clienteSeleccionado.direccion2"
                      id="formdisabledInput"
                       >
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                      </template>
                    </v-textarea>
                  </v-col>

                  <!-- -----------------------------  email -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field label="E-mail:" dense outlined v-model="clienteSeleccionado.email">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                      </template>
                    </v-text-field>

                  </v-col>

                  <!-- ----------------------------- fecha de nacimiento   -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field dense outlined type="date" label="Fecha de Nacimiento:"
                      v-model="clienteSeleccionado.fechanacimiento">

                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-calendar"></i>
                      </template>

                    </v-text-field>

                  </v-col>




                  <!-- ----------------------------- Ciudad-->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-text-field label="* Ciudad:" dense outlined autocomplete="off" v-model="clienteSeleccionado.ciudad"
                      :rules="[$rules.required]">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map"></i>
                      </template>
                    </v-text-field>
                  </v-col>


                  <!-- -----------------------------sector -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field label="sector:" dense outlined autocomplete="off" v-model="clienteSeleccionado.sector"
                      :rules="[$rules.required]">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                      </template>
                    </v-text-field>

                  </v-col>


                  <!-- ----------------------------- Dia de credito -->
                  <v-col v-if="false" cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field label="Días de Crédito" dense outlined v-model="clienteSeleccionado.diascredito"
                      @keyup="KeyUpCantidad">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                      </template>
                    </v-text-field>
                  </v-col>



                  <!-- ----------------------------- tipocomprobante -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-combobox autocomplete="off" dense outlined label="* Tipos de Comprobante:"
                      :items="filterTipoComprobantePvItbis(RtipoComprobante)" :rules="[$rules.required]" item-text="descripcion"
                      v-model="clienteSeleccionado.tipocomprobante" @blur="SelctCbTipoComprobante"
                      @focus="cargarTipoComprobante"
                      @change="changeCbTipoComprobante(clienteSeleccionado.tipocomprobante,'RefTextFieldcedrnc')"
                      :disabled="validarDisabledClienteCredito(busquedaCliente)" id="formdisabledInput">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-tag"></i>
                      </template>
                    </v-combobox>

                  </v-col>




                  <!-- -----------------------------sexo -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <!-- <v-text-field label="Sexo:" dense outlined
                      autocomplete="off"
                        v-model="clienteSeleccionado.sexo"
                        :rules="[$rules.required]"
                        >
                      <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-venus-mars"></i> 
                      </template>
                        </v-text-field> -->

                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="py-0 my-0"
                    v-if="$store.getters.GetdatosInfUsu.usuario.micromarket == false">
                    <hr style="border: 1px solid #000000;">
                    <br>
                    <v-row>
                      <!-- ----------------------------- Número de Afiliado  -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field v-if="clienteSeleccionado.ars==null" label="Número de Afiliado:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.carnet">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                        <v-text-field v-if="clienteSeleccionado.ars!=null" label="Número de Afiliado:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.carnet" :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                      </v-col>
                      <!-- ----------------------------- ARS -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-combobox autocomplete="off" dense outlined label="ARS:" :items="RArs" item-text="nombre"
                          v-model="clienteSeleccionado.ars" @blur="SelctCbArsClient" @focus="cargarArs">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shield"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                      <!-- -----------------------------Donde Trabaja  -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field label="Donde Trabaja:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.dondetrabaja">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-building-o"></i>
                          </template>
                        </v-text-field>


                      </v-col>
                      <!-- ----------------------------- Número de Contrato -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field label="Número de Contrato:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.contrato">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <!-- ----------------------------- Número de NSS -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                        <v-text-field label="Número de NSS:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.nss">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-slack"></i>
                          </template>
                        </v-text-field>

                      </v-col>

                      <!-- ----------------------------- Plan  lan -->
                      <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                        <v-text-field label="Plan  lan:" autocomplete="off" dense outlined
                          v-model="clienteSeleccionado.planlan">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-sign-language"></i>
                          </template>
                        </v-text-field>

                      </v-col>


                    </v-row>
                  </v-col>






                </v-row>

              </v-form>




            </v-col>
            <!--------- fn columna 1 Crear/editar ---------->


            <!--------- columna 2 Crear/editar ---------->
            <v-col style="border: 1px solid #000000; " cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">

              <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b>Historial de compras del cliente</b></span>
              </v-col>

              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

                <!--------------------------------------------------  Header tb Historial compra cliente -->
                <!-- <v-col style="background-color:#3d6687;" class="py-0 my-0" cols="12" md="12" sm="12">
                      <v-row style="border: 1px solid #000000;">
                        <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`">Fecha</b>
                        </v-col>
                        <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`">
                            Cantidad</b></v-col>
                        <v-col class="pa-0 ma-0" cols="4" md="4"><b :class="`d-flex justify-center white--text`"> Precio
                          </b></v-col>
                        <v-col class="pa-0 ma-0" cols="4" md="4"><b :class="`d-flex justify-center white--text`"> No.Factura
                          </b></v-col>
                      </v-row>
                    </v-col> -->
                <!-------------------------------------------------- fn Header tb Historial compra cliente  -->
                <!-------------------------------------------------- tb Historial compra cliente 
    :single-select="true" :show-select="true" 
    -->
                <!--  hide-default-header  -->
                <div
                  v-bind:style="{ 'height': this.windowSize.height - 30 + 'px', 'max-height': this.windowSize.height - 30 + 'px' }"
                  class="overflow-x-auto">
                  <v-data-table dense item-key="indx" :headers="[
                    { text: 'FECHA', value: 'fecha', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'PRODUCTO', value: 'producto', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'No.FACTURA', value: 'factura', sortable: false, class: ['no-gutters black--text'] },
                  ]
                  " :items="RHistorialCompracliente" class="elevation-1 TbSize13" :footer-props="arrayPropiedases"
                    :items-per-page="TbPaginatorHisCli.itemsPerPage" @pagination="cargarPaginacionAllHisCli"
                    :server-items-length="TbTotalItemHisCli" :page="TbPaginatorHisCli.page"
                    :pageCount="TbPaginatorHisCli.pageCount">

                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                    </template>

                    <template v-slot:item.precio="props">
                      <span style="font-size:13px;">{{ currencyFormatter2(props.item.precio) }}</span>
                    </template>


                    <!-- EditarProductoSelect(props.item,false) -->
                    <!-- <template v-slot:item="props">
                          <span style="display:none;">{{ props.item.indx =
                          RHistorialCompracliente.indexOf(props.item)
                          }}</span>
                          <tr>
                           
                            <td @click.prevent="" v-ripple="{ class: `indigo--text` }">
                              <span style="display:none;" v-if="!props.item.hasOwnProperty('cantidad')">{{
                              props.item.cantidad = 1
                              }}</span>
                              <v-row>
                                <v-col class="pa-0 ma-0" cols="12" md="12"><span><b>PRODUCTO:
                                      {{ props.item.productos.descripcion }} </b></span></v-col>
                                <v-col class="pa-0 ma-0" cols="2" md="2"><span> {{
                                FormatoFecha(props.item.productos.fechaCreacion)
                                }}</span></v-col>
                                <v-col class="pa-0 ma-0" cols="2" md="2"><span :class="`d-flex justify-center`"> {{
                                currencyFormatter(props.item.cantidad)
                                }}</span></v-col>
                                <v-col class="pa-0 ma-0" cols="4" md="4"><span :class="`d-flex justify-center`"> {{
                                currencyFormatter(props.item.precio)
                                }}</span></v-col>
                                <v-col class="pa-0 ma-0" cols="4" md="4"><span :class="`d-flex justify-center`"> #{{
                                props.item.facturasventaDetallePK.secuencia
                                }}</span></v-col>
                              </v-row>
                            </td>
                          </tr>
                        </template> -->
                  </v-data-table>
                </div>
                <!-------------------------------------------------- fn tb Historial compra cliente-->



              </v-col>


            </v-col>
            <!--------- fn columna 2 Crear/editar ---------->

          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <!--------------------------------------------------------  fn Modal- Crear editar cliente -->

    <!-------------------------------------------------------- Modal-Reimprecion punto de venta-->
    <v-dialog label="Modal Reimprecion " v-model="dialogReimprecion" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      
      <v-card class="mx-auto">
<div v-if="dialogReimprecion"  v-dirkeydown="finalizarReimprimirKeyEsc">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Reimpresión
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Reimpresión: </span> Recibo, Otros
              ingresos, Factura, Devolución, Vale de caja, Cotización</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions v-if="windowSize.width > 600">
            <v-btn-toggle>
              <!-- Reimprimir btn Canselar max-height: 130px; ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">

                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ListReimprecion = [], NewReimprecionFacturaSeleccionada(), BtnReimprecionvalue = 0, dialogReimprecion = false"
                      v-on="on" height="45px" min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>


                        <v-avatar tile size="30" color="transparent">
                          <i v-bind:style="{ 'font-size': '30px', 'color': hover ? 'blcak' : 'white' }"
                            class="fa fa-reply-all"></i>
                        </v-avatar>

                        <!-- CANCELAR: Cancela y retornar atrás, class="fa fa-reply-all"
        <br>
    <b v-bind:style="{  'font-size':'10px', 'color': hover ? 'white':'#000000'  }">Recibo</b> -->
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cancela y retornar atrás</b> </span>
              </v-tooltip>


              <!-- Reimprimir btn recibo ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ReimpresionOpciones('AccReimpresionOpcRecibo')" v-on="on" height="45px"
                      min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>

                        <v-badge overlap color="teal">
                          <template v-slot:badge>
                            <i v-if="BtnReimprecionvalue == 1" style="font-size: 10px;" class="fa fa-check"></i>
                          </template>

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/menu/recibo64.png" alt="Recibo">
                          </v-avatar>
                        </v-badge>

                        <br>
                        <b v-if="BtnReimprecionvalue != 1"
                          v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Recibo</b>
                        <b v-if="BtnReimprecionvalue == 1" class='white--text'>Recibo</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Recibo de ingresos </b> </span>
              </v-tooltip>

              <!-- Reimprimir btn Otros ingresos ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ReimpresionOpciones('AccReimpresionOpcOtrosingresos')" v-on="on" height="45px"
                      min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>

                        <v-badge overlap color="teal">
                          <template v-slot:badge>
                            <i v-if="BtnReimprecionvalue == 2" style="font-size: 10px;" class="fa fa-check"></i>
                          </template>

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/menu/otrosIngresos64.png" alt="Otros ingresos">
                          </v-avatar>
                        </v-badge>
                        <br>
                        <b v-if="BtnReimprecionvalue != 2"
                          v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Otros
                          ingresos</b>
                        <b v-if="BtnReimprecionvalue == 2" class='white--text'>Otros ingresos</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Otros ingresos </b> </span>
              </v-tooltip>

              <!-- Reimprimir btn Factura ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ReimpresionOpciones('AccReimpresionOpcFactura')" v-on="on" height="45px"
                      min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>

                        <v-badge overlap color="teal">
                          <template v-slot:badge>
                            <i v-if="BtnReimprecionvalue == 3" style="font-size: 10px;" class="fa fa-check"></i>
                          </template>

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/menu/factura64.png" alt="Factura">
                          </v-avatar>
                        </v-badge>
                        <br>
                        <b v-if="BtnReimprecionvalue != 3"
                          v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Factura</b>
                        <b v-if="BtnReimprecionvalue == 3" class='white--text'>Factura</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Factura</b></span>
              </v-tooltip>


              <!-- Reimprimir btn Devolución ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ReimpresionOpciones('AccReimpresionOpcDevolución')" v-on="on" height="45px"
                      min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>

                        <v-badge overlap color="teal">
                          <template v-slot:badge>
                            <i v-if="BtnReimprecionvalue == 4" style="font-size: 10px;" class="fa fa-check"></i>
                          </template>

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/menu/devolucion64.png" alt="Devolución">
                          </v-avatar>
                        </v-badge>
                        <br>
                        <b v-if="BtnReimprecionvalue != 4"
                          v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Devolución</b>
                        <b v-if="BtnReimprecionvalue == 4" class='white--text'>Devolución</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Devolución</b> </span>
              </v-tooltip>


              <!-- Reimprimir btn Vale de caja ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ReimpresionOpciones('AccReimpresionOpcValeCaja')" v-on="on" height="45px"
                      min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>

                        <v-badge overlap color="teal">
                          <template v-slot:badge>
                            <i v-if="BtnReimprecionvalue == 5" style="font-size: 10px;" class="fa fa-check"></i>
                          </template>

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/menu/valeCaja64.png" alt="Vale de caja">
                          </v-avatar>
                        </v-badge>

                        <br>
                        <b v-if="BtnReimprecionvalue != 5"
                          v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Vale de caja</b>
                        <b v-if="BtnReimprecionvalue == 5" class='white--text'>Vale de caja</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Vale de caja </b> </span>
              </v-tooltip>


              <!-- Reimprimir btn Cotización ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ReimpresionOpciones('AccReimpresionOpcCotizacion')" v-on="on" height="45px"
                      min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>

                        <v-badge overlap color="teal">
                          <template v-slot:badge>
                            <i v-if="BtnReimprecionvalue == 6" style="font-size: 10px;" class="fa fa-check"></i>
                          </template>

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                          </v-avatar>
                        </v-badge>

                        <br>
                        <b v-if="BtnReimprecionvalue != 6"
                          v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Cotización</b>
                        <b v-if="BtnReimprecionvalue == 6" class='white--text'>Cotización</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Cotización </b> </span>
              </v-tooltip>


              <!-- Reimprimir btn Conduce ------>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-hover v-slot:default="{ hover }" close-delay="200">
                    <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                      :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }"
                      @click.prevent="ReimpresionOpciones('AccReimpresionOpcConduce')" v-on="on" height="45px"
                      min-width="105px" dark>
                      <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                      <span>

                        <v-badge overlap color="teal">
                          <template v-slot:badge>
                            <i v-if="BtnReimprecionvalue == 7" style="font-size: 10px;" class="fa fa-check"></i>
                          </template>

                          <v-avatar tile size="30" color="transparent">
                            <img src="@/assets/icons_pv/menu/Conduce2.png" alt="Conduce">
                          </v-avatar>
                        </v-badge>

                        <br>
                        <b v-if="BtnReimprecionvalue != 7"
                          v-bind:style="{ 'font-size': '10px', 'color': hover ? 'white' : '#BDBDBD' }">Conduce</b>
                        <b v-if="BtnReimprecionvalue == 7" class='white--text'>Conduce</b>
                      </span>
                    </v-btn>
                  </v-hover>
                </template>
                <span><b> Conduce </b> </span>
              </v-tooltip>


            </v-btn-toggle>
          </v-card-actions>


          <v-card-actions v-if="windowSize.width <= 600">

            <v-btn @click.prevent="BtnReimprecionvalue = 1, dialogReimprecion = false" v-bind="attrs"
              color="transparent" outlined dark>
              <i v-bind:style="{ 'font-size': '30px', 'color': hover ? 'white' : '#BDBDBD' }" class="fa fa-reply-all"></i>
            </v-btn>

            <!-- Reimprimir btnes Movil; ------>
            <v-bottom-sheet v-model="ReimprecionSheet">
              <template v-slot:activator="{ on, attrs }">

                <v-btn v-bind="attrs" v-on="on" color="teal darken-2" outlined fab x-small dark>
                  <i style="font-size: 20px;" class="fa fa-list-ul"></i>
                </v-btn>
              </template>
              <v-list>

                <v-subheader class="d-flex justify-center"> <b> Funciones de Reimpreción </b>


                </v-subheader>

                <!-- 32px -->
                <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcRecibo')">
                  <v-list-item-avatar tile size="32">
                    <img src="@/assets/icons_pv/menu/recibo64.png" alt="Recibo">
                    <!-- <i style="font-size: 32px; color:#00796B; " class="fa fa-plus-square"></i> -->
                  </v-list-item-avatar>
                  <v-list-item-title>Recibo</v-list-item-title>
                </v-list-item>

                <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcOtrosingresos')">
                  <v-list-item-avatar tile size="32">
                    <img src="@/assets/icons_pv/menu/otrosIngresos64.png" alt="Otros ingresos">

                  </v-list-item-avatar>
                  <v-list-item-title>Otros ingresos</v-list-item-title>
                </v-list-item>

                <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcFactura')">
                  <v-list-item-avatar tile size="32">
                    <img src="@/assets/icons_pv/menu/factura64.png" alt="Factura">
                  </v-list-item-avatar>
                  <v-list-item-title>Factura</v-list-item-title>
                </v-list-item>

                <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcDevolución')">
                  <v-list-item-avatar tile size="32">
                    <img src="@/assets/icons_pv/menu/devolucion64.png" alt="Devolución">
                  </v-list-item-avatar>
                  <v-list-item-title>Devolución</v-list-item-title>
                </v-list-item>

                <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcValeCaja')">
                  <v-list-item-avatar tile size="32">
                    <img src="@/assets/icons_pv/menu/valeCaja64.png" alt="Vale de caja">
                  </v-list-item-avatar>
                  <v-list-item-title>Vale de caja</v-list-item-title>
                </v-list-item>

                <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcCotizacion')">
                  <v-list-item-avatar tile size="32">
                    <img src="@/assets/icons_pv/menu/cotizacion64.png" alt="Cotización">
                  </v-list-item-avatar>
                  <v-list-item-title>Cotización</v-list-item-title>
                </v-list-item>

                <v-list-item @click.prevent="ReimpresionOpciones('AccReimpresionOpcConduce')">
                  <v-list-item-avatar tile size="32">
                    <img src="@/assets/icons_pv/menu/Conduce2.png" alt="Conduce">
                  </v-list-item-avatar>
                  <v-list-item-title>Conduce</v-list-item-title>
                </v-list-item>


              </v-list>
            </v-bottom-sheet>
          </v-card-actions>

        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <b :class="`d-flex justify-center`"> {{ LabelReimprecion }}</b>
            <br>

            <v-form v-show="BtnReimprecionvalue != 0" ref="formReimprecion" @submit.prevent="" v-model="valid"
              lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="ReimpresionBusqueda.secuencia" style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="ReimpresionBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="ReimpresionBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta" @blur="SelctCbClienteBuscar" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" ref="RefCombClienteReImprimir" autocomplete="off" dense outlined
                        label="cliente:" :items="RCliente" item-text="codnom" style="font-size: 13px;"
                        v-model="ReimpresionBusqueda.cliente" @focus="cargarClienteBusqueda" @keyup="CbFilcliente" no-filter>

                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre" v-if="item.hasOwnProperty('codigo')" ><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                    <span class="fontSize13 colorNombre" v-if="item!=null && !item.hasOwnProperty('codigo')" >{{item}}</span>
                  </template>


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getReimprecionlistFactCliente" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-col v-show="BtnReimprecionvalue != 0" cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">

                <!-- ReimprecionCargarPaginacion
    ReimprecionSelected,ReimprecionObtenerDatoSelect,ReimprecionlistFactCliente,ReimprecionCargarPaginacion,
    ReimprecionDatostabla,ReimprecionTotalRegistros
    ReimprecionPaginator -->
                <!-- @click:row="datostabla" -->

                <div v-if="ReimprecionFacturaSeleccionada.secuencia == 0">

                  <!-- Tabla Reimprecion Factura  @pagination="ReimprecionCargarPaginacion"
    :items-per-page="ReimprecionPaginator.itemsPerPage" :server-items-length="TotalRegistros"    -->
                  <v-data-table class="RowSelectColor" :single-select="true" item-key="indx" :show-select="true"
                    v-model="ReimprecionSelected" @item-selected="ReimprecionObtenerDatoSelect"
                    @click:row="ReimprecionDatostabla" :headers="[{ text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                    { text: '# Secuencia', sortable: false, value: 'secdoc', width: 129 },
                    { text: 'Cliente', sortable: false, value: 'nombre', width: 129 },
                    { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 }]" dense
                    :items="ListReimprecion" @pagination="ReimprecionCargarPaginacion"
                    :items-per-page="ReimprecionPaginator.itemsPerPage" :server-items-length="TotalRegistros"
                    :footer-props="arrayPropiedases">
                    <template v-slot:item.action="{ item }">
                      <!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
                      <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;"
                          class="fa fa-trash-o"></i></v-btn>

                    </template>

                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =
                      ListReimprecion.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                    </template>

                    <template v-slot:item.documentoFactura.descripcion="props">
                      <span style="font-size:13px;">{{ props.item.documentoFactura.descripcion }} </span>
                    </template>


                    <template v-slot:item.secdoc="props">
                      <span style="font-size:13px;">{{ props.item.secdoc }} </span>
                    </template>
                    <template v-slot:item.ncf="props">
                      <span style="font-size:13px;">{{ props.item.ncf }} </span>
                    </template>

                    <template v-slot:item.nombre="props">
                      <span v-if="props.item.nombre != null" style="font-size:13px;">{{ props.item.nombre }} </span>
                    </template>

                    <template v-slot:item.sucursal.descripcion="props">
                      <span v-if="props.item.sucursal != null" style="font-size:13px;">{{
                        props.item.sucursal.descripcion
                      }}
                      </span>
                    </template>


                    <template v-slot:item.valor="props">
                     <!-- <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>-->
                      <span style="font-size:13px;">{{ currencyFormatter2TasaInf(getTasaInfo(props.item.valor,props.item.tasa),props.item.tasa) }}</span>
                    </template>
                  </v-data-table>
                  <!-- fn Tabla Reimprecion Factura -->

                </div>





                <!-- informacion de fatura  selecionada reimprecion -->
                <v-col v-if="ReimprecionFacturaSeleccionada.secuencia > 0" style="border: 1px solid #000000;"
                  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                  <v-row>
                    <v-col cols="12" md="8" sm="8">
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FACTURA #: </b>
                        {{ ReimprecionFacturaSeleccionada.secdoc }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
                        {{ FormatoFecha(ReimprecionFacturaSeleccionada.fecha) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NCF : </b>
                        {{ ReimprecionFacturaSeleccionada.ncf }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CLIENTE : </b>
                        {{ ReimprecionFacturaSeleccionada.cliente }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> VALOR : </b>
                        {{ currencyFormatter2TasaInf(getTasaInfo(ReimprecionFacturaSeleccionada.valor,ReimprecionFacturaSeleccionada.tasa),ReimprecionFacturaSeleccionada.tasa)  }}
                      </p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> COMENTARIO : </b>
                        {{ ReimprecionFacturaSeleccionada.comentario }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> TURNO : </b>
                        {{ ReimprecionFacturaSeleccionada.turno }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FORMA DE PAGO : </b>
                        {{ ReimprecionFacturaSeleccionada.formapago }}</p>
                         <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="ReimprecionFacturaSeleccionada.moneda!=null"><b> MONEDA : </b>
                        ${{ ReimprecionFacturaSeleccionada.moneda.nombrecorto.trim() }}</p>
                    </v-col>
                    <v-col cols="12" md="4" sm="4">

                      <div style="border: 1px dashed  #616161;" class="d-flex justify-center">
                        <p style="font-size:20px;" class="pa-0 ma-0 py-0 my-0"><b> ¿DESEA {{ LabelReimprecion }}?</b>
                        </p>
                      </div>
                      <br>
                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'red darken-3' : 'red'" v-ripple="{ class: `white--text` }"
                          @click.prevent="NewReimprecionFacturaSeleccionada">

                          <h2>CANCELAR</h2>

                        </v-btn>
                      </v-hover>

                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'blue darken-3' : 'blue'" v-ripple="{ class: `white--text` }"
                          @click.prevent="ImpriReimprimirFactura">

                          <h2>IMPRIMIR</h2>

                        </v-btn>
                      </v-hover>
                    </v-col>
                  </v-row>
                  <p style="border: 1px dashed  #616161;">

                    <!-- Tabla ReimprecionFacturaSeleccionada de factura -->
                    <v-data-table v-if="BtnReimprecionvalue == 1" :headers="[
                      { text: 'FECHA', value: 'fecha', sortable: false, class: ['no-gutters'] },
                      { text: 'FACTURA', value: 'factura', sortable: false, class: ['no-gutters'] },
                      { text: 'VALOR ORIGINAL', value: 'valororiginal', sortable: false, class: ['no-gutters'] },
                      { text: 'ABONO', value: 'abono', sortable: false, class: ['no-gutters'] },
                      { text: 'PENDIENTE', value: 'pendientedespues', sortable: false, class: ['no-gutters'] },
                    ]" dense :items="ReimprecionFacturaSeleccionada.DetalleList" :items-per-page="15">

                      <template v-slot:item.fecha="props">
                        <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                      </template>

                      <template v-slot:item.factura="props">
                        <span v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null"
                          style="font-size:13px;">
                          {{ props.item.facturasventa.facturasventaPK.secuencia }}
                        </span>

                        <span
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null"
                          style="font-size:13px;">
                          {{ props.item.notadebitoclientes.notadebitoclientesPK.secuencia }}
                        </span>
                      </template>

                      <template v-slot:item.valororiginal="props">

                        <span v-if="props.item.hasOwnProperty('facturasventa') && props.item.facturasventa != null"
                          style="font-size:13px;">
                          {{ currencyFormatter(props.item.facturasventa.valor) }}
                          <!-- <span style="display:none;">{{props.item.pendiente=props.item.facturasventa.valor-props.item.abono}}</span> -->
                        </span>
                        <span
                          v-if="props.item.hasOwnProperty('notadebitoclientes') && props.item.notadebitoclientes != null"
                          style="font-size:13px;">
                          {{ props.item.notadebitoclientes.valor }}
                          <!-- <span style="display:none;">{{props.item.pendiente=props.item.notadebitoclientes.valor-props.item.abono}}</span> -->
                        </span>
                      </template>

                      <template v-slot:item.abono="props">
                        <span style="font-size:13px;">{{ currencyFormatter(props.item.abono) }}</span>
                      </template>

                      <template v-slot:item.pendiente="props">
                        <!-- <span style="font-size:13px;">{{ currencyFormatter( props.item.pendiente) }}</span> -->
                        <span style="font-size:13px;">{{ currencyFormatter(props.item.pendiente) }}</span>
                      </template>

                      <template v-slot:item.pendientedespues="props">
                        <!-- <span style="font-size:13px;">{{ currencyFormatter( props.item.pendiente) }}</span> -->
                        <span style="font-size:13px;">{{ currencyFormatter(calcularReciboIngPendienteDespues(props.item)) }}</span>
                      </template>


                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->


                    <!-- Tabla ReimprecionFacturaSeleccionada de varias-->
                    <v-data-table v-if="BtnReimprecionvalue == 3 || BtnReimprecionvalue == 6 || BtnReimprecionvalue == 7"
                      :headers="[
                        { text: 'PRODUCTO', value: 'descripcion', sortable: false, class: ['no-gutters'] },
                        { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters'] },
                        { text: 'VALOR', value: 'valor', sortable: false, class: ['no-gutters'] },
                        { text: 'IMPORTE', value: 'importe', sortable: false, class: ['no-gutters'] },
                      ]" dense :items="ReimprecionFacturaSeleccionada.DetalleList" :items-per-page="15">

                      <template v-slot:item.valor="props">
                        <!-- <span style="font-size:13px;" >{{currencyFormatter( (props.item.cantidad * props.item.precio)+props.item.valorimpuesto)}}</span> -->
                        <!-- <span style="font-size:13px;">{{ currencyFormatter(props.item.precio) }}</span> -->
<span style="font-size:13px;">
  {{ currencyFormatter2TasaInf(getTasaInfo(props.item.precio,ReimprecionFacturaSeleccionada.tasa),ReimprecionFacturaSeleccionada.tasa)  }}
</span>


                      </template>

                      <template v-slot:item.descripcion="props">
                        <span style="font-size:13px;">{{ props.item.descripcion }}</span>
                      </template>

                      <template v-slot:item.cantidad="props">
                        <span style="font-size:13px;">{{ props.item.cantidad }}</span>
                      </template>

                      <template v-slot:item.importe="props">
                        <!-- <span style="font-size:13px;">{{ currencyFormatter( props.item.importe) }}</span> -->
                        <!-- <span style="font-size:13px;" >{{currencyFormatter( (props.item.cantidad * props.item.precio))}}</span> -->

                       <!--  <span style="font-size:13px;" v-if="IncluirItbisGeneral == true">
                          {{ currencyFormatter((props.item.cantidad * props.item.precio)) }}
                        </span>
                        <span style="font-size:13px;" v-if="IncluirItbisGeneral == false">
                          {{ currencyFormatter((props.item.cantidad * props.item.precio) + (props.item.valorimpuesto *
                            props.item.cantidad))
                          }}
                        </span>-->
                        
                        <span style="font-size:13px;" v-if="IncluirItbisGeneral == true">
                          {{ currencyFormatter2TasaInf(getTasaInfo((props.item.cantidad * props.item.precio),ReimprecionFacturaSeleccionada.tasa),ReimprecionFacturaSeleccionada.tasa)  }}
                        </span>
                        <span style="font-size:13px;" v-if="IncluirItbisGeneral == false">
                          {{ currencyFormatter2TasaInf(getTasaInfo(((props.item.cantidad * props.item.precio) + (props.item.valorimpuesto *props.item.cantidad)),ReimprecionFacturaSeleccionada.tasa),ReimprecionFacturaSeleccionada.tasa)  }}
                        </span>

                        
                      </template>
                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->


                    <!-- Tabla ReimprecionFacturaSeleccionada de factura -->
                    <v-data-table v-if="BtnReimprecionvalue == 4" :headers="[
                      { text: 'PRODUCTO', value: 'producto.descripcion', sortable: false, class: ['no-gutters'] },
                      { text: 'CANTIDAD', value: 'cantidad', sortable: false, class: ['no-gutters'] },
                      // { text: 'COSTO', value: 'costo', sortable: false, class: ['no-gutters'] },
                      { text: 'PRECIO', value: 'precio', sortable: false, class: ['no-gutters'] },
                      // { text: 'VALOR', value: 'valor', sortable: false, class: ['no-gutters'] },

                    ]" dense :items="ReimprecionFacturaSeleccionada.DetalleList" :items-per-page="15">

                      <template v-slot:item.valor="props">
                        <!-- <span style="font-size:13px;" >{{currencyFormatter( (props.item.cantidad * props.item.precio)+props.item.valorimpuesto)}}</span> -->
                        <span style="font-size:13px;">
                        <!-- {{ currencyFormatter(ReimprecionItemSelectValor(props.item))}} -->
                        {{ currencyFormatter2TasaInf(getTasaInfo(ReimprecionItemSelectValor(props.item),ReimprecionFacturaSeleccionada.tasa),ReimprecionFacturaSeleccionada.tasa) }}
                        </span>
                      </template>

                      <template v-slot:item.producto.descripcion="props">
                        <span style="font-size:13px;">{{ props.item.producto.descripcion }}</span>
                      </template>

                      <template v-slot:item.cantidad="props">
                        <span style="font-size:13px;">{{ props.item.cantidad }}</span>
                      </template>

                      <template v-slot:item.costo="props">
                        <span style="font-size:13px;">
                          <!-- {{ currencyFormatter(props.item.costo) }} -->
                          {{ currencyFormatter2TasaInf(getTasaInfo(props.item.costo,ReimprecionFacturaSeleccionada.tasa),ReimprecionFacturaSeleccionada.tasa) }}
                        </span>
                      </template>

                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->




                  </p>

                </v-col>
                <!-- fn  informacion de fatura  selecionada reimprecion -->



              </div>
            </v-col>




          </v-card>
        </v-col>

      </div>
      </v-card>
      
    </v-dialog>
    <!-------------------------------------------------------- fn Modal- Reimprecion-->


    <!-- ------------------------------------------------------ Modal- Aceptar Devolucion-->
    <v-dialog transition="fab-transition" label="Modal Aceptar Devolucion" v-model="dialogAceptarDevolucion" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Aceptar Devolucion ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Información
                devolución</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Información devolución: </span>
              cliente y cedula </i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarAceptarDevolucion()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="newnewAceptarDevolucion()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>



              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarAceptarDevolucion()" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y finalizar devolución </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> FINALIZAR DEVOLUCIÓN </b>
                <v-form v-model="valid" ref="formAceptarDevolucion" lazy-validation>
                  <!-- Row 1      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">


                      <!-- ----------------------------- nombre -->

                      <v-text-field label="* Cliente:" autocomplete="off" dense outlined :rules="[$rules.required]"
                        v-model="AceptarDevolucion.nombre">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>


                      <v-text-field autocomplete="off" v-mask="MakCelRnc" label=" Cedula/RNC:" dense outlined
                        maxlength="13" :rules="[$rules.RulesCelRncValidar]" v-model="AceptarDevolucion.cedula"
                        @blur="MakaraCelAceptarDevolucion" v-on:blur="validandoCedRnc(AceptarDevolucion.cedula)" @focus="MakCelRnc = '#############'">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                        </template>
                      </v-text-field>

                    </v-col>

                  </v-row>
                  <!-- Row 2 ------------------------------------------->

                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Aceptar Devolucion -->




    <!-------------------------------------------------------- Modal-hacer devolucion -->
    <v-dialog label="Modal Devolucion  " v-model="dialogDevolucion" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Devolución
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Devolución: </span></i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="NewDevolucionFacturaSeleccionada(), dialogDevolucion = false"
                    color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>


        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <v-col cols="12" md="4" sm="4" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Digite Número Factura:" dense autocomplete="off" outlined
                        type="number" v-model.number="DevolucionBusqueda.secuencia"
                        @keydown.enter="buscarFacturaDevolucion" style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de factura que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">

                <!-- fn Tabla Busqueda Devolucion -->
                <div v-if="DevolucionFacturaSeleccionada.secuencia == 0">
                  <v-data-table class="RowSelectColor" :single-select="true" item-key="indx" :show-select="true"
                    v-model="DevolucionSelected" @item-selected="DevolucionObtenerDatoSelect"
                    @click:row="DevolucionDatostabla" :headers="[
                      { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                      { text: '# Secuencia', sortable: false, value: 'sec', width: 129 },
                      { text: 'Cliente', sortable: false, value: 'clinica.nombre', width: 129 },
                      { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
                    ]" dense :items="filterFacturaDevolucion(DevolucionlistFactCliente)">

                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =
                      DevolucionlistFactCliente.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                    </template>

                    <template v-slot:item.facturasventaPK.secuencia="props">
                      <span style="font-size:13px;">{{ props.item.facturasventaPK.secuencia }} </span>
                    </template>

                    <template v-slot:item.sec="props">
                      <span style="font-size:13px;">{{ props.item.documentoFactura.nombrecorto.trim() }}-{{
                        props.item.facturasventaPK.secuencia }} </span>
                    </template>

                    <template v-slot:item.clinica.nombre="props">
                      <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
                      </span>
                    </template>

                    <template v-slot:item.valor="props">
                      <!-- <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span> -->
                      <span style="font-size:13px;">{{ currencyFormatter2TasaInf(getTasaInfo(props.item.valor,props.item.tasa),props.item.tasa) }}</span> 
                    </template>
                  </v-data-table>
                </div>
                <!-- fn Tabla Busqueda Devolucion -->

                <!-- Devolucion Selecionada -->
                <v-col v-if="DevolucionFacturaSeleccionada.secuencia > 0" style="border: 1px solid #000000;"
                  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                  <v-row>
                    <v-col cols="12" md="8" sm="8">
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FACTURA #: </b>
                        {{ DevolucionFacturaSeleccionada.secuencia }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
                        {{ FormatoFecha(DevolucionFacturaSeleccionada.fecha) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NCF : </b>
                        {{ DevolucionFacturaSeleccionada.ncf }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CLIENTE : </b>
                        {{ DevolucionFacturaSeleccionada.cliente }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> VALOR : </b>
                        <!-- {{ currencyFormatter(DevolucionFacturaSeleccionada.valortotal) }} -->
                        {{ currencyFormatter2TasaInf(getTasaInfo(DevolucionFacturaSeleccionada.valortotal,DevolucionFacturaSeleccionada.tasa),DevolucionFacturaSeleccionada.tasa)  }}
                      </p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FORMA DE PAGO : </b>
                        {{ DevolucionFacturaSeleccionada.formapago }}</p>
                        <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0" v-if="DevolucionFacturaSeleccionada.moneda!=null"><b> MONEDA : </b> 
                        ${{ DevolucionFacturaSeleccionada.moneda.nombrecorto.trim() }}</p>
                      <v-textarea autocomplete="off" label="comentario:" rows="2" dense outlined
                        v-model="DevolucionFacturaSeleccionada.comentario" hide-details>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-commenting"></i>
                        </template>
                      </v-textarea>
                      <v-row>
                        <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                          <v-checkbox
                          @change="changeDevolucionEfectivo"
                            v-if="DevolucionFacturaSeleccionada.Pagoefec > 0 && DevolucionFacturaSeleccionada.cheques == 0 &&
                              DevolucionFacturaSeleccionada.cupones == 0 && DevolucionFacturaSeleccionada.cxc == 0 &&
                              DevolucionFacturaSeleccionada.puntos == 0 && DevolucionFacturaSeleccionada.tarjeta == 0 &&
                              DevolucionFacturaSeleccionada.valorNc == 0 && DevolucionFacturaSeleccionada.transferencia == 0"
                            class="mt-0 ma-3" v-model="DevolucionFacturaSeleccionada.efectivo" label="" color="teal "
                            hide-details>
                            <template v-slot:label>
                              <strong v-if="DevolucionFacturaSeleccionada.efectivo == true"
                                style="color:#000000; font-size:13px;">Devolucion en Efectivo: Si</strong>
                              <strong v-if="DevolucionFacturaSeleccionada.efectivo == false"
                                style="color:#000000; font-size:13px;">Devolucion en Efectivo: No</strong>
                            </template>
                          </v-checkbox>
                        </v-col>


                        <v-col cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
                          <v-checkbox @change="DevolucionValorTotal()" v-on:change="verificarVenFacDev()" class="mt-0 ma-3"
                            v-model="DevolucionFacturaSeleccionada.quitaritbis" label="" color="teal " hide-details>
                            <template v-slot:label>
                              <strong v-if="DevolucionFacturaSeleccionada.quitaritbis == true"
                                style="color:#000000; font-size:13px;">Quitar itbis: Si</strong>
                              <strong v-if="DevolucionFacturaSeleccionada.quitaritbis == false"
                                style="color:#000000; font-size:13px;">Quitar itbis: No</strong>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>

                    </v-col>
                    <v-col cols="12" md="4" sm="4">

                      <br>
                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'red darken-3' : 'red'" v-ripple="{ class: `white--text` }"
                          @click.prevent="NewDevolucionFacturaSeleccionada">

                          <h2>CANCELAR</h2>

                        </v-btn>
                      </v-hover>

                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'blue darken-3' : 'blue'" v-ripple="{ class: `white--text` }"
                          @click.prevent="openAceptarDevolucion" :loading="!ejecucion" :disabled="!ejecucion">

                          <h2>FINALIZAR</h2>
                          <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>

                        </v-btn>
                      </v-hover>


                    </v-col>
                  </v-row>
                  <!-- Tabla ReimprecionFacturaSeleccionada de factura -->
                  <p style="border: 1px dashed  #616161;">
                    <strong style="color:#000000; font-size:13px;">El valor de la devolucion sera RD$:
                      {{ DevolucionFacturaSeleccionada.valordevolucion }}</strong>
                    <v-data-table item-key="indx" :show-select="true"
                      v-model="DevolucionFacturaSeleccionada.SeletDetalleList" :headers="[
                        { text: 'DESCRIPCIÓN', value: 'descripcion', sortable: false, width: 'auto', class: ['no-gutters black--text'] },
                        { text: 'CANTIDAD', value: 'cantidad', sortable: false, width: 200, class: ['no-gutters black--text'] },
                        { text: 'PRECIO', value: 'precio', sortable: false, width: 200, class: ['no-gutters black--text'] },
                        { text: 'VALOR', value: 'valor', sortable: false, width: 200, class: ['no-gutters black--text'] },
                      ]" dense :items="DevolucionFacturaSeleccionada.DetalleList" :items-per-page="15"
                      @item-selected="SelcDevObtenerDatoSelect" @toggle-select-all="AllSelcDevObtenerDatoSelect">
                      <template v-slot:item.descripcion="props">
                        <span style="font-size:13px; display:none;">{{ props.item.indx =
                        DevolucionFacturaSeleccionada.DetalleList.indexOf(props.item)
                        }}</span>
                        <span style="font-size:13px;">{{ props.item.descripcion }}</span>
                      </template>

                      <template v-slot:item.cantidad="props">

                        <!-- <span style="font-size:13px; display:none;">{{props.item.cantidad = props.item.cantidadPendiente}}</span> -->

                        <VueAutonumeric label="" style="font-size:13px;" dense hide-details outlined autocomplete="off"
                          v-model.number="props.item.cantidad" v-on:blur="DevolucionValorTotal()"
                          @blur="DevolucionVerificaCantidad(props.item)">
                        </VueAutonumeric>
                      </template>

                      <template v-slot:item.precio="props">
                        <span style="font-size:13px;">
                          <!-- {{ currencyFormatter(props.item.precio) }} -->
                          {{ currencyFormatter2TasaInf(getTasaInfo(props.item.precio,DevolucionFacturaSeleccionada.tasa),DevolucionFacturaSeleccionada.tasa)  }}
                        </span>
                      </template>

                      <template v-slot:item.valor="props">

                        <span style="font-size:13px;">
                          <!-- {{ currencyFormatter(DevolucionValor(props.item)) }} -->
                          {{ currencyFormatter2TasaInf(getTasaInfo(DevolucionValor(props.item),DevolucionFacturaSeleccionada.tasa),DevolucionFacturaSeleccionada.tasa)  }}
                        </span>



                      </template>

                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->
                  </p>


                </v-col>
                <!-- fn devolucion Selecionada -->

              </div>
            </v-col>
          </v-card>
        </v-col>

      </v-card>

    </v-dialog>
    <!-------------------------------------------------------- fn Modal-hacer devolucion -->

    <!-------------------------------------------------------- Modal-Anulacion de factura -->
    <v-dialog label="Modal Anulacion " v-model="dialogAnulacion" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Anulación de
                factura</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Anulación de factura: </span></i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="NewAnulacionFacturaSeleccionada()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>


        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">

            <v-form ref="formAnulacion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <v-col cols="12" md="4" sm="4" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Digite Número Factura:" dense autocomplete="off" outlined
                        type="number" v-model.number="AnulacionBusqueda.secuencia" @keydown.enter="buscarFacturaAnulada"
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de factura que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">

                <!-- fn Tabla Busqueda anulacion -->
                <!-- && !AnulacionFacturaSeleccionada.hasOwnProperty('secuencia') ||  AnulacionFacturaSeleccionada.secuencia==0 -->
                <div v-if="AnulacionFacturaSeleccionada == null">
                  <v-data-table class="RowSelectColor elevation-1 TbSize13" :single-select="true" item-key="indx"
                    :show-select="true" v-model="AnulacionSelected" @item-selected="AnularObtenerDatoSelect"
                    @click:row="AnularDatostabla" :headers="[
                      { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                      { text: '# Secuencia', sortable: false, value: 'sec', width: 129 },
                      { text: 'Cliente', sortable: false, value: 'clinica.nombre', width: 129 },
                      { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
                    ]" dense :items="AnulacionlistFactCliente">

                    <template v-slot:item.fecha="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =
                      AnulacionlistFactCliente.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                    </template>

                    <template v-slot:item.sec="props">
                      <span style="font-size:13px;">{{ props.item.documentoFactura.nombrecorto.trim() }}-{{
                        props.item.facturasventaPK.secuencia }} </span>
                    </template>


                    <template v-slot:item.clinica.nombre="props">
                      <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
                      </span>
                    </template>

                    <template v-slot:item.valor="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                    </template>
                  </v-data-table>
                </div>
                <!-- fn Tabla Busqueda anulacion -->

                <!-- anulacion Selecionada -->
                <v-col v-if="AnulacionFacturaSeleccionada != null" style="border: 1px solid #000000;"
                  class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                  <v-row no-gutters>
                    <v-col cols="12" md="5" sm="5" style="font-size: 13px;">
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FACTURA #: </b>
                        {{ AnulacionFacturaSeleccionada.facturasventaPK.secuencia }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
                        {{ FormatoFecha(new Date(AnulacionFacturaSeleccionada.fecha).toISOString().substr(0, 10)) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NCF : </b>
                        {{ AnulacionFacturaSeleccionada.ncf }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CLIENTE : </b>
                        {{ (AnulacionFacturaSeleccionada.cliente == null ? ''
                          : AnulacionFacturaSeleccionada.cliente.nombre)
                        }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> VALOR : </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.valor) }}</p>

                    </v-col>

                    <v-col cols="12" md="3" sm="3" style="font-size: 13px;">
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.efectivo > 0"><b> EFECTIVO : </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.efectivo) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.tarjeta > 0"><b> TIPO TARJETA : </b>
                        {{ AnulacionFacturaSeleccionada.tipoTarjeta.nombre }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.tarjeta > 0"><b> #TARJETA : </b>
                        {{ AnulacionFacturaSeleccionada.numtar }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.tarjeta > 0"><b> VALOR TARJETA : </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.tarjeta) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.cheques > 0"><b> CHEQUE : </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.cheques) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.cupones > 0"><b> CUPONES : </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.cupones) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.valorNc > 0"><b> NOTA CREDITO : </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.valorNc) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.cxc > 0"><b> CREDITO(CXC): </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.cxc) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.transferencia > 0"><b> TRANSFERENCIA: </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.transferencia) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"
                        v-if="AnulacionFacturaSeleccionada.puntos > 0"><b> PUNTO: </b>
                        {{ currencyFormatter(AnulacionFacturaSeleccionada.puntos) }}</p>
                    </v-col>

                    <v-col cols="12" md="4" sm="4" class="px-2">

                      <br>

                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'grey darken-3' : 'grey'" v-ripple="{ class: `white--text` }"
                          @click.prevent="CancelarAnulacionFacturaSeleccionada">

                          <h2>CANCELAR</h2>

                        </v-btn>
                      </v-hover>

                      <v-hover v-slot:default="{ hover }" close-delay="200">

                        <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                          :color="hover ? 'red darken-3' : 'red'" v-ripple="{ class: `white--text` }"
                          @click.prevent="AnulacionFacturaAnular">

                          <h2>ANULAR</h2>

                        </v-btn>
                      </v-hover>



                    </v-col>

                    <!-- <v-textarea
                      autocomplete="off"
                        label="comentario:"
                        rows="3"
                        dense
                        outlined
                        v-model="AnulacionFacturaSeleccionada.comentario"
                        
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-commenting"></i>
                        </template>
                      </v-textarea>
    cheques,Pagoefec,cupones,puntos,valorNc,cxc,tarjeta,transferencia,efectivo -->

                  </v-row>
                  <!-- :class="`d-flex justify-center`"  -->
                  <center class="pa-0 ma-0 py-0 my-0">
                    <v-form ref="formAnulacion" @submit.prevent="" v-model="valid" lazy-validation>
                      <v-col cols="12" md="8" sm="8" class="pb-0 mb-0">

                        <v-combobox autocomplete="off" dense outlined label="Motivo de anulación:" :items="RTipoAnulacion"
                          item-text="descripcion" :rules="[$rules.required]" v-model="TipoAnulacion"
                          @blur="SelctCbMotivoAnulacion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class=" fa fa-ban"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                    </v-form>
                  </center>
                  <!-- Tabla ReimprecionFacturaSeleccionada de factura -->
                  <!-- :show-select="true"
            v-model="AnulacionFacturaSeleccionada.SeletDetalleList" -->
                  <p style="border: 1px dashed  #616161;">
                    <v-data-table class="elevation-1 TbSize13" item-key="indx" :headers="[
                      { text: 'DESCRIPCIÓN', value: 'descripcion', sortable: false, width: 'auto', class: ['no-gutters black--text'] },
                      { text: 'CANTIDAD', value: 'cantidad', sortable: false, width: 200, class: ['no-gutters black--text'] },
                      { text: 'PRECIO', value: 'precio', sortable: false, width: 200, class: ['no-gutters black--text'] },
                    ]" dense :items="AnulacionFacturaSeleccionada.facturasventaDetalleList" :items-per-page="15">
                      <template v-slot:item.descripcion="props">
                        <span style="font-size:13px; display:none;">{{ props.item.indx =
                        AnulacionFacturaSeleccionada.facturasventaDetalleList.indexOf(props.item)
                        }}</span>
                        <span style="font-size:13px;">{{ props.item.descripcion }}</span>
                      </template>

                      <template v-slot:item.cantidad="props">
                        <!-- <span style="font-size:13px; display:none;">{{props.item.cantidad = props.item.cantidadPendiente}}</span> -->
                        <span style="font-size:13px;">{{ props.item.cantidad }}</span>
                      </template>

                      <template v-slot:item.precio="props">
                        <span style="font-size:13px;">{{ currencyFormatter(props.item.precio) }}</span>
                      </template>
                    </v-data-table>
                    <!-- Tabla ReimprecionFacturaSeleccionada de factura   -->
                  </p>


                </v-col>
                <!-- fn anulacion Selecionada -->

              </div>
            </v-col>
          </v-card>
        </v-col>

      </v-card>

    </v-dialog>
    <!-------------------------------------------------------- fn Modal- Anulacion de factura-->



    <!--------------------------------------------------------   Modal-Recibo de ingreso -->
       <!---   Modal-Recibo de ingreso classico -->
      <div v-if="$store.getters.GetConfiguracionGeneral.configPv.reciboIngresoAvanzadopv==false">
    <v-dialog label="Modal Recibo de ingreso" v-model="dialogReciboIngreso"  persistent fullscreen hide-overlay transition="dialog-bottom-transition">
        <div v-if="dialogReciboIngreso"  v-dirkeydown="reciboIngresoKeyCtrl">
      <v-card>
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Recibo de ingreso
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Recibo de ingreso: </span> Crea ,
              Modificar cliente y muestra el historial de compra</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="dialogReciboIngreso = false, NewclienteSeleccionado()"
                    color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>

              <v-tooltip v-if="ReciboIngresoFactPendHist == true" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="ReciboIngresoFactPendHist = false, historialClieList()"
                    color="light-green lighten-1" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/historial.png" alt="HISTORIAL">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    HISTORIAL
                  </v-btn>
                </template>
                <span>HISTORIAL: historial factura</span>
              </v-tooltip>


              <v-tooltip v-if="ReciboIngresoFactPendHist == false" bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="ReciboIngresoFactPendHist = true" color="light-green lighten-1"
                    small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/menu/recibo64.png" alt="FACTURA PENDIENTE">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    FACTURA PENDIENTE
                  </v-btn>
                </template>
                <span>FACTURA PENDIENTE: Muestra las facturas pendiente el cliente</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarReciboIngresoFacPen" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Guarda Recibo de ingreso y retornar atrás, Al presionar la tecla (CTRL), ejecuta este boton </span>
              </v-tooltip>


            </v-btn-toggle>

          </v-card-actions>
        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-row>
            <!--------- columna 1 Recibo de ingreso ---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b>Datos e Información</b></span>
              </v-col>

              <!-- columna Recibo de ingreso-->
              <v-col style="border: 1px solid #000000;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <v-card color="grey lighten-3">
                  <b :class="`d-flex justify-center`"> CLIENTE</b>
                  <span>
    <b>Nota:</b><span style="color: #C62828;"> Salvar al presionar tecla (CTRL).</span>
 </span>
                  <v-col v-if="clienteFactura != null" style="border: 1px solid #000000;" class="pa-0 ma-0 py-0 my-0"
                    cols="12" md="12" sm="12">
                    <!-- style="border: 1px dashed  #616161;" -->
                    <span><b> CLIENTE: </b> {{ clienteFactura.nombre }} </span>
                    <span><b> TELEFONO: </b>{{selectTelefonoCliente(clienteFactura)}} </span>
                    <span v-if="false"><b> BALANCE: </b> {{ currencyFormatter(0) }} </span>
                    <!-- {{clienteFactura.telefonoPrincipal}} -->
                    <p><b> DIRECCIÓN: </b> {{ clienteFactura.direccion }} </p>

                  </v-col>


                  <b :class="`d-flex justify-center`"> EDITAR RECIBO DE INGRESO</b>
                  <v-form v-model="valid" ref="formReciboIngreso" lazy-validation>
                    <!-- Row 1  Recibo Ingreso   @keydown.enter="SalvarProductoCompraEnter"   ------------------------------------------->
                    <v-row class="pa-0 ma-0 py-0 my-0">

                      <v-col cols="12" md="6" sm="12">

                        <!-- CalcularReciboIngreso1() -->
                        <VueAutonumeric label="Abono" dense outlined @blur="CalcularReciboIngreso1()" autocomplete="off"
                          :rules="[$rules.Numericc]" v-model.number="ReciboIngreso.abono" style="font-size:13px;"
                          v-on:input="calcularToTalRecIng">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric label="Descuento" dense outlined @blur="CalcularReciboIngreso1()"
                          autocomplete="off" :rules="[$rules.Numericc]" v-model.number="ReciboIngreso.descuento"
                          style="font-size:13px;" hide-details>
                          <template v-slot:prepend>
                            <i v-if="ReciboIngreso.DescValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                            <i v-if="ReciboIngreso.DescValorPorc == false" style="font-size: 20px;" class="fa fa-percent"></i>
                          </template>

                          <template v-slot:append-outer>
                            <!-- ChangeReciboIngresoValorPorcc -->
                            <v-radio-group hide-details dense @change="CalcularReciboIngreso1"
                              style="margin-top: -18%; border: 1px dashed  #616161;" v-model="ReciboIngreso.DescValorPorc"
                              column>
                              <v-radio class="ma-0 pa-0 " label="Valor" color="teal lighten-2" :value="true"></v-radio>
                              <v-radio class="ma-0 pa-0 " label="Porciento" color="teal lighten-2" :value="false">
                              </v-radio>
                            </v-radio-group>

                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric label="Retencion" dense outlined v-if="false" @blur="CalcularReciboIngreso()"
                          autocomplete="off" :rules="[$rules.Numericc]" v-model.number="ReciboIngreso.retencion"
                          style="font-size:13px;" hide-details>
                          <template v-slot:prepend>
                            <i v-if="ReciboIngreso.retenValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                            <i v-if="ReciboIngreso.retenValorPorc == false" style="font-size: 20px;"
                              class="fa fa-percent"></i>
                          </template>

                          <template v-slot:append-outer>

                            <v-radio-group hide-details dense @change="ChangeReciboIngresoValorPorcc"
                              style="margin-top: -15%; border: 1px dashed  #616161;"
                              v-model="ReciboIngreso.retenValorPorc" column>
                              <v-radio class="ma-0 pa-0 " dense label="Valor" color="teal lighten-2" :value="true">
                              </v-radio>
                              <v-radio class="ma-0 pa-0 " dense label="Porciento" color="teal lighten-2" :value="false">
                              </v-radio>
                            </v-radio-group>

                          </template>
                        </VueAutonumeric>
                        <VueAutonumeric label="Retencion ISR" dense outlined v-if="false" @blur="CalcularReciboIngreso()"
                          autocomplete="off" :rules="[$rules.Numericc]" v-model.number="ReciboIngreso.isr"
                          style="font-size:13px;" hide-details>
                          <template v-slot:prepend>
                            <i v-if="ReciboIngreso.isrValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                            <i v-if="ReciboIngreso.isrValorPorc == false" style="font-size: 20px;"
                              class="fa fa-percent"></i>
                          </template>

                          <template v-slot:append-outer>

                            <v-radio-group hide-details dense @change="ChangeReciboIngresoValorPorcc"
                              style="margin-top: -15%; border: 1px dashed  #616161;" v-model="ReciboIngreso.isrValorPorc"
                              column>
                              <v-radio class="ma-0 pa-0 " label="Valor" color="teal lighten-2" :value="true"></v-radio>
                              <v-radio class="ma-0 pa-0 " label="Porciento" color="teal lighten-2" :value="false">
                              </v-radio>
                            </v-radio-group>

                          </template>
                        </VueAutonumeric>



                      </v-col>

                      <v-col cols="12" md="6" sm="12" style="border: 1px solid #000000;">
                        <!-- <p style="border: 1px solid #000000;" ><b>PENDIENTE:</b>  <b :class="`d-flex justify-end red--text`" >0.00</b></p> -->
                        <v-row no-gutters class="pa-0 ma-0 py-0 my-0">
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b>PENDIENTE:</b></p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b :class="`d-flex justify-end red--text`">{{
                              currencyFormatter(Totales.ReciboIngreso.pendiente)
                            }}</b>
                            </p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b>ABONADO:</b></p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b :class="`d-flex justify-end`">{{
                              currencyFormatter(Totales.ReciboIngreso.abono)
                            }}</b></p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b>DESCUENTO:</b></p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b :class="`d-flex justify-end`">{{
                              currencyFormatter(Totales.ReciboIngreso.descuento)
                            }}</b>
                            </p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b>RETENCIÓN:</b></p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b :class="`d-flex justify-end`">{{
                              currencyFormatter(Totales.ReciboIngreso.retencion)
                            }}</b>
                            </p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b>RETENCIÓN ISR:</b></p>
                          </v-col>
                          <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <p class="pa-0 ma-0 py-0 my-0"><b :class="`d-flex justify-end`">{{
                              currencyFormatter(Totales.ReciboIngreso.isr)
                            }}</b></p>
                          </v-col>

                        </v-row>

                      </v-col>
                    </v-row>
                  </v-form>
                </v-card>

              </v-col>
              <!-- Forma de pago recibo de ingreso -->
              <v-col v-if="FactPendienteSeleccionado == null" style="border: 1px solid #000000;"
                class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <center>
                  <v-card color="grey lighten-3">
                    <v-row>
                      <!--  columna 1  -->
                      <v-col cols="12" md="6" sm="6">


                        <VueAutonumeric label="Efectivo:" dense outlined autocomplete="off"
                          v-model.number="ReciboIngresoFactura.efectivo" :rules="[$rules.Numericc]"
                          style="font-size: 13px;" hide-details @focus="PanelReciboFormaPago = false"
                          @keydown.enter="ReciboEnterEfectivo" @input="calcularToTalRecIng"
                          v-on:keydown.enter="calcularToTalRecIng"
                          >
                          <template v-slot:prepend>
                            <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->

                            <!-- <span ></span> -->
                            <v-avatar tile size="30" color="transparent">
                              <img src="@/assets/icons_pv/formapago/efectivo35.png" alt="Efectivo">
                            </v-avatar>

                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric class="mt-1" label="Tarjeta:" dense outlined autocomplete="off"
                          v-model.number="ReciboIngresoFactura.tarjeta" :rules="[$rules.Numericc]"
                          style="font-size: 13px;" hide-details @focus="OpenPanelReciboFormaPago('Tarjeta')"
                          @keydown.enter="ReciboEnterTarjeta" @input="calcularToTalRecIng"
                          v-on:keydown.enter="calcularToTalRecIng"
                          >
                          <template v-slot:prepend>
                            <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                            <v-avatar tile size="30" color="transparent">
                              <img src="@/assets/icons_pv/formapago/tarjeta35.png" alt="Tarjeta">
                            </v-avatar>
                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric class="mt-1" label="Cheque:" dense outlined autocomplete="off"
                          v-model.number="ReciboIngresoFactura.cheque" @input="calcularToTalRecIng"
                          @keydown.enter="enterCheque" :rules="[$rules.Numericc]" style="font-size: 13px;" hide-details
                          @focus="OpenPanelReciboFormaPago('Cheque')" v-on:keydown.enter="ReciboEnterCheques"
                          >
                          <template v-slot:prepend>
                            <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->
                            <v-avatar tile size="30" color="transparent">
                              <img src="@/assets/icons_pv/formapago/cheques35.png" alt="Cheque">
                            </v-avatar>

                          </template>
                        </VueAutonumeric>

                        <VueAutonumeric class="mt-1" label="Transferencia:" dense outlined autocomplete="off"
                          v-model.number="ReciboIngresoFactura.transferencia" @input="calcularToTalRecIng"
                          @keydown.enter="enterTransferencia" :rules="[$rules.Numericc]" style="font-size: 13px;"
                          hide-details @focus="OpenPanelReciboFormaPago('Transferencia')"
                          v-on:keydown.enter="ReciboEnterTransferencia" >
                          <template v-slot:prepend>
                            <!-- <i style="font-size: 20px;" class="fa fa-money"></i> -->

                            <v-avatar tile size="30" color="transparent">
                              <img src="@/assets/icons_pv/formapago/transferencia35.png" alt="Transferencia">
                            </v-avatar>
                          </template>
                        </VueAutonumeric>
                        <center>
                          <v-tooltip dense top>
                            <template v-slot:activator="{ on }">
                              <h3 v-on="on" style="border: 2px solid #757575;" block>
                                <span v-if="devueltaRecIngreso < 0" class="red--text">Devuelta: $ ({{
                                  currencyFormatter2(devueltaRecIngreso * (-1)) }}
                                  )</span>
                                <span v-if="devueltaRecIngreso == 0">Devuelta: $ 0.00</span>
                                <span v-if="devueltaRecIngreso > 0" class="green--text">Devuelta: $ {{
                                  currencyFormatter2(devueltaRecIngreso) }}</span>
                              </h3>
                            </template>
                            <b><span> <strong style="  background-color:black; font-size:14px;">Letra Negro:</strong> no
                                contiene
                                devuelta ni faltante,
                                <strong style="  background-color:red; font-size:14px;">Letra Rojo:</strong> contiene
                                faltante,
                                <strong style="  background-color:green; font-size:14px;">Letra Verde:</strong> contiene
                                devuelta.</span></b>

                          </v-tooltip>

                        </center>

                      </v-col>
                      <!--  fn columna 1  -->

                      <!--  columna 2 -->

                      <!-- columna 2- Opcion 2 cheque -->
                      <v-col v-show="PanelReciboFormaPago" cols="12" md="6" sm="6">
                        <v-row style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0">
                          <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                            <span :class="`d-flex justify-center white--text`"><b>{{ NombrePanelReciboFormaPago
                            }}</b></span>
                          </v-col>
                          <v-col style="background-color:#dd4b39;" class=" pa-0 ma-0 py-0 my-0 d-flex justify-end"
                            cols="6" md="6" sm="6">
                            <v-btn color="grey darken-4" fab x-small @click="PanelReciboFormaPago = false">
                              <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                            </v-btn>
                          </v-col>

                          <div
                            v-if="NombrePanelReciboFormaPago == 'Cheque' || NombrePanelReciboFormaPago == 'Transferencia'">
                            <v-text-field style="font-size:13px;" v-model="ReciboIngresoFactura.numeroCheque"
                              :rules="[$rules.required]" autocomplete="off"
                              :label="'Número ' + NombrePanelReciboFormaPago" required outlined dense class="mt-2"
                              hide-details>
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                              </template>
                            </v-text-field>

                            <v-combobox autocomplete="off" dense outlined label="Banco" :items="RBanco" item-text="nombre"
                              :rules="[$rules.required]" v-model="ReciboIngresoFactura.bancoCheque"
                              @blur="SelctCbBancoReciboFacturaFormPag" @focus="cargarBanco" class="mt-2" hide-details>
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-wpforms"></i>
                              </template>
                            </v-combobox>

                          </div>

                          <div v-show="NombrePanelReciboFormaPago == 'Tarjeta'">
                            <v-form ref="formRecibTarjetaFormPago" v-model="valid" lazy-validation>
                              <v-text-field style="font-size:13px;" v-model="ReciboIngresoFactura.numtar"
                                :rules="[$rules.required]" autocomplete="off" label="Número Tarjeta:" required outlined
                                dense class="mt-2" hide-details>
                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                                </template>
                              </v-text-field>
                              <v-combobox autocomplete="off" dense outlined label="Tipo de Tarjeta" :items="RTipoTarjeta"
                                item-text="nombre" :rules="[$rules.required]" v-model="ReciboIngresoFactura.tipoTarjeta"
                                @blur="SelctCbTipoTarjetaReciboFacturaFormPag" @focus="cargarTipoTarjeta" class="mt-2"
                                hide-details>

                                <template v-slot:prepend>
                                  <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                                </template>
                              </v-combobox>
                            </v-form>
                          </div>
                        </v-row>

                      </v-col>

                      <!-- fn columna 2- Opcion 2 Cheque  -->

                      <!--  fn columna 2  -->

                    </v-row>
                  </v-card>
                </center>
              </v-col>
              <!-- fn Forma de pago recibo de ingreso -->
              <!-- columna recibo de ingreso  ReciboIngreso.abono==0 &&-->
              <v-col v-if="FactPendienteSeleccionado != null" style="border: 1px solid #000000;"
                class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <b :class="`d-flex justify-center`"> FACTURA PENDIENTE SELECCIONADA </b>
                <v-row no-gutters>
                  <v-col cols="12" md="6" sm="12" style="border: 1px solid #000000; ">
                    <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161; font-size: 14px;"><b> FECHA: </b>
                      {{ FormatoFecha(HeadInfFactPendienteSelecc.fecha) }} </p>
                    <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161; font-size: 14px;"><b> #FACTURA:
                      </b> {{ HeadInfFactPendienteSelecc.factura }} </p>
                    <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161; font-size: 14px;"><b> VALOR
                        FACTURA: </b> {{ currencyFormatter(HeadInfFactPendienteSelecc.valorf) }}</p>
                    <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161; font-size: 14px;"><b> PENDIENTE:
                      </b> {{ currencyFormatter(HeadInfFactPendienteSelecc.pendiente) }} </p>

                    <VueAutonumeric label="Abono" dense outlined class="ma-1" autocomplete="off"
                      :rules="[$rules.Numericc]" v-model.number="FactPendienteSeleccionado.abono" style="font-size:13px;"
                      hide-details>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>

                    <VueAutonumeric label="Descuento" dense outlined class="ma-1" autocomplete="off"
                      :rules="[$rules.Numericc]" v-model.number="FactPendienteSeleccionado.descuento"
                      style="font-size:13px;" hide-details>
                      <template v-slot:prepend>
                            <i v-if="FactPendienteSeleccionado.DescValorPorc == true" style="font-size: 20px;" class="fa fa-usd"></i>
                            <i v-if="FactPendienteSeleccionado.DescValorPorc == false" style="font-size: 20px;" class="fa fa-percent"></i>
                          </template>
                          <template v-slot:append-outer>
                            <!-- ChangeReciboIngresoValorPorcc -->
                            <v-radio-group hide-details dense 
                              style="margin-top: -18%;" v-model="FactPendienteSeleccionado.DescValorPorc"
                              column>
                              <v-radio class="ma-0 pa-0 " label="Valor" color="teal lighten-2" :value="true"></v-radio>
                              <v-radio class="ma-0 pa-0 " label="Porciento" color="teal lighten-2" :value="false">
                              </v-radio>
                            </v-radio-group>

                          </template>
                    </VueAutonumeric>
                    <VueAutonumeric label="retencion" dense outlined class="ma-1" autocomplete="off"
                      :rules="[$rules.Numericc]" v-model.number="FactPendienteSeleccionado.retencion"
                      style="font-size:13px;" hide-details>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>


                  </v-col>

                  <v-col cols="12" md="6" sm="12" style="border: 1px solid #000000;">
                    <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161; font-size: 14px;"><b> NCF: </b>
                      {{ FactPendienteSeleccionado.ncf }} </p>
                    <p class="pa-0 ma-0 py-0 my-0" style="border: 1px dashed  #616161; font-size: 14px;"><b> REFERENCIA:
                      </b> {{ FactPendienteSeleccionado.referencia }} </p>
                    <br>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn block id="boton-mini" v-on="on" @click="FactPendienteSeleccionado = null" color="orange"
                          small dark>
                          <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                          CANCELAR
                        </v-btn>
                      </template>
                      <span>CANCELAR: Cancela operacion de factura seleccionada</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn block id="boton-mini" v-on="on" @click="SalvarReciboIngresoSelctAbono1"
                          color="light-blue darken-4" small dark>
                          <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                          SALVAR
                        </v-btn>
                      </template>
                      <span>SALVAR: Guardar la operacion realizada en la factura seleccionada </span>
                    </v-tooltip>


                  </v-col>
                </v-row>
              </v-col>

            </v-col>
            <!--------- fn columna 1 recibo de ingreso ---------->


            <!--------- columna 2 recibo de ingreso ---------->
            <v-col style="border: 1px solid #000000; " cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">


              <div v-if="ReciboIngresoFactPendHist == true">
                <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                  <span :class="`d-flex justify-center white--text`"><b>Facturas Pendientes</b></span>
                </v-col>

                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

                  <!--------------------------------------------------  Header tb Facturas Pendientes -->
                  <v-col style="background-color:#3d6687;" class="py-0 my-0" cols="12" md="12" sm="12">
                    <v-row style="border: 1px solid #000000;">
                      <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`">Fecha</b>
                      </v-col>
                      <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-center white--text`">
                          Factura</b></v-col>
                      <v-col class="pa-0 ma-0" cols="4" md="4"><b :class="`d-flex justify-center white--text`"> Valor
                          Factura </b></v-col>
                      <v-col class="pa-0 ma-0" cols="4" md="4"><b :class="`d-flex justify-center white--text`">
                          Pendiente </b></v-col>
                    </v-row>
                  </v-col>
                  <!-------------------------------------------------- fn Header tb Facturas Pendientes -->
                  <!-------------------------------------------------- tb Facturas Pendientes
    :single-select="true" :show-select="true" 
    -->
                  <div
                    v-bind:style="{ 'height': this.windowSize.height - 30 + 'px', 'max-height': this.windowSize.height - 30 + 'px' }"
                    class="overflow-x-auto">

                    <v-data-table dense hide-default-footer hide-default-header :items-per-page="-1" class="elevation-1"
                      :single-select="true" :show-select="true" item-key="indx" :headers="[
                        { text: 'FECHA', value: 'props.item', sortable: false, class: ['no-gutters'] },
                        { text: 'CODIGO', value: 'props.item', sortable: false, class: ['no-gutters'] },
                        { text: 'VALOR FACTURA', value: 'props.item', sortable: false, class: ['no-gutters'] },
                        { text: 'PENDIENTE', value: 'props.item', sortable: false, class: ['no-gutters'] },
                      ]
                      " :items="RListFactpendiente">

                      <template v-slot:item="props">
                        <span style="display:none;" v-if="!props.item.hasOwnProperty('abono')">{{ props.item.abono
                                                  = 0
                        }}</span>
                        <span style="display:none;" v-if="!props.item.hasOwnProperty('descuento')">{{
                          props.item.descuento = 0
                        }}</span>
                        <span style="display:none;" v-if="!props.item.hasOwnProperty('retencion')">{{
                          props.item.retencion = 0
                        }}</span>
                        <span style="display:none;" v-if="!props.item.hasOwnProperty('isr')">{{ props.item.isr
                                                  = 0
                        }}</span>

                        <span style="display:none;">{{ props.item.indx = RListFactpendiente.indexOf(props.item)
                        }}</span>
                        <tr v-bind:class="classObjFactPenSelect(props.item)">
                          <td @click.prevent="datostablaFactPendiente(props.item)" v-ripple="{ class: `indigo--text` }">
                            <!-- <span style="display:none;" v-if="!props.item.hasOwnProperty('cantidad')">{{props.item.cantidad=1}}</span> 
           @item-selected="obtenerDatoSelectFactPendiente" v-model="SelectedFactPendiente"-->
                            <v-row>
                              <!-- <v-col class="pa-0 ma-0"  cols="12" md="12"><span><b>PRODUCTO: {{props.item.productos.descripcion}} </b></span></v-col> -->
                              <v-col class="pa-0 ma-0" cols="2" md="2">
                                 <span> {{ FormatoFecha(props.item.fechafactura)}}</span>
                                <!-- <span> {{ FormatoFecha(props.item.fecha)}}</span> -->
                              </v-col>
                              <v-col v-if="props.item.facturasventa != null" class="pa-0 ma-0" cols="2" md="2"><span
                                  :class="`d-flex justify-center`"> {{
                                    props.item.facturasventa.documentoFactura.nombrecorto + "-" +
                                    props.item.facturasventa.facturasventaPK.secuencia
                                  }}</span>
                              </v-col>
                              <v-col v-if="props.item.facturasventa != null" class="pa-0 ma-0" cols="4" md="4"><span
                                  :class="`d-flex justify-center`"> {{
                                    currencyFormatter(props.item.facturasventa.valor)
                                  }}</span></v-col>
                              <v-col v-if="props.item.notadebitoclientes != null" class="pa-0 ma-0" cols="2" md="2">
                                <span :class="`d-flex justify-center`"> {{
                                  props.item.notadebitoclientes.documentocxc.nombrecorto + "-" +
                                  props.item.notadebitoclientes.notadebitoclientesPK.secuencia
                                }}</span>
                              </v-col>
                              <v-col v-if="props.item.notadebitoclientes != null" class="pa-0 ma-0" cols="4" md="4">
                                <span :class="`d-flex justify-center`"> {{
                                  currencyFormatter(props.item.notadebitoclientes.valor)
                                }}</span>
                              </v-col>
                              <v-col class="pa-0 ma-0" cols="4" md="4"><span :class="`d-flex justify-center`"> {{
                                currencyFormatter(props.item.pendientedespues)
                              }}</span></v-col>
                              <v-col class="pa-0 ma-0" cols="12" md="12">
                                <span class="px-1" v-if="props.item.abono > 0"
                                  style=" font-size:12px; border: 1px dashed  #616161;"><b> **ABONO:</b>
                                  {{ currencyFormatter(props.item.abono) }} </span>
                                <span v-if="props.item.descuento > 0"
                                  style=" font-size:12px; border: 1px dashed  #616161;"><b> **DESCUENTO:</b>
                                  {{ props.item.descuento }} </span>
                                <span v-if="props.item.retencion > 0"
                                  style=" font-size:12px; border: 1px dashed  #616161;"><b> **RETENCIÓN:</b>
                                  {{ props.item.retencion }} </span>
                                <span v-if="props.item.isr > 0" style=" font-size:12px; border: 1px dashed  #616161;"><b>
                                    **RETENCIÓN ISR:</b> {{ props.item.isr }} </span>
                              </v-col>


                            </v-row>
                          </td>
                        </tr>

                      </template>
                    </v-data-table>





                  </div>
                  <!-------------------------------------------------- fn tb Facturas Pendientes-->
                </v-col>
              </div>


              <div v-if="ReciboIngresoFactPendHist == false">
                <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                  <span :class="`d-flex justify-center white--text`"><b>Historial</b></span>
                </v-col>

                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

                  <!--------------------------------------------------  Header tb Historial -->
                  <!-- <v-col style="background-color:#3d6687;" class="py-0 my-0" cols="12" md="12" sm="12">

                        <v-row style="border: 1px solid #000000;">
                          <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`">Fecha</b>
                          </v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"> <b :class="`d-flex justify-start white--text`">
                              #Factura</b></v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`">
                              Referencia </b></v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`"> Débito
                            </b></v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`">
                              Crédito</b></v-col>
                          <v-col class="pa-0 ma-0" cols="2" md="2"><b :class="`d-flex justify-center white--text`">
                              Balance</b></v-col>
                        </v-row>
                      </v-col> -->
                  <!-------------------------------------------------- fn Header tb Facturas Pendientes -->
                  <!-------------------------------------------------- tb Historial-->
                  <div
                    v-bind:style="{ 'height': this.windowSize.height - 30 + 'px', 'max-height': this.windowSize.height - 30 + 'px' }"
                    class="overflow-x-auto">

                    <v-data-table class="elevation-1 RowSelectColor TbSize13" :headers="[
                      { text: 'Fecha', sortable: false, value: 'fecha', width: 'auto', class: ['no-gutters black--text'] },
                      { text: 'No.Documento', sortable: false, value: 'ref', width: 'auto', class: ['no-gutters black--text'] },
                      { text: 'Descripción', sortable: false, value: 'descripcion', width: 'auto', class: ['no-gutters black--text'] },
                      { text: '$ Factura(Débito)', sortable: false, value: 'debito', width: 'auto', class: ['no-gutters black--text'] },
                      { text: '$ (Pagos)Crédito', sortable: false, value: 'credito', width: 'auto', width: 'auto', class: ['no-gutters black--text'] },
                      { text: '$ Balance', sortable: false, value: 'balance', width: 'auto', class: ['no-gutters black--text'] }
                    ]" dense :items="listHiscliente" :items-per-page="15" :footer-props="arrayPropiedases">

                      <template v-slot:item.fecha="{ item }">
                        <span>{{ FormatoFecha(item.fecha) }}</span>
                      </template>

                      <template v-slot:item.balance="{ item }">
                        <span>{{ currencyFormatter(item.balance) }}</span>
                      </template>

                      <template v-slot:item.debito="{ item }">
                        <span>{{ currencyFormatter(item.debito) }}</span>
                      </template>

                      <template v-slot:item.credito="{ item }">
                        <span>{{ currencyFormatter(item.credito) }}</span>
                      </template>

                      <template v-slot:body.append v-if="windowSize.width > 600">
                        <tr>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-top-bottom"><strong class="d-flex justify-end"
                              style="font-size:15px; color: #00838F;">Total:</strong></td>
                          <td class="border-top-bottom"><strong></strong></td>
                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.debito) }} </strong>
                          </td>
                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.credito) }} </strong>
                          </td>
                          <td class="border-total"><strong class="d-flex justify-start"
                              style="font-size:15px; color: #00838F;"> {{ currencyFormatter(totales.BalanceTotal) }}
                            </strong></td>
                        </tr>
                      </template>
                      <template v-slot:footer v-if="windowSize.width <= 600">
                        <v-list-item style="font-size: 70%;" class="white--text">
                          <v-list-item-content>
                            <span>Débito:{{ currencyFormatter(totales.debito) }}</span>
                            <span>Crédito:{{ currencyFormatter(totales.credito) }}</span>
                            <span>Balance:{{ currencyFormatter(totales.BalanceTotal) }}</span>
                          </v-list-item-content>
                        </v-list-item>

                      </template>


                    </v-data-table>
                  </div>
                  <!-------------------------------------------------- fn tb Historial-->
                </v-col>
              </div>

            </v-col>
            <!--------- fn columna 2 recibo de ingreso ---------->

          </v-row>
        </v-col>
      </v-card>
      </div>
    
     
    </v-dialog>
     </div>
    
 <!-----  Modal-Recibo de ingreso avanzado -->
 <div v-if="$store.getters.GetConfiguracionGeneral.configPv.reciboIngresoAvanzadopv==true">
   <v-dialog v-model="dialogReciboIngreso" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
      <v-card class="mx-auto">
    <v-col class="pa-0 ma-0 py-0 my-0 d-flex justify-end" style="background-color: #dd4b39" cols="12" md="12" sm="12">
      <v-btn color="grey darken-4" fab x-small @click="closeComponentsRecibosIngresos()">
        <i style="font-size: 20px; color: white" class="fa fa-close"></i>
      </v-btn>
    </v-col>

    <v-list three-line subheader>
      <ComponentsRecibosIngresos ref="RefComptsRecibosIngresos" @DataRetornComptsRecibosIngresos="DataRetornComptsRecibosIngresos" />
    </v-list>
  </v-card>
   
     <!----- fn Modal-Recibo de ingreso avanzado -->
    </v-dialog>   
  </div> 
    <!--------------------------------------------------------  fn Modal- recibo de ingreso -->


    <!-------------------------------------------------------- Modal Modal-Lista Receta -->
    <v-dialog label="Modal Receta  " v-model="dialogRecetas" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Recetas
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Recetas: </span></i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="dialogRecetas = false" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>


        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <!--  recibo busqueda receta  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-text-field v-on="on" label="Receta:" autocomplete="off" dense outlined
                        v-model="RecetaBusqueda.receta" @blur="CargarBusquedaRecetas">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la receta que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  receta paciente @change="getFacVenta"  -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-text-field v-on="on" label="Paciente:" autocomplete="off" dense outlined
                        v-model="RecetaBusqueda.paciente" @blur="CargarBusquedaRecetas">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>




                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el paciente que deseamos búscar.</span>
                    </i>
                  </v-tooltip>

                </v-col>
                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="RecetaBusqueda.fechaInicial" placeholder=" " style="font-size: 12px;"
                        @input="CargarBusquedaRecetas">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="ReimpresionBusqueda.fechaFinal" placeholder=" " style="font-size: 12px;"
                        @input="CargarBusquedaRecetas">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>


              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">
                <v-data-table class="RowSelectColor" :single-select="true" item-key="indx" :show-select="true"
                  v-model="RecetaSelected" @item-selected="RecetaObtenerDatoSelect" :headers="[{ text: 'Código', sortable: false, value: 'codigo', width: 120 },
                  { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                  { text: '# Receta', sortable: false, value: 'numero', width: 129 },
                  { text: 'Paciente', sortable: false, value: 'cliente.nombre', width: 129 },
                  { text: 'Doctor', sortable: false, value: 'medico', width: 'auto', width: 105 },


                  ]" dense :items="Recetaslist" @click:row="RecetaDatostabla" @pagination="RecetaCargarPaginacion"
                  :items-per-page="RecetaPaginator.itemsPerPage" :server-items-length="TotalRegistros"
                  v-if="resertPagi == true">
                  <template v-slot:item.action="{ item }">

                    <!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
                    <v-btn fab @click.prevent="" x-small color="red darken-3"><i style="font-size: 20px;"
                        class="fa fa-trash-o"></i></v-btn>

                  </template>
                  <template v-slot:item.fecha="props">
                    <span style="font-size:13px; display:none;">{{ props.item.indx =
                    Recetaslist.indexOf(props.item)
                    }}</span>
                    <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                  </template>

                  <template v-slot:item.codigo="props">
                    <span style="font-size:13px;">{{ props.item.codigo }} </span>
                  </template>

                  <template v-slot:item.numero="props">
                    <span style="font-size:13px;">{{ props.item.numero }} </span>
                  </template>

                  <template v-slot:item.cliente.nombre="props">
                    <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
                    </span>
                  </template>

                  <template v-slot:item.medico="props">
                    <span style="font-size:13px;">{{ props.item.medico }} </span>
                  </template>

                </v-data-table>
              </div>
            </v-col>
          </v-card>
        </v-col>

      </v-card>

    </v-dialog>
    <!-------------------------------------------------------- fn Modal- Recibo-->

    <!-- ------------------------------------------------------ Modal-Receta completa -->
    <v-dialog transition="fab-transition" label="Modal Receta completa " v-model="dialogVerRecetaCompleta" persistent
      max-width="95%" max-height="85%">

      <!--------------------- receta ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Ver Receta
                completa</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Receta: </span> Ver Receta completa
            </i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn v-on="on" color="grey darken-4" fab small @click.prevent="CerrarVerRecetaCompleta()">
                    <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
                  </v-btn>

                </template>
                <span>Cerrar: cierra la pestaña actual</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion Receta -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">

                <!-- Row 1 Receta  ------------------------------------------->
                <v-row no-gutters v-if="RecetaSeleccionado != null">
                  <v-col cols="12" md="6" sm="6">
                    <p style="border: 1px dashed  #616161;"><b> # RECETA: </b> {{ RecetaSeleccionado.numero }} </p>
                  </v-col>
                  <v-col cols="12" md="6" sm="6">
                    <p style="border: 1px dashed  #616161;"><b> FECHA: </b> {{ FormatoFecha(RecetaSeleccionado.fecha) }}
                    </p>
                  </v-col>
                  <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
                    <p v-if="RecetaSeleccionado.hasOwnProperty('faturas') && RecetaSeleccionado.faturas.length >0 " style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FACTURA: </b>
                      {{ RecetaSeleccionado.faturas }}. </p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> PACIENTE: </b>
                      {{ RecetaSeleccionado.cliente.nombre }} </p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> TELEFONO: </b>
                      {{ RecetaSeleccionado.telefono }} </p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> DIRECCIÓN: </b>
                      {{ RecetaSeleccionado.direccion }} </p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> MEDICO: </b>
                      {{ RecetaSeleccionado.medico }} </p>

                  </v-col>
                  <!-- <v-col cols="12" md="12" sm="12"></v-col> -->


                  <v-col cols="12" md="12" sm="12"
                    v-if="RecetaSeleccionado.hasOwnProperty('recetaDetalleList') && RecetaSeleccionado.recetaDetalleList.length > 0">

                    <v-data-table :headers="[{ text: 'PRODUCTO', value: 'producto.descripcion', sortable: false },
                    { text: 'CANTIDAD RECETA', value: 'cantidadRecetada', sortable: false },
                    { text: 'CANTIDAD DESPACHADA', value: 'cantidadDespachada', sortable: false },
                    ]" :items="RecetaSeleccionado.recetaDetalleList" :items-per-page="-1" class="elevation-1" dense
                      hide-default-footer @click:row="RecetaProdctoDatostabla">
                      <template v-slot:item.action="{ item }">
                        <v-btn fab @click.prevent="editTel(item)" x-small color="grey "><i style="font-size: 20px;"
                            class="fa fa-pencil"></i></v-btn>
                        <v-btn fab @click.prevent="deleTel(item)" x-small color="red darken-3"><i style="font-size: 20px;"
                            class="fa fa-trash-o"></i></v-btn>
                      </template>

                      <template v-slot:item.producto.descripcion="props">
                        <span style="font-size:13px;">{{ props.item.producto.descripcion }} </span>
                      </template>

                      <template v-slot:item.cantidadRecetada="props">
                        <span style="font-size:13px;">{{ props.item.cantidadRecetada }} </span>
                      </template>

                      <template v-slot:item.cantidadDespachada="props">
                        <span style="font-size:13px;">{{ props.item.cantidadDespachada }}</span>
                      </template>

                    </v-data-table>

                  </v-col>


                  <v-col cols="12" md="12" sm="12" v-if="recetaFacturaList.length > 0">
                    <hr style="border: 1px solid  #616161;">
                    <v-data-table :headers="[{ text: 'FECHA', value: 'fecha', sortable: false },
                    { text: 'FACTURA', value: 'secuencia', sortable: false },
                    { text: 'CANTIDAD VENDIDA', value: 'cantidad', sortable: false },
                    ]" :items="recetaFacturaList" :items-per-page="-1" class="elevation-1" dense hide-default-footer>
                      <template v-slot:item.action="{ item }">
                        <v-btn fab @click.prevent="editTel(item)" x-small color="grey "><i style="font-size: 20px;"
                            class="fa fa-pencil"></i></v-btn>
                        <v-btn fab @click.prevent="deleTel(item)" x-small color="red darken-3"><i style="font-size: 20px;"
                            class="fa fa-trash-o"></i></v-btn>
                      </template>

                      <template v-slot:item.fecha="props">
                        <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                      </template>


                      <template v-slot:item.secuencia="props">
                        <span style="font-size:13px;">{{ props.item.nombrecorto }}-{{ props.item.secuencia }} </span>
                      </template>

                      <template v-slot:item.cantidad="props">
                        <span style="font-size:13px;">{{ props.item.cantidad }}</span>
                      </template>

                    </v-data-table>

                  </v-col>






                </v-row>
                <!-- Row 2 Receta ------------------------------------------->

              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Receta -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Receta completa -->


  <!-- ------------------------------------------------------ Modal-Restaurar Factura-->
    <v-dialog label="Modal Restaurar Factura  " v-model="dialogRestaurarFactura" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Restaurar Factura
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Restaurar Factura: </span></i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="canselarBuscarRestaurarFacturasFrecuencia()"
                    color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>


        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <v-col cols="12" md="4" sm="4" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="buscar por nombre:" ref="RefTextFieldBuscarResFact" dense autocomplete="off" outlined
                        v-model="RestaurarFacturaBusqueda.buscar" @keyup="BuscarRestaurarFacturas"
                        @keydown="SelectMarcaFactTemp"
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el Nombre del cliente que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" md="4" sm="4" class="py-0 my-0">

                  <v-combobox v-if="cambiarDestinoSucursal == true" autocomplete="off" dense outlined label="Sucursal:"
                    :items="RSucursal" item-text="descripcion" :rules="[$rules.required]"
                    v-model="RestaurarFacturaBusqueda.sucursal" @blur="SelctCbSucursalFactTempSeach"
                    @focus="CargarRSucursal" @change="BuscarRestaurarFacturas">
                    <template v-slot:prepend>
                      <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                    </template>
                  </v-combobox>
                </v-col>

              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <div style="border: 1px solid #000000;">

                <v-data-table id="TbBord" item-key="indx"  :search="RestaurarFacturaBusqueda.buscar"  
                :headers="[
                { text: 'NOMBRE', sortable: true, value: 'descripcion', width: 'auto', class: ['no-gutters black--text'] }, 
                { text: 'VENDEDOR', sortable: true, value: 'vendedor', width: 'auto', class: ['no-gutters black--text'] }, 
                { text: 'TELEFONO', sortable: true, value: 'telefono', width: 'auto', class: ['no-gutters black--text'] }, 
                { text: 'FECHA', sortable: false, value: 'nuevaFecha', width: '110', class: ['no-gutters black--text'] },
                { text: 'VALOR FACTURA', sortable: false, value: 'valor', width: 'auto', class: ['no-gutters black--text'] }, 
                { text: 'HORA', sortable: false, value: 'hora', width: '110', class: ['no-gutters black--text'] },
                { text: 'Tipo factura', sortable: false, value: 'tipo', width: 'auto', class: ['no-gutters black--text'] },
                { text: 'ACCIÓN', value: 'action', width: '110', sortable: true}]" :items="RestaurarFactlistFact"
                  @click:row="RestaurarFacturaDatostabla" :footer-props="arrayPropiedases" dense
                  @pagination="cargarPaginacionAllFactTem" :items-per-page="TbPaginatorFactTem.itemsPerPage" :page.sync="TbPaginatorFactTem.page" :pageCount="TbPaginatorFactTem.pageCount" >
                  <template v-slot:item.descripcion="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item), 'border-top':getBorderColorSelectFactTem(props.item) ,'border-bottom':getBorderColorSelectFactTem(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{props.item.descripcion.trim() }} </span>
                    </v-col>
                  </template>
                  
                  <template v-slot:item.vendedor="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item), 'border-top':getBorderColorSelectFactTem(props.item) ,'border-bottom':getBorderColorSelectFactTem(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span  class="px-2"> <span v-if="props.item.vendedor!=null"> {{props.item.vendedor.nombre.trim()}} </span> </span>
                  </v-col>
                  </template>

                  <template v-slot:item.telefono="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item), 'border-top':getBorderColorSelectFactTem(props.item) ,'border-bottom':getBorderColorSelectFactTem(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span  class="px-2"> {{ RestaurarFacturaTelefono(props.item) }} </span>
                    </v-col>
                  </template>


                  <template v-slot:item.nuevaFecha="props">
                    <span style="display:none;">{{ props.item.indx = RestaurarFactlistFact.indexOf(props.item) }}</span>
                    <span style="display:none;" v-if="!props.item.hasOwnProperty('valor')">{{ props.item.valor= 0 }}</span>
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item), 'border-top':getBorderColorSelectFactTem(props.item) ,'border-bottom':getBorderColorSelectFactTem(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{ FormatoFecha(props.item.nuevaFecha) }} </span>
                    </v-col>
                  </template>

                  <template v-slot:item.valor="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item), 'border-top':getBorderColorSelectFactTem(props.item) ,'border-bottom':getBorderColorSelectFactTem(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{currencyFormatter(props.item.valor)}} </span>
                    </v-col>
                  </template>

              
                  <template v-slot:item.hora="props">
                    <v-col v-bind:style="{ 'font-size': '12px;', 'background': GetColorRestaurarFact(props.item), 'border-top':getBorderColorSelectFactTem(props.item) ,'border-bottom':getBorderColorSelectFactTem(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <input id="formdisabledInput" disabled style="font-size:12px;" type="time" :value="HoraFormatter2(props.item.hora)" />
                    </v-col>
                  </template>
<!-- 'border': '1px solid #000000'
style="border: 1px solid #000000;" -->

                  <template v-slot:item.tipo="props">
                    <v-col v-bind:style="{ 'font-size': '13px;', 'background': GetColorRestaurarFact(props.item), 'border-top':getBorderColorSelectFactTem(props.item) ,'border-bottom':getBorderColorSelectFactTem(props.item) }" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span class="px-2"> {{TipoRestaurarFactura(props.item)}} </span>
                    </v-col>
                  </template>

               
                  <template v-slot:item.action="{ item }">
                  
                    <v-btn fab @click.prevent="verificarusofacturatemporalAplicar(item)" x-small color="green" :loading="!ejecutarAplRestFact" :disabled="!ejecutarAplRestFact">
                      <i style="font-size: 20px;" class="fa fa-check"></i>
                      <template v-slot:loader>
                          <span class="custom-loader">
                            <i light style="font-size: 28px; color:#424242;" class="fa fa-refresh"> </i>

                            <!-- <v-icon light>cached</v-icon> -->
                          </span>
                        </template>
                    </v-btn>
                    <v-btn fab @click.prevent="ImprimirRestaurarFact(item)" x-small color="blue"><i
                        style="font-size: 20px;" class="fa fa-print"></i></v-btn>
                    <v-btn fab @click.prevent="LlamadeleRestaurarFact(item)" x-small color="red darken-3"><i
                        style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>
                
                  </template>





                  
                </v-data-table>
                <!------- Por Aquii -->


              </div>
            </v-col>
          </v-card>
        </v-col>

      </v-card>

    </v-dialog>
    <!-------------------------------------------------------- fn Modal- Restaurar Factura-->

    <!-- ------------------------------------------------------ Modal-Crear Cotizacion -->
    <v-dialog transition="fab-transition" label="Modal Cotizacion" v-model="dialogCotizacion" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion Cotizacion ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Cotización
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Cotización: </span> Creación de
              Cotización</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarCotizacion()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear Cotización</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarCotizacion()" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y crear Cotización</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion Cotizacion -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> COTIZACIÓN</b>
                <v-form v-model="valid" ref="formCrearProduct" lazy-validation>
                  <!-- Row 1 edicion Cotizacion     ------------------------------------------->

                  <v-row no-gutters>


                    <v-col cols="12" md="12" sm="12">
                      <v-text-field label="Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                        v-model="CotizacionFact.nombrecliente">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="4" sm="4">
                      <v-text-field label="Teléfono:" dense outlined autocomplete="off" maxlength="13"
                        v-mask="'(###)###-####'" :rules="[$rules.required, $rules.RulesTel]"
                        v-model="CotizacionFact.telefono">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-phone"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                    <v-col cols="12" md="8" sm="8">
                      <v-text-field label="Correo electrónico" dense outlined v-model="CotizacionFact.correo">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                        </template>
                      </v-text-field>

                    </v-col>



                    <v-col cols="12" md="12" sm="12">
                      <v-textarea autocomplete="off" label="Dirección:" rows="2" dense outlined
                        v-model="CotizacionFact.direccion" :rules="[$rules.required]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                        </template>
                      </v-textarea>

                      <v-text-field label="Válido Hasta:" autocomplete="off" dense outlined
                        v-model="CotizacionFact.valido">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-clock-o"></i>
                        </template>
                      </v-text-field>

                      <v-text-field label="Condición de Pago:" autocomplete="off" dense outlined
                        v-model="CotizacionFact.conceptoPago">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-money"></i>
                        </template>
                      </v-text-field>

                      <v-textarea autocomplete="off" label="Detalle:" rows="2" dense outlined
                        v-model="CotizacionFact.concepto" :rules="[$rules.required]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-commenting-o"></i>
                        </template>
                      </v-textarea>

                    </v-col>


                    <v-col cols="12" md="5" sm="5">
                      <v-checkbox class="mt-0" color="teal" label="" v-model="CotizacionImp.CotImg" width="50">
                        <template v-slot:label>
                          <strong v-if="CotizacionImp.CotImg == true" style="color:#000000; font-size:13px;">Imprimir
                            Cotización con Imagen: Si</strong>
                          <strong v-if="CotizacionImp.CotImg == false" style="color:#000000; font-size:13px;">Imprimir
                            Cotización con Imagen: No</strong>
                        </template>
                      </v-checkbox>

                    </v-col>
                    <v-col cols="12" md="2" sm="2">
                      <v-checkbox class="mt-0" color="teal" label="" v-model="CotizacionImp.ProForma" width="50">
                        <template v-slot:label>
                          <strong v-if="CotizacionImp.ProForma == true" style="color:#000000; font-size:13px;">Pro-forma:
                            Si</strong>
                          <strong v-if="CotizacionImp.ProForma == false" style="color:#000000; font-size:13px;">Pro-forma:
                            No</strong>
                        </template>
                      </v-checkbox>
                    </v-col>
                    

                    <v-col cols="12" md="2" sm="2">
                      <v-checkbox @change="quitarItbisCotizacion()"  class="mt-0 ma-3"
                            v-model="CotizacionFact.quitaritbis" label="" color="teal " hide-details>
                            <template v-slot:label>
                              <strong v-if="CotizacionFact.quitaritbis == true"
                                style="color:#000000; font-size:13px;">Quitar itbis: Si</strong>
                              <strong v-if="CotizacionFact.quitaritbis == false"
                                style="color:#000000; font-size:13px;">Quitar itbis: No</strong>
                            </template>
                          </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="3" sm="3">
                      <v-checkbox   class="mt-0 ma-3"
                            v-model="CotizacionFact.provedorEstado" label="" color="teal " hide-details>
                            <template v-slot:label>
                              <strong v-if="CotizacionFact.provedorEstado == true"
                                style="color:#000000; font-size:13px;">Proveedor del estado: Si</strong>
                              <strong v-if="CotizacionFact.provedorEstado == false"
                                style="color:#000000; font-size:13px;">Proveedor del estado: No</strong>
                            </template>
                          </v-checkbox>
                    </v-col>


                    <v-col cols="12" md="12" sm="12">
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"><b>
                          SUB-TOTAL: </b> {{ currencyFormatter(CotizacionFact.subtotal) }}
                      </p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"><b>
                          DESCUENTO : </b>
                        {{ currencyFormatter(CotizacionFact.valordescuento) }}</p>
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"><b> ITBIS :
                        </b> {{ currencyFormatter(CotizacionFact.valorimpuesto) }}</p>
                      <hr style="border:1px solid #000000;">
                      <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"><b> TOTAL :
                        </b> {{ currencyFormatter(CotizacionFact.valor) }}</p>
                      <!-- <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0 d-flex justify-end"  ><b> TOTAL : </b> {{currencyFormatter(CotizacionFact.valor-CotizacionFact.valordescuento)}}</p> -->


                      <!-- Totales.totalGeneral.total
    Totales.totalGeneral.subtotal
    Totales.totalGeneral.itbis
    Totales.totalGeneral.descuento

     -->

                    </v-col>

                    <!-- Totales.totalGeneral.subtotal,Totales.totalGeneral.itbis,Totales.totalGeneral.descuento
    this.CotizacionFact.valor=this.Totales.totalGeneral.subtotal
    this.CotizacionFact.valordescuento=this.Totales.totalGeneral.descuento
    this.CotizacionFact.valorimpuesto=this.Totales.totalGeneral.itbis -->
                  </v-row>
                  <!-- Row 2 edicion Cotizacion ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Cotizacion-->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Crear Cotizacion-->




    <!-- ------------------------------------------------------ Modal-Vale de caja -->
    <v-dialog transition="fab-transition" label="Modal Vale de caja" v-model="dialogValecaja" persistent max-width="95%"
      max-height="85%">


      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Vale de caja
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Vale de caja: </span> Creación de
              Vale de caja</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarValecaja()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear vale de caja</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarValecaja()" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y crear vale de caja </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion Vale de caja -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> VALE DE CAJA</b>
                <v-form v-model="valid" ref="formCrearValeCaja" lazy-validation>
                  <!-- Row 1 edicion Vale de caja      ------------------------------------------->

                  <v-row>
                    <v-col cols="12" md="12" sm="12">
                      <VueAutonumeric label="Valor" dense outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="Valecaja.valor" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </VueAutonumeric>


                      <v-text-field v-model="Valecaja.beneficiario" :rules="[$rules.required]" autocomplete="off"
                        label="Beneficiario:" required outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <v-text-field v-model="Valecaja.autorizadoPor" :rules="[$rules.required]" autocomplete="off"
                        label="Autorizado Por:" required outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <v-textarea :rules="[$rules.required]" autocomplete="off" label="Comentario:" rows="5" dense outlined
                        v-model="Valecaja.descripcion">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-commenting"></i>
                        </template>
                      </v-textarea>
                    </v-col>

                  </v-row>
                  <!-- Row 2 edicion Vale de caja ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Vale de caja -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Crear Vale de caja-->


    <!-- ------------------------------------------------------ Modal- Nombre factura temporal-->
    <v-dialog transition="fab-transition" label="Modal factura temporal" v-model="dialogGuardartemporal" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Creacion Nombre factura temporal ------------------------------->
      <div v-if="dialogGuardartemporal"  v-dirkeydown="finalizarGuardartemporalKeyCtrl">
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Nombre a factura
                temporal</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Nombre : </span> Asignar nombre a
              factura temporal </i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="dialogGuardartemporal = false, nombreFactTem = ''"
                    color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="ValidarFomsguardartemporal()" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y Guardar factura temporal, Al presionar la tecla (CTRL), ejecuta este boton </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
                      <span>
                          <b>Nota:</b><span style="color: #C62828;"> Salvar al presionar tecla (CTRL).</span>
                        </span>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Nombre a factura temporal</b>
                <v-form v-model="valid" @submit.prevent="" ref="formNombreFactTem" lazy-validation>
                  <!-- Row 1 edicion      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                      <v-text-field ref="RefTextFieldNombreFactTem" v-model="nombreFactTem" :rules="[$rules.required]" autocomplete="off" label="Nombre:"
                        required outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <!------------------------------- Sucursal:-->
                      <v-combobox v-if="cambiarDestinoSucursal == true" autocomplete="off" dense outlined
                        label="Sucursal:" :items="RSucursal" item-text="descripcion" :rules="[$rules.required]"
                        v-model="sucursalFactTemporal" @blur="SelctCbSucursal" @focus="CargarRSucursal">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-sitemap"></i>
                        </template>
                      </v-combobox>




                    </v-col>

                  </v-row>
                  <!-- Row 1 edicion ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Nombre factura temporal-->




    <!-------------------------------------------------------- Modal- Modal-Cierre de Cajas -->
    <v-dialog label="Modal Crear editar cliente  " v-model="dialogCierreCaja" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card v-if="dialogCierreCaja">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Cierre de Cajas
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Cierre de Cajas: </span> Crea Cierre
              de Cajas</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarCierreCaja()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="newnewCierreCaja(), OpenCierreCaja()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarCierreCaja" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Crea o edita cliente y retornar atrás </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <!-- no-gutters -->
        <v-col cols="12" md="12" sm="12">
          <v-row>
            <!--------- columna 1 Cierre ---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <!-- 285 id="divSelecProd"   v-bind:style="{  'max-height':this.windowSize.height-400+'px','border': '2px solid #000000' }" class="overflow-x-auto"  -->
              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <h1><b>Cierre #{{ CierresCajas.cierrescajasPK.secuencia }}</b></h1>
                <v-container>

                  <v-form ref="formCierre" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-card color="grey lighten-3">
                      <center><b> Tarjeta </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <!-- ----------------------------- Cheques Emitidos -->
                          <v-text-field label="(Cantidad):"  autocomplete="off" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadtarjeta" @keyup="KeyUpCantidad"
                            v-on:keyup="CalcularDenominacion" @blur="blurCantidadtarjeta" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>

                        </v-col>
                        <v-col cols="12" md="6" sm="6">
                          <!-- ----------------------------- Cheques Emitidos -->
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.tarjeta" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>
                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>
                    <v-card color="grey lighten-3">
                      <center><b> Cheque </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field label="(Cantidad):" autocomplete="off" @blur="blurCantidadcheques" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadcheques" @keyup="KeyUpCantidad"
                            v-on:keyup="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6">
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.cheques" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>

                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>
                    <v-card color="grey lighten-3">
                      <center><b> Transferencia </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadtranferencia" @blur="blurCantidadtranferencia" @keyup="KeyUpCantidad"
                            v-on:keyup="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" md="6" sm="6">
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.transferencia" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>

                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>

                    <v-card color="grey lighten-3">
                      <center><b> Recibo </b></center>
                      <v-row>
                        <v-col cols="12" md="6" sm="6">
                          <v-text-field label="(Cantidad):" autocomplete="off" style="font-size: 13px;" dense outlined
                            v-model.number="CierresCajas.cantidadrecibo" @blur="blurCantidadrecibo" @keyup="KeyUpCantidad" hide-details
                            v-on:keyup="CalcularDenominacion">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                            </template>
                          </v-text-field>


                        </v-col>

                        <v-col cols="12" md="6" sm="6">
                          <VueAutonumeric label="(Valor):" autocomplete="off" style="font-size: 13px;" required outlined
                            dense v-model="CierresCajas.recibos" @input="CalcularDenominacion" hide-details>
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-money"></i>
                            </template>
                          </VueAutonumeric>

                        </v-col>
                      </v-row>
                    </v-card>
                    <hr>
                  </v-form>
                  <!--------------------------------------------------- tb MONEDA -->

                  <v-card>
                    <v-toolbar flat dense color="#BDBDBD">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">

                          <v-btn v-on="on" @click.prevent="AddMonedaCierrecaja" color="green" fab x-small dark>
                            <i style="font-size: 20px;" class="fa fa-plus-square"></i>
                          </v-btn>


                        </template>
                        <span>Agregar Moneda</span>
                      </v-tooltip>

                      <v-toolbar-title class="font-weight-light">
                        <v-card-title>MONEDA</v-card-title>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <strong style="font-size: 15px;">{{Monedafactura.nombrecorto.trim()}}.{{ currencyFormatter(Totales.CierresCajas.totalTotal)
                      }}</strong>

                    </v-toolbar>
                    <v-data-table
                      :headers="[{ sortable: true, text: 'Valor', value: 'valor', width: 'auto' }, { sortable: false, text: 'Moneda', value: 'moneda', width: 200 }, { sortable: false, text: 'ACCIÓN', value: 'action', width: 90 }]"
                      :items="RLisMon">

                      <template v-slot:item.valor="props">
                        <!-- ----------------------------- cantidad -->
                        <VueAutonumeric label="" autocomplete="off" style="font-size: 13px;" required outlined dense
                          v-model="props.item.valor">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-money"></i>
                          </template>
                        </VueAutonumeric>


                      </template>

                      <template v-slot:item.moneda="props">
                        <!-- -----------------------------------------Moneda   @blur="SelctCbMoneda"  -->
                        <v-combobox autocomplete="off" dense outlined label="" :items="Rmoneda" item-text="nombrecorto"
                          :rules="[$rules.required]" v-model="props.item.moneda"
                          @blur="SelctCbMonedaCierre(props.item.moneda)" style="font-size:13px;" @focus="cargarMoneda">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-usd"></i>
                          </template>
                        </v-combobox>

                      </template>

                      <template v-slot:item.action="{ item }">
                        <!-- <v-btn fab @click.prevent="editMon(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                        <v-btn style="margin-top:-30px" fab @click.prevent="deleMondaCierre(item)" x-small
                          color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn>

                      </template>
                    </v-data-table>
                  </v-card>
                  <!--------------------------------------------------- fn tb  MONEDA -->


                </v-container>
              </v-col>




            </v-col>
            <!--------- fn columna 1 Cierre ---------->

            <!--------- fn columna 2 Cierre ---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <v-form ref="formDenominacionPeso" @submit.prevent="" v-model="valid" lazy-validation>
                <v-data-table color="primary" class="elevation-1" dense :headers="[{ text: 'Denominación', value: 'valor', width: 'auto' },
                { text: 'Cantidad', sortable: false, value: 'cantidad' },
                { text: 'Valor', value: 'Vtotal', width: 'auto' },
                ]" :items="ListDenomMoneda" :items-per-page="-1">


                  <template v-slot:item.valor="props">
                    <span style="font-size: 15px;">{{ currencyFormatter(props.item.valor) }}</span>
                  </template>


                  <template v-slot:item.cantidad="props">
                    <!-- ----------------------------- cantidad -->
                    <!-- <input 
      style="border: 2px solid #607D8B; font-size: 15px; "
      autocomplete="off"
      size="5"
        dense outlined
        v-model.number="props.item.cantidad"
         @keyup="KeyUpCantidad"
         v-on:keyup="CalcularDenominacion"                  
     /> -->

                    <v-text-field label="" autocomplete="off" dense outlined v-model.number="props.item.cantidad"
                      @keyup="KeyUpCantidad" @blur="blurCantNotnull(props.item)" v-on:input="CalcularDenominacion"
                      hide-details>
                    </v-text-field>

                  </template>

                  <template v-slot:item.Vtotal="props">
                    <p style="display:none;" v-if="!props.item.hasOwnProperty('cantidad')">{{ props.item.cantidad = 0 }}
                    </p>
                    <span v-bind:style="{ 'font-size': '17px;', 'color': getColor(props.item.Vtotal) }">
                    {{Monedafactura.nombrecorto.trim()}}.{{currencyFormatter(props.item.Vtotal)
                    }}</span>
                  </template>






                  <template v-slot:item.action="{ item }">
                    <!-- <v-btn fab @click.prevent="editMon(item)" x-small color="grey "><i style="font-size: 20px;" class="fa fa-pencil"></i></v-btn>  -->
                    <!-- <v-btn  fab @click.prevent="deleDenominPeso(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->
                  </template>

                  <template v-slot:body.append v-if="windowSize.width > 600">
                    <tr>
                      <td class="border-top-bottom"><strong></strong></td>
                      <td class="border-top-bottom"><strong class="d-flex justify-end"
                          style="font-size:13px;">Totales:</strong></td>
                      <td class="border-total"><strong class="d-flex justify-center" style="font-size:13px;">
                          {{ currencyFormatter(Totales.CierresCajas.totalDenomiacionvalor) }} </strong></td>
                      <td class="border-top-bottom"><strong></strong></td>
                    </tr>
                  </template>

                  <template v-slot:footer v-if="windowSize.width <= 600">
                    <v-list-item style="font-size: 80%; border: 2px solid #757575;" class="white--text">
                      <v-list-item-content>
                        <b>TOTAL: {{ currencyFormatter(Totales.CierresCajas.totalDenomiacionvalor) }}</b>
                      </v-list-item-content>
                    </v-list-item>
                  </template>




                </v-data-table>


              </v-form>


            </v-col>

          </v-row>
        </v-col>




      </v-card>
    </v-dialog>
    <!-------------------------------------------------------- fn Modal Cierre de Cajas -->


    <!-- ------------------------------------------------------ Modal-Cobertura de seguro-->
    <v-dialog transition="fab-transition" label="Modal Crear producto" v-model="dialogCoberturaSeguro" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Creacion de Cobertura de seguro------------------------------->
      <div v-if="dialogCoberturaSeguro"  v-dirkeydown="coberturaSeguroKeyCtrl">
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Cobertura de
                seguro</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Crear Cobertura de seguro: </span>
              Creación de Cobertura de seguro</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarCoberturaSeguro()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear Cobertura de seguro</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="newnewCoberturaSeguro()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarCoberturaSeguro()" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y crear Cobertura de seguro, Al presionar la tecla (CTRL), ejecuta este boton</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion producto -->
          <v-col cols="12" md="12" sm="12">
            <span>
<b>Nota:</b><span style="color: #C62828;"> Salvar al presionar tecla (CTRL).</span>
 </span>
            <v-container>
              <v-card color="grey lighten-3" >
                <b :class="`d-flex justify-center`"> CREAR COBERTURA DE SEGURO</b>
                <v-form v-model="valid" ref="formCrearCobertura" lazy-validation>
                  <!-- Row 1 Cobertura de seguro      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">
                      <!-- ----------------------------- ARS -->
                      <v-combobox autocomplete="off" dense outlined label="ARS:" :items="RArs" :rules="[$rules.required]"
                        item-text="nombre" v-model="CoberturaSeguro.ars" @blur="SelctCbArs" @focus="cargarArs"
                        >

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-shield"></i>
                        </template>
                      </v-combobox>

                      <VueAutonumeric label="Cobertura" dense outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="CoberturaSeguro.cobertura" style="font-size:13px;"
                        >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-dollar"></i>
                        </template>
                      </VueAutonumeric>
                      <!-- fa fa-usd   fa fa-medkit -->

                      <v-text-field v-model="CoberturaSeguro.autorizacion" :rules="[$rules.required]" autocomplete="off"
                        label="Autorización:" outlined dense >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-check-square-o"></i>
                        </template>
                      </v-text-field>

                      <v-text-field v-model="CoberturaSeguro.medico" autocomplete="off" label="Médico:" outlined dense
                      >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-md"></i>
                        </template>
                      </v-text-field>

                      <v-text-field v-model="CoberturaSeguro.exequartur" autocomplete="off" label="Exequartur:" outlined
                        dense >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-id-badge"></i>
                        </template>
                      </v-text-field>

                      <v-text-field v-model="CoberturaSeguro.especialidad" autocomplete="off" label="Especialidad:"
                        outlined dense >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-stethoscope"></i>
                        </template>
                      </v-text-field>

                      <v-text-field v-model="CoberturaSeguro.diagnostico" autocomplete="off" label="Diagnostico:" outlined
                        dense >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-heartbeat"></i>
                        </template>
                      </v-text-field>
                    </v-col>

                  </v-row>
                  <!-- Row 2 Cobertura de seguro ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Cobertura de seguro -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Cobertura de seguro-->




    <!-- ------------------------------------------------------ Modal-Otros Ingresos -->
    <v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogOtrosIngreso" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Creacion de Otros Ingresos ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Recibos de Otros
                Ingresos</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Recibos de Otros Ingresos: </span>
              Creación de Recibos de Otros Ingresos</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarOtrosIngreso()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear el Recibos de Otros Ingresos</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="newnewOtrosIngreso()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>



              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarOtrosIngreso()" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y crear el Recibos de Otros Ingresos </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> CREAR RECIBOS DE OTROS INGRESOS</b>
                <v-form v-model="valid" ref="formOtrosIngresos" lazy-validation>
                  <!-- Row 1      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">
                      <!-------------------------------------------------- fecha -->
                      <v-text-field dense outlined type="date" label="Fecha" v-model="OtrosIngreso.fecha"
                        :rules="[$rules.required]">

                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-calendar"></i>
                        </template>

                      </v-text-field>

                      <!-------------------------------------------------- Referencia -->
                      <v-text-field label="No.Referencia" dense outlined v-model="OtrosIngreso.referencia"
                        @blur="getDocumentoOtrosIngresos">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-signs"></i>
                        </template>
                      </v-text-field>

                      <!-- ----------------------------- nombre -->

                      <v-text-field label="* Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                        v-model="OtrosIngreso.nombre" @blur="getDocumentoOtrosIngresos">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>
                      <v-text-field label="Télefono:" dense outlined autocomplete="off" maxlength="13"
                        v-mask="'(###)###-####'" v-model="OtrosIngreso.telefono">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-phone"></i>
                        </template>
                      </v-text-field>

                      <v-text-field autocomplete="off" v-mask="MakCelRnc" label="* Cedula/RNC:" dense outlined
                        maxlength="13" :rules="[$rules.RulesCelRnc]" v-model="OtrosIngreso.identificacion"
                        @blur="MakaraCelOtroIngreso" @focus="MakCelRnc = '#############'">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                        </template>
                      </v-text-field>

                      <VueAutonumeric label="Valor" dense outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="OtrosIngreso.valor" @blur="contabilidadOtrosIngreso" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </VueAutonumeric>


                      <!-- @blur="SelctCbImpuestoProducto"
                @focus="cargarImpuestoProducto" -->
                      <v-combobox autocomplete="off" dense outlined label="Tipo:" :items="RtipoOtroIngreso"
                        item-text="nombre" style="font-size: 13px;" v-model="OtrosIngreso.tipoRecibo"
                        @blur="SelctCbTipoRecibo">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-percent"></i>
                        </template>
                      </v-combobox>

                      <!-------------------------------------------------- Concepto -->
                      <v-textarea autocomplete="off" label="Concepto" dense outlined v-model="OtrosIngreso.concepto"
                        rows="5" :rules="[$rules.required]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-commenting"></i>
                        </template>
                      </v-textarea>



                    </v-col>

                  </v-row>
                  <!-- Row 2 ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Otros Ingresos-->

    <!-- ------------------------------------------------------ Modal-Impresora Fiscal -->
    <v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogImpFiscal" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Creacion de Otros Ingresos ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Impresora Fiscal
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Impresora Fiscal: </span> Impresora
              Fiscal</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarImpresoraFiscal()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar atras</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="newnewImpresoraFiscal()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> IMPRESORA FISCAL</b>

                <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" :class="`d-flex justify-center`">
                  <!-- <v-card-actions > </v-card-actions>  -->
                  <!-- Botones de Impresora fiscal -------------------------------------->
                  <v-row :class="`d-flex justify-center`">
                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="imprimirCierreZ()"
                            v-on="on" height="50px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="30" color="transparent">
                                <img src="@/assets/icons_pv/impfiscal/cierreZ64.png" alt="CIERRE Z">
                              </v-avatar>


                              <br>
                              <b v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">CIERRE
                                Z</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> CIERRE Z</b> </span>
                    </v-tooltip>

                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="imprimirReporteX()"
                            v-on="on" height="50px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="30" color="transparent">
                                <img src="@/assets/icons_pv/impfiscal/reportes64.png" alt="REPORTE X">
                              </v-avatar>


                              <br>
                              <b v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">REPORTE
                                X</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> REPORTE X</b> </span>
                    </v-tooltip>

                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="abrirLVentas()"
                            v-on="on" height="50px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="30" color="transparent">
                                <img src="@/assets/icons_pv/impfiscal/ventas64.png" alt="L VENTAS">
                              </v-avatar>


                              <br>
                              <b v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">L
                                VENTAS</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> L VENTAS</b> </span>
                    </v-tooltip>

                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="imprimirUltimoDocumento()"
                            v-on="on" height="50px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="30" color="transparent">
                                <img src="@/assets/icons_pv/impfiscal/imprimir64.png" alt="Imp.Ult.Doc">
                              </v-avatar>


                              <br>
                              <b
                                v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">Imp.Ult.Doc</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> Imprimir Ultimo Documento </b> </span>
                    </v-tooltip>

                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="cancelarUltimoDocumento()"
                            v-on="on" height="50px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="30" color="transparent">
                                <img src="@/assets/icons_pv/impfiscal/cancelarDocumento64.png" alt="Canc.Ult.Doc">
                              </v-avatar>


                              <br>
                              <b
                                v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">Canc.Ult.Doc</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> Cancelar Ultimo Documento</b> </span>
                    </v-tooltip>
                    <!-- Botones de Impresora fiscal -------------------------------------->


                  </v-row>
                </v-col>



                <v-container>

                  <v-row>
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" style="border: 1px solid #000000;">
                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CIERRE Z RANGO DE FECHAR</b></span>
                      </v-col>
                      <v-col cols="12" md="12" sm="12">
                        <v-form v-model="valid" ref="formOtrosIngresos" lazy-validation>
                          <v-text-field dense outlined type="date" label="Fecha Inicial"
                            v-model="CierreRangoFecha.fechaInicio" :rules="[$rules.required]">

                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-calendar"></i>
                            </template>

                          </v-text-field>

                          <v-text-field dense outlined type="date" label="Fecha Final"
                            v-model="CierreRangoFecha.fechaFinal" :rules="[$rules.required]">

                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-calendar"></i>
                            </template>

                          </v-text-field>
                          <v-hover v-slot:default="{ hover }" close-delay="200">

                            <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                              :color="hover ? 'blue darken-3' : 'blue'" v-ripple="{ class: `white--text` }">

                              <h2>IMPRIMIR</h2>

                            </v-btn>
                          </v-hover>

                        </v-form>
                      </v-col>
                    </v-col>

                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="6" sm="6" style="border: 1px solid #000000;">

                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CIERRE Z RANGO DE NÚMERO</b></span>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">
                        <v-form v-model="valid" ref="formCierreRangoNumero" lazy-validation>
                          <v-text-field dense outlined type="number" label="Rango Inicial"
                            v-model.number="CierreRangoNumero.inicio" :rules="[$rules.required]">

                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-sort-numeric-asc"></i>
                            </template>

                          </v-text-field>

                          <v-text-field dense outlined type="number" label="Rango Final"
                            v-model.number="CierreRangoNumero.final" :rules="[$rules.required]">

                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-sort-numeric-desc"></i>
                            </template>

                          </v-text-field>

                          <v-hover v-slot:default="{ hover }" close-delay="200">

                            <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true" dark block
                              :color="hover ? 'blue darken-3' : 'blue'" v-ripple="{ class: `white--text` }">

                              <h2>IMPRIMIR</h2>

                            </v-btn>
                          </v-hover>

                        </v-form>
                      </v-col>

                    </v-col>
                  </v-row>
                </v-container>



              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Impresora Fiscal-->

    <!-------------------------------------------------------- Modal- Modal-Cambio Divisa -->
    <v-dialog label="Modal Crear editar cliente  " v-model="dialogCambioDivisa" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Cambio Divisa
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Cambio Divisa: </span> Crea y anular
              Cambio Divisa</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click.prevent="dialogCambioDivisa = false, NewNewCambioDivisa()"
                    color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click.prevent="NewNewCambioDivisa()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>


                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click.prevent="SalvarCambioDivisa()" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Crea Cambio Divisay retornar atrás </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-row>
            <!--------- columna 1 Crear---------->
            <v-col style="border: 1px solid #000000;" cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">
              <!-- 285 id="divSelecProd"   v-bind:style="{  'max-height':this.windowSize.height-400+'px','border': '2px solid #000000' }" class="overflow-x-auto"  -->
              <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b>Crear Cambio Divisa </b></span>
              </v-col>

              <v-form v-model="valid" ref="formCambioDivisa" lazy-validation>
                <v-row>

                  <!-- -----------------------------  Codigo -->
                  <v-col cols="12" md="12" sm="12">
                    <v-text-field id="formdisabledInput" label="Codigo:" dense outlined v-model="CambioDivisa.id"
                      :disabled="true" hide-details>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                      </template>
                    </v-text-field>
                  </v-col>


                  <!-- -----------------------------------------Moneda   @blur="SelctCbMoneda"  -->
                  <v-col cols="12" md="12" sm="12" class="py-0 my-0">
                    <v-combobox id="formdisabledInput" autocomplete="off" dense outlined label="Monedas" :items="Rmoneda"
                      item-text="nombrecorto" :rules="[$rules.required]" v-model="CambioDivisa.moneda"
                      style="font-size:13px;" @focus="cargarMoneda" :disabled="CambioDivisa.id > 0">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>

                      <template slot="item" slot-scope="item">
                        <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>-->
                        <v-col style="font-size: 13px;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                          <p class="pa-0 ma-0 py-0 my-0"><b>{{ item.item.descripcion }} ({{ item.item.nombrecorto
                          }})</b>
                          </p>
                        </v-col>
                      </template>
                    </v-combobox>
                  </v-col>

                  <!-- -----------------------------  Tasa Compra -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0" v-if="CambioDivisa.moneda != null">
                    <v-text-field id="formdisabledInput" label="Tasa Compra:" dense outlined
                      v-model.number="CambioDivisa.moneda.tasaCompra" :disabled="true">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </v-text-field>
                  </v-col>
                  <!-- -----------------------------  Tasa Ventas -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0" v-if="CambioDivisa.moneda != null">
                    <v-text-field id="formdisabledInput" label="Tasa Ventas:" dense outlined
                      v-model.number="CambioDivisa.moneda.tasaVenta" :disabled="true">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="py-0 my-0">

                    <span :class="`d-flex justify-center`"><b> OPERACIÓN </b></span>

                    <v-radio-group :disabled="CambioDivisa.id > 0" @change="cambioMonedas"
                      class="mt-0 d-flex justify-center" v-model="CambioDivisa.operacion" row>
                      <!-- <v-row></v-row>
                              <v-col cols="12" md="6"></v-col> -->
                      <v-radio label="COMPRA" color="green darken-3" :value="false"></v-radio>

                      <!-- <v-col cols="12" md="6"></v-col> -->
                      <v-radio label="VENTA" color="teal lighten-2" :value="true"></v-radio>
                    </v-radio-group>

                  </v-col>

                  <v-col cols="12" md="12" sm="12" class="py-0 my-0">

                    <!-- -----------------------------  Nombre -->
                    <v-text-field id="formdisabledInput" label="* Nombre:" autocomplete="off" dense outlined
                      :rules="[$rules.required]" v-model="CambioDivisa.nombreCliente" :disabled="CambioDivisa.id > 0">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-font"></i>
                      </template>
                    </v-text-field>


                  </v-col>


                  <!-- ----------------------------- cedula/rnc -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <v-text-field id="formdisabledInput" autocomplete="off" v-mask="MakCelRncCambDiv"
                      label="* Cedula/RNC:" dense outlined maxlength="13" :rules="[$rules.RulesCelRnc]"
                      v-model="CambioDivisa.cedulaCliente" @blur="MakaraCelCambio"
                      @focus="MakCelRncCambDiv = '#############'" :disabled="CambioDivisa.id > 0">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- ----------------------------- Télefono -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                    <v-text-field id="formdisabledInput" label="Télefono:" dense outlined autocomplete="off"
                      maxlength="13" v-mask="'(###)###-####'" v-model="CambioDivisa.telefonoClietne"
                      :disabled="CambioDivisa.id > 0">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-phone"></i>
                      </template>
                    </v-text-field>
                  </v-col>

                  <!-- ----------------------------- Cantidad a comprar -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <VueAutonumeric id="formdisabledInput" :label="tituloCompra + ':'" dense outlined
                      :disabled="CambioDivisa.id > 0" autocomplete="off" :rules="[$rules.required]" @input="cambioMonedas"
                      v-model.number="CambioDivisa.cantidadComprada" style="font-size:13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>
                  </v-col>



                  <!-- ----------------------------- Cantidad a entregar -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <VueAutonumeric id="formdisabledInput" label="Cantidad a entregar:" dense outlined disabled
                      autocomplete="off" :rules="[$rules.required]" v-model.number="CambioDivisa.cantidadEntregada"
                      style="font-size:13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>
                  </v-col>

                  <!-- ----------------------------- Cantidad a recibida:-->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <VueAutonumeric id="formdisabledInput" label="Cantidad recibida:" dense outlined
                      :disabled="CambioDivisa.id > 0" autocomplete="off" :rules="[$rules.required]" @input="cambioMonedas"
                      v-model.number="CambioDivisa.cantidadRecivida" style="font-size:13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>
                  </v-col>

                  <!-- ----------------------------- Devolver:-->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                    <VueAutonumeric id="formdisabledInput" label="Devolver:" dense outlined disabled autocomplete="off"
                      :rules="[$rules.Numericc]" v-model.number="CambioDivisa.devuelta" @input="cambioMonedas"
                      style="font-size:13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-usd"></i>
                      </template>
                    </VueAutonumeric>
                  </v-col>


                  <!-- -----------------------------  Btn Anular -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0" v-if="CambioDivisa.id > 0">
                    <v-hover v-slot:default="{ hover }" close-delay="200">

                      <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? true : false" dark block
                        @click.prevent="anularCambioDivisa" :color="hover ? 'red darken-3' : 'red'"
                        v-ripple="{ class: `white--text` }">

                        <h2>ANULAR</h2>

                      </v-btn>
                    </v-hover>
                  </v-col>

                  <!-- -----------------------------  Btn Imprimir -->
                  <v-col cols="12" md="6" sm="6" class="py-0 my-0" v-if="CambioDivisa.id > 0">
                    <v-hover v-slot:default="{ hover }" close-delay="200">

                      <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? true : false" dark block
                        @click.prevent="imprimirCambioDivisa" :color="hover ? 'blue darken-3' : 'blue'"
                        v-ripple="{ class: `white--text` }">

                        <h2>IMPRIMIR</h2>

                      </v-btn>
                    </v-hover>
                  </v-col>








                </v-row>
              </v-form>

            </v-col>
            <!--------- fn columna 1 Cambio Divisa ---------->


            <!--------- columna 2 Cambio Divisa ---------->
            <v-col style="border: 1px solid #000000; " cols="12" md="6" sm="6" class="pa-0 ma-0 py-0 my-0">

              <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                <span :class="`d-flex justify-center white--text`"><b> Buscar registro Cambio Divisa </b></span>
              </v-col>

              <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">

                <!--------------------------------------------------  Header tb Cambio Divisa -->
                <v-col class="py-0 my-0" cols="12" md="12" sm="12">
                  <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
                    <v-row>


                      <v-col cols="12" md="6" sm="6">
                        <!--  Cambio Divisa busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">

                            <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                              v-model.number="CambioDivisaBusqueda.secuencia" style="font-size: 12px;">


                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                              </template>
                            </v-text-field>


                          </template>
                          <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                            <span> Aquí se registra el número de cierre que deseamos búscar.</span>
                          </i>
                        </v-tooltip>

                        <!--  Cambio Divisa busqueda cliente  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">

                            <v-text-field v-on="on" label="Cliente:" dense autocomplete="off" outlined
                              v-model="CambioDivisaBusqueda.cliente" style="font-size: 12px;">


                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                              </template>
                            </v-text-field>


                          </template>
                          <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                            <span> Aquí se registra el nombre del cliente que deseamos búscar.</span>
                          </i>
                        </v-tooltip>

                      </v-col>


                      <v-col cols="12" md="6" sm="6">
                        <!--  Cambio Divisa busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->

                        <v-tooltip top>
                          <template v-slot:activator="{ on }">

                            <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                              v-model="CambioDivisaBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                              style="font-size: 12px;" @blur="CargarBusquedaCambioDivisa">
                              <template v-slot:prepend>
                                <i style="font-size: 18px;" class="fa fa-calendar"></i>
                              </template>
                            </v-text-field>

                          </template>
                          <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                            <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                          </i>
                        </v-tooltip>
                        <!--  Cambio Divisa busqueda Fecha final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">

                            <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                              v-model="CambioDivisaBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                              style="font-size: 12px;" @blur="CargarBusquedaCambioDivisa">
                              <template v-slot:prepend>
                                <i style="font-size: 18px;" class="fa fa-calendar"></i>
                              </template>

                              <template v-slot:append-outer>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn @click.prevent="CargarBusquedaCambioDivisa" v-on="on" color="green" small dark>
                                      <i style="font-size: 20px;" class="fa fa-search"></i>

                                    </v-btn>
                                  </template>
                                  <span>Buscar Cambio Divisa </span>
                                </v-tooltip>

                              </template>


                            </v-text-field>

                          </template>
                          <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                            <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                          </i>
                        </v-tooltip>
                      </v-col>

                    </v-row>
                  </v-form>

                </v-col>
                <!-------------------------------------------------- fn Header tb Cambio Divisa  -->
                <!-------------------------------------------------- tb Cambio Divisa 
    :single-select="true" :show-select="true" 
    -->
                <div
                  v-bind:style="{ 'margin-top': '-20px', 'height': this.windowSize.height - 150 + 'px', 'max-height': this.windowSize.height - 150 + 'px' }"
                  class="overflow-x-auto">

                  <v-data-table style="border: 1px solid #000000; " class="RowSelectColor" item-key="indx" :headers="[
                  { text: 'Fecha', sortable: false, value: 'nuevaFecha', width: 120 },
                  { text: '# Secuencia', sortable: false, value: 'id', width: 129 },
                  { text: 'Cliente', sortable: false, value: 'nombreCliente', width: 129 },
                  { text: 'Valor', sortable: false, value: 'cantidadComprada', width: 'auto', width: 105 },
                  ]" dense :items="CambioDivisalist" @click:row="CambioDivisaDatostabla" :items-per-page="-1"
                    hide-default-footer>


                    <template v-slot:item.action="{ item }">

                      <!-- <v-btn fab @click.prevent="VerReporte(item)" x-small color="yellow accent-4"><i style="font-size: 20px;" class="fa fa-eye"></i></v-btn>  -->
                      <!-- <v-btn fab @click.prevent="deleFact(item)" x-small color="red darken-3"><i style="font-size: 20px;" class="fa fa-trash-o"></i></v-btn> -->

                    </template>

                    <template v-slot:item.nuevaFecha="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =CambioDivisalist.indexOf(props.item)}}</span>
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.nuevaFecha) }}</span>
                    </template>

                    <template v-slot:item.id="props">
                      <span style="font-size:13px;">{{ props.item.id }} </span>
                    </template>

                    <template v-slot:item.nombreCliente="props">
                      <span style="font-size:13px;">{{ props.item.nombreCliente }}</span>
                    </template>


                    <template v-slot:item.cantidadComprada="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.cantidadComprada) }}</span>
                    </template>

                  </v-data-table>



                </div>
                <!-------------------------------------------------- fn tb Cambio Divisa -->



              </v-col>


            </v-col>
            <!--------- fn columna 2 Crear/editar ---------->

          </v-row>
        </v-col>
      </v-card>
    </v-dialog>
    <!--------------------------------------------------------  fn Modal- Cambio Divisa -->


    <!-- ------------------------------------------------------ Modal-Configuracion pv -->
    <v-dialog transition="fab-transition" label="M Configuracion pv " v-model="dialogConfigPv" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion de ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> CONFIGURACIÓN
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> CONFIGURACIÓN: </span>
              Configuración</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey darken-4" fab small @click="dialogConfigPv = false">
              <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
            </v-btn>

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <div class="px-2">
              <v-card color="grey lighten-3">

                <div class="px-2">

                  <v-row>
                    <!-- Columna 1 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="4" sm="4" style="border: 1px solid #000000;">

                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CONFIGURACIÓN DE CAJA</b></span>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">

                        <v-combobox v-model="caja" label="Caja" required outlined dense autocomplete="off" :items="RCaja"
                          item-text="descripcion" @blur="SelctCbCajaConfigPv" @focus="cargarCaja"
                          @change="UpdateConfigPv">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-fax"></i>
                          </template>
                        </v-combobox>

                      </v-col>
                    </v-col>
                    <!-- fn Columna 1 -->
                    <!-- Columna 2 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="4" sm="4" style="border: 1px solid #000000;">

                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CONFIGURACIÓN DE IMPRESORA</b></span>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">

                        <v-combobox v-if="false" autocomplete="off" dense outlined label="Seleccione Terminal:"
                          item-text="terminal" :items="listTerminal" v-model="terminal" @focus="findAllTerminal"
                          @change="updateImpresoraLocal">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-trademark"></i>
                          </template>
                        </v-combobox>

                        <v-combobox autocomplete="off" dense outlined label="Seleccione Impresora:" item-text="nombre"
                          :items="terminal.impresoras" v-model="OtrasConfigPv.impresora" @change="updateImpresoraLocal"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-print"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                    </v-col>
                    <!-- fn Columna 2 -->
                    <!-- Columna 3 -->
                    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="4" sm="4" style="border: 1px solid #000000;">

                      <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                        <span :class="`d-flex justify-center white--text`"><b>CAMBIO DE TURNO</b></span>
                      </v-col>

                      <v-col cols="12" md="12" sm="12">

                        <v-combobox autocomplete="off" dense outlined label="Turno:" item-text="nombre"
                          v-model="OtrasConfigPv.turno" @change="UpdateConfigPv">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-clock-o"></i>
                          </template>
                        </v-combobox>

                      </v-col>

                    </v-col>
                    <!-- fn Columna 3 -->



                  </v-row>
                </div>



              </v-card>
            </div>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Modal-Configuracion pv-->

    <!-------------------------------------------------------- Modal-Cotizacion a Factura-->
    <v-dialog label="Modal Cotizacion a Factura " v-model="dialogBucarCotizacion" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> ver Cotización
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>ver Cotización: </span> Ver
              Cotización</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>

            <v-tooltip bottom>

              <template v-slot:activator="{ on }">
                <v-btn id="boton-mini" v-on="on" @click="dialogBucarCotizacion = false, NewBucarCotizacion()"
                  color="orange" small dark>
                  <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                  CANCELAR
                </v-btn>
              </template>
              <span>CANCELAR: Cancela y retornar atrás</span>
            </v-tooltip>

          </v-card-actions>
        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="CotizacionBusqueda.secuencia" style="font-size: 12px;">


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="CotizacionBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="CotizacionBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta"  @blur="SelctCbCotizacionBuscar"-------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" autocomplete="off" ref="RefCombClienteCosFac" dense outlined label="cliente:"
                        :items="RCliente" item-text="codnom" style="font-size: 13px;" v-model="CotizacionBusqueda.cliente"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" no-filter>

                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getCotizacionlistFactCliente" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0">
              <v-data-table class="RowSelectColor" item-key="indx" @click:row="VerCotizacionDatostabla" :headers="[
                { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                { text: '# Secuencia', sortable: false, value: 'secuencia', width: 129 },
                { text: 'Cliente', sortable: false, value: 'nombrecliente', width: 129 },
                { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
              ]" dense :items="filterCotizacionAfactura(VerCotizacionlistFactCliente)" :footer-props="arrayPropiedases"
                @pagination="CotizacionCargarPaginacion" :items-per-page="CotizacionPaginator.itemsPerPage"
                :server-items-length="TotalRegistros">
                <template v-slot:item.fecha="props">

                  <span style="font-size:13px; display:none;">{{ props.item.indx =
                  VerCotizacionlistFactCliente.indexOf(props.item)
                  }}</span>
                  <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                </template>

                <template v-slot:item.secuencia="props">
                  <span style="font-size:13px;">{{ props.item.secuencia }} </span>
                </template>

                <template v-slot:item.clinica.nombre="props">
                  <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.nombrecliente }} </span>
                </template>

                <template v-slot:item.valor="props">
                  <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                </template>
              </v-data-table>
            </v-col>

          </v-card>


        </v-col>

      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Modal- fn Cotizacion a Factura--->


    <!-- ------------------------------------------------------ Modal- Datos Vuelos-->
    <v-dialog transition="fab-transition" label="Modal Datos Vuelos" v-model="dialogDatosVuelo" persistent max-width="95%"
      max-height="85%">

      <!--------------------- Creacion Datos Vuelos ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Datos Vuelos
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Datos Vuelos: </span> Creación de
              Datos Vuelos</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="dialogDatosVuelo = false" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear Datos Vuelos</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="NewDatosVuelo()" color="grey" small dark>

                    <v-avatar tile size="28" color="transparent">
                      <img src="@/assets/icons_pv/extras/limpiarPantalla.png" alt="Limpiar">
                    </v-avatar>

                    <i style="font-size: 20px;"> </i>
                    NUEVO
                  </v-btn>
                </template>
                <span>NUEVO: Limpia los campo para un nuevo registro</span>
              </v-tooltip>



              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="dialogDatosVuelo = false" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y crear Datos Vuelos </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion Datos Vuelos -->
          <v-col cols="12" md="12" sm="12">

            <div class="px-2">
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> DATOS VUELOS</b>
                <v-form v-model="valid" ref="formCrearDatoVuelo" lazy-validation>
                  <!-- Row 1 edicion Datos Vuelos      ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="6" sm="6">
                      <v-text-field v-model="Facturasventa.lineaAerea" autocomplete="off" label="Linea Aerea:" outlined
                        dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-plane"></i>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6">
                      <v-text-field v-model="Facturasventa.numeroVuelo" autocomplete="off" label="# Vuelo:" outlined
                        dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-plane"></i>
                        </template>
                      </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" sm="6">
                      <p style="font-size:18px;" class="d-flex justify-center"> <b>RUTA SALIDA</b></p>
                      <v-text-field v-model="Facturasventa.rutaSalidaE" autocomplete="off" label="E:" outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-plane"></i>
                        </template>
                      </v-text-field>

                      <v-text-field v-model="Facturasventa.rutaSalidaD" autocomplete="off" label="D:" outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-plane"></i>
                        </template>
                      </v-text-field>

                    </v-col>


                    <v-col cols="12" md="6" sm="6">

                      <p style="font-size:18px;" class="d-flex justify-center"> <b>RUTA RETORNO </b> </p>
                      <v-text-field v-model="Facturasventa.rutaRetornoE" autocomplete="off" label="E:" outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-plane"></i>
                        </template>
                      </v-text-field>

                      <v-text-field v-model="Facturasventa.rutaRetornoD" autocomplete="off" label="D:" outlined dense>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-plane"></i>
                        </template>
                      </v-text-field>
                    </v-col>


                  </v-row>
                  <!-- Row 2 edicion Datos Vuelos  ------------------------------------------->



                </v-form>
              </v-card>
            </div>
          </v-col>
          <!-- columna informacion Datos Vuelos  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Crear Datos Vuelos-->


    <!-------------------------------------------------------- Modal- Datos Del Pagare-->
    <v-dialog label="Datos Del Pagare " v-model="dialogPagare" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Datos Del Pagare
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Datos Del Pagare: </span></i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="CancelarDatosPagareFactura()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Cancela y retornar atrás</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarDatosPagareFactura" color="light-blue darken-4" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Guardar pagares y retornar atrás </span>
              </v-tooltip>


            </v-btn-toggle>

          </v-card-actions>


        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
              <!-- Infomacion Selecionada -->
              <v-row v-if="clienteFactura != null">
                <v-col cols="12" md="6" sm="6">
                  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CÓDIGO #: </b>
                    {{ clienteFactura.codigo }}</p>
                  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CLIENTE: </b>
                    {{ clienteFactura.nombre }}</p>
                  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> TELEFONO : </b>
                    {{ clienteFactura.telefono1 }}</p>
                </v-col>
                <v-col cols="12" md="6" sm="6">
                  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> DIRECCIÓN: </b>
                    {{ clienteFactura.direccion }}</p>
                  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> FECHA : </b>
                    {{ FormatoFecha(Facturasventa.fecha) }}</p>
                  <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> TOTAL FACTURA: </b>
                    {{ currencyFormatter2(Totales.totalGeneral.total) }}</p>
                </v-col>
              </v-row>
              <!-- fn Infomacion Selecionada -->

              <!--  Tabla  -->
              <div>
                <v-data-table class="RowSelectColor" item-key="indx" :headers="[
                  { text: 'Código', sortable: false, value: 'productos.codigo', width: 120, class: ['no-gutters  black--text'] },
                  { text: 'Descripción', sortable: false, value: 'productos.descripcion', width: 'auto', class: ['no-gutters  black--text'] },
                  { text: 'Cantidad', sortable: false, value: 'cantidad', width: 129, class: ['no-gutters  black--text'] },
                  { text: 'Precio', sortable: false, value: 'precio', width: 'auto', width: 105, class: ['no-gutters  black--text'] },
                  { text: 'Total', sortable: false, value: 'valor', width: 'auto', width: 105, class: ['no-gutters  black--text'] },
                ]" dense :items="RProductoCompra">

                  <template v-slot:item.productos.codigo="props">
                    <span style="font-size:13px;">{{ props.item.productos.codigo }}</span>
                  </template>

                  <template v-slot:item.productos.descripcion="props">
                    <span style="font-size:13px;">{{ props.item.productos.descripcion }} </span>
                  </template>

                  <template v-slot:item.cantidad="props">
                    <span style="font-size:13px;">{{ props.item.cantidad }} </span>
                  </template>

                  <template v-slot:item.precio="props">
                    <span style="font-size:13px;">{{ currencyFormatter2(props.item.precio) }}</span>
                  </template>

                  <template v-slot:item.valor="props">
                    <span style="font-size:13px;">{{ currencyFormatter2(props.item.valor) }}</span>
                  </template>

                </v-data-table>
              </div>
              <!-- fn Tabla  -->
            </v-col>
          </v-card>

          <!-- Row contrato y cuenta-->
          <v-form ref="formPagares" v-model="valid" lazy-validation>
            <v-row>
              <!--Columna 1  -->
              <v-col cols="12" md="6" sm="6">
                <h3 class="d-flex justify-center">Detalle del contrato</h3>

                <v-row>
                  <v-col cols="12" md="6" sm="6">
                    <!------ Fecha -->
                    <v-text-field dense outlined type="date" label="Fecha inicial:" style="font-size:13px;"
                      v-model="DatosPagareFactura.contrato.fecha" :rules="[$rules.required]">

                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-calendar"></i>
                      </template>
                    </v-text-field>
                    <!------ valor prestamo -->
                    <VueAutonumeric id="formdisabledInput" label="Valor del prestamo:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.valorPrestamo" disabled style="font-size: 13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>
                    <!------ inicio -->
                    <VueAutonumeric id="formdisabledInput" label="Inicio:" autocomplete="off" dense outlined
                      v-model.number="DatosPagareFactura.contrato.inicio" @input="calcularCapital">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-italic"></i>
                      </template>
                    </VueAutonumeric>
                    <!------ inicio -->

                    <!------ Valor Recibido: -->
                    <VueAutonumeric id="formdisabledInput" label="Valor Recivido:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.valorRecibido" disabled style="font-size: 13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                    <!------ Capital Financiado: -->
                    <VueAutonumeric id="formdisabledInput" label="Capital Financiado:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.CapitalFinanciado" style="font-size: 13px;"
                      :rules="[$rules.required]">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>


                    <!------ % de Interes: -->
                    <VueAutonumeric id="formdisabledInput" label="% de Interes:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.interes" style="font-size: 13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-percent"></i>
                      </template>
                    </VueAutonumeric>

                    <!------ Cantidad Cuotas: -->
                    <VueAutonumeric id="formdisabledInput" label="Cantidad Cuotas:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.CantidadCuotas" @input="calcularInteres"
                      style="font-size: 13px;" :rules="[$rules.required]">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-sort-numeric-asc"></i>
                      </template>
                    </VueAutonumeric>


                  </v-col>

                  <!----------------------------------------  -->
                  <v-col cols="12" md="6" sm="6">

                    <!------ Capital a pagar: -->
                    <VueAutonumeric id="formdisabledInput" label="Capital a pagar:" dense outlined disabled
                      autocomplete="off" v-model.number="DatosPagareFactura.contrato.Capitalpagar"
                      style="font-size: 13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                    <!------ Interes a pagar: -->
                    <VueAutonumeric id="formdisabledInput" label="Interes a pagar:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.Interespagar" style="font-size: 13px;" disabled>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-percent"></i>
                      </template>
                    </VueAutonumeric>

                    <!------ Monto Cuotas -->
                    <VueAutonumeric id="formdisabledInput" label="Monto Cuotas:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.MontoCuotas" style="font-size: 13px;" disabled>
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-money"></i>
                      </template>
                    </VueAutonumeric>

                    <!-- Tipo Interes -->
                    <v-combobox id="formdisabledInput" autocomplete="off" dense outlined label="Tipo Interes:"
                      :items="RTipoInteres" item-text="descripcion" style="font-size: 13px;"
                      v-model="DatosPagareFactura.contrato.TipoInteres" @change="cargarDetallePagare"
                      :rules="[$rules.required]">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-tags"></i>
                      </template>
                    </v-combobox>

                    <!------ Intervalos de días: -->
                    <v-text-field id="formdisabledInput" label="Intervalos de días:" autocomplete="off" dense outlined
                      disabled v-model="DatosPagareFactura.contrato.Intervalosdias">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-sun-o"></i>
                      </template>
                    </v-text-field>

                    <VueAutonumeric id="formdisabledInput" label="% Interes Mora:" dense outlined autocomplete="off"
                      v-model.number="DatosPagareFactura.contrato.InteresMora" style="font-size: 13px;">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-percent"></i>
                      </template>
                    </VueAutonumeric>

                    <v-text-field id="formdisabledInput" label="Prórroga:" autocomplete="off" dense outlined
                      v-model="DatosPagareFactura.contrato.prorroga">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-sun-o"></i>
                      </template>
                    </v-text-field>
                  </v-col>



                </v-row>

              </v-col>
              <!-- fn Columna 1  -->



              <!--Columna 2  -->
              <v-col cols="12" md="6" sm="6">
                <h3 class="d-flex justify-center">Distribución de cuotas</h3>
                <!--  Tabla  -->
                <div>
                  <v-data-table class="RowSelectColor" item-key="indx" :headers="[
                    { text: 'Tipo', sortable: false, value: 'tipo', width: 100, class: ['no-gutters  black--text'] },
                    { text: 'Pago #', sortable: false, value: 'pago', width: 120, class: ['no-gutters  black--text'] },
                    { text: 'Facha Venc.', sortable: false, value: 'fechaVenc', width: 129, class: ['no-gutters  black--text'] },
                    { text: 'Capital', sortable: false, value: 'capital', width: 'auto', width: 105, class: ['no-gutters  black--text'] },
                    { text: 'Interes', sortable: false, value: 'interes', width: 'auto', width: 105, class: ['no-gutters  black--text'] },
                    { text: 'Total', sortable: false, value: 'total', width: 'auto', width: 105, class: ['no-gutters  black--text'] },
                  ]" dense :items="DatosPagareFactura.Distribucioncuotas">

                    <template v-slot:item.tipo="props">
                      <span style="font-size:13px; display:none;">{{ props.item.indx =
                      DatosPagareFactura.Distribucioncuotas.indexOf(props.item)
                      }}</span>
                      <span style="font-size:13px;">{{ props.item.tipo }}</span>
                    </template>

                    <template v-slot:item.pago="props">
                      <span style="font-size:13px;">{{ props.item.pago }} </span>
                    </template>

                    <template v-slot:item.fechaVenc="props">
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.fechaVenc) }} </span>
                    </template>

                    <template v-slot:item.capital="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.capital) }}</span>
                    </template>

                    <template v-slot:item.interes="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.interes) }}</span>
                    </template>

                    <template v-slot:item.total="props">
                      <span style="font-size:13px;">{{ currencyFormatter(props.item.total) }}</span>
                      <!-- <span style="font-size:13px;" >{{ currencyFormatter(props.item.total) }}</span> -->
                    </template>

                  </v-data-table>
                </div>
                <!-- fn Tabla  -->
              </v-col>
              <!-- fn Columna 2  -->
            </v-row>
          </v-form>
          <!-- fn Row contrato y cuenta -->



        </v-col>

      </v-card>

    </v-dialog>
    <!-------------------------------------------------------- fn Modal- Datos Del Pagare-->

    <!-------------------------------------------------------- Modal Modal-Conduce a factura -->
    <v-dialog label="Modal Reimprecion " v-model="dialogBucarConduce" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> ver Conduce
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>ver Conduce: </span> Ver Conduce</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>

            <v-tooltip bottom>

              <template v-slot:activator="{ on }">
                <v-btn id="boton-mini" v-on="on" @click="dialogBucarConduce = false, NewBucarConduce()" color="orange"
                  small dark>
                  <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                  CANCELAR
                </v-btn>
              </template>
              <span>CANCELAR: Cancela y retornar atrás</span>
            </v-tooltip>

          </v-card-actions>
        </v-toolbar>

        <v-col cols="12" md="12" sm="12">
          <v-card color="grey lighten-3">
            <br>

            <v-form ref="formReimprecion" @submit.prevent="" v-model="valid" lazy-validation>
              <v-row>

                <!--  reimprecion busqueda secuencia  @blur="getCierreCaja" v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="2" sm="2" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" label="# Secuencia:" dense autocomplete="off" outlined type="number"
                        v-model.number="ConduceBusqueda.secuencia" style="font-size: 12px;">


                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                        </template>
                      </v-text-field>


                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra el número de secuencia que deseamos búscar.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Inicial @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Inicial"
                        v-model="ConduceBusqueda.fechaInicial" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha a partir de la cual queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>

                <!--  reimprecion busqueda Fecha Final @input="getCierreCaja"  v-on:input="resertPaginacion" -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">

                      <v-text-field v-on="on" dense outlined type="date" label="Fecha Final"
                        v-model="ConduceBusqueda.fechaFinal" :rules="[$rules.required]" placeholder=" "
                        style="font-size: 12px;">
                        <template v-slot:prepend>
                          <i style="font-size: 18px;" class="fa fa-calendar"></i>
                        </template>
                      </v-text-field>

                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Aquí se registra la fecha hasta donde queremos la búsqueda.</span>
                    </i>
                  </v-tooltip>
                </v-col>
                <!--  reimprecion cliente @change="getFacVenta"  -------------->
                <v-col cols="12" md="3" sm="3" class="py-0 my-0">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox v-on="on" autocomplete="off" dense outlined label="cliente:" :items="RCliente"
                        item-text="codnom" style="font-size: 13px;" v-model="ConduceBusqueda.cliente"
                        @blur="SelctCbConduceBuscar" @focus="cargarClienteBusqueda" @keyup="CbFilcliente" no-filter >
                        <template slot="item" slot-scope="item">
                  <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                  <span class="fontSize13"><b>{{ item.item.codigo}}</b> - {{item.item.nombre.trim()}}</span>
                  </template>

                  <template v-slot:selection="{ attrs, item, parent, selected }">
                    <span class="fontSize13 colorNombre"><b>{{ item.codigo}}</b> - {{item.nombre}}</span>
                  </template>
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente que desea buscar.</span>
                    </i>
                  </v-tooltip>


                </v-col>
                <v-col cols="12" md="1" sm="1" class="py-0 my-0">
                  <v-btn @click.prevent="getConducelistFactCliente" small color="teal"><i style="font-size: 20px;"
                      class="fa fa-search"></i></v-btn>
                </v-col>
              </v-row>
            </v-form>

            <v-col cols="12" md="12" sm="12" class=" pa-0 ma-0 py-0 my-0">
              <v-data-table class="RowSelectColor" item-key="indx" @click:row="VerConduceDatostabla" :headers="[
                { text: 'Fecha', sortable: false, value: 'fecha', width: 120 },
                { text: '# Secuencia', sortable: false, value: 'conduceInventarioPK.secuencia', width: 129 },
                { text: 'Cliente', sortable: false, value: 'cliente', width: 129 },
                { text: 'Valor', sortable: false, value: 'valor', width: 'auto', width: 105 },
              ]" dense :items="VerConducelistFactCliente">
                <template v-slot:item.fecha="props">
                  <span style="font-size:13px; display:none;">{{ props.item.indx =
                  VerConducelistFactCliente.indexOf(props.item)
                  }}</span>
                  <span style="font-size:13px;">{{ FormatoFecha(props.item.fecha) }}</span>
                </template>

                <template v-slot:item.conduceInventarioPK.secuencia="props">
                  <span style="font-size:13px;">{{ props.item.documentoinventario.nombrecorto }} - {{
                    props.item.conduceInventarioPK.secuencia }} </span>
                </template>

                <template v-slot:item.cliente="props">
                  <span v-if="props.item.cliente != null" style="font-size:13px;">{{ props.item.cliente.nombre }}
                  </span>
                </template>

                <template v-slot:item.valor="props">
                  <span style="font-size:13px;">{{ currencyFormatter(props.item.valor) }}</span>
                </template>
              </v-data-table>
            </v-col>

          </v-card>


        </v-col>

      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ Modal- fn Modal-Conduce a factura-->



    <!-- ------------------------------------------------------ Modal- Notificacion Producto Controlado-->
    <v-dialog transition="fab-transition" label="Modal Otros Ingresos" v-model="dialogNotifProdControlado" persistent
      max-width="55%" max-height="85%">

      <!--------------------- Creacion de Otros Ingresos ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Producto
                controlado</v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Producto cotrolado: </span>
              Producto cotrolado</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="EjecuModalProductoControlado('accept')" color="green" small
                    dark>

                    <i style="font-size: 20px;" class="fa fa-check-circle"> </i>
                    ACCEPTAR
                  </v-btn>
                </template>
                <span>ACCEPTAR: acceptar las condiciones del producto</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="EjecuModalProductoControlado('cancelar')" color="orange" small
                    dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar atras</span>
              </v-tooltip>



            </v-btn-toggle>

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col class="pa-0 ma-0 py-9 my-0" cols="12" md="12" sm="12" :class="`d-flex justify-center`">
            <center>
              <h1 class="py-9"> <b>{{ LabelProdCotrolado }}</b> </h1>
            </center>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Impresora Fiscal-->

    <!-------------------------------------------------------- Modal-Receta producto controlado -->
    <v-dialog label="Receta producto controlado" v-model="dialogProdControlado" persistent fullscreen hide-overlay
      transition="dialog-bottom-transition">
      <v-card class="mx-auto">
        <v-form ref="formRecetaProdControlado" v-model="valid" lazy-validation>

          <v-toolbar dark color="#dd4b39" dense>

            <v-tooltip bottom>
              <template v-slot:activator="{ on }">

                <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Receta de
                  producto controlado</v-toolbar-title>

              </template>
              <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Receta de producto controlado:
                </span></i>

            </v-tooltip>

            <v-spacer></v-spacer>

            <v-card-actions>
              <v-btn-toggle>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click.prevent="CancelarRecetaControlada()" color="orange" small
                      dark>
                      <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                      CANCELAR
                    </v-btn>
                  </template>
                  <span>CANCELAR: Cancela y retornar atrás</span>
                </v-tooltip>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn id="boton-mini" v-on="on" @click.prevent="SalvaeRecetaControlada()" color="light-blue darken-4"
                      small dark>
                      <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                      SALVAR
                    </v-btn>
                  </template>
                  <span>SALVAR: Guardar Receta Controlada </span>
                </v-tooltip>


              </v-btn-toggle>

            </v-card-actions>

          </v-toolbar>

          <v-col cols="12" md="12" sm="12">
            <v-card color="grey lighten-3">

              <v-row no-gutters>
                <v-text-field label="Receta No." dense autocomplete="off" outlined v-model="RecetaProdControlado.numero"
                  @blur="findReceta" style="font-size: 12px;" hide-details :rules="[$rules.required]">
                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-hashtag"></i>
                  </template>
                </v-text-field>



                <v-text-field dense outlined type="date" label="Fecha :" style="font-size:13px;"
                  v-model="RecetaProdControlado.fecha" hide-details disabled id="formdisabledInput">

                  <template v-slot:prepend>
                    <i style="font-size: 20px;" class="fa fa-calendar"></i>
                  </template>
                </v-text-field>

              </v-row>


              <v-col cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">
                <!-- Infomacion Selecionada -->
                <v-row no-gutters>
                  <v-col cols="12" md="6" sm="6" v-if="this.RecetaProdControlado.cliente != null">
                    <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <span :class="`d-flex justify-center white--text`"><b>Datos Del Paciente</b></span>
                    </v-col>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CÓDIGO #: </b>
                      {{ RecetaProdControlado.cliente.codigo }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> NOMBRE: </b>
                      {{ RecetaProdControlado.cliente.nombre }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> CÉDULA : </b>
                      {{ RecetaProdControlado.cliente.cedrnc }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> TELEFONO : </b>
                      {{ RecetaProdControlado.cliente.telefono1 }}</p>
                    <p style="border: 1px dashed  #616161;" class="pa-0 ma-0 py-0 my-0"><b> DIRECCIÓN: </b>
                      {{ RecetaProdControlado.cliente.direccion }}</p>
                  </v-col>

                  <v-col cols="12" md="6" sm="6">
                    <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                      <span :class="`d-flex justify-center white--text`"><b>Datos Del Doctor</b></span>
                    </v-col>

                    <v-row class="px-2">
                      <v-col cols="12" md="6" sm="6">
                        <v-text-field v-model="RecetaProdControlado.medico" style="font-size: 13px;" autocomplete="off"
                          label="Médico:" outlined dense :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-md"></i>
                          </template>
                        </v-text-field>
                        <v-text-field v-model="RecetaProdControlado.exq" style="font-size: 13px;" autocomplete="off"
                          label="Exequartur:" outlined dense :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-id-badge"></i>
                          </template>
                        </v-text-field>

                      </v-col>

                      <v-col cols="12" md="6" sm="6">
                        <v-text-field style="font-size: 13px;" label="Télefono:" dense outlined autocomplete="off"
                          maxlength="13" v-mask="'(###)###-####'" v-model="RecetaProdControlado.telefono">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-phone"></i>
                          </template>
                        </v-text-field>

                        <v-text-field v-model="RecetaProdControlado.especialidad" style="font-size: 13px;"
                          autocomplete="off" label="Especialidad:" outlined dense :rules="[$rules.required]">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-stethoscope"></i>
                          </template>
                        </v-text-field>
                      </v-col>

                      <v-col style="margin-top: -25px;" cols="12" md="12" sm="12">
                        <v-textarea style="font-size: 13px;" autocomplete="off" label="* Direccion:" rows="1" dense
                          outlined v-model="RecetaProdControlado.direccion">
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                          </template>
                        </v-textarea>

                        <v-combobox style="font-size: 13px;" v-model="RecetaProdControlado.centroSalud"
                          :items="RLiClinicas" label="Centro de Salud" @focus="CargarCentroSalud"
                          @blur="SelctCbCentroSalud" item-text="nombre" :rules="[$rules.required]" outlined dense>
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-ambulance"></i>
                          </template>
                        </v-combobox>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
                <!-- fn Infomacion Selecionada -->

                <!--  Tabla  -->
                <v-col style="border: 1px solid #000000;" cols="12" md="12" sm="12" class="pa-0 ma-0 py-0 my-0">

                  <v-col style="background-color:#dd4b39;" class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12">
                    <span :class="`d-flex justify-center white--text`"><b>Productos Controlados</b></span>
                  </v-col>

                  <v-data-table class="RowSelectColor TbSize13" item-key="indx" :headers="[
                    //    { text: 'Código', sortable: false,  value: 'productos.codigo', width:120, class: ['no-gutters  black--text'] },
                    { text: 'Medicamento', sortable: false, value: 'productos.descripcion', width: 'auto', class: ['no-gutters  black--text'] },
                    { text: 'Cantidad Recetada', sortable: false, value: 'cantidadRecetada', width: 'auto', class: ['no-gutters  black--text'] },
                    { text: 'cantidad Despachada', sortable: false, value: 'cantidadDespachada', width: 'auto', class: ['no-gutters  black--text'] },
                  ]" dense :items="RecetaProdControlado.detalleList">
                    <template v-slot:item.productos.descripcion="props">
                      <span v-if="props.item.productos != null" style="font-size:13px;display:none">
                        {{ props.item.producto = props.item.productos }}
                      </span>
                      <span style="font-size:13px;">{{ props.item.productos.descripcion }}
                        {{ props.item.unidad.descripcion }} </span>
                    </template>

                    <template v-slot:item.cantidadRecetada="props">
                      <!-- <span style="font-size:13px;">{{props.item.cantidadRecetada}}  </span> -->

                      <VueAutonumeric :disabled="disableNumeroReceta" id="formdisabledInput" label=""
                        style="font-size:13px;" dense hide-details outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="props.item.cantidadRecetada">
                      </VueAutonumeric>


                    </template>

                  </v-data-table>
                </v-col>
                <!-- fn Tabla  -->




              </v-col>

            </v-card>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <!-------------------------------------------------------- fn Modal- Receta producto controlado -->


    <!-- ------------------------------------------------------ Modal-comprobante fiscal -->
    <v-dialog transition="fab-transition" label="Modal comprobante fiscal" v-model="dialogComprobante" persistent
      max-width="95%" max-height="85%">
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Comprobante
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Comprobante: </span> Selección de
              Comprobante</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarComprobante()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear vale de caja</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarComprobante()" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar Comprobante </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion comprobante fiscal-->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> Comprobante</b>
                <v-form v-model="valid" ref="formComprobante" lazy-validation>
                  <!-- Row 1 edicion comprobante fiscal     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12">
                      <!-- filterTipoComprobantePv -->
                      <v-combobox autocomplete="off" dense outlined label="Tipos de Comprobante:"
                        :items="filterTipoComprobantePvItbis(RtipoComprobante)" :rules="[$rules.required]" item-text="descripcion"
                        v-model="ComprobanteFisc.comprobante" @blur="SelctCbTipoCompComprobant"
                        @focus="cargarTipoComprobante" @change="getChangeTipoComprobante"
                        v-on:change="changeCbTipoComprobante(ComprobanteFisc.comprobante,'RefTextFieldcedrncComprobante')">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-tag"></i>
                        </template>
                      </v-combobox>
              <!-- $rules.RulesCelRnc -->
                      <v-text-field v-if="cedularequired==false" autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense outlined
                        maxlength="13" :rules="[$rules.RulesCelRncValidar]" v-model="ComprobanteFisc.cedrnc"
                        @blur="MakaraCelComprobante" v-on:blur="validandoCedRnc(ComprobanteFisc.cedrnc)" @focus="MakCelRnc = '#############'">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                        </template>
                      </v-text-field>


                      <v-text-field ref="RefTextFieldcedrncComprobante" v-if="cedularequired==true" autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense outlined
                        maxlength="13" :rules="[$rules.required,$rules.RulesCelRncValidar]" v-model="ComprobanteFisc.cedrnc"
                        @blur="MakaraCelComprobante" v-on:blur="validandoCedRnc(ComprobanteFisc.cedrnc)" @focus="MakCelRnc = '#############'">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                        </template>
                      </v-text-field>


                      

                      <v-text-field label="Télefono:" dense outlined autocomplete="off" maxlength="13"
                        v-mask="'(###)###-####'" :rules="[$rules.RulesTel]" v-model="ComprobanteFisc.telefono">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-phone"></i>
                        </template>
                      </v-text-field>

                      <v-text-field label="Nombre:" autocomplete="off" dense outlined :rules="[$rules.required]"
                        v-model="ComprobanteFisc.nombre">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>


                      <v-textarea autocomplete="off" label="Direccion:" rows="1" dense outlined
                        v-model="ComprobanteFisc.direccion">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                        </template>
                      </v-textarea>
                    </v-col>

                  </v-row>
                  <!-- Row 2 edicion comprobante fiscal ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion comprobante fiscal -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-comprobante fiscal-->


<!-- ------------------------------------------------------ Modal-Crear Farmaco Vigilancia -->
<v-dialog  label="Modal Cotizacion" v-model="dialogFarmacoVigilancia" persistent 
fullscreen hide-overlay transition="dialog-bottom-transition"  >
<v-card class="mx-auto"> 
      <!--------------------- Creacion Fearmaco Vigilancia ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Tratamiento uso continuo
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span>Farmaco Vigilancia: </span> Creación de
               Farmaco Vigilancia</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarFarmacoVigilancia()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no Crear Farmaco Vigilancia</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarFarmacoVigilancia()" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y crear Farmaco Vigilancia</span>
              </v-tooltip>

              <v-tooltip bottom v-if="farmacoVigilancia.codigo>0 && farmacoVigilancia.farmacoVigilanciaDetalleList.length>0 " >
                    <template v-slot:activator="{ on }">
                      <v-btn id="boton-mini" v-on="on" @click="eliminarFarmacoVigilancia()" color="red lighten-2" small
                        dark>
                        <i style="font-size: 20px;" class="fa fa-trash-o"> </i>
                        ELIMINAR
                      </v-btn>
                    </template>
                    <span>ELIMINAR: Remueve la receta seleccionada</span>
                  </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>
        </v-toolbar>
        <v-card>
          <!-- columna informacion Farmaco Vigilancia -->
          <v-col cols="12" md="12" sm="12">
            <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >

              <v-text-field :label="buscarFarmVigBoolFactReceta==true? `#Factura:`:`#Receta:`" autocomplete="off" dense outlined 
              class="fontSize13" v-model.number="buscarFarmVigilancia" hide-details
               @keyup="KeyUpCantidad" type="number"
              >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-slack"></i>
                        </template>
                        <template v-slot:append-outer>


                          <v-radio-group hide-details dense @change="" style="margin-top: -12%;" v-model="buscarFarmVigBoolFactReceta"
                             column>
                             <v-radio label="#Receta" color="teal lighten-2" :value="false"></v-radio>
                             <v-radio label="#Factura" color="teal lighten-2" :value="true"></v-radio>
                          </v-radio-group> 
                      
<v-tooltip top>
  <template v-slot:activator="{ on }">
    <v-btn @click.prevent="buscarRecetaVigilancia" v-on="on" color="blue darken-3" fab
      x-small dark>
      <i style="font-size: 20px;" class="fa fa-search"></i>
    </v-btn>

  </template>
  <span>Buscar</span>
</v-tooltip>

<v-tooltip top>
  <template v-slot:activator="{ on }">

    <v-btn @click.prevent="LimpiarFarmacoVigilancia" v-on="on" color="#90A4AE" fab
      x-small dark>
      <i style="font-size: 20px;" class="fa fa-file-o"></i>
    </v-btn>

  </template>
  <span>Nuevo</span>
</v-tooltip>

</template>
              </v-text-field>


            </v-col>
            <!-- <v-container> -->
              <v-form v-model="valid" ref="formfarmacoVigilancia" lazy-validation>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-start`">DATOS CLIENTE</b>
                  <!-- Row 1 edicion Farmaco Vigilancia     ------------------------------------------->
                  <v-row>
                    <v-col cols="12" md="4" sm="4">

                      <v-combobox disabled id="formdisabledInput" autocomplete="off" dense outlined
                        label="Nombre:"  item-text="nombre" class="fontSize13"
                        v-model="farmacoVigilancia.cliente"  >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>

                      <v-textarea disabled id="formdisabledInput" class="fontSize13" autocomplete="off" label="Dirección:" rows="1" dense outlined
                        v-model="farmacoVigilancia.direccion" :rules="[$rules.required]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                        </template>
                      </v-textarea>

                    </v-col>

                    <v-col cols="12" md="4" sm="4">
                      <v-combobox disabled id="formdisabledInput" autocomplete="off" dense outlined label="ARS:" item-text="nombre"
                          v-model="farmacoVigilancia.ars" class="fontSize13"  >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-shield"></i>
                          </template>
                        </v-combobox>

                        <v-text-field disabled id="formdisabledInput" class="fontSize13" label="Teléfono:" dense outlined autocomplete="off" maxlength="13"
                        v-mask="'(###)###-####'" 
                        v-model="farmacoVigilancia.telefono">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-phone"></i>
                        </template>
                      </v-text-field>
                    </v-col>


                    <v-col cols="12" md="4" sm="4">
                      <v-text-field disabled id="formdisabledInput"  class="fontSize13" label="NSS:" autocomplete="off" dense outlined 
                        v-model="farmacoVigilancia.nss">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-font"></i>
                        </template>
                      </v-text-field>

                      <v-text-field disabled id="formdisabledInput" class="fontSize13" label="Autorizacion" autocomplete="off" dense outlined v-model="farmacoVigilancia.autorizacion"
                       >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-check-square-o"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Row 1 edicion Crear Farmaco Vigilancia------------------------------------------->
              </v-card>

              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-start`">DATOS DE LA RECETA</b>
                  <!-- Row 1 edicion Farmaco Vigilancia     ------------------------------------------->
                  <v-row>
                    <v-col cols="12" md="4" sm="4">

                      <v-text-field disabled id="formdisabledInput" dense outlined type="date" label="Fecha:"
                            v-model="farmacoVigilancia.fecha" :rules="[$rules.required]">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-calendar"></i>
                            </template>
                          </v-text-field>

                      <v-text-field v-model="farmacoVigilancia.medico" style="font-size: 13px;" autocomplete="off"
                          label="Médico:" outlined dense
                          ref="RefTextFieldFarViMedico"
                          >
                          <template v-slot:prepend>
                            <i style="font-size: 20px;" class="fa fa-user-md"></i>
                          </template>
                        </v-text-field>

                    </v-col>

                    <v-col cols="12" md="4" sm="4">


                    <v-text-field label="Hora:" dense outlined v-model="farmacoVigilancia.hora"
                     autocomplete="off" type="time">
                      <template v-slot:prepend>
                        <i style="font-size: 20px;" class="fa fa-clock-o"></i> 
                      </template>
                    </v-text-field>

                    <v-text-field   dense outlined type="date" label="Fecha Reposición:"
                            v-model="farmacoVigilancia.fechavencimiento" :rules="[$rules.required]">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-calendar"></i>
                            </template>
                          </v-text-field>


                    </v-col>


                    <v-col cols="12" md="4" sm="4">
                      <VueAutonumeric disabled id="formdisabledInput" label="Monto" dense outlined autocomplete="off" :rules="[$rules.required]"
                        v-model.number="farmacoVigilancia.monto" style="font-size:13px;">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-usd"></i>
                        </template>
                      </VueAutonumeric>

                      <v-text-field class="fontSize13" label="Padecimiento:" autocomplete="off" dense outlined v-model="farmacoVigilancia.padecimiento"
                       :rules="[$rules.required]">
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-heartbeat"></i>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <!-- Row 1 edicion Crear Farmaco Vigilancia------------------------------------------->
              </v-card>

              <v-card  style="border: 1px solid  #616161;">
                  <v-data-table class="elevation-1 TbSize13"  :headers="[
                    { text: 'PRODUCTO', value: 'producto', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'CANT.FACTURADA', value: 'cantfac', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'CANT.RECETADA', value: 'cantrec', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'CANT.FALTANTE', value: 'cantfal', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'INDICACIONES', value: 'cantind', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'FEC.REP', value: 'fechaReposicion', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'DETALLE', value: 'indic', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'ESTADO', sortable: false, value: 'estado', class: ['no-gutters black--text'] },

                  ]" dense :items="farmacoVigilancia.farmacoVigilanciaDetalleList" :footer-props="arrayPropiedases" :items-per-page="15">

                    <template v-slot:item.cantfal="props">          
                    <span  class="px-3" > 
                    <span v-if="(props.item.cantrec-props.item.cantfac)<0 " style="font-size: 13px;">0</span>
                    <span v-if="(props.item.cantrec-props.item.cantfac)>0" style="font-size: 13px;">{{(props.item.cantrec-props.item.cantfac)}}</span>
                    </span> 
                    </template>

                    <template v-slot:item.estado="props">          
                    <span v-if="farmacoVigilancia.codigo>0" class="px-3" > 
                     <i  v-if="props.item.cantfac>= props.item.cantrec" style="font-size: 20px;" class="fa fa-check-circle green--text"></i>
                     <i @click="props.item.estatus=true" v-if="props.item.cantfac<props.item.cantrec && props.item.estatus==false" style="font-size: 20px;" class="fa fa-times-circle-o red--text"></i>
                     <i @click="props.item.estatus=false" v-if="props.item.cantfac<props.item.cantrec && props.item.estatus==true" style="font-size: 20px;" class="fa fa-check-circle green--text"></i>
                    </span> 
                    <span v-if="farmacoVigilancia.codigo==0" class="px-3" > 
                      <i v-if="props.item.cantfac>= props.item.cantrec" style="font-size: 20px;" class="fa fa-check-circle green--text"></i>
                      <i v-if="props.item.cantfac<props.item.cantrec" style="font-size: 20px;" class="fa fa-times-circle-o red--text"></i>
                    </span> 

                    </template>

                  <template v-slot:item.producto="props">
                      <span style="font-size:13px;">{{ props.item.desprod}}</span>
                    </template>
                    
                    <template v-slot:item.cantrec="props">
                      <v-text-field   dense outlined type="number" hide-details @keyup="KeyUpCantidad"
                            v-model.number="props.item.cantrec" :rules="[$rules.Numericc]">
                            <template v-slot:append-outer>
                              <span style="font-size:13px;"></span>
                            </template>
                          </v-text-field>
                    </template>

                    <template v-slot:item.cantind="props">
                      <v-text-field dense outlined type="number" hide-details @keyup="KeyUpCantidad"
                            v-model.number="props.item.cantind " :rules="[$rules.Numericc]">
                            <template v-slot:append-outer>
                              <b style="font-size:13px;"> X DIA </b>
                            </template>
                          </v-text-field>
                    </template>

                    <template v-slot:item.indic="props">
                      <v-text-field   dense outlined type="text" hide-details
                            v-model="props.item.indic">
                          </v-text-field>
                    </template>

                    <template v-slot:item.fechaReposicion="props">
                      <v-text-field  id="formdisabledInput" dense outlined type="date" label=""
                            v-model="props.item.fechaReposicion" hide-details  class="fontSize13">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-calendar"></i>
                            </template>
                          </v-text-field>
                    </template>


                  </v-data-table>
                </v-card>

            </v-form>
            <!-- </v-container> -->
          </v-col>
          <!-- columna informacion Farmaco Vigilancia-->

        </v-card>
      </div>
    </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal-Crear Farmaco Vigilancia-->



<!-- ------------------------------------------------------Modal Modal-Consulta de recetas creadas -->
<v-dialog transition="fab-transition" label="Modal Crear producto" v-model="dialogConsultaRecetaCreada" persistent
      max-width="95%" max-height="85%">

      <!--------------------- Consulta de recetas creadas ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Consulta de recetas creadas
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Consulta: </span>recetas creadas</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">

                  <v-btn color="grey darken-4" fab x-small
                    @click="dialogConsultaRecetaCreada = false,consultaRecetaCreadaList=[], consultaRecetaFarmVig={desde:null,hasta:null}">
                    <i style="font-size: 20px; color:#dd4b39;" class="fa fa-close"></i>
                  </v-btn>
                  <!-- <v-btn id="boton-mini" v-on="on" @click="" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn> -->

                </template>
                <span>CERRAR: cierra el formulario</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion producto -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <v-form v-model="valid" ref="formConsultaRecetas" lazy-validation>
                  <!-- Row 1 edicion producto      ------------------------------------------->
                <!-- hide-details -->
                  <v-row>

                    <v-col cols="12" md="12" sm="12">

                      <v-row>
                      <v-col class="py-0 my-0" cols="12" md="6" sm="6" >
                      <v-combobox disabled id="formdisabledInput" autocomplete="off" dense outlined
                        label="Nombre:"  item-text="nombre" class="fontSize13"
                        v-model="farmacoVigilancia.cliente"   >
                        <template v-slot:prepend>
                          <i style="font-size: 20px;" class="fa fa-user-circle"></i>
                        </template>
                      </v-combobox>
                     </v-col>
                     <v-col class="py-0 my-0" cols="12" md="3" sm="3" >
                      <v-text-field id="formdisabledInput" dense outlined type="date" label="Desde:"
                            v-model="consultaRecetaFarmVig.desde" class="fontSize13" @blur="BlurDesdeFarmVig" >
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-calendar"></i>
                            </template>
                          </v-text-field>
                     </v-col>
                     <v-col class="py-0 my-0" cols="12" md="3" sm="3" >
                      <v-text-field  id="formdisabledInput" dense outlined type="date" label="Hasta:"
                            v-model="consultaRecetaFarmVig.hasta" @blur="BlurHastaFarmVig" class="fontSize13">
                            <template v-slot:prepend>
                              <i style="font-size: 20px;" class="fa fa-calendar"></i>
                            </template>
                          </v-text-field>
                     </v-col>

                    </v-row>



                      <v-card  style="border: 1px solid  #616161;">
                  <v-data-table class="elevation-1 TbSize13"  :headers="[
                    { text: '#RECETA', value: 'codigo', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'MEDICO', value: 'medico', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'FECHA', value: 'nuevaFecha', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'HORA', value: 'hora', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'TELEFONO', value: 'telefono', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'PADECIMIENTO', value: 'padecimiento', sortable: false, class: ['no-gutters black--text'] },
                    { text: 'EDITAR', value: 'action', sortable: false, class: ['no-gutters black--text'] },

                  ]" dense :items="getConsultaRecetaCreadaList(consultaRecetaCreadaList)" :footer-props="arrayPropiedases" :items-per-page="15">


                      <template v-slot:item.action="{ item }">
                        <v-btn fab @click.prevent="editConsultaRecetas(item)" x-small color="grey "><i style="font-size: 20px;"
                            class="fa fa-pencil"></i></v-btn>
                      </template>

                    <template v-slot:item.nuevaFecha="props">
                      <span style="font-size:13px;">{{ FormatoFecha(props.item.nuevaFecha)}}</span>
                    </template>
                    
                    <template v-slot:item.hora="props">
                      <input type="time" disabled id="formdisabledInput"  :value="props.item.hora" />
                    </template>

                    
                  </v-data-table>
                </v-card>

                    </v-col>
                  

                  </v-row>
                  <!-- Row 1 Consulta de recetas creadas ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion Consulta de recetas creadas-->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Consulta de recetas creadas -->



   <!-- ------------------------------------------------------ Modal- seleccion de cliente a credito con cobertura  -->
   <v-dialog transition="fab-transition" label="Modal comprobante fiscal" v-model="dialogSelectClienteTempCredito" persistent
      max-width="95%" max-height="85%">
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> cliente a crédito
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> cliente a crédito: </span> Selección de
              cliente a crédito</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="cancelarSeleccionClienteCredito()" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar y no facturar</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="SalvarSeleccionClienteCredito()" color="light-blue darken-4" small dark>
                    <i style="font-size: 20px;" class="fa fa-plus"></i><!-- fab x-small dark  -->
                    SALVAR
                  </v-btn>
                </template>
                <span>SALVAR: Aceptar y cobrar </span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

          <!-- <v-btn
              color="grey darken-4"
              fab
              small
              @click=""
            >
             <i  style="font-size: 20px; color:white;" class="fa fa-close"></i>
            </v-btn> -->
        </v-toolbar>
        <v-card>
          <!-- columna informacion comprobante fiscal-->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b style="font-size: 25px;" :class="`d-flex justify-center`"> Cliente a Crédito</b>
                <v-form v-model="valid" ref="formSeleccionClienteCreditoCobertura" lazy-validation>
                  <!-- Row 1 edicion comprobante fiscal     ------------------------------------------->

                  <v-row>

                    <v-col cols="12" md="12" sm="12" >

                      <v-col cols="12" md="12" sm="12" >
                      <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-combobox ref="RefTextFieldBuscaClienteCredito" v-on="on" autocomplete="off" background-color="white"
                        color="grey darken-4" dense outlined label="cliente a crédito:" :items="RCliente" item-text="codnom"
                        style="font-size: 13px;" v-model="clienteTempCredito" @blur="SelctCbClienteBuscarCredito"
                         @keyup.enter="SalvarSeleccionClienteCredito"
                         @change="MakaraCelAuto(clienteTempCredito)"
                        @focus="cargarClienteBusqueda" @keyup="CbFilcliente" hide-details :rules="[$rules.required]"  no-filter>
                        <template v-slot:prepend-inner>
                          <i style="font-size: 25px;" class="fa fa-search"></i>
                        </template>
                        <!-- margin-top="50px"style="font-size: 14px;" -->
                        <template slot="item" slot-scope="item">
                          <span style="display:none;">{{ item.item.codnom = `${item.item.codigo} - ${item.item.nombre.trim()} ` }}</span>
                          <!--<v-col cols="12" md="12" sm="12"> <v-row no-gutters></v-row> </v-col>
                            style="border: 1px dashed  #616161;"
                              -->
                          <v-col style="border: 1px solid #000000; font-size: 13px; " class="pa-0 ma-0 py-0 my-0"
                            cols="12" md="12" sm="12">
                            <p class="pa-0 ma-0 py-0 my-0"><b>NOMBRE:
                                {{ item.item.nombre }}</b></p>

                            <span class="pa-0 ma-0 py-0 my-0">
                              <span><b>TEL.: </b>
                                {{ selectTelefonoCliente(item.item) }}</span>
                              <span><b>CED/RNC: </b> {{ item.item.cedrnc }}</span>
                            </span>
                            <p class="pa-0 ma-0 py-0 my-0"><b>DIRECCIÓN: </b>
                              {{ item.item.direccion }} </p>
                            <span v-if="item.item.hasOwnProperty('ars') && item.item.ars != null"><b> ARS
                                :{{ item.item.ars.nombre }}</b></span>
                            <span v-if="item.item.diascredito > 0"><b> TIPO:
                                Cliente
                                Credito</b></span>
                            <span v-if="item.item.diascredito == 0"><b>TIPO:
                                Cliente
                                Contado</b></span>
                          </v-col>


                        </template>

                      </v-combobox>
                    </template>
                    <i style="font-size: 20px;" dark dense class="fa fa-info-circle">
                      <span> Se selecciona el cliente a crédito.</span>
                    </i>
                      </v-tooltip>
                      </v-col>
                      <div v-if="clienteTempCredito!=null" >
                        <v-row>
                          <!-- -----------------------------  Codigo -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                            <v-text-field id="formdisabledInput" :disabled="true" label="Codigo:" dense outlined
                              v-model="clienteTempCredito.codigo"  hide-details>
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-list-ol"></i>
                              </template>
                            </v-text-field>
                          </v-col>

                          <!-- -----------------------------  comentario -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                            <v-textarea autocomplete="off" id="formdisabledInput" :disabled="true" label="comentario:" rows="1" dense outlined
                                  v-model="clienteTempCredito.comentario">
                                  <template v-slot:prepend>
                                    <i style="font-size: 20px" class="fa fa-commenting"></i>
                                  </template>
                                </v-textarea>
                          </v-col>

                          <!-- -----------------------------  Nombre -->
                          <v-col cols="12" md="6" sm="6">
                            <v-text-field label="* Nombre:" id="formdisabledInput" :disabled="true" autocomplete="off" dense outlined :rules="[$rules.required]"
                              v-model="clienteTempCredito.nombre">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-font"></i>
                              </template>
                            </v-text-field>


                          </v-col>

                          <!-- ----------------------------- cedula/rnc -->
                          <v-col cols="12" md="6" sm="6">

                            

                            <v-text-field  autocomplete="off" v-mask="MakCelRnc" label="Cedula/RNC:" dense outlined maxlength="13"
                               v-model="clienteTempCredito.cedrnc" @blur="MakaraCel"
                              id="formdisabledInput" :disabled="true"
                              >
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-id-card-o"></i>
                              </template>
                            </v-text-field>

                          </v-col>


                          <!-- -----------------------------  Direccion -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                            <v-textarea autocomplete="off" label="* Dirección:" rows="1" dense outlined
                              v-model="clienteTempCredito.direccion">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                              </template>
                            </v-textarea>
                          </v-col>



                          <!-- ----------------------------- Télefono -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0 ">
                            <v-text-field label="Télefono:" dense outlined autocomplete="off" maxlength="13"
                              v-mask="'(###)###-####'" v-model="clienteTempCredito.telefono"
                              id="formdisabledInput" :disabled="true"
                              >
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-phone"></i>
                              </template>
                            </v-text-field>

                          </v-col>

                          <!-- -----------------------------  Direccion -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                            <v-textarea autocomplete="off" label=" Dirección2:" rows="1" dense outlined
                              v-model="clienteTempCredito.direccion2"
                              >
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                              </template>
                            </v-textarea>
                          </v-col>

                          <!-- -----------------------------  email -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                            <v-text-field label="E-mail:" dense outlined v-model="clienteTempCredito.email"
                            id="formdisabledInput" :disabled="true">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-envelope-o"></i>
                              </template>
                            </v-text-field>

                          </v-col>

                          <!-- ----------------------------- fecha de nacimiento   -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                            <v-text-field dense outlined type="date" label="Fecha de Nacimiento:"
                              v-model="clienteTempCredito.fechanacimiento"
                              id="formdisabledInput" :disabled="true"
                              >

                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-calendar"></i>
                              </template>

                            </v-text-field>

                          </v-col>




                          <!-- ----------------------------- Ciudad-->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">
                            <v-text-field label="* Ciudad:" dense outlined autocomplete="off" v-model="clienteTempCredito.ciudad"
                            id="formdisabledInput" :disabled="true"
>
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-map"></i>
                              </template>
                            </v-text-field>
                          </v-col>


                          <!-- -----------------------------sector -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                            <v-text-field label="sector:" dense outlined autocomplete="off" v-model="clienteTempCredito.sector"
                              
                              id="formdisabledInput" :disabled="true">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-map-marker"></i>
                              </template>
                            </v-text-field>

                          </v-col>


                          <!-- ----------------------------- Dia de credito -->
                          <v-col v-if="false" cols="12" md="6" sm="6" class="py-0 my-0">

                            <v-text-field label="Días de Crédito" dense outlined v-model="clienteTempCredito.diascredito"
                              @keyup="KeyUpCantidad"
                              id="formdisabledInput" :disabled="true">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-credit-card"></i>
                              </template>
                            </v-text-field>
                          </v-col>



                          <!-- ----------------------------- tipocomprobante -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                            <v-combobox autocomplete="off" dense outlined label="* Tipos de Comprobante:"
                              :items="RtipoComprobante" :rules="[$rules.required]" item-text="descripcion"
                              v-model="clienteTempCredito.tipocomprobante" @blur="SelctCbTipoComprobanteTem"
                              @focus="cargarTipoComprobante"
                              id="formdisabledInput" :disabled="true">
                              <template v-slot:prepend>
                                <i style="font-size: 20px;" class="fa fa-tag"></i>
                              </template>
                            </v-combobox>

                          </v-col>




                          <!-- -----------------------------sexo -->
                          <v-col cols="12" md="6" sm="6" class="py-0 my-0">

                            <!-- <v-text-field label="Sexo:" dense outlined
                              autocomplete="off"
                                v-model="clienteSeleccionado.sexo"
                                :rules="[$rules.required]"
                                >
                              <template v-slot:prepend>
                                      <i style="font-size: 20px;" class="fa fa-venus-mars"></i> 
                              </template>
                                </v-text-field> -->

                          </v-col>
                  
                        </v-row>

                     </div>
                      

                    </v-col>

                  </v-row>
                  <!-- Row 2 edicion comprobante fiscal ------------------------------------------->



                </v-form>
              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion comprobante fiscal -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- seleccion de cliente a credito con cobertura-->

<!-- ------------------------------------------------------ Modal Medicamentos Pendientes -->  
<v-dialog v-model="dialogMedicamentosPendientes" persistent fullscreen hide-overlay transition="dialog-bottom-transition">
  <v-card class="mx-auto">
    <ComponentsPvMedicamentosPendientes :cajaSelect="caja" :configuraciones="configuraciones" ref="RefComponentsPvMedicamentosPendientes"   @DataRetornCancelarMedicPend="dialogMedicamentosPendientes= false" />
  </v-card>
</v-dialog>
<!-- fn Restaurar Factura ----------------------------------------------------------------------------------------------->

    <!-- ------------------------------------------------------ notif-->
    <v-dialog label="myAlert" v-model="notif.estado" persistent max-width="350" transition="fab-transition">
      <template justify="center" v-slot:activator="{ on }"></template>

      <v-card>
        <v-card-title>
          <h4>
            <i style="font-size: 28px;" class="fa fa-warning"></i>
            CONFIRMACIÓN
            <i style="font-size: 28px;" class="fa fa-warning"></i>
          </h4>
        </v-card-title>
        <hr />

        <v-card-text>
          <h5>{{ notif.nombre }}</h5>
        </v-card-text>

        <hr />
        <v-card-actions>
          <v-btn v-if="notif.Exec == 'quitarCliente'"
            @click.prevent="quitarClienteFactura(), (notif.estado = !notif.estado)" small>
            <i style="font-size: 20px;" class="fa fa-check"></i> Si
          </v-btn>




          <v-btn @click.prevent="notif.estado = !notif.estado" small>
            <i style="font-size: 20px;" class="fa fa-ban"></i> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------  fn notif -->


 
    <v-dialog
      v-model="dialogNotifQuitarDescuentoCliente"
      width="500"
      persistent
    >
       <v-card class="text-center">
        <v-alert
      outlined
      type="warning"
      prominent
     border="left"
    >
    <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
      Esta factura con forma de pago a crédito se le quitara el descuento. 
    </v-col>
   <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" >
      <v-btn color="warning" outlined  @click="QuitarDescuentoClienteCredito()" >
          ACEPTAR
    </v-btn>
    </v-col>
  </v-alert>

      </v-card>
    </v-dialog>


    <!-- ------------------------------------------------------ Modal- Cliente preferencial -->
    <v-dialog transition="fab-transition" label="Modal Cliente preferencial " v-model="dialogClientePreferencial" persistent
      max-width="95%" max-height="85%">

      <!---------------------  Cliente preferencial ------------------------------->
      <div>
        <!-- <div style="max-height:345px;" class="overflow-x-auto">
    </div> -->

        <v-toolbar dark color="#dd4b39" dense>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">

              <v-toolbar-title v-on="on"> <i style="font-size: 20px;" class="fa fa-info-circle"> </i> Clientes preferencial
              </v-toolbar-title>

            </template>
            <i style="font-size: 20px;" dark dense class="fa fa-info-circle"> <span> Clientes preferencial: </span> Clientes preferencial</i>

          </v-tooltip>

          <v-spacer></v-spacer>

          <v-card-actions>
            <v-btn-toggle>


              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn id="boton-mini" v-on="on" @click="dialogClientePreferencial=false" color="orange" small dark>
                    <i style="font-size: 20px;" class="fa fa-reply-all"> </i>
                    CANCELAR
                  </v-btn>
                </template>
                <span>CANCELAR: Retornar atras</span>
              </v-tooltip>

            </v-btn-toggle>

          </v-card-actions>

        </v-toolbar>
        <v-card>
          <!-- columna informacion  -->
          <v-col cols="12" md="12" sm="12">

            <v-container>
              <v-card color="grey lighten-3">
                <b :class="`d-flex justify-center`"> CLIENTES PREFERENCIAL</b>

                <v-container>

                  <v-col class="pa-0 ma-0 py-0 my-0" cols="12" md="12" sm="12" :class="`d-flex justify-center`">
                  <!-- <v-card-actions > </v-card-actions>  -->
                  <!-- Botones de Cliente preferencial -------------------------------------->
                  <v-row :class="`d-flex justify-center`">
                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="funcionValidaPermisoPv('AccBtnRegistrarClientesPreferencial')"
                            v-on="on" height="100px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="80" color="transparent">
                                <img src="@/assets/icons_pv/menu/cliente64.png" alt="CLIENTE">
                              </v-avatar>


                              <br>
                              <b v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">REGISTRAR CLIENTES</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> REGISTRAR CLIENTES</b> </span>
                    </v-tooltip>

                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="funcionValidaPermisoPv('AccBtnConsultaClientesPreferencial')"
                            v-on="on" height="100px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="80" color="transparent">
                                <img src="@/assets/icons_pv/impfiscal/reportes64.png" alt="REPORTE X">
                              </v-avatar>


                              <br>
                              <b v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">CONSULTA DE CONSUMO CLIENTES</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> CONSULTA DE CONSUMO CLIENTES </b> </span>
                    </v-tooltip>

                    <!-- Imp.Fiscal btn ------>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-hover v-slot:default="{ hover }" close-delay="200">
                          <v-btn :elevation="hover ? 24 : 2" :outlined="hover ? false : true"
                            :color="hover ? 'red' : 'grey darken-2'" v-ripple="{ class: `white--text` }" @click.prevent="openConsultaBalancePunto()"
                            v-on="on" height="100px" min-width="110px" dark>
                            <!-- <i style="font-size: 50px;" class="fa fa-floppy-o"></i>  -->
                            <span>

                              <v-avatar tile size="80" color="transparent">
                                <img src="@/assets/icons_pv/impfiscal/ventas64.png" alt="L VENTAS">
                              </v-avatar>


                              <br>
                              <b v-bind:style="{ 'font-size': '13px', 'color': hover ? 'white' : '#000000' }">CONSULTA BALANCES PUNTOS</b>

                            </span>
                          </v-btn>
                        </v-hover>
                      </template>
                      <span><b> CONSULTA BALANCES PUNTOS</b> </span>
                    </v-tooltip>

                    <!-- Botones de Cliente preferencial -------------------------------------->


                  </v-row>
                </v-col>

                </v-container>



              </v-card>
            </v-container>
          </v-col>
          <!-- columna informacion  -->

        </v-card>
      </div>

    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Cliente preferencial-->


    <!-- ------------------------------------------------------ Modal- consulta balance punto  -->
    <v-dialog transition="fab-transition" label="Modal consulta balance punto " v-model="dialogConsultaBalancePunto" persistent
      max-width="100%" max-height="100%">
        <v-card class="mx-auto">
          <ComponentsPvModalConsultaBalancePunto ref="RefComponentsPvModalConsultaBalancePunto" @DataRetornCancelarClienteBalancePunto="dialogConsultaBalancePunto= false" />
        </v-card>
    </v-dialog>
    <!-- ------------------------------------------------------ fn Modal- consulta balance punto-->



 <!-- ------------------------------------------------------ Modal- Consulta Consumo Cliente  -->
 <v-dialog transition="fab-transition" label="Modal consulta Consumo Cliente " v-model="dialogConsultaConsumoCliente" persistent
      max-width="100%" max-height="100%">
        <v-card class="mx-auto">
          <ComponentsPvModalConsultaConsumoCliente ref="RefComponentsPvModalConsultaConsumoCliente" @DataRetornCancelarConsumoCliente="dialogConsultaConsumoCliente= false" />
        </v-card>
</v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Consulta Consumo Cliente-->

    
 <!-- ------------------------------------------------------ Modal- Crear y modificar cliente -->
 <v-dialog transition="fab-transition" label="Modal Crear y modificar cliente " v-model="dialogCrearModificarCliente" persistent
      max-width="100%" max-height="100%">
        <v-card class="mx-auto">
          <ComponentsPvModalCrearModificarCliente ref="RefComponentsPvModalCrearModificarCliente" @DataRetornCancelarCrearModificarCliente="dialogCrearModificarCliente=false" />
        </v-card>
</v-dialog>
    <!-- ------------------------------------------------------ fn Modal- Crear y modificar cliente -->


    <!-- fn modales -->

<!---------------------  Panel Menu Opcion Botones style="max-height:300px;" ------------------------------->
<div class="overflow-x-auto" v-show="false">
              <!-- columna  ---------------------->
              <ComponentsPvImprecionViewer ref="RefPvImprecionViewer" />
</div>
      


  </div>
</template>

<script>
import { EventBus } from "../../event-bus";
import VueAutonumeric from "../../components/AutoNumericNuevo";
import AutoNumericNuevoWithOptions from "../../components/AutoNumericNuevoWithOptions.vue";
import PvMenuOpcionBotones from "@/PuntodeVenta/UtilidadComponents/PvMenuOpcionBotones.vue";
import ComponentsRecibosIngresos from "@/CuentaXCobrar/Trans/RecibosIngresos.vue";
import ComponentsPvMedicamentosPendientes from "@/PuntodeVenta/UtilidadComponents/PvMedicamentosPendientesEntregar.vue";
import ComponentsPvImprecionViewer from "@/PuntodeVenta/UtilidadComponents/PvImprecionViewer.vue";
import ComponentsPvModalConsultaBalancePunto from "@/PuntodeVenta/UtilidadComponents/PvModalConsultaBalancePunto.vue";
import ComponentsPvModalConsultaConsumoCliente from "@/PuntodeVenta/UtilidadComponents/PvModalConsultaConsumoCliente.vue";
import ComponentsPvModalCrearModificarCliente from "@/PuntodeVenta/UtilidadComponents/PvModalCrearModificarCliente.vue";

import { currencyFormatter,
  FormatoFecha,
  HoraFormatter,
  HoraFormatter2,
  RedondeoValor,
  RedondeoBasico,
  RedondeoValorCantRed,
  fechaNueva,
  getParamFecha,
  validaCedRnc,
  getFechaZone,
  getHoraActual
} from "@/js/Funciones.js";

export default {
  components: {
    VueAutonumeric,
    PvMenuOpcionBotones,
    AutoNumericNuevoWithOptions,
    ComponentsRecibosIngresos,
    ComponentsPvMedicamentosPendientes,
    ComponentsPvImprecionViewer,
    ComponentsPvModalConsultaBalancePunto,
    ComponentsPvModalConsultaConsumoCliente,
    ComponentsPvModalCrearModificarCliente
  },
  directives: {
    dirkeydown: {
      // Definición de directiva
      bind(el, binding) {
        // __HandleScrollHandler__
        el.__HandledKey__ = event => binding.value(event)
        document.body.addEventListener('keydown', el.__HandledKey__)
      },
      unbind(el) {
        document.body.removeEventListener('keydown', el.__HandledKey__)
      }

    }
  },

  created() {
    var json = {
      titulo: "Punto de ventas",
      descripcion: "Punto de ventas",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      accion: [
        "ReportesChequesBeneficiarioNew",
        "ReportesChequesBeneficiarioSave",
        "ReportesChequesBeneficiarioRemove",
        "ReportesChequesBeneficiarioAnular",
        "ReportesChequesBeneficiarioPrintList",
      ],
    };
    EventBus.$emit("anularBotonesAccion", json);
    this.loadPv();
  },

  mounted() {

    this.onResize();
    // this.onkeydownCode()
  
    EventBus.$on("emitEjecConfigPv", this.validaEjecConfigPv);
    //EventBus.$on("emitReportesChequesBeneficiario", this.datostabla);
    // this.onkeydownCode()
    this.IncluirItbisGeneral =
      this.$store.getters.GetConfiguracionGeneral.configPv.impuestoincluido;
    this.ConfImpfiscal = this.$store.getters.GetConfiguracionGeneral.config.impresoraFiscal
    this.UsarLote = this.$store.getters.GetConfiguracionGeneral.configInv.usarLote
    this.cambiarDestinoSucursal = this.$store.getters.GetConfiguracionGeneral.configPv.cambiarDestino
    this.SoloCxc = this.$store.getters.GetConfiguracionGeneral.configPv.soloCxc



    if(this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios!=null && this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios==true){
      this.activarListasPrecios=this.$store.getters.GetConfiguracionGeneral.configPv.activarListasPrecios
      var CONFIGPV=this.$store.getters.GetConfiguracionGeneral.configPv
      if(CONFIGPV.hasOwnProperty('listaPrecio') && CONFIGPV.listaPrecio!=null){
       this.listaPrecioDefecto = CONFIGPV.listaPrecio
      }
      /*else{
        this.alerta(`Debe de Seleccionar una "Lista precio por defecto" en la configuracion`,"error")
      }*/
    }

    // EventBus.$on("onResize", this.onResize);
    //console.log(this.$store.getters.GetConfiguracionGeneral.configPv.soloCxc)

   // this.NotfFactRestaurarInterval()
   console.log("..")
  },

  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off("emitEjecConfigPv");
    this.canselarBuscarRestaurarFacturasFrecuencia()
    this.canselarFactRestaurarInterval()
  },

  data: () => ({
   
    disabledTextFieldProducto:false,
    arrayPropiedases: {
      'items-per-page-options': [10, 15, 20, 50],
      'items-per-page-text': 'Cantidad de Registros Por Paginas',
      showFirstLastPage: false
    },
    actualizarDecimal:true,
    contFactExcedeLimitCredito:false,
    listaPrecioDefecto:null,
    activarListasPrecios:false,
    setIntervalBuscarRestaurar:null,
    setIntervalNotfFactRestaurar:null,
    activoF7:false,
    searchVendedorCodigo:'',
    activaConduceCredito:false,
    disableNumeroReceta: false,
    UsarLote: false,
    cambiarDestinoSucursal: false,
    RSucursal: [],
    sucursalFactTemporal: null,
    ConfImpfiscal: false,
    SoloCxc: false,
    EjecutarPermisoPv: false,
    ObjEjecPermisoPv: null,
    dialogGuardartemporal: false,
    deleteFacturaTemporal: null,
    nombreFactTem: "",
    EjecutarInfSaveProd: true,
    objTemp: null,
    objTempaplicarDescuentoGlobalFacturaXencimaMaximo:null,
    MakCelRnc: "#############",
    RVendedor: [],
    Rpropietario: [],
    RCaja: [],
    Rproducto: [],
    RCuenta: [],
    RImpuesto: [],
    RCliente: [],
    RtipoComprobante: [],
    RArs: [],
    pedirNombre: false,
    configuraciones: null,
    conexion: null,
    toggle_exclusive: false,
    TasaMoneda: 1,
    CantRedondeo: 2,

    dialogNotifQuitarDescuentoCliente:false,
    dialogNotifProdControlado: false,
    LabelProdCotrolado: "",
    OpenEjecProdControlado: false,
    dialogProdControlado: false,
    dialogMedicamentosPendientes:false,
    valid: true,
    RLiClinicas: [],
    ObjRectPro: null,
    EsNuevoObjRectPro: true,
    ProductoOpcionVer: false,
    VerRecProdControlado: false,
    RecetaProdControlado: {
      codigo: 0,
      numero: null,
      exq: "",
      fecha: fechaNueva(),
      cliente: null,
      telefono: null,
      direccion: null,
      centroSalud: null,
      activo: true,
      estatus: true,
      medico: null,
      especialidad: null,
      detalleList: [],
    },

    BuscarClienteFormaPagoNC: null,
    ListFormaPagoNC: [],
    NombreFormaPagoCheqTras: "",
    impresoraConectada: false,
    selectedListFPagoNC: [],
    bancoCheque: null,
    tipoTarjeta: null,
    RTipoTarjeta: [],
    RBanco: [],
    PanelFormaPagoColmna1: true,
    PanelFormaPagoColmna2: true,
    PanelFormaPagoTarjeta: false,
    PanelFormaPagoCheque: false,
    PanelFormaPagoNotaCredito: false,
    dialogConfigPv: false,
    OtrasConfigPv: {
      caja: null,
      terminal: null,
      impresora: null,
      turno: null,
      cajero: null,
      moneda: null,
    },
    listTerminal: [],
    terminal: {
      defaulPrinter: "",
      impresoras: [],
      terminal: "",
    },
    dialogBucarCotizacion: false,
    VerCotizacionlistFactCliente: [],
    CotizacionBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },

    dialogBucarConduce: false,

    VerConducelistFactCliente: [],
    ConduceBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },

    cambioPagoDomicilio:null,
    RCambioPagoDomicilio:[
                  {codigo:1,descripcion:"EFECTIVO"},
                  {codigo:2,descripcion:"TARJETA"},
                  {codigo:3,descripcion:"CHEQUE"},
                  {codigo:4,descripcion:"TRANSFERENCIA"},
                  ],


    dialogPagare: false,
    OpenEjecDatosPagareFactura: false,
    RTipoInteres: [
      { codigo: 1, descripcion: "Dia", dia: 1, tipo: 1 },
      { codigo: 2, descripcion: "semanal", dia: 7, tipo: 1 },
      { codigo: 3, descripcion: "Quincenal", dia: 15, tipo: 1 },
      { codigo: 4, descripcion: "Mensual", dia: 30, tipo: 2 },
      { codigo: 5, descripcion: "Anual", dia: 365, tipo: 3 },
    ],

    ObjTemPagare: null,
    DatosPagareFactura: {
      contrato: {
        fecha: fechaNueva(),
        valorPrestamo: 3760.35,
        inicio: 0,
        valorRecibido: 0,
        CapitalFinanciado: 0,
        interes: 0,
        CantidadCuotas: 0,
        Capitalpagar: 0,
        Interespagar: 0,
        MontoCuotas: 0,
        TipoInteres: null,
        Intervalosdias: null,
        InteresMora: 0,
        prorroga: "",
      },

      Distribucioncuotas: [],
    },

    caja: null,

    IncluirItbisGeneral: false,
    selected: null,
    selecteTemRecCont:null,
    SelectedFactPendiente: null,
    busquedaCliente: null,
    producto: null,
    clienteFactura: null,
    dialogSelectClienteTempCredito:false,
    clienteTempCredito:null,
    filtroTipoCliente: 3,
    ReimprecionFacturaSeleccionada: {
      secuencia: 0,
      fecha: null,
      ncf: "",
      cliente: "",
      comentario: "",
      turno: 0,
      formapago: "",
      DetalleList: [],
    },

    DevolucionSelected: [],
    DevolucionlistFactCliente: [],
    devolucion: {
      devoclucionPK: { documento: 0, secuencia: 0 },
      fecha: new Date(),
      referencia: "",
      valor: 0,
      usuario: "",
      ncf: "",
      caja: 0,
      numerocierre: 0,
      efectivo: false,
      cobertura: 0,
      fechaImpresion: null,
      nif: "",
      horaImpresion: null,
      comentario: "",
      borrado: false,
      devolucionDetalleList: [],
      facturasventa: null,
      nombre: "",
      cedula: "",
      notacreditocontado: null,
      notacreditoclientes: null,
      documentoinventario: null,
      almacen: null,
    },
    DevolucionFacturaSeleccionada: {
      secuencia: 0,
      fecha: null,
      ncf: "",
      cliente: "",
      comentario: "",
      turno: 0,
      formapago: "",
      DetalleList: [],
    },

    ReimprecionSelected: [],
    ReciboIngresoFactPendHist: true,
    OpenEjecReciboIngreso: false,

    PanelReciboFormaPago: false,
    NombrePanelReciboFormaPago: "",
    devueltaRecIngreso: 0.0,
    ReciboIngresoFactura: {
      efectivo: 0,
      tarjeta: 0,
      cheque: 0,
      transferencia: 0,
      numeroCheque: null,
      bancoCheque: null,
      tipoTarjeta: null,
      numtar: null,
    },

    ReciboIngreso: {
      precio: 0,
      abono: 0,
      retencion: 0,
      descuento: 0,
      isr: 0,
      DescValorPorc: false,
      retenValorPorc: true,
      isrValorPorc: true,
    },
    dialogComprobante: false,
    ComprobanteFisc: {
      comprobante: null,
      nombre: null,
      telefono: '',
      direccion: '',
      cedrnc: ""
    },
    conduceSelect: null,
    dialogDatosVuelo: false,
    Facturasventa: {
      facturasventaPK: { secuencia: 0, documento: 0 },
      empresa: 1,
      fecha: new Date(),
      referencia: "",
      diascredito: 0,
      ncf: "",
      valor: 0.0,
      tasa: 1,
      comentario: "",
      valordescuencto: 0.0,
      valorimpuesto: 0.0,
      pendiente: 0.0,
      activada: true,
      efectivo: 0.0,
      tarjeta: 0.0,
      cheques: 0.0,
      cxc: 0.0,
      puntos: 0.0,
      valorConduce: 0.0,
      cupones: 0.0,
      cedularncncf: "",
      direccionncf: "",
      telefononcf: "",
      codigocupon: "",
      numeroCheque: "",
      nombreDgii: "",
      documentoFactura: null,
      umerocierre: 0,
      autorizacion: null,
      cobertura: null,
      medico: null,
      exequartur: null,
      diagnostico: null,
      especialidad: null,
      enviada: false,
      glosada: false,
      coberturaPendiente: null,
      arsCobrarEmpleado: false,
      arsIncobrable: false,
      arsComentario: null,
      usuario: "",
      usuarioCobro: "",
      fechaImpresion: null,
      nif: null,
      horaImpresion: null,
      receta: null,
      valorRecargo: 0,
      descGloblal: true,
      recgGlobal: false,
      numtar: null,
      valorNc: 0.0,
      codigoNc: 0,
      secuenciaNc: 0,
      retencion: 0.0,
      sumado: false,
      ncfVencimiento: null,
      medicoCodigo: null,
      totalIsr: 0.0,
      totalOtroImpuesto: 0,
      motivoAnular: null,
      facturaManual: false,
      descripcion: "",
      numeroOrden: null,
      lineaAerea: null,
      numeroVuelo: null,
      rutaSalidaE: null,
      rutaSalidaD: null,
      rutaRetornoE: null,
      rutaRetornoD: null,
      turno: null,
      gaspremium: 0.0,
      valordonacion: 0.0,
      valorbonogas: 0.0,
      valorasignacion: 0.0,
      valorcomision: 0.0,
      valorotros: 0.0,
      borrado: false,
      fechaIngreso: null,
      fechaSalida: null,
      tipoAsistencia: null,
      numeroHabitacion: null,
      facturaRecurrente: false,
      configuracionDocumentoRecurrente: 0,
      valorTraspaso: null,
      valorPruebaMetro: null,
      detalleJson: "",
      generadaDesdeEnviosPaquetes: false,
      generadaDesdeBoleteria: false,
      conduceSecuencia: null,
      conduceDocumento: null,
      shipto: null,
      incoterms: null,
      pagare: null,
      pagareValor: null,
      usuariosAnulacion: null,
      fechaAnulacion: null,
      usuariosEditar: null,
      fechaEdicion: null,
      bancoCheque: null,
      ars: null,
      caja: null,
      cliente: null,
      clinica: null,
      impuesto: null,
      moneda: null,
      sucursal: null,
      tipoTarjeta: null,
      tipocomprobante: null,
      vendedor: null,
      contabilidad: null,
      transferencia: 0.0,
      domicilio: null,
      facturasventaDetalleList: [],
      datoTarjeta: null,
      horaFactura: null
    },
    contabilidad: null,
    ObtemRListFactpendiente: [],
    RListFactpendiente: [],
    HeadInfFactPendienteSelecc: {
      fecha: fechaNueva(),
      factura: "",
      valorf: 0,
      pendiente: 0,
      ncf: "",
      referencia: "",
    },
    //ObtemFactPendienteSeleccionado:null,
    FactPendienteSeleccionado: null,
    ListaProdxAlmacen: [],
    ListProductoSustitutos: [],
    ListaProdxLote: [],
    selectedListProdxLote: [],
    productoSeleccionado: {
      cantidad: 1,
      precio: 0,
      descuento: 0,
      descuentoKeys: 0,
      vendedor: null,
      recargo: 0,
      ValorPorcRecarg: false,
      cobertura: 0,
      productos: null,
      valor: 0,
      ValorPorc: false,
    },
    cedularequired:false,
    clienteSeleccionado: {
      codigo: 0,
      nombre: null,
      cedrnc: "",
      ciudad: null,
      direccion: null,
      telefono: "",
      sector: "",
      tipocomprobante: null,
      email: "",
      sexo: "",
      dondetrabaja: "",
      diascredito: 0,
      fechanacimiento: null,
      carnet: "",
      ars: null,
      contrato: "",
      nss: "",
      planlan: 0,
      comentario:'',
      pasaporte:'',
      zonaRuta:null,
      ordenVisita:0,
      diaVisita:null,
      balancePuntos:0,
      codigoBarraPreferencial:null

    },
    unidadesProductos: {
      codigobarra: "",
      precio: 0.0,
      costo: 0.0,
      porbeneficio: 0.0,
      factor: 1,
      unidades: null,
      productos: {
        borrado: false,
        activo: false,
        codigo: 0,
        descripcion: "",
        puntoVenta: true,
        impuesto: null,
      },
      unidadminima: true,
      unidadcompra: true,
      borrado: false,
    },

    RTipoAnulacion: [
      { codigo: 1, descripcion: "Deterioro de Factura Pre-Impresa" },
      { codigo: 2, descripcion: "Errores de Impresión(Factrua Pre-Impresa" },
      { codigo: 3, descripcion: "Impresión Defectuosa" },
      { codigo: 4, descripcion: "Duplicidad de Factura" },
      { codigo: 5, descripcion: "Correción de la Información" },
      { codigo: 6, descripcion: "Cambio de Productos" },
      { codigo: 7, descripcion: "Devolución de Productos" },
      { codigo: 8, descripcion: "Omisión de Productos" },
      { codigo: 9, descripcion: "Errorres en la secuencias NCF" },
    ],
    TipoAnulacion: null,

    dialogAnulacion: false,
    AnulacionBusqueda: {
      secuencia: 0,
    },

    AnulacionlistFactCliente: [],
    AnulacionSelected: [],
    AnulacionFacturaSeleccionada: null,

    DevolucionBusqueda: {
      secuencia: 0,
    },

    BtnReimprecionvalue: 0,
    LabelReimprecion: "SELECCIONAR TIPO DE IMPRESIÓN",
    ReimprecionSheet: false,
    ReimpresionBusqueda: {
      secuencia: 0,
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      cliente: null,
    },

    // RecetaBusqueda:{
    //   receta:"",
    //   fechaInicial:new Date().toISOString().substr(0,10),
    //   fechaFinal: new Date().toISOString().substr(0,10),
    //   paciente:""

    //   },Código, Fecha,#Receta,Paciente,Doctor

    dialogVerRecetaCompleta: false,
    dialogRecetas: false,
    RecetaSelected: [],
    listFactVent: [],
    RecetaSeleccionado: null,
    recetaFacturaList: [],
    RecetaBusqueda: {
      receta: "0",
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
      paciente: "",
    },
    RecetaPaginator: {
      itemsPerPage: 15,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
    RestaurarFactlistFact: [],
    dialogRestaurarFactura: false,

    selectFactTem:null,
    TbPaginatorFactTem: {
      itemsLength: 0,
      itemsPerPage: 15,
      page: 1,
      pageCount: 0,
      pageStart: 0,
      pageStop: 0
      },
    RestaurarFacturaBusqueda: {
      buscar: "",
      sucursal: null
    },

    dialogCrearProduct: false,
    dialogCrearEditarCliente: false,
    dialogReimprecion: false,
    dialogDevolucion: false,

    dialogAceptarDevolucion: false,
    AceptarDevolucion: {
      nombre: null,
      cedula: "",
    },

    dialogReciboIngreso: false,
    PanelMenuOpcionCompleto: true,
    PanelMenuOpcionBotones: true,
    PanelMenuOpcionVendedor: false,
    PanelMenuOpcionMedico: false,
    PanelMenuOpcionDescuentoFactura: false,
    PanelMenuOpcionRetencionFactura: false,
    PanelMenuOpcionRecargoFactura: false,
    PanelMenuOpcionMonedaFactura: false,
    PanelMenuOpcionComentarioFactura: false,
    PanelMenuOpcionFacturaDomicilio: false,

    PanelSelectProducto: true,
    PanelInfProduct: false,
    boolRuleprodprecio: false,
    ProdCompOrSelecProd: false,
    EditProdCompa: false,
    rulePrecioProdBajoPrecio:0,
    boolEnterPrecioProdSelect:false,
    ReimprecionlistFactCliente: [],
    Recetaslist: [],
    ListaTiposClientes: [],
    RallProducto: [],
    RProductoCompra: [],
    RProductoCompraTemp: [],
    RProductoCompraOfercta: [],
    RHistorialCompracliente: [],
    TbTotalItemHisCli: 0,
    TbPaginatorHisCli: {
      page: 1,
      itemsPerPage: 10,
      pageStart: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },


    listHiscliente: [],
    totales: {
      debito: 0,
      credito: 0,
      BalanceTotal: 0
    },

    windowSize: { width: "auto", height: "auto" },
    windowSizeCaja: { width: "auto", height: "auto" },

    searchProducto: "",

    formaPago: {
      efectivo: 0,
      tarjeta: 0,
      cheques: 0,
      cxc: 0,
      puntos: 0,
      cupones: 0,
    },

    CrearProducto: {
      nombre: "",
      precio: 0,
      costo: 0,
      impuesto: null,
    },

    TotalRegistros: 1,
    resertPagi: true,
    ReimprecionPaginator: {
      itemsPerPage: 15,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },
    CotizacionPaginator: {
      itemsPerPage: 15,
      pageStart: 1,
      page: 1,
      pageStop: 15,
      pageCount: 10,
      itemsLength: 5,
    },

    dialogValecaja: false,
    Valecaja: {
      fecha: fechaNueva(),
      valor: 0,
      beneficiario: "",
      Autorizado: "",
      Concepto: "",
      secuencia: 0,
      caja: null,
      numerocierre: 0,
      cajero: null,
    },
    ListReimprecion: [],
    dialogCierreCaja: false,
    Rmoneda: [],
    RLisMon: [],
    ListDenomMoneda: [],
    CierresCajas: {
      cierrescajasPK: { caja: 0, secuencia: 0 },
      cantidadtarjeta: 0,
      tarjeta: 0,
      cantidadcheques: 0,
      cheques: 0,
      cantidadrecibo: 0,
      recibos: 0,
      efectivo: 0,
      cierrecajaMonedaList: [],
      DenominacionCierrecajaList: [],
      datoTarjeta: null,
      cantidadtranferencia:0,
      transferencia:0,

    },
    OpenEjecCoberturaSeguro: false,
    dialogCoberturaSeguro: false,
    objTempCobertura: null,
    CoberturaSeguro: {
      ars: null,
      cobertura: 0,
      autorizacion: "",
      medico: "",
      exequartur: "",
      especialidad: "",
      diagnostico: "",
    },
    RtipoOtroIngreso: [
      { codigo: 1, nombre: "INGRESO A CAJA" },
      { codigo: 2, nombre: "INGRESO A BANCO" },
    ],
    dialogOtrosIngreso: false,
    OtrosIngreso: {
      //secuencia2: 12
      //secuencia: 20
      activo: true,
      caja: 1,
      concepto: "",
      documento: null,
      fecha: new Date(),
      identificacion: "",
      nombre: "",
      referencia: "",
      telefono: "",
      tipoRecibo: null,
      valor: 0,
    },
    dialogClientePreferencial: false,
    dialogConsultaBalancePunto:false,
    dialogConsultaConsumoCliente:false,
    dialogCrearModificarCliente:false,
    dialogImpFiscal: false,
    CierreRangoFecha: {
      fechaInicio: fechaNueva(),
      fechaFinal: fechaNueva(),
    },
    devuelta: 0.0,
    MakCelRncCambDiv: "#############",
    dialogCambioDivisa: false,
    CambioDivisalist: [],
    CambioDivisa: {
      id: 0,
      nombreCliente: "",
      telefonoClietne: "",
      cedulaCliente: "",
      operacion: true,
      tasaCompra: 0,
      tasaVenta: 0,
      cantidadRecivida: 0,
      devuelta: 0,
      cuadre: 0,
      caja: 0,
      usuario: null,
      fecha: new Date(),
      usuarioAnulado: null,
      fechaAnulada: null,
      cantidadEntregada: 0,
      cantidadComprada: 0,
      activada: true,
      direccionCliente: null,
      cajero: null,
      borrado: false,
      moneda: null,
    },
    CambioDivisaBusqueda: {
      secuencia: 0,
      cliente: "0",
      fechaInicial: fechaNueva(),
      fechaFinal: fechaNueva(),
    },
    CierreRangoNumero: {
      inicio: 0,
      final: 0,
    },
    RVendedor: [],
    SelectVendedorFactura: null,
    RMedico: [],
    SelectMedicoFactura: null,
    Descuentofactura: {
      ValorPorc: true,
      descuento: 0,
      DescuentoFacturaGlobal: false
    },
    Retencionfactura: {
      ValorPorc: true,
      Retencion: 0,
    },
    Recargofactura: {
      recargo: 0,
      ValorPorc: true,
    },
    Otros: {
      comentario: "",
      numeroOrden: "",
      ShipTo: "",
      incoterms: "",
    },
    Monedafactura: null,
    tituloCompra: "",
    RDireccionDomicilio: [],
    RFacturasRutasMensajeros:[],
    OpenEjecFacturaDomicilio: false,
    domiciliofactura: null,
    dialogMensajero: false,
    dialogRecibirMensajeros: false,
    dialogAsignarMensajeros: false,
    dialogFacturasRutasMensajeros:false,
    filtroFacturaRutaCliente:'',
    filtroAsigMensajero:{
    fact:'',
    sector:'',
    caja:null
    },
    nuevoMensajeroSelect:null,
    dialogCambiarMensajeros:false,
    selectedCambiarMensajeros: [],
    selectedAsignarMensajero: [],
    selectedRecibirMensajeros: [],
    VerFacturaDomicilioMensajero: false,
    FacturaDomicilioMensajero: null,
    buscarMensajero: null,
    Rmensajero: [],
    RRecibirMensajeros: [],
    RFacturasDomicilio: [],
    MensageroSeleccionado: {
      codigo: 0,
      nombre: "",
      telefono: "",
      cedula: "",
      metadeenvios: 0,
      sucursal: { descripcion: "" },
    },
    totalDomicilio:{
      valor:0
    },
    CotizacionImp: { CotImg: false, ProForma: false},
    dialogCotizacion: false,
    cotizacionAfacturaSelect:null,
    CotizacionFact: {
      secuencia: 0,
      activo: false,
      caja: null,
      concepto: "",
      conceptoPago: "",
      correo: "",
      direccion: "",
      facturada: false,
      fecha: new Date(),
      moneda: null,
      nombrecliente: "",
      sucursal: null,
      tasa: 1,
      telefono: "",
      valido: "",
      valor: 0,
      valordescuento: 0,
      valorimpuesto: 0,
      subtotal:0,
      quitaritbis:false,
      provedorEstado:false
    },
    OpenEjecFarmacoVigilancia:false,
    dialogFarmacoVigilancia:false,
    buscarFarmVigBoolFactReceta:false,
    buscarFarmVigilancia:0,
farmacoVigilancia:{
codigo:0,
autorizacion:'',
direccion:'',
fecha:fechaNueva(),
fechavencimiento:null,
hora:null,
medico:'',
padecimiento:'',
monto:0,
nss:'',
telefono:'',
borrado:false,
ars:null,
cliente:null,
farmacoVigilanciaDetalleList:[],
facturasventa:null
},
dialogConsultaRecetaCreada:false,
consultaRecetaFarmVig:{
desde:null,
hasta:null
},
consultaRecetaCreadaList:[],

    Totales: {
      ReciboIngreso: {
        abono: 0,
        retencion: 0,
        descuento: 0,
        isr: 0,
        pendiente: 0,
      },

      totalGeneral: {
        subtotal: 0,
        itbis: 0,
        total: 0,
        descuento: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
      },

      CierresCajas: {
        totalTotal: 0,
        totalDenomiacionvalor: 0,
      },
    },
    puntoVenta: {
      activo: true,
      codigo: 0,
    },
    ejecucion: true,
    ejecutarAplRestFact: true,
    ejecutarEliminarProd: true,
    text: "",
    valid: true,
    Elim_notif: {
      estado: false,
      nombre: "¿Seguro que desea anular este registro?",
    },
    aalert: {
      estado: false,
      color: null,
      timeout: 3000,
      top: false,
      right: false
    },
    notif: {
      estado: false,
      nombre: "",
      Exec: ""
    },
  }),
  methods: {
    currencyFormatter,
    FormatoFecha,
    HoraFormatter,
    HoraFormatter2,
    RedondeoValorCantRed,
    fechaNueva,
    getParamFecha,
    validaCedRnc,
    getFechaZone,
    getHoraActual,
    validaEjecConfigPv(){
      if (this.EjecutarPermisoPv == false) {
          this.funcionValidaPermisoPv('AccCambiarCaja')
          return;
        }

    },

    EjecConfigPv() {
      this.findAllTerminal();
      // :items="terminal.impresoras"
      //  v-model="OtrasConfigPv.impresora"

      if (localStorage.hasOwnProperty("printerSelect")) {
        this.OtrasConfigPv.impresora = JSON.parse(localStorage.printerSelect);
      } else {
        this.OtrasConfigPv.impresora = null;
      }

      this.dialogConfigPv = true;
    },


    // Llamar a una función en un componente secundario para los permisos de usuario.
    funcionValidaPermisoPv(e) {

      var Sudo = this.$store.getters.GetdatosInfUsu.usuario.sudo
      var permiso = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

      //if (Sudo == false) { }
      var obj = {
        accion: JSON.parse(JSON.stringify(e)),
        Permiso: permiso,
        Sudo: Sudo
      }
      this.$refs.RefPvMenuOpcionBotones.ClickAccionPadreEjecut(obj)

    },
    DataRetornPadrePermisoPv(e) {
      // console.log('viendo-Data2->',e)
    
      //Permite Cambiar Cantidad a producto"
      if (e == 'AccCambioCantidadProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoCantidad.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }
      //Permite Cambiar Precio a producto"
      if (e == 'AccCambioPrecioProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoPrecio.focus();
          _this.EjecutarPermisoPv = false
        }, 999);


        return;
      }

//permitir vender por debajo del precio actual
if (e == 'AccCambiarPrecioProductoBajoPrecioActual') {
  var Config = this.$store.getters.GetConfiguracionGeneral
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {

          if(Config.configPv.venderalcosto == false && _this.rulePrecioProdBajoPrecio< _this.productoSeleccionado.costo){
            _this.productoSeleccionado.precio= JSON.parse(JSON.stringify(_this.productoSeleccionado.costo))
            _this.productoSeleccionado.precioDig=JSON.parse(JSON.stringify(_this.productoSeleccionado.costo));

          }else{
            _this.productoSeleccionado.precio=JSON.parse(JSON.stringify(_this.rulePrecioProdBajoPrecio));
            _this.productoSeleccionado.precioDig=JSON.parse(JSON.stringify(_this.rulePrecioProdBajoPrecio));
          }
         // _this.$refs.RefTextFieldProductoPrecio.focus();
          _this.EjecutarPermisoPv = false
          if(_this.boolEnterPrecioProdSelect==true){
            _this.boolEnterPrecioProdSelect=false
        setTimeout(function () { _this.SalvarProductoCompraEnter() }, 100)
          }


        }, 500);
        return;
      }


      //Permite Cambiar Descuento a producto"
      if (e == 'AccCambiarDescuentoProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoDescuento.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Cambiar vendedor a producto"
      if (e == 'AccCambiarVendedorProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoVendedor.focus();
          _this.cargarVendedorProducto()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Cambiar vendedor a producto"
      if (e == 'AccEliminarLineaProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.eliminarInfProductoCompra();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Cambiar recargo a producto"
      if (e == 'AccCambiarRecargoProd') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProductoRecargo.focus();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Permite Crear Cliente"
      if (e == 'AccCrearEditarCliente') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.SalvarCrearEditarCliente();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Cancelar Creacion de Factura
      if (e == 'AccCancelarCreacionFactura') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.limpiarPuntoVentaCompleto();
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Abrir Caja Registradora F2
      if (e == 'AccAbrirCajaRegistradoraF2') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.openCashDrawer()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }



      //entraga mensajero domicilio
      if (e == 'AccMensajeroEntregado') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.slvarEntregado()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      // no entraga mensajero domicilio
      if (e == 'AccMensajeroNoEntregado') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.salvarNoEntregado()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //Anular cambio de divisa
      if (e == 'AccAnularCambioDivisa') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.anularCambioDivisa()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //imprimir cambio de divisa
      if (e == 'AccImprimirCambioDivisa') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.imprimirCambioDivisa()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      //imprimir Guardar temporal
      if (e == 'AccGuardartemporal') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.OpenDialogGuardartemporal()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar RestaurarFact
      if (e == 'AccAplicarRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          // _this.AplicarRestaurarFact(_this.ObjEjecPermisoPv)
          _this.verificarusofacturatemporalAplicar(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar RestaurarFact
      if (e == 'AccImprimirRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.ImprimirRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar RestaurarFact
      if (e == 'AccImprimirRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.ImprimirRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }


      //Aplicar deleRestaurarFact
      if (e == 'AccDeleRestaurarFact') {
        this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.LlamadeleRestaurarFact(_this.ObjEjecPermisoPv)
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

      if (e == 'AccReimpresionOpcRecibo') {
        this.BtnReimprecionvalue = 1
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcOtrosingresos') {
        this.BtnReimprecionvalue = 2
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcFactura') {
        this.BtnReimprecionvalue = 3
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcDevolución') {
        this.BtnReimprecionvalue = 4
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcValeCaja') {
        this.BtnReimprecionvalue = 5
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcCotizacion') {
        this.BtnReimprecionvalue = 6
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

      if (e == 'AccReimpresionOpcConduce') {
        this.BtnReimprecionvalue = 7
        this.ReimprecionSheet = false
        this.ReimprecionResetCargarPaginacion()
        return;
      }

if (e == 'AccDescuentoGlobalFactura') {
          this.aplicarDescuentoGlobalFacturaXencimaMaximo()
        return;
      }


      //Permite Cambiar Cantidad a producto"
      if (e == 'AccCambiarCaja') {
        // this.EjecutarPermisoPv = true
        var _this = this;
        setTimeout(function () {
          _this.EjecConfigPv()
          _this.EjecutarPermisoPv = false
        }, 500);
        return;
      }

 //Permitir facturar a cliente que excede limite de crédito
 if (e == 'AccFactExcedeLimitCredito') {
        var _this = this;
        setTimeout(function () {
          _this.contFactExcedeLimitCredito = true
          _this.ContrealizarCobro()   
        }, 500);
        return;
      }


//Registrar Clientes Preferencial
if (e == 'AccBtnRegistrarClientesPreferencial') {
        var _this = this;
        setTimeout(function () {
          _this.openCrearModificarCliente()   
        }, 500);
        return;
      }



      //Registrar Clientes Preferencial
if (e == 'AccBtnConsultaClientesPreferencial') {
        var _this = this;
        setTimeout(function () {
          _this.openConsultaConsumoCliente()   
        }, 500);
        return;
      }



      
    },

    DataRetornMenuOpcBotonesPermisosPv(e) {
      // console.log('Data 1-->',e)
      //Abre el boton del menu producto
      if (e == 'BtnProducto') {
        this.openCrearPoducto()
        return;
      }
      //Abre el boton del menu Cliente
      if (e == 'BtnClientes') {
        this.OpenDialogCrearEditarCliente(), this.filtroTipoCliente = 3
        return;
      }

      //Abre el boton del menu Reimprimir
      if (e == 'BtnReimprimir') {
        this.dialogReimprecion = true
        return;
      }

      //Abre el boton del menu devolucion
      if (e == 'BtnFacturaDevolucion') {
        this.OpenBuscarFacturaDevolucion()
        return;
      }


      //Abre el boton del menu ReciboIngreso
      if (e == 'BtnReciboIngreso') {
        this.OpenDialogReciboIngreso()
        return;
      }

      //Abre el boton del menu RestaurarFactura
      if (e == 'BtnRestaurarFactura') {
        this.dialogRestaurarFactura = true,
          this.BuscarRestaurarFacturas()
          this.RedireccionFocus('RefTextFieldBuscarResFact')
    
var configPv = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.configPv))
if( configPv.hasOwnProperty('tiempoActualizarFacturaTemporal')&& configPv.tiempoActualizarFacturaTemporal!=null && configPv.tiempoActualizarFacturaTemporal>0){
  this.ejecutarBuscarRestaurarFacturasFrecuencia()
      }
   
        return;
      }

      //Abre el boton del menu cierre de caja
      if (e == 'BtnCierreCaja') {
        var NO_DOMI_PEN=false
        if(this.$store.getters.GetConfiguracionGeneral.configPv.noDomiciliosPendiete!=null){
        NO_DOMI_PEN=this.$store.getters.GetConfiguracionGeneral.configPv.noDomiciliosPendiete
        }

        if(NO_DOMI_PEN==false){
          this.OpenCierreCaja()
        }else{
          this.verificarPermitirDomicilio()
        }
        return;
      }

      //Abre el boton del Vendedores
      if (e == 'BtnVendedores') {
        this.OpenVendedores()
        return;
      }

      //Abre el boton del Factura Domicilio
      if (e == 'BtnFacturaDomicilio') {
        this.OpenFacturaDomicilio()
        return;
      }


      //Abre el boton del AsignarMensajeros
      if (e == 'BtnAsignarMensajero') {
        this.OpenDialogAsignarMensajeros()
        return;
      }

      //Abre el boton del RecibirMensajeros
      if (e == 'BtnRecibirMensajeros') {
        this.OpenDialogRecibirMensajeros()
        return;
      }

      //Abre el boton del FacturasRutas
      if (e == 'BtnFacturasRutas') {
        this.OpenDialogFacturasRutasMensajeros()
        return;
      }

      //Abre el boton del FacturasRutas
      if (e == 'BtnCambiarMensajeros') {
        this.OpenDialogCambiarMensajeros()
        return;
      }

      //Abre el boton del menu comentario
      if (e == 'BtnComentarioFactura') {
        this.OpenComentarioFactura()
        return;
      }

      //Abre el boton del menu Descuento factura global
      if (e == 'BtnDescuentoFacturaGlobal') {
        this.Descuentofactura.ValorPorc = false
        this.OpenDescuentoFactura()
        return;
      }

      //Abre el boton del menu Recargo factura global
      if (e == 'BtnRecargoFacturaGlobal') {
        this.OpenRecargoFactura()
        return;
      }

      //Abre el boton del menu Retenciones Gub
      if (e == 'BtnRetencionesGub') {
        this.OpenRetencionFactura()
        return;
      }

      //Abre el boton del menu Seguro
      if (e == 'BtnSeguro') {
        this.OpenCoberturaSeguro()
        return;
      }

      //Abre el boton del menu receta
      if (e == 'BtnRecetaControlada') {
        this.OpenRecProdControlado()
        return;
      }

      //Abre el boton del menu Lista Receta
      if (e == 'BtnListaReceta') {
        this.Recetaslist = []
        this.dialogRecetas = true
        this.CargarBusquedaRecetas()
        return;
      }

      //Abre el boton del menu Vale de Caja
      if (e == 'BtnCotizacion') {
        this.OpenDialogCotizacion()
        return;
      }
      //Abre el boton del menu Vale de Caja
      if (e == 'BtnValeCaja') {
        this.dialogValecaja = true
        this.newnewValecaja()
        return;
      }


      //Abre el boton del menu Otros ingresos
      if (e == 'BtnOtrosingresos') {
        this.dialogOtrosIngreso = true
        return;
      }



      //Abre el boton del menu Moneda
      if (e == 'BtnMoneda') {
        this.OpenMonedaFactura()
        return;
      }

      //Abre el boton del menu BucarCotizacion
      if (e == 'BtnBucarCotizacion') {
        this.dialogBucarCotizacion = true
        this.getCotizacionlistFactCliente()
        return;
      }


      //Abre el boton del menu BucarConduce
      if (e == 'BtnBucarConduce') {
        this.dialogBucarConduce = true
        return;
      }

      //Abre el boton del menu Impresora fiscal
      if (e == 'BtnImpresorafiscal') {
        this.dialogImpFiscal = true
        return;
      }



      //Abre el boton del menu Cambio divisas
      if (e == 'BtnCambiodivisas') {
        this.OpenDialogCambioDivisa()
        return;
      }


      //Abre el boton del menu medico
      if (e == 'BtnMedico') {
        this.OpenMedico()
        return;
      }




      //Abre el boton del menu DatosVuelo
      if (e == 'BtnDatosVuelo') {
        this.dialogDatosVuelo = true
        return;
      }



      //Abre el boton del menu DatosPagareFactura
      if (e == 'BtnDatosPagareFactura') {
        this.OpenDialogDatosPagareFactura()
        return;
      }




      //Abre el boton del menu anular factura
      if (e == 'BtnAnularFactura') {
        this.OpenBuscarFacturaAnular()
        return;
      }


      //Abre el boton Comprobantes
      if (e == 'BtnComprobantes') {
        this.ComprobanteFisc = {
          comprobante: JSON.parse(JSON.stringify(this.Facturasventa.tipocomprobante)),
          nombre: JSON.parse(JSON.stringify(this.Facturasventa.nombreDgii)),
          telefono: JSON.parse(JSON.stringify(this.Facturasventa.telefononcf)),
          direccion: JSON.parse(JSON.stringify(this.Facturasventa.direccionncf)),
          cedrnc: JSON.parse(JSON.stringify(this.Facturasventa.cedularncncf))
        },


          this.dialogComprobante = true
        return;
      }

//Abre el boton del menu anular factura
if (e == 'BtnFarmVigilancia') {
  this.openFarmacoVigilancia()

        return;
      }


      //Abre el boton del menu anular factura
if (e == 'BtnPendientesEntrega') {
       this.openPendientesEntrega()
        return;
      }



 //Abre el boton del menu Impresora fiscal
 if (e == 'BtnClientePreferencial') {
        this.dialogClientePreferencial = true
        return;
      }


      //Registrar Clientes Preferencial
if (e == 'AccBtnRegistrarClientesPreferencial') {
        var _this = this;
        setTimeout(function () {
          _this.openCrearModificarCliente()   
        }, 500);
        return;
      }



      //Registrar Clientes Preferencial
if (e == 'AccBtnConsultaClientesPreferencial') {
        var _this = this;
        setTimeout(function () {
          _this.openConsultaConsumoCliente()   
        }, 500);
        return;
      }


    },

    // Limpiando el formulario completo.
    limpiarPuntoVentaCompleto() {
      // this.devuelta = 0;
       
      this.disabledTextFieldProducto=false
      this.dialogNotifQuitarDescuentoCliente=false
      var DEVUELTA=JSON.parse(JSON.stringify(this.devuelta))
      this.searchVendedorCodigo=""
      this.activoF7=false
      this.SelectMedicoFactura = null
      this.cotizacionAfacturaSelect=null
      this.deleteFacturaTemporal = null;
      this.dialogGuardartemporal = false;
      this.nombreFactTem = "";
      this.sucursalFactTemporal = null;
      this.TipoAnulacion = null;
      this.ejecucion = true;
      this.tipoTarjeta = null;
      this.conduceSelect = null;
      this.objTempaplicarDescuentoGlobalFacturaXencimaMaximo=null;
      this.NewclienteSeleccionado();
      this.NewNewRetencionFactura();
      this.cedularequired=false
      this.clienteSeleccionado = {
        codigo: 0,
        nombre: null,
        cedrnc: "",
        ciudad: null,
        direccion: null,
        telefono: "",
        sector: "",
        tipocomprobante: null,
        email: "",
        sexo: "",
        dondetrabaja: "",
        diascredito: 0,
        fechanacimiento: null,
        carnet: "",
        ars: null,
        contrato: "",
        nss: "",
        planlan: 0,
        pasaporte:'',
        zonaRuta:null,
        ordenVisita:0,
        diaVisita:null,
        balancePuntos:0,
        codigoBarraPreferencial:null
      };

      this.Totales = {
        ReciboIngreso: {
          abono: 0,
          retencion: 0,
          descuento: 0,
          isr: 0,
        },
        totalGeneral: {
          subtotal: 0,
          itbis: 0,
          total: 0,
          descuento: 0,
          Retencion: 0,
          recargo: 0,
          cobertura: 0,
        },
        CierresCajas: {
          totalTotal: 0,
          totalDenomiacionvalor: 0,
        },
      };
      this.cancelarInfProducto();
      this.productoSeleccionado = {
        cantidad: 1,
        precio: 0,
        descuento: 0,
        descuentoKeys: 0,
        vendedor: null,
        recargo: 0,
        ValorPorcRecarg: false,
        cobertura: 0,
        productos: null,
        valor: 0,
        ValorPorc: false,
      };
      this.RallProducto = [];
      this.RProductoCompra = [];
      this.RProductoCompraTemp = [];
      this.RProductoCompraOfercta=[]
      this.ListDenomMoneda = [];
      this.formaPago = {
        efectivo: 0,
      };
      this.clienteFactura = null;
      this.clienteTempCredito=null;
      this.dialogSelectClienteTempCredito=false;
      this.filtroTipoCliente = 3;
      this.busquedaCliente = null;
      this.Facturasventa = {
        facturasventaPK: { secuencia: 0, documento: 0 },
        empresa: 1,
        fecha: new Date(),
        referencia: "",
        diascredito: 0,
        ncf: "",
        valor: 0.0,
        tasa: 1,
        comentario: "",
        valordescuencto: 0.0,
        valorimpuesto: 0.0,
        pendiente: 0.0,
        activada: true,
        efectivo: 0.0,
        tarjeta: 0.0,
        cheques: 0.0,
        cxc: 0.0,
        puntos: 0.0,
        valorConduce: 0.0,
        cupones: 0.0,
        cedularncncf: "",
        direccionncf: "",
        telefononcf: "",
        codigocupon: "",
        numeroCheque: "",
        nombreDgii: "",
        documentoFactura: null,
        umerocierre: 0,
        autorizacion: null,
        cobertura: null,
        medico: null,
        exequartur: null,
        diagnostico: null,
        especialidad: null,
        enviada: false,
        glosada: false,
        coberturaPendiente: null,
        arsCobrarEmpleado: false,
        arsIncobrable: false,
        arsComentario: null,
        usuario: "",
        usuarioCobro: "",
        fechaImpresion: null,
        nif: null,
        horaImpresion: null,
        receta: null,
        valorRecargo: 0,
        descGloblal: true,
        recgGlobal: false,
        numtar: null,
        valorNc: 0.0,
        codigoNc: 0,
        secuenciaNc: 0,
        retencion: 0.0,
        sumado: false,
        ncfVencimiento: null,
        medicoCodigo: null,
        totalIsr: 0.0,
        totalOtroImpuesto: 0,
        motivoAnular: null,
        facturaManual: false,
        descripcion: "",
        numeroOrden: null,
        lineaAerea: null,
        numeroVuelo: null,
        rutaSalidaE: null,
        rutaSalidaD: null,
        rutaRetornoE: null,
        rutaRetornoD: null,
        turno: null,
        gaspremium: 0.0,
        valordonacion: 0.0,
        valorbonogas: 0.0,
        valorasignacion: 0.0,
        valorcomision: 0.0,
        valorotros: 0.0,
        borrado: false,
        fechaIngreso: null,
        fechaSalida: null,
        tipoAsistencia: null,
        numeroHabitacion: null,
        facturaRecurrente: false,
        configuracionDocumentoRecurrente: 0,
        valorTraspaso: null,
        valorPruebaMetro: null,
        detalleJson: "",
        generadaDesdeEnviosPaquetes: false,
        generadaDesdeBoleteria: false,
        conduceSecuencia: null,
        conduceDocumento: null,
        shipto: null,
        incoterms: null,
        pagare: null,
        pagareValor: null,
        usuariosAnulacion: null,
        fechaAnulacion: null,
        usuariosEditar: null,
        fechaEdicion: null,
        bancoCheque: null,
        ars: null,
        caja: null,
        cliente: null,
        clinica: null,
        impuesto: null,
        moneda: null,
        sucursal: null,
        tipoTarjeta: null,
        tipocomprobante: null,
        vendedor: null,
        contabilidad: null,
        transferencia: 0.0,
        domicilio: null,
        facturasventaDetalleList: [],
        datoTarjeta: null,
      };
      this.newnewCotizacion();
      this.dialogDevolucion = false;
      this.cancelarAceptarDevolucion();
      this.DevolucionFacturaSeleccionada.secuencia = 0;

      this.Monedafactura = this.configuraciones.config.monedabase;
      this.UpdateConfigMoneda();
      this.SalvarMonedaFactura()
      this.NewAnulacionFacturaSeleccionada();
      this.devueltaRecIngreso = 0.0
      this.ReciboIngresoFactura = {
        efectivo: 0,
        tarjeta: 0,
        cheque: 0,
        transferencia: 0,
        numeroCheque: null,
        bancoCheque: null,
        tipoTarjeta: null,
        numtar: null,
      };

      this.ReciboIngreso = {
        precio: 0,
        abono: 0,
        retencion: 0,
        descuento: 0,
        isr: 0,
        DescValorPorc: false,
        retenValorPorc: true,
        isrValorPorc: true,
      };


      this.Descuentofactura = {
        ValorPorc: true,
        descuento: 0,
        DescuentoFacturaGlobal: false
      };


      this.PanelFormaPagoNotaCredito = false
      this.PanelMenuOpcionBotones = true
      this.selectedListFPagoNC = []
      this.ListFormaPagoNC = []
      this.ListProductoSustitutos = []
      this.activaConduceCredito = false

      this.NewRecetaProdCOntrolado()
     this.filtroFacturaRutaCliente=''
  this.filtroAsigMensajero={
    fact:'',
    sector:'',
    caja:null
    },
    this.boolRuleprodprecio= false
    this.contFactExcedeLimitCredito=false
    this.nuevoFarmacoVigilancia()
      this.loading(false)
      this.selecteTemRecCont=null

      var _this = this;
      setTimeout(function () {
        _this.devuelta=JSON.parse(JSON.stringify(DEVUELTA))
        _this.verificarVendedor()
      }, 2000);

      this.bancoCheque=null
      this.selectFactTem=null
      this.ejecutarEliminarProd=true

    },
    // Cargar los datos de la base de datos y almacenarlos en el almacenamiento local dotos.
    async loadPv() {

      var User = ""
      if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
        User = this.$store.getters.GetdatosInfUsu.usuario.username
      } else {
        User = this.$store.getters.GetdatosInfUsu.usuario.nombre
      }
      this.OtrasConfigPv = {
        caja: null,
        terminal: null,
        impresora: null,
        turno: null,
        cajero: User,
        moneda: null,
      };
      if (!localStorage.caja) {
        this.dialogConfigPv = true;
      } else {
        var d = JSON.parse(localStorage.caja);
        await this.$axios
          .get(
            this.$hostname + this.$hName + "/cajas/findbycodigo/" + d.codigo,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.RestaurarFacturaBusqueda.sucursal = res.data.sucursal
            this.sucursalFactTemporal = res.data.sucursal
            this.caja = res.data;
            localStorage.setItem("caja", JSON.stringify(res.data));
            var _this = this;
           setTimeout(function () { _this.verificarVendedor() }, 300)
          })
          .catch((error) => {
            this.dialogConfigPv = true;
            console.error(error);
          });
      }
      await this.$axios
        .get(this.$hostname + this.$hName + "/configuraciones/find", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.configuraciones = res.data;
          //this.$store.commit('MutationaddConfiguracionGeneral',res.data);
        })
        .catch((error) => console.error(error));
      //this.configuraciones =this.$store.getters.GetConfiguracionGeneral;
      // console.log(!localStorage.hasOwnProperty("printerSelect"))
      // console.log(!this.OtrasConfigPv.hasOwnProperty("impresora"))

      //!localStorage.printerSelect
      if (
        !localStorage.hasOwnProperty("printerSelect") ||
        !this.OtrasConfigPv.hasOwnProperty("impresora")
      ) {
        this.OtrasConfigPv.impresora = null;
      } else {
        this.OtrasConfigPv.impresora = JSON.parse(localStorage.printerSelect);
      }
      this.Monedafactura = this.configuraciones.config.monedabase;

      this.InfomCaja();
      this.findCierreActual();
      this.findAllTerminal();
    },
    close() { },
    // Envío de una solicitud POST al servidor con los datos a imprimir.
    imprimir(datos, operacion) {
      datos.operacion = operacion;
      if (datos.formato.trim() === "rolloBlue") {
if(datos.operacion.trim()==="factura"){
var FACT=datos.fac.hasOwnProperty('factura')? datos.fac.factura.factura:datos.fac
var _this = this;
setTimeout(function () {
  _this.$refs.RefPvImprecionViewer.GetPrintFacturaVenta(FACT)
  // _this.$refs.RefPvImprecionViewer.GetPrintFacturaVenta(datos.fac.factura.factura)
}, 800);
       
}

      }else{
        if(datos.impresora==null){
          this.alerta(" seleccione la impresora","error")
          return
        }

        if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => { 
            this.ejecucion=true 
            console.error(error)
          });
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
        .catch((error) => { 
            this.ejecucion=true 
            console.error(error)
          });
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => {
            console.error(error)
            this.ejecucion=true 
          });
      }

      }

      
    },
    imprimir2(datos, operacion,callback) {
      if(datos.impresora==null){
          this.alerta(" seleccione la impresora","error")
          return
        }
      datos.operacion = operacion;
      if (datos.formato === "fiscale") {
        this.$axios.get("http://localhost:15999/impresora/imprimir/validad").then(res => {
          if (res.data.LastError ===0) {
          this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            this.limpiarPuntoVentaCompleto();

            callback(datos.fac);
          })
          .catch((error) => {
            console.error(error)
            this.ejecucion=true
          });
          }else{
            this.alerta("La impresora esta desconectada o esta apagada!!!","error")
            return;
          }
        })
      }else{
        this.$axios
          .post("http://localhost:15999/impresora/imprimir", datos)
          .then((res) => {
            callback(datos.fac);
            this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => {
            console.error(error)
            this.ejecucion=true
          });
      }
    },
    calculostotales() {
      if (this.Facturasventa.efectivo > 0) {
      }
      if (this.Facturasventa.cxc > 0) {
      }
      this.calcularDevuelta();
    },
    ConfDefoul() {
      this.dialogRestaurarFactura = false
      this.dialogCrearEditarCliente = false
      this.NewclienteSeleccionado()
      if (this.PanelMenuOpcionVendedor == true) { this.cancelarVendedores() }
      if (this.PanelMenuOpcionDescuentoFactura == true) {
        this.cancelarDescuentoFactura()
      }

    },
    imprimirCierreZ(){
      if (localStorage.printerSelect != null) { }
      var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
        var msg = {
                accion: "cierrez",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
      this.imprimir(msg, "reporteFiscal");
     
    },
    imprimirReporteX(){
       if (localStorage.printerSelect != null) {}
       var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
        var msg = {
                accion: "cierrex",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
      this.imprimir(msg, "reporteFiscal");
      
    },
    abrirLVentas(){
      if (localStorage.printerSelect != null) {}
      var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

        var msg = {
                accion: "lventa",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
      this.imprimir(msg, "reporteFiscal");
      
    },
    imprimirUltimoDocumento(){
      if (localStorage.printerSelect != null) { }
      var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

        var msg = {
                accion: "ultimodocumento",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
      this.imprimir(msg, "reporteFiscal");
     
    },
    cancelarUltimoDocumento(){
      if (localStorage.printerSelect != null) {}
      var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
        var msg = {
                accion: "anularultimo",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
      this.imprimir(msg, "reporteFiscal");
      
    },
    onkeydownCode(ee) {
      // ee.Handled = true;
      var _this = this;
      if (ee.keyCode >= 112 && ee.keyCode <= 123) {
        ee.preventDefault();
        this.ConfDefoul()
      }

      if (ee.keyCode >= 33 && ee.keyCode <= 36 || ee.keyCode == 45 || ee.keyCode == 46) {
        ee.preventDefault();
        this.ConfDefoul()
      }


      //key:"F2":113 :Abrir caja registradora
      if (ee.keyCode == 113) {
        if (this.EjecutarPermisoPv == false) {
          this.funcionValidaPermisoPv('AccAbrirCajaRegistradoraF2')
          return;
        }
        //  this.openCashDrawer()
        return;
      }

      //key:"F3":114: Guardar Factura Temporal
      if (ee.keyCode == 114) {
        this.OpenDialogGuardartemporal()
        return;
      }


      //key:"F4":115
      if (ee.keyCode == 115) {
       /* this.dialogRestaurarFactura = true
        this.BuscarRestaurarFacturas()
        return;*/
        this.dialogRestaurarFactura = true,
        this.BuscarRestaurarFacturas()       
var configPv = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.configPv))
if( configPv.hasOwnProperty('tiempoActualizarFacturaTemporal')&& configPv.tiempoActualizarFacturaTemporal!=null && configPv.tiempoActualizarFacturaTemporal>0){
  this.ejecutarBuscarRestaurarFacturasFrecuencia()
      }
   
        return;

      }

      //key:"F5":116
      if (ee.keyCode == 116) {
        if (this.EjecutarPermisoPv == false) {
          this.funcionValidaPermisoPv('AccCancelarCreacionFactura')
          return;
        }
        // this.limpiarPuntoVentaCompleto()

      }
      //key:"F7":118
      if (ee.keyCode == 118) {
        this.activoF7=true
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldBuscaVendedorCodigo.focus();
        }, 500);
       /* this.OpenVendedores()
        setTimeout(function () {
          _this.$refs.RefTextFieldBuscaVendedor.focus();
        }, 500); */
        return;
      }
      //key:"F8":119
      if (ee.keyCode == 119) {
        this.OpenDialogCrearEditarCliente()
        this.filtroTipoCliente = 3
        setTimeout(function () {
          _this.$refs.RefTextFieldBuscaCliente.focus();
        }, 500);

        return;
      }

      //key:"F9":120 //key:"F10":120
      if (ee.keyCode == 120 || ee.keyCode == 121) {
        //pporciento
        this.OpenDescuentoFactura()
        setTimeout(function () {
          _this.Descuentofactura.descuento=0
          if (ee.keyCode == 120) {
            _this.Descuentofactura.ValorPorc = false
          }
          if (ee.keyCode == 121) {
            _this.Descuentofactura.ValorPorc = true
          }

          // _this.$refs.RefTextFieldDescuentoFactura.focus();
        }, 500);


      var _this = this;
      setTimeout(function () {
         _this.$refs.RefTextFieldDescuentoFactura.focus(); 
      }, 1000);




        return;
      }


      //key:"END":35
      if (ee.keyCode == 35) {

        if (this.devuelta >= 0) {
          this.realizarCobro()
          return
        }

        if (this.SoloCxc == true) {
          this.$refs.RefTextFieldForPagoCxc.focus();
        } else {
          this.$refs.RefTextFieldForPagoEfectivo.focus();
        }

        // this.realizarCobro()
        return;
      }


      //key:"Backquote":192
      if (ee.keyCode == 192) {
        ee.preventDefault();
        this.FocusInputProductoBusqueda()
        return;
      }


      // OpenDialogCrearEditarCliente(), filtroTipoCliente = 3
      //key:"F1"/112
      //this.openCrearPoducto()
      //pauseSound();

    },
  

    FocusInputProductoBusqueda() {
      var _this = this;
      //Crear Producto
      if (this.dialogCrearProduct == true) {
        this.cancelarCrearProducto()
      }
      //Crear / Editar Cliente
      if (this.dialogCrearEditarCliente == true) {
        this.dialogCrearEditarCliente = false
        this.NewclienteSeleccionado()
      }

      //Reimpresión
      if (this.dialogReimprecion == true) {
        this.ListReimprecion = []
        this.NewReimprecionFacturaSeleccionada()
        this.BtnReimprecionvalue = 1
        this.dialogReimprecion = false
      }

      // Devolucion
      if (this.dialogDevolucion == true) {
        this.cancelarAceptarDevolucion()
        this.NewDevolucionFacturaSeleccionada()
        this.dialogDevolucion = false
      }

      //Recibo de ingreso
      if (this.dialogReciboIngreso == true) {
        this.dialogReciboIngreso = false
        this.NewclienteSeleccionado()
      }

      // Restaurar Factura
      if (this.dialogRestaurarFactura == true) {
        this.dialogRestaurarFactura = false
      }

      //Cierre de Cajas
      if (this.dialogCierreCaja == true) {
        this.cancelarCierreCaja()
      }

      //Seleccionar y Ver Mensajero
      if (this.dialogMensajero == true) {
        this.VerFacturaDomicilioMensajero = false
        this.FacturaDomicilioMensajero = null
        this.dialogMensajero = false
        this.dialogRecibirMensajeros = false
        this.dialogAsignarMensajeros = false
        this.dialogFacturasRutasMensajeros=false
        this.dialogCambiarMensajeros=false

        this.NewMensagero()
      }

      // Cobertura de seguro
      if (this.dialogCoberturaSeguro == true) {
        this.cancelarCoberturaSeguro()
      }

      //Recetas
      if (this.dialogRecetas == true) {
        this.dialogRecetas = false
      }

      //Receta de producto controlado
      if (this.dialogProdControlado == true) {
        this.CancelarRecetaControlada()

      }

      //Cotización
      if (this.dialogCotizacion == true) {
        this.cancelarCotizacion()
      }

      //Vale de caja
      if (this.dialogValecaja == true) {
        this.cancelarValecaja()
      }

      // Recibos de Otros Ingresos
      if (this.dialogOtrosIngreso == true) {
        this.cancelarOtrosIngreso()
      }
      // ver Cotización
      if (this.dialogBucarCotizacion == true) {
        this.dialogBucarCotizacion = false
        this.NewBucarCotizacion()
      }

      // ver Conduce
      if (this.dialogBucarConduce) {
        this.dialogBucarConduce = false
        this.NewBucarConduce()
      }

      //Impresora Fiscal
      if (this.dialogImpFiscal == true) {
        this.cancelarImpresoraFiscal()
      }
       //Impresora Fiscal
      if (this.dialogClientePreferencial == true) {
        this.dialogClientePreferencial = false
      }

      //Cambio Divisa
      if (this.dialogCambioDivisa == true) {
        this.dialogCambioDivisa = false
        this.NewNewCambioDivisa()
      }

      //Anulación de factura
      if (this.dialogAnulacion == true) {
        this.NewAnulacionFacturaSeleccionada()
      }

      setTimeout(function () {
        _this.$refs.RefTextFieldProducto.focus();
      }, 500);


    },

    

    onResize() {
      //divSelecProd
      var jsn = { width: window.innerWidth, height: window.innerHeight - 112 };
      this.windowSize = jsn;
      //this.windowSize=e
    },
    AddInfProd() { },
    VerInfProd() { },

    // Un método que se llama en el vue html.
    classObjProdSelect(e) {
      //class="RowSelectColorPv" class="white--text"

      if (this.selected == null) {
        return "";
      }

      if (this.selected != null) {
        if (e.indx == this.selected.indx) {
          return "RowSelectColorPv white--text ";
        } else {
          return "";
        }
      }
      return "";
    },


     // Un método que se llama en el vue html.
     classObjProdSelectFabricante(e) {
      //class="RowSelectColorPv" class="white--text"

      if (this.selected == null) {
        return "green--text";
      }

      if (this.selected != null) {
        if (e.indx == this.selected.indx) {
          return "white--text";
        } else {
          return "green--text";
        }
      }
      return "";
    },

    VerInfom() {
      // var elemento = document.getElementById("scroll-target");
      // document.getElementById('scroll-target').scrollTop += 20;
      //   var elem = document.getElementById('para');
      //  elem.style.color = newColor;
      // function move_up() {
      //   document.getElementById('divElem').scrollTop += 10;
      // }
      // function move_down() {
      //   document.getElementById('divElem').scrollTop -= 10;
      // }
    },

    obtenerDatoSelect(e) {
      this.datostabla(e.item);
    },
    permisosPV() { },

    // Validando el formulario y luego se está comprobando si el producto es válido o no.
    ValidarProductoOpcionVer(e) {
      if (this.$refs.formCampProdc.validate()) {
        var Config = this.$store.getters.GetConfiguracionGeneral

        /*
        NOTA: COMENTADO POR REGLA NUEVA EN MODULO USUARIO / OPC (PRODUCTO)
        permitir vender por debajo del precio actual
        if (Config.configPv.noBajaPrecio == true) {
          if (this.RedondeoValor(e.precio) < this.RedondeoValor(e.precioOrig)) {
            this.productoSeleccionado.precio = JSON.parse(JSON.stringify(e.precioOrig))
            this.productoSeleccionado.precioDig = JSON.parse(JSON.stringify(e.precioOrig))
            this.alerta("Este producto no se permite vender por debajo del precio actual", "error");
            return;
          }

        }*/

        if (Config.configPv.venderalcosto == false) {

          if (this.RedondeoValor(e.precio) < this.RedondeoValor(e.costo)) {
            this.productoSeleccionado.precio = JSON.parse(JSON.stringify(e.costo))
            this.productoSeleccionado.precioDig = JSON.parse(JSON.stringify(e.costo))
            this.alerta("Este producto no se permite vender por debajo del costo actual", "error");
            return;

          }
        }

    if (this.configuraciones.configInv.permitirNegativos == false) {
if (e.productos.servicio==false && e.cantidad > e.existencias) {
  this.searchProducto="";
  this.alerta("la cantidad que intenta vender, es mayor a la existencia", "error");
  return;
}
}

        if (this.ProductoOpcionVer == false) {
          this.loading(true)
          if (this.EjecutarInfSaveProd == true) {
            this.EjecutarInfSaveProd = false
            var _this = this;
            setTimeout(function () {
              _this.preValidadorProducto(e)
            }, 400);

            setTimeout(function () {
              _this.loading(false)
            }, 600);
          }

        } else {
          this.loading(true)
          if (this.EjecutarInfSaveProd == true) {
            this.EjecutarInfSaveProd = false
            var _this = this;
            setTimeout(function () {
              _this.ProductoOpcionVer = false
              _this.BtnSelectAddProducto(e)
            }, 400);

            setTimeout(function () {
              _this.loading(false)
              _this.EjecutarInfSaveProd = true
            }, 600);
          }


        }


        if (this.UsarLote == true) {
          var FechSel = this.selectedListProdxLote[0].fechaVencimiento.fecha
          var ObjProRow = this.ListaProdxLote.filter((ee) => ee.fechaVencimiento.codigo != e.lote && ee.fechaVencimiento.fecha < FechSel);
          if (ObjProRow.length > 0) {
            this.alerta("Existe un lote más antigüo  que el que sea seleccionado", "error");
          }
        }
      }
      else {
        this.alerta("los campos en rojo son requeridos", "error");
      }

    },

    // Comprobar si el producto es válido o no.
    preValidadorProducto(e) {
      /*   var selc = JSON.parse(JSON.stringify(e));
         if (selc.productos.controlado == true) {
           this.EjecuModalProductoControlado("notf");
           return;
         }*/

      if (e.hasOwnProperty('ProductoValidado') && e.ProductoValidado == true) {
        this.SalvarInfProducto()
      } else {

        if (this.configuraciones.configInv.permitirNegativos == false) {

          var BOOL_OMITI_REGLA=false
          if (e.hasOwnProperty("omitirRegla") && e.omitirRegla == true) {
            BOOL_OMITI_REGLA=true
    
        } 

          if ( BOOL_OMITI_REGLA==false && this.productoSeleccionado.productos.servicio==false && this.productoSeleccionado.cantidad > this.productoSeleccionado.existencias) {
            this.searchProducto="";
            this.alerta("la cantidad que intenta vender, es mayor a la existencia", "error");
            this.PanelSelectProducto = true
            return;
          }
        }


        e.ProductoValidado = true
        this.SalvarInfProducto()/* */

      }



    },

FabricanteOrProveedor(){
  var MOSTRAR_PROD=this.$store.getters.GetConfiguracionGeneral.configPv.proveedorListaProductoBusqueda
  // me quede aquii
  // var MOSTRAR_PROD=null
  var Desc="";
  if(MOSTRAR_PROD==null || MOSTRAR_PROD==false){
    Desc="Fabricante: "
  }else{
    Desc="Proveedor: "
  }
  return Desc;
},
    mostrarFabricanteProveedor(ITEM){
       var MOSTRAR_PROD=this.$store.getters.GetConfiguracionGeneral.configPv.proveedorListaProductoBusqueda
      // Fabricante: 
     
      var Desc='';   
      if(MOSTRAR_PROD==null || MOSTRAR_PROD==false){
            Desc=`${ITEM.productos.fabricante.nombre.trim()}`
          }else{
          if(ITEM.productos.listaProveedor.length>0){
            Desc=ITEM.productos.listaProveedor[0].nombre.trim()
          }else{
            Desc='N/A'
          }
          }
      // props.item.productos.fabricante.nombre.trim()
      return Desc;
    },

    // Haciendo una llamada axios al servidor para obtener el descuento del producto.
    BuscPropiedadProductoSelct(ObjProd, Destino) {
      if(Destino == 'ver'){ this.pedirNombre= ObjProd.productos.pedirNombre}
      if (ObjProd.hasOwnProperty("omitirRegla") && ObjProd.omitirRegla == true) {
        if (Destino == 'add') { this.BtnSelectAddProducto(ObjProd) }
        if (Destino == 'ver') { this.BtnVerProductoSelect(ObjProd) }
      } else {
        var cli = 0
        if (this.clienteFactura != null) {
          cli = this.clienteFactura.codigo
        }
        var SoloCxc = this.$store.getters.GetConfiguracionGeneral.configPv.soloCxc
        //  .config.soloCxc,
        // console.log(SoloCxc)!this.Facturasventa.efectivo

        var link = `/producto/${ObjProd.productos.codigo}/descuento/${SoloCxc}/${this.domiciliofactura != null}/${this.Totales.totalGeneral.cobertura > 0}/${this.Facturasventa.tarjeta > 0}/${this.Totales.totalGeneral.subtotal + ObjProd.precio}/${cli}`
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            link,
            {
              headers: this.$store.getters.GetheadersAxios
            }
          )
          .then(res => {
            var CANTIDAD = ObjProd.hasOwnProperty("cantidad") && ObjProd.cantidad != null ? ObjProd.cantidad : 1;
            var ObjProRow = this.RProductoCompra.filter((ee) => ee.productos.codigo == ObjProd.productos.codigo && ee.unidades.codigo == ObjProd.unidades.codigo);
          if (ObjProRow.length > 0) { 

      var OBJ_PROD_SELE= JSON.parse(JSON.stringify(ObjProRow[0])) 

      var BOOLUNIPRO =(OBJ_PROD_SELE.unidadesProductosPK.producto==ObjProd.unidadesProductosPK.producto && OBJ_PROD_SELE.unidadesProductosPK.unidad==ObjProd.unidadesProductosPK.unidad)
  
      if( BOOLUNIPRO==true && OBJ_PROD_SELE.productos.servicio==true&& OBJ_PROD_SELE.descripcion.trim()!=(`${ObjProd.productos.descripcion.trim()} ${ObjProd.unidades.descripcion.trim()}`)){
        this.productoSeleccionado= JSON.parse(JSON.stringify(ObjProd))
            this.productoSeleccionado.cantidad=ObjProd.hasOwnProperty("cantidad") && ObjProd.cantidad != null ? ObjProd.cantidad : 1;
            var CANTIDAD = ObjProd.hasOwnProperty("cantidad") && ObjProd.cantidad != null ? ObjProd.cantidad : 1; 
      }else{
// OBJ_PROD_SELE.cantidad = (CANTIDAD+ObjProRow[0].cantidad)
        this.productoSeleccionado= JSON.parse(JSON.stringify(OBJ_PROD_SELE))
      }
 
      }else{
            this.productoSeleccionado= JSON.parse(JSON.stringify(ObjProd))
            this.productoSeleccionado.cantidad=ObjProd.hasOwnProperty("cantidad") && ObjProd.cantidad != null ? ObjProd.cantidad : 1;
            var CANTIDAD = ObjProd.hasOwnProperty("cantidad") && ObjProd.cantidad != null ? ObjProd.cantidad : 1; 
      }
            this.productoSeleccionado.descuento = res.data.descuento
            this.productoSeleccionado.descuentoKeys = res.data.descuento
            this.productoSeleccionado.paramEspecial = res.data.especial
            
        var bar = new Promise((resolve, reject) => {
        this.verficardatos(this.productoSeleccionado)
        resolve()
          });

bar.then(() => {
  var ProObj= JSON.parse(JSON.stringify(this.productoSeleccionado))
  //ProObj.cantidad=CANTIDAD
  // console.log('All done BuscPropiedadProductoSelct !');
            if (res.data.descuento > 0) { ProObj.ValorPorc = false } else { ProObj.ValorPorc = false /*ProObj.ValorPorc = true*/ }
            if (Destino == 'add') { this.BtnSelectAddProducto(ProObj) }
            if (Destino == 'ver') { this.BtnVerProductoSelect(ProObj) }
});
        
          })
          .catch(error => { console.log('-E-:' + error) }
          );
      }


    },


    verFotodeProducto(e) {
      var images = require("@/assets/img/Nofoto.png");

      if (e.foto == null) {
        return images
      }

      if (e.foto != null && e.foto.length == 0) {
        return images
      }

      if (e.foto != null && e.foto.length > 0) {
        return e.foto
      }
      return images

    },


findUnidaProducto(codpro,coduni){
return  new Promise((resolve, reject) => {
var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/find/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
     EventBus.$emit("loading", false); 
  })
  .catch(error => {
      EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},


    findListaprecioUnidaProducto(codpro,coduni,codlispre){

      return  new Promise((resolve, reject) => {
      var VARLINK=`findListaprecioUnidaProducto/${codpro}/${coduni}/${codlispre}`
  EventBus.$emit("loading", true);
        this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/' + VARLINK, { headers: this.$store.getters.GetheadersAxios })
        .then(res => { 
          if(res.data.hasOwnProperty('id')){
            resolve(res.data)
          } else{
            resolve(null)
          }
           EventBus.$emit("loading", false);
           
        })
        .catch(error => {
            EventBus.$emit("loading", false);
            resolve(null)
          });  

        });

    },


    // Añadir un producto al carrito de la compra.
    async BtnSelectAddProducto(Datos) {
      var e =JSON.parse(JSON.stringify(Datos))

if(this.activarListasPrecios==true){
  var CODPRO=e.unidadesProductosPK.producto
  var CODUNI=e.unidadesProductosPK.unidad
  var CODLISPRE=this.listaPrecioDefecto.id
  if(this.clienteFactura!=null && this.clienteFactura.listaPrecio!=null && this.clienteFactura.listaPrecio.activo==true ){
    CODLISPRE=this.clienteFactura.listaPrecio.id
  }
  const result =  await this.findListaprecioUnidaProducto(CODPRO,CODUNI,CODLISPRE);
  const resUniPro =  await this.findUnidaProducto(CODPRO,CODUNI);
  if(result!=null){
    e.precio=result.precio
    e.precioDig=result.precio
    e.precioOrig=resUniPro.precio
  }
}

      var selc = JSON.parse(JSON.stringify(e));
      this.selected = JSON.parse(JSON.stringify(e));
      
  
      var ObjProRow = this.RProductoCompra.filter(
        (ee) => ee.productos.codigo == e.productos.codigo &&
          ee.unidades.codigo == e.unidades.codigo && ee.paramOfert==false
      );

      if (ObjProRow.length > 0) {
        if (e.productos.controlado == true) {
          var ObjProControlRow = this.RecetaProdControlado.detalleList.filter(
            (ec) => ec.productos.codigo == e.productos.codigo &&ec.unidad.codigo == e.unidades.codigo )[0];
          // if (ObjProRow[0].cantidad >= ObjProControlRow.cantidadRecetada)
          if (ObjProRow[0].cantidad + ObjProControlRow.cantidadDespachada > ObjProControlRow.cantidadRecetada) {
            this.alerta(
              "Producto Controlado: La cantidad a despachar esta por encima de la cantiadad recetada",
              "error"
            );
            return;
          }
        }
        var canttt = 1
        if (e.cantidad != null) {

            if(this.EditProdCompa==false&&this.PanelInfProduct==true){
              canttt = (this.productoSeleccionado.cantidad + ObjProRow[0].cantidad)
            }else{
              canttt = (1 + ObjProRow[0].cantidad)
            }

          //canttt = (e.cantidad + ObjProRow[0].cantidad)
           

          // console.log(ObjProRow[0].productos.servicio)
          if(ObjProRow[0].productos.servicio==false && this.configuraciones.configInv.permitirNegativos == false && canttt>ObjProRow[0].existencias){
            this.alerta("la cantidad que intenta vender, es mayor a la existencia","error");
            return;
          }
          
        } else {
          canttt += (ObjProRow[0].cantidad)
          // console.log(ObjProRow[0].productos.servicio)
          if(ObjProRow[0].productos.servicio==false && this.configuraciones.configInv.permitirNegativos == false && canttt>ObjProRow[0].existencias){
            this.alerta("la cantidad que intenta vender, es mayor a la existencia","error");
            return;
          }
        }

        var OBJ_PROD_SELE= JSON.parse(JSON.stringify(ObjProRow[0])) 
       var BOOLUNIPRO =(OBJ_PROD_SELE.unidadesProductosPK.producto==selc.unidadesProductosPK.producto && OBJ_PROD_SELE.unidadesProductosPK.unidad==selc.unidadesProductosPK.unidad)
  
       if( BOOLUNIPRO==true && OBJ_PROD_SELE.productos.servicio==true&& OBJ_PROD_SELE.descripcion.trim()!=(`${selc.productos.descripcion.trim()} ${selc.unidades.descripcion.trim()}`)){
        this.BtnAgregarProductoSelect(selc);
       }else{

        this.RProductoCompra.filter((ee) => ee.productos.codigo == e.productos.codigo && ee.unidades.codigo == e.unidades.codigo && ee.paramOfert==false
        )[0].cantidad = canttt;

          if(selc.hasOwnProperty("DescValor")){
                this.RProductoCompra.filter((ee) => ee.productos.codigo == e.productos.codigo &&
                  ee.unidades.codigo == e.unidades.codigo)[0].DescValor = selc.DescValor;
                    }
       

        this.RProductoCompraTemp = JSON.parse(JSON.stringify(this.RProductoCompra))
        this.LimpiaInfProducto()
        }



      } else {

        if (selc.hasOwnProperty("omitirRegla") && selc.omitirRegla == true) {
          this.BtnAgregarProductoSelect(selc);
        } else {
          if (selc.productos.controlado == true) {
            this.EjecuModalProductoControlado("notf");
          } else {
            this.BtnAgregarProductoSelect(selc);
          }


        }


      }




      //this.RallProducto=[]
    },

    BtnAgregarProductoSelect(e) {

      if (e.hasOwnProperty("omitirRegla") && e.omitirRegla == true) {
        this.continuarBtnAgregarProductoSelect(e)
      } else {

        if (e.productos.controlado == true) {
          this.EjecuModalProductoControlado("notf");
        } else {
          this.continuarBtnAgregarProductoSelect(e)
        }

      }

      this.RallProducto = [];
      //this.NewproductoSeleccionado()
      //var eftc = JSON.parse(JSON.stringify(e))
      //-----Aqui para la modificacion de los productos ---------------------
      return 0;
    },

    continuarBtnAgregarProductoSelect(e) {
      this.selected = JSON.parse(JSON.stringify(e));
      this.EditProdCompa = false;
      var selc = JSON.parse(JSON.stringify(e));
      selc.vendedor = null;
      selc.paramOfert = false
      selc.recargo = 0;
      selc.cobertura = 0;
      selc.descuento = e.descuento;
      selc.DescValor = e.hasOwnProperty("DescValor") && e.DescValor != null ? e.DescValor : 0;
      selc.descuentoKeys = e.hasOwnProperty("descuentoKeys") && e.descuentoKeys != null ? e.descuentoKeys : 0;
      selc.ValorPorc = e.hasOwnProperty("ValorPorc") && e.ValorPorc != null ? e.ValorPorc : true;
      selc.RecarValor = 0;
      selc.valor = 0;
      selc.cantidad = e.hasOwnProperty("cantidad") && e.cantidad != null ? e.cantidad : 1;
      selc.omitirRegla = e.hasOwnProperty("omitirRegla") ? e.omitirRegla : false;
      // selc.ValorPorc = false;
      selc.ValorPorcRecarg = true;
      selc.precioOrig = e.hasOwnProperty("precioOrig") ? e.precioOrig : e.precio;
      selc.precioDig = e.precio;
      selc.descripcion= e.hasOwnProperty("descripcion") && e.descripcion!=null ? e.descripcion : e.productos.descripcion + " " + e.unidades.descripcion;
      // selc.descripcion= e.productos.descripcion + " " + e.unidades.descripcion;
      this.pedirNombre = e.productos.pedirNombre;
      this.productoSeleccionado = JSON.parse(JSON.stringify(selc));

      if (selc.hasOwnProperty("omitirRegla") && selc.omitirRegla == true) {
        this.preValidadorProducto(selc);
      } else {
        if (selc.productos.digitarcantidadenpv) {
          this.PanelMenuOpcionCompleto = false;
          this.PanelSelectProducto = false;
          this.PanelInfProduct = true;
          this.$refs.RefScroll.scrollTop = 0;
          var _this = this;
          setTimeout(function () {
            _this.$refs.RefTextFieldProductoCantidad.focus();
            _this.getExistProdXAlmacen()
            _this.getProductoSustitutos()
          }, 500);


        } else {
          this.PanelSelectProducto = false;
          this.preValidadorProducto(selc);
          //this.SalvarInfProducto();
        }
      }


    },

    // Verificando si el producto tiene descuento y si lo tiene es verificando si el descuento es mayor
    // al descuento máximo permitido para el producto.
    verficardatos() {
      if (this.productoSeleccionado.productos != null) {
        var Cant = this.productoSeleccionado.cantidad


        var PrecioVal = 0


        if (this.ConfImpfiscal == true) {
          PrecioVal = this.productoSeleccionado.precio
        } else {

          if (this.productoSeleccionado.productos.incluirimpuestoenprecio == true) {
            if (this.productoSeleccionado.productos.impuesto != null) {
              PrecioVal = (this.productoSeleccionado.precio) / (1 + (this.productoSeleccionado.productos.impuesto.tasa / 100))
            } else { PrecioVal = this.productoSeleccionado.precio }
          } else {

            PrecioVal = this.productoSeleccionado.precio
          }

        }

        //this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList

        var DescPorencima = false
        var SuperAdmin = this.$store.getters.GetdatosInfUsu.usuario.sudo
        if (this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.length > 0) {
          var ObjPerm = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.filter((ee) => ee.fn == 3 && ee.op == 14);
          if (ObjPerm.length > 0) {
            DescPorencima = ObjPerm[0].permiso
          } else {
            DescPorencima = false
          }
        }


        if (this.productoSeleccionado.ValorPorc == true) {
          //convirtiendo en porciento
          var valorDesc = ((this.productoSeleccionado.descuentoKeys * 100) / (PrecioVal * Cant));
          var DescOdeMax = ((this.productoSeleccionado.descuentoKeys * 100) / (PrecioVal * Cant));
          this.productoSeleccionado.descuento = JSON.parse(JSON.stringify(this.productoSeleccionado.descuentoKeys))

          if (this.productoSeleccionado.paramEspecial == false) {
            if (SuperAdmin == false && DescPorencima == false) {
              if (valorDesc > this.productoSeleccionado.productos.descuentomaximo) {
                var valorrr = ((this.productoSeleccionado.productos.descuentomaximo * (PrecioVal * Cant)) / 100)
                this.productoSeleccionado.descuento = valorrr
                this.productoSeleccionado.descuentoKeys = valorrr
                DescOdeMax = this.productoSeleccionado.productos.descuentomaximo
              }
            }
          }

          this.productoSeleccionado.DescValor = this.productoSeleccionado.descuento
          this.productoSeleccionado.valordescuento = this.productoSeleccionado.descuento
          this.productoSeleccionado.descuento = DescOdeMax
        } else {
          this.productoSeleccionado.descuento = JSON.parse(JSON.stringify(this.productoSeleccionado.descuentoKeys))

          if (this.productoSeleccionado.paramEspecial == false) {
            if (SuperAdmin == false && DescPorencima == false) {
              if (this.productoSeleccionado.descuento > this.productoSeleccionado.productos.descuentomaximo) {
                this.productoSeleccionado.descuento = this.productoSeleccionado.productos.descuentomaximo
                this.productoSeleccionado.descuentoKeys = this.productoSeleccionado.productos.descuentomaximo
              }
            }
          }
          //convirtiendo en valor
          this.productoSeleccionado.DescValor = ((this.productoSeleccionado.descuento * (PrecioVal * Cant)) / 100)
          this.productoSeleccionado.valordescuento = ((this.productoSeleccionado.descuento * (PrecioVal * Cant)) / 100)
        }




        if (this.productoSeleccionado.ValorPorcRecarg == true) {
          this.productoSeleccionado.RecarValor = this.productoSeleccionado.recargo
        } else {
          this.productoSeleccionado.RecarValor = ((this.productoSeleccionado.recargo * (this.productoSeleccionado.precio * Cant)) / 100)

        }

      }

      return new Promise(resolve => {
        setTimeout(() => { resolve('resolved'); }, 200);
      });

      /*  if (this.productoSeleccionado.ValorPorc === true) {
          console.log('----viendo')
          if (
            this.productoSeleccionado.descuento >
            this.productoSeleccionado.productos.descuentomaximo
          ) {
            this.productoSeleccionado.descuento =
              this.productoSeleccionado.productos.descuentomaximo;
          }
        } else {
          var valorDesc =
            this.productoSeleccionado.precio *
            (this.productoSeleccionado.productos.descuentomaximo / 100);
          console.log(valorDesc)
          if (this.productoSeleccionado.descuento > valorDesc) {
            this.productoSeleccionado.descuento = valorDesc;
          }
        } */
    },
    cambiarPrecios() {
      if (
        !this.configuracion.configPv.venderalcosto &&
        this.productoSeleccionado.precio < this.productoSeleccionado.costo
      ) {
        this.alerta("No puede dijitar el precio por debajo del costo", "error");
        return;
      }
      if (
        this.configuracion.configPv.noBajaPrecio &&
        this.productoSeleccionado.precio < this.productoSeleccionado.precioOrig
      ) {
        this.alerta("No puede bajar el precio", "error");
        return;
      }
    },
    BtnVerProductoSelect(e) {

      //var eftc = JSON.parse(JSON.stringify(e))
      //-----Aqui para la modificacion de los productos ---------------------
      this.EditProdCompa = false;
      this.ProductoOpcionVer = true
      var selc = JSON.parse(JSON.stringify(e));
      this.selected = JSON.parse(JSON.stringify(e));
      selc.vendedor = null;
      selc.paramOfert = false
      selc.recargo = 0;
      selc.cobertura = 0;
      selc.descuento = 0;
      selc.descuentoKeys = e.hasOwnProperty("descuentoKeys") && e.descuentoKeys != null ? e.descuentoKeys : 0;
      selc.DescValor = 0;
      selc.RecarValor = 0;
      selc.valor = 0;
      selc.cantidad = 1;
      selc.ValorPorc = false;
      selc.ValorPorcRecarg = false;
      selc.precioOrig = e.precio;
      selc.precioDig = e.precio;
      selc.descripcion = e.productos.descripcion + " " + e.unidades.descripcion;
      this.productoSeleccionado = JSON.parse(JSON.stringify(selc));
      this.PanelMenuOpcionCompleto = false;
      this.PanelSelectProducto = false;
      this.PanelInfProduct = true;
      this.$refs.RefScroll.scrollTop = 0;
      var _this = this;
      setTimeout(function () {
        _this.$refs.RefTextFieldProductoCantidad.focus();
        _this.getExistProdXAlmacen()
        _this.getProductoSustitutos()
      }, 500);

      //this.NewproductoSeleccionado()

      return 0;
    },

    EditarProductoSelect(e, st) {
      
      if (e.hasOwnProperty("paramOfert") && e.paramOfert == true) {
        this.alerta("No puede editar este producto porque es una oferta", "error");
        return;
      }
      var _this = this;
      setTimeout(function () {
        _this.EditProdCompa = st;
        var selc = JSON.parse(JSON.stringify(e));
        if(!e.hasOwnProperty('recargo') || e.hasOwnProperty('recargo') && e.recargo==null){
          selc.recargo=0
          selc.ValorPorcRecarg=true
        }

        if(!e.hasOwnProperty('ValorPorcRecarg') || e.hasOwnProperty('ValorPorcRecarg') && e.ValorPorcRecarg==null){
          selc.ValorPorcRecarg=true
        }


           selc.ValorPorc = false;
            selc.ValorPorcRecarg = false;
            if(e.hasOwnProperty('descuento')){selc.descuentoKeys=e.descuento}
        
        selc.paramEspecial = false
        _this.productoSeleccionado = JSON.parse(JSON.stringify(selc));
        if (st) {
          _this.PanelMenuOpcionCompleto = false;
          _this.PanelSelectProducto = false;
          _this.PanelInfProduct = true;
          _this.$refs.RefScrollProductoCompra.scrollTop = 0;
          setTimeout(function () {
            _this.$refs.RefTextFieldProductoCantidad.focus();
            _this.getExistProdXAlmacen()
            _this.getProductoSustitutos()
          }, 500);
        }

      }, 500);

      /*   this.EditProdCompa = st;
         var selc = JSON.parse(JSON.stringify(e));
         this.productoSeleccionado = JSON.parse(JSON.stringify(selc));
         if (st) {
           this.PanelMenuOpcionCompleto = false;
           this.PanelSelectProducto = false;
           this.PanelInfProduct = true;
           this.$refs.RefScrollProductoCompra.scrollTop = 0;
           var _this = this;
           setTimeout(function () {
             _this.$refs.RefTextFieldProductoCantidad.focus(); 
             _this.getExistProdXAlmacen()
           }, 500);
         }
   
   
   */



      return 0;

    },


    // Obtener los datos de la base de datos y mostrarlos en una tabla.
    getExistProdXAlmacen() {

      if (!this.productoSeleccionado.hasOwnProperty("unidadesProductosPK")) {
        return
      }

      if (this.UsarLote == false) {
        this.ListaProdxAlmacen = []
        var obj = {
          unidadProducto: JSON.parse(JSON.stringify(this.productoSeleccionado)),
        }

        this.$axios
          .post(this.$hostname + this.$hName + "/producto/ProductoAlmacExist", obj, {
            headers: this.$store.getters.GetheadersAxios,
          })
          .then((res) => {
            this.ListaProdxAlmacen = res.data

          })
          .catch((error) => { console.log('Catch---') });
      }
      if (this.UsarLote == true) {
        this.ListaProdxLote = []

        this.$axios
          .post(this.$hostname + this.$hName + "/producto/ProductoLoteExist", this.productoSeleccionado.productos, {
            headers: this.$store.getters.GetheadersAxios,
          })
          .then((res) => {

            var ObjList = res.data
            var ObjProRow = ObjList.filter((ee) => ee.fechaVencimiento.codigo == this.productoSeleccionado.lote);

            if (ObjProRow.length > 0) {
              this.ListaProdxLote = ObjList
              var _this = this;
              setTimeout(function () {
                _this.SelectorListProdXlote(ObjProRow[0])
              }, 800);
            } else {

              var Intlote = 0
              if (this.productoSeleccionado.lote.length == 0) {
                Intlote = 0
              } else {
                Intlote = this.productoSeleccionado.lote
              }

              this.$axios.get(this.$hostname + this.$hName + "/unidades-productos/findFecha/" + Intlote, {
                headers: this.$store.getters.GetheadersAxios,
              })
                .then((res) => {
                  this.ListaProdxLote = ObjList
                  var oobj = {
                    balance: 0,
                    codigo: res.data.codigo,
                    fechaVencimiento: { codigo: res.data.codigo, fecha: res.data.fecha }
                  }
                  this.ListaProdxLote.push(oobj)
                  var _this = this;
                  setTimeout(function () {
                    _this.SelectorListProdXlote(oobj)
                  }, 800);

                })
                .catch((error) => { console.log('Catch---') });
            }

          })
          .catch((error) => { console.log('Catch---') });
      }

    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable
    // ListProductoSustitutos.
    getProductoSustitutos() {
      this.ListProductoSustitutos = []
      if (!this.productoSeleccionado.hasOwnProperty("unidadesProductosPK")) {
        return
      }

      this.$axios
        .get(
          this.$hostname + this.$hName + "/unidades-productos/getSubstitutoUnidadProducto/"+ this.productoSeleccionado.unidadesProductosPK.producto+ "/" +this.caja.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.ListProductoSustitutos = res.data
        })
        .catch((error) => { console.log('Catch---') });

    },

    // Filtrando el array de objetos por la propiedad "fechaVencimiento.codigo" y devolviendo el objeto
    // que coincida con el valor de la propiedad.
    SelectorListProdXlote(e) {
      var ObjProRow = this.ListaProdxLote.filter((ee) => ee.fechaVencimiento.codigo == e.fechaVencimiento.codigo);
      this.selectedListProdxLote = ObjProRow
    },


    obtenerDatoSelectProdxLote(e) {
      this.obtenerDatoSelectProdxLoteClickRow(e.item);
    },
    obtenerDatoSelectProdxLoteClickRow(e) {
      this.selectedListProdxLote = [];
      this.selectedListProdxLote.push(e);
      this.productoSeleccionado.lote = JSON.parse(JSON.stringify(e.codigo))
      this.productoSeleccionado.existencias = JSON.parse(JSON.stringify(e.balance))

    },

    // Hacer una llamada API al servidor y obtener los datos.
    CargarRproducto() {
      this.RallProducto=[]
      this.selected=null
      return ;
      if (this.searchProducto.length == 0) {
        this.$axios
          .get(
            this.$hostname + this.$hName + "/unidades-productos/*/false/" + this.caja.sucursal.codigo + "/buscar",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.RallProducto = res.data;
          });
      }



    },
    // El código anterior es una función que se llama cuando se presiona una tecla, 
    //marca y despliega el indice del record posicionado de color diferente.
    SelectMarcaProducto(e) {
      if (this.ProdCompOrSelecProd == false) {
        // ArrowUp/ArrowDown
        if(e.keyCode == 38 ||  e.keyCode == 40){
          if (this.selected == null && this.RallProducto.length != 0) {
          this.selected = JSON.parse(JSON.stringify(this.RallProducto[0]));
          this.$refs.RefScroll.scrollTop=0
          return 0;
        }
        }
  
        if (this.selected != null) {
          //key: "Enter"keyCode: 13
          if (e.keyCode == 13) {
            if(this.validarBusProductoBarraReferencia(e.target.value)==true){
              return
            }
            
            this.EditProdCompa = false;
            var selc = JSON.parse(JSON.stringify(this.selected));

        if(!this.selected.hasOwnProperty('recargo') || this.selected.hasOwnProperty('recargo') && this.selected.recargo==null || !isNaN(this.selected.recargo)){
          selc.recargo=0
          selc.ValorPorcRecarg=true
        }

        if(!this.selected.hasOwnProperty('ValorPorcRecarg') || this.selected.hasOwnProperty('ValorPorcRecarg') && this.selected.ValorPorcRecarg==null || !isNaN(this.selected.recargo)){
          selc.ValorPorcRecarg=true
        }
            //!isNaN:Verifica si es solo numero el string
            if (this.UsarLote == true && this.searchProducto.length == 14 && !isNaN(this.searchProducto)) {
              selc.lote = parseInt(this.searchProducto.substr(10))
            }
            //console.log(isNum('13579'));   
            // this.BtnSelectAddProducto(selc)
            this.BuscPropiedadProductoSelct(selc, 'add')
            this.RallProducto=[]
            /* if (selc.productos.controlado == true) {
               this.EjecuModalProductoControlado("notf");
             } else {
               this.BtnAgregarProductoSelect(selc);
             }*/
             this.calcularDevuelta();
            return 0;
            //this.NewproductoSeleccionado()
          }

          //key: "ArrowUp"keyCode: 38
          if (e.keyCode == 38) {
            var VarInddx = JSON.parse(JSON.stringify(this.selected.indx - 1));
            if (this.RallProducto[VarInddx] != undefined) {
              this.selected = JSON.parse(
                JSON.stringify(this.RallProducto[VarInddx])
              );
              this.$refs.RefScroll.scrollTop -= 40;
            } else {
              this.selected = JSON.parse(
                JSON.stringify(this.RallProducto[VarInddx + 1])
              );
            }

            return 0;
          }

          //key: "ArrowDown"keyCode: 40
          if (e.keyCode == 40) {
            var VarInddx = JSON.parse(JSON.stringify(this.selected.indx + 1));
            if (this.RallProducto[VarInddx] != undefined) {
              this.selected = JSON.parse(
                JSON.stringify(this.RallProducto[VarInddx])
              );
              this.$refs.RefScroll.scrollTop += 40;
            } else {
              this.selected = JSON.parse(
                JSON.stringify(this.RallProducto[VarInddx - 1])
              );
            }
            return 0;
          }
        }
      }

      // Porducto seleccionado para comprar
      if (this.ProdCompOrSelecProd == true) {
        if (this.selected == null && this.RProductoCompra.length != 0) {
          this.selected = JSON.parse(JSON.stringify(this.RProductoCompra[0]));
          return 0;
        }
        if (this.selected != null) {
          if (e.keyCode == 13) {
            this.EditProdCompa = true;
            var selc = JSON.parse(JSON.stringify(this.selected));
if(!this.selected.hasOwnProperty('recargo') || this.selected.hasOwnProperty('recargo') && this.selected.recargo==null || !isNaN(this.selected.recargo)){
  selc.recargo=0
  selc.ValorPorcRecarg=true
}

if(!this.selected.hasOwnProperty('ValorPorcRecarg') || this.selected.hasOwnProperty('ValorPorcRecarg') && this.selected.ValorPorcRecarg==null || !isNaN(this.selected.recargo)){
  selc.ValorPorcRecarg=true
}
            selc.ValorPorc = false;
            selc.ValorPorcRecarg = false;
            if(this.selected.hasOwnProperty('descuento')){selc.descuentoKeys=this.selected.descuento}
            this.productoSeleccionado = JSON.parse(JSON.stringify(selc));
            this.PanelMenuOpcionCompleto = false;
            this.PanelSelectProducto = false;
            this.PanelInfProduct = true;
            this.$refs.RefScrollProductoCompra.scrollTop = 0;
            var _this = this;
            setTimeout(function () {
              _this.$refs.RefTextFieldProductoCantidad.focus();
              _this.getExistProdXAlmacen()
              _this.getProductoSustitutos()
            }, 500);
            return 0;
          }

          //key: "ArrowUp"keyCode: 38
          if (e.keyCode == 38) {
            var VarCompInddx = JSON.parse(
              JSON.stringify(this.selected.indx - 1)
            );
            if (this.RProductoCompra[VarCompInddx] != undefined) {
              this.selected = JSON.parse(
                JSON.stringify(this.RProductoCompra[VarCompInddx])
              );
              this.$refs.RefScrollProductoCompra.scrollTop -= 35;
            } else {
              this.selected = JSON.parse(
                JSON.stringify(this.RProductoCompra[VarCompInddx + 1])
              );
            }

            return 0;
          }

          //key: "ArrowDown"keyCode: 40
          if (e.keyCode == 40) {
            var VarCompInddx = JSON.parse(
              JSON.stringify(this.selected.indx + 1)
            );
            if (this.RProductoCompra[VarCompInddx] != undefined) {
              this.selected = JSON.parse(
                JSON.stringify(this.RProductoCompra[VarCompInddx])
              );
              this.$refs.RefScrollProductoCompra.scrollTop += 35;
            } else {
              this.selected = JSON.parse(
                JSON.stringify(this.RProductoCompra[VarCompInddx - 1])
              );
            }
            return 0;
          }

          //key: "Delete"keyCode: 46
          if (e.keyCode == 46) {
            
            var selc = JSON.parse(JSON.stringify(this.selected));
            this.productoSeleccionado = JSON.parse(JSON.stringify(selc));
            var _this = this;
            setTimeout(function () {
              _this.eliminarInfProductoCompra();
            }, 500);
          }

          if (e.keyCode == 117) {
            this.EditProdCompa = true;
            var selc = JSON.parse(JSON.stringify(this.selected));
            this.productoSeleccionado = JSON.parse(JSON.stringify(selc));
            this.PanelMenuOpcionCompleto = false;
            this.PanelSelectProducto = false;
            this.PanelInfProduct = true;
            this.$refs.RefScrollProductoCompra.scrollTop = 0;
            var _this = this;
            setTimeout(function () {
              _this.$refs.RefTextFieldProductoPrecio.focus();
              _this.getExistProdXAlmacen()
              _this.getProductoSustitutos()
            }, 500);
            return 0;
          }


        }
      }
    },

    async ActualizarProductPago() {
      ///--problema aqui
      if (this.Totales.totalGeneral.total == 0 && this.Totales.totalGeneral.cobertura == 0) {
        return 0;
      }

      /*   this.loading(true)
         var _this = this;
         setTimeout(function () {
           _this.LimpiaInfProducto()
         }, 500);*/

      const result = await this.LimpiaInfProducto();
      return new Promise(resolve => {
        setTimeout(() => {
          resolve('resolved');
        }, 500);
      });

    },
    condicionByPago() {
      if (this.clienteFactura == null) {
        this.filtroTipoCliente = 1
        this.OpenDialogCrearEditarCliente();
      }
    },
    // Tomando el valor del campo de entrada y agregándolo al valor del campo cxc completand el valor del faltante.
    enterCxc() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.cxc)
        );
      }
      this.Facturasventa.cxc = Objefc;
      this.calcularDevuelta();
    },
    // Tomando el valor de la entrada y sumándolo al valor de la variable para saver el valor efectivo.
    enterEfectivo() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.efectivo)
        );
      }
      this.Facturasventa.efectivo = Objefc;
      //this.Facturasventa.efectivo = this.Totales.totalGeneral.total
      this.calcularDevuelta();
    },
    //  //  convierte la devuelta de la Nota de credito para poner el monto restante a el campo faltante.
    enterNC() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.valorNc)
        );
      }
      this.Facturasventa.valorNc = Objefc;

      this.calcularDevuelta();
    },
    // Tomando el valor de la entrada y agregándolo al objeto del pago de tarjeta.
    enterTarjeta() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.tarjeta)
        );
      }

      this.Facturasventa.tarjeta = Objefc;
      this.calcularDevuelta();
    },
    // Tomando el valor de la entrada de cheques y sumándolo al valor de la entrada devuelta.
    enterCheque() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.cheques)
        );
      }
      this.Facturasventa.cheques = Objefc;
      this.calcularDevuelta();
    },
    // convierte la devuelta de la conduce para poner el monto restante a el campo faltante.
    enterConduce() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.valorConduce)
        );
      }
      this.Facturasventa.valorConduce = Objefc;
      this.calcularDevuelta();
    },
    //  convierte la devuelta de la cupones para poner el monto restante a el campo faltante.
    enterCupones() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.cupones)
        );
      }
      this.Facturasventa.cupones = Objefc;
      this.calcularDevuelta();
    },
    // convierte la devuelta de la tranferencia para poner el monto restante a el campo.
    enterTransferencia() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.transferencia)
        );
      }

      this.Facturasventa.transferencia = Objefc;
      this.calcularDevuelta();
    },

    // Tomando el valor de la entrada y sumándolo al valor de la variable para saver el valor punto.
    enterPunto() {
      var Objefc = 0;
      if (this.devuelta < 0) {
        Objefc = JSON.parse(
          JSON.stringify(this.devuelta * -1 + this.Facturasventa.puntos)
        );
      }
      this.Facturasventa.puntos = Objefc;
      //this.Facturasventa.efectivo = this.Totales.totalGeneral.total
      this.calcularDevuelta();
    },

    // Un método que se llama cuando el usuario abandona el campo de entrada.
    BlurEfectivoPago() {
      if (this.Facturasventa.efectivo < 0) {
        this.Facturasventa.efectivo = 0
        this.calculostotales()
      }
    },

    BlurTarjetaPago() {
      if (this.Facturasventa.tarjeta < 0) {
        this.Facturasventa.tarjeta = 0
        this.calculostotales()
      }
    },
    // Un método que se llama cuando el usuario abandona el campo de entrada.
    BlurChequesPago() {
      if (this.Facturasventa.cheques < 0) {
        this.Facturasventa.cheques = 0
        this.calculostotales()
      }
    },

    BlurTransferenciaPago() {
      if (this.Facturasventa.transferencia < 0) {
        this.Facturasventa.transferencia = 0
        this.calculostotales()
      }
    },
    BlurCxcPago() {
      if (this.Facturasventa.cxc < 0) {
        this.Facturasventa.cxc = 0
        this.calculostotales()
      }

      var QUITAR_DESC=this.$store.getters.GetConfiguracionGeneral.configPv.quitarDescuentoClienteCredito
      if(QUITAR_DESC!=null && QUITAR_DESC==true){ 
if(this.Facturasventa.cxc>0 && this.Totales.totalGeneral.descuento>0){
      this.dialogNotifQuitarDescuentoCliente=true
      return
      }
    }


    },

    BlurCuponesPago() {
      if (this.Facturasventa.cupones < 0) {
        this.Facturasventa.cupones = 0
        this.calculostotales()
      }
    },
    BlurConducePago() {
      if (this.Facturasventa.valorConduce < 0) {
        this.Facturasventa.valorConduce = 0
        this.calculostotales()
      }
    },
    BlurValorNcPago() {
      if (this.Facturasventa.valorNc < 0) {
        this.Facturasventa.valorNc = 0
        this.calculostotales()
      }
    },

// Un método que se llama cuando el usuario abandona el campo de entrada.
BlurPuntos() {
      if (this.Facturasventa.puntos < 0) {
        this.Facturasventa.puntos = 0
        this.calculostotales()
      }
    },


    // Activa conduce a credito
    btnActivaInactivaConduceCredito() {
      this.activaConduceCredito = !this.activaConduceCredito

      if (this.activaConduceCredito == true) {
        this.Facturasventa.efectivo = 0
        this.Facturasventa.tarjeta = 0
        this.Facturasventa.cheques = 0
        this.Facturasventa.transferencia = 0
        this.Facturasventa.cxc = 0
        this.Facturasventa.puntos = 0
        // this.Facturasventa.cupones=0
        this.Facturasventa.valorNc = 0
        this.Facturasventa.numtar = null
        this.tipoTarjeta = null
        this.bancoCheque = null
        this.Facturasventa.numeroCheque = ""
      }

      this.calculostotales()


    },


    // Cálculo del cambio a devolver al cliente.
    calcularDevuelta() {
      var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
      var MONEDAFACTURA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa )
      var tot = this.RedondeoBasico(
        (
        this.Facturasventa.efectivo +
        this.Facturasventa.cxc +
        this.Facturasventa.cupones +
        this.Facturasventa.puntos +
        this.Facturasventa.transferencia +
        this.Facturasventa.valorNc +
        this.Facturasventa.cheques +
        this.Facturasventa.valorConduce +
        this.Facturasventa.tarjeta) * MONEDAFACTURA
        
        )



      this.devuelta = Math.round((tot - this.Totales.totalGeneral.total) * 100) / 100;

      /*  return new Promise(resolve => {
    setTimeout(() => {
      resolve('resolved');
    }, 2000);
  });*/


      /*   var _this = this;
             setTimeout(function () {
                _this.loading(false)
             }, 300);*/

    },
    buscardor(e){
      clearTimeout(0);
     // setTimeout(this.SeachFilProducto(e),300);
      //this.SeachFilProducto(e);
    },



    SeachFilkeyEnterProd(e){
      this.SeachFilProducto(e)
    },

   
    SelectMarcaProductoKeyNofluido(e){
      
      // @keydown="SelectMarcaProducto"
     //key: "ArrowUp"keyCode: 38
      //key: "ArrowDown"keyCode: 40
     //key: "Delete"keyCode: 46
    //key: "F6"keyCode: 117
     //key: "Enter"keyCode: 13
  
    if (this.selected != null && e.keyCode == 13) {
      this.SelectMarcaProducto(e)
      return
    }

    // var pru=(e.keyCode != 38 && e.keyCode != 40 && e.keyCode != 46 && e.keyCode != 117)
    // console.log(pru)

    if(e.keyCode != 38 && e.keyCode != 40 && e.keyCode != 46 && e.keyCode != 117){
      this.selected = null
      return
    }

    this.SelectMarcaProducto(e)
 

    },
 
    // Una función que se ejecuta cuando el usuario ingresa un valor en la entrada, la función se
    // ejecuta y el valor se envía al backend, el backend devuelve los datos y los datos se muestran en
    // la entrada.
    SeachFilProducto(e) {
      
      //Enter:13,ArrowUp:38,ArrowDown:40
      if(e.keyCode != 13 && e.keyCode != 38  && e.keyCode != 40){
        this.selected = null
      }
      /*var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      console.log('viendo-->',MODALIDAD_CLI_PRE)
      console.log('viendo-->',e.target.value)*/
      

      var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true){
        var BANDERA_CODBAR_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.banderaCodigoBarraPreferencial
        //key: "Enter"keyCode: 13
        if(e.keyCode == 13){
          if(e.target.value.substr(0,2)===BANDERA_CODBAR_CLI_PRE){
            this.calcularDevuelta();
            this.SeachTextFilBarraPreferencial(e.target.value)
            return
          }
        }
      }

      var BUS_CODIGO_BARR_REF=this.$store.getters.GetConfiguracionGeneral.configPv.busquedaPorCodigobarraReferencia
        if(BUS_CODIGO_BARR_REF && e.target.value.substr(0,2)==='**' ){
          //console.log('Desc-->',e.target.value.substr(2).trim())
          this.SeachProductoBusquedaConsulta(e.target.value.substr(2).trim())
          return

          }

      var BUSQUEDA_FLUIDA=this.$store.getters.GetConfiguracionGeneral.configPv.busquedaFluido
      if(BUSQUEDA_FLUIDA==true&&MODALIDAD_CLI_PRE==false){
        this.calcularDevuelta();
        this.SeachProductoFluida()

      }else{
//key: "Enter"keyCode: 13
      if(e.keyCode == 13){
      if (e.target.value.trim().length == 0) {
        return;
      }

      //console.log('viendo-->',e.target.value)
      this.calcularDevuelta();
    // this.disabledTextFieldProducto=true
      this.SeachProducto()
      if (
        e.keyCode != 38 &&
        e.keyCode != 40 &&
        e.keyCode != 13 &&
        e.keyCode != 46
      ) {
        this.NewproductoSeleccionado();
      }
       }
      }
      

    },

    SeachProducto(){
      // this.$store.getters.GetConfiguracionGeneral.configPv.busquedaUnidadMinima
      if (this.$refs.RefTextFieldProducto.value.trim().length == 0) {
        return;
      }

      var USAR_MINIMA=this.$store.getters.GetConfiguracionGeneral.configPv.busquedaUnidadMinima
      var link=`${this.$hostname+this.$hName}`
      if(USAR_MINIMA==null || USAR_MINIMA==false){
      link+=`/unidades-productos/${this.$refs.RefTextFieldProducto.value.trim()}/false/${this.caja.sucursal.codigo}/buscar`
      // this.$hostname +this.$hName +"/unidades-productos/" +this.$refs.RefTextFieldProducto.value +"/false/" + this.caja.sucursal.codigo + "/buscar"
      }else{
        link+=`/unidades-productos/${this.$refs.RefTextFieldProducto.value.trim()}/false/${this.caja.sucursal.codigo}/buscar/true`
      }
      //{qrt}/{bandera}/buscar/{minima}

      this.$axios
        .get(
          link,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          //  this.RallProducto = res.data
          // &&res.data[0].productos.controlado==false
          if(res.data.length==1){
            this.disabledTextFieldProducto=true
            this.selected=res.data[0]
            this.RallProducto = res.data
            this.BuscPropiedadProductoSelct(res.data[0], 'add')
            if(res.data[0].productos.controlado==true){
              this.selecteTemRecCont=res.data[0]
            }
            
            // this.RallProducto=[]
            var _this = this;
          /*setTimeout(function () {
            //  _this.selected=res.data[0]
            _this.RedireccionFocus('RefTextFieldProducto')
        // _this.$refs.RefTextFieldProducto.focus();
           }, 1000);*/

           setTimeout(function () {
            //  _this.selected=res.data[0]
            _this.disabledTextFieldProducto=false
        // _this.$refs.RefTextFieldProducto.focus();
           }, 2000);

           setTimeout(function () {
            //  _this.selected=res.data[0]
            _this.RedireccionFocus('RefTextFieldProducto')
        // _this.$refs.RefTextFieldProducto.focus();
           }, 2500);


          }else{
            this.disabledTextFieldProducto=false
            this.RallProducto = res.data
          }
        }
        
        
        );  
    },


    SeachProductoFluida(){
      // this.$store.getters.GetConfiguracionGeneral.configPv.busquedaUnidadMinima
      if (this.$refs.RefTextFieldProducto.value.trim().length == 0) {
        return;
      }

      var USAR_MINIMA=this.$store.getters.GetConfiguracionGeneral.configPv.busquedaUnidadMinima
      var link=`${this.$hostname+this.$hName}`
      if(USAR_MINIMA==null || USAR_MINIMA==false){
      link+=`/unidades-productos/${this.$refs.RefTextFieldProducto.value}/false/${this.caja.sucursal.codigo}/buscar`
      // this.$hostname +this.$hName +"/unidades-productos/" +this.$refs.RefTextFieldProducto.value +"/false/" + this.caja.sucursal.codigo + "/buscar"
      }else{
        link+=`/unidades-productos/${this.$refs.RefTextFieldProducto.value}/false/${this.caja.sucursal.codigo}/buscar/true`
      }
      //{qrt}/{bandera}/buscar/{minima}

      this.$axios
        .get(
          link,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          //  this.RallProducto = res.data
          // &&res.data[0].productos.controlado==false
          this.RallProducto = res.data
        });  
    },

validarBusProductoBarraReferencia(DESCPROD){
  var BOOL=false
  var BUS_CODIGO_BARR_REF=this.$store.getters.GetConfiguracionGeneral.configPv.busquedaPorCodigobarraReferencia
            if(typeof DESCPROD==='string' && BUS_CODIGO_BARR_REF && DESCPROD.substr(0,2)==='**' ){
              BOOL=true
            }

  return BOOL;
},

    SeachProductoBusquedaConsulta(DESC){
      // this.$store.getters.GetConfiguracionGeneral.configPv.busquedaUnidadMinima
      if (this.$refs.RefTextFieldProducto.value.trim().length == 0) {
        return;
      }

      var USAR_MINIMA=this.$store.getters.GetConfiguracionGeneral.configPv.busquedaUnidadMinima
      var link=`${this.$hostname+this.$hName}`
      if(USAR_MINIMA==null || USAR_MINIMA==false){
      link+=`/unidades-productos/${DESC}/false/${this.caja.sucursal.codigo}/buscarConsulta`
      // this.$hostname +this.$hName +"/unidades-productos/" +this.$refs.RefTextFieldProducto.value +"/false/" + this.caja.sucursal.codigo + "/buscar"
      }else{
        link+=`/unidades-productos/${DESC}/false/${this.caja.sucursal.codigo}/buscar/true`
      }
      //{qrt}/{bandera}/buscar/{minima}

      this.$axios
        .get(
          link,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RallProducto = res.data
        });  
    },

    SelctCbProduc() {
      if (this.producto == null) {
        this.producto = "";
      }
      if (this.producto.codigo == undefined) {
        this.alerta("Seleccione un producto", "error");
        this.producto = "";
        return;
      }
    },
    ChangeProductoselectValorPorcc() {
      // if(e==true){this.productoSeleccionado.descuento=0
      // }else{this.productoSeleccionado.descuentoValor=0}
    },

    // Comprobando si el usuario tiene permiso para cambiar la cantidad de un producto.
    FocusProductoCantidad() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambioCantidadProd')
        return;
      }

    },
    // Comprobar si el usuario tiene permiso para cambiar el precio de un producto.
    FocusProductoPrecio() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambioPrecioProd')
        return;
      }

    },
    FocusProductoDescuento() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambiarDescuentoProd')
        return;
      }

    },

    FocusProductoRecargo() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambiarRecargoProd')
        return;
      }

    },

    async getRulesProductoPrecio(){

     if(this.PanelInfProduct==false){
            this.boolRuleprodprecio= false
            return false
          }else{
     var BOOL = await this.$refs.RefPvMenuOpcionBotones.EjecutVerificarPermiso('AccCambiarProductoAOferta');
     this.boolRuleprodprecio= BOOL
     return  BOOL;
          }
     
      },

      enterPrecioProdSelect(){
        this.EjecutarPermisoPv = false
        var _this=this;
setTimeout(function(){ 
  if(_this.productoSeleccionado.precio!=_this.productoSeleccionado.precioOrig){
    _this.boolEnterPrecioProdSelect=true
    _this.blurPrecioProductoSelecc()
  }else{
      _this.SalvarProductoCompraEnter()
  }
      }, 300);

      }, 

      async blurPrecioProductoSelecc() {
        var Config = this.$store.getters.GetConfiguracionGeneral
        var BOOL = await this.$refs.RefPvMenuOpcionBotones.EjecutVerificarPermiso('AccCambiarPrecioProductoBajoPrecioActual');
var _this=this;
setTimeout(function(){  
  if(BOOL==false && _this.productoSeleccionado.precio<_this.productoSeleccionado.precioOrig){
    _this.EjecutarPermisoPv = true
    _this.rulePrecioProdBajoPrecio=JSON.parse(JSON.stringify(_this.productoSeleccionado.precio))
    _this.productoSeleccionado.precio=JSON.parse(JSON.stringify(_this.productoSeleccionado.precioOrig))
    _this.funcionValidaPermisoPv('AccCambiarPrecioProductoBajoPrecioActual')
    return;
  }else{
  _this.productoSeleccionado.precioDig=JSON.parse(JSON.stringify(_this.productoSeleccionado.precio));
 
  if(_this.boolEnterPrecioProdSelect==true){
    setTimeout(function () { _this.SalvarProductoCompraEnter() }, 100)
  }

}
  
}, 300);

/*precio: 1200
precioDig: 1200
precioOrig: 1300.78*/

/*var _this=this;
setTimeout(function(){  
  _this.productoSeleccionado.precioDig=JSON.parse(JSON.stringify(_this.productoSeleccionado.precio));
}, 300);*/

    },
   
    // Verificar si el producto está controlado y si el producto está controlado es verificar si la
    // cantidad es mayor a la prescrita.
    ValidaRecetaProduControlado() {
      //alert('Hola')

      if (this.productoSeleccionado.productos != null) {
        if (this.productoSeleccionado.productos.controlado == true
          && this.RecetaProdControlado.detalleList.length > 0) {
          this.productoSeleccionado.productos.codigo;
          this.productoSeleccionado.unidades.codigo;
/*console.log(this.RecetaProdControlado.detalleList)
console.log("CodiPro:"+this.productoSeleccionado.productos.codigo)
console.log("UniPro:"+this.productoSeleccionado.unidades.codigo)*/

          var ObjProRow = this.RecetaProdControlado.detalleList.filter(
            (e) =>
              e.productos.codigo ==
              this.productoSeleccionado.productos.codigo &&
              e.unidad.codigo == this.productoSeleccionado.unidades.codigo
          )[0];
          if (this.productoSeleccionado.cantidad + ObjProRow.cantidadDespachada > ObjProRow.cantidadRecetada) {
            //  this.productoSeleccionado.cantidad = 1;
            this.productoSeleccionado.cantidad = (ObjProRow.cantidadRecetada - ObjProRow.cantidadDespachada);
            this.alerta(
              "La cantidad a despachar esta por encima de la cantiadad recetada",
              "error"
            );

            return
          }
        }
      }

    },

    async SalvarProductoCompraEnter() {
      if(await this.getRulesProductoPrecio()==true && this.productoSeleccionado.precio==0){
        this.AgregarOfertasCompra(this.productoSeleccionado)
        return
      }

      var _this=this;
      setTimeout(function(){  
      if(_this.EjecutarPermisoPv==true){
        return
      }
      const result =  _this.verficardatos()
      _this.ValidaRecetaProduControlado()
      _this.ValidarProductoOpcionVer(_this.productoSeleccionado)
      _this.boolEnterPrecioProdSelect=false

    }, 999);


    },

    // Creando un nuevo objeto y asignándolo a la variable productoSeleccionado.
    NewproductoSeleccionado() {
      this.selected = null;
      this.productoSeleccionado = {
        cantidad: 1,
        precio: 0,
        descuento: 0,
        vendedor: null,
        recargo: 0,
        ValorPorcRecarg: false,
        cobertura: 0,
        productos: null,
        valor: 0,
        ValorPorc: false,
      };
      this.EjecutarInfSaveProd = true
      this.ejecutarEliminarProd=true
      this.rulePrecioProdBajoPrecio=0
      this.boolEnterPrecioProdSelect=false
    },

    // Guardando la información del producto en la tabla.
    SalvarInfProducto() {
      this.loading(true)


      if (this.EditProdCompa == false) {
        var ProTem = JSON.parse(JSON.stringify(this.productoSeleccionado));

        if (ProTem.hasOwnProperty("omitirRegla")) {
          //  ProTem.omitirRegla=false
        } 
        this.RProductoCompra.unshift(JSON.parse(JSON.stringify(ProTem)));
        this.RProductoCompraTemp.unshift(JSON.parse(JSON.stringify(ProTem)));
        this.$refs.RefScroll.scrollTop = 0;
        this.$refs.RefScrollProductoCompra.scrollTop = 0;
        this.PanelSelectProducto = true
        //   this.LimpiaInfProducto(); 
        setTimeout(function () {
          _this.LimpiaInfProducto();
        }, 400);
      }

      var _this = this;
      if (this.EditProdCompa == true) {
        var prods = JSON.parse(JSON.stringify(this.productoSeleccionado));
        if (prods.hasOwnProperty("omitirRegla")) {
          //  prods.omitirRegla=false
        } 
        prods.indx = 0;
        //this.eliminarInfProductoCompra();

        var ObjRow = this.RProductoCompra.filter((e) => e.indx == this.productoSeleccionado.indx)[0];
        const index = this.RProductoCompra.indexOf(ObjRow);
        this.RProductoCompra.splice(index, 1);
        this.RProductoCompraTemp.splice(index, 1);
        this.cancelarInfProducto();
        setTimeout(function () {
          _this.RProductoCompra.unshift(JSON.parse(JSON.stringify(prods)));
          _this.RProductoCompraTemp.unshift(JSON.parse(JSON.stringify(prods)));
          _this.$refs.RefScroll.scrollTop = 0;
          _this.$refs.RefScrollProductoCompra.scrollTop = 0;
        }, 300);

        setTimeout(function () {
          _this.LimpiaInfProducto();
        }, 400);
      }
      this.loading(false)
      //this.LimpiaInfProducto(); 


      setTimeout(function () {
        _this.$refs.RefTextFieldProducto.focus();
      }, 500);


      /*setTimeout(function () {
           _this.LimpiaInfProducto(); 
         }, 400);*/



      //
      /*   if (this.Descuentofactura.DescuentoFacturaGlobal==false) { 
         var _this = this;
         setTimeout(function () {
           _this.ReActualizarProductoCompra();
         }, 900);
          }*/

    },

    // Una función que se llama cuando un usuario hace clic en un botón.
    async LimpiaInfProducto() {
      this.PanelMenuOpcionCompleto = true;
      this.PanelSelectProducto = true;
      this.PanelInfProduct = false;
      this.searchProducto = "";
      this.ObjRectPro = null
      this.EsNuevoObjRectPro = true
      this.NewproductoSeleccionado();
      var _this = this;
      /* setTimeout(function () {
         //  _this.$refs.RefTextFieldProducto.focus();
         // _this.CalcularTotalesProductosCompra();
         _this.ReActualizarProductoCompra();
       }, 500);*/

      /*  if (this.Descuentofactura.DescuentoFacturaGlobal == false) {
          var _this = this;
          setTimeout(function () {
            _this.ReActualizarProductoCompra();
          }, 900);
        }*/

      const result = await this.ReActualizarProductoCompra();
      return new Promise(resolve => {
        setTimeout(() => {
          resolve('resolved');
        }, 500);
      });


    },

    // Eliminando el último elemento de la lista.
    cancelarInfProducto() {

      if (this.EditProdCompa == false && this.EsNuevoObjRectPro == true) {
        if (this.productoSeleccionado.productos != null && this.productoSeleccionado.productos.controlado == true) {
          if (this.RecetaProdControlado.detalleList.length > 1) {
            var size = this.RecetaProdControlado.detalleList.length - 1
            var RectPro = this.RecetaProdControlado.detalleList[size]
            const index = this.RecetaProdControlado.detalleList.indexOf(RectPro);
            this.RecetaProdControlado.detalleList.splice(index, 1);
          } else {
            this.NewRecetaProdCOntrolado();
          }

        }
      }

      /*

            if (
              this.productoSeleccionado.productos != null &&
              this.productoSeleccionado.productos.controlado == true
            ) {
              this.NewRecetaProdCOntrolado();
            }*/

      this.PanelMenuOpcionCompleto = true;
      this.PanelSelectProducto = true;
      this.PanelInfProduct = false;
      this.searchProducto = "";
      this.ejecutarEliminarProd=true

      this.NewproductoSeleccionado();
      var _this = this;
      setTimeout(function () {
        _this.$refs.RefTextFieldProducto.focus();
        _this.CalcularTotalesProductosCompra();
      }, 500);
    },

    // Eliminación de un elemento de una lista.
    async eliminarInfProductoCompra() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccEliminarLineaProd')
        return;
      }
      
      // Removiendo Producto controlado de la receta
      /*  if (this.productoSeleccionado.productos.controlado == true) {
  
          if (this.RecetaProdControlado.detalleList.length > 1) {
            var ObjRow = this.RecetaProdControlado.detalleList.filter((e) => e.productos.codigo == this.productoSeleccionado.productos.codigo
              && e.unidad.codigo == this.productoSeleccionado.unidades.codigo)[0];
            const index = this.RecetaProdControlado.detalleList.indexOf(ObjRow);
            this.RecetaProdControlado.detalleList.splice(index, 1);
          } else {
            this.NewRecetaProdCOntrolado();
          }
  
        }*/

      //ELIMINAR: Remueve producto a la lista


      if(this.ejecutarEliminarProd==false){
        return
      }

    
      var _this = this;
      this.ejecutarEliminarProd=false


      setTimeout(function () {
        var ObjProRow = _this.RProductoCompra.filter(
        (ee) => ee.indx == _this.productoSeleccionado.indx)[0];
      const index = _this.RProductoCompra.indexOf(ObjProRow);
      _this.cancelarInfProducto();

      _this.RProductoCompra.splice(index, 1);
      _this.RProductoCompraTemp.splice(index, 1);
   
      /*var codPro = _this.productoSeleccionado.productos.codigo
      var codUni = _this.productoSeleccionado.unidades.codigo
      var RProTem = JSON.parse(JSON.stringify(_this.RProductoCompraTemp))
      var RProTemBse = JSON.parse(JSON.stringify(_this.RProductoCompraTemp))*/

      /*console.log('RProTem',RProTem)
      console.log('RProTemBse',RProTemBse)*/
      // RProTemBse.forEach((ele) => {});
      // (async () => {   })()
     /* await Promise.all(RProTemBse.map(async (ele) => { 
        if (ele.hasOwnProperty("paramOfert") && ele.paramOfert == true) {
          if (ele.ProductoBase.productos.codigo == codPro && ele.ProductoBase.unidades.codigo == codUni) {
            const ix = RProTem.indexOf(ele);
            RProTem.splice(ix, 1);
          }

        }
      }));*/


      }, 400);

      
      //ELIMINAR: Remueve producto a la lista
      /*     this.RProductoCompra.forEach((element) => {
             if (element.indx == this.productoSeleccionado.indx) {
               const index = this.RProductoCompra.indexOf(element);
               // const index2 =  this.RProductoCompraTemp.indexOf(element)
               this.RProductoCompra.splice(index, 1);
               this.RProductoCompraTemp.splice(index, 1);
               this.cancelarInfProducto();
             }
           }); */

     
      setTimeout(function () {
       // _this.RProductoCompra = JSON.parse(JSON.stringify(RProTem))
       // _this.RProductoCompraTemp = JSON.parse(JSON.stringify(RProTem))
        _this.CalcularTotalesProductosCompra();
      }, 800);
    },

    // El código anterior es una función que se llama cuando el usuario hace clic en un botón.
    //calcula los decuento automatico de el producto
    // La función se llama ReActualizarProductoCompra()
    // La función se llama cuando el usuario hace clic en un botón.
    // La función se llama ReActualizarProductoCompra()
    // La función se llama cuando el usuario hace clic en un botón.
    // La función se llama ReActualizarProductoCompra()
    // La función se llama cuando el usuario hace clic en un botón.
    // La función se llama ReActualizarProductoCompra()
    // la funcion es
    async ReActualizarProductoCompra() {
       
      var Totales = this.Totales.totalGeneral
      var cxc = this.Facturasventa.cxc
      var efectivo = this.Facturasventa.efectivo
      var tarjeta = this.Facturasventa.tarjeta
      var SoloCxc = this.$store.getters.GetConfiguracionGeneral.configPv.soloCxc

      var cli = 0
      if (this.clienteFactura != null) {
        cli = this.clienteFactura.codigo
      }

      if (this.RProductoCompra.length > 0) {
        this.loading(true)
        /* var RObjProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompra));
         this.RProductoCompraTemp = JSON.parse(JSON.stringify(this.RProductoCompra));*/
        var RObjProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp));
        this.RProductoCompraTemp = JSON.parse(JSON.stringify(this.RProductoCompraTemp));
        this.RProductoCompraTemp = []
        this.RProductoCompra = []
    
     // var ObjPerm = this.RProductoCompraOfercta.filter((ee) => ee.ProductoBase==null);
       // this.RProductoCompraOfercta = []
       this.RProductoCompraOfercta = this.RProductoCompraOfercta.filter((ee) => ee.ProductoBase==null);
       
        /* RObjProductoCompra.forEach((element) => {
           this.verficarDescuentoProducto(element)
 
         });*/
        if (this.Descuentofactura.descuento > 0) {

          
          var bar1 = new Promise((resolve, reject) => {
            
            // RObjProductoCompra.forEach((element) => {});
           // (async () => {  
            //await 
            Promise.all(RObjProductoCompra.map(async (element) => { 
          var link1 = `/producto/getOferctaProducto/${element.cantidad}/${element.productos.codigo}/${element.unidades.codigo}`
                    this.$axios.get(this.$hostname + this.$hName + link1,
                      { headers: this.$store.getters.GetheadersAxios })
                      .then(res => {
                        
                        
                        if (res.data.sizeList > 0) {
                          this.AgregarEliminarOfertasCompra(res.data.listUniProd, element)
                        }

                      var _this=this;
                      setTimeout(function(){ resolve() }, 600);

                      })
                      .catch(error => { console.log('-E-:' + error) }
                      );
                    }));

                 // })()

                    }); 

bar1.then(() => { 
                  var ObjProRow = RObjProductoCompra.filter((ee) => !ee.hasOwnProperty("paramOfert") || ee.paramOfert == false);
                  this.RProductoCompraTemp = ObjProRow.concat(this.RProductoCompraOfercta)
                  this.RProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp));

          var _this = this;
        setTimeout(function () {
          _this.CalcularTotalesProductosCompra();
        }, 300);

        return new Promise(resolve => {
          setTimeout(() => {
            _this.loading(false)
            resolve('resolved');
          }, 400);
        });

      });


        } else {
          var bar = new Promise((resolve, reject) => {

            // RObjProductoCompra.forEach((element) => {});
           // (async () => {
            //await 
            Promise.all(RObjProductoCompra.map(async (element) => { 


              if (!element.hasOwnProperty("paramOfert") || element.paramOfert == false) {
                var link = `/producto/${element.productos.codigo}/descuento/${cxc > 0}/${this.domiciliofactura != null}/${Totales.cobertura > 0}/${tarjeta > 0}/${Totales.subtotal}/${cli}`
                this.$axios
                  .get(
                    this.$hostname +
                    this.$hName +
                    link,
                    {
                      headers: this.$store.getters.GetheadersAxios
                    }
                  )
                  .then(res => {
                    if (element.descuento <= 0) {
                      element.descuento = res.data.descuento
                      element.descuentoKeys = res.data.descuento
                      element.paramEspecial = res.data.especial
                      if (res.data.descuento > 0) { element.ValorPorc = false } else { element.ValorPorc = true }
                      this.verficarDescuentoProducto(element)
                    }
                    var link1 = `/producto/getOferctaProducto/${element.cantidad}/${element.productos.codigo}/${element.unidades.codigo}`
                    this.$axios.get(this.$hostname + this.$hName + link1,
                      { headers: this.$store.getters.GetheadersAxios })
                      .then(res => {
                        
                        
                        if (res.data.sizeList > 0) {
                          this.AgregarEliminarOfertasCompra(res.data.listUniProd, element)
                        }

                      var _this=this;
                      setTimeout(function(){ resolve() }, 600);

                      })
                      .catch(error => { console.log('-E-:' + error) }
                      );
                  })
                  .catch(error => { console.log('-E-:' + error) }
                  );
              }
            }));
            //  })()

          });

          bar.then(() => {  
            var ObjProRow = RObjProductoCompra.filter(
            (ee) => !ee.hasOwnProperty("paramOfert") || ee.paramOfert == false);
          var _this = this;
          this.RProductoCompraTemp = ObjProRow.concat(this.RProductoCompraOfercta)
          this.RProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp));
        
          var _this = this;
        setTimeout(function () {
          _this.CalcularTotalesProductosCompra();
          _this.loading(false)
        }, 300);

        return new Promise(resolve => {
          setTimeout(() => {
            _this.loading(false)
            resolve('resolved');
          }, 400);
        });
        
        });
          
          

        }








        
        /* setTimeout(function () {
           _this.RProductoCompraTemp = ObjProRow.concat(_this.RProductoCompraOfercta)
           _this.RProductoCompra = JSON.parse(JSON.stringify(_this.RProductoCompraTemp));
           // _this.RProductoCompraTemp = JSON.parse(JSON.stringify(RObjProductoCompra))
           // _this.RProductoCompra = JSON.parse(JSON.stringify(RObjProductoCompra))
         }, 700);*/
        

        /* var _this = this;
         setTimeout(function () {
           _this.CalcularTotalesProductosCompra();
         }, 800);*/

      }
    },

    // Agregar una nueva propiedad al objeto.
    async AgregarEliminarOfertasCompra(e, ProductoBase) {
      //e.forEach(element => {});
      console.log('AgregarEliminarOfertasCompra-->',e)
      await Promise.all(e.map(async (element) => { 
        element.ProductoBase = ProductoBase
        element.DescValor = 0
        element.ProductoValidado = true
        element.RecarValor = 0
        element.ValorPorc = false
        element.ValorPorcRecarg = 0
        element.cobertura = 0
        element.descuento = 0
        element.descuentoKeys = 0
        element.recargo = 0
        element.descripcion = element.productos.descripcion.trim() + " " + element.unidades.descripcion.trim()
        element.valor = 0
        element.paramEspecial = false,
        element.paramOfert = true

        this.RProductoCompraOfercta.push(element)
      }));

    },

    //esto es cuando el producto en la opcion de editar se coloca el precio 0
    AgregarOfertasCompra(e){
      this.loading(true)
       var element= JSON.parse(JSON.stringify(e))

        element.ProductoBase = null
        element.DescValor = 0
        element.ProductoValidado = true
        element.RecarValor = 0
        element.ValorPorc = false
        element.ValorPorcRecarg = 0
        element.cobertura = 0
        element.descuento = 0
        element.descuentoKeys = 0
        element.recargo = 0
        element.descripcion = element.productos.descripcion.trim() + " " + element.unidades.descripcion.trim()
        element.valor = 0
        element.paramEspecial = false,
        element.paramOfert = true
        this.RProductoCompraOfercta.push(element)
        this.RProductoCompra.push(element)
       this.RProductoCompraTemp.push(element)
       //this.BtnAgregarProductoSelect(element)
      
          var _this = this;
            setTimeout(function () {
              _this.cancelarInfProducto()
            //  _this.CalcularTotalesProductosCompra();
              _this.loading(false)
            }, 400);

        
    },


    verficarDescuentoProducto(e) {
      var DescPorencima = false
      var SuperAdmin = this.$store.getters.GetdatosInfUsu.usuario.sudo
      if (this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.length > 0) {
        var ObjPerm = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.filter((ee) => ee.fn == 3 && ee.op == 14);
        if (ObjPerm.length > 0) {
          DescPorencima = ObjPerm[0].permiso
        } else {
          DescPorencima = false
        }
      }

      if (e.productos != null) {
        var Cant = e.cantidad
        if (e.ValorPorc == true) {
          var valorDesc = ((e.descuentoKeys * 100) / (e.precio * Cant));
          var DescOdeMax = ((e.descuentoKeys * 100) / (e.precio * Cant));

          if (e.paramEspecial == false) {
            if (SuperAdmin == false && DescPorencima == false) {
              if (valorDesc > e.productos.descuentomaximo) {
                var valorrr = ((e.productos.descuentomaximo * (e.precio * Cant)) / 100)
                e.descuento = valorrr
                e.descuentoKeys = valorrr
                DescOdeMax = e.productos.descuentomaximo
              }
            }

          }

          //e.DescValor = e.descuento
          e.DescValor = e.descuentoKeys
          e.descuento = DescOdeMax
        } else {
          var valorDesc = e.descuentoKeys;
          e.descuento = e.descuentoKeys;
          if (e.paramEspecial == false) {
            if (SuperAdmin == false && DescPorencima == false) {
              if (e.descuento > e.productos.descuentomaximo) {
                e.descuento = e.productos.descuentomaximo
                valorDesc = e.productos.descuentomaximo
                e.descuentoKeys = e.productos.descuentomaximo
              }
            }
          }


          e.DescValor = ((valorDesc * (e.precio * Cant)) / 100)

          // console.log(e.descuento + "*(" + e.precio + "*" + Cant + "))/100) =" + e.DescValor)

        }

        if (e.ValorPorcRecarg == true) {
          e.RecarValor = e.recargo
        } else {
          e.RecarValor = ((e.recargo * (e.precio * Cant)) / 100)

        }

      }

    },

    RemoveTax(prod, precio) {
      var pre = 0
      if (prod.incluirimpuestoenprecio == true && prod.impuesto != null) {
        pre = (precio / (1 + (prod.impuesto.tasa / 100)))
      } else {
        pre = precio;
      }
       //var RED3 =this.RedondeoValorCantRed(pre,2)
       //var RED3 =this.RedondeoValorCantRed(pre,2)
       var RED3 =this.RedondeoValor(pre)
       return RED3;
     //return pre;
    },
    CalculateTax(element) {
      //element.precio = this.RedondeoValor(element.precio / tasaMoneda);
      //esto es para la tributacion rst
      var BOOLREGTRIB= this.$store.getters.GetConfiguracionGeneral.configPv.regimenTributacionRst
       if(BOOLREGTRIB==false){ 
      if (this.Facturasventa.tipocomprobante != null && this.Facturasventa.tipocomprobante.itbis == false) {
        // element.precio = this.RemoveTax(element.productos, element.precio); element.precioOrig
         element.precio = this.RedondeoValorCantRed(this.RemoveTax(element.productos, element.precioDig),2);
        element.valor = element.precio * element.cantidad;
        return 0.00;
        }
      }
     
      
      if (this.ConfImpfiscal == true) {

      if (element.productos.incluirimpuestoenprecio == false && element.productos.impuesto != null) {
       // var pre = (element.precioDig / (1 + (element.productos.impuesto.tasa / 100)))
       var PRECPROD=(element.cantidad *((element.precioDig - (element.DescValor / element.cantidad))*(element.productos.impuesto.tasa / 100)))
        return PRECPROD;
       }

        
     if (element.productos.incluirimpuestoenprecio == true && element.productos.impuesto != null) {
        return element.cantidad * this.RedondeoValor((element.precio - (element.DescValor / element.cantidad)) - ((element.precio - (element.DescValor / element.cantidad)) / (1 + (element.productos.impuesto.tasa / 100))))
      }
      } else {
        // var precioVar = this.RemoveTax(element.productos, element.precio) element.precioOrig
        var precioVar = this.RemoveTax(element.productos, element.precioDig)
        // console.log(`Ite==> ${element.cantidad} * ((${precioVar} - (${element.DescValor} / ${element.cantidad})) * (${element.productos.impuesto.tasa} / 100))`)
        // return element.cantidad * this.RedondeoValor(this.RedondeoValorCantRed(((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100)),2))
    /*   console.log('--precioVar--')
       console.log(element.precioDig-precioVar)
      //console.log( )
       //console.log( element.cantidad * (((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100))))   
       console.log(`${element.cantidad} * (((${precioVar} - (${element.DescValor} / ${element.cantidad})) * (${element.productos.impuesto.tasa} / 100)))`)*/
      
      if (element.productos.incluirimpuestoenprecio == true && element.productos.impuesto != null) {
     //   return element.cantidad * this.RedondeoValorCantRed(((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100)),2)
     return this.RedondeoValor(element.cantidad * this.RedondeoValorCantRed(((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100)),2))
     //return element.cantidad * this.RedondeoValorCantRed((element.precioDig-(precioVar)),2)
      }

      if (element.productos.incluirimpuestoenprecio == false && element.productos.impuesto != null) {
        var pre = (element.precioDig / (1 + (element.productos.impuesto.tasa / 100)))
        //return element.cantidad * this.RedondeoValorCantRed(((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100)),2)
        return this.RedondeoValorCantRed(element.cantidad * ((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100)),2)
     
     
      }
      
      //return element.cantidad * this.RedondeoValorCantRed((element.precioDig-precioVar),2)
       //Original: 26-03-2024: return element.cantidad * this.RedondeoValorCantRed(((precioVar - (element.DescValor / element.cantidad)) * (element.productos.impuesto.tasa / 100)),2)
      }

    },
    // Crear un objeto con las propiedades del objeto y calcula 
    //los totales de los producto a comprar.
    async CalcularTotalesProductosCompra() {
      var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
        var MONEDAFACTURATASA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa)


      this.Totales.totalGeneral = {
        subtotal: 0,
        itbis: 0,
        total: 0,
        descuento: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
      };

      //if (this.CoberturaSeguro.ars != null) { }
      if (this.RProductoCompra.length > 0) {
        // if(this.RProductoCompraTemp==null){
        //   this.RProductoCompraTemp=JSON.parse(JSON.stringify(this.RProductoCompra))
        // }

        var RObjProductoCompra = JSON.parse(
          JSON.stringify(this.RProductoCompraTemp)
        );
        //cambio por cambio de moneda(01-03-2024)
        //var tasaMoneda = JSON.parse(JSON.stringify(this.Monedafactura.tasa));
        var tasaMoneda=this.$store.getters.GetConfiguracionGeneral.config.monedabase.tasa
        //
        var Ccobertura = 0
        // RObjProductoCompra.forEach((element) => {});
       // (async () => {
        await Promise.all(RObjProductoCompra.map(async (element) => { 

          if (!element.hasOwnProperty("paramOfert") || element.paramOfert == false) {
           /* element.precio = this.RedondeoValor(element.precio / tasaMoneda);
            element.valor = this.RedondeoValor(element.precio * element.cantidad);*/
            //precioOrig
             element.precio = this.RedondeoValor(element.precioDig / tasaMoneda);
            element.valor = this.RedondeoValor(element.precio * element.cantidad);
          
            if (this.ConfImpfiscal == false) {
              if (element.productos.incluirimpuestoenprecio == true) {
                if (element.productos.impuesto != null) {
                  // var precioVar = this.RemoveTax(element.productos, element.precio)
                  
                   var precioVar = this.RemoveTax(element.productos, element.precioDig)
                  this.Totales.totalGeneral.subtotal += (element.cantidad * precioVar)
                   var VALOR_IMPUESTO= this.CalculateTax(element);
                  element.valorimpuesto= VALOR_IMPUESTO
                  this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                }

                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                this.Totales.totalGeneral.recargo += element.RecarValor
                Ccobertura += element.cobertura
              } else {
                this.Totales.totalGeneral.subtotal += this.RedondeoBasico(element.valor);
                if (element.productos.incluirimpuestoenprecio == true) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }

                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                this.Totales.totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
              }
            } else {



              if (element.productos.incluirimpuestoenprecio == true) {
                if (element.productos.impuesto != null) {
                  // var precioVar = element.precio    element.precioOrig
                   var precioVar =  JSON.parse(JSON.stringify(element.precioDig))
                  this.Totales.totalGeneral.subtotal += this.RedondeoBasico((element.cantidad * precioVar))

                  var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                  this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                }

                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                this.Totales.totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
                Ccobertura += element.cobertura
              } else {

                this.Totales.totalGeneral.subtotal += this.RedondeoBasico(element.valor);
                if (element.productos.incluirimpuestoenprecio == true) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }
                if (element.productos.incluirimpuestoenprecio == false) {
                  if (element.productos.impuesto != null) {
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    this.Totales.totalGeneral.itbis += VALOR_IMPUESTO
                  }
                }

                this.Totales.totalGeneral.descuento += this.RedondeoBasico(element.DescValor*MONEDAFACTURATASA)
                this.Totales.totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
              }


            }
          }
        }));
     // })()//aquii

        this.RProductoCompra = JSON.parse(JSON.stringify(RObjProductoCompra));
        if (Ccobertura > 0) {
          this.Totales.totalGeneral.cobertura = this.RedondeoBasico(Ccobertura)
          this.CoberturaSeguro.cobertura = this.RedondeoBasico(Ccobertura)
        }

        // this.RProductoCompra.forEach(element => {
        // element.precio= this.RedondeoValor(element.precio)
        // element.valor= this.RedondeoValor(element.precio*element.cantidad)
        // this.Totales.totalGeneral.subtotal+=element.valor
        // //--calculando itbis al producto
        // if(element.productos.incluirimpuestoenprecio==true){
        // if(element.productos.impuesto!=null){
        // this.Totales.totalGeneral.itbis +=(element.cantidad*element.precio)-((element.cantidad*element.precio)/ (1+(element.productos.impuesto.tasa/100)))
        // }
        // }
        // if(element.productos.incluirimpuestoenprecio==false){
        // if(element.productos.impuesto!=null){
        // this.Totales.totalGeneral.itbis +=((element.cantidad*element.precio)*(element.productos.impuesto.tasa/100))
        // }

        // }

        // });
      }

      this.Descuentofactura.descuento = JSON.parse(JSON.stringify(this.Totales.totalGeneral.descuento))
      this.Descuentofactura.ValorPorc = true

      this.Recargofactura.recargo = JSON.parse(JSON.stringify(this.Totales.totalGeneral.recargo))
      this.Recargofactura.ValorPorc = true


      var _this = this;
      setTimeout(function () {
         _this.CalcularTotalesGeneral();
      }, 500);


    },

    CalcularTotalesGeneral() {
      var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
        var MONEDAFACTURATASA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa)

      // Monedafactura.tasa
      // Descuentofactura.descuento,
      // Retencionfactura.Retencion,
      // Recargofactura.recargo,
      // CoberturaSeguro.cobertura,
      // Totales.totalGeneral.total

      if (this.Descuentofactura.descuento > 0) {
        if (this.Descuentofactura.ValorPorc == true) {
          this.Totales.totalGeneral.descuento = ((this.Descuentofactura.descuento*MONEDAFACTURATASA) / this.Monedafactura.tasa);
        }
        if (this.Descuentofactura.ValorPorc == false) {
          this.Totales.totalGeneral.descuento = this.RedondeoBasico(
            (this.Descuentofactura.descuento *
              this.Totales.totalGeneral.subtotal) /
            100 /
            this.Monedafactura.tasa)
        }
      } else {
        this.Totales.totalGeneral.descuento = 0;
      }

      if (this.Retencionfactura.Retencion > 0) {
        if (this.Retencionfactura.ValorPorc == true) {
          this.Totales.totalGeneral.Retencion =
            this.Retencionfactura.Retencion / this.Monedafactura.tasa;
        }
        if (this.Retencionfactura.ValorPorc == false) {
          this.Totales.totalGeneral.Retencion = this.RedondeoBasico(
            (this.Retencionfactura.Retencion *
              this.Totales.totalGeneral.subtotal) /
            100 /
            this.Monedafactura.tasa)
        }
      } else {
        this.Totales.totalGeneral.Retencion = 0;
      }

      if (this.Recargofactura.recargo > 0) {
        if (this.Recargofactura.ValorPorc == true) {
          this.Totales.totalGeneral.recargo = this.RedondeoBasico(
            this.Recargofactura.recargo / this.Monedafactura.tasa)
        }
        if (this.Recargofactura.ValorPorc == false) {
          this.Totales.totalGeneral.recargo =
            (this.Recargofactura.recargo * this.Totales.totalGeneral.subtotal) /
            100 /
            this.Monedafactura.tasa;
        }
      } else {
        this.Totales.totalGeneral.recargo = 0;
      }

      if (this.CoberturaSeguro.cobertura > 0) {
        this.Totales.totalGeneral.cobertura = this.RedondeoBasico(
          this.CoberturaSeguro.cobertura / this.Monedafactura.tasa)
      } else {
        this.Totales.totalGeneral.cobertura = 0;
      }
      //this.Totales.totalGeneral.subtotal=(this.Totales.totalGeneral.subtotal/this.Monedafactura.tasa)

      //this.Totales.totalGeneral.total=((this.Totales.totalGeneral.subtotal+ this.Totales.totalGeneral.recargo)-(this.Totales.totalGeneral.descuento + this.Totales.totalGeneral.Retencion +this.Totales.totalGeneral.cobertura))

      // console.log("--- Viendo IncluirItbisGeneral  ---")
      // console.log(this.IncluirItbisGeneral)
      // console.log(this.Totales.totalGeneral.subtotal + this.Totales.totalGeneral.itbis)
      //this.Totales.totalGeneral.total=this.Totales.totalGeneral.subtotal + this.Totales.totalGeneral.itbis


      if (this.ConfImpfiscal == true) {
        if (this.IncluirItbisGeneral == false) {

     /* this.IncluirItbisGeneral =this.$store.getters.GetConfiguracionGeneral.configPv.impuestoincluido;
    this.ConfImpfiscal = this.$store.getters.GetConfiguracionGeneral.config.impresoraFiscal*/

    //this.Totales.totalGeneral.itbis +
          this.Totales.totalGeneral.total = this.RedondeoBasico(
            this.Totales.totalGeneral.subtotal +
            this.Totales.totalGeneral.recargo - (this.Totales.totalGeneral.descuento +this.Totales.totalGeneral.Retencion +this.Totales.totalGeneral.cobertura));
          //this.Totales.totalGeneral.total= this.Totales.totalGeneral.subtotal + this.Totales.totalGeneral.itbis
        }

        if (this.IncluirItbisGeneral == true) {
          this.Totales.totalGeneral.total = this.RedondeoBasico(
            this.Totales.totalGeneral.subtotal +
            this.Totales.totalGeneral.recargo -
            (this.Totales.totalGeneral.descuento +
              this.Totales.totalGeneral.Retencion +
              this.Totales.totalGeneral.cobertura))
          //this.Totales.totalGeneral.total= this.Totales.totalGeneral.subtotal
        }

      } else {

        this.Totales.totalGeneral.total = this.RedondeoBasico(
          this.Totales.totalGeneral.subtotal +
          this.Totales.totalGeneral.itbis +
          this.Totales.totalGeneral.recargo -
          (this.Totales.totalGeneral.descuento +
            this.Totales.totalGeneral.Retencion +
            this.Totales.totalGeneral.cobertura))

      }

      // if(this.Facturasventa.tipocomprobante!=null && this.Facturasventa.tipocomprobante.itbis==false){
      //   this.Totales.totalGeneral.itbis=0
      //   this.Totales.totalGeneral.total =  (this.Totales.totalGeneral.subtotal + this.Totales.totalGeneral.recargo) -(this.Totales.totalGeneral.descuento + this.Totales.totalGeneral.Retencion +this.Totales.totalGeneral.cobertura);
      // }

      //   this.Totales.totalGeneral={
      //   subtotal:0,
      //   itbis:0,
      //   total:0,
      //   descuento:0,
      //   Retencion:0,
      //   recargo:0,
      //   cobertura:0
      // }

      /* var ObjProRow = this.RProductoCompraTemp.filter(
         (ee) => ee.paramOfert == false);
 
       console.log('----ElRealFiltre---')
       console.log(ObjProRow)*/

       /*por aqui régimen de tributación RST*/
       var TIPOCOMPROBANTE=null
if (this.Facturasventa.tipocomprobante == null || this.Facturasventa.tipocomprobante != null &&
      !this.Facturasventa.tipocomprobante.hasOwnProperty("codigo")) {
        TIPOCOMPROBANTE =this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion;
      }else{
        TIPOCOMPROBANTE=this.Facturasventa.tipocomprobante
      }

      var _this = this;
      setTimeout(function () {
       var BOOLREGTRIB= _this.$store.getters.GetConfiguracionGeneral.configPv.regimenTributacionRst
        if(BOOLREGTRIB==true && TIPOCOMPROBANTE.codigo==1){
        /*por aqui régimen de tributación RST*/
        _this.Totales.totalGeneral.subtotal= _this.RedondeoBasico(_this.Totales.totalGeneral.subtotal+_this.Totales.totalGeneral.itbis)
        _this.Totales.totalGeneral.itbis=0
        }
       
        _this.calcularDevuelta();
      }, 500);


    },

    CalcularValorProductoRPCompra(element) {
      var subtotalvar = 0
      var itbisvar = 0
      var descuentovar = 0
      var recargovar = 0
      var coberturavar = 0
      element.RecarValor=(element.hasOwnProperty("RecarValor")&& element.RecarValor!=null?element.RecarValor:0)
      element.valorRecargo=(element.hasOwnProperty("valorRecargo")&& element.valorRecargo!=null?element.valorRecargo:0)
      if (!element.hasOwnProperty("paramOfert") || element.paramOfert == false) {
      if(this.ConfImpfiscal==null){ this.ConfImpfiscal=false }
      if (this.ConfImpfiscal == false) {
          if (element.productos.incluirimpuestoenprecio == true) {
            if (element.productos.impuesto != null) {
              // var precioVar = this.RedondeoValorCantRed(this.RemoveTax(element.productos, element.precio),3) element.precioOrig
              //var precioVar = this.RedondeoValorCantRed(this.RemoveTax(element.productos, element.precioDig),3)
              var precioVar = this.RemoveTax(element.productos, element.precioDig)
              subtotalvar = (element.cantidad * precioVar)
              itbisvar = this.CalculateTax(element);
            }

            if (element.productos.incluirimpuestoenprecio == false) {
              if (element.productos.impuesto != null) {
                itbisvar = this.CalculateTax(element);
              }
            }
            descuentovar = this.RedondeoBasico(element.DescValor)
            recargovar = (element.hasOwnProperty("RecarValor")&& element.RecarValor!=null?element.RecarValor:0)
            coberturavar = element.cobertura
          } else {
            subtotalvar = this.RedondeoBasico(element.valor);
            if (element.productos.incluirimpuestoenprecio == true) {
              if (element.productos.impuesto != null) {
                itbisvar = this.CalculateTax(element);
              }
            }
            if (element.productos.incluirimpuestoenprecio == false) {
              if (element.productos.impuesto != null) {
                itbisvar = this.CalculateTax(element);
              }
            }

            descuentovar += this.RedondeoBasico(element.DescValor)
            recargovar += this.RedondeoBasico(element.RecarValor)
          }
        } else {
          
          
          if (element.productos.incluirimpuestoenprecio == true) {
            if (element.productos.impuesto != null) {
              var precioVar = element.precioDig
              subtotalvar += (element.cantidad * precioVar)
              itbisvar = this.CalculateTax(element)
            }

            if (element.productos.incluirimpuestoenprecio == false) {
              if (element.productos.impuesto != null) {
                itbisvar = this.CalculateTax(element);
              }
            }
            descuentovar += this.RedondeoBasico(element.DescValor)
            recargovar += this.RedondeoBasico(element.RecarValor)

          } else {
            subtotalvar = this.RedondeoBasico(element.valor);
           // this.Totales.totalGeneral.subtotal += element.valor;
            if (element.productos.incluirimpuestoenprecio == true) {
              if (element.productos.impuesto != null) {
                itbisvar = this.CalculateTax(element);
              }
            }
            if (element.productos.incluirimpuestoenprecio == false) {
              if (element.productos.impuesto != null) {
                itbisvar = this.CalculateTax(element);
              }
            }

            descuentovar += this.RedondeoBasico(element.DescValor)
            recargovar += this.RedondeoBasico(element.RecarValor)
          }


        }

      }
      //console.log('ObjBa==>',element)
      //console.log(`((((${subtotalvar} + ${recargovar}) - ${descuentovar}) + ${itbisvar}) - ${coberturavar})`)
      var TotalVar = this.RedondeoValorCantRed(((((subtotalvar + recargovar) - descuentovar) + itbisvar) - coberturavar),2)
      // this.currencyFormatter2(((element.valor + element.RecarValor) -element.DescValor) - element.cobertura)

     /* console.log(`Formula==> ((((${subtotalvar} + ${recargovar}) - ${descuentovar}) + ${itbisvar}) - ${coberturavar})  `)
      console.log('--Viendo Redond--')
      console.log(this.currencyFormatter2(this.RedondeoValorCantRed(TotalVar,2)))*/
      return this.getTasaMonedaSelect(TotalVar) 

    },



    // Crear un nuevo objeto y asignarle valores a unidadesProductos.
    NewCrearProducto() {
      this.unidadesProductos = {
        codigobarra: "",
        precio: 0.0,
        costo: 0.0,
        porbeneficio: 0.0,
        factor: 1,
        unidades: null,
        productos: {
          borrado: false,
          activo: false,
          codigo: 0,
          descripcion: "",
          puntoVenta: true,
          impuesto: null,
        },
        unidadminima: true,
        unidadcompra: true,
        borrado: false,
      };

      this.ejecucion = true;
      this.loading(false)
    },
    openCrearPoducto() {
      this.dialogCrearProduct = true;
    },
    // Envío de una solicitud POST al servidor.
    SalvarCrearProducto() {
      if (this.$refs.formCrearProduct.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
          this.loading(true)
          this.unidadesProductos.unidades =
            this.configuraciones.configInv.unidadDefault;
          this.unidadesProductos.tasa =
            this.configuraciones.config.monedabase.tasa;
          this.$axios
            .post(
              this.$hostname + this.$hName + "/unidades-productos/save/",
              this.unidadesProductos,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.alerta("Dato guardado correctamente!", "bien2");
              this.NewCrearProducto();
              this.dialogCrearProduct = false;
            })
            .catch((error) => {
              this.loading(false)
              this.alerta("Dato no guardado correctamente", "error")
            }
            );
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },
    // Cerrar el cuadro de diálogo y restablecer el formulario.
    cancelarCrearProducto() {
      this.dialogCrearProduct = false;
      this.NewCrearProducto();
    },

    SelctCbVendedorProducto() { },
    // Llamar a una API y obtener los datos de vendedor.
    cargarVendedorProducto() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccCambiarVendedorProd')
        return;
      }

      this.cargarVendedorFactura()
      /*this.$axios
        .get(this.$hostname + this.$hName + "/vendedores/findbydesc/25/a", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RVendedor = res.data));*/
    },
    // Hacer una llamada axios al servidor y devolver los datos .
    CbFilVendedorProducto(e) {
      if (e.target.value.length >= 2) {
        this.$axios
          .post(
            this.$hostname +
            this.$hName +
            "/vendedores/findbydesc/25/" +
            e.target.value,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RVendedor = res.data));
      }
    },
    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RImpuesto.
    cargarImpuestoProducto() {
      this.$axios
        .get(this.$hostname + this.$hName + "/impuestos/findallActivos", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RImpuesto = res.data));
    },
    // Comprobando si el valor de la selección es nulo o indefinido. Si es nulo o indefinido, mostrará
    // una alerta y establecerá el valor de la selección en una cadena vacía.
    SelctCbImpuestoProducto() {
      if (this.unidadesProductos.productos.impuesto == null) {
        this.unidadesProductos.productos.impuesto = "";
      }
      if (this.unidadesProductos.productos.impuesto.codigo == undefined) {
        this.alerta("Seleccione un impuesto", "error");
        this.unidadesProductos.productos.impuesto = "";
        return;
      }
    },
    OpenDialogCrearEditarCliente() {
      this.NewclienteSeleccionado();
      this.dialogCrearEditarCliente = true;
      if (this.clienteFactura != null) {
        this.busquedaCliente = JSON.parse(JSON.stringify(this.clienteFactura));
        this.clienteSeleccionado = JSON.parse(
          JSON.stringify(this.clienteFactura)
        );
        if (this.clienteFactura.telefonosClientesList.length > 0) {
          this.clienteSeleccionado.telefono = this.clienteFactura.telefonosClientesList[0].telefonosClientesPK.telefono
        }
        if (this.clienteFactura.cedrnc == null) {
          this.clienteFactura.cedrnc = '';
        }
        this.CargarHistorialCliente()
      }
      if (this.clienteFactura == null) {
        var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldBuscaCliente.focus();
        }, 500);
      }
    },
    // Restableciendo el formulario de cliente .
    NewclienteSeleccionado() {
      this.busquedaCliente = null
      this.clienteSeleccionado = {
        codigo: 0,
        nombre: null,
        cedrnc: "",
        ciudad: null,
        direccion: null,
        telefono: "",
        sector: "",
        tipocomprobante: null,
        email: "",
        sexo: "",
        dondetrabaja: "",
        diascredito: 0,
        fechanacimiento: null,
        carnet: "",
        ars: null,
        contrato: "",
        nss: "",
        planlan: 0,
        comentario:'',
        pasaporte:''
      };
      this.RHistorialCompracliente = [];

      this.SelectedFactPendiente = null;
      this.FactPendienteSeleccionado = null;
      this.RListFactpendiente = [];
      this.ObtemRListFactpendiente = [];

      this.HeadInfFactPendienteSelecc = {
        fecha: fechaNueva(),
        factura: "",
        valorf: 0,
        pendiente: 0,
        ncf: "",
        referencia: "",
      };

      this.ReciboIngreso.abono = 0
      this.ReciboIngreso.descuento = 0
      this.ReciboIngreso.DescValorPorc = false
      this.devueltaRecIngreso = 0
      this.Totales.ReciboIngreso = {
        pendiente: 0,
        abono: 0,
        descuento: 0,
        retencion: 0,
        isr: 0
      }

      this.NewHistorialClientRecIng()


    },
    // Cerrando el cuadro de diálogo del cliente.
    CancelarCrearEditarCliente() {
      this.dialogCrearEditarCliente = false,
        this.NewclienteSeleccionado()
      this.OpenEjecReciboIngreso = false;
      this.OpenEjecCoberturaSeguro = false;
      this.OpenEjecFacturaDomicilio = false;
      this.OpenEjecDatosPagareFactura = false;
      this.OpenEjecProdControlado = false;
      this.OpenEjecFarmacoVigilancia=false
    },

    // Modifica un objecto cliente para enviarlo a guardar.
    SalvarCrearEditarCliente() {

      if (this.$refs.formCrearEditarCliente.validate()) {
        if(this.cedularequired==true){
        if(this.clienteSeleccionado.cedrnc==null || this.clienteSeleccionado.cedrnc!=null && this.clienteSeleccionado.cedrnc.trim().length==0){
        this.$refs.formCrearEditarCliente.validate();
        this.alerta(`el campo "cedula/RNC" es requeridos`, "error");
        return
        }
      }

      if(this.clienteSeleccionado.cedrnc!=null && this.clienteSeleccionado.cedrnc.length>0){ 
          var CEDULA = this.clienteSeleccionado.cedrnc.replace("-", "");
          CEDULA = CEDULA.replace(/-/g,'')
        if(this.validaCedRnc(CEDULA)==false){
      var NOM= CEDULA.length>10?"CEDULA":"RNC"
      this.alerta(`${NOM} Invalida `, "error");
        return;
        }
        }


        if (this.EjecutarPermisoPv == false) {
          this.funcionValidaPermisoPv('AccCrearEditarCliente')
          return;
        }
        if (this.ejecucion == true) {
          this.ejecucion = false;
          this.loading(true)
          if (this.clienteSeleccionado.hasOwnProperty("telefonosClientesList") && this.clienteSeleccionado.telefonosClientesList.length > 0) {
     
            if(this.clienteSeleccionado.telefono!=null && this.clienteSeleccionado.telefono.length>3 ){
              this.clienteSeleccionado.telefonosClientesList[0].telefonosClientesPK.telefono = this.clienteSeleccionado.telefono
            }
            

          } else {
            this.clienteSeleccionado.telefonosClientesList = []
            var obj = { telefonosClientesPK: { telefono: this.clienteSeleccionado.telefono, cliente: this.clienteSeleccionado.codigo }, borrado: false }
            this.clienteSeleccionado.telefonosClientesList.push(obj)
          }

          this.$axios
            .post(
              this.$hostname + this.$hName + "/clientes/save/",
              this.clienteSeleccionado,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              var CLIENTE_SELECT=JSON.parse(JSON.stringify(res.data))
              CLIENTE_SELECT.codnom=`${res.data.codigo} - ${res.data.nombre.trim()}`
             this.busquedaCliente=JSON.parse(JSON.stringify(CLIENTE_SELECT))
              this.getChangeClienteBusqueda(CLIENTE_SELECT)
              if(this.clienteFactura!=null && this.clienteFactura.codigo==CLIENTE_SELECT.codigo){
                this.clienteFactura=CLIENTE_SELECT
              }

                this.alerta("cliente guardado corretamente", "bien2");
            })
            .catch(error => {
              this.ejecucion = true;
              this.loading(false)
            }
            );
          //.then((res) => (this.RCliente = res.data));
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },
    // Llamar a una API y obtener los datos del cliente.
    cargarClienteBusqueda() {
      var param=""
      if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo') ){ 
        var sem=["d", "l", "ma", "mi", "j", "v", "s"];
        var fecha= new Date();
        var semana=sem[fecha.getUTCDay()]
        var param="/"+this.Facturasventa.vendedor.codigo+"/"+semana
      }

      this.$axios
        .get(this.$hostname + this.$hName + "/clientes/findtodo/a/" + this.filtroTipoCliente + "/25/0"+param, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCliente = res.data));
    },
    // Hacer una llamada API al servidor y devolver los datos al cliente.
    CbFilcliente(e) {
      if (e.target.value.length >= 0) {

        var param=""
      if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){ 
        var sem=["d", "l", "ma", "mi", "j", "v", "s"];
        var fecha= new Date();
        var semana=sem[fecha.getUTCDay()]
        var param="/"+this.Facturasventa.vendedor.codigo+"/"+semana
      }

        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/clientes/findtodo/" +
            (e.target.value === null || e.target.value.length==0 ? 'A' : e.target.value)  +
            "/" + this.filtroTipoCliente + "/25/0"+param,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RCliente = res.data));
      }
    },
    // Un método que recibe un cliente y verifica en una lista si tiene telefono .
    selectTelefonoCliente(e) {
      if (typeof e === 'object' && e.hasOwnProperty('telefonosClientesList') && e.telefonosClientesList!=null && e.telefonosClientesList.length > 0) {
        return e.telefonosClientesList[0].telefonosClientesPK.telefono.trim()
      } else { return ' ' }
    },
    // Comprobando si el valor de la entrada es nulo o indefinido.
    SelctCbClienteBuscar() {
      var _this = this;
      setTimeout(function () {
        if (_this.busquedaCliente== null) {
          _this.busquedaCliente= "";
        }

        if (!_this.busquedaCliente.hasOwnProperty('codigo')) {
          _this.busquedaCliente= "";
          _this.busquedaCliente= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);


     /* console.log('Lalallal Pamoinjn')
      if (this.busquedaCliente == null) {
        this.busquedaCliente = "";
      }
      if (this.busquedaCliente.codigo == undefined) {
        this.busquedaCliente = "";
        this.alerta("Seleccione un cliente", "error");
        return;
      }*/
    },
    // Una función que se llama cuando un usuario selecciona un cliente de una lista desplegable.
    getChangeClienteBusqueda(e) {
      if ( typeof e === 'object' && e != null) {

       /* if (e.statuscliente != null) {
          if (e.statuscliente == 2 || e.statuscliente == 3) {
            this.busquedaCliente = null
            this.alerta("Este cliente esta suspendido o cancelado, no puede Seleccinarlo", "error");
            return
          }
        }*/

        /* if(e.statuscliente!=null){
           if(e.statuscliente==2 || e.statuscliente==3){
             this.alerta("Este cliente esta suspendido o cancelado, no puede Seleccinarlo", "error");
             this.busquedaCliente=null
             this.clienteSeleccionado =null
             return
           }
         }*/

        var obj = JSON.parse(JSON.stringify(e))
        obj.telefono = this.selectTelefonoCliente(e)
        if(e.fechanacimiento!=null){
          var fecnac=this.getParamFecha(e.fechanacimiento)
          obj.fechanacimiento=fecnac.split("T")[0]
        }

        if (e.cedrnc != null) { obj.cedrnc = e.cedrnc.trim() } else { obj.cedrnc = '' }
        this.clienteSeleccionado = obj;

        //statuscliente--Suspendido:2
        //statuscliente--statuscliente:2
        //statuscliente:--Cancelado:3

        this.TbTotalItemHisCli = 0
        this.TbPaginatorHisCli = {
          page: 1,
          itemsPerPage: 10,
          pageStart: 1,
          pageStop: 15,
          pageCount: 10,
          itemsLength: 5,
        }

        var _this = this;
        setTimeout(function () {
          _this.CargarHistorialCliente(e)
        }, 500);

      }
    },

 // Una función que que validad si el cliente seleccionado es a credito y desabilita el nombre y la direccion .
validarDisabledClienteCredito(e) {
  var MODIF_NOMB=this.$store.getters.GetConfiguracionGeneral.configPv.modificarNombreClienteCredito
  var BOOLSTADO=false
        if(MODIF_NOMB!=null && MODIF_NOMB==false){
          if ( typeof e === 'object' && e != null) {
          if(e.hasOwnProperty('diascredito') && e.diascredito!=null && e.diascredito>0){
            BOOLSTADO=true
          }
          }
        }

          return BOOLSTADO;
      
          
    },




    cargarPaginacionAllHisCli(e) {
      this.TbPaginatorHisCli = JSON.parse(JSON.stringify(e));
      this.CargarHistorialCliente()
    },

    // Llamar a una API y obtener los datos factura historial del cliente..
    CargarHistorialCliente() {
      this.RHistorialCompracliente = []
      if (this.clienteSeleccionado == null || this.clienteSeleccionado.codigo == 0) {
        return
      }

      //    /FindHistorialCliente/{limit}/{ultimo}/{cliente}
      var obj =
        this.TbPaginatorHisCli.itemsPerPage +
        "/" +
        this.TbPaginatorHisCli.pageStart +
        "/" +
        this.clienteSeleccionado.codigo;

      this.$axios
        .get(
          this.$hostname + this.$hName + "/facturasventas/FindHistorialCliente/" + obj,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.RHistorialCompracliente = res.data.ListHistCliente),
            (this.TbTotalItemHisCli = res.data.TbTotalItem);
        });


    },



    // Llamar a una API y obtener los datos historial del cliente..
    historialClieList() {
      if (this.clienteFactura == null) {
        return 0;
      }

      this.listHiscliente = []
      var q2 = "";
      var tasa = 1.00;

      if (this.clienteFactura != null) {
        q2 = ` and clientecod = ${this.clienteFactura.codigo} `;
      }
      if (this.Monedafactura != null) {
        tasa = this.Monedafactura.tasa
      }



      var f1 = '1900-01-01'
      var f2 = this.fechaNueva()

      // var Obj={}

      var link = `/ConsultaRapida/historialClieList/${this.clienteFactura.codigo}/${f1}/${f2}/${q2}/${tasa}`
      this.$axios
        .get(this.$hostname + this.$hName + link, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.CalcularBalance(res.data)
        })
        .catch(error => { this.alerta("Reporte no Impreso(" + error + ")", "error") });

    },

    // Cálculo del saldo de un cliente.
    async CalcularBalance(e) {

      this.totales = {
        BalanceTotal: 0,
        credito: 0,
        debito: 0,
      }


      var obj = JSON.parse(JSON.stringify(e.list))

      // await obj.forEach(elent => {});
      await Promise.all(obj.map(async (elent) => { 


        if (!elent.descripcion.includes("Contado")) {
          this.totales.BalanceTotal += (elent.debito - elent.credito)
        }
        elent.balance = this.totales.BalanceTotal
        this.totales.debito += elent.debito
        this.totales.credito += elent.credito
      }));

      this.listHiscliente = JSON.parse(JSON.stringify(obj))

    },



    async ActualizarListaPrecioCambioCliente(){
      var LISTAPRODUCTO=JSON.parse(JSON.stringify(this.RProductoCompra));
      this.RProductoCompra=[]
      this.RProductoCompraTemp=[]
      this.RProductoCompraTemp=[]
       //RProductoCompra:Array[1]
      //RProductoCompraTemp:Array[1]
     //RProductoCompraOfercta:Array[0]
     var NEWLISTAPRODUCTO=[]
     var bar = new Promise((resolve, reject) => {

      //LISTAPRODUCTO.forEach(async (element) => {  });
      (async () => { 
      await Promise.all(LISTAPRODUCTO.map(async (element) => { 
        var OBJ=JSON.parse(JSON.stringify(element));
        var CODPRO=OBJ.unidadesProductosPK.producto
        var CODUNI=OBJ.unidadesProductosPK.unidad
        var CODLISPRE=this.listaPrecioDefecto.id
        
  if(this.clienteFactura!=null && this.clienteFactura.listaPrecio!=null && this.clienteFactura.listaPrecio.activo==true){
    CODLISPRE=this.clienteFactura.listaPrecio.id
  }
        const result =  await this.findListaprecioUnidaProducto(CODPRO,CODUNI,CODLISPRE);
        // const resUniPro =  await this.findUnidaProducto(CODPRO,CODUNI);
        if(result!=null){
          OBJ.precio=result.precio
          OBJ.precioDig=result.precio
          OBJ.precioOrig=element.precioOrig
          OBJ.omitirRegla = true
        }else{
          OBJ.precio=element.precioOrig
          OBJ.precioDig=element.precioOrig
          OBJ.precioOrig=element.precioOrig
          OBJ.omitirRegla = true
        }
         NEWLISTAPRODUCTO.push(OBJ)
        var _this=this;
        setTimeout(function(){ resolve() }, 600);

      }));

    })()
          
});

bar.then(() => {
// console.log(NEWLISTAPRODUCTO)
  NEWLISTAPRODUCTO.sort(function (a, b) {
  if (a.indx > b.indx) {return 1;}
  if (a.indx < b.indx) {return -1;
  }
  return 0;
});



  // NEWLISTAPRODUCTO.forEach(element => {});

    //this.BuscPropiedadProductoSelct(element, 'add')
    (async () => {  
await Promise.all(NEWLISTAPRODUCTO.map(async (element) => { 

 
  element.RecarValor=(element.hasOwnProperty("RecarValor")&& element.RecarValor!=null?element.RecarValor:0)
          element.valorRecargo=(element.hasOwnProperty("valorRecargo")&& element.valorRecargo!=null?element.valorRecargo:0)
          //this.BuscPropiedadProductoSelct(element, 'add')
          this.RProductoCompra.unshift(JSON.parse(JSON.stringify(element)));
          this.RProductoCompraTemp.unshift(JSON.parse(JSON.stringify(element)));


       }));

       var _this = this
setTimeout(function () {
          _this.LimpiaInfProducto();
        }, 400);
})()

  // this.BuscPropiedadProductoSelct(OBJ, 'add')
  console.log('All done ActualizarListaPrecioCambioCliente!');
   //console.log(NEWLISTAPRODUCTO);
});

     

    },
    // Una función que se llama cuando un usuario selecciona un cliente de una lista de clientes.
    async SeleccionarClienteFactura() {
      if (this.busquedaCliente == null) {
        this.alerta(
          "Seleccione un cliente para aplicarlo a la factura",
          "error"
        );
        this.$refs.RefTextFieldBuscaCliente.focus();
        return;
      }

      if (!this.$refs.formBuscarCliente.validate()) {
        this.alerta("Seleccione un cliente para aplicarlo a la factura", "error");
        this.$refs.RefTextFieldBuscaCliente.focus();
        return;
      }
      if ( typeof this.busquedaCliente === 'object' && this.busquedaCliente != null) {

        if(this.busquedaCliente.cedrnc!=null && this.busquedaCliente.cedrnc.length>0){ 
          var CEDULA = this.busquedaCliente.cedrnc.replace("-", "");
          CEDULA = CEDULA.replace(/-/g,'')
        if(this.validaCedRnc(CEDULA)==false){
          var NOM= CEDULA.length>10?"CEDULA":"RNC"
      this.alerta(`${NOM} Invalida `, "error");
        return;
        }
        }
        this.clienteFactura = JSON.parse(JSON.stringify(this.busquedaCliente));
        this.dialogCrearEditarCliente = false;

        if(this.activarListasPrecios==true && this.RProductoCompra.length>0){ 
          const result =  await this.ActualizarListaPrecioCambioCliente();
        }

        this.NewclienteSeleccionado();
        var EJECUTAR_BLUR_PRODUCTO=true
        if (this.OpenEjecReciboIngreso == true) {
          this.OpenEjecReciboIngreso = false;
          EJECUTAR_BLUR_PRODUCTO=false
          this.OpenDialogReciboIngreso();
        }
        if (this.OpenEjecCoberturaSeguro == true) {
          this.OpenEjecCoberturaSeguro = false;
          EJECUTAR_BLUR_PRODUCTO=false
          this.OpenCoberturaSeguro();
        }
        if (this.OpenEjecFacturaDomicilio == true) {
          this.OpenEjecFacturaDomicilio = false;
          EJECUTAR_BLUR_PRODUCTO=false
          this.OpenFacturaDomicilio();
        }
        if (this.OpenEjecDatosPagareFactura == true) {
          this.OpenEjecDatosPagareFactura = false;
          EJECUTAR_BLUR_PRODUCTO=false
          this.OpenDialogDatosPagareFactura();
        }
 
        if (this.OpenEjecProdControlado == true) {
          this.OpenEjecProdControlado = false;
          EJECUTAR_BLUR_PRODUCTO=false
          this.OpenDialogProdControlado();
        }

      if (this.OpenEjecFarmacoVigilancia == true) {
          this.OpenEjecFarmacoVigilancia = false;
          EJECUTAR_BLUR_PRODUCTO=false
          this.openFarmacoVigilancia();
        }

        

        /*this.$axios
        .get(this.$hostname + this.$hName + "/vendedores/findbydesc/25/a", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RVendedor = res.data));*/

        this.CompletaDatoClienteSelectFactura()

        if(EJECUTAR_BLUR_PRODUCTO==true){
          setTimeout(function () {
        _this.$refs.RefTextFieldProducto.focus();
            }, 500);
        }
        

        if(this.activarListasPrecios==false){
          var _this = this;
        setTimeout(function () {
           _this.CalcularTotalesProductosCompra();
        }, 800);
        }
        

        /*this.ComprobanteFisc={
              comprobante:JSON.parse(JSON.stringify(this.Facturasventa.tipocomprobante)),
              nombre:JSON.parse(JSON.stringify(this.Facturasventa.nombreDgii)),
              telefono:JSON.parse(JSON.stringify(this.Facturasventa.telefononcf)),
              direccion:JSON.parse(JSON.stringify(this.Facturasventa.direccionncf)),
              cedrnc:JSON.parse(JSON.stringify(this.Facturasventa.cedularncncf))
            },*/



        // this.clienteFactura = JSON.parse(JSON.stringify(this.busquedaCliente));
        // this.Facturasventa.vendedor
      }
    },


    async LimpiaInfProducto_DatoClienteSelectRestFact(e) {
       // _this.Facturasventa.tipocomprobante = e.tipoComprobante
       //_this.Facturasventa.tipocomprobante = e.tipoComprobante
          /**/
          if(e.cliente!=null){
                      this.clienteFactura = JSON.parse(JSON.stringify(e.cliente))
                     this.clienteFactura.codnom = `${e.cliente.codigo} - ${e.cliente.nombre.trim()} `
                }else{
                  if(e.OBJ.descripcion!=null && e.OBJ.hasOwnProperty("descripcion")&& e.OBJ.descripcion.trim().length>0){
                    this.Facturasventa.descripcion=e.OBJ.descripcion
                  }
                }

              if(e.vendedor!=null){
                this.Facturasventa.vendedor = e.vendedor
              }

              if(e.tipoComp!=null){
                this.Facturasventa.tipocomprobante = e.tipoComp
              }else{
                this.Facturasventa.tipocomprobante = this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
              }


    this.PanelMenuOpcionCompleto = true;
      this.PanelSelectProducto = true;
      this.PanelInfProduct = false;
      this.searchProducto = "";
      this.ObjRectPro = null
      this.EsNuevoObjRectPro = true
      this.NewproductoSeleccionado();
      //MIC-2510: https://decasystem.atlassian.net/browse/MIC-2510
     // const result = await this.ReActualizarProductoCompra();
      const result = await this.CalcularTotalesProductosCompra();
      var _this = this;
      return new Promise(resolve => {
        setTimeout(() => {
          resolve('resolved');
        }, 999);
      });



/*if (this.clienteFactura.telefonosClientesList.length > 0) {
  this.Facturasventa.telefononcf = this.clienteFactura.telefonosClientesList[0].telefonosClientesPK.telefono
}
if (this.clienteFactura.cedrnc != null && this.clienteFactura.cedrnc.length > 0) {
  this.Facturasventa.cedularncncf = this.clienteFactura.cedrnc.trim()
}
this.Facturasventa.nombreDgii = this.clienteFactura.nombre
this.Facturasventa.direccionncf = this.clienteFactura.direccion*/

},





    // Obtener los datos del vendedor de la base de datos y completar el formulario.
    CompletaDatoClienteSelectFactura() {

      // vendedor
      if (this.clienteFactura.vendedor != null) {
        this.$axios
          .get(this.$hostname + this.$hName + "/vendedores/find/" + this.clienteFactura.vendedor, {
            headers: this.$store.getters.GetheadersAxios,
          })
          .then((res) => {
            this.Facturasventa.vendedor = res.data
          });

      }

      if (this.clienteFactura.tipocomprobante != null) {
        this.Facturasventa.tipocomprobante = this.clienteFactura.tipocomprobante
      } else {
        this.Facturasventa.tipocomprobante = this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
      }

      if (this.clienteFactura.telefonosClientesList.length > 0) {
        this.Facturasventa.telefononcf = this.clienteFactura.telefonosClientesList[0].telefonosClientesPK.telefono
      }

      if (this.clienteFactura.cedrnc != null && this.clienteFactura.cedrnc.length > 0) {
        this.Facturasventa.cedularncncf = this.clienteFactura.cedrnc.trim()
      }

      this.Facturasventa.nombreDgii = this.clienteFactura.nombre
      this.Facturasventa.direccionncf = this.clienteFactura.direccion

    },

    NotifMessage(exc) {

      var notif = {
        estado: true,
        nombre: "Este cliente posee operaciones, si quita el cliente se limpiara  la pantalla completa. \n ¿Seguro que desea quitar este cliente?",
        Exec: exc
      }
      this.notif = JSON.parse(JSON.stringify(notif))

    },

    getNombreClienteSelectGlobal(e){
      var DESC="";
    if(e!=null && e.hasOwnProperty("codigo")){

      var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true){
        DESC=`.ACUM. ${currencyFormatter(e.balancePuntos)}`
      }else{
        DESC=`${e.nombre.trim()}`
      } 
    }
      return DESC;
    },


    quitarClienteFactura() {

  var _this = this;
        setTimeout(function () {
          _this.clienteFactura = null;
          _this.busquedaCliente = null;
          _this.SelctCbClienteBuscar()
        }, 200);

      
      this.NewclienteSeleccionado();
      this.limpiarPuntoVentaCompleto()
      //quitarClienteFactura,clienteFactura.nombre,

      /*  var _this = this;
        setTimeout(function () {
          _this.CalcularTotalesProductosCompra();
        }, 200);*/
    },

    // Llamar a una API  y devolver los datos del comprobante.
    cargarTipoComprobante() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tiponcf/findallactivo", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RtipoComprobante = res.data.filter((ee) => ee.pv == true || ee.especial==true);
        });
    },

    getChangeTipoComprobante(e){

      //secuenciaComprobantes/existesecuencia/{documento}/{tipo}/{modulo}

      if ( typeof e === 'object' && e != null) { 
        var links=`${this.caja.sucursal.documentofacturacion}/${e.codigo}/PV`
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/"+links, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
            if(res.data==false){
              this.ComprobanteFisc.comprobante = null
              this.alerta("no posee secuencia este tipo de comprobante", "error");
            }
          
        });

      }
    },

    filterTipoComprobantePvItbis(List){
      //RtipoComprobante
      return List.filter((ee) => ee.pv==true && ee.itbis==true || ee.descripcion.trim().toLowerCase().includes('regimen especial') || ee.especial==true );
      //return List.filter((ee) => ee.pv==true && ee.itbis==true );
    },


     filterTipoComprobantePv(List){
      //RtipoComprobante
      return List.filter((ee) => ee.pv==true);
    },

  changeCbTipoComprobante(e,nombreFocu){
// cuando el codigo sea mayor a  y que pv sea true
if(e!=null&& e.hasOwnProperty('codigo')){
   var CODCOM=e.codigo
  //  if(CODCOM==2|| CODCOM==3 || CODCOM==4){

          if(CODCOM>1 && e.pv==true){
            this.cedularequired=true
            this.RedireccionFocus(nombreFocu)
          }else{
            this.cedularequired=false
          }
}

},



  
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoComprobante() {
     
      var _this = this;
      setTimeout(function () {
        if (_this.clienteSeleccionado.tipocomprobante == null) {
          _this.clienteSeleccionado.tipocomprobante = "";
        }

        if (!_this.clienteSeleccionado.tipocomprobante.hasOwnProperty('codigo')) {
          _this.alerta("Seleccione un Tipo Comprobante", "error");
          _this.clienteSeleccionado.tipocomprobante = "";
          _this.clienteSeleccionado.tipocomprobante = null;
          return;
        }
        var CODCOM=_this.clienteSeleccionado.tipocomprobante.codigo
        var BOOLPV=_this.clienteSeleccionado.tipocomprobante.pv
        // if(CODCOM==2|| CODCOM==3 || CODCOM==4){
          if(CODCOM>1 && BOOLPV==true){
            _this.cedularequired=true
          }else{
            _this.cedularequired=false
          }


      }, 300);

    },
      SelctCbTipoComprobanteTem() {
     
     var _this = this;
     setTimeout(function () {
       if (_this.clienteTempCredito.tipocomprobante == null) {
         _this.clienteTempCredito.tipocomprobante = "";
       }

       if (!_this.clienteTempCredito.tipocomprobante.hasOwnProperty('codigo')) {
         _this.alerta("Seleccione un Tipo Comprobante", "error");
         _this.clienteTempCredito.tipocomprobante = "";
         _this.clienteTempCredito.tipocomprobante = null;
         return;
       }
       var CODCOM=_this.clienteTempCredito.tipocomprobante.codigo
       var BOOLPV=_this.clienteTempCredito.tipocomprobante.pv
        // if(CODCOM==2|| CODCOM==3 || CODCOM==4){
          if(CODCOM>1 && BOOLPV==true){
           _this.cedularequired=true
         }else{
           _this.cedularequired=false
         }


     }, 300);

      



    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RArs.
    cargarArs() {
      this.$axios
        .get(this.$hostname + this.$hName + "/ars/findallactivos", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RArs = res.data));
    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbArs() {
      if (this.CoberturaSeguro.ars == null) {
        this.CoberturaSeguro.ars = "";
      }
      if (this.CoberturaSeguro.ars.codigo == undefined) {
        this.alerta("Seleccione una ars", "error");
        this.CoberturaSeguro.ars = "";
        return;
      }
    },





    SelctCbArsClient() {
      var _this = this;
      setTimeout(function () {
        if (_this.clienteSeleccionado.ars == null) {
          _this.clienteSeleccionado.ars = "";
        }

        if (!_this.clienteSeleccionado.ars.hasOwnProperty('codigo')) {
          _this.clienteSeleccionado.ars = "";
          _this.clienteSeleccionado.ars = null;
          return;
        }
      }, 300);
    },

    // Obtener una lista de objetos del servidor y luego insertarlos en una tabla los datos obtenidos.
    async getReimprecionlistFactCliente() {
      this.ListReimprecion = [];
      if (this.ReimpresionBusqueda.secuencia.length == 0) {
        this.ReimpresionBusqueda.secuencia = 0;
      }
      //var lccaja = JSON.parse(localStorage.caja);
      //lccaja.codigo
      //lccaja.sucursal.codigo

      var cliente = "null";
      var fecini = "null";
      var fecfin = "null";
      var sec = 0;
      var caja="";
      var param=""

      if (this.$refs.formReimprecion.validate()) {
        var url = "";
        if (this.BtnReimprecionvalue == 0) {
          return;
        }
        if (this.BtnReimprecionvalue == 1) {
          url = "recibos";
          if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){ 
          var param="/"+this.Facturasventa.vendedor.codigo
         }
        }
        if (this.BtnReimprecionvalue == 2) {
          url = "otrosingresos";
        }
        if (this.BtnReimprecionvalue == 3) {

         if(this.ReimpresionBusqueda.secuencia>0){
            url = "facturas";
          }else{
            url = "facturasCaja";
            caja="/"+this.caja.codigo
            if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true&& this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){ 
               var param="/"+this.Facturasventa.vendedor.codigo
             }
          }
          
        }
        if (this.BtnReimprecionvalue == 4) {
          url = "devolucion";
        }
        if (this.BtnReimprecionvalue == 5) {
          url = "valecajas";
        }
        if (this.BtnReimprecionvalue == 6) {
          url = "cotizacion";
          if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){ 
          var param="/"+this.Facturasventa.vendedor.codigo
           }
        }
        if (this.BtnReimprecionvalue == 7) {
          url = "conduce";
          if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){ 
           var param="/"+this.Facturasventa.vendedor.codigo
           }
        }
        if (this.ReimpresionBusqueda.cliente != null) {
          //cliente = this.ReimpresionBusqueda.cliente.nombre;
          // cliente = this.$refs.RefCombClienteReImprimir.internalSearch;
          // cliente = this.$refs.RefCombClienteReImprimir.internalValue
       if(this.$refs.RefCombClienteReImprimir.selectedItem.hasOwnProperty('codigo')){
        cliente = this.$refs.RefCombClienteReImprimir.selectedItem.nombre;
       }else{
        cliente = this.$refs.RefCombClienteReImprimir.selectedItem;
       }
          
        }
        if (this.ReimpresionBusqueda.fechaInicial != null) {
          fecini = this.ReimpresionBusqueda.fechaInicial;
        }
        if (this.ReimpresionBusqueda.fechaFinal != null) {
          fecfin = this.ReimpresionBusqueda.fechaFinal;
        }
        if (this.ReimpresionBusqueda.secuencia != null) {
          sec = this.ReimpresionBusqueda.secuencia;
        }

      
        var _this = this;
      setTimeout(function () {

        var LINKCOMPL=  _this.$hostname + _this.$hName +
            "/reimpresion/" + url + "/" + sec +
            "/" + fecini + "/" +fecfin +"/" +
            cliente +"/" +
            _this.ReimprecionPaginator.itemsPerPage +
            "/" + (_this.ReimprecionPaginator.pageStart)+caja+param;
 
            // _this.$hostname +
            // _this.$hName +
            // "/reimpresion/" +
            // url +
            // "/" +
            // sec +
            // "/" +
            // fecini +
            // "/" +
            // fecfin +
            // "/" +
            // cliente +
            // "/" +
            // _this.ReimprecionPaginator.itemsPerPage +
            // "/" +
            // (_this.ReimprecionPaginator.pageStart)
        _this.$axios
          .get(LINKCOMPL,
            { headers: _this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            _this.ListReimprecion = [];
            (_this.TotalRegistros = res.data.cantidad),
              (_this.listFactVent = res.data.listas);

            //  _this.listFactVent.forEach((e) => {});
            
//(async () => {
          //  await 
            Promise.all(_this.listFactVent.map(async (e) => { 
              var det = null;
              if (_this.BtnReimprecionvalue == 1) {
                det = {
                  fecha: e.fecha,
                  secuencia: e.recibosdeingresoPK.secuencia,
                  secdoc: e.documentocxc.nombrecorto.trim() + "-" + e.recibosdeingresoPK.secuencia,
                  nombre: e.cliente === null ? "" : e.cliente.nombre,
                  valor: e.valor,
                  ncf: "",
                  comentario: e.concepto,
                  turno: "",
                  pago: _this.ObtenerFormaDepago(e),
                  detalle: e.recibosingresoDetalleList,
                  recibos: e,
                  moneda:e.moneda,
                  tasa:e.tasa
                };
              }
              if (_this.BtnReimprecionvalue == 2) {
                det = {
                  fecha: e.fecha,
                  secuencia: e.secuencia2,
                  secdoc: e.documento.nombrecorto.trim() + "-" + e.secuencia2,
                  nombre: e.nombre.trim(),
                  valor: e.valor,
                  ncf: "",
                  comentario: e.concepto,
                  turno: "",
                  pago: _this.ObtenerFormaDepago(e),
                  detalle: e.facturasventaDetalleList,
                  recibo: e,
                  moneda:e.moneda,
                  tasa:e.tasa
                };
              }
              if (_this.BtnReimprecionvalue == 3) {
                var nomDes=e.descripcion === null ? "" : e.descripcion
                det = {
                  fecha: e.fecha,
                  secuencia: e.facturasventaPK.secuencia,
                  secdoc: e.documentoFactura.nombrecorto.trim() + "-" + e.facturasventaPK.secuencia,
                  nombre: e.cliente === null ? nomDes : e.cliente.nombre,
                  valor: e.valor,
                  ncf: e.ncf,
                  comentario: e.comentario,
                  turno: e.turno,
                  pago: _this.ObtenerFormaDepago(e),
                  detalle: e.facturasventaDetalleList,
                  factura: e,
                  moneda:e.moneda,
                  tasa:e.tasa
                };
              }
              if (_this.BtnReimprecionvalue == 4) {
                //(e.notacreditocontado===null && e.notacreditocontado.cliente===null ?'':e.notacreditocontado.cliente.nombre),
                det = {
                  fecha: e.fecha,
                  secuencia: e.devoclucionPK.secuencia,
                  secdoc: e.documentoinventario.nombrecorto.trim() + "-" + e.devoclucionPK.secuencia,
                  nombre:
                    e.notacreditocontado != null &&
                      e.notacreditocontado.cliente != null
                      ? e.notacreditocontado.cliente
                      : "",
                  valor: e.valor,
                  ncf: e.ncf,
                  comentario: e.comentario,
                  turno: "",
                  pago: _this.ObtenerFormaDepago(e),
                  detalle: e.devolucionDetalleList,
                  devolucion: e,
                  moneda:e.moneda,
                  tasa:e.tasa
                };
              }
              if (_this.BtnReimprecionvalue == 5) {
                det = {
                  fecha: e.fecha,
                  secuencia: e.secuencia,
                  secdoc: e.secuencia,
                  nombre: e.beneficiario,
                  valor: e.valor,
                  ncf: "",
                  comentario: e.descripcion,
                  turno: "",
                  pago: _this.ObtenerFormaDepago(e),
                  detalle: e.facturasventaDetalleList,
                  valecaja: e,
                  moneda:_this.$store.getters.GetConfiguracionGeneral.config.monedabase,
                  tasa:_this.$store.getters.GetConfiguracionGeneral.config.monedabase.tasa
                };
              }
              if (_this.BtnReimprecionvalue == 6) {
                det = {
                  fecha: e.fecha,
                  secuencia: e.secuencia,
                  secdoc: e.secuencia,
                  nombre: e.nombrecliente,
                  valor: e.valor,
                  ncf: "",
                  comentario: e.comentario,
                  turno: "",
                  pago: _this.ObtenerFormaDepago(e),
                  detalle: e.cotizacionDetalleList,
                  cotizacion: e,
                  moneda:_this.$store.getters.GetConfiguracionGeneral.config.monedabase,
                  tasa:_this.$store.getters.GetConfiguracionGeneral.config.monedabase.tasa
                };
              }
              if (_this.BtnReimprecionvalue == 7) {
                det = {
                  fecha: e.fecha,
                  secuencia: e.conduceInventarioPK.secuencia,
                  nombre: e.cliente == null ? "" : e.cliente.nombre,
                  secdoc: e.documentoinventario.nombrecorto.trim() + "-" + e.conduceInventarioPK.secuencia,
                  valor: e.valor,
                  ncf: "",
                  comentario: e.comentario,
                  turno: null,
                  pago: _this.ObtenerFormaDepago(e),
                  detalle: e.conduceInventarioDetalleList,
                  conduce: e,
                //  moneda:_this.$store.getters.GetConfiguracionGeneral.config.monedabase,
                //tasa:_this.$store.getters.GetConfiguracionGeneral.config.monedabase.tasa
                  moneda: e.dtmoneda,
                  tasa:e.tasa
                };
              }
              if (det != null) {
                _this.ListReimprecion.push(det);
              }
            }));
        //  })()
          });

        }, 300);

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }
    },
    // Comprobando si el objeto tiene una propiedad y si la tiene devuelve el valor de la propiedad.
    ObtenerFormaDepago(e) {
      var Fpago = '';

      if (e.hasOwnProperty("puntos") && e.puntos > 0) {
        Fpago = "Puntos"
        return Fpago;
      }

      if (e.hasOwnProperty("cxc") && e.cxc > 0) {
        Fpago = "Cuenta Por Cobrar"
        return Fpago;
      }

      if (e.hasOwnProperty("tarjeta") && e.tarjeta > 0) {
        Fpago = "Tarjeta de Credito"
        return Fpago;
      }

      if (e.hasOwnProperty("cheques") && e.cheques > 0) {
        Fpago = "Cheques"
        return Fpago;
      }

      if (e.hasOwnProperty("transferencia") && e.transferencia > 0) {
        Fpago = "Transferencia"
        return Fpago;
      }

      if (e.hasOwnProperty("efectivo") && e.efectivo > 0) {
        Fpago = "Efectivo"
        return Fpago;
      }

      return Fpago;

    },

    // Comprobando si el usuario tiene permiso para imprimir un dato.
    ReimpresionOpciones(e) {
      this.ListReimprecion = []
      this.NewReimprecionFacturaSeleccionada()
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv(e)
        return;
      }

    },

    // Restableciendo la paginación Reimprecion.
    ReimprecionResetCargarPaginacion() {
      this.ReimprecionPaginator = {
        itemsPerPage: 15,
        pageStart: 0,
        page: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      }

      var _this = this;
      setTimeout(function () { _this.getReimprecionlistFactCliente() }, 300);

    },

    ReimprecionCargarPaginacion(e) {
      this.ReimprecionSelected = [];
      this.ReimprecionPaginator = e;
      if (this.ReimprecionPaginator.itemsPerPage == 1) {
        this.ReimprecionPaginator.itemsPerPage = 15;
      }
      this.getReimprecionlistFactCliente();
    },

    // Crear un nuevo objeto con las mismas propiedades que el objeto en la matriz.
    NewReimprecionFacturaSeleccionada() {
      this.ReimprecionFacturaSeleccionada = {
        secuencia: 0,
        fecha: null,
        ncf: "",
        cliente: "",
        comentario: "",
        turno: 0,
        formapago: "",
        DetalleList: [],
        fac: {},
      };
    },

    // Restablecimiento de los datos en el formulario.
    NewAnulacionFacturaSeleccionada() {
      this.dialogAnulacion = false;
      this.AnulacionBusqueda.secuencia = 0;
      this.AnulacionlistFactCliente = [];
      this.AnulacionSelected = [];
      this.AnulacionFacturaSeleccionada = null;

      this.AnulacionBusqueda = {
        secuencia: 0,
      };
    },

    OpenBuscarFacturaAnular() {
      this.AnulacionBusqueda.secuencia = 0;
      this.AnulacionFacturaSeleccionada = null;
      this.dialogAnulacion = true;
      this.AnulacionSelected = [];
      this.AnulacionlistFactCliente = [];
      return
      var lccaja = JSON.parse(localStorage.caja);

      var fech = this.fechaNueva();
      //console.log(fech.setDate(fech.getDate() - 1))
      //fech =new Date(fech)
      var cliente = "null",
        fecini = this.fechaNueva(),
        fecfin = this.fechaNueva(),
        sec = 0;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reimpresion/facturasCaja/" +
          sec +
          "/" +
          fecini +
          "/" +
          fecfin +
          "/" +
          cliente +
          "/" +
          30 +
          "/" +
          0 +
          "/" +
          lccaja.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.AnulacionlistFactCliente = res.data.listas;
        })
        .catch((error) => this.alerta(" ", "error"));
    },


    // Restablecimiento de los valores del objeto.
    CancelarAnulacionFacturaSeleccionada() {
      this.AnulacionBusqueda.secuencia = 0;
      this.AnulacionSelected = [];
      this.AnulacionFacturaSeleccionada = null;

      this.AnulacionBusqueda = {
        secuencia: 0,
      };
    },

    // Llamar a una API y obtener los datos de la API mostrarlo en una tabla.
    buscarFacturaAnulada(e) {
      if (e.target.value == 0) {
        this.OpenBuscarFacturaAnular();
        return 0;
      }

      this.AnulacionSelected = [];
      this.AnulacionlistFactCliente = [];
      var lccaja = JSON.parse(localStorage.caja);
      var cliente = "null",
        fecini = "null",
        fecfin = "null",
        sec = e.target.value;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reimpresion/facturasCaja/" +
          sec +
          "/" +
          fecini +
          "/" +
          fecfin +
          "/" +
          cliente +
          "/" +
          30 +
          "/" +
          0 +
          "/" +
          lccaja.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {

          if(res.data.listas.length==0){
            this.alerta("Esta factura no existe o no pertenece a la caja seleccionada.", "error")
          }
         
          this.AnulacionlistFactCliente = res.data.listas;
        })
        .catch((error) => this.alerta(" ", "error"));
    },

    AnularObtenerDatoSelect(e) {
      this.AnularDatostabla(e.item);
    },

    // Creando un nuevo objeto y asignándolo a la variable AnulacionFacturaSeleccionada.
    AnularDatostabla(e) {
      if(e.hasOwnProperty('domicilio') && e.domicilio!=null){
        if(e.domicilio.hasOwnProperty('mensajero')&& e.domicilio.mensajero!=null){
        this.alerta("No puede hacer una anulación de esta factura porque esta asignada a un mensajero. se debe realizar el proceso de no entregada", "error");
        return
        }
      }
      this.AnulacionSelected = [];
      this.AnulacionFacturaSeleccionada = { ...e };
      this.AnulacionSelected.push(e);
      // this.AnulacionFacturaSeleccionada=objjt
    },

    // Comprobar si el formulario es válido, si es válido, es comprobar si la
    // AnulacionFacturaSeleccionada.cxc es mayor a 0 y si la AnulacionFacturaSeleccionada.valor no es
    // igual a la AnulacionFacturaSeleccionada.pendiente. Si lo es, está alertando al usuario de que no
    // se puede anular la factura. Si no es así, se está comprobando si la ejecución es cierta. Si es
    // así, está configurando el ejec
    AnulacionFacturaAnular() {
      if (this.$refs.formAnulacion.validate()) {
      
      
        var link=`${this.AnulacionFacturaSeleccionada.facturasventaPK.secuencia}/${this.AnulacionFacturaSeleccionada.facturasventaPK.documento}`
     this.$axios
          .get(
            this.$hostname + this.$hName + "/devolucion/devolucionFactura/" + link,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {

                  if(res.data==true){
                    this.alerta("Esta factura no puede ser anulada, porque tiene devoluciones de venta aplicada", "error");
                    return
                  }else{

 // if (this.AnulacionFacturaSeleccionada.cxc > 0 && this.AnulacionFacturaSeleccionada.valor != this.AnulacionFacturaSeleccionada.pendiente)
 if (this.AnulacionFacturaSeleccionada.cxc > 0 && this.AnulacionFacturaSeleccionada.cxc != this.AnulacionFacturaSeleccionada.pendiente) {
          this.alerta("Esta factura no se puede anular ya que posee algún movimiento.", "error")
          return;
        }
        if (this.ejecucion == true) {
          this.ejecucion = false;
          //TipoAnulacion
          this.AnulacionFacturaSeleccionada.motivoAnular =
            this.TipoAnulacion.descripcion;
          //     var obj={
          //   datos:JSON.parse(JSON.stringify(this.anular)),
          //   usuario:JSON.parse(JSON.stringify(this.$user))
          // }


          if (
            this.AnulacionFacturaSeleccionada.hasOwnProperty("datoTarjeta") &&
            this.AnulacionFacturaSeleccionada.datoTarjeta != null
          ) {
            this.Anulacioncarnet();
          }

          if (
            this.AnulacionFacturaSeleccionada.hasOwnProperty("datoTarjeta") &&
            this.AnulacionFacturaSeleccionada.datoTarjeta == null
          ) {
            this.loading(true)
            this.ProcessFacturaAnula();
          }
        }
                    
                  }

})
          .catch((error) => {
            console.error(error);
          });
      
      
      
      
       



      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },

    // Envío de una solicitud POST al servidor para anular la factura.
    ProcessFacturaAnula() {
      //this.AnulacionFacturaSeleccionada.
      var obj = {
        secuencia: this.AnulacionFacturaSeleccionada.facturasventaPK.secuencia,
        documento: this.AnulacionFacturaSeleccionada.facturasventaPK.documento,
        motivoAnular: this.AnulacionFacturaSeleccionada.motivoAnular,
        datoTarjeta: this.AnulacionFacturaSeleccionada.datoTarjeta,
        usuariosAnulacion:this.$store.getters.GetdatosInfUsu.usuario.usuario,
        fechaAnulacion:this.fechaNueva()+"T04:00"
      };



      this.$axios
        .post(
          this.$hostname + this.$hName + "/AnularFactura/AnularFacturaPv",
          JSON.parse(JSON.stringify(obj))
        )
        .then((res) => {
          this.alerta("Dato Anulado correctamente", "bien");
          this.loading(false)
          if (localStorage.printerSelect != null) { }
          var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
                      var msg = {
                        accion: "print",
                        formato: this.caja.formatoimpresioncontado.trim(),
                        impresora: impresora,
                        data: this.configuraciones,
                        fac: res.data,
                        caja: this.caja,
                      };
                      this.imprimir(msg, "factura");
                   

        })
        .catch((error) => {
          this.alerta(error, "error");
          this.loading(false)
        });
    },

    // Creación de un nuevo objeto con las propiedades del objeto DevolucionFacturaSeleccionada.
    NewDevolucionFacturaSeleccionada() {
     this.DevolucionSelected=[]
      this.DevolucionFacturaSeleccionada = {
        secuencia: 0,
        fecha: null,
        ncf: "",
        cliente: "",
        comentario: "",
        turno: 0,
        formapago: "",
        DetalleList: [],
      };

    },

    // Hacer una llamada API al servidor y obtiene un lista de facturas.
    OpenBuscarFacturaDevolucion() {
      this.DevolucionBusqueda.secuencia = 0;
      this.DevolucionSelected = [];
      this.DevolucionlistFactCliente = [];
      this.dialogDevolucion = true;


      /*let date = new Date();
      var fech = date.toLocaleDateString("en-CA");*/
      var fech = this.fechaNueva();
      // console.log(this.$hostname+this.$hName+'/devolucion/'+fech+'/facturasFecha/'+this.caja.sucursal.codigo)

      var param=""
      if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){ 
        var param="/"+this.Facturasventa.vendedor.codigo
      }


      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/devolucion/" +
          fech +
          "/facturasFecha/" +
          this.caja.sucursal.codigo+param ,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.DevolucionlistFactCliente = res.data;
        });
    },

    // Hacer una llamada axios al backend y devolver una lista de objetos de factura.
    buscarFacturaDevolucion(e) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/devolucion/" +
          e.target.value +
          "/facturas/" +
          this.caja.sucursal.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.DevolucionlistFactCliente = res.data;
        });
    },

filterFacturaDevolucion(list){
var LIST_DIRE =  list.filter((ee) => ee.domicilio==null || ee.domicilio!=null && ee.domicilio.devuelto==false );
return LIST_DIRE;
},



    
    /*FinalizarDevolucionFactura(){
      if(this.DevolucionFacturaSeleccionada.cxc!=null&& this.DevolucionFacturaSeleccionada.cxc>0){
        var FactObj= this.DevolucionSelected[0]
     if(FactObj.cxc!= FactObj.pendiente){
      //notas: hay que ir a verificar si tiene recibo de increso
      this.alerta("Devolucion salvada correctamente !!!", "bien");
     }

      }else{
        this.ContFinalizarDevolucionFactura()
      }
     // 
    },*/

    // Crear un nuevo objeto y asignarle valores.
    async FinalizarDevolucionFactura() {
      if(this.ejecucion==true){
         this.ejecucion=false  
          var _this=this;
  this.$axios
        .get(this.$hostname +this.$hName +"/cierrecaja/ultimocierre/" +
          this.caja.codigo +"/",{ headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;   
        setTimeout(function(){ 
          _this.continuandoFinalizarDevolucionFactura() 
        }, 300);
        });

        }
    },
    


    async continuandoFinalizarDevolucionFactura() {
var DEV_FAC_SEL=this.DevolucionFacturaSeleccionada

if(DEV_FAC_SEL.hasOwnProperty('domicilio') && DEV_FAC_SEL.domicilio!=null && DEV_FAC_SEL.domicilio.facturado==false){
var BOOLMEN=(!DEV_FAC_SEL.hasOwnProperty('mensejero') || DEV_FAC_SEL.hasOwnProperty('mensejero') && DEV_FAC_SEL.mensejero==null )
if(BOOLMEN==true ){
  if(DEV_FAC_SEL.DetalleList.length!= DEV_FAC_SEL.SeletDetalleList.length || DEV_FAC_SEL.valortotal!=DEV_FAC_SEL.domicilio.valor ){
    this.alerta('Esta factura es de domicilio y no esta asignada a un mensajero, debe de anular o hacer la devolución completa.', "error");
    return
  }
}

}
  
      //this.$store.getters.GetdatosInfUsu.usuario.nombre
      //this.$store.getters.GetdatosInfUsu.usuario.codigo
      EventBus.$emit("loading", true);
      var l = this.DevolucionSelected[0];
      if(l.hasOwnProperty('horaFactura')){
          delete l["horaFactura"]
      }
      

      var domnoentre=null
        if(this.DevolucionFacturaSeleccionada.hasOwnProperty('domicilionoentregado')&&this.DevolucionFacturaSeleccionada.domicilionoentregado!=null){
          domnoentre=this.DevolucionFacturaSeleccionada.domicilionoentregado
        }

      this.devolucion = {
        devoclucionPK: { documento: 0, secuencia: 0 },
        // fecha: new Date(),
        fecha: null,
        referencia: "",
        valor: 0.0,
        valorItbis: 0.0,
        usuario: this.$store.getters.GetdatosInfUsu.usuario.codigo,
        ncf: "",
        caja: this.caja.codigo,
        numerocierre: this.CierresCajas.cierrescajasPK.secuencia,
        efectivo: this.DevolucionFacturaSeleccionada.efectivo,
        quitaritbis: this.DevolucionFacturaSeleccionada.quitaritbis,
        cobertura: null,
        fechaImpresion: null,
        nif: null,
        horaImpresion: null,
        comentario: this.DevolucionFacturaSeleccionada.comentario,
        borrado: false,
        devolucionDetalleList: [],
        facturasventa: l,
        nombre: this.AceptarDevolucion.nombre,
        cedula: this.AceptarDevolucion.cedula,
        notacreditocontado: null,
        notacreditoclientes: null,
        documentoinventario: null,
        almacen: this.caja.almacen,
        activo: true,
        domicilionoentregado: domnoentre,
        moneda:this.DevolucionFacturaSeleccionada.moneda,
        tasa:this.DevolucionFacturaSeleccionada.tasa
      };


      this.devolucion.devolucionDetalleList = [];
     /* var bar = new Promise((resolve, reject) => {
        this.DevolucionFacturaSeleccionada.SeletDetalleList.forEach(async (d) => {
        this.detalleDevolucion(d);
        //this.devolucion.valor = this.devolucion.valor + d.cantidad * d.precio;
        this.devolucion.valor = this.devolucion.valor + d.valor;
        resolve()
      });
     });
    
  bar.then(() => {
  console.log('All done Devolucion arrays!')
  this.saveDevolucion();
    });
   
     
     */


     await Promise.all(this.DevolucionFacturaSeleccionada.SeletDetalleList.map(async (d) => { 
      this.detalleDevolucion(d);
      this.devolucion.valor = this.devolucion.valor + d.valor;
      if(this.DevolucionFacturaSeleccionada.quitaritbis==true){
        this.devolucion.valorItbis=0
      }else{
        this.devolucion.valorItbis=this.devolucion.valorItbis+(d.cantidad*d.valorimpuesto)
        //this.devolucion.valorItbis = this.devolucion.valorItbis + d.valor;
      }
     }));

     //this.saveDevolucion();
     this.preValidarSaveDevolucion();

    },

preValidarSaveDevolucion(){

  this.$axios.post(
            this.$hostname + this.$hName + "/devolucion/verificarDevolucion",
            this.devolucion,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
          if(res.data.continuarDev==false){
            this.alerta(res.data.mensage, res.data.estado) 
 //DevolucionFacturaSeleccionada.SeletDetalleList
            //DevolucionFacturaSeleccionada.DetalleList

            this.DevolucionFacturaSeleccionada.SeletDetalleList.filter((ee) => ee.facturasventaDetallePK.producto == res.data.producto &&
            ee.facturasventaDetallePK.unidad == res.data.unidad &&
            ee.facturasventaDetallePK.secuencia == res.data.secFac &&
            ee.facturasventaDetallePK.documento == res.data.docFac 
            )[0].cantidadPendiente= res.data.catPendiente;
         
            this.DevolucionFacturaSeleccionada.DetalleList.filter((ee) => ee.facturasventaDetallePK.producto == res.data.producto &&
            ee.facturasventaDetallePK.unidad == res.data.unidad &&
            ee.facturasventaDetallePK.secuencia == res.data.secFac &&
            ee.facturasventaDetallePK.documento == res.data.docFac 
            )[0].cantidadPendiente= res.data.catPendiente;
           
          }
          if(res.data.continuarDev==true){ 
              this.saveDevolucion()
          }

          })
          .catch((error) => {
            this.loading(false)
            if (error.response) {
              this.alerta(error.response.request.responseText, "error");
            } else {
              this.alerta(error, "error");
            }
          });

},

    // Envío de una solicitud POST al servidor guardando la devolucion.
    saveDevolucion() {

      //documentoNotaCredito
      //console.log(this.devolucion);
      /*if(this.DevolucionFacturaSeleccionada.efectivo==false){
        var doc=this.caja.sucursal.documentoNotaCredito
      }else{

      }
      return*/
      //var doc=this.caja.sucursal.documentofacturacredito

      var doc=this.caja.sucursal.documentoNotaCredito
      this.$axios.get(this.$hostname + this.$hName +"/documentocxc/find/"+doc,
          { headers: this.$store.getters.GetheadersAxios }
        ).then(resdoc => {

          if (this.devolucion.devolucionDetalleList.length > 0) { 

            if(resdoc.data.comprobante==true){
        var link = resdoc.data.codigo +"/" + "CXC"
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/" + link,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data == true) {

    var _this = this;
    setTimeout(function(){ 
      _this.ContinuarsaveDevolucion()

                }, 600); 

            } else {
              this.alerta("No posee secuencia para la Notas de Crédito que está intentado realizar", "error")
            }
          })
          .catch(error => { 
        this.alerta(error, "error")
        this.loading(false)
      
           });

      }else{
        this.ContinuarsaveDevolucion()
      }

          }else{
        this.ejecucion = true
        EventBus.$emit("loading", false);
          }

        });
    },

    ContinuarsaveDevolucion(){
      if (this.devolucion.devolucionDetalleList.length > 0) {
        //this.devolucion.usuario=this.$store.getters.GetOtrasConfigPv.cajero
        this.loading(true)
        this.$axios.post(
            this.$hostname + this.$hName + "/devolucion/save",
            this.devolucion,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {

            (this.devolucion = res.data), this.cancelarAceptarDevolucion();
            this.alerta("Devolucion salvada correctamente !!!", "bien");
            if (localStorage.printerSelect != null) { }
            var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
              var msg = {
                accion: "print",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                devolucion: res.data,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
              this.imprimir(msg, "devolucion");
           
            this.limpiarPuntoVentaCompleto();
          })
          .catch((error) => {
            this.loading(false)
            if (error.response) {
              this.alerta(error.response.request.responseText, "error");
            } else {
              this.alerta(error, "error");
            }
          });
      }else{
        this.ejecucion = true
        EventBus.$emit("loading", false);
      }
    },

    // Agregar un nuevo objeto a la lista de devolucion detalle.
    async detalleDevolucion(datos) {
      var det = {
        cantidad: datos.cantidad,
        precio: datos.precio,
        costo: datos.costounidad,
        codigo: 0,
        documentofactura: null,
        borrado: false,
        producto: datos.productos,
        unidad: datos.unidades,
      };
      this.devolucion.devolucionDetalleList.push(det);
    },
    DevolucionVerificaCantidad(e) {
      if (e.cantidad > e.cantidadPendiente) {
        e.cantidad = e.cantidadPendiente;
      }
      if (e.cantidad <= 0) {
        e.cantidad = e.cantidadPendiente;
      }
    },
    devolucionDescuento(ee){

var Cant = ee.cantidad
var PrecioVal = 0


if (this.ConfImpfiscal == true) {
  PrecioVal = ee.precio
} else {

  if (ee.productos.incluirimpuestoenprecio == true) {
    if (ee.productos.impuesto != null) {
      PrecioVal = (ee.precio) / (1 + (ee.productos.impuesto.tasa / 100))
    } else { PrecioVal = ee.precio }
  } else {

    PrecioVal = ee.precio
  }

}

// ee.DescValor = ((ee.descuento * (PrecioVal * Cant)) / 100)
var valordescuento = ((ee.descuento * (PrecioVal * Cant)) / 100)

return valordescuento;

    },

    DevolucionValor(e) {
      //DevolucionFacturaSeleccionada.DetalleList
      //this.RedondeoBasico(this.Facturasventa.tarjeta)
      //this.DevolucionFacturaSeleccionada.quitaritbis
      var itbisTasa = 0.00;
      var itbis = 0.00;
      var descuento = 0.00;
      var descuentoValor = 0.00;
      var precioSinItbis = 0.00;
      var valor = 0.00;
      if (e.productos.impuesto != null) {
        itbisTasa = e.productos.impuesto.tasa;
      }
      if (e.valordescuento > 0) {
          var DESCUENTOVALOR= this.devolucionDescuento(e)
        descuentoValor = this.RedondeoValor(DESCUENTOVALOR / e.cantidad);
      }
      if (e.descuento > 0) {
        if (itbisTasa > 0) {
          if (e.productos.incluirimpuestoenprecio) {
            descuento = e.cantidad * this.RedondeoValor(((e.precio / (1 + (itbisTasa / 100))) * (e.descuento / 100)));
            precioSinItbis = e.cantidad * this.RedondeoValor((e.precio / (1 + (itbisTasa / 100))));
            itbis = this.RedondeoValor((precioSinItbis - descuento) * (itbisTasa / 100));
            if (this.DevolucionFacturaSeleccionada.quitaritbis) {
              itbis = 0;
            }
            valor = (precioSinItbis - descuento) + itbis;
          } else {
            descuento = e.cantidad * this.RedondeoValor((e.precio * (e.descuento / 100)));
            precioSinItbis = e.cantidad * this.RedondeoValor(e.precio);
            itbis = this.RedondeoValor((precioSinItbis - descuento) * (itbisTasa / 100));
            if (this.DevolucionFacturaSeleccionada.quitaritbis) {
              itbis = 0;
            }
            valor = (precioSinItbis - descuento) + itbis;
          }
        } else {
          if (descuentoValor > 0) {
            valor = (e.cantidad * e.precio) - (e.cantidad * descuentoValor);
          } else {
            valor = e.cantidad * (e.precio) * (e.descuento / 100);
          }
        }
      } else {
        if (this.DevolucionFacturaSeleccionada.quitaritbis) {
          if (e.valorimpuesto > 0) {
            valor = e.cantidad * (e.precio - e.valorimpuesto);
          } else {
            valor = e.cantidad * (e.precio);
          }
        } else {
          valor = e.cantidad * e.precio;
        }
      }
      e.valor = valor;
      return e.valor;
    },


verificarVenFacDev(){
  var fechaHoy= this.fechaNueva()
        var fechBase=this.DevolucionFacturaSeleccionada.fecha
        var fechNew=new Date(this.DevolucionFacturaSeleccionada.fecha)
        fechNew.setDate(fechNew.getDate() + 30);
        fechNew =new Date(fechNew).toLocaleDateString("en-CA")
        if(fechaHoy>fechNew){
          this.alerta(`La factura pasa de los 30 días de su emisión ${this.FormatoFecha(this.DevolucionFacturaSeleccionada.fecha)}, se recomiendo quitar el itbis`, "error")
        }
},

    async DevolucionValorTotal() {

      this.DevolucionFacturaSeleccionada.valordevolucion = 0
      if (this.DevolucionFacturaSeleccionada.SeletDetalleList.length > 0) {
      await Promise.all(this.DevolucionFacturaSeleccionada.SeletDetalleList.map(async (element) => { 
          this.DevolucionFacturaSeleccionada.valordevolucion += element.valor;
       }));


       /* this.DevolucionFacturaSeleccionada.SeletDetalleList.forEach(element => {
          this.DevolucionFacturaSeleccionada.valordevolucion += element.valor;
        });*/

      }

    },

    DevolucionObtenerDatoSelect(e) {
      this.DevolucionDatostabla(e.item);
    },

    async DevolucionDatostabla(e) {
      if(e.hasOwnProperty('cobertura')&&e.cobertura!=null&&e.cobertura>0){
        this.alerta("No puede hacer una devolución de esta factura porque es de seguro. esta se debe anular", "error");
        return
      }

      if(e.hasOwnProperty('domicilio') && e.domicilio!=null && e.domicilio.facturado==false ){
        if(e.domicilio.hasOwnProperty('mensajero')&& e.domicilio.mensajero!=null){
        this.alerta("No puede hacer una devolución de esta factura porque esta asignada a un mensajero. se debe realizar el proceso de no entregada", "error");
        this.DevolucionSelected = [];
        return
        }
      }
      this.DevolucionSelected = [];
      var objjt = {
        factcaja:e.caja,
        valordevolucion: 0,
        valortotal: e.valor,
        secuencia: e.facturasventaPK.secuencia,
        //fecha: new Date(e.fecha).toISOString().substr(0, 10),
        fecha: e.nuevaFecha,
        ncf: e.ncf,
        cliente: e.cliente == null ? "" : e.cliente.nombre,
        cedula: e.cliente != null ? e.cliente.cedrnc : "",
        comentario: "",
        turno: e.turno,
        formapago: this.ObtenerFormaDepago(e),
        DetalleList: [],
        SeletDetalleList: [],
        efectivo: false,
        quitaritbis: false,
        moneda:e.moneda,
        tasa:e.tasa,
        domicilio:e.hasOwnProperty("domicilio")?e.domicilio:null,
        cheques:e.hasOwnProperty("cheques") && e.cheques != null ? e.cheques : 0,
        cupones:e.hasOwnProperty("cupones") && e.cupones != null ? e.cupones : 0,
        cxc: e.hasOwnProperty("cxc") && e.cupones != null ? e.cxc : 0,
        Pagoefec:e.hasOwnProperty("efectivo") && e.cupones != null ? e.efectivo : 0,
        puntos: e.hasOwnProperty("puntos") && e.puntos != null ? e.puntos : 0,
        tarjeta:e.hasOwnProperty("tarjeta") && e.tarjeta != null ? e.tarjeta : 0,
        valorNc:e.hasOwnProperty("valorNc") && e.valorNc != null ? e.valorNc : 0,
        transferencia:e.hasOwnProperty("transferencia") && e.transferencia != null ? e.transferencia : 0,
        numerocierre:e.numerocierre
      
      };
      //DetalleList:e.facturasventaDetalleList,
      //e.facturasventaDetalleList.forEach((element) => { });
      await Promise.all(e.facturasventaDetalleList.map(async (element) => { 
element.cantidad = element.cantidadPendiente;
        objjt.DetalleList.push(element);
}));
      

      this.DevolucionSelected.push(e);
      this.DevolucionFacturaSeleccionada = objjt;

      var fechaHoy= this.fechaNueva()
      var fechBase=e.nuevaFecha
      var fechNew=new Date(e.nuevaFecha)
      fechNew.setDate(fechNew.getDate() + 30);
      fechNew =new Date(fechNew).toLocaleDateString("en-CA")
      var _this = this;
    setTimeout(function(){ 
      if(fechaHoy>fechNew){
        _this.alerta(`La factura pasa de los 30 días de su emisión ${_this.FormatoFecha(e.nuevaFecha)}, se recomiendo quitar el itbis`, "error")
      _this.DevolucionFacturaSeleccionada.quitaritbis=true
      _this.DevolucionValorTotal()
      }
     
                }, 900); 

    },

    ReimprecionObtenerDatoSelect(e) {
      this.ReimprecionDatostabla(e.item);
    },
    async  ReimprecionDatostabla(e) {
      this.NewReimprecionFacturaSeleccionada();
      this.ReimprecionSelected = [];
      this.ReimprecionSelected.push(e);
      var objjt = null;
      objjt = {
        secuencia: e.secuencia,
        secdoc: e.secdoc,
        fecha: new Date(e.fecha),
        ncf: e.ncf,
        cliente: e.nombre,
        comentario: e.comentario,
        turno: e.turno,
        formapago: e.pago,
        DetalleList: e.detalle,
        valor: e.valor,
        factura: JSON.parse(JSON.stringify(e)),
        moneda:e.moneda,
        tasa:e.tasa
      };
      if (this.BtnReimprecionvalue == 1) {
        objjt.factura.recibos.fecha = new Date(e.recibos.fecha).toISOString().substr(0, 10)

        if(e.recibos.ncAutomatico==true){
        await Promise.all(objjt.factura.recibos.recibosingresoDetalleList.filter((ee) => ee.descuentoInformativoNc >0).map(async (element) => {  
         element.descuento=element.descuentoInformativoNc
         }));
         objjt.DetalleList=JSON.parse(JSON.stringify(objjt.factura.recibos.recibosingresoDetalleList))
        }

      }
      if (this.BtnReimprecionvalue == 2) {
        objjt.factura.recibo.fecha = new Date(e.fecha).toISOString().substr(0, 10)
      }

      if (this.BtnReimprecionvalue == 3) {
        objjt.factura.fecha = new Date(e.fecha).toISOString().substr(0, 10)
        objjt.factura.factura.fecha = new Date(e.fecha).toISOString().substr(0, 10)
        if(objjt.factura.factura.ncfVencimiento!=null){
          objjt.factura.factura.ncfVencimiento=objjt.factura.factura.ncfVencimientonuevaFecha
        }

        /*var sec=e.factura.facturasventaPK.secuencia
        var doc=e.factura.facturasventaPK.documento
        this.$axios
        .get(this.$hostname + this.$hName + `/facturasventas/findDomicilioFactura/${sec}/${doc}`, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          console.log('domicilio')
          console.log(res.data)
        })
        .catch((error) => console.log(error));*/

      }

      if (this.BtnReimprecionvalue == 4) {
        objjt.factura.devolucion.fecha = new Date(e.devolucion.fecha).toISOString().substr(0, 10)
        objjt.factura.devolucion.facturasventa.fecha = new Date(e.devolucion.facturasventa.fecha).toISOString().substr(0, 10)
        objjt.quitaritbis = e.devolucion.quitaritbis
      }

      if (this.BtnReimprecionvalue == 5) {
        objjt.factura.valecaja.fecha = new Date(e.valecaja.fecha).toISOString().substr(0, 10)
      }

      if (this.BtnReimprecionvalue == 6) {
        objjt.factura.cotizacion.fecha = new Date(e.cotizacion.fecha).toISOString().substr(0, 10)
      }

      if ( this.BtnReimprecionvalue== 7) {
        objjt.factura.conduce.fecha = new Date(e.conduce.fecha).toISOString().substr(0, 10)
      }

      //objjt.factura.fecha=new Date(e.fecha).toISOString().substr(0, 10)
      /* var tzoffset = (new Date(e.fecha)).getTimezoneOffset() * 60000; //offset in milliseconds
       var localISOTime = (new Date(Date.now(e.fecha) - tzoffset)).toISOString().slice(0, -1);
       console.log(localISOTime)  // => '2015-01-26T06:40:36.181'
       objjt.factura.fecha=localISOTime
       objjt.factura.factura.fecha=localISOTime*/
      /* console.log('Imprimiendo aqui...')
       console.log(new Date(e.fecha).toISOString().substr(0, 10))*/
      // console.log(objjt)
      this.ReimprecionFacturaSeleccionada = objjt;

    },

    changeDevolucionEfectivo(){
  var _this=this;
setTimeout(function(){ 
  if(_this.DevolucionFacturaSeleccionada.efectivo==true){
     var CODCAJASELC=_this.DevolucionFacturaSeleccionada.factcaja.codigo
     if(_this.caja.codigo!=CODCAJASELC){
      _this.DevolucionFacturaSeleccionada.efectivo=false 
      _this.alerta("Esta Factura no se realizo en esta caja, no puede hacer la devolucion en efectivo ", "error");
      
     }
  }
  
}, 300);

    },

    ReimprecionItemSelectValor(e) {
      //this.BtnReimprecionvalue == 4

      // console.log(this.ReimprecionFacturaSeleccionada)
      // console.log('--ReimprecionItemValor--')
      // console.log(e)

      //props.item.cantidad *props.item.costo
      // ReimprecionItemValor(props.item.valor)

      var varcosto = 0;
      //10-05-2024 nota: caso MIC-2071
      //var costo = e.costo
      var costo = e.precio
      if (this.ReimprecionFacturaSeleccionada.quitaritbis != null && this.ReimprecionFacturaSeleccionada.quitaritbis == true) {
        if (e.producto.impuesto != null && e.producto.impuesto.hasOwnProperty("tasa")) {
          var impprod = e.producto.impuesto.tasa
          //valor=(costo/1+(impprod/100))
          varcosto = ((costo / (1 + (impprod / 100))));
          return (e.cantidad * varcosto)
        } else {
          return (e.cantidad * e.costo)
        }
      } else {
        //10-05-2024 nota: caso MIC-2071
        //return (e.cantidad * e.costo)
        return (e.cantidad * e.precio)
      }

    },

    MakaraCelAceptarDevolucion() {
      if (this.AceptarDevolucion.cedula.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }

      if (this.AceptarDevolucion.cedula.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }
    },


    validandoCedRnc(e){

    if(e!=null && e.length>0){
    if(this.validaCedRnc(e)==false){
      var NOM= e.length>10?"CEDULA":"RNC"
      this.alerta(`${NOM} Invalida `, "error");
        return false;
        }
      }
  
      return true;
    
    },

    // Comprobando si el usuario ha seleccionado al menos un producto para devolver.
    openAceptarDevolucion() {
      if (this.DevolucionFacturaSeleccionada.SeletDetalleList.length == 0) {
        this.alerta("Seleccione al menos 1 producto", "error");
        return 0;
      }

      var ObjProRow = this.DevolucionFacturaSeleccionada.SeletDetalleList.filter((ee) => ee.cantidadPendiente == 0);

      if (ObjProRow.length > 0) {
        this.alerta("no puede hacer una devolución a un producto con la cantidad en 0", "error");
        return 0;
      }

      if(this.DevolucionFacturaSeleccionada.efectivo==true){

        var _this=this;
  this.$axios
        .get(this.$hostname +this.$hName +"/cierrecaja/ultimocierre/" +
          this.caja.codigo +"/",{ headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;   
          if(this.caja.codigo==this.DevolucionFacturaSeleccionada.factcaja.codigo && res.data==this.DevolucionFacturaSeleccionada.numerocierre){
            this.contOpenAceptarDevolucion()
          }else{
            this.alerta("No puede realizar la devolución en efectivo, porque esta factura no pertenece al mismo cierre", "error");
          }

        });

      }else{
          this.contOpenAceptarDevolucion()
      }  
    },

contOpenAceptarDevolucion(){
  if (this.DevolucionFacturaSeleccionada.cliente.length == 0) {
        this.newnewAceptarDevolucion();
        this.dialogAceptarDevolucion = true;
      } else {
        this.AceptarDevolucion = {
          nombre: this.DevolucionFacturaSeleccionada.cliente,
          cedula: this.DevolucionFacturaSeleccionada.cedula,
        };
        this.FinalizarDevolucionFactura();
      }
},


    // Creando un nuevo objeto llamado AceptarDevolucion y asignándolo a la propiedad de datos del mismo
    // nombre.
    newnewAceptarDevolucion() {
      this.AceptarDevolucion = {
        nombre: null,
        cedula: "",
      };
    },

    // Validando el formulario y si es válido llamará al método FinalizarDevolucionFactura().
    SalvarAceptarDevolucion() {
      if (this.$refs.formAceptarDevolucion.validate()) {

        if(this.AceptarDevolucion.cedula!=null && this.AceptarDevolucion.cedula.length>0){ 
          var CEDULA = this.AceptarDevolucion.cedula.replace("-", "");
        if(this.validaCedRnc(CEDULA)==false){
          var NOM= CEDULA.length>10?"CEDULA":"RNC"
      this.alerta(`${NOM} Invalida `, "error");
        return 0;
        }
        }

        this.FinalizarDevolucionFactura();
      } else {
        this.alerta("los campos en rojo son Requeridos", "error");
      }
    },

    // Cerrando el cuadro de diálogo del modal de la devolucion.
    cancelarAceptarDevolucion() {
      this.dialogAceptarDevolucion = false;
      this.newnewAceptarDevolucion();
      this.NewDevolucionFacturaSeleccionada()
    this.DevolucionSelected=[]
    },

    // Impresión de un Objecto.
    ImpriReimprimirFactura() {
      
     /* if(localStorage.printerSelect!=null&& localStorage.printerSelect.trim().length > 0){
        impresora=JSON.parse(localStorage.printerSelect).nombre
      }*/
     //JSON.parse()
      // var OBJIMPSELE={"nombre": "Microsoft Print to PDF","codigo": 4}
      var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
      //var selectImpre=JSON.parse(localStorage.printerSelect)
      // selectImpre.to.trim().length > 0 &&
 
      var impresora=null
      if( selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
        impresora=JSON.parse(localStorage.printerSelect).nombre
      }
      
     
      if (localStorage.printerSelect != null) { }
        var msg = {
          accion: "reprint",
          formato: this.caja.formatoimpresioncontado.trim(),
        //  impresora: JSON.parse(localStorage.printerSelect).nombre,
          impresora: impresora,
          data: this.configuraciones,
          fac: this.ReimprecionFacturaSeleccionada,
          cajero: this.OtrasConfigPv.cajero,
          caja: this.caja,
        };
        if (this.BtnReimprecionvalue == 1) {
          this.imprimir(msg, "recibo");
        }
        if (this.BtnReimprecionvalue == 2) {
          this.imprimir(msg, "otros-ingreso");
        }
        if (this.BtnReimprecionvalue == 3) {
          this.imprimir(msg, "factura");
        }
        if (this.BtnReimprecionvalue == 4) {
          this.imprimir(msg, "devolucion");
        }
        if (this.BtnReimprecionvalue == 5) {
          this.imprimir(msg, "vale");
        }
        if (this.BtnReimprecionvalue == 6) {
          this.imprimir(msg, "cotizacion");
        }
        if (this.BtnReimprecionvalue == 7) {

          if(this.caja.marca){
            msg.formato= this.caja.formatoImprecionConduce.trim()
              }

          this.imprimir(msg, "conduce");
        }
     




    },

    calcularReciboIngPendienteDespues(e){
      var balanceTotal=0
      if(e.pendientedespues>0){
      var abdesreisr=this.RedondeoValor(e.abono + e.descuento + e.retencion + e.retencionIsr)
      //element.balanceTotal
      balanceTotal = this.RedondeoValor(e.pendientedespues - (abdesreisr))
    }else{
     balanceTotal = 0
    }
    return balanceTotal;

    },
    async cargarReciboListFactpendiente() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reciboingreos/facturaspendientes/" +
          this.clienteFactura.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
        /*  this.objTemp = res.data;
         this.RListFactpendiente = res.data;
          this.ObtemRListFactpendiente = res.data;*/

 var OBJ=res.data
OBJ.sort(function (a, b) {
  if (a.fechafactura > b.fechafactura) {return 1;}
  if (a.fechafactura < b.fechafactura) {return -1;
  }
  // a must be equal to b
  return 0;
});

          this.objTemp =OBJ;
         this.RListFactpendiente =OBJ;
          this.ObtemRListFactpendiente =OBJ;

          this.Totales.ReciboIngreso.pendiente = 0;
          this.Totales.ReciboIngreso.abono = 0;
          if (res.data.length > 0) {

           // (async () => {  
            //OBJ.forEach((elent) => {});
            //await 
            Promise.all(OBJ.map(async (elent) => { 
              this.Totales.ReciboIngreso.pendiente += this.RedondeoBasico(elent.pendientedespues);
            }));
         // })()
            

          }
        });
    },
    obtenerDatoSelectFactPendiente(e) {
      this.datostablaFactPendiente(e.item);
    },
    datostablaFactPendiente(e) {
      this.HeadInfFactPendienteSelecc = {
        fecha: fechaNueva(),
        factura: "",
        valorf: 0,
        pendiente: 0,
        ncf: "",
        referencia: "",
      };

      this.SelectedFactPendiente = null;
      this.FactPendienteSeleccionado = null;
      this.SelectedFactPendiente = JSON.parse(JSON.stringify(e));
      this.FactPendienteSeleccionado = JSON.parse(JSON.stringify(e));
       this.FactPendienteSeleccionado.DescValorPorc=false;

      //this.ObtemFactPendienteSeleccionado=JSON.parse(JSON.stringify(e))

      (this.HeadInfFactPendienteSelecc.fecha = new Date(e.fechafactura)
        .toISOString()
        .substr(0, 10));


        // (this.HeadInfFactPendienteSelecc.pendiente = e.pendientedespues);

        if (e.facturasventa != null) {
          this.HeadInfFactPendienteSelecc.pendiente=this.RedondeoBasico(e.facturasventa.pendiente)
        }

        if (e.notadebitoclientes != null) {
          this.HeadInfFactPendienteSelecc.pendiente=this.RedondeoBasico(e.notadebitoclientes.pendiente)
        }




      if (e.facturasventa != null) {
        this.HeadInfFactPendienteSelecc.factura =
          e.facturasventa.documentoFactura.nombrecorto +
          "-" +
          e.facturasventa.facturasventaPK.secuencia;
        this.HeadInfFactPendienteSelecc.valorf = e.facturasventa.valor;
        this.HeadInfFactPendienteSelecc.ncf = e.facturasventa.ncf;
        this.HeadInfFactPendienteSelecc.referencia = e.facturasventa.referencia;
      }

      if (e.notadebitoclientes != null) {
        this.HeadInfFactPendienteSelecc.factura =
          e.notadebitoclientes.documentocxc.nombrecorto +
          "-" +
          e.notadebitoclientes.notadebitoclientesPK.secuencia;
        this.HeadInfFactPendienteSelecc.valorf = e.notadebitoclientes.valor;
        this.HeadInfFactPendienteSelecc.ncf = e.notadebitoclientes.ncf;
        this.HeadInfFactPendienteSelecc.referencia =
          e.notadebitoclientes.referencia;
      }
    },

    OpenRecProdControlado() {
      if (this.RecetaProdControlado.detalleList.length > 0) {
        this.dialogProdControlado = true;
        this.EsNuevoObjRectPro = false
        this.VerRecProdControlado = true
        this.ObjRectPro = JSON.parse(JSON.stringify(this.RecetaProdControlado));
      } else {
        this.alerta("No ha realizado receta con producto controlado", "error");
      }
    },

    OpenDialogReciboIngreso() {
      if (this.clienteFactura == null) {
        this.OpenEjecReciboIngreso = true;
        this.OpenDialogCrearEditarCliente();
      }
      if (this.clienteFactura != null) {

        var RECIBO_ING_AVANZ=this.$store.getters.GetConfiguracionGeneral.configPv.reciboIngresoAvanzadopv

        if(RECIBO_ING_AVANZ==true){
          this.dialogReciboIngreso = true;
        this.OpenEjecReciboIngreso = false;
        this.openComponentsRecibosIngresos()
        }else{
         //se cambio por el recibo de ingreso heredado
       this.dialogReciboIngreso = true;
        this.OpenEjecReciboIngreso = false;
        this.cargarReciboListFactpendiente();
        }
       

       /*  console.log(this.caja.sucursal.documentorecibo)
        console.log(this.CierresCajas.cierrescajasPK.secuencia)
        console.log(this.clienteFactura)*/

      }
    },


openComponentsRecibosIngresos(){

var _this=this;
  this.$axios
        .get(this.$hostname +this.$hName +"/cierrecaja/ultimocierre/" +
          this.caja.codigo +"/",{ headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;   
setTimeout(function(){ 
  _this.$refs.RefComptsRecibosIngresos.form.recibo.numerocierre=res.data
  _this.$refs.RefComptsRecibosIngresos.form.recibo.cliente=_this.clienteFactura
  _this.$refs.RefComptsRecibosIngresos.form.caja=_this.caja
  _this.$refs.RefComptsRecibosIngresos.isComponente=true
   _this.$refs.RefComptsRecibosIngresos.form.recibo.documentocxc=_this.caja.sucursal.docrecibo
   _this.$refs.RefComptsRecibosIngresos.form.recibo.usuario= _this.$store.getters.GetOtrasConfigPv.cajero
   _this.$refs.RefComptsRecibosIngresos.FactPendientes()
   _this.$refs.RefComptsRecibosIngresos.cargarNotaCreditoAutomatica()
  //_this.$refs.RefComptsRecibosIngresos.headerFactura.vendedor=data.datos.headerFactura.vendedor
}, 300);

        });
},

closeComponentsRecibosIngresos(){
  this.$refs.RefComptsRecibosIngresos.newwnuevo()
  this.dialogReciboIngreso = false
},

DataRetornComptsRecibosIngresos(e){

  if(e.opcion=='GuardarRecibo'){
    var _this=this;
 setTimeout(function(){ 
       // _this.loading(true)
            _this.closeComponentsRecibosIngresos()
            _this.alerta("Dato guardado correctamente", "bien");
            var lccaja = JSON.parse(localStorage.caja);
            if (localStorage.printerSelect != null) {}
            var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

              var msg = {
                accion: "print",
                formato: _this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: _this.configuraciones,
                ingreso: e.datos,
                caja: lccaja,
                cajero: _this.$store.getters.GetOtrasConfigPv.cajero,
              };
              _this.imprimir(msg, "recibo");
            
    }, 300);
  }

},


    OpenDialogProdControlado() {
      if (this.clienteFactura == null) {
        this.OpenEjecProdControlado = true;
        this.OpenDialogCrearEditarCliente();
      }
      if (this.clienteFactura != null) {
        // EjecuModalProductoControlado
        // this.dialogProdControlado=true
         this.EjecuModalProductoControlado("notf");
      }
    },

    EjecuModalProductoControlado(e) {
      if (e == "notf") {
        if (this.clienteFactura == null) {
          this.LabelProdCotrolado =
            "Este Producto está controlado, debe indicar un cliente";
          this.dialogNotifProdControlado = true;
        }

        if (this.clienteFactura != null) {
          this.LabelProdCotrolado =
            "Este Producto está controlado, debe indicar una receta";
          this.dialogNotifProdControlado = true;
        }
      }

      if (e == "accept") {
        if (this.clienteFactura == null) {
          this.dialogNotifProdControlado = false;
          this.OpenDialogProdControlado();
          return;
        }

        if (this.clienteFactura != null) {
          this.RecetaProdControlado.cliente = JSON.parse(
            JSON.stringify(this.clienteFactura)
          );

          var selc
          if(this.selected==null){
            this.selected=JSON.parse(JSON.stringify(this.selecteTemRecCont))
            this.selecteTemRecCont=null
          }

          selc = {
            productos: this.selected.productos,
            unidad: this.selected.unidades,
            cantidadRecetada: 0,
            cantidadDespachada: 0,
          };
          
          this.RecetaProdControlado.detalleList.push(selc);
          this.dialogNotifProdControlado = false;
          this.dialogProdControlado = true;
          // this.ObjRectPro = JSON.parse(JSON.stringify(this.RecetaProdControlado));


        }
      }
      //canselar en modal
      if (e == "cancelar") {
        this.dialogNotifProdControlado = false;
        this.selecteTemRecCont=null
      }
    },

    SalvaeRecetaControlada() {
      if (this.$refs.formRecetaProdControlado.validate()) {
        this.dialogProdControlado = false;
        this.ObjRectPro = null
        if (this.VerRecProdControlado == false) {
          this.EsNuevoObjRectPro = true
          var selc = JSON.parse(JSON.stringify(this.selected));
          var e = JSON.parse(JSON.stringify(this.selected));
          selc.vendedor = null;
          selc.recargo = 0;
          selc.cobertura = 0;
          selc.descuento = 0;
          selc.valor = 0;
          selc.cantidad = 1;
          selc.ValorPorc = true;
          selc.ValorPorcRecarg = true;
          selc.precioOrig = e.precio;
          selc.precioDig =e.precio;
          selc.descripcion = e.productos.descripcion + " " + e.unidades.descripcion;
          selc.descuentoKeys = e.hasOwnProperty("descuentoKeys") && e.descuentoKeys != null ? e.descuentoKeys : 0;
          selc.DescValor = e.hasOwnProperty("DescValor") && e.DescValor != null ? e.DescValor : 0;
          this.pedirNombre = e.productos.pedirNombre;
          this.productoSeleccionado = JSON.parse(JSON.stringify(selc));

          this.PanelMenuOpcionCompleto = false;
          this.PanelSelectProducto = false;
          this.PanelInfProduct = true;
          this.$refs.RefScroll.scrollTop = 0;
          var _this = this;
          setTimeout(function () {
            _this.$refs.RefTextFieldProductoCantidad.focus();
            _this.getExistProdXAlmacen()
            _this.getProductoSustitutos()
          }, 500);

        }
        // selc.vendedor=null
        // selc.recargo=0
        // selc.ValorPorcRecarg=true
        // selc.cobertura=0
        // selc.descuento=0
        // selc.valor=0
        // selc.ValorPorc=false
        // this.productoSeleccionado =JSON.parse(JSON.stringify(selc))
        // this.PanelMenuOpcionCompleto=false
        // this.PanelSelectProducto=false
        // this.PanelInfProduct=true
        // this.$refs.RefScroll.scrollTop=0
        // var _this=this;
        // setTimeout(function(){
        // _this.$refs.RefTextFieldProductoCantidad.focus();
        //  }, 500);



      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },

    // Un método que se llama cuando el usuario hace clic en el botón cancelar de un cuadro de diálogo y 
    //lo cierra  asignando variables necesaria.
    CancelarRecetaControlada() {

      if (this.EsNuevoObjRectPro == true) {
        this.dialogProdControlado = false;
        if (this.ObjRectPro != null) {
          this.RecetaProdControlado = JSON.parse(JSON.stringify(this.ObjRectPro));
          this.ObjRectPro = null
        }

        if (this.ObjRectPro == null) {

          if (this.RecetaProdControlado.detalleList.length > 1) {
            var size = this.RecetaProdControlado.detalleList.length - 1
            var RectPro = this.RecetaProdControlado.detalleList[size]
            const index = this.RecetaProdControlado.detalleList.indexOf(RectPro);
            this.RecetaProdControlado.detalleList.splice(index, 1);
          } else {
            this.NewRecetaProdCOntrolado();
          }
        }
      } else {

        this.RecetaProdControlado = JSON.parse(JSON.stringify(this.ObjRectPro));
        this.dialogProdControlado = false;
        this.ObjRectPro = null
      }


    },

    // Creando un nuevo objeto y asignándolo a la variable RecetaProdControlado.
    NewRecetaProdCOntrolado() {
      this.RecetaProdControlado = {
        codigo: 0,
        numero: null,
        exq: "",
        fecha: fechaNueva(),
        cliente: null,
        telefono: null,
        direccion: null,
        centroSalud: null,
        activo: true,
        estatus: true,
        medico: null,
        especialidad: null,
        detalleList: [],
      };
      this.ObjRectPro = null
      this.EsNuevoObjRectPro = true
    },

    // Hacer una solicitud al servidor y devolver los datos de centro de salud.
    CargarCentroSalud() {
      this.$axios
        .get(this.$hostname + this.$hName + "/centrosalud/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RLiClinicas = res.data;
        })
        .catch((error) => console.log(error));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCentroSalud() {
      if (this.RecetaProdControlado.centroSalud == null) {
        this.RecetaProdControlado.centroSalud = "";
      }

      if (this.RecetaProdControlado.centroSalud.codigo == undefined) {
        this.alerta("Seleccione un Centro de salud", "error");
        this.RecetaProdControlado.centroSalud = "";
        return;
      }
    },

    classObjFactPenSelect(e) {
      if (this.SelectedFactPendiente == null) {
        return "";
      }
      if (this.SelectedFactPendiente != null) {
        if (e.indx == this.SelectedFactPendiente.indx) {
          return "RowSelectColorPv1";
        } else {
          return "";
        }
      }
      return "";
    },
    // Crear un nuevo objeto y empujarlo a un objto detalle de factura.
    llenarDetalle(dato) {
      var VALORPRODUCTO=this.CalcularValorProductoRPCompra(dato)
      var COMISIONPRODUCTO=dato.productos.comision!=null ? dato.productos.comision:0
      var VALORCOMISION=0;
      // productos.comision
      //valorComisionProducto: dato.productos.comision!=null && dato.productos.comision>0 ?this.RedondeoBasico(((VALORPRODUCTO*COMISIONPRODUCTO)/100)):0,

        if(dato.vendedor!=null&&dato.vendedor!=null&& dato.vendedor.hasOwnProperty('codigo')){
          if(dato.vendedor.comisionventa!=null && dato.vendedor.comisionventa>0){
            if(dato.vendedor.comisionventa<dato.productos.comision){ 
              VALORCOMISION=this.RedondeoBasico(((VALORPRODUCTO*dato.vendedor.comisionventa)/100)) 
            }else{
              if(COMISIONPRODUCTO==0){
                VALORCOMISION=this.RedondeoBasico(((VALORPRODUCTO*dato.vendedor.comisionventa)/100)) 
              }else{
                VALORCOMISION=dato.productos.comision!=null && dato.productos.comision>0 ?this.RedondeoBasico(((VALORPRODUCTO*COMISIONPRODUCTO)/100)):0
              }
            }
          }else{
            VALORCOMISION=dato.productos.comision!=null && dato.productos.comision>0 ?this.RedondeoBasico(((VALORPRODUCTO*COMISIONPRODUCTO)/100)):0
          }

        }else{
          if(this.Facturasventa.vendedor!=null&&this.Facturasventa.vendedor!=null&& this.Facturasventa.vendedor.hasOwnProperty('codigo')){

            if(this.Facturasventa.vendedor.comisionventa!=null && this.Facturasventa.vendedor.comisionventa>0){
            if(this.Facturasventa.vendedor.comisionventa<dato.productos.comision){ 
              VALORCOMISION=this.RedondeoBasico(((VALORPRODUCTO*this.Facturasventa.vendedor.comisionventa)/100)) 
            }else{
              if(COMISIONPRODUCTO==0){
                VALORCOMISION=this.RedondeoBasico(((VALORPRODUCTO*this.Facturasventa.vendedor.comisionventa)/100)) 
              }else{
                VALORCOMISION=dato.productos.comision!=null && dato.productos.comision>0 ?this.RedondeoBasico(((VALORPRODUCTO*COMISIONPRODUCTO)/100)):0
              }
            }

          }else{
            VALORCOMISION=dato.productos.comision!=null && dato.productos.comision>0 ?this.RedondeoBasico(((VALORPRODUCTO*COMISIONPRODUCTO)/100)):0
          }
          }else{
            VALORCOMISION=dato.productos.comision!=null && dato.productos.comision>0 ?this.RedondeoBasico(((VALORPRODUCTO*COMISIONPRODUCTO)/100)):0
          }

        }

      var detalle = {
        facturasventaDetallePK: {
          documento: 0,
          secuencia: 0,
          producto: 0,
          unidad: 0,
          id: 0,
        },
        almacen: this.caja.almacen.codigo,
        cantidad: dato.cantidad,
        valordescuento: dato.valordescuento,
        valorimpuesto: dato.valorimpuesto,
        precio: dato.precio,
        descuento: dato.descuento,
        codigocupon: null,
        valorcupon: 0.0,
        cantidadPendiente: dato.cantdad,
        oferta: null,
        valorRecargo: dato.RecarValor,
        recargo: 0.0,
        descripcion: dato.descripcion,
        costounidad: dato.costo,
        cantidadDespachada: dato.cantidadDespachada,
        borrado: false,
        cobertura: 0,
        impuesto: dato.productos.hasOwnProperty('impuesto')?dato.productos.impuesto:null,
        productos: dato.productos,
        unidades: dato.unidades,
        vendedor: dato.vendedor!=null && dato.vendedor.hasOwnProperty('codigo') ? dato.vendedor:null,
        valorComisionProducto: VALORCOMISION ,
      };
      this.Facturasventa.facturasventaDetalleList.push(detalle);
    },


    // Creación de una nueva factura.
    async PostrealizarCobro() {
      /* console.log('--Hora1--:')
       console.log(new Date())
       console.log('--Hora2--:')
       console.log(new Date().getTime())
        console.log('--Hora3--:')
       console.log(this.HoraFormatter2(new Date()))
       console.log('--Hora4--:')
       var hora =new Date() 
       console.log(hora.getHours() + ':' + hora.getMinutes() + ':' + hora.getSeconds())*/

       var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
      var MONEDAFACTURATASA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa)
      this.Facturasventa.fecha = new Date().valueOf()
      var _this=this;

      if (this.bancoCheque != null) {
        this.Facturasventa.bancoCheque = this.bancoCheque.codigo;
      }
      if (this.tipoTarjeta) {
        this.Facturasventa.tipoTarjeta = this.tipoTarjeta.codigo;
      }
      this.Facturasventa.horaFactura = new Date().getTime()
      this.Facturasventa.facturasventaDetalleList = [];
  
      // this.RProductoCompra.forEach((d) => {
        await Promise.all(this.RProductoCompra.map(async (d) => { 
        d.valorimpuesto = 0;
        if (d.productos.incluirimpuestoenprecio == true) {
          if (d.productos.impuesto != null) {
            // d.valorimpuesto =d.precio - d.precio / (1 + d.productos.impuesto.tasa / 100);
            // var objimpuesto= (((objprecc)-d.valordescuento)* (d.productos.impuesto.tasa/100))
          
            /* var objprecc= this.RedondeoBasico(d.precio / (1 + (d.productos.impuesto.tasa/ 100)));
            var objimpuesto= (((objprecc))* (d.productos.impuesto.tasa/100));
             d.valorimpuesto =this.RedondeoBasico(objimpuesto);*/
          //console.log(objimpuesto)
          
                    var VALOR_IMPUESTO=this.CalculateTax(d);
                       d.valorimpuesto= (VALOR_IMPUESTO/d.cantidad) 
          // console.log(d.valorimpuesto)
          }
        }
        if (d.productos.incluirimpuestoenprecio == false) {
          if (d.productos.impuesto != null) {
            d.valorimpuesto = d.precio * (d.productos.impuesto.tasa / 100);
          }
        }

        this.llenarDetalle(d);
      }));
      // });
      
      var BOOL_COBERTURA=false
      if (this.clienteFactura != null) {
        this.Facturasventa.cliente = this.clienteFactura;
        if (this.CoberturaSeguro.cobertura > 0) {
          this.Facturasventa.ars = this.CoberturaSeguro.ars;
          this.Facturasventa.cobertura = this.Totales.totalGeneral.cobertura;
          this.Facturasventa.coberturaPendiente =
            this.Totales.totalGeneral.cobertura;
          this.Facturasventa.autorizacion = this.CoberturaSeguro.autorizacion;
          this.Facturasventa.medico = this.CoberturaSeguro.medico;
          this.Facturasventa.dianostico = this.CoberturaSeguro.dianostico;
          this.Facturasventa.especialidad = this.CoberturaSeguro.especiales;
          this.Facturasventa.exequartur = this.CoberturaSeguro.exequartur;
          BOOL_COBERTURA=true
        }
        this.Facturasventa.descripcion=this.clienteFactura.nombre.trim()
      }
      this.Facturasventa.caja = this.caja;
      if (
        this.Facturasventa.tipocomprobante == null ||
        this.Facturasventa.tipocomprobante == ""
      ) {
        this.Facturasventa.tipocomprobante =
          this.configuraciones.configPv.comprobantefacturacion;
      }
      if (this.DatosPagareFactura != null && this.DatosPagareFactura.contrato.CantidadCuotas > 0) {

        var Obj = {
          secuencia: 0,
          fecha: fechaNueva(),
          valorPrestamo: this.DatosPagareFactura.contrato.valorPrestamo,
          fechaInicial: this.DatosPagareFactura.contrato.fecha,
          inicial: this.DatosPagareFactura.contrato.inicio,
          valorRecibido: this.DatosPagareFactura.contrato.valorRecibido,
          porcientoInteres: this.DatosPagareFactura.contrato.interes,
          cantidadCuotas: this.DatosPagareFactura.contrato.CantidadCuotas,
          capitalPaga: this.DatosPagareFactura.contrato.Capitalpagar,
          interesTotal: this.DatosPagareFactura.contrato.Interespagar,
          montoCuota: this.DatosPagareFactura.contrato.MontoCuotas,
          tipoInteres: this.DatosPagareFactura.contrato.TipoInteres.codigo,
          interesMora: this.DatosPagareFactura.contrato.InteresMora,
          prorroga: this.DatosPagareFactura.contrato.prorroga,
          cliente: this.clienteFactura,
          activo: true,
          borrado: false
        }

        this.Facturasventa.pagareObj = Obj;
      } else {
        this.Facturasventa.pagareObj = null;
      }
      if (this.RecetaProdControlado != null) {
        this.Facturasventa.recetaObj = this.RecetaProdControlado;
        this.Facturasventa.recetaObj.recetaDetalleList = this.RecetaProdControlado.detalleList;
        this.Facturasventa.receta = this.RecetaProdControlado.numero;
        this.Facturasventa.exequartur = this.RecetaProdControlado.exq;
        this.Facturasventa.medico = this.RecetaProdControlado.medico;
        this.Facturasventa.recetaObj.fecha=this.RecetaProdControlado.fecha.trim().substr(0,10)+"T04:00"
      }

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.caja.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.Facturasventa.numerocierre = res.data;
        });

      //this.Facturasventa.numerocierre= this.CierresCajas.cierrescajasPK.secuencia
      //numerocierre: datos.nuemrocierre,,
      this.Facturasventa.sucursal = this.caja.sucursal;
      this.Facturasventa.valor = this.Totales.totalGeneral.total;
      this.Facturasventa.valorimpuesto = this.Totales.totalGeneral.itbis;
      this.Facturasventa.valordescuento = this.Totales.totalGeneral.descuento;
      this.Facturasventa.retencion = this.Totales.totalGeneral.Retencion;
      this.Facturasventa.valorRecargo = this.Totales.totalGeneral.recargo;
      this.Facturasventa.moneda = this.Monedafactura;
      this.Facturasventa.tasa = this.Monedafactura.tasa;
      this.Facturasventa.usuario = this.OtrasConfigPv.cajero;
      this.Facturasventa.usuarioCobro = this.OtrasConfigPv.cajero;
      if (this.Facturasventa.vendedor != null) {
        this.Facturasventa.vendedor = this.Facturasventa.vendedor;
      }

      if (this.Facturasventa.tipocomprobante == null || 
      this.Facturasventa.tipocomprobante != null &&
      !this.Facturasventa.tipocomprobante.hasOwnProperty("codigo")) {
        this.Facturasventa.tipocomprobante =this.configuraciones.configPv.comprobantefacturacion;
      }

      if (this.domiciliofactura != null) {
        this.ConstruirDomicilio(this.Facturasventa, this.devuelta);
      }

      if(BOOL_COBERTURA==true){
        this.Facturasventa.clienteArs=JSON.parse(JSON.stringify(this.clienteFactura))
        if(this.Facturasventa.cxc > 0 ){
          this.Facturasventa.cliente=JSON.parse(JSON.stringify(this.clienteTempCredito))
        }else{
          this.Facturasventa.cliente=JSON.parse(JSON.stringify(this.clienteFactura))
        }
          
      }
          
        
      if (this.selectedListFPagoNC.length > 0) {
        this.Facturasventa.codigoNc = this.selectedListFPagoNC[0].notacreditocontadoPK.documento;
        this.Facturasventa.secuenciaNc = this.selectedListFPagoNC[0].notacreditocontadoPK.secuencia;
      }

      var condFAct=(this.conduceSelect != null)
      if (
        condFAct==false&&
        this.caja.marca &&
        this.configuraciones.configPv.conduce &&
        (this.Facturasventa.efectivo*MONEDAFACTURATASA) < 250000 && //el monto maximo que tieene la dgi para consumidor final B02
        this.Facturasventa.tipocomprobante.codigo === 1 &&
        (this.Facturasventa.efectivo*MONEDAFACTURATASA) > 0 &&
        (this.Facturasventa.efectivo*MONEDAFACTURATASA) >= this.Facturasventa.valor &&
        (this.Facturasventa.tarjeta *MONEDAFACTURATASA) == 0 &&
        (this.Facturasventa.cxc*MONEDAFACTURATASA) == 0 &&
        this.selectedListFPagoNC.length <= 0 && this.conduceSelect == null
      ) {

        if (this.ejecucion == true) {
          this.loading(true)
          this.ejecucion = false
          this.Facturasventa.fecha = new Date().valueOf()
          var FACTURAS_VENTA= JSON.parse(JSON.stringify(this.Facturasventa));
          FACTURAS_VENTA.efectivo= JSON.parse(JSON.stringify(this.Facturasventa.efectivo*MONEDAFACTURATASA));
          FACTURAS_VENTA.tarjeta= JSON.parse(JSON.stringify(this.Facturasventa.tarjeta*MONEDAFACTURATASA));
          FACTURAS_VENTA.cheques= JSON.parse(JSON.stringify(this.Facturasventa.cheques*MONEDAFACTURATASA));
          FACTURAS_VENTA.transferencia= JSON.parse(JSON.stringify(this.Facturasventa.transferencia*MONEDAFACTURATASA));
          FACTURAS_VENTA.cxc= JSON.parse(JSON.stringify(this.Facturasventa.cxc*MONEDAFACTURATASA));
          FACTURAS_VENTA.cupones= JSON.parse(JSON.stringify(this.Facturasventa.cupones*MONEDAFACTURATASA));
          FACTURAS_VENTA.valorConduce= JSON.parse(JSON.stringify(this.Facturasventa.valorConduce*MONEDAFACTURATASA));
          FACTURAS_VENTA.valorNc= JSON.parse(JSON.stringify(this.Facturasventa.valorNc*MONEDAFACTURATASA));

          

          // if(this.activaConduceCredito==false){ }

            if(FACTURAS_VENTA.efectivo>this.RedondeoBasico(this.Totales.totalGeneral.total)){
              FACTURAS_VENTA.devuelta=this.RedondeoBasico((FACTURAS_VENTA.efectivo-this.Totales.totalGeneral.total))
              FACTURAS_VENTA.efectivo=this.Totales.totalGeneral.total
              FACTURAS_VENTA.valorConduce=this.Totales.totalGeneral.total
             
            }else{
                   //this.Facturasventa.efectivo=this.Facturasventa.valorConduce
                   FACTURAS_VENTA.efectivo=this.Totales.totalGeneral.total
                   FACTURAS_VENTA.valorConduce=this.Totales.totalGeneral.total
            }
           
         

          //  _this.$hostname + _this.$hName + "/conduce/save",_this.Facturasventa,
          setTimeout(function(){
          _this.$axios
            .post(
              _this.$hostname + _this.$hName + "/conduce/save",
              FACTURAS_VENTA,
              { headers: _this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (_this.deleteFacturaTemporal != null) {
                _this.deleRestaurarFact(_this.deleteFacturaTemporal);
              }
              if(_this.cotizacionAfacturaSelect!=null){
                      _this.ActualizarCotizacionFActurada(_this.cotizacionAfacturaSelect)
                    }
              _this.objTemp = res.data;
              _this.RListFactpendiente = res.data;
              if (localStorage.printerSelect != null) { }
              var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

                var msg = {
                  accion: "print",
                 // formato: _this.caja.formatoimpresioncontado.trim(),
                  formato: _this.caja.formatoImprecionConduce.trim(),
                  impresora: impresora,
                  data: _this.configuraciones,
                  fac: res.data,
                  caja: _this.caja,
                  //   fac:this.ReimprecionFacturaSeleccionada
                };
                _this.imprimir(msg, "conduce");
             
              _this.alerta("Datos salvado correctamente !!!", "bien");
            })
            .catch((error) => {
              if (error.response) {
                _this.alerta(error.response.request.responseText, "error");
              } else {
                _this.alerta(error, "error");
                _this.ejecucion = true
              }
            });
          }, 600);
            //--
        }


      } else if ((this.Facturasventa.valorConduce*MONEDAFACTURATASA) > 0) {
        if (this.caja.sucursal.documentoConduce == null) {
          this.alerta("La sucursal no esta configurada para conduce.","error")
          return;
        }
        if (this.ejecucion == true) {
          this.Facturasventa.fecha = new Date().valueOf()
          this.ejecucion = false
          this.Facturasventa.conduceCredito = this.activaConduceCredito
         /* if(this.activaConduceCredito==false){
            if(this.Facturasventa.valorConduce>this.RedondeoBasico(this.Totales.totalGeneral.total)){
              this.Facturasventa.efectivo=this.Totales.totalGeneral.total
              this.Facturasventa.devuelta=this.RedondeoBasico((this.Facturasventa.valorConduce-this.Totales.totalGeneral.total))
            }else{
                   //this.Facturasventa.efectivo=this.Facturasventa.valorConduce
                   this.Facturasventa.efectivo=this.Totales.totalGeneral.total
            }
           
          }*/

//  _this.$hostname + _this.$hName + "/conduce/save",_this.Facturasventa,
          var FACTURAS_VENTA= JSON.parse(JSON.stringify(this.Facturasventa));
          FACTURAS_VENTA.efectivo= JSON.parse(JSON.stringify(this.Facturasventa.efectivo*MONEDAFACTURATASA));
          FACTURAS_VENTA.tarjeta= JSON.parse(JSON.stringify(this.Facturasventa.tarjeta*MONEDAFACTURATASA));
          FACTURAS_VENTA.cheques= JSON.parse(JSON.stringify(this.Facturasventa.cheques*MONEDAFACTURATASA));
          FACTURAS_VENTA.transferencia= JSON.parse(JSON.stringify(this.Facturasventa.transferencia*MONEDAFACTURATASA));
          FACTURAS_VENTA.cxc= JSON.parse(JSON.stringify(this.Facturasventa.cxc*MONEDAFACTURATASA));
          FACTURAS_VENTA.cupones= JSON.parse(JSON.stringify(this.Facturasventa.cupones*MONEDAFACTURATASA));
          FACTURAS_VENTA.valorConduce= JSON.parse(JSON.stringify(this.Facturasventa.valorConduce*MONEDAFACTURATASA));
          FACTURAS_VENTA.valorNc= JSON.parse(JSON.stringify(this.Facturasventa.valorNc*MONEDAFACTURATASA));
          var _this=this;

          if(this.activaConduceCredito==false){
            if(FACTURAS_VENTA.valorConduce>this.RedondeoBasico(this.Totales.totalGeneral.total)){
              FACTURAS_VENTA.efectivo=this.Totales.totalGeneral.total
              FACTURAS_VENTA.devuelta=this.RedondeoBasico((FACTURAS_VENTA.valorConduce-this.Totales.totalGeneral.total))
            }else{
                   //this.Facturasventa.efectivo=this.Facturasventa.valorConduce
                   FACTURAS_VENTA.efectivo=this.Totales.totalGeneral.total
            }
           
          }else{
                     FACTURAS_VENTA.efectivo=0
                     FACTURAS_VENTA.valorConduce=0
          }

 setTimeout(function(){
          _this.$axios
            .post(
              _this.$hostname + _this.$hName + "/conduce/save",
               FACTURAS_VENTA,
              { headers: _this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              if (_this.deleteFacturaTemporal != null) {
                _this.deleRestaurarFact(_this.deleteFacturaTemporal);
              }
              if(_this.cotizacionAfacturaSelect!=null){
                      _this.ActualizarCotizacionFActurada(_this.cotizacionAfacturaSelect)
                    }
              if (_this.conduceSelect != null) {
                _this.deleteConduce(_this.conduceSelect);
              }
              _this.objTemp = res.data;
              _this.RListFactpendiente = res.data;
              if (localStorage.printerSelect != null) {}
              var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

              var FORMATOIMP= _this.caja.formatoimpresioncontado.trim()
              if(_this.caja.marca){
                FORMATOIMP= _this.caja.formatoImprecionConduce.trim()
              }
    
                var msg = {
                  accion: "print",
                 // formato: _this.caja.formatoimpresioncontado.trim(),
                 formato: FORMATOIMP,
                  impresora: impresora,
                  data: _this.configuraciones,
                  fac: res.data,
                  caja: _this.caja,
                  //   fac:this.ReimprecionFacturaSeleccionada
                };
                _this.imprimir(msg, "conduce");
              
              _this.alerta("Datos salvado correctamente !!!", "bien");
            })
            .catch((error) => {
              if (error.response) {
                _this.alerta(error.response.request.responseText, "error");
              } else {
                _this.alerta(error, "error");
              }
              _this.loading(false)
              _this.ejecucion = true
            });
          }, 600);
          //--

        }
      }
      else {

        var docu = 0;
        if ((this.Facturasventa.cxc*MONEDAFACTURATASA) > 0) {
          docu = this.caja.sucursal.documentofacturacredito
        } else {
          docu = this.caja.sucursal.documentofacturacion
        }

        var link = docu + "/" + this.Facturasventa.tipocomprobante.codigo + "/" + "PV"
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/" + link,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data == true) {

              if (this.ejecucion == true) {
                //  this.Facturasventa.fecha = new Date().toISOString().substr(0, 10)
                var fech = new Date(new Date().toLocaleDateString("en-DO")).toISOString()
                //this.Facturasventa.fecha = new Date().valueOf()

                /*his.loading(false)
                return */

                //_this.$hostname + _this.$hName + "/pv/save", _this.Facturasventa,
                this.ejecucion = false
                this.loading(true)
                this.Facturasventa.fecha = fech

                var FACTURAS_VENTA= JSON.parse(JSON.stringify(this.Facturasventa));
          FACTURAS_VENTA.efectivo= JSON.parse(JSON.stringify(this.Facturasventa.efectivo*MONEDAFACTURATASA));
          FACTURAS_VENTA.tarjeta= JSON.parse(JSON.stringify(this.Facturasventa.tarjeta*MONEDAFACTURATASA));
          FACTURAS_VENTA.cheques= JSON.parse(JSON.stringify(this.Facturasventa.cheques*MONEDAFACTURATASA));
          FACTURAS_VENTA.transferencia= JSON.parse(JSON.stringify(this.Facturasventa.transferencia*MONEDAFACTURATASA));
          FACTURAS_VENTA.cxc= JSON.parse(JSON.stringify(this.Facturasventa.cxc*MONEDAFACTURATASA));
          FACTURAS_VENTA.cupones= JSON.parse(JSON.stringify(this.Facturasventa.cupones*MONEDAFACTURATASA));
          FACTURAS_VENTA.valorConduce= JSON.parse(JSON.stringify(this.Facturasventa.valorConduce*MONEDAFACTURATASA));
          FACTURAS_VENTA.valorNc= JSON.parse(JSON.stringify(this.Facturasventa.valorNc*MONEDAFACTURATASA));
     
              setTimeout(function(){ 
                _this.$axios
                  .post(_this.$hostname + _this.$hName + "/pv/save", FACTURAS_VENTA, {
                    headers: _this.$store.getters.GetheadersAxios,
                  })
                  .then((res) => {
                    if (_this.deleteFacturaTemporal != null) {
                      _this.deleRestaurarFact(_this.deleteFacturaTemporal);
                    }
                    if (_this.conduceSelect != null) {
                      _this.deleteConduce(_this.conduceSelect);
                    }


                    if(_this.cotizacionAfacturaSelect!=null){
                      _this.ActualizarCotizacionFActurada(_this.cotizacionAfacturaSelect)
                    }

                    _this.objTemp = res.data;
                    _this.RListFactpendiente = res.data;
                    _this.alerta("Datos salvado correctamente !!!", "bien");
                    if (localStorage.printerSelect != null) {}
                    var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
                      var msg = {
                        accion: "print",
                        formato: _this.caja.formatoimpresioncontado.trim(),
                        impresora: impresora,
                        data: _this.configuraciones,
                        fac: res.data,
                        caja: _this.caja,
                      };
                      _this.imprimir(msg, "factura");
                    


                  })
                  .catch((error) => {
                    _this.ejecucion = true
                    _this.loading(false)
                  });

                }, 600);

              }



            } else {
              this.alerta("No posee secuencia para la factura que está intentado realizar", "error")
            }
          })
          .catch(error => { });


      }
    },

    // Esperando a que el usuario haga clic en el botón para inicial el proceso de cobro de factura.
    async realizarCobro() {

      var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
      var MONEDAFACTURATASA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa)

      if (this.caja != null) {
        if (this.caja.cobradora == false) {
          this.alerta("En esta caja no esta permitido cobrar", "error");
          return 0;
        }
      } else {
        this.alerta("Seleccione una caja", "error");
        return 0;
      }
var totalTotalFac=this.RedondeoBasico((this.Totales.totalGeneral.subtotal+this.Totales.totalGeneral.itbis))

      var SumaTotal = this.RedondeoBasico((this.Facturasventa.efectivo +this.Facturasventa.tarjeta + this.Facturasventa.transferencia + this.Facturasventa.cheques + this.Facturasventa.cxc
        + this.Facturasventa.cupones + this.Facturasventa.puntos + this.Facturasventa.valorConduce + this.Facturasventa.valorNc)*MONEDAFACTURATASA)
      
        if (this.CoberturaSeguro.cobertura > 0 && this.CoberturaSeguro.cobertura==totalTotalFac) {

        }else{
          if(SumaTotal==0){
  this.alerta("Dijite una forma de pago", "error");
        return 0; 
}
        }

if(this.clienteFactura!=null && this.Facturasventa.cxc>0 ){
  if (this.clienteFactura.statuscliente != null) {
          if (this.clienteFactura.statuscliente == 2 || this.clienteFactura.statuscliente == 3) {
            this.alerta("Este cliente esta suspendido o cancelado, no puede facturarle a crédito", "error");
            return
          }
        }
}

      var vend = this.$store.getters.GetConfiguracionGeneral.configPv.vendedorobligatorio
      var Medic = this.$store.getters.GetConfiguracionGeneral.configPv.usarMedico
      var comentarioObligatorio=this.$store.getters.GetConfiguracionGeneral.configPv.comentarioObligatorio
     
      if (vend == true && this.Facturasventa.vendedor == null) {
        this.alerta("Debe seleccionar un vendedor", "error");
        return 0;
      }


      if (Medic == true && this.SelectMedicoFactura == null) {
        this.alerta("Debe seleccionar un medico", "error");
        return 0;
      }


      if (comentarioObligatorio == true && this.Facturasventa.comentario.trim().length==0 ) {
        this.alerta("Debe colocar un comentario a esta factura", "error");
        this.OpenComentarioFactura()
        return 0;
      }

if(this.Totales.totalGeneral.cobertura>0 && this.clienteFactura.ars==null ){
  this.alerta("Debe asignarle una ars a  el cliente seleccionado.", "error");
  return 0 ;
}

var QUITAR_DESC=this.$store.getters.GetConfiguracionGeneral.configPv.quitarDescuentoClienteCredito
if(QUITAR_DESC!=null && QUITAR_DESC==true){ 
if((this.Facturasventa.cxc*MONEDAFACTURATASA)>0 && this.Totales.totalGeneral.descuento>0){
      this.dialogNotifQuitarDescuentoCliente=true
      return
      }
    }


  

// validar el balance de cliente punto preferencial
var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true && this.Facturasventa.puntos>0 && this.clienteFactura != null){


        this.$axios.get(this.$hostname + this.$hName + "/clientes/find/"+this.clienteFactura.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          
          this.clienteFactura.balancePuntos=this.RedondeoBasico(res.data.balancePuntos)
          if(res.data.hasOwnProperty("codigo")){
            if(this.Facturasventa.puntos > this.RedondeoBasico(res.data.balancePuntos)){
          this.alerta(`Los puntos digitado (${currencyFormatter(this.Facturasventa.puntos)}) sobre pasa a los puntos del cliente (${currencyFormatter(res.data.balancePuntos)}) `, "error")
             return
            }

            this.loading(true)
            var _this = this;
            setTimeout(function () {
            _this.ContrealizarCobro();
            }, 500);

          }else{
            this.alerta("error validando los punto acumulado del cliente", "error");
          }
          
        })
        .catch(error => { this.alerta("error validando los punto acumulado del cliente", "error") });
        }else{

          this.loading(true)
      // const result = await this.ActualizarProductPago();
      // this.loading(false)
      var _this = this;
      setTimeout(function () {
        _this.ContrealizarCobro();
      }, 2000);

        }
    },


    // Comprobando si el pago es mayor al total de la factura.
    async ContrealizarCobro() {
      var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
      var MONEDAFACTURATASA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa)
      //var moneda=this.$store.getters.GetOtrasConfigPv.moneda
      if (this.RProductoCompra.length <= 0) {
        this.alerta("agrege al menos un producto a la lista", "error");
        return 0;
      }

      if (this.UsarLote == true) {
        var ObjRow = this.RProductoCompra.filter((ee) => ee.lote.length == 0);
        if (ObjRow.length > 0) {
          var msj = `Esta utilizando la configuración de lote, y este producto:#${ObjRow[0].indx + 1}-(${ObjRow[0].descripcion}),no tiene lote asignado`
          this.alerta(msj, "error");
        }

      }

      if (this.devuelta < 0) {
        this.alerta(
          "El valor a pagar es menor que el valor de la factura",
          "error"
        );
        return 0;
      }

      if (this.Totales.totalGeneral.total == 0 && this.Totales.totalGeneral.cobertura == 0) {
        return 0;
      }
      //RedondeoBasico
      //currencyFormatter2
      //if(this.Facturasventa.efectivo<=0){}
      if (this.RedondeoBasico(this.Facturasventa.tarjeta*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' Tarjeta ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cheques*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' Cheques ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.transferencia*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' Transferencia ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cxc*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' Crédito(cxc) ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.cupones*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' cupones ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      if (this.RedondeoBasico(this.Facturasventa.puntos*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' puntos ' sobre pasa el total de la factura  ", "error")
        return 0;
      }

      /*if (this.RedondeoBasico(this.Facturasventa.valorConduce*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' valor Conduce ' sobre pasa el total de la factura  ", "error")
        return 0;
      }*/

      if (this.RedondeoBasico(this.Facturasventa.valorNc*MONEDAFACTURATASA) > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("La forma de pago ' Nota de crédito ' sobre pasa el total de la factura  ", "error")
        return 0;
      }
      //this.Facturasventa.valorConduce + 
      var SumaTotal = this.RedondeoBasico(
       ( this.Facturasventa.tarjeta + this.Facturasventa.transferencia + this.Facturasventa.cheques + this.Facturasventa.cxc
        + this.Facturasventa.cupones+ this.Facturasventa.puntos + this.Facturasventa.valorNc)*MONEDAFACTURATASA
        )

      if (SumaTotal > this.RedondeoBasico(this.Totales.totalGeneral.total)) {
        this.alerta("Las formas de pago sobre pasa el total de la factura  ", "error")
        return 0;
      }

      var MODALIDAD_CLI_PRE=this.$store.getters.GetConfiguracionGeneral.configPv.modalidadClientePreferencial
      if(MODALIDAD_CLI_PRE==true && this.Facturasventa.puntos>0 && this.clienteFactura != null){
       
        if(this.Facturasventa.puntos > this.clienteFactura.balancePuntos){
          this.alerta(`Los puntos digitado (${currencyFormatter(this.Facturasventa.puntos)}) sobre pasa a los puntos del cliente (${currencyFormatter(this.clienteFactura.balancePuntos)}) `, "error")
          return
        }


        }



      if (this.activaConduceCredito == true) {

        if (this.clienteFactura == null) {
          this.alerta("Para hacer esta operación necesita elegir un cliente a credito", "error");
          return 0;
        } else {
          if (this.clienteFactura.diascredito == null || this.clienteFactura.diascredito == 0) {
            this.alerta("Para hacer esta operación necesita elegir un cliente a credito", "error");
            return 0;
          }
        }

        var CONDUC_DOCUM = this.$store.getters.GetConfiguracionGeneral.configCxc.documentoVenta

        if (CONDUC_DOCUM == null) {
          this.alerta("No tiene la configuración necesaria para hacer esta operación", "error");
          return 0;
        }

      }



      /*this.Facturasventa.efectivo*/
// valirdar cliente credito y si contiene credito
      if (this.Facturasventa.cxc*MONEDAFACTURATASA > 0 && this.CoberturaSeguro.cobertura==0 ) {
        if (
          this.clienteFactura.hasOwnProperty("suspensiondiascredito") &&
          this.clienteFactura.suspensiondiascredito != null &&
          this.clienteFactura.suspensiondiascredito == true
        ) {
          this.alerta("El Cliente Esta suspeniddo", "error");
          return 0;
        }

        if (this.clienteFactura.diascredito == 0) {
          this.alerta(
            "El cliente seleccionado no es un cliente a credito",
            "error"
          );
          return 0;
        } else {
          if (
            this.clienteFactura.limitecredito == null ||
            this.clienteFactura.limitecredito == 0
          ) {

                  if(this.contFactExcedeLimitCredito==false){
                    this.funcionValidaPermisoPv('AccFactExcedeLimitCredito')
                    this.alerta("El Cliente excede limite de credito ", "error");
                    return 0;
                  }

            
          } else {
            var balancecliente = 0;
            if (
              this.clienteFactura.balancecliente != null &&
              this.clienteFactura.balancecliente.balance > 0
            ) {
              balancecliente = this.clienteFactura.balancecliente.balance;
            }

            //var moneda=this.$store.getters.GetOtrasConfigPv.moneda
            //(App.client.selectedClient.balancecliente.balance / _this.util.exchangeRate) +
            //(_this.pago.creditValue / _this.util.exchangeRate) > App.client.selectedClient.limiteCredito)

            //---------validando balance del cliente
            var cliBalanc =
              balancecliente / this.Monedafactura.tasa + this.Facturasventa.cxc;
            if (cliBalanc > this.clienteFactura.limitecredito) {
              if(this.contFactExcedeLimitCredito==false){
                this.funcionValidaPermisoPv('AccFactExcedeLimitCredito')
               this.alerta("El Cliente excede limite de credito ", "error");
              return 0;
              }
            }
          }
        }
      }


// valirdar cliente credito y si contiene credito y con cobertura
 if (this.Facturasventa.cxc*MONEDAFACTURATASA > 0 && this.CoberturaSeguro.cobertura > 0 ) {
        var CLIENTE_VALIDAR_CREDITO=null;
        //this.clienteTempCredito
        if(this.clienteTempCredito==null){
          CLIENTE_VALIDAR_CREDITO= JSON.parse(JSON.stringify(this.clienteFactura))
          this.clienteTempCredito= JSON.parse(JSON.stringify(this.clienteFactura))
          
        }else{
          CLIENTE_VALIDAR_CREDITO= JSON.parse(JSON.stringify(this.clienteTempCredito))
        }

        if (
          CLIENTE_VALIDAR_CREDITO.hasOwnProperty("suspensiondiascredito") &&
          CLIENTE_VALIDAR_CREDITO.suspensiondiascredito != null &&
          CLIENTE_VALIDAR_CREDITO.suspensiondiascredito == true
        ) {
          this.alerta("El Cliente seleccionado Esta suspeniddo", "error");
          this.dialogSelectClienteTempCredito=true
          this.filtroTipoCliente=3
          this.RedireccionFocus('RefTextFieldBuscaClienteCredito')
          return 0;
        }

        if (CLIENTE_VALIDAR_CREDITO.diascredito == 0) {
          this.alerta(
            "El cliente seleccionado no es un cliente a credito",
            "error"
          );
          this.dialogSelectClienteTempCredito=true
          this.filtroTipoCliente=3
          this.RedireccionFocus('RefTextFieldBuscaClienteCredito')
          return 0;
        } else {
          if (
            CLIENTE_VALIDAR_CREDITO.limitecredito == null ||
            CLIENTE_VALIDAR_CREDITO.limitecredito == 0
          ) {
            this.alerta("El Cliente excede limite de credito ", "error");
            this.dialogSelectClienteTempCredito=true
            this.filtroTipoCliente=3
            this.RedireccionFocus('RefTextFieldBuscaClienteCredito')
            return 0;
          } else {
            var balancecliente = 0;
            if (
              CLIENTE_VALIDAR_CREDITO.balancecliente != null &&
              CLIENTE_VALIDAR_CREDITO.balancecliente.balance > 0
            ) {
              balancecliente = CLIENTE_VALIDAR_CREDITO.balancecliente.balance;
            }

            //var moneda=this.$store.getters.GetOtrasConfigPv.moneda
            //(App.client.selectedClient.balancecliente.balance / _this.util.exchangeRate) +
            //(_this.pago.creditValue / _this.util.exchangeRate) > App.client.selectedClient.limiteCredito)

            //---------validando balance del cliente
            var cliBalanc =
              balancecliente / this.Monedafactura.tasa + this.Facturasventa.cxc;
            if (cliBalanc > CLIENTE_VALIDAR_CREDITO.limitecredito) {
              this.alerta("El Cliente excede limite de credito ", "error");
              this.dialogSelectClienteTempCredito=true
              this.filtroTipoCliente=3
              this.RedireccionFocus('RefTextFieldBuscaClienteCredito')
              return 0;
            }
          }
        }


        this.clienteTempCredito=JSON.parse(JSON.stringify(CLIENTE_VALIDAR_CREDITO))

        
      }





      if (this.Facturasventa.valorNc*MONEDAFACTURATASA > 0) {

        if (this.selectedListFPagoNC.length == 0) {
          this.alerta("Debe seleccionar una nota de crédito para poder cobrar con nota de crédito", "error");
          return 0;
        }

        if (this.Facturasventa.valorNc*MONEDAFACTURATASA > this.Totales.totalGeneral.total) {
          this.alerta("El valor de pago nota de crédito es mayor al valor de la factura", "error");
          return 0;
        }

      }



      // && this.Facturasventa.tipoTarjeta==null
      // this.Facturasventa.numtar

      // ------- pagos con tarjeta de credito
      if (this.Facturasventa.tarjeta*MONEDAFACTURATASA > 0) {
        if (!this.$refs.formTarjetaFormPago.validate()) {
          this.alerta("Los datos de la tarjeta son requeridos ", "error");
          this.OpenPanelFormaPagoTarjeta();
          return 0;
        }

      var CONFDESCTARJ=this.$store.getters.GetConfiguracionGeneral.configPv.descuentoTarjeta
      if(CONFDESCTARJ>0 && this.Totales.totalGeneral.descuento>0 ){ 
          //  po=((desc/subtotal)*100)
            var po=this.RedondeoBasico(((this.Totales.totalGeneral.descuento/this.Totales.totalGeneral.subtotal)*100))
            if(po>CONFDESCTARJ){
              this.alerta("El descuento de esta factura sobre pasa el por ciento de descuento con tarjeta.", "error");
              return
            }
          
        }
       
        var lccaja = JSON.parse(localStorage.caja);
        if (lccaja.veryFoms == true) {

          if (this.tipoTarjeta.azul == true) {
            return 0;
          }
          if (this.tipoTarjeta.cardnet == true) {
            this.validarcarnet();
            return 0;
          }
          this.PostrealizarCobro();
        } else {
          this.PostrealizarCobro();
        }
      } else {
        this.PostrealizarCobro();
      }
    },

    // Hacer una solicitud de publicación al servidor.
    async validarcarnet() {
      this.loading(true)
      var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
      var MONEDAFACTURATASA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa)
      //http://localhost:22105/api/Prueba
      // this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>( this.Rmoneda=res.data))

      var num = "";
      num = ((this.Facturasventa.tarjeta*MONEDAFACTURATASA).toFixed(2) + "").replace(".", "");

      var obj = {
        amount: num,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: 0,
        referenceNumber: 0,
      };

      //var stobj='{"Status":"Successful","Host":{"Value":6,"Description":"Credit"},"Mode":{"Value":"C@5","Description":"Sale with CHIP(EMV)"},"Card":{"Product":"Mastercard","CardNumber":"534217******5024","HolderName":"SCOTIABANK/TARJETAHABIENTE"},"Transaction":{"AuthorizationNumber":"007656","Reference":2,"RetrievalReference":214611000062,"DataTime":"26/5/2022 11:04:40 a. m.","ApplicationIdentifier":"A0000000041010","LoyaltyDeferredNumber":null,"TID":null},"Batch":22,"TerminalID":"80029126","MerchantID":"349011300","Acquired":"CardNET","Reserved":null,"Signature":null}'

      this.$axios
        .post("http://localhost:22105/api/ProcessNormalSale", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.loading(false)
          var e = JSON.parse(res.data);
          e.tipo = "Carnet";

          if (e.Status == "Successful") {
            this.Facturasventa.numtar = e.Card.CardNumber;
            this.Facturasventa.datoTarjeta = JSON.stringify(e);

            //   App.checkIn();
            this.alerta("Transacción finalizada correctamente", "bien2");
            var _this = this;
            setTimeout(function () {
              _this.PostrealizarCobro();
            }, 3000);
          }

          if (e.Status == "Failed") {
            this.alerta("Transacción cancelada", "error");
          }
        })
        .catch((error) => {
          this.alerta("Transacción cancelada", "error");
          this.loading(false)
        });
    },

    // Hacer una solicitud de publicación al servidor enviando y comprobando los datos de carnet .
    Anulacioncarnet() {
      // var bool=true

      // if(bool==true){
      //   this.ProcessFacturaAnula()
      //   return 0;
      // }
      this.loading(true)
      var obj = JSON.parse(this.AnulacionFacturaSeleccionada.datoTarjeta);

      var objinf = {
        amount: 0,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: obj.Host.Value,
        referenceNumber: obj.Transaction.Reference,
      };

      this.$axios
        .post("http://localhost:22105/api/ProcessAnnulment", objinf, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.loading(false)
          var e = JSON.parse(res.data);

          if (e.Status == "Successful") {
            obj.TarjetaAnulada = e;
            this.AnulacionFacturaSeleccionada.datoTarjeta = JSON.stringify(obj);
            var _this = this;
            setTimeout(function () {
              _this.ProcessFacturaAnula();
            }, 3000);
          }

          if (e.Status == "Failed") {
            this.alerta("Anulacion cancelada", "error");
          }
        })
        .catch((error) => this.alerta("Anulacion cancelada", "error"));

      //AnulafacturatarjetacreditoCar
    },

    // Hacer una solicitud  al servidor para procesar el cierre del veriphone.
    async validarCierreCarnet() {
      this.loading(true)
      //http://localhost:22105/api/Prueba
      // this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>( this.Rmoneda=res.data))

      var num = "";
      num = (this.Facturasventa.tarjeta.toFixed(2) + "").replace(".", "");

      var obj = {
        amount: 0,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: 0,
        referenceNumber: 0,
      };

      //var stobj='{"Status":"Successful","Host":{"Value":6,"Description":"Credit"},"Mode":{"Value":"C@5","Description":"Sale with CHIP(EMV)"},"Card":{"Product":"Mastercard","CardNumber":"534217******5024","HolderName":"SCOTIABANK/TARJETAHABIENTE"},"Transaction":{"AuthorizationNumber":"007656","Reference":2,"RetrievalReference":214611000062,"DataTime":"26/5/2022 11:04:40 a. m.","ApplicationIdentifier":"A0000000041010","LoyaltyDeferredNumber":null,"TID":null},"Batch":22,"TerminalID":"80029126","MerchantID":"349011300","Acquired":"CardNET","Reserved":null,"Signature":null}'

      this.$axios
        .post("http://localhost:22105/api/ProcessClose", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.loading(false)
          var e = JSON.parse(res.data);
          var _this = this;
          if (e.Status == "Successful") {
            this.CierresCajas.datoTarjeta = JSON.stringify(e);
            this.alerta("Cierre finalizada correctamente", "bien2");
            setTimeout(function () {
              _this.PostSalvarCierreCaja(true);
            }, 3000);
          }

          if (e.Status == "Failed") {
            if (
              e.Messages[0] ==
              "La cadena de entrada no tiene el formato correcto."
            ) {
              setTimeout(function () {
                _this.PostSalvarCierreCaja(false);
              }, 3000);
            } else {
              this.alerta("Transacción cancelada", "error");
            }
          }
        })
        .catch((error) => {
          this.alerta("Transacción cancelada", "error");
          this.loading(false)
        });
    },

    // Creando un nuevo objeto llamado domicilio y asignándole valores.
     ConstruirDomicilio(datos, devuelta) {
    var TIPOCOMPROBANTE=0
      if(datos.tipocomprobante!=null){
        TIPOCOMPROBANTE=datos.tipocomprobante.codigo
      }else{
        TIPOCOMPROBANTE=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion.codigo
      }
    

      var domicilio = {
        codigo: 0,
        direccion: this.domiciliofactura.direccion,
        telefono: this.domiciliofactura.telefonoPrincipal,
        valor: datos.valor,
        itbis: datos.valorimpuesto,
        descuento: datos.valordescuento,
        diascredito: 0,
        tipocomprobante: TIPOCOMPROBANTE,
        tasa: datos.tasa,
        facturado: false,
        devuelta: devuelta,
        fecha: datos.fecha,
        moneda: datos.moneda.codigo,
        devuelto: false,
        numerocierre: datos.numerocierre,
        caja: datos.caja.codigo,
        usuario: "",
        sucursal: datos.sucursal.codigo,
        borrado: false,
        horaPedido: null,
        horaEntrega: null,
        domciliosDetalleList: this.crearDetalle(datos.facturasventaDetalleList),
        cliente: datos.cliente,
        facturasventa: null,
        mensajero: null,
      };
      this.Facturasventa.domicilio = domicilio;

    },
    // Crear una nueva lista de objetos a partir de los datos pasados.
     crearDetalle(datos) {
      var List = [];
      
    // (async () => {
       datos.forEach((l) => {
    /*await Promise.all(datos.map(async (l) => { 
         }));*/
        var detalle = {
          domciliosDetallePK: { codigo: 0, producto: 0, unidad: 0 },
          precio: l.precio,
          cantidad: l.cantidad,
          valordescuento: l.valordescuento,
          impuesto: l.impuesto!=null&&l.impuesto.hasOwnProperty('codigo')?l.impuesto.codigo:null,
          valorimpuesto: l.valorimpuesto,
          valorcupon: 0.0,
          codigocupon: "",
          descuento: l.descuento,
          borrado: false,
          productos: l.productos,
          unidades: l.unidades,
          vendedor: l.vendedor,
        };
        List.push(detalle);
      });
     //  })()
      return List;
    },
    ChangeReciboIngresoValorPorcc() {
      this.CalcularReciboIngreso();
    },

    reciboIngresoKeyCtrl(e){
      //key:"Ctrl":17
        if (e.keyCode ==17 ) {
          if(this.ReciboIngresoFactPendHist == true){
             this.SalvarReciboIngresoFacPen()
          }
          
      }


    },

    ReciboEnterEfectivo() {
      var Valortotal =
        this.ReciboIngresoFactura.efectivo +
        this.ReciboIngresoFactura.tarjeta +
        this.ReciboIngresoFactura.cheque +
        this.ReciboIngresoFactura.transferencia;

      var Objefc = 0;
      if (Valortotal < this.Totales.ReciboIngreso.abono) {
        Objefc = JSON.parse(
          JSON.stringify(this.Totales.ReciboIngreso.abono - Valortotal)
        );
      }
      this.ReciboIngresoFactura.efectivo = Objefc;
    },

    ReciboEnterTarjeta() {
      var Valortotal =
        this.ReciboIngresoFactura.efectivo +
        this.ReciboIngresoFactura.tarjeta +
        this.ReciboIngresoFactura.cheque +
        this.ReciboIngresoFactura.transferencia;

      var Objefc = 0;
      if (Valortotal < this.Totales.ReciboIngreso.abono) {
        Objefc = JSON.parse(
          JSON.stringify(this.Totales.ReciboIngreso.abono - Valortotal)
        );
      }
      this.ReciboIngresoFactura.tarjeta = Objefc;
    },

    ReciboEnterCheques() {
      var Valortotal =
        this.ReciboIngresoFactura.efectivo +
        this.ReciboIngresoFactura.tarjeta +
        this.ReciboIngresoFactura.cheque +
        this.ReciboIngresoFactura.transferencia;

      var Objefc = 0;
      if (Valortotal < this.Totales.ReciboIngreso.abono) {
        Objefc = JSON.parse(
          JSON.stringify(this.Totales.ReciboIngreso.abono - Valortotal)
        );
      }
      this.ReciboIngresoFactura.cheque = Objefc;

      this.calcularToTalRecIng()
    },

    ReciboEnterTransferencia() {
      var Valortotal =
        this.ReciboIngresoFactura.efectivo +
        this.ReciboIngresoFactura.tarjeta +
        this.ReciboIngresoFactura.cheque +
        this.ReciboIngresoFactura.transferencia;

      var Objefc = 0;
      if (Valortotal < this.Totales.ReciboIngreso.abono) {
        Objefc = JSON.parse(
          JSON.stringify(this.Totales.ReciboIngreso.abono - Valortotal)
        );
      }
      this.ReciboIngresoFactura.transferencia = Objefc;
      this.calcularToTalRecIng()
    },

    //Agrega el recibo combleto
    SalvarReciboIngresoFacPen() {
      if (this.caja != null) {
          if (this.caja.cobradora == false) {
            this.alerta("En esta caja no esta permitido cobrar", "error");
            return 0;
          }
        } else {
          this.alerta("Seleccione una caja", "error");
          return 0;
        }

        this.Totales.ReciboIngreso.pendiente=this.RedondeoBasico(this.Totales.ReciboIngreso.pendiente)
        this.Totales.ReciboIngreso.abono=this.RedondeoBasico(this.Totales.ReciboIngreso.abono)
        this.ReciboIngreso.abono=this.RedondeoBasico(this.ReciboIngreso.abono)
      if (this.ReciboIngreso.abono > this.Totales.ReciboIngreso.pendiente) {
        this.alerta("El valor a abonar es mayor que el valor pendiente.", "error");
        return;
      }
   
      if (this.Totales.ReciboIngreso.abono == 0) {
        this.alerta(
          "Debe de abonar en al menos una factura pendiente",
          "error"
        );
        return 0;
      }

      var Valortotal =
        this.ReciboIngresoFactura.efectivo +
        this.ReciboIngresoFactura.tarjeta +
        this.ReciboIngresoFactura.cheque +
        this.ReciboIngresoFactura.transferencia;

      if (Valortotal < this.Totales.ReciboIngreso.abono) {
        this.alerta(
          "El valor a pagar es menor que el valor abonado a la factura",
          "error"
        );
        return 0;
      }

      /*   if (Valortotal > this.Totales.ReciboIngreso.abono) {
           this.alerta(
             "El valor a pagar debe ser igual al valor abonado a la factura",
             "error"
           );
           return 0;
         }*/


      //if(this.ReciboIngresoFactura.efectivo<=0){}
      if (this.ReciboIngresoFactura.cheque > this.Totales.ReciboIngreso.abono) {
        this.alerta("La forma de pago ' Cheque ' sobre pasa el monto ha abonar de la factura  ", "error")
        return 0;
      }

      if (this.ReciboIngresoFactura.transferencia > this.Totales.ReciboIngreso.abono) {
        this.alerta("La forma de pago ' Transferencia ' sobre pasa el monto ha abonar de la factura  ", "error")
        return 0;
      }

      if (this.ReciboIngresoFactura.tarjeta > this.Totales.ReciboIngreso.abono) {
        this.alerta("La forma de pago ' Tarjeta ' sobre pasa el monto ha abonar de la factura  ", "error")
        return 0;
      }


      var SumaTotal = (this.ReciboIngresoFactura.cheque + this.ReciboIngresoFactura.transferencia + this.ReciboIngresoFactura.tarjeta)

      if (SumaTotal > this.Totales.ReciboIngreso.abono) {
        this.alerta("Las formas de pago sobre pasa el monto ha abonar de la factura  ", "error")
        return 0;
      }



      if (this.ReciboIngresoFactura.tarjeta > 0) {
        if (!this.$refs.formRecibTarjetaFormPago.validate()) {
          this.alerta("Los datos de la tarjeta son requeridos ", "error");
          this.OpenPanelReciboFormaPago('Tarjeta')
          return 0;
        }

        var lccaja = JSON.parse(localStorage.caja);
        if (lccaja.veryFoms == true) {

          if (this.ReciboIngresoFactura.tipoTarjeta.azul == true) {
            return 0;
          }
          if (this.ReciboIngresoFactura.tipoTarjeta.cardnet == true) {
            this.validarcarnetReciboIngreso();
            return 0;
          }
          this.FinalizarSalvarReciboIngresoFacPen();
        } else {
          this.FinalizarSalvarReciboIngresoFacPen();
        }

      } else {
        this.FinalizarSalvarReciboIngresoFacPen();
      }

    

      //this.FinalizarSalvarReciboIngresoFacPen()

      //this.alerta("Dato guardado correctamente!", "bien2")
    },
    // Hacer una solicitud  api  para verificar y procesar transacion tarjeta carnet.
    validarcarnetReciboIngreso() {
      this.loading(true)
      //http://localhost:22105/api/Prueba
      // this.$axios.get(this.$hostname+this.$hName+'/monedas/findallActivos/',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>( this.Rmoneda=res.data))

      var num = "";
      num = (this.ReciboIngresoFactura.tarjeta.toFixed(2) + "").replace(".", "");

      var obj = {
        amount: num,
        tax: 0,
        otherTaxes: 0,
        invoiceId: 0,
        quantyOfPayments: 0,
        host: 0,
        referenceNumber: 0,
      };

      //var stobj='{"Status":"Successful","Host":{"Value":6,"Description":"Credit"},"Mode":{"Value":"C@5","Description":"Sale with CHIP(EMV)"},"Card":{"Product":"Mastercard","CardNumber":"534217******5024","HolderName":"SCOTIABANK/TARJETAHABIENTE"},"Transaction":{"AuthorizationNumber":"007656","Reference":2,"RetrievalReference":214611000062,"DataTime":"26/5/2022 11:04:40 a. m.","ApplicationIdentifier":"A0000000041010","LoyaltyDeferredNumber":null,"TID":null},"Batch":22,"TerminalID":"80029126","MerchantID":"349011300","Acquired":"CardNET","Reserved":null,"Signature":null}'

      this.$axios
        .post("http://localhost:22105/api/ProcessNormalSale", obj, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.loading(false)
          var e = JSON.parse(res.data);
          e.tipo = "Carnet";

          if (e.Status == "Successful") {
            this.ReciboIngresoFactura.numtar = e.Card.CardNumber;
            this.ReciboIngreso.datoTarjeta = JSON.stringify(e);

            //   App.checkIn();
            this.alerta("Transacción finalizada correctamente", "bien2");
            var _this = this;
            setTimeout(function () {
              _this.FinalizarSalvarReciboIngresoFacPen();
            }, 3000);
          }

          if (e.Status == "Failed") {
            this.alerta("Transacción cancelada", "error");
          }
        })
        .catch((error) => {
          this.alerta("Transacción cancelada", "error");
          this.loading(false)
        });

    },

    // Guardar un recibo en la base de datos.
  async FinalizarSalvarReciboIngresoFacPen() {

      var lccaja = JSON.parse(localStorage.caja);
      this.ReciboIngreso.formaPago = JSON.parse(
        JSON.stringify(this.ReciboIngresoFactura)
      );
      var ObjRow = this.RListFactpendiente.filter((e) => e.abono > 0);
      // console.log(new Date().getTime())
// ObjRow.forEach(element => {  });
      await Promise.all(ObjRow.map(async (element) => { 
        if (element.facturasventa != null) {
          delete element.facturasventa["horaFactura"]
          /*  console.log(element.facturasventa.horaFactura.getTime())
            element.facturasventa.horaFactura=new Date(element.facturasventa.horaFactura)*/
        }
}));
      
        
    

      this.ReciboIngreso.FactList = JSON.parse(JSON.stringify(ObjRow));
      this.FactPendienteSeleccionado = null;
      //cajero:this.$store.getters.GetdatosInfUsu.usuario.nombre,
      //moneda: this.$store.getters.GetConfiguracionGeneral.config.monedabase.descripcion,
      var obj = {
        banco: null,
        caja: this.caja,
        cheque: this.ReciboIngreso.formaPago.cheque,
        cliente: this.clienteFactura,
        concepto: null,
        contabilidad: null,
        descuento: this.Totales.ReciboIngreso.descuento,
        documentocxc: lccaja.sucursal.documentorecibo,
        efectivo: this.ReciboIngreso.formaPago.efectivo,
        fecha: new Date(),
        moneda: this.Monedafactura,
        numeroTarjeta: this.ReciboIngreso.formaPago.numtar,
        numeroCheque: this.ReciboIngreso.formaPago.numeroCheque,
        bancoTipo: this.ReciboIngreso.formaPago.hasOwnProperty("bancoCheque") && this.ReciboIngreso.formaPago.bancoCheque != null ? this.ReciboIngreso.formaPago.bancoCheque.codigo : null,
        recibosdeingresoPK: { secuencia: 0 },
        recibosingresoDetalleList: ObjRow,
        referencia: null,
        tarjeta: this.ReciboIngreso.formaPago.tarjeta,
        tarjetaTipo: this.ReciboIngreso.formaPago.hasOwnProperty("tipoTarjeta") && this.ReciboIngreso.formaPago.tipoTarjeta != null ? this.ReciboIngreso.formaPago.tipoTarjeta.codigo : null,
        tasa: this.Monedafactura.tasa,
        tipoDescuento: 1,
        transferencia: this.ReciboIngreso.formaPago.transferencia,
        valor: this.Totales.ReciboIngreso.abono,
        vendedor: null,
        usuario: this.$store.getters.GetOtrasConfigPv.cajero,
        totalRetencion: this.Totales.ReciboIngreso.retencion,
        totalIsr: this.Totales.ReciboIngreso.isr,
        datoTarjeta: this.ReciboIngreso.datoTarjeta,
        vendedor:this.Facturasventa.vendedor
      };


      var _this=this;
 setTimeout(function(){ 


      if (_this.ejecucion == true) {
        _this.ejecucion = false;
        _this.loading(true)

        _this.$axios
          .post(_this.$hostname + _this.$hName + "/reciboingreos/save", obj, {
            headers: _this.$store.getters.GetheadersAxios,
          })
          .then((res) => {

            _this.dialogReciboIngreso = false;
            _this.alerta("Dato guardado correctamente", "bien");
            if (localStorage.printerSelect != null) {}
            var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
              var msg = {
                accion: "print",
                formato: _this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: _this.configuraciones,
                ingreso: res.data,
                caja: lccaja,
                cajero: _this.$store.getters.GetOtrasConfigPv.cajero,
              };

              _this.imprimir(msg, "recibo");
            
          })
          .catch((error) => { _this.loading(true), _this.alerta(error, "error") });
      }
    }, 300);


    },

    //Agrega el abonado a la factura seleccionado
    async SalvarReciboIngresoSelctAbono() {
      //volviendo factura  abono=0
      if (this.FactPendienteSeleccionado.abono == 0) {
        this.Totales.ReciboIngreso.abono = 0;
        if (this.FactPendienteSeleccionado.facturasventa != null) {
          this.FactPendienteSeleccionado.pendientedespues = JSON.parse(
            JSON.stringify(
              this.FactPendienteSeleccionado.facturasventa.pendiente
            )
          );
        }

        if (this.FactPendienteSeleccionado.notadebitoclientes != null) {
          this.FactPendienteSeleccionado.pendientedespues = JSON.parse(
            JSON.stringify(
              this.FactPendienteSeleccionado.notadebitoclientes.pendiente
            )
          );
        }

        // await this.RListFactpendiente.forEach((elet) => {});
        await Promise.all(this.RListFactpendiente.map(async (elet) => { 
          if (elet.indx == this.FactPendienteSeleccionado.indx) {
            elet.abono = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.abono)
            );
            elet.pendientedespues = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.pendientedespues)
            );
          }
          
          this.Totales.ReciboIngreso.abono += this.RedondeoBasico(elet.abono);
        }));
      }

      //calculando abono=0
      if (this.FactPendienteSeleccionado.abono > 0) {
        this.Totales.ReciboIngreso.abono = 0;

        if (this.FactPendienteSeleccionado.facturasventa != null) {
          var abono = JSON.parse(
            JSON.stringify(this.FactPendienteSeleccionado.abono)
          );
          var pendiente = JSON.parse(
            JSON.stringify(
              this.FactPendienteSeleccionado.facturasventa.pendiente
            )
          );
          this.FactPendienteSeleccionado.facturasventa.pendiente;

          if (abono >= pendiente) {
            this.FactPendienteSeleccionado.abono = pendiente;
            this.FactPendienteSeleccionado.pendientedespues = 0;
          } else {
            this.FactPendienteSeleccionado.facturasventa.pendiente;
            this.FactPendienteSeleccionado.pendientedespues = pendiente - abono;
          }
        }

        if (this.FactPendienteSeleccionado.notadebitoclientes != null) {
          var abono = JSON.parse(
            JSON.stringify(this.FactPendienteSeleccionado.abono)
          );
          var pendiente = JSON.parse(
            JSON.stringify(
              this.FactPendienteSeleccionado.notadebitoclientes.pendiente
            )
          );
          this.FactPendienteSeleccionado.notadebitoclientes.pendiente;

          if (abono >= pendiente) {
            this.FactPendienteSeleccionado.abono = pendiente;
            this.FactPendienteSeleccionado.pendientedespues = 0;
          } else {
            this.FactPendienteSeleccionado.notadebitoclientes.pendiente;
            this.FactPendienteSeleccionado.pendientedespues = pendiente - abono;
          }
        }

        //var list = JSON.parse(JSON.stringify(this.RListFactpendiente))

      //  await this.RListFactpendiente.forEach((elet) => {});
      await Promise.all(this.RListFactpendiente.map(async (elet) => { 
          if (elet.indx == this.FactPendienteSeleccionado.indx) {
            elet.abono = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.abono)
            );
            elet.pendientedespues = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.pendientedespues)
            );
          }

          this.Totales.ReciboIngreso.abono += this.RedondeoBasico(elet.abono);
        }));
      }

      this.FactPendienteSeleccionado = null;
    },

    // Cálculo de la cantidad de dinero que está siendo pagado por el cliente en el recibo de ingreso.
    async SalvarReciboIngresoSelctAbono1() {
          this.Totales.ReciboIngreso.abono = 0;
          this.Totales.ReciboIngreso.descuento = 0;
          this.Totales.ReciboIngreso.retencion = 0;
      if (this.FactPendienteSeleccionado.facturasventa != null) {
        this.FactPendienteSeleccionado.pendientedespues = JSON.parse(
          JSON.stringify(this.FactPendienteSeleccionado.facturasventa.pendiente)
        );
      }

      if (this.FactPendienteSeleccionado.notadebitoclientes != null) {
        this.FactPendienteSeleccionado.pendientedespues = JSON.parse(
          JSON.stringify(
            this.FactPendienteSeleccionado.notadebitoclientes.pendiente
          )
        );
      }

      if (this.FactPendienteSeleccionado.abono == 0) {
        this.FactPendienteSeleccionado.descuento = 0;
        this.FactPendienteSeleccionado.retencion = 0;
        this.Totales.ReciboIngreso.abono = 0;
        this.Totales.ReciboIngreso.descuento = 0;
        this.Totales.ReciboIngreso.retencion = 0;
       


       // await this.RListFactpendiente.forEach((elet) => {});
       await Promise.all(this.RListFactpendiente.map(async (elet) => { 


          if (elet.indx == this.FactPendienteSeleccionado.indx) {
            elet.abono = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.abono)
            );
            elet.pendientedespues = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.pendientedespues)
            );
              elet.descuento = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.descuento));
          
            elet.retencion = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.retencion)
            );
          }
          //  Totales.ReciboIngreso.retencion;

          this.Totales.ReciboIngreso.abono += this.RedondeoBasico(elet.abono);
          this.Totales.ReciboIngreso.descuento += this.RedondeoBasico(elet.descuento);
          this.Totales.ReciboIngreso.retencion += this.RedondeoBasico(elet.retencion);
        }));
      }

      //calculando abono=0
      if (this.FactPendienteSeleccionado.abono > 0) {
        this.Totales.ReciboIngreso.abono = 0;
          
        var abono = JSON.parse(JSON.stringify(this.FactPendienteSeleccionado.abono));
        var pendiente = this.RedondeoBasico( JSON.parse(JSON.stringify(this.FactPendienteSeleccionado.pendientedespues)));
        var descontado = 0;
        var retencion = 0;

        var VALOR_FACT=0
        var DescValorPorc=this.FactPendienteSeleccionado.DescValorPorc

       if (this.FactPendienteSeleccionado.facturasventa != null) {
        VALOR_FACT=this.RedondeoBasico(this.FactPendienteSeleccionado.facturasventa.pendiente)
        }

        if (this.FactPendienteSeleccionado.notadebitoclientes != null) {
          VALOR_FACT=this.RedondeoBasico(this.FactPendienteSeleccionado.notadebitoclientes.pendiente)
        }


        if (this.FactPendienteSeleccionado.descuento != null) {
          /*  descontado = JSON.parse(
            JSON.stringify(this.FactPendienteSeleccionado.descuento)
          );*/
          if(DescValorPorc==false){
            descontado=this.RedondeoBasico((VALOR_FACT * this.FactPendienteSeleccionado.descuento) / 100)
            if(descontado>this.FactPendienteSeleccionado.abono){descontado=0}
            }else{
              descontado = JSON.parse(JSON.stringify(this.FactPendienteSeleccionado.descuento));
              if(descontado>this.FactPendienteSeleccionado.abono){descontado=VALOR_FACT-1}
            }
        }
        if (this.FactPendienteSeleccionado.retencion != null) {
          retencion = JSON.parse(
            JSON.stringify(this.FactPendienteSeleccionado.retencion)
          );
        }

        if (abono >= pendiente) {
          this.FactPendienteSeleccionado.abono = this.RedondeoBasico(pendiente);
          this.FactPendienteSeleccionado.pendientedespues = 0;
          if (retencion <= abono) {
            this.FactPendienteSeleccionado.abono -= retencion;
          }
          if (descontado <= abono) {
            this.FactPendienteSeleccionado.abono -= descontado;
          }
        } else {
          // this.FactPendienteSeleccionado.facturasventa.pendiente

          if (retencion >= abono) {
            this.FactPendienteSeleccionado.retencion = abono - 1;
            retencion = abono - 1;
          }

          if (descontado >= abono) {
            this.FactPendienteSeleccionado.descuento = abono - 1;
            descontado = abono - 1;
          }

          var SUMA_ABO_DESC=(this.FactPendienteSeleccionado.abono+descontado)
          if(SUMA_ABO_DESC>pendiente){
               this.FactPendienteSeleccionado.abono -= descontado;
          }

          var SUMA_ABO_RET=(this.FactPendienteSeleccionado.abono+retencion)
          if(SUMA_ABO_RET>pendiente){
            this.FactPendienteSeleccionado.abono -= retencion;
          }
         
          this.FactPendienteSeleccionado.pendientedespues=(pendiente-(abono+descontado+retencion))
          //this.FactPendienteSeleccionado.pendientedespues = pendiente - abono;
        }

        if (this.FactPendienteSeleccionado.abono == 0) {
          this.FactPendienteSeleccionado.descuento = 0;
          this.FactPendienteSeleccionado.retencion = 0;
        }

        //var list = JSON.parse(JSON.stringify(this.RListFactpendiente))
        // var VALOR_FACT=this.FactPendienteSeleccionado.facturasventa.cxc

        //  ojo aquii

        // this.RedondeoBasico(this.FactPendienteSeleccionado.facturasventa.pendiente)

       // await this.RListFactpendiente.forEach((elet) => {});
       await Promise.all(this.RListFactpendiente.map(async (elet) => { 


          if (elet.indx == this.FactPendienteSeleccionado.indx) {
            elet.abono = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.abono)
            );
            elet.pendientedespues = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.pendientedespues)
            );


            if(DescValorPorc==false){
            elet.descuento=this.RedondeoBasico((VALOR_FACT * this.FactPendienteSeleccionado.descuento) / 100)
            if(elet.descuento>elet.abono){elet.descuento=0}
            }else{
              elet.descuento = JSON.parse(JSON.stringify(this.FactPendienteSeleccionado.descuento));
              if(elet.descuento>elet.abono){descontado=elet.descuento-1}
            }

            elet.retencion = JSON.parse(
              JSON.stringify(this.FactPendienteSeleccionado.retencion)
            );
          }

          this.Totales.ReciboIngreso.abono += this.RedondeoBasico(elet.abono);
          this.Totales.ReciboIngreso.descuento += this.RedondeoBasico(elet.descuento);
          this.Totales.ReciboIngreso.retencion += this.RedondeoBasico(elet.retencion);
        }));
      }

      //this.ReciboIngreso.abono = JSON.parse(JSON.stringify(this.FactPendienteSeleccionado.abono))
      this.ReciboIngreso.abono = JSON.parse(JSON.stringify(this.Totales.ReciboIngreso.abono))
      this.FactPendienteSeleccionado = null;
      this.calcularToTalRecIng()
    },

    // Cálculo de la cantidad de dinero que se le va aplicar a las facturas del cliente en la lista de recibos de ingreso 
    async CalcularReciboIngreso1() {
      this.FactPendienteSeleccionado = null;

      this.Totales.ReciboIngreso.abono = JSON.parse(
        JSON.stringify(this.ReciboIngreso.abono)
      );

      if (this.ObtemRListFactpendiente.length > 0) {
        this.Totales.ReciboIngreso.descuento = 0;
        var list = JSON.parse(JSON.stringify(this.ObtemRListFactpendiente));
        var abonoTotal = this.ReciboIngreso.abono;
        //var Lodescontado=JSON.parse(JSON.stringify(this.ReciboIngreso.descuento))

        var DESC_PORC= this.ReciboIngreso.descuento
        var totalDesc=0
        var totalReten=0
        var totalAbono=0
        await list.forEach((elent) => {
          //props.item.descuento
          // if(Lodescontado>0){ReciboIngreso.DescValorPorc}
          var Lodescontado = 0;

          if (this.ReciboIngreso.descuento > 0) {
            if (this.ReciboIngreso.DescValorPorc == true) {
              if (this.ReciboIngreso.descuento >= this.ReciboIngreso.abono) {
                this.ReciboIngreso.descuento = JSON.parse(JSON.stringify(this.ReciboIngreso.abono - 1));
                //this.ReciboIngreso.descuento = 0;
                this.Totales.ReciboIngreso.descuento = JSON.parse(
                  JSON.stringify(this.ReciboIngreso.descuento)
                );
              }
              Lodescontado = JSON.parse(
                JSON.stringify(this.ReciboIngreso.descuento)
              );
            } else {
              if (this.ReciboIngreso.descuento > 100) {
                this.ReciboIngreso.descuento = 100;
              }
              Lodescontado =this.RedondeoBasico((elent.pendientedespues * this.ReciboIngreso.descuento) / 100);
         // this.Totales.ReciboIngreso.descuento =(this.ReciboIngreso.abono * this.ReciboIngreso.descuento) / 100;
            }
          }
          if (abonoTotal >= elent.pendientedespues) {
            elent.abono = this.RedondeoBasico(elent.pendientedespues);
            abonoTotal -= this.RedondeoBasico(elent.pendientedespues);
            elent.pendientedespues = 0;
            if(Lodescontado>elent.abono){Lodescontado=0}

            if (Lodescontado <= elent.abono) {
              elent.descuento = JSON.parse(JSON.stringify(Lodescontado));
              elent.abono -= elent.descuento;
              abonoTotal += Lodescontado;
              
            }
          } else {
            elent.abono = this.RedondeoBasico(abonoTotal);
            if(Lodescontado>elent.abono){Lodescontado=0}
            if (abonoTotal != 0) {
              abonoTotal += Lodescontado;
              elent.descuento = JSON.parse(JSON.stringify(Lodescontado));
            }

            elent.pendientedespues -= this.RedondeoBasico(abonoTotal);
            abonoTotal = 0;
          }

         // this.Totales.ReciboIngreso.descuento += elent.descuento;

         if(elent.abono==0){
            elent.descuento=0
            elent.retencion=0
          }
         
           if(elent.pendientedespues<0){
            elent.descuento= this.RedondeoBasico((elent.descuento+(elent.pendientedespues)))
            elent.pendientedespues=0
           }

           totalDesc+=elent.descuento
          totalReten+=elent.retencion
          totalAbono+=elent.abono
          
        });
        this.Totales.ReciboIngreso.descuento=JSON.parse(JSON.stringify(totalDesc))
        this.Totales.ReciboIngreso.retencion=JSON.parse(JSON.stringify(totalReten))
        this.RListFactpendiente = JSON.parse(JSON.stringify(list));
        this.ReciboIngreso.abono=JSON.parse(JSON.stringify(totalAbono));
        this.Totales.ReciboIngreso.abono=JSON.parse(JSON.stringify(totalAbono));
        this.calcularToTalRecIng()
        // RListFactpendiente
      }
    },


// Cálculo de la cantidad de dinero que se le va aplicar a las facturas del cliente en la lista de recibos de ingreso 
    async CalcularReciboIngreso11() {
  // NOTA: este metodo es el anterior al CalcularReciboIngreso1 (se cambio por que el descuento se le resta a lo pendiente, 
// y cuando no se salda el pendiente completo el balance da en negativo).
      this.FactPendienteSeleccionado = null;

      this.Totales.ReciboIngreso.abono = JSON.parse(
        JSON.stringify(this.ReciboIngreso.abono)
      );

      if (this.ObtemRListFactpendiente.length > 0) {
        this.Totales.ReciboIngreso.descuento = 0;
        var list = JSON.parse(JSON.stringify(this.ObtemRListFactpendiente));
        var abonoTotal = this.ReciboIngreso.abono;
        //var Lodescontado=JSON.parse(JSON.stringify(this.ReciboIngreso.descuento))
 var totalDesc=0
 var totalReten=0


 // await list.forEach((elent) => { }); 
 await Promise.all(list.map(async (elent) => { 
          //props.item.descuento
          // if(Lodescontado>0){ReciboIngreso.DescValorPorc}
          var Lodescontado = 0;

          if (this.ReciboIngreso.descuento > 0) {
            if (this.ReciboIngreso.DescValorPorc == true) {
              if (this.ReciboIngreso.descuento >= this.ReciboIngreso.abono) {
                this.ReciboIngreso.descuento = JSON.parse(
                  JSON.stringify(this.ReciboIngreso.abono - 1)
                );
                this.Totales.ReciboIngreso.descuento = JSON.parse(
                  JSON.stringify(this.ReciboIngreso.descuento)
                );
              }
              Lodescontado = JSON.parse(JSON.stringify(this.ReciboIngreso.descuento));
            } else {
              if (this.ReciboIngreso.descuento > 100) {
                this.ReciboIngreso.descuento = 100;
              }
              Lodescontado =
                (this.ReciboIngreso.abono * this.ReciboIngreso.descuento) / 100;
              this.Totales.ReciboIngreso.descuento =
                (this.ReciboIngreso.abono * this.ReciboIngreso.descuento) / 100;
            }
          }
          if (abonoTotal >= elent.pendientedespues) {
            elent.abono = this.RedondeoBasico(elent.pendientedespues);
            abonoTotal -= elent.pendientedespues;
            elent.pendientedespues = 0;

            if (Lodescontado <= elent.abono) {
              elent.descuento = JSON.parse(JSON.stringify(Lodescontado));
              elent.abono -= elent.descuento;
              abonoTotal += Lodescontado;
            }
          } else {
            elent.abono = this.RedondeoBasico(abonoTotal);

            if (abonoTotal != 0) {
              abonoTotal += Lodescontado;
              elent.descuento = JSON.parse(JSON.stringify(Lodescontado));
            }

            elent.pendientedespues -= abonoTotal;
            abonoTotal = 0;
          }

         // this.Totales.ReciboIngreso.descuento += elent.descuento;

         if(elent.abono==0){
            elent.descuento=0
            elent.retencion=0
          }
          totalDesc+=elent.descuento
          totalReten+=elent.retencion
         
          
           })); 
        this.Totales.ReciboIngreso.descuento=JSON.parse(JSON.stringify(totalDesc))
        this.Totales.ReciboIngreso.retencion=JSON.parse(JSON.stringify(totalReten))
        this.RListFactpendiente = JSON.parse(JSON.stringify(list));
        this.calcularToTalRecIng()
        // RListFactpendiente
      }
    },

    async CalcularReciboIngreso() {
      var Lodescontado = 0;
      var LoRetenido = 0;
      var LoRetenidoIsr = 0;
      this.Totales.ReciboIngreso.abono = JSON.parse(
        JSON.stringify(this.ReciboIngreso.abono)
      );
      if (this.ReciboIngreso.abono > 0) {
        if (this.ReciboIngreso.DescValorPorc == true) {
          if (this.ReciboIngreso.descuento >= this.ReciboIngreso.abono) {
            this.ReciboIngreso.descuento = JSON.parse(
              JSON.stringify(this.ReciboIngreso.abono - 1)
            );
            this.Totales.ReciboIngreso.descuento = JSON.parse(
              JSON.stringify(this.ReciboIngreso.descuento)
            );
          }
          Lodescontado = JSON.parse(
            JSON.stringify(this.ReciboIngreso.descuento)
          );
        } else {
          if (this.ReciboIngreso.descuento > 100) {
            this.ReciboIngreso.descuento = 100;
          }
          Lodescontado =
            (this.ReciboIngreso.abono * this.ReciboIngreso.descuento) / 100;
          this.Totales.ReciboIngreso.descuento =
            (this.ReciboIngreso.abono * this.ReciboIngreso.descuento) / 100;
        }
        if (this.ReciboIngreso.retenValorPorc == true) {
          if (this.ReciboIngreso.retencion >= this.ReciboIngreso.abono) {
            this.ReciboIngreso.retencion = JSON.parse(
              JSON.stringify(this.ReciboIngreso.abono - 1)
            );
            this.Totales.ReciboIngreso.retencion = JSON.parse(
              JSON.stringify(this.ReciboIngreso.retencion)
            );
          }
          LoRetenido = JSON.parse(JSON.stringify(this.ReciboIngreso.retencion));
        } else {
          if (this.ReciboIngreso.retencion > 100) {
            ReciboIngreso.retencion = 100;
          }
          LoRetenido =
            (this.ReciboIngreso.abono * this.ReciboIngreso.retencion) / 100;
          this.Totales.ReciboIngreso.retencion =
            (this.ReciboIngreso.abono * this.ReciboIngreso.retencion) / 100;
        }
        if (this.ReciboIngreso.isrValorPorc == true) {
          if (this.ReciboIngreso.isr >= this.ReciboIngreso.abono) {
            this.ReciboIngreso.isr = JSON.parse(
              JSON.stringify(this.ReciboIngreso.abono - 1)
            );
            this.Totales.ReciboIngreso.isr = JSON.parse(
              JSON.stringify(this.ReciboIngreso.isr)
            );
          }
          LoRetenidoIsr = JSON.parse(JSON.stringify(this.ReciboIngreso.isr));
        } else {
          if (this.ReciboIngreso.isr > 100) {
            ReciboIngreso.isr = 100;
          }
          LoRetenidoIsr =
            (this.ReciboIngreso.abono * this.ReciboIngreso.isr) / 100;
          this.Totales.ReciboIngreso.isr =
            (this.ReciboIngreso.abono * this.ReciboIngreso.isr) / 100;
        }
        if (this.RListFactpendiente.length > 0) {
          this.RListFactpendiente = JSON.parse(JSON.stringify(this.objTemp));
          if (this.ReciboIngreso.abono > 0) {
            var LoAbono = JSON.parse(JSON.stringify(this.ReciboIngreso.abono));

            // this.RListFactpendiente.forEach((el) => { }); 
            await Promise.all(this.RListFactpendiente.map(async (el) => { 


              if (LoAbono > 0 && LoAbono < this.RedondeoBasico(el.pendiente)) {
                var sumaloabono = LoRetenido + LoRetenidoIsr + Lodescontado;
                var re = sumaloabono - LoAbono + 1;
                if (sumaloabono >= LoAbono) {
                  if (Lodescontado > 0) {
                    if (Lodescontado > re) {
                      (el.descuento = this.RedondeoBasico(Lodescontado - re)),
                        (re = 0),
                        (sumaloabono = sumaloabono - re);
                    } else {
                      (el.descuento = 0),
                        (re = re - Lodescontado),
                        (sumaloabono = sumaloabono - re);
                    }
                  }
                } else {
                  el.descuento = Lodescontado;
                }
                if (sumaloabono >= LoAbono) {
                  if (LoRetenido > 0) {
                    if (LoRetenido > re) {
                      (el.descuento = this.RedondeoBasico(LoRetenido - re)),
                        (re = 0),
                        (sumaloabono = sumaloabono - re);
                    } else {
                      (el.descuento = 0),
                        (re = re - LoRetenido),
                        (sumaloabono = sumaloabono - re);
                    }
                  }
                } else {
                  el.retencion = LoRetenido;
                }
                if (sumaloabono >= LoAbono) {
                  if (LoRetenidoIsr > 0) {
                    if (LoRetenidoIsr > re) {
                      (el.descuento = this.RedondeoBasico(LoRetenidoIsr - re)),
                        (re = 0),
                        (sumaloabono = sumaloabono - re);
                    } else {
                      (el.descuento = 0),
                        (re = re - LoRetenidoIsr),
                        (sumaloabono = sumaloabono - re);
                    }
                  }
                } else {
                  el.isr = LoRetenidoIsr;
                }
                el.pendiente = this.RedondeoBasico(el.pendiente - LoAbono);
                el.abono = this.RedondeoBasico(
                  LoAbono - el.descuento - el.retencion - el.isr
                );
                LoAbono = 0;
                return 0;
              }
              if (LoAbono > 0 && LoAbono >= this.RedondeoBasico(el.pendiente)) {
                el.abono = JSON.parse(
                  JSON.stringify(this.RedondeoBasico(el.pendiente))
                );
                var sumaloabono = LoRetenido + LoRetenidoIsr + Lodescontado;
                var re = sumaloabono - LoAbono + 1;
                if (sumaloabono >= LoAbono) {
                  if (Lodescontado > 0) {
                    if (Lodescontado > re) {
                      (el.descuento = this.RedondeoBasico(Lodescontado - re)),
                        (re = 0),
                        (sumaloabono = sumaloabono - re);
                    } else {
                      (el.descuento = 0),
                        (re = re - Lodescontado),
                        (sumaloabono = sumaloabono - re);
                    }
                  }
                } else {
                  el.descuento = Lodescontado;
                }
                if (sumaloabono >= LoAbono) {
                  if (LoRetenido > 0) {
                    if (LoRetenido > re) {
                      (el.descuento = this.RedondeoBasico(LoRetenido - re)),
                        (re = 0),
                        (sumaloabono = sumaloabono - re);
                    } else {
                      (el.descuento = 0),
                        (re = re - LoRetenido),
                        (sumaloabono = sumaloabono - re);
                    }
                  }
                } else {
                  el.retencion = LoRetenido;
                }

                if (sumaloabono >= LoAbono) {
                  if (LoRetenidoIsr > 0) {
                    if (LoRetenidoIsr > re) {
                      (el.descuento = this.RedondeoBasico(LoRetenidoIsr - re)),
                        (re = 0),
                        (sumaloabono = sumaloabono - re);
                    } else {
                      (el.descuento = 0),
                        (re = re - LoRetenidoIsr),
                        (sumaloabono = sumaloabono - re);
                    }
                  }
                } else {
                  el.isr = LoRetenidoIsr;
                }

                // el.descuento=Lodescontado, el.retencion=LoRetenido, el.isr=LoRetenidoIsr
                el.pendiente = 0;

                if (el.abono > el.descuento) {
                  el.descuento = el.descuento;
                  el.abono = this.RedondeoBasico(el.abono - el.descuento);
                  LoAbono = LoAbono + el.descuento;
                } else {
                  el.descuento = 0;
                }

                if (el.abono > el.retencion) {
                  el.retencion = el.retencion;
                  el.abono = this.RedondeoBasico(el.abono - el.retencion);
                  LoAbono = LoAbono + el.retencion;
                } else {
                  el.retencion = 0;
                }

                if (el.abono > el.retencion) {
                  el.isr = el.isr;
                  el.abono = this.RedondeoBasico(el.abono - el.isr);
                  LoAbono = LoAbono + el.isr;
                } else {
                  el.isr = 0;
                }

                LoAbono = LoAbono - el.abono;
                //LoAbono =((LoAbono-el.pendiente)+el.abono+el.descuento+el.retencion+el.isr)
                return 0;
              }
             }));//aqui
          }
        }

        // @input="CalcularReciboIngreso()"
        // Totales:{
        //   ReciboIngreso:{
        //   abono:0,
        //   retencion:0,
        //   descuento:0,
        // }
        // ReciboIngreso:{
        //   precio:0,
        //   abono:0,
        //   retencion:0,
        //   descuento:0,
        //   DescValorPorc:true,
        //   retenValorPorc:true
        // }
      }
    },

    SelctCbTipoTarjetaReciboFacturaFormPag() {
      if (this.ReciboIngresoFactura.tipoTarjeta == null) {
        this.ReciboIngresoFactura.tipoTarjeta = "";
      }

      if (this.ReciboIngresoFactura.tipoTarjeta.codigo == undefined) {
        this.alerta("Seleccione un Tipo de tarjeta", "error");
        this.ReciboIngresoFactura.tipoTarjeta = "";
        return;
      }
    },

    SelctCbBancoReciboFacturaFormPag() {
      if (this.ReciboIngresoFactura.bancoCheque == null) {
        this.ReciboIngresoFactura.bancoCheque = "";
      }

      if (this.ReciboIngresoFactura.bancoCheque.codigo == undefined) {
        this.alerta("Seleccione un banco", "error");
        this.ReciboIngresoFactura.bancoCheque = "";
        return;
      }
    },

    OpenPanelReciboFormaPago(e) {
      this.NombrePanelReciboFormaPago = e;
      this.PanelReciboFormaPago = true;
    },

    // Hacer una llamada API al servidor y obtener los datos.
    CargarBusquedaRecetas() {
      this.Recetaslist = [];
      var paciente = "null";
      if (this.RecetaBusqueda.paciente !== "") {
        paciente = this.RecetaBusqueda.paciente
      }

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/receta/find/" +
          this.RecetaBusqueda.receta +
          "/" +
          this.RecetaBusqueda.fechaInicial +
          "/" +
          this.RecetaBusqueda.fechaFinal +
          "/" +
          paciente +
          "/15/0",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.Recetaslist = res.data.listas;
          this.TotalRegistros = res.data.cantidad
        });
    },
    RecetaObtenerDatoSelect(e) {
      this.RecetaDatostabla(e.item);
    },
    findReceta(e) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/receta/find/" +
          this.RecetaProdControlado.numero,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (res.data.toString().length == 0) {
            this.disableNumeroReceta = false
            return
          }
          this.disableNumeroReceta = true
          //if(){}
          if (res.data.cliente.codigo != this.RecetaProdControlado.cliente.codigo) {
            this.alerta("El cliente no es el mismo que tiene la receta. revise el número de receta.", "error");
            return;
          }
          this.RecetaProdControlado = res.data;
         // this.RecetaProdControlado.fecha = new Date(res.data.fecha).toISOString().substr(0, 10)
         this.RecetaProdControlado.fecha =res.data.nuevaFecha
          this.RecetaProdControlado.detalleList = [];
          res.data.recetaDetalleList.forEach(e => {
            var det = {
              productos: e.producto,
              unidad: e.unidad,
              cantidadRecetada: e.cantidadRecetada,
              cantidadDespachada: e.cantidadDespachada,
            }
            this.RecetaProdControlado.detalleList.push(det);
          });


          
        });
    },
    // Tomando los datos de la tabla y colocándolos en un cuadro de diálogo.
    RecetaDatostabla(e) {
      this.$axios.get(this.$hostname + this.$hName + '/receta/findfacturareceta/' + e.numero, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
    
     
          var FACTURAS=""
          var bar = new Promise((resolve, reject) => {
            res.data.forEach((element, index, array) => {
              
              if(FACTURAS.length >0){FACTURAS+=", " }
              FACTURAS+=`${element.facturasventaPK.secuencia}`
              // facturasventaPK.secuencia:53327
          });
          resolve()

});

bar.then(() => { 
  this.RecetaSelected = [];
      this.RecetaSeleccionado = null;
      this.RecetaSelected.push(JSON.parse(JSON.stringify(e)));
      this.RecetaSeleccionado = JSON.parse(JSON.stringify(e));
      this.RecetaSeleccionado.faturas=FACTURAS 
      this.dialogVerRecetaCompleta = true;
  
});


        })


    },
    // Un método que se llama cuando se cambia el paginador.
    RecetaCargarPaginacion(e) {
      this.RecetaSelected = [];
      this.RecetaPaginator = e;
      if (this.RecetaPaginator.itemsPerPage == -1) {
        this.RecetaPaginator.itemsPerPage = 15;
      }
    },
    // Cerrando el cuadro de diálogo.
    CerrarVerRecetaCompleta() {
      this.RecetaSelected = [];
      this.RecetaSeleccionado = null;
      this.dialogVerRecetaCompleta = false;
      this.recetaFacturaList = [];
    },
    // Hacer una solicitud HTTP a un servidor y obtener una respuesta de recetas.
    RecetaProdctoDatostabla(e) {
      this.recetaFacturaList = [];
      //http://10.0.0.95:8080/micromarket/services/recetas/receta/2151/9253/482

      // this.$axios.get(this.$hostname+this.$hName+'/services/cliente-c/+'+this.clienteFactura.codigo+'/facturas-pendientes',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>{
      //   this.recetaFacturaList=res.data
      // })

      //http://10.0.0.95:8080/micromarket/services/recetas/receta/2151/9253/482 []

      // 0: 1
      // 1: "Fco"
      // 2: "2021-08-19"
      // 3: 199725

      // 1, 'Fco', '2021-08-19', 199725

      this.recetaFacturaList = [
      ];
      //+'/'+e.producto.codigo+'/'+e.unidad.codigo+''
      this.$axios.get(this.$hostname + this.$hName + '/receta/findReceta/' + this.RecetaSeleccionado.numero, { headers: this.$store.getters.GetheadersAxios })
        .then(res => {
          this.recetaFacturaList = res.data
        })
    },

ejecutarBuscarRestaurarFacturasFrecuencia(){
  var INTERVALO =  ((this.$store.getters.GetConfiguracionGeneral.configPv.tiempoActualizarFacturaTemporal)*1000)
  this.$store.getters.GetConfiguracionGeneral.configPv.tiempoActualizarFacturaTemporal
  var _this=this;
  this.setIntervalBuscarRestaurar= setInterval(function(){ _this.BuscarRestaurarFacturas() }, INTERVALO);

},

canselarBuscarRestaurarFacturasFrecuencia(){
  clearInterval(this.setIntervalBuscarRestaurar)
  this.setIntervalBuscarRestaurar=null
  this.ejecutarAplRestFact= true
  this.dialogRestaurarFactura = false
  this.ObjEjecPermisoPv = null
},

NotfFactRestaurarInterval(){
  //var _this=this;
  //this.setIntervalNotfFactRestaurar=setInterval(function(){ _this.ejecutarNotfFactRestaurar() }, 10000);

},

canselarFactRestaurarInterval(){
  clearInterval(this.setIntervalNotfFactRestaurar)
  this.setIntervalNotfFactRestaurar=null
},

ejecutarNotfFactRestaurar(){
 //this.RestaurarFacturaBusqueda.sucursal.codigo


/*if(this.dialogRestaurarFactura==false && this.RestaurarFacturaBusqueda.sucursal!=null && this.RestaurarFacturaBusqueda.sucursal.hasOwnProperty('codigo') ){

var _this = this;
      this.$axios
        .get(this.$hostname + this.$hName + "/temporales/todas/" + this.RestaurarFacturaBusqueda.sucursal.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          setTimeout(function () {
            _this.$refs.RefPvMenuOpcionBotones.notfFacTem=res.data.length;
           // _this.RestaurarFactlistFact = JSON.parse(JSON.stringify(res.data));
          }, 600); 
        })
        .catch((error) =>{
          this.$refs.RefPvMenuOpcionBotones.notfFacTem=0;
        });
      }*/

},

    // Hacer una solicitud GET al servidor y devolver una lista de objetos de facturas temporales.
    BuscarRestaurarFacturas(e) {
      if(typeof e === 'object' && e!=null ){
        
        if(e.keyCode == 38||e.keyCode == 40||e.keyCode == 37||e.keyCode == 39){return}
       /* console.log(e.hasOwnProperty('keyCode'))
        if(e.hasOwnProperty('keyCode')){
          console.log('keyCode--')
          console.log(e.keyCode === 40)
          if(e.keyCode == 38){return}
          if(e.keyCode == 40){return}
          if(e.keyCode == 37){return}
          if(e.keyCode == 39){return} 
        }*/
        //&&  
      
      }

      if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo') ){
      this.RestaurarFactlistFact=[]
      this.selectFactTem=null
      this.TbPaginatorFactTem.pageCount=0
      this.TbPaginatorFactTem.pageStart=0
      var _this = this;
      this.$axios
        .get(this.$hostname + this.$hName + "/temporales/todas/" + this.RestaurarFacturaBusqueda.sucursal.codigo+"/"+this.Facturasventa.vendedor.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          setTimeout(function () {
            _this.RestaurarFactlistFact = JSON.parse(JSON.stringify(res.data));
          }, 600); 
        })
        .catch((error) =>{this.loading(false)});
    
      }else{

        this.RestaurarFactlistFact=[]
      this.selectFactTem=null
      this.TbPaginatorFactTem.pageCount=0
      this.TbPaginatorFactTem.pageStart=0
      var _this = this;
      this.$axios
        .get(this.$hostname + this.$hName + "/temporales/todas/" + this.RestaurarFacturaBusqueda.sucursal.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          setTimeout(function () {
            _this.RestaurarFactlistFact = JSON.parse(JSON.stringify(res.data));
          }, 600); 
        })
        .catch((error) =>{this.loading(false)});
      } 
    },

    // Devolver una cadena basada en el valor del objeto.
    TipoRestaurarFactura(e) {
      var data = ""

      if (e.ars != null && e.hasOwnProperty('cobertura')&& e.cobertura!=null&&  e.cobertura>0) {
        return data = " Sequro "
      }

      if (e.telefonoDomicilio != null) {
        return data = " Domicilio "
      }

      if (e.cxc != null && e.cxc > 0) {
        return data = " Cuenta por cobrar "
      }

      if (e.tarjeta != null && e.tarjeta > 0) {
        return data = " Tarjeta ";
      }

      if (e.efectivo != null && e.efectivo > 0) {
        return data = " Efectivo ";
      }
      return data;
    },
    RestaurarFacturaDatostabla() { },

    RestaurarFacturaTelefono(e){
      if(e.cliente!=null){
        return this.selectTelefonoCliente(e.cliente)
      }else{
        return ' '
      }

    },

    GetColorRestaurarFact(e){
if(e.hasOwnProperty('reimprimida') && e.reimprimida!=null && e.reimprimida==true){
  return '#66BB6A'
}else{
  if(e.hasOwnProperty('cobertura')&& e.cobertura!=null&&  e.cobertura>0){
    return '#CE93D8'
  }else{
      if(e.hasOwnProperty('telefonoDomicilio') && e.telefonoDomicilio!=null && e.telefonoDomicilio.trim().length>0){
        return '#FFD180'
      }else{
        return 'transparent'
      }
  }
  
 
}
     
      /* #81C784 */
      // 
      
     // return "RowSelectColorPvGreen black--text ";
    },

    getBorderColorSelectFactTem(e){

      
      var FACTTEMSELEC=this.selectFactTem
      /*console.log('FACTTEMSELEC')
      console.log(FACTTEMSELEC)

      console.log('--e--')
      console.log(e)*/

    if(e!=null){

    
  if(FACTTEMSELEC!=null && FACTTEMSELEC.hasOwnProperty('indx') && FACTTEMSELEC.indx== e.indx && FACTTEMSELEC.secuencia ==e.secuencia){
   // return '#FF9800'
   return '3px dashed #dd4b39'
  }else{
    return '3px dashed transparent'
  }
}else{
  return '3px dashed transparent'
}
    },

    cargarPaginacionAllFactTem(e){
      this.TbPaginatorFactTem = JSON.parse(JSON.stringify(e))
    } ,

    SelectMarcaFactTemp(e) {
        //ArrowRight:39
        if(e.keyCode == 39){
          if (this.RestaurarFactlistFact.length != 0) {
            if(this.TbPaginatorFactTem.pageStop!=this.RestaurarFactlistFact.length){

              if( this.TbPaginatorFactTem.pageCount==0 || this.TbPaginatorFactTem.page<this.TbPaginatorFactTem.pageCount){
                this.TbPaginatorFactTem.page=(this.TbPaginatorFactTem.page+1)
              }else{
                this.TbPaginatorFactTem.page=this.TbPaginatorFactTem.pageCount
              }
              

            var _this = this;
        setTimeout(function () {
          var list = JSON.parse(JSON.stringify(_this.RestaurarFactlistFact.filter((ee) => ee.hasOwnProperty('indx') && ee.indx!=null)))
          if(_this.RestaurarFacturaBusqueda.buscar.length>0){
            _this.selectFactTem =list[_this.TbPaginatorFactTem.pageStart]
           }else{
          _this.selectFactTem = JSON.parse(JSON.stringify(_this.RestaurarFactlistFact[_this.TbPaginatorFactTem.pageStart]));
           }
           }, 500);
          return 0;

               }
             }
        }

        //ArrowLeft:37
        if(e.keyCode == 37){
          if (this.RestaurarFactlistFact.length != 0) {
          if(this.TbPaginatorFactTem.pageStart>0){
            this.TbPaginatorFactTem.page=(this.TbPaginatorFactTem.page-1)
            var _this = this;
        setTimeout(function () {
          var list = JSON.parse(JSON.stringify(_this.RestaurarFactlistFact.filter((ee) => ee.hasOwnProperty('indx') && ee.indx!=null)))
           if(_this.RestaurarFacturaBusqueda.buscar.length>0){
            _this.selectFactTem =list[_this.TbPaginatorFactTem.pageStart]
           }else{
            _this.selectFactTem = JSON.parse(JSON.stringify(_this.RestaurarFactlistFact[_this.TbPaginatorFactTem.pageStart]));
           }
           }, 500);
          return 0;
            }
        }
        }

       // ArrowUp/ArrowDown
       if(e.keyCode == 38 ||  e.keyCode == 40){
          if (this.selectFactTem == null && this.RestaurarFactlistFact.length != 0) {
            var list = JSON.parse(JSON.stringify(this.RestaurarFactlistFact.filter((ee) => ee.hasOwnProperty('indx') && ee.indx!=null)))
          this.selectFactTem = JSON.parse(JSON.stringify(this.RestaurarFactlistFact[list[0].indx]));
          return 0;
        }
        }

        if (this.selectFactTem != null) {

          //key: "Enter
          if (e.keyCode == 13) {

            var OBJ=JSON.parse(JSON.stringify(this.selectFactTem))
            this.selectFactTem=null
            this.verificarusofacturatemporalAplicar(OBJ)
           //this.addClikProd(this.selectProducto)
          }

        //key: "ArrowUp"keyCode: 38
        if (e.keyCode == 38) {
           // var VarInddx = JSON.parse(JSON.stringify(this.selectFactTem.indx - 1));
            //var VarInddx = JSON.parse(JSON.stringify(this.selectFactTem.indx + 1));
            var BASEVarInddx = JSON.parse(JSON.stringify(this.selectFactTem.indx));
          //cuando se filtra bucando index
            var list = JSON.parse(JSON.stringify(this.RestaurarFactlistFact.filter((ee) => ee.hasOwnProperty('indx') && ee.indx!=null)))
            var NEWINND =list.filter((ee) => ee.hasOwnProperty('indx') && ee.indx!=null && ee.indx<BASEVarInddx)
            var INND = 0
            if(NEWINND.length>0){
               INND = NEWINND[NEWINND.length-1].indx;
            }else{
              INND =this.selectFactTem.indx
            }
            var VarInddx =INND
            if (this.RestaurarFactlistFact[VarInddx] != undefined) {
              this.selectFactTem = JSON.parse(JSON.stringify(this.RestaurarFactlistFact[VarInddx]));
            } else {
              this.selectFactTem = JSON.parse(
                JSON.stringify(this.RestaurarFactlistFact[VarInddx + 1])
              );
            }

            return 0;
          }

//key: "ArrowDown"keyCode: 40
if (e.keyCode == 40) {

            //var VarInddx = JSON.parse(JSON.stringify(this.selectFactTem.indx + 1));
            var BASEVarInddx = JSON.parse(JSON.stringify(this.selectFactTem.indx));
          //cuando se filtra bucando index
            var list = JSON.parse(JSON.stringify(this.RestaurarFactlistFact.filter((ee) => ee.hasOwnProperty('indx') && ee.indx!=null)))
            var NEWINND =list.filter((ee) => ee.hasOwnProperty('indx') && ee.indx!=null && ee.indx>BASEVarInddx)
            var INND = 0
            if(NEWINND.length>0){
               INND = NEWINND[0].indx;
            }else{
              INND =this.selectFactTem.indx
            }
            var VarInddx =INND
            if (this.RestaurarFactlistFact[VarInddx] != undefined) {
              this.selectFactTem = JSON.parse(JSON.stringify(this.RestaurarFactlistFact[VarInddx]));

            } else {
              this.selectFactTem = JSON.parse(
                JSON.stringify(this.RestaurarFactlistFact[VarInddx - 1])
              );
            }
            return 0;
          }

        }

    },



    verificarusofacturatemporalAplicar(e){
      this.RestaurarFacturaBusqueda.buscar=""
      // this.AplicarRestaurarFact(res.data.Facturastemporales)

   
// var _this=this;
// setTimeout(function(){ }, 300);


  if(this.ejecutarAplRestFact==true){
    this.ejecutarAplRestFact=false  
    this.AplicarRestaurarFact(e) 
}

return

 

     
     /* if (this.EjecutarPermisoPv == false) {
        this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(e))
        this.funcionValidaPermisoPv('AccAplicarRestaurarFact')
        return;
      }
      this.loading(true)

      this.$axios
        .post(this.$hostname + this.$hName + "/temporales/verificarusofacturatemporal/", e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
         console.log('--vIENDO iNFOMACION')
         console.log(res.data)
          if(res.data.enUso==true){
            this.alerta("Esta factura temporal está en uso", "error")
          }else{
            this.AplicarRestaurarFact(res.data.Facturastemporales)
            this.canselarBuscarRestaurarFacturasFrecuencia()
          }
          // this.BuscarRestaurarFacturas();
        })
        .catch((error) =>
          console.log("Dato no conectado.", "error")
        );
*/

      
    },

    async AplicarRestaurarFact(e){
      var Limpiar = new Promise((resolve, reject) => {
        this.limpiarPuntoVentaCompleto()

        setTimeout(() => {
          this.loading(true)
          resolve('resolved');
        }, 200);
      });

      Limpiar.then(() => { 
        var _this=this;
 setTimeout(function(){ _this.ContAplicarRestaurarFact(e)}, 300);
        
      });
      

    },

    // Una función que se llama cuando se hace clic en un botón y carga el objecto factura temporal 
    //y asignando los valores de la factura.
    async ContAplicarRestaurarFact(e) {
      this.dialogRestaurarFactura = false
      this.canselarBuscarRestaurarFacturasFrecuencia();
      this.clienteFactura = null
      this.RProductoCompraTemp = []
      this.RProductoCompra = []
      this.Facturasventa.vendedor = null
      this.Facturasventa.tipocomprobante = null
      this.Facturasventa.telefononcf = ""
      this.Facturasventa.cedularncncf = ""
      this.Facturasventa.nombreDgii = ""
      this.Facturasventa.direccionncf = ""
      this.Facturasventa.caja = this.caja;
      this.Facturasventa.sucursal = this.caja.sucursal
      this.Facturasventa.comentario = ""

      this.configuraciones.config.monedabase.tasa
      /*this.Monedafactura=JSON.parse(JSON.stringify(this.configuraciones.config.monedabase))
      this.Facturasventa.moneda=JSON.parse(JSON.stringify(this.configuraciones.config.monedabase))*/
      if (e.moneda != null) {
        var monedaa = e.moneda
        monedaa.tasa = e.tasa
        this.Monedafactura = monedaa
        this.Facturasventa.moneda = monedaa
        this.Facturasventa.tasa = monedaa.tasa;
      }
      this.SalvarMonedaFactura()

      var _this = this;
      this.deleteFacturaTemporal = JSON.parse(JSON.stringify(e));
      /*this.Descuentofactura = {
        descuento: e.valordescuento,
        ValorPorc: true,
        DescuentoFacturaGlobal: true
      }*/

      //Verifica si de la lista de producto la existencia no alcansa
      var BOOL_existencia=false
      var OBJrestrList=[];
      var bar = new Promise((resolve, reject) => {
        (async () => {

        if (e.facturastemporalesDetalleList.length > 0) {

          //var listFtempDetalle = JSON.parse(JSON.stringify(e.facturastemporalesDetalleList.filter((ee) => ee.precio>0)))
          var listFtempDetalle = JSON.parse(JSON.stringify(e.facturastemporalesDetalleList.filter((ee) => ee.precio>=0)))
          listFtempDetalle.sort(function (a, b) {
            if (a.facturastemporalesDetallePK.id < b.facturastemporalesDetallePK.id) { return 1; }
            if (a.facturastemporalesDetallePK.id > b.facturastemporalesDetallePK.id) {
              return -1;
            }
            // a must be equal to b
            return 0;
          });
          setTimeout(() => {
            (async () => {

            
          // console.log(listFtempDetalle)
          const restrList =  await this.detalleRestaurarFactTem(listFtempDetalle,e.tipoComprobante);
          OBJrestrList= JSON.parse(JSON.stringify(restrList.ListTem))
          /* console.log('--OBJrestrList---')
          console.log(OBJrestrList)

         BOOL_existencia=restrList.BOOL_existencia
          console.log('--DFBOOL_existencia--')
          console.log(BOOL_existencia)*/
          if(restrList.BOOL_existencia==true){
            _this.alerta("hay producto que intenta vender, que la cantiada es mayor a la existencia", "error");
          }    
          if (OBJrestrList.length > 0) {
          // console.log('--restrList--')
          // console.log(OBJrestrList)
          this.RProductoCompraOfercta=JSON.parse(JSON.stringify(restrList.ListTem.filter((ee) => ee.paramOfert==true)))

          await Promise.all(OBJrestrList.map(async (element) => {
           // this.BuscPropiedadProductoSelct(element, 'add')
          // this.productoSeleccionado=JSON.parse(JSON.stringify(element))
          // this.SalvarInfProducto()
          element.RecarValor=(element.hasOwnProperty("RecarValor")&& element.RecarValor!=null?element.RecarValor:0)
          element.valorRecargo=(element.hasOwnProperty("valorRecargo")&& element.valorRecargo!=null?element.valorRecargo:0)
          this.RProductoCompra.unshift(JSON.parse(JSON.stringify(element)));
          this.RProductoCompraTemp.unshift(JSON.parse(JSON.stringify(element)));
         /* //this.BuscPropiedadProductoSelct(element, 'add')*/
        
          }));
          this.loading(false)
          this.$refs.RefScroll.scrollTop = 0;
        this.$refs.RefScrollProductoCompra.scrollTop = 0;

        var DATO={
              cliente:e.cliente,
              vendedor:e.vendedor,
              tipoComp:e.tipoComprobante,
              OBJ:JSON.parse(JSON.stringify(e))
            }

          

        setTimeout(function () {
     
         // _this.LimpiaInfProducto()

         _this.LimpiaInfProducto_DatoClienteSelectRestFact(DATO);

        }, 400);
          /*OBJrestrList.forEach(async(element) => {
            this.BuscPropiedadProductoSelct(element, 'add')
          });*/
             }


   setTimeout(() => {
          resolve()
        }, 500); 


            })()
          }, 500); 
        
        }

       /* setTimeout(() => {
          resolve()
        }, 500); */


      })() 
        
      });

      
      bar.then(() => {
        if (e.cobertura != null && e.cobertura > 0) {
          this.CoberturaSeguro.ars = e.ars
          this.CoberturaSeguro.cobertura = e.cobertura
          this.CoberturaSeguro.autorizacion = e.autorizacion
          this.CoberturaSeguro.medico = e.medico
          this.CoberturaSeguro.exequartur = e.execuantum
          this.CoberturaSeguro.especialidad = e.especialidaMedico
          this.CoberturaSeguro.diagnostico = e.diagnostico
        }


        if (e.receta != null && e.receta.length > 0) {
          this.RecetaProdControlado = JSON.parse(e.receta)
        }

        
        this.ObjEjecPermisoPv = null
        
        setTimeout(function () {

          _this.SelectMedicoFactura = e.medicoCodigo
          _this.Facturasventa.vendedor = e.vendedor
          _this.Facturasventa.nombreDgii = e.nombreDgii
          _this.Facturasventa.cedularncncf = e.cedulaDgii
          _this.Facturasventa.telefononcf = e.telefonoDgii
          _this.Facturasventa.direccionncf = e.direccionDgii
          _this.Facturasventa.nombreFactTem=e.descripcion
          if(e.comentario!=null){
            _this.Facturasventa.comentario=e.comentario
          }else{
            _this.Facturasventa.comentario=""
          }
          
          _this.nombreFactTem=e.descripcion

          if(e.cliente==null && e.descripcion!=null && e.hasOwnProperty("descripcion")&& e.descripcion.trim().length>0){
            _this.Facturasventa.nombreDgii = e.descripcion
            }

          if (e.telefonoDomicilio != null) {
            // _this.OpenFacturaDomicilio()
            if (e.cliente != null) {
              _this.domiciliofactura = JSON.parse(JSON.stringify(e.cliente));
            }

          
            _this.PanelMenuOpcionBotones = false;
            _this.PanelMenuOpcionFacturaDomicilio = true;
            var jsn = [
              {
                codigo: 1,
                descripcion: "Dirección 1",
                direccion: e.cliente.direccion,
              },
              {
                codigo: 2,
                descripcion: "Dirección 2",
                direccion: e.cliente.direccion2,
              },
              { codigo: 3, descripcion: "Otra dirección ", direccion: " " },
            ];
            _this.RDireccionDomicilio = JSON.parse(JSON.stringify(jsn));
            //this.OpenFacturaDomicilio()
            //  e.codDir
            // console.log(this.RDireccionDomicilio)
            _this.domiciliofactura.telefonoPrincipal = e.telefonoDomicilio
            var LIST_DIRE =  jsn.filter((ee) => ee.codigo == e.codDir);
            _this.domiciliofactura.selctDireccion = LIST_DIRE[0]
            _this.domiciliofactura.direccion=LIST_DIRE[0].direccion
            // _this.ConstruirDomicilio(_this.Facturasventa, _this.devuelta);
             _this.AceptarFacturaDomicilio()
          }

           
        }, 800);
      });

      (async () => {
if(e.efectivo>0){
  this.Facturasventa.efectivo=JSON.parse(JSON.stringify(e.efectivo))
}

if(e.cxc>0){
  this.Facturasventa.cxc=JSON.parse(JSON.stringify(e.cxc))
}

if(e.cheque>0){
  this.Facturasventa.cheques=JSON.parse(JSON.stringify(e.cheque))
  this.Facturasventa.numeroCheque=e.numeroCheque
  this.bancoCheque=e.bancoCheque
}

if(e.transferencia>0){
  this.Facturasventa.transferencia=JSON.parse(JSON.stringify(e.transferencia))
  this.Facturasventa.numeroCheque=e.numeroCheque
  this.bancoCheque=e.bancoCheque
}

if(e.tarjeta>0){
  this.Facturasventa.tarjeta=JSON.parse(JSON.stringify(e.tarjeta))
  this.Facturasventa.numtar=JSON.parse(JSON.stringify(e.numtar))

  if(e.tipotar!=null){
    this.$axios
        .get(this.$hostname + this.$hName + "/tarjeta/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.length>0){
            var ObjProRow = res.data.filter((ee) => ee.codigo == e.tipotar);
            if (ObjProRow.length > 0) { 
              this.tipoTarjeta=ObjProRow[0]
            }
          }
            
        });

  }
  
}

// if (e.cliente != null) {
// setTimeout(function () {
//           var DATO={
//               cliente:e.cliente,
//               vendedor:e.vendedor,
//               tipoComp:e.tipoComprobante
//             }

//           _this.LimpiaInfProducto_DatoClienteSelectRestFact(DATO);

//         }, 400);
//       }else{
//           this.Facturasventa.descripcion=e.descripcion
//         }



})() 

      // this.RProductoCompraTemp=JSON.parse(JSON.stringify(this.RProductoCompra))


      /*RProductoCompra:Array[3]
      RProductoCompraOfercta:Array[0]
      RProductoCompraTemp:*/

      /*if(e.valordescuento!=null){
      
        
        var Descuentofactura={
          descuento:e.valordescuento,
          ValorPorc:true,
          DescuentoFacturaGlobal:true
        }
      
        this.Descuentofactura=Descuentofactura
        
      }*/



      //this.SalvarDescuentoFactura()

      /*e.facturastemporalesDetalleList.forEach(element => {
       var Link=`${element.productos.codigo }/${element.unidades.codigo }`
         this.$axios
             .get(
               this.$hostname + this.$hName + "/unidades-productos/find/"+Link,
               { headers: this.$store.getters.GetheadersAxios }
             )
             .then((res) => {
               var Obj=res.data
               Obj.cantidad=element.cantidad
               this.BuscPropiedadProductoSelct(Obj, 'add')
             });     
         }); */




      //clienteFactura
      // Facturasventa.vendedor


      /*   facturastemporalesDetalleList
         return
         this.RProductoCompraTemp = JSON.parse(
           JSON.stringify(e.facturastemporalesDetalleList)
         );
   
         
         this.RProductoCompra = JSON.parse(
           JSON.stringify(e.facturastemporalesDetalleList)
         );
         setTimeout(function () {
           _this.$refs.RefTextFieldProducto.focus();
           _this.CalcularTotalesProductosCompra();
         }, 500);
   */

      // var _this=this;
      // this.$axios.get(this.$hostname+this.$hName+'/temporales/'+e.secuencia+'/detalle',{headers:this.$store.getters.GetheadersAxios})
      // .then(res=>{
      // this.dialogRestaurarFactura=false,
      // this.RProductoCompra=res.data,
      // setTimeout(function(){
      // _this.$refs.RefTextFieldProducto.focus();
      // _this.CalcularTotalesProductosCompra()
      //  }, 500);

      // })
    },


findUnidadProductoConExistenciaAlmacen(VARLINK){
return  new Promise((resolve, reject) => {
// var VARLINK=`${codpro}/${coduni}`
EventBus.$emit("loading", true);
  this.$axios.get(this.$hostname + this.$hName + '/unidades-productos/findUnidadProductoConExistenciaAlmacen/'+ VARLINK, { headers: this.$store.getters.GetheadersAxios })
  .then(res => { 
    resolve(res.data)
    //  EventBus.$emit("loading", false); 
  })
  .catch(error => {
      // EventBus.$emit("loading", false);
      resolve(null)
    });  

  });

},


    
//Organizar Y preparar detalle factura temporal
async detalleRestaurarFactTem(listFtempDetalle,tipocomprobante){
      var OBJDATA={
        sucursal:this.caja.sucursal.codigo,
        permitirNegativos:this.configuraciones.configInv.permitirNegativos,
        facturaTempDetalle:listFtempDetalle,
        tipoComprobante:tipocomprobante
      }
      return new Promise((rresolve, reject) => { 
        this.$axios
            .post(
              this.$hostname + this.$hName + "/temporales/existenciaListFactTemDetalle",
              OBJDATA,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.loading(false)

             /**/ var _this=this;
               setTimeout(function(){  
                  rresolve(res.data)  
                }, 300);

              /*this.alerta(res.data.mensage, res.data.estado);
              this.cancelarFarmacoVigilancia();*/
            })
            .catch((error) => {
             /* this.loading(false)
              this.alerta("Dato no borrado correctamente", "error")*/
            }
            );
      });
    },

async detalleRestaurarFactTem2(listFtempDetalle){
  var OBJ={
    ListTem:[],
    BOOL_existencia:false
  }
  return new Promise((rresolve, reject) => { 
    var bar = new Promise((resolve, reject) => { 
    // (async () => { })()
  listFtempDetalle.forEach(async(element, index, array) => {
    (async () => {
            //findUnidadProductoConExistenciaAlmacen    
            var Link = `${element.productos.codigo}/${element.unidades.codigo}/${this.caja.sucursal.codigo}`
             const resUniPro =  await this.findUnidadProductoConExistenciaAlmacen(Link);
           
           /* 
            Obj.precioOrig = res.data.precio
                Obj.precioDig = res.data.precio
              this.$axios
              .get(
                this.$hostname + this.$hName + "/unidades-productos/findUnidadProductoConExistenciaAlmacen/" + Link,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {var resUniPro=res.data});
            */
            
                if(resUniPro!=null){
                var Obj = resUniPro
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.valordescuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = true
                Obj.precio = element.precio
                Obj.precioOrig = resUniPro.precio
                Obj.precioDig = resUniPro.precio
                
      if (this.configuraciones.configInv.permitirNegativos == false) {
      if (resUniPro.productos.servicio==false && element.cantidad > resUniPro.existencias) {
        OBJ.BOOL_existencia=true
        }else{
          OBJ.ListTem.push(Obj)
          // this.BuscPropiedadProductoSelct(Obj, 'add')
        }

         }else{
         OBJ.ListTem.push(Obj)
            }
          }

            })()
              
            });
             resolve() 
            });

  bar.then(() => { 
    var _this=this;
    setTimeout(function(){ 
       rresolve(OBJ)  
       
      }, 500);

  });
        // OBJ.ListTem=ListDetalleTemp
    
         
   

         
      

        });

},

    // Impresión de un recibo.
    ImprimirRestaurarFact(d) {
      if (this.EjecutarPermisoPv == false) {
        this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(d))
        this.funcionValidaPermisoPv('AccImprimirRestaurarFact')
        return;
      }

      if (localStorage.printerSelect != null) {}
        // d.reimprimida=true
        // this.actualizarRestaurarFacReimprimida(d)
        var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
        var OBJ=d
        OBJ.imprimirPrefacturaSinencabezado=this.$store.getters.GetConfiguracionGeneral.configPv.imprimirPrefacturaSinencabezado
        var msg = {
          accion: "print",
          formato: this.caja.formatoimpresioncontado.trim(),
          impresora: impresora,
          data: this.configuraciones,
          fac: OBJ,
          caja: this.caja,
        };
        this.imprimir2(msg, "factura-temp",this.actualizarRestaurarFacReimprimida);
      
    },
    // Llamar a una función que verificará si el usuario tiene permiso para eliminar un registro. Si el
    // usuario no tiene permiso llama un panel para validar el usuario con permiso
    LlamadeleRestaurarFact(e) {
      if (this.EjecutarPermisoPv == false) {
        this.ObjEjecPermisoPv = JSON.parse(JSON.stringify(e))
        this.funcionValidaPermisoPv('AccDeleRestaurarFact')
        return;
      }
      this.deleRestaurarFact(e)
    },
    // Hacer una solicitud de publicación al servidor para borrar la factura temporal.
    deleRestaurarFact(e) {
      this.$axios
        .post(this.$hostname + this.$hName + "/temporales/borrado/", e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          // this.alerta(res.data.mensage, res.data.estado);
          // this.RestaurarFacturaBusqueda.buscar=""
          this.BuscarRestaurarFacturas();
        })
        .catch((error) =>
          this.alerta("Dato no eliminado correctamente.", "error")
        );
    },

    actualizarRestaurarFacReimprimida(e){
      this.$axios
        .post(this.$hostname + this.$hName + "/temporales/reimprimida/", e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RestaurarFactlistFact[e.indx].reimprimida=res.data
          // this.BuscarRestaurarFacturas();
        })
        .catch((error) =>
          this.alerta("Dato no eliminado correctamente.", "error")
        );

    },



    // Eliminación de una fila de una tabla.
    deleteConduce(e) {
      this.$axios
        .get(this.$hostname + this.$hName + "/conduce/deleteConduce/" + e.secuencia + "/" + e.documento, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.VerConducelistFactCliente = []
        })

    },
    // Creando un nuevo objeto con las propiedades del objeto Valecaja.
    newnewValecaja() {
      this.Valecaja = {
        valor: 0,
        beneficiario: "",
        Autorizado: "",
        Concepto: "",
        secuencia: 0,
        // fecha: new Date(),
        fecha:fechaNueva(),
        caja: null,
        numerocierre: 0,
        cajero: null,
      };
    },
    // Envío de una solicitud POST al servidor guardando el vale de caja.
    SalvarValecaja() {
      // this.Valecaja.secuencia=0
      // this.Valecaja.numerocierre= this.CierresCajas.cierrescajasPK.secuencia;
      // this.Valecaja.cajero = this.OtrasConfigPv.cajero;
      // this.Valecaja.caja= this.caja
      if (this.$refs.formCrearValeCaja.validate()) {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.caja.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
        this.CierresCajas.cierrescajasPK.secuencia = res.data;
        var Obj = {
        secuencia: 0,
        autorizadoPor: this.Valecaja.autorizadoPor,
        beneficiario: this.Valecaja.beneficiario,
        borrado: false,
        caja: this.caja,
        cajero: this.$store.getters.GetdatosInfUsu.usuario.codigo,
        //cajero:this.OtrasConfigPv.cajero,
        descripcion: this.Valecaja.descripcion,
        fecha: getFechaZone(this.Valecaja.fecha),
        numerocierre: this.CierresCajas.cierrescajasPK.secuencia,
        valor: this.Valecaja.valor,
        hora:getHoraActual(),
        nomCaj:this.$store.getters.GetOtrasConfigPv.cajero
      };
      this.loading(true)
      this.$axios
        .post(
          this.$hostname + this.$hName + "/valecaja/save",
          JSON.parse(JSON.stringify(Obj)),
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (localStorage.printerSelect != null) { }
          var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

            var msg = {
              accion: "print",
              formato: this.caja.formatoimpresioncontado.trim(),
              impresora: impresora,
              data: this.configuraciones,
              vale: res.data,
              caja: this.caja,
              cajero: this.$store.getters.GetOtrasConfigPv.cajero,
            };
            this.imprimir(msg, "vale");
         
          this.alerta("Vale de caja salvado correctamente!", "bien");
          this.cancelarValecaja();
        })
        .catch((error) => {
          this.alerta("Vale de caja no se salvo", "error");
        });



        });

      } else {
        this.alerta("Los campos en rojo son requeridos", "error");
      }

      //  this.$axios.get(this.$hostname + this.$hName + "/valecaja/findall",
      //           {headers:this.$store.getters.GetheadersAxios})
      //         .then(res => {console.log('respuesta'), console.log(res.data)});
    },
    // Cerrar el cuadro de diálogo y restablecer el formulario.
    cancelarValecaja() {
      this.dialogValecaja = false;
      this.newnewValecaja();
    },


    getUltimocierreCaja(){
      this.$axios
        .get(this.$hostname +this.$hName +"/cierrecaja/ultimocierre/" +
          this.caja.codigo +"/",{ headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;   
          var _this = this;
      setTimeout(function () {
            _this.UpdateConfigPv(_this.caja)
      }, 300);

        });
    },


    // Creando un nuevo objeto y asignándolo a la variable CierresCajas.
    newnewCierreCaja() {
      this.ListDenomMoneda = [];
      (this.Rmoneda = []),
        (this.RLisMon = []),
        (this.CierresCajas = {
          cierrescajasPK: { caja: 0, secuencia: 0 },
          cantidadtarjeta: 0,
          tarjeta: 0,
          cantidadcheques: 0,
          cheques: 0,
          cantidadrecibo: 0,
          recibos: 0,
          efectivo: 0,
          cierrecajaMonedaList: [],
          DenominacionCierrecajaList: [],
          datoTarjeta: null,
          cantidadtranferencia:0,
           transferencia:0
        }),
        (this.Totales.CierresCajas = {
          totalTotal: 0,
          totalDenomiacionvalor: 0,
        });
      this.loading(false)

        this.getUltimocierreCaja()
      
    },

   

    // Comprobando si el valor de veryFoms es verdadero. Si es verdadero, llamará a la función
    // validarCierreCarnet(). Si es falso llamará a la función PostSalvarCierreCaja(falso).
    SalvarCierreCaja() {
      var lccaja = JSON.parse(localStorage.caja);
      if (lccaja.veryFoms == true) {
        //if(this.tipoTarjeta.azul==true){return 0;}
        //if(this.tipoTarjeta.cardnet==true){this.validarCierreCarnet();return 0;}
        this.loading(true)
        this.validarCierreCarnet();
        return 0;
      } else {
        this.PostSalvarCierreCaja(false);
      }
    },

    // Envío de una solicitud POST al servidor para guardar el cierre caja.
    PostSalvarCierreCaja(verifone) {
      this.CierresCajas.cierrecajaMonedaList = this.RLisMon;
      this.CierresCajas.denominacionCierrecajaList = this.ListDenomMoneda;
      this.CierresCajas.cierrescajasPK.caja = this.caja.codigo;
      this.CierresCajas.sucursal = this.caja.sucursal;
      this.CierresCajas.cajero = this.$store.getters.GetOtrasConfigPv.cajero
      var MONEDABASE=this.$store.getters.GetConfiguracionGeneral.config.monedabase
      this.CierresCajas.moneda = MONEDABASE.codigo
      // this.CierresCajas.moneda = this.Monedafactura.codigo
        // var msg ={
        //           accion:"print",
        //           formato:this.caja.formatoimpresioncontado.trim(),
        //           impresora:JSON.parse(localStorage.printerSelect).nombre,
        //           data:this.configuraciones,
        //           cierre:JSON.parse(ooop),
        //           caja:this.caja,
        //           verifone:verifone,
        //           cajero:this.$store.getters.GetOtrasConfigPv.cajero
        //        //   fac:this.ReimprecionFacturaSeleccionada
        //         }
        //        this.imprimir(msg,"cierrescaja/imprimir")

        // var bool=true
        // if(bool==true){
        //   return 0;
        // }
        this.loading(true)
      this.$axios
        .post(
          this.$hostname + this.$hName + "/cierrecaja/saveOrUpdate/",
          this.CierresCajas,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CierresCajas = res.data.Cierre;
          // if (res.data.cierrescajasPK.secuencia>0) {}
          this.alerta("Cierre de caja salvado correctamente!!!", "bien");
          this.dialogCierreCaja = false;
          if (localStorage.printerSelect != null) {};
          var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
            var msg = {
              accion: "print",
              formato: this.caja.formatoimpresioncontado.trim(),
              impresora: impresora,
              data: this.configuraciones,
              cierre: res.data.Cierre,
              caja: this.caja,
              verifone: verifone,
              cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              ListFaccierre: res.data.ListFaccierre,
            }
            this.imprimir(msg, "cierre");
          
          this.alerta("Datos salvado correctamente !!!", "bien");
          this.newnewCierreCaja();
        })
        .catch((erro) => {
          this.loading(false)
          this.alerta("El cierre de caja no se salvo correctamente", "error");
        });
    },

    // Cerrar el cuadro de diálogo y restablecer el formulario.
    cancelarCierreCaja() {
      this.dialogCierreCaja = false;
      this.newnewCierreCaja();
    },

    verificarPermitirDomicilio(){
      // var LIST_MENS =  res.data.filter((ee) => ee.mensajero.nombre=== this.nuevoMensajeroSelect.nombre);
      /*/findall/mensajeroverificarcaja/{caja}"*/

      if(this.caja==null || !this.caja.hasOwnProperty('codigo')){
        this.alerta("No ha seleccionado una caja para hacer el cierre", "error");
        return
      }


      this.$axios.get(this.$hostname +this.$hName +"/Mesajeros/findall/mensajeroverificarcaja/"+this.caja.codigo,
          { headers: this.$store.getters.GetheadersAxios }).then((res) => {
            if(res.data==false){
              this.OpenCierreCaja()
            }else{
              this.alerta("No puede hacer cierre de caja porque tiene domicilio pendiente", "error");
            }

         /* if(res.data.length==0){
            // this.OpenCierreCaja()
          }else{this.alerta("No puede hacer cierre de caja porque tiene domicilio pendiente", "error");} */
        });

    },

    // Llamar a una API para obtener los datos y luego asignar esos valores a variable.
    OpenCierreCaja() {
      var _this = this;
      this.dialogCierreCaja = true;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.caja.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;
        });

      this.$axios
        .get(
          this.$hostname + this.$hName + "/denominacionmoneda/findallActivos/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          (this.ListDenomMoneda = res.data),
            setTimeout(function () {
              _this.CalcularDenominacion();
            }, 500);
        });
    },
    // Llamar a una API REST y obtener el último número de secuencia de la caja registradora.
    findCierreActual() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/cierrecaja/ultimocierre/" +
          this.caja.codigo +
          "/",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;
          this.updateConfiguracion();
        });
    },


    SelctCbMonedaCierre(e) {
      setTimeout(function () {

      }, 300);

      /*var _this = this;
            setTimeout(function () {
              if (e.moneda == null) {
                e.moneda = "";
              }
      
              if (e.moneda.hasOwnProperty('codigo')) {
                e.moneda = "";
                e.moneda = null;
                return;
              }
            }, 300);*/
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable Rmoneda.
    cargarMoneda() {
      this.$axios
        .get(this.$hostname + this.$hName + "/monedas/findallActivos/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmoneda = res.data));
    },
    AddMonedaCierrecaja() {
      var jsn = {
        codigo: 0,
        valor: 0,
        moneda: null,
      };
      this.RLisMon.push(jsn);
    },
    // Eliminar el elemento de la Tabla.
    deleMondaCierre(item) {
      const index = this.RLisMon.indexOf(item);
      confirm("¿ Desea eliminar este Moneda ?") &&
        this.RLisMon.splice(index, 1);
    },
    // Cálculo del total de las denominaciones y el total del efectivo.
    CalcularDenominacion() {
      var _this = this;
      var Lisdnm = JSON.parse(JSON.stringify(this.ListDenomMoneda));
      this.Totales.CierresCajas.totalDenomiacionvalor = 0;
      Lisdnm.forEach((element) => {
        element.Vtotal = element.cantidad * element.valor;
        if (element.denominacion === undefined) {
          element.denominacion = { codigo: element.codigo, descripcion: element.descripcion };
        }
        this.Totales.CierresCajas.totalDenomiacionvalor += element.Vtotal;
      });
      setTimeout(function () {
        _this.ListDenomMoneda = JSON.parse(JSON.stringify(Lisdnm));
      }, 500);
      this.CierresCajas.efectivo =
        this.Totales.CierresCajas.totalDenomiacionvalor;
      /*this.Totales.CierresCajas.totalTotal =
        this.Totales.CierresCajas.totalDenomiacionvalor +
        this.CierresCajas.cheques * this.CierresCajas.cantidadcheques +
        this.CierresCajas.recibos * this.CierresCajas.cantidadrecibo +
        this.CierresCajas.tarjeta * this.CierresCajas.cantidadtarjeta;*/
      this.Totales.CierresCajas.totalTotal =
        this.Totales.CierresCajas.totalDenomiacionvalor +
        this.CierresCajas.cheques + this.CierresCajas.recibos + this.CierresCajas.transferencia + this.CierresCajas.tarjeta;
    },
    OpenCoberturaSeguro() {
      if (this.clienteFactura == null) {
        // this.filtroTipoCliente = 2
        this.filtroTipoCliente = 3
        this.OpenEjecCoberturaSeguro = true;
        this.OpenDialogCrearEditarCliente();
      }
      if (this.clienteFactura != null) {
          if(this.clienteFactura.ars!=null && this.clienteFactura.ars.hasOwnProperty('codigo')){
            this.dialogCoberturaSeguro = true;
            this.OpenEjecCoberturaSeguro = false;
            this.objTemp = JSON.parse(JSON.stringify(this.CoberturaSeguro));
        // if (this.clienteFactura.ars != null) { }
            this.CoberturaSeguro.ars = JSON.parse(
            JSON.stringify(this.clienteFactura.ars)
          );
       
          }else{
            this.OpenEjecCoberturaSeguro = false;
            this.alerta("El cliente seleccionado no contiene ars asignado", "error");
            return
          }


      }
    },
    // Un método que se llama cuando el usuario hace clic en el botón cancelar de un modal.
    cancelarCoberturaSeguro() {
      this.dialogCoberturaSeguro = false;
      this.CoberturaSeguro = JSON.parse(JSON.stringify(this.objTemp));
      this.objTemp = null;
      this.CalcularTotalesProductosCompra();
    },

    // Creando un nuevo objeto llamado CoberturaSeguro.
    newnewCoberturaSeguro() {
      this.CoberturaSeguro = {
        ars: null,
        cobertura: 0,
        autorizacion: 0,
        medico: "",
        exequartur: "",
        especialidad: "",
        diagnostico: "",
      };
    },
    // Guardando los datos en el formulario y luego actualizando los datos en la tabla de los productos con la cobertura.
    async SalvarCoberturaSeguro() {
      if (this.$refs.formCrearCobertura.validate()) {
     

if(this.CoberturaSeguro.ars.hasOwnProperty('letra')&& this.CoberturaSeguro.ars.letra!=null
&& this.CoberturaSeguro.ars.letra.length>0){
  if(this.CoberturaSeguro.ars.letra!=this.CoberturaSeguro.autorizacion.substr(0,1)){
    this.alerta("La autorización de la ars no coincide con la autorización colocada", "error");
    return
  }
}

        this.CoberturaSeguro.autorizacion
        this.CoberturaSeguro.ars.letra


        this.objTemp = null;
        this.dialogCoberturaSeguro = false;
        (async () => {

        if(this.Totales.totalGeneral.descuento>0 && this.CoberturaSeguro.cobertura>0){
        this.Descuentofactura.descuento=0
        this.Descuentofactura.ValorPorc=false
        await this.SalvarDescuentoFactura()
          }

          this.RProductoCompraTemp.forEach(element => {
          element.cobertura = 0
            });

        this.RProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp))
        var _this = this;
        setTimeout(function () {
           _this.CalcularTotalesProductosCompra();
        }, 500);
        })() 
        
         
        

      } else {
        this.alerta("Debe completar los datos de la cobertura", "error");
      }
        
    },



    coberturaSeguroKeyCtrl(e){
      
    if (e.keyCode ==17 ) {
         this.SalvarCoberturaSeguro()
      }


    },

    getDocumentoOtrosIngresos() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/documentocxc/find/" +
          this.caja.sucursal.otrosIngresos,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.OtrosIngreso.documento = res.data;
        });
    },
    // Creando un nuevo objeto llamado OtrosIngreso.
    newnewOtrosIngreso() {
      this.OtrosIngreso = {
        //secuencia2: 12
        //secuencia: 20
        activo: true,
        caja: 1,
        concepto: "",
        documento: null,
        fecha: new Date(),
        identificacion: "",
        nombre: "",
        referencia: "",
        telefono: "",
        tipoRecibo: null,
        valor: 0,
        contabilidad: null,
      };
    },
    // Un método que se llama cuando el usuario hace clic en el botón cancelar del cuadro de diálogo.
    cancelarOtrosIngreso() {
      this.dialogOtrosIngreso = false;
    },
    contabilidadOtrosIngreso() {
      if (this.OtrosIngreso.valor == 0) {
        return;
      }
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/contabilidad/crear-contabilidad-cxc/" +
          this.caja.sucursal.otrosIngresos +
          "/" +
          this.OtrosIngreso.valor +
          "/0/0/0/0/0/0",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.OtrosIngreso.contabilidad = res.data;
        });
    },
    openCashDrawer() {
      if (localStorage.printerSelect != null) {}
      var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
        var msg = {
          accion: "print",
          formato: this.caja.formatoimpresioncontado.trim(),
          impresora: impresora,
          caja: this.caja,
          cajero: this.$store.getters.GetOtrasConfigPv.cajero,
        };
        this.imprimir(msg, "open");
      
    },
    // Guardando los datos en la base de datos de otros ingreso.
    SalvarOtrosIngreso() {
      if (this.$refs.formOtrosIngresos.validate()) {
        this.OtrosIngreso.tiporecibo = this.OtrosIngreso.tipoRecibo.codigo;
        this.OtrosIngreso.usuario = this.$store.getters.GetOtrasConfigPv.cajero;
        this.loading(true)
        this.$axios
          .post(
            this.$hostname + this.$hName + "/otrosingresos/salvar",
            this.OtrosIngreso,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.alerta("Dato guardado correctamente", "bien2");
            this.newnewOtrosIngreso();
            this.dialogOtrosIngreso = false;
            if (localStorage.printerSelect != null) { }
            var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
              var msg = {
                accion: "print",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                recibo: res.data,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
              this.imprimir(msg, "otros-ingreso");
           
          })
          .catch((error) =>
            this.alerta("Datos no guardado correctamente", "error")
          );
      } else {
        this.alerta("Debe completar los datos de la cobertura", "error");
      }
    },
    // Creando un nuevo objeto con dos propiedades, fechaInicio y fechaFinal.
    newnewImpresoraFiscal() {
      this.CierreRangoFecha = {
        fechaInicio: fechaNueva(),
        fechaFinal: fechaNueva(),
      };
      this.CierreRangoNumero = {
        inicio: 0,
        final: 0,
      };
    },
    // Cerrar el cuadro de diálogo y restablecer el formulario.
    cancelarImpresoraFiscal() {
      this.dialogImpFiscal = false;
      this.newnewImpresoraFiscal();
    },

    OpenDialogCambioDivisa() {
      this.dialogCambioDivisa = true;
      this.NewNewCambioDivisa();
      this.CargarBusquedaCambioDivisa();
    },
    // Creando un nuevo objeto y asignándolo a la variable CambioDivisa.
    NewNewCambioDivisa() {
      this.CambioDivisa = {
        id: 0,
        nombreCliente: "",
        telefonoClietne: "",
        cedulaCliente: "",
        operacion: true,
        tasaCompra: 0,
        tasaVenta: 0,
        cantidadRecivida: 0,
        devuelta: 0,
        cuadre: 0,
        caja: 0,
        usuario: null,
        fecha: new Date(),
        usuarioAnulado: null,
        fechaAnulada: null,
        cantidadEntregada: 0,
        cantidadComprada: 0,
        activada: true,
        direccionCliente: null,
        cajero: null,
        borrado: false,
        moneda: null,
      };
      this.CambioDivisaBusqueda = {
        secuencia: 0,
        cliente: "0",
        fechaInicial: fechaNueva(),
        fechaFinal: fechaNueva(),
      };
      this.loading(false)

    },
    // Envío de una solicitud al servidor y guarda la divisa.
    SalvarCambioDivisa() {

      if (this.CambioDivisa.devuelta < 0) {
        this.alerta("La cantidad Cantidad recibida es menor a la Cantidad a entregar", "error");
        return;
      }

      if (this.$refs.formCambioDivisa.validate()) {
        this.CambioDivisa.caja = this.caja.codigo;
        this.CambioDivisa.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
        var User = ""
      if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
        User = this.$store.getters.GetdatosInfUsu.usuario.username
      } else {
        User = this.$store.getters.GetdatosInfUsu.usuario.nombre
      }
      this.CambioDivisa.cajero=User
     this.CambioDivisa.tasaCompra =this.CambioDivisa.moneda.tasaCompra
      this.CambioDivisa.tasaVenta=this.CambioDivisa.moneda.tasaVenta
        this.loading(true)
        this.$axios
          .post(
            this.$hostname + this.$hName + "/divisa/save/",
            this.CambioDivisa,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {  
            var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

            var fecha =new Date().toISOString().replaceAll("T", " ").trim();
      //console.log(fecha.split(".")[0])
            var obj=res.data
            obj.nuevaFecha=fecha
        var msg = {
        accion: "print",
        formato: this.caja.formatoimpresioncontado.trim(),
        impresora: impresora,
        data: this.configuraciones,
        divisa:obj ,
        caja: this.caja,
        cajero: this.$store.getters.GetOtrasConfigPv.cajero,
      };
      this.imprimir(msg, "divisa");

            var _this = this;
      setTimeout(function () {
            _this.alerta("Divisa guardada Correctamente !!!", "bien");
            _this.dialogCambioDivisa = false
            _this.NewNewCambioDivisa()

      }, 300);

          });
      }
    },
    // Llamar a una api  servidor y borra la divisa 
    anularCambioDivisa() {

      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccAnularCambioDivisa')
        return;
      }

      if (this.$refs.formCambioDivisa.validate()) {
        this.CambioDivisa.caja = this.caja.codigo;
        this.$axios
          .post(
            this.$hostname + this.$hName + "/divisa/Borrar/",
            this.CambioDivisa,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => (this.RVendedor = res.data));
      }
    },
    // Impresión de un recibo de cambio divisa.
    imprimirCambioDivisa() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccImprimirCambioDivisa')
        return;
      }
      var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

      var msg = {
        accion: "print",
        formato: this.caja.formatoimpresioncontado.trim(),
        impresora: impresora,
        data: this.configuraciones,
        divisa: this.CambioDivisa,
        caja: this.caja,
        cajero: this.$store.getters.GetOtrasConfigPv.cajero,
      };
      this.imprimir(msg, "divisa");
    },
    // Llamar a una API REST y obtener una lista de objetos.
    CargarBusquedaCambioDivisa() {
      //CargarBusquedaRecetas:OpenDialogCrearEditarCliente:CambioDivisalist,CambioDivisaDatostabla,
      //CambioDivisaBusqueda:secuencia,cliente,fechaInicial,fechaFinal
      //http://10.0.0.95:8080/micromarket/services/divisas/filtroDivisa/jc/0/2021-01-01/2021-10-15

      if (this.CambioDivisaBusqueda.fechaInicial.toString().length == 0) {
        this.CambioDivisaBusqueda.fechaInicial = this.fechaNueva();
      }
      if (this.CambioDivisaBusqueda.fechaFinal.toString().length == 0) {
        this.CambioDivisaBusqueda.fechaFinal = this.fechaNueva();
      }

      this.CambioDivisalist = [];

      if (this.CambioDivisaBusqueda.cliente.toString().length == 0) {
        this.CambioDivisaBusqueda.cliente = "0";
      }
      if (this.CambioDivisaBusqueda.secuencia.toString().length == 0) {
        this.CambioDivisaBusqueda.secuencia = "0";
      }

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/divisa/filtroDivisa/" +
          this.CambioDivisaBusqueda.cliente +
          "/" +
          this.CambioDivisaBusqueda.secuencia +
          "/" +
          this.CambioDivisaBusqueda.fechaInicial +
          "/" +
          this.CambioDivisaBusqueda.fechaFinal +
          "",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.CambioDivisalist = res.data;
        });

      //this.dialogRecetas=true
    },

    // Convertir la fecha en una cadena y setea los valores a un formulario.
    CambioDivisaDatostabla(e) {
      var cm = JSON.parse(JSON.stringify(e));
      cm.fe = new Date(e.fecha).toISOString().substr(0, 10);
      this.CambioDivisa = JSON.parse(JSON.stringify(cm));
    },
    OpenVendedores() {
      if (this.Facturasventa.vendedor != null) {
        this.objTemp = JSON.parse(JSON.stringify(this.Facturasventa.vendedor));
      }
      this.PanelMenuOpcionBotones = false;
      this.PanelMenuOpcionVendedor = true;
    },

    // Guardar el valor seleccionado del menú desplegable en el objeto.
    SalvarVendedores() {
      if (this.Facturasventa.vendedor != null) {
        this.objTemp = null;
        this.PanelMenuOpcionBotones = true;
        this.PanelMenuOpcionVendedor = false;
      }
    },
    // Tomando el objeto de la matriz y asignándolo al objeto en el formulario.
    cancelarVendedores() {
      this.Facturasventa.vendedor = JSON.parse(JSON.stringify(this.objTemp));
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionVendedor = false;

      var _this = this;
          setTimeout(function () { 
            if(_this.Facturasventa.vendedor==null){
              _this.verificarVendedor()
            }
             
          }, 300)
      //this.NewNewVendedores()
    },
    // Un método que se llama cuando se hace clic en un botón y resetea un objeto.
    NewNewVendedores() {
      this.Facturasventa.vendedor = null;
      //cancelarVendedores,NewNewVendedores (dialogCrearEditarCliente=false, NewclienteSeleccionado())clienteSeleccionado
    },
    // Comprobando si el valor seleccionado es nulo o indefinido.
    SelctCbVendedorFactura() {

      var _this = this;
      setTimeout(function () {
        if (_this.Facturasventa.vendedor == null) {
          _this.Facturasventa.vendedor = "";
        }

        if (!_this.Facturasventa.vendedor.hasOwnProperty('codigo')) {
          _this.Facturasventa.vendedor = "";
          _this.Facturasventa.vendedor = null;
          return;
        }
      }, 300);


      /*   if (this.Facturasventa.vendedor == null) {
           this.Facturasventa.vendedor = "";
         }
         if (this.Facturasventa.vendedor.codigo == undefined) {
           this.Facturasventa.vendedor = "";
           this.alerta("Seleccione un Vendedor", "error");
           return;
         }*/
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RVendedor.
    cargarVendedorFactura() {
      this.$axios
        .get(this.$hostname + this.$hName + "/vendedores/findallactiv", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.RVendedor = res.data.filter((ee) => ee.sucursal==null || ee.sucursal!=null&& ee.sucursal.codigo==this.caja.sucursal.codigo);
          // this.RVendedor = res.data
        }
        
        
        );
    },

    OpenMedico() {
      if (this.SelectMedicoFactura != null) {
        this.objTemp = JSON.parse(JSON.stringify(this.SelectMedicoFactura));
      }
      this.PanelMenuOpcionBotones = false;
      this.PanelMenuOpcionMedico = true;
    },
    NewNewMedico() {
      this.SelectMedicoFactura = null;
    },
    SalvarMedico() {
      if (this.SelectMedicoFactura != null) {
        this.objTemp = null;
        this.PanelMenuOpcionBotones = true;
        this.PanelMenuOpcionMedico = false;
      }
    },
    // Tomando el objeto que se creó en la sección de datos y copiándolo al objeto SelectMedicoFactura.
    cancelarMedico() {
      this.SelectMedicoFactura = JSON.parse(JSON.stringify(this.objTemp));
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionMedico = false;
      //this.NewNewVendedores()
    },
    // Hacer una solicitud GET al servidor y devolver los datos del medico.
    cargarMedico() {
      this.$axios
        .get(this.$hostname + this.$hName + "/medicoafiliados/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RMedico = res.data));
    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbMedicoFactura() {
      if (this.SelectMedicoFactura == null) {
        this.SelectMedicoFactura = "";
      }
      if (this.SelectMedicoFactura.codigo == undefined) {
        this.SelectMedicoFactura = "";
        this.alerta("Seleccione un Vendedor", "error");
        return;
      }
    },
    OpenDescuentoFactura() {
      this.objTempaplicarDescuentoGlobalFacturaXencimaMaximo=null
      this.objTemp = JSON.parse(JSON.stringify(this.Descuentofactura));
      this.PanelMenuOpcionBotones = false;
      this.PanelMenuOpcionDescuentoFactura = true;
      var _this = this;
      setTimeout(function () {
        _this.Descuentofactura.descuento=0
      }, 600);

     // .select()
      var _this = this;
      setTimeout(function () {
         _this.$refs.RefTextFieldDescuentoFactura.focus(); 
      }, 1000);
    },
    // Creando un nuevo objeto y asignándolo a la variable Descuentofactura.
    NewNewDescuentoFactura() {

      this.Descuentofactura.ValorPorc = true
      this.Descuentofactura.descuento = 0
      /*this.Descuentofactura = {
         ValorPorc: true,
         descuento: 0,
         DescuentoFacturaGlobal: false
       };*/
      //PanelMenuOpcionDescuentoFactura,Panel Descuentofactura,cancelarDescuentoFactura,NewNewDescuentoFactura,OpenDescuentoFactura
    },
    // Cálculo del descuento de los productos en el carrito de compras.
    async SalvarDescuentoFactura() {
      if (this.$refs.formDescuentoFactura.validate()) {
        this.objTemp = null;
        this.PanelMenuOpcionBotones = true;
        this.PanelMenuOpcionDescuentoFactura = false;
        this.Descuentofactura.DescuentoFacturaGlobal = true

        //this.RProductoCompra

        var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
        var MONEDAFACTURATASA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa)

       /* if (this.Descuentofactura.ValorPorc == true) {
          this.Descuentofactura.descuento =(((this.Descuentofactura.descuento*MONEDAFACTURATASA))   )
            }*/

        var EXEC_VALIDAR_DESC=false
        this.objTempaplicarDescuentoGlobalFacturaXencimaMaximo=JSON.parse(JSON.stringify(this.Descuentofactura))

       

        
        var bar = new Promise((resolve, reject) => {
         // (async () => { 
        // this.RProductoCompraTemp.forEach(element => {
          //await 
          Promise.all(this.RProductoCompraTemp.map(async (element) => {
          var Cant = element.cantidad

          var desc = (this.Descuentofactura.descuento)
          element.descuento = JSON.parse(JSON.stringify(this.Descuentofactura.descuento))
          element.ValorPorc = JSON.parse(JSON.stringify(this.Descuentofactura.ValorPorc))
          var PrecioVal = 0

          if (this.ConfImpfiscal == true) {
            PrecioVal = element.precio
          } else {

            if (element.productos.incluirimpuestoenprecio == true) {
              if (element.productos.impuesto != null) {
                PrecioVal = (element.precio) / (1 + (element.productos.impuesto.tasa / 100))
              } else { PrecioVal = element.precio }
            } else {

              PrecioVal = element.precio
            }

          }

          var DescPorencima = false
          var SuperAdmin = this.$store.getters.GetdatosInfUsu.usuario.sudo
          if (this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.length > 0) {
            var ObjPerm = this.$store.getters.GetdatosInfUsu.usuario.usuarioPermisoPvList.filter((ee) => ee.fn == 3 && ee.op == 14);
            if (ObjPerm.length > 0) {
              DescPorencima = ObjPerm[0].permiso
            } else {
              DescPorencima = false
            }
          }
          
          if (this.Descuentofactura.ValorPorc == true) {
          /*var valorDesc = ((element.descuentoKeys * 100) / (PrecioVal * Cant));
            var DescOdeMax = ((element.descuentoKeys * 100) / (PrecioVal * Cant)); */
            var valorDesc = (((desc*MONEDAFACTURATASA) * 100) / (PrecioVal * Cant));
            var DescOdeMax = (((desc*MONEDAFACTURATASA) * 100) / (PrecioVal * Cant));

            // console.log(`SuperAdmin=>${SuperAdmin};;DescPorencima=>${DescPorencima}`)
            if (SuperAdmin == false && DescPorencima == false) {
              if (valorDesc > element.productos.descuentomaximo) {
                var valorrr = ((element.productos.descuentomaximo * (PrecioVal * Cant)) / 100)
                element.descuento = valorrr
                DescOdeMax = element.productos.descuentomaximo
                EXEC_VALIDAR_DESC=true
              }
            }

            element.DescValor = (element.descuento*MONEDAFACTURATASA)
            element.valordescuento = element.descuento
            element.descuento = DescOdeMax

          } else {
            if (SuperAdmin == false && DescPorencima == false) {
              if (desc > element.productos.descuentomaximo) {
                element.descuento = element.productos.descuentomaximo
                EXEC_VALIDAR_DESC=true
              }
            }
            element.DescValor = ((element.descuento * (PrecioVal * Cant)) / 100)
            element.valordescuento = ((element.descuento * (PrecioVal * Cant)) / 100)
            
          }
          // Calculo de itbis en producto
        }));
     // })()
        // });
        resolve()
      });


       bar.then(() => {console.log('SalvarDescuentoFactura:All done!');
       this.RProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp))
        var _this = this;
        setTimeout(function () {
          _this.CalcularTotalesProductosCompra();

        if(EXEC_VALIDAR_DESC==true){
          _this.funcionValidaPermisoPv('AccDescuentoGlobalFactura')
        }

        }, 500);
      });


        
      
        
      } else {
        this.alerta("Debe digitar un descuento", "error");
      }

    },

    //Aplicando Descuento Global Factura
    async aplicarDescuentoGlobalFacturaXencimaMaximo(){
     
     var DESCUENTO_FACTURA =JSON.parse(JSON.stringify(this.objTempaplicarDescuentoGlobalFacturaXencimaMaximo))
       
    //  this.RProductoCompraTemp.forEach(element => {});

    await Promise.all(this.RProductoCompraTemp.map(async (element) => { 
          var Cant = element.cantidad
          var desc = DESCUENTO_FACTURA.descuento
          element.descuento = JSON.parse(JSON.stringify(DESCUENTO_FACTURA.descuento))
          element.ValorPorc = JSON.parse(JSON.stringify(DESCUENTO_FACTURA.ValorPorc))

          var PrecioVal = 0


          if (this.ConfImpfiscal == true) {
            PrecioVal = element.precio
          } else {

            if (element.productos.incluirimpuestoenprecio == true) {
              if (element.productos.impuesto != null) {
                PrecioVal = (element.precio) / (1 + (element.productos.impuesto.tasa / 100))
              } else { PrecioVal = element.precio }
            } else {

              PrecioVal = element.precio
            }

          }
          
          if (DESCUENTO_FACTURA.ValorPorc == true) {

            var valorDesc = ((desc * 100) / (PrecioVal * Cant));
            var DescOdeMax = ((desc * 100) / (PrecioVal * Cant));

            element.DescValor = element.descuento
            element.valordescuento = element.descuento
            element.descuento = DescOdeMax

          } else {
           
            element.DescValor = ((element.descuento * (PrecioVal * Cant)) / 100)
            element.valordescuento = ((element.descuento * (PrecioVal * Cant)) / 100)
            
          }
          // Calculo de itbis en producto
        }));


        this.RProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp))

        var _this = this;
        setTimeout(function () {
          _this.CalcularTotalesProductosCompra();
          _this.objTempaplicarDescuentoGlobalFacturaXencimaMaximo
        }, 500);
   
    },




    // Un método que se llama cuando el usuario hace clic en un botón cancelar la operacion. 
    cancelarDescuentoFactura() {
      this.Descuentofactura = JSON.parse(JSON.stringify(this.objTemp));
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionDescuentoFactura = false;
      //this.NewNewVendedores()
      this.CalcularTotalesProductosCompra();
    },
    OpenRetencionFactura() {
      this.objTemp = JSON.parse(JSON.stringify(this.Retencionfactura));
      this.PanelMenuOpcionBotones = false;
      this.PanelMenuOpcionRetencionFactura = true;
      var _this = this;
      setTimeout(function () {
        _this.$refs.RefTextFieldRetencionFactura.focus();
      }, 500);
    },
    // Creando un nuevo objeto llamado Retencionfactura y asignándolo a la propiedad de datos
    NewNewRetencionFactura() {
      this.Retencionfactura = {
        ValorPorc: true,
        Retencion: 0,
      };
    },
    // Activa las variables necesarios para la retenciones de los productos
    SalvarRetencionFactura() {
      this.objTemp = null;
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionRetencionFactura = false;
      this.CalcularTotalesProductosCompra();
    },
    // Tomando el objeto que se está editando y copiándolo a un objeto temporal.
    cancelarRetencionFactura() {
      this.Retencionfactura = JSON.parse(JSON.stringify(this.objTemp));
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionRetencionFactura = false;
      //this.NewNewVendedores()
      this.CalcularTotalesProductosCompra();
    },
    OpenRecargoFactura() {
      this.objTemp = JSON.parse(JSON.stringify(this.Recargofactura));
      this.PanelMenuOpcionBotones = false;
      this.PanelMenuOpcionRecargoFactura = true;
      var _this = this;
      setTimeout(function () {
        _this.$refs.RefTextFieldRecargoFactura.focus();
      }, 500);
    },
    // Creando un nuevo objeto con las propiedades ValorPorc y Retención.
    NewNewRecargoFactura() {
      this.Retencionfactura = {
        ValorPorc: true,
        Retencion: 0,
      };
    },
    // Agregar una nueva propiedad al objeto de de la lista de productos a comprar.
    async SalvarRecargoFactura() {
      this.objTemp = null;
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionRecargoFactura = false;


      //this.RProductoCompraTemp.forEach(element => {});

      await Promise.all(this.RProductoCompraTemp.map(async (element) => { 
        var Cant = element.cantidad
        element.recargo = JSON.parse(JSON.stringify(this.Recargofactura.recargo))
        element.ValorPorcRecarg = JSON.parse(JSON.stringify(this.Recargofactura.ValorPorc))

        if (element.ValorPorcRecarg == true) {
          element.RecarValor = element.recargo
        } else {
          element.RecarValor = ((element.recargo * (element.precio * Cant)) / 100)

        }
      }));
      



      this.RProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp))

      var _this = this;
      setTimeout(function () {
        _this.CalcularTotalesProductosCompra();
      }, 500);


    },
    // Tomando el objeto que se creó en la sección temporal y copiándolo al objeto que se está
    // utilizando en el formulario y activa el panel necesario.
    cancelarRecargoFactura() {
      this.Recargofactura = JSON.parse(JSON.stringify(this.objTemp));
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionRecargoFactura = false;
      this.CalcularTotalesProductosCompra();
    },
    OpenMonedaFactura() {
      this.objTemp = JSON.parse(JSON.stringify(this.Monedafactura));
      this.PanelMenuOpcionBotones = false;
      this.PanelMenuOpcionMonedaFactura = true;
    },
    // Creando una nueva objeto de la variable MonedaFactura.
    NewNewMonedaFactura() {
      this.Monedafactura = null;
    },
    // Guardar la moneda de la factura.

   
    SalvarMonedaFactura() {
      this.UpdateConfigMoneda();

      this.objTemp = null;
      /*this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionMonedaFactura = false;*/
      this.actualizarDecimal=false
      EventBus.$emit("loading", true);
      var _this = this;
        setTimeout(function () {
          _this.PanelMenuOpcionBotones = true;
          _this.PanelMenuOpcionMonedaFactura = false;
          _this.actualizarDecimal=true
          _this.calculostotales()
          EventBus.$emit("loading", false);
        }, 500);

      //cambio por cambio de moneda(01-03-2024):this.CalcularTotalesProductosCompra();
    },
    // Un método que se llama cuando el usuario hace clic en el botón 
    //cancelar y activa o inavilita el panel de monea.
    cancelarMonedaFactura() {
      this.Monedafactura = JSON.parse(JSON.stringify(this.objTemp));
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionMonedaFactura = false;
    //cambio por cambio de moneda(01-03-2024): this.CalcularTotalesProductosCompra();


      //this.NewNewMonedaFactura()
      //  Moneda Factura, PanelMenuOpcionMonedaFactura, cancelarMonedaFactura,NewNewMonedaFactura,SalvarMonedaFactura
      // Monedafactura:,OpenMonedaFactura
    },

    // Convertir los valores de las entradas a la nueva moneda.
    UpdateConfigMoneda() {
      var OtrasConfPv = this.$store.getters.GetOtrasConfigPv;
      OtrasConfPv.moneda = this.Monedafactura.descripcion;
      var TasaMmon = this.Monedafactura.tasa;

      if (this.Facturasventa.efectivo > 0) {
        this.Facturasventa.efectivo = JSON.parse(
          JSON.stringify(this.Facturasventa.efectivo / TasaMmon)
        );
      }

      if (this.Facturasventa.tarjeta > 0) {
        this.Facturasventa.tarjeta = JSON.parse(
          JSON.stringify(this.Facturasventa.tarjeta / TasaMmon)
        );
      }

      if (this.Facturasventa.cheques > 0) {
        this.Facturasventa.cheques = JSON.parse(
          JSON.stringify(this.Facturasventa.cheques / TasaMmon)
        );
      }

      if (this.Facturasventa.transferencia > 0) {
        this.Facturasventa.transferencia = JSON.parse(
          JSON.stringify(this.Facturasventa.transferencia / TasaMmon)
        );
      }

      if (this.Facturasventa.cxc > 0) {
        this.Facturasventa.cxc = JSON.parse(
          JSON.stringify(this.Facturasventa.cxc / TasaMmon)
        );
      }

      if (this.Facturasventa.cupones > 0) {
        this.Facturasventa.cupones = JSON.parse(
          JSON.stringify(this.Facturasventa.cupones / TasaMmon)
        );
      }

      if (this.Facturasventa.puntos > 0) {
        this.Facturasventa.puntos = JSON.parse(
          JSON.stringify(this.Facturasventa.puntos / TasaMmon)
        );
      }

      if (this.Facturasventa.valorNc > 0) {
        this.Facturasventa.valorNc = JSON.parse(
          JSON.stringify(this.Facturasventa.valorNc / TasaMmon)
        );
      }
    },

    OpenComentarioFactura() {
      this.objTemp = JSON.parse(JSON.stringify(this.Otros));
      this.PanelMenuOpcionBotones = false;
      this.PanelMenuOpcionComentarioFactura = true;
    },
    // Creando un nuevo objeto llamado Otros y asignándolo al objeto a la factura.
    NewNewComentarioFactura() {
      this.Otros = {
        comentario: "",
        numeroOrden: "",
        ShipTo: "",
        incoterms: "",
      };
    },
    // Poniendo el valor de la variable PanelMenuOpcionBotones a verdadero y
    // PanelMenuOpcionComentarioFactura a falso.
    SalvarComentarioFactura() {
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionComentarioFactura = false;
    },
    // Establecer el valor de la entrada en una cadena vacía.
    cancelarComentarioFactura() {
      this.Facturasventa.comentario = "";
      this.Facturasventa.numeroOrden = "";
      this.Facturasventa.ShipTo = "";
      this.Facturasventa.incoterms = "";
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionComentarioFactura = false;
    },
    // Una función que se llama cuando se hace clic en un botón y coloca los datos necesario 
    //para que la factora sea adomicilio.
    AceptarFacturaDomicilio() {
      this.PanelMenuOpcionFacturaDomicilio = false
      this.PanelMenuOpcionBotones = true
      this.PanelMenuOpcionFacturaDomicilio = false
    },

    // Establecer el valor de la variable en nulo.
    cancelarFacturaDomicilio() {
      this.domiciliofactura = null;
      this.RDireccionDomicilio = [];
      this.PanelMenuOpcionBotones = true;
      this.PanelMenuOpcionFacturaDomicilio = false;
    },

    CargarRSucursal() {

      this.$axios.get(this.$hostname + this.$hName + "/sucursal/findallActivos/", { headers: this.$store.getters.GetheadersAxios }).then(res => {
        this.RSucursal = JSON.parse(JSON.stringify(res.data));
      });

    },
    SelctCbSucursal() {
      var _this = this;
      setTimeout(function () {
        if (_this.sucursalFactTemporal == null) {
          _this.sucursalFactTemporal = "";
        }

        if (!_this.sucursalFactTemporal.hasOwnProperty('codigo')) {
          _this.sucursalFactTemporal = "";
          _this.sucursalFactTemporal = null;
          _this.sucursalFactTemporal=_this.caja.sucursal
          return;
        }
      }, 300);
    },

    SelctCbSucursalFactTempSeach() {
      var _this = this;
      setTimeout(function () {
        if (_this.RestaurarFacturaBusqueda.sucursal == null) {
          _this.RestaurarFacturaBusqueda.sucursal = "";
        }

        if (!_this.RestaurarFacturaBusqueda.sucursal.hasOwnProperty('codigo')) {
          _this.RestaurarFacturaBusqueda.sucursal = "";
          _this.RestaurarFacturaBusqueda.sucursal = null;
          return;
        }
      }, 300);
    },

    OpenDialogGuardartemporal() {
      if (this.RProductoCompra.length == 0) {
        this.alerta("Debe seleccionar al menos un producto", "error");
        return;
      }

      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccGuardartemporal')
        return;
      }

      var vend = this.$store.getters.GetConfiguracionGeneral.configPv.vendedorobligatorio

      if (vend == true && this.Facturasventa.vendedor == null) {
        this.alerta("Debe seleccionar un vendedor", "error");
        return;
      }

      
      if (this.clienteFactura != null) {
        this.nombreFactTem = this.clienteFactura.nombre;
        if (this.cambiarDestinoSucursal == true && this.sucursalFactTemporal == null) {
          this.dialogGuardartemporal = true;
          this.sucursalFactTemporal=this.caja.sucursal
          this.focusRefTextFieldNombreFactTem()
          return
        }

        var _this = this;
        setTimeout(function () {
          _this.loading(true)
          _this.guardartemporal();
        }, 500);



      } else {
        this.focusRefTextFieldNombreFactTem()
        if(this.Facturasventa.hasOwnProperty('nombreFactTem')){
          this.nombreFactTem =JSON.parse(JSON.stringify(this.Facturasventa.nombreFactTem)) 
        } 
        this.dialogGuardartemporal = true;
      }
    },

    focusRefTextFieldNombreFactTem(){
      var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldNombreFactTem.focus();
        }, 500);
    },

    finalizarGuardartemporalKeyCtrl(e){
      
      if (e.keyCode ==17 ) {
           this.ValidarFomsguardartemporal()
        }
  
  
      },

    ValidarFomsguardartemporal() {
      this.Facturasventa.facturastemporalesDetalle = [];
      if (this.RProductoCompra.length == 0) {
        this.alerta("Debe seleccionar al menos un producto", "error");
        return;
      }

      if (!this.$refs.formNombreFactTem.validate()) {
        this.alerta("Los compos en rojos son requerido", "error");
        return;
      }

      this.guardartemporal()

    },

    async CalcularTotalesProductosSaveTemp() {

      var totalGeneral = {
        subtotal: 0,
        itbis: 0,
        total: 0,
        descuento: 0,
        Retencion: 0,
        recargo: 0,
        cobertura: 0,
      };


      return new Promise((resolve, reject) => {
        if (this.RProductoCompra.length > 0) {


          var RObjProductoCompra = JSON.parse(
            JSON.stringify(this.RProductoCompraTemp)
          );
          var tasaMoneda = JSON.parse(JSON.stringify(this.configuraciones.config.monedabase.tasa));
          var Ccobertura = 0
         // RObjProductoCompra.forEach((element) => {}); 
        // (async () => {
          Promise.all(RObjProductoCompra.map(async (element) => { 

            if (!element.hasOwnProperty("paramOfert") || element.paramOfert == false) {
              element.precio = this.RedondeoValor(element.precio / tasaMoneda);
              element.valor = this.RedondeoValor(element.precio * element.cantidad);
              if (this.ConfImpfiscal == false) {
                if (element.productos.incluirimpuestoenprecio == true) {
                  if (element.productos.impuesto != null) {
                    var precioVar = this.RemoveTax(element.productos, element.precio)
                    totalGeneral.subtotal += (element.cantidad * precioVar)
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    totalGeneral.itbis += VALOR_IMPUESTO
                  }

                  if (element.productos.incluirimpuestoenprecio == false) {
                    if (element.productos.impuesto != null) {
                      var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                      totalGeneral.itbis += VALOR_IMPUESTO
                    }
                  }
                  totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                  totalGeneral.recargo += element.RecarValor
                  Ccobertura += element.cobertura
                } else {

                  totalGeneral.subtotal += this.RedondeoBasico(element.valor);
                  if (element.productos.incluirimpuestoenprecio == true) {
                    if (element.productos.impuesto != null) {
                      var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                      totalGeneral.itbis += VALOR_IMPUESTO
                    }
                  }
                  if (element.productos.incluirimpuestoenprecio == false) {
                    if (element.productos.impuesto != null) {
                      var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                      totalGeneral.itbis += VALOR_IMPUESTO
                    }
                  }

                  totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                  totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
                }
              } else {



                if (element.productos.incluirimpuestoenprecio == true) {
                  if (element.productos.impuesto != null) {
                    var precioVar = element.precio
                    totalGeneral.subtotal += (element.cantidad * precioVar)
                    var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                    totalGeneral.itbis += VALOR_IMPUESTO
                  }

                  if (element.productos.incluirimpuestoenprecio == false) {
                    if (element.productos.impuesto != null) {
                      var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                      totalGeneral.itbis += VALOR_IMPUESTO
                    }
                  }
                  totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                  totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
                  Ccobertura += element.cobertura
                } else {

                  totalGeneral.subtotal += element.valor;
                  if (element.productos.incluirimpuestoenprecio == true) {
                    if (element.productos.impuesto != null) {
                      var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                      totalGeneral.itbis += VALOR_IMPUESTO
                    }
                  }
                  if (element.productos.incluirimpuestoenprecio == false) {
                    if (element.productos.impuesto != null) {
                      var VALOR_IMPUESTO=this.CalculateTax(element);
                       element.valorimpuesto= VALOR_IMPUESTO
                      totalGeneral.itbis += VALOR_IMPUESTO
                    }
                  }

                  totalGeneral.descuento += this.RedondeoBasico(element.DescValor)
                  totalGeneral.recargo += this.RedondeoBasico(element.RecarValor)
                }


              }
            }

          }));
       // })()


          if (Ccobertura > 0) {
            totalGeneral.cobertura = this.RedondeoBasico(Ccobertura)
          }
        }

        /* this.Descuentofactura.descuento = JSON.parse(JSON.stringify(this.Totales.totalGeneral.descuento))
       this.Descuentofactura.ValorPorc = true
 
       this.Recargofactura.recargo = JSON.parse(JSON.stringify(this.Totales.totalGeneral.recargo))
       this.Recargofactura.ValorPorc = true*/

        var todo = {
          Descuentofactura: {
            descuento: JSON.parse(JSON.stringify(totalGeneral.descuento)),
            ValorPorc: true
          },

          Recargofactura: {
            recargo: JSON.parse(JSON.stringify(totalGeneral.recargo)),
            ValorPorc: true
          },
          Retencionfactura: this.Retencionfactura,
          CoberturaSeguro: this.CoberturaSeguro,
          totalGeneral: totalGeneral,
          RObjProductoCompra:RObjProductoCompra

        }

        resolve(todo)
      });



      /* this.Descuentofactura.descuento = JSON.parse(JSON.stringify(this.Totales.totalGeneral.descuento))
       this.Descuentofactura.ValorPorc = true
 
       this.Recargofactura.recargo = JSON.parse(JSON.stringify(this.Totales.totalGeneral.recargo))
       this.Recargofactura.ValorPorc = true*/

    },

    // Convertir la moneda de una moneda a otra.
    async ConvertirMonedaRd() {

      const result = await this.CalcularTotalesProductosSaveTemp();
      var MonedaTasa = this.configuraciones.config.monedabase.tasa
      var totalGeneral = JSON.parse(JSON.stringify(result.totalGeneral))
      var Descuentofactura = JSON.parse(JSON.stringify(result.totalGeneral))
      var Recargofactura = JSON.parse(JSON.stringify(result.Recargofactura))
      var Retencionfactura = JSON.parse(JSON.stringify(result.Retencionfactura))
      var CoberturaSeguro = JSON.parse(JSON.stringify(result.CoberturaSeguro))

      return new Promise((resolve, reject) => {
        //Nota: La variable:RObjProductoCompra trae el impuesto
        // var RObjProductoCompra = JSON.parse(JSON.stringify(this.RProductoCompraTemp));
        var RObjProductoCompra = JSON.parse(JSON.stringify(result.RObjProductoCompra));

        if (Descuentofactura.descuento > 0) {
          if (Descuentofactura.ValorPorc == true) {
            totalGeneral.descuento = Descuentofactura.descuento / MonedaTasa;
          }
          if (Descuentofactura.ValorPorc == false) {
            totalGeneral.descuento =
              (Descuentofactura.descuento * totalGeneral.subtotal) / 100 / MonedaTasa;
          }
        } else {
          totalGeneral.descuento = 0;
        }

        if (Retencionfactura.Retencion > 0) {
          if (Retencionfactura.ValorPorc == true) {
            totalGeneral.Retencion = Retencionfactura.Retencion / MonedaTasa;
          }
          if (Retencionfactura.ValorPorc == false) {
            totalGeneral.Retencion =
              (Retencionfactura.Retencion * this.Totales.totalGeneral.subtotal) / 100 / MonedaTasa;
          }
        } else {
          totalGeneral.Retencion = 0;
        }

        if (Recargofactura.recargo > 0) {
          if (Recargofactura.ValorPorc == true) {
            totalGeneral.recargo = Recargofactura.recargo / MonedaTasa;
          }
          if (Recargofactura.ValorPorc == false) {
            totalGeneral.recargo = (Recargofactura.recargo * this.Totales.totalGeneral.subtotal) / 100 / MonedaTasa;
          }
        } else {
          totalGeneral.recargo = 0;
        }

        if (CoberturaSeguro.cobertura > 0) {
          totalGeneral.cobertura = CoberturaSeguro.cobertura / MonedaTasa;
        } else {
          totalGeneral.cobertura = 0;
        }

        if (this.ConfImpfiscal == true) {

          if (this.IncluirItbisGeneral == false) {
            totalGeneral.total = this.RedondeoBasico(
              totalGeneral.subtotal +
              totalGeneral.itbis +
              totalGeneral.recargo -
              (totalGeneral.descuento +
                totalGeneral.Retencion +
                totalGeneral.cobertura));
            //this.Totales.totalGeneral.total= this.Totales.totalGeneral.subtotal + this.Totales.totalGeneral.itbis
          }

          if (this.IncluirItbisGeneral == true) {
            totalGeneral.total = this.RedondeoBasico(
              totalGeneral.subtotal +
              totalGeneral.recargo -
              (totalGeneral.descuento +
                totalGeneral.Retencion +
                totalGeneral.cobertura))
            //this.Totales.totalGeneral.total= this.Totales.totalGeneral.subtotal
          }

        } else {

          totalGeneral.total = this.RedondeoBasico(
            totalGeneral.subtotal +
            totalGeneral.itbis +
            totalGeneral.recargo -
            (totalGeneral.descuento +
              totalGeneral.Retencion +
              totalGeneral.cobertura))

        }

        var ListDet = []
        // this.RProductoCompraTemp.forEach((dato) => {
          //precioDig
          RObjProductoCompra.forEach((dato) => {
          var detalle = {
            facturastemporalesDetallePK: {
              secuencia: 0,
              producto: dato.productos.codigo,
              unidad: dato.unidades.codigo,
              // id: this.RProductoCompraTemp.indexOf(dato),
              id: RObjProductoCompra.indexOf(dato),
            },
            almacen: this.caja.almacen.codigo,
            cantidad: dato.cantidad,
            valordescuento: dato.valordescuento,
            valorimpuesto: dato.valorimpuesto,
            precio: dato.precio,
            precioDig:dato.precioDig,
            descuento: dato.descuento,
            codigocupon: null,
            valorcupon: 0.0,
            cantidadPendiente: dato.cantdad,
            oferta: null,
            valorRecargo: 0.0,
            recargo: 0.0,
            descripcion: dato.descripcion,
            costounidad: dato.costo,
            cantidadDespachada: dato.cantidadDespachada,
            borrado: false,
            cobertura: 0,
            impuesto: dato.impuesto,
            productos: dato.productos,
            unidades: dato.unidades,
            vendedor: dato.vendedor,
          };
          if(!dato.hasOwnProperty('valordescuento')&& dato.DescValor > 0 ){
            detalle.valordescuento=dato.DescValor
          }
          if(!dato.hasOwnProperty('impuesto') && dato.hasOwnProperty('valorimpuesto') && dato.valorimpuesto > 0 ){
            detalle.impuesto=dato.valorimpuesto
          }

          ListDet.push(detalle);

        });




        var todo = {
          totalGeneral: totalGeneral,
          ListDet: ListDet
        }



        resolve(todo)
      });


    },

    // Guardando los datos en la base de datos de factura temporal.
    async guardartemporal() {


      this.Facturasventa.facturastemporalesDetalle = [];
      if (this.RProductoCompra.length == 0) {
        this.alerta("Debe seleccionar al menos un producto", "error");
        return;
      }

      const result = await this.ConvertirMonedaRd();
      //var totalGeneral = JSON.parse(JSON.stringify(result.totalGeneral))
      var totalGeneral = JSON.parse(JSON.stringify(this.Totales.totalGeneral))
      var ListDet = JSON.parse(JSON.stringify(result.ListDet))
      if (this.ejecucion == true) {
        this.ejecucion = false;

        if (this.clienteFactura != null) {
          this.Facturasventa.cliente = this.clienteFactura;
        }

        this.Facturasventa.moneda = this.Monedafactura
        this.Facturasventa.tasa = this.Monedafactura.tasa
        this.Facturasventa.caja = this.caja
        this.Facturasventa.sucursal = this.caja.sucursal;
        if (this.domiciliofactura != null) {
           this.ConstruirDomicilio(this.Facturasventa, this.devuelta);
        }


        //this.Facturasventa.valordescuento=this.Descuentofactura.descuento
        var objfact = JSON.parse(JSON.stringify(this.Facturasventa));
        objfact.caja = this.caja;
        objfact.descripcion = this.nombreFactTem;

        objfact.sucursal = this.caja.sucursal;
        //this.Totales.totalGeneral.total;
        objfact.valor = this.RedondeoValor(totalGeneral.total);
        objfact.valorimpuesto = this.RedondeoValor(totalGeneral.itbis);
        objfact.valordescuento = this.RedondeoValor(totalGeneral.descuento);
        objfact.retencion = this.RedondeoValor(totalGeneral.Retencion);
        objfact.valorRecargo = this.RedondeoValor(totalGeneral.recargo);
        objfact.cobertura = this.RedondeoValor(totalGeneral.cobertura);
        objfact.coberturaPendiente = this.RedondeoValor(totalGeneral.cobertura);
        //objfact.facturastemporalesDetalleList =this.Facturasventa.facturastemporalesDetalle;
        objfact.facturastemporalesDetalleList = ListDet;
        objfact.nombreDgii = this.Facturasventa.nombreDgii
        objfact.cedulaDgii = this.Facturasventa.cedularncncf
        objfact.telefonoDgii = this.Facturasventa.telefononcf
        objfact.moneda = this.Monedafactura
        objfact.tasa = this.Monedafactura.tasa

        if(this.tipoTarjeta!=null && this.tipoTarjeta.hasOwnProperty('codigo') ){
          objfact.tipotar=this.tipoTarjeta.codigo
        }

        if (this.Facturasventa.tipocomprobante == null) {
          objfact.tipocomprobante = this.configuraciones.configPv.comprobantefacturacion;
        } else {
          objfact.tipoComprobante = this.Facturasventa.tipocomprobante.codigo
        }

        objfact.direccionDgii = this.Facturasventa.direccionncf
        objfact.hora = new Date()

        if (this.domiciliofactura != null) {
          objfact.telefonoDomicilio = this.domiciliofactura.telefonoPrincipal
          objfact.codDir = this.domiciliofactura.selctDireccion.codigo
        }


        if (this.CoberturaSeguro.cobertura > 0) {
          objfact.ars = this.CoberturaSeguro.ars
          objfact.autorizacion = this.CoberturaSeguro.autorizacion
          objfact.medico = this.CoberturaSeguro.medico
          objfact.execuantum = this.CoberturaSeguro.exequartur
          objfact.especialidaMedico = this.CoberturaSeguro.especialidad
          objfact.diagnostico = this.CoberturaSeguro.diagnostico

        }

        if (this.SelectMedicoFactura != null) {
          objfact.medicoCodigo = this.SelectMedicoFactura
        }
        if (this.cambiarDestinoSucursal == true) {
          objfact.sucursal = this.sucursalFactTemporal.codigo
        }

        if (this.Facturasventa.vendedor != null && this.Facturasventa.vendedor.hasOwnProperty('codigo')) {
          objfact.vendedor = this.Facturasventa.vendedor
        }

        if (this.RecetaProdControlado.numero != null && this.RecetaProdControlado.detalleList.length > 0) {
          objfact.receta = JSON.stringify(this.RecetaProdControlado)
        }
        if (this.deleteFacturaTemporal != null) {
          this.deleRestaurarFact(this.deleteFacturaTemporal);
        }
        objfact.enUso=false
        objfact.cheque=this.Facturasventa.cheques
        objfact.bancoCheque=this.bancoCheque
        objfact.numeroCheque=this.Facturasventa.numeroCheque
        objfact.transferencia=this.Facturasventa.transferencia
      // this.Facturasventa.numeroCheque

       /* console.log('--guardartemporal--')
        console.log(objfact)
        console.log('PraPra es la señal')*/
        // console.log(JSON.stringify(objfact.facturastemporalesDetalleList))
        //  objfact.facturastemporalesDetalleList=[] 
        // console.log(JSON.stringify(objfact)) 
       /* return */
       //objfact.usuario=this.$store.getters.GetdatosInfUsu.usuario.nombre
       objfact.usuario=this.$store.getters.GetdatosInfUsu.usuario.usuario
        this.$axios.post(this.$hostname + this.$hName + "/temporales/save", objfact, {
            headers: this.$store.getters.GetheadersAxios,
          })
          .then((res) => {
            this.alerta("Guardado corretamente...", "bien");
          })
          .catch((error) => this.alerta(error, "error"));
      }
    },
    llenarDetalleTemp(dato) {
      var detalle = {
        facturastemporalesDetallePK: {
          secuencia: 0,
          producto: dato.productos.codigo,
          unidad: dato.unidades.codigo,
          id: 0,
        },
        almacen: this.caja.almacen.codigo,
        cantidad: dato.cantidad,
        valordescuento: dato.valordescuento,
        valorimpuesto: dato.valorimpuesto,
        precio: dato.precio,
        descuento: dato.descuento,
        codigocupon: null,
        valorcupon: 0.0,
        cantidadPendiente: dato.cantdad,
        oferta: null,
        valorRecargo: 0.0,
        recargo: 0.0,
        descripcion: dato.descripcion,
        costounidad: dato.costo,
        cantidadDespachada: dato.cantidadDespachada,
        borrado: false,
        cobertura: 0,
        impuesto: dato.impuesto,
        productos: dato.productos,
        unidades: dato.unidades,
        vendedor: dato.vendedor,
      };
      this.Facturasventa.facturastemporalesDetalle.push(detalle);
    },
    // Abriendo una ventana modal .
    OpenFacturaDomicilio() {
      if (this.clienteFactura == null) {
        this.OpenEjecFacturaDomicilio = true;
        this.OpenDialogCrearEditarCliente();
      }
      if (this.clienteFactura != null) {

        if(this.domiciliofactura==null){
        this.domiciliofactura = JSON.parse(JSON.stringify(this.clienteFactura));
        this.domiciliofactura.telefonoPrincipal = this.selectTelefonoCliente(this.clienteFactura)
        this.PanelMenuOpcionBotones = false;
        this.PanelMenuOpcionFacturaDomicilio = true;
        var jsn = [
          {
            codigo: 1,
            descripcion: "Dirección 1",
            direccion: this.clienteFactura.direccion,
          },
          {
            codigo: 2,
            descripcion: "Dirección 2",
            direccion: this.clienteFactura.direccion2,
          },
          { codigo: 3, descripcion: "Otra dirección ", direccion: " " },
        ];
        this.RDireccionDomicilio = JSON.parse(JSON.stringify(jsn));
        this.domiciliofactura.selctDireccion = jsn[0];
       }else{
        this.PanelMenuOpcionBotones = false;
        this.PanelMenuOpcionFacturaDomicilio = true;
       }


      }
    },
    ChangeDireccionDomicilio(e) {

      this.domiciliofactura.direccion = JSON.parse(JSON.stringify(e.direccion));
    },
    SelctCbDireccionDomicilio() {
      if (this.domiciliofactura.selctDireccion == null) {
        this.domiciliofactura.selctDireccion = "";
      }
      if (this.domiciliofactura.selctDireccion.codigo == undefined) {
        this.domiciliofactura.selctDireccion = "";
        this.alerta("Seleccione una direccion", "error");
        return;
      }
    },
    OpenDialogAsignarMensajeros() {
      this.buscarMensajero = null;
      this.NewMensagero();
      this.dialogAsignarMensajeros = true;
      this.dialogRecibirMensajeros = false;
      this.dialogFacturasRutasMensajeros = false;
      this.dialogCambiarMensajeros=false
      this.dialogMensajero = true;
    },
    OpenDialogRecibirMensajeros() {
      this.getUltimocierreCaja()
      this.buscarMensajero = null;
      this.NewMensagero();
      this.dialogAsignarMensajeros = false;
      this.dialogRecibirMensajeros = true;
      this.dialogFacturasRutasMensajeros = false;
      this.dialogCambiarMensajeros = false;
      this.dialogMensajero = true;
    },

    OpenDialogFacturasRutasMensajeros() {
      this.buscarMensajero = null;
      this.NewMensagero();
      this.dialogAsignarMensajeros = false;
      this.dialogRecibirMensajeros = false;
      this.dialogFacturasRutasMensajeros = true;
      this.dialogCambiarMensajeros = false;
      this.dialogMensajero = true;
      this.CargarFacturasRutasMensajeros()
    },

    OpenDialogCambiarMensajeros() {
      this.buscarMensajero = null;
      this.NewMensagero();
      this.dialogAsignarMensajeros = false;
      this.dialogRecibirMensajeros = false;
      this.dialogFacturasRutasMensajeros = false;
      this.dialogCambiarMensajeros=true
      this.dialogMensajero = true;
    },


    // Creando un nuevo objeto y asignándolo a la variable MensageroSeleccionado.
    NewMensagero() {
      this.MensageroSeleccionado = {
        codigo: 0,
        nombre: "",
        telefono: "",
        cedula: "",
        metadeenvios: 0,
        sucursal: { descripcion: "" },
      };
      this.RFacturasDomicilio = [];
      this.RRecibirMensajeros = [];
      this.selectedRecibirMensajeros = [];
      this.selectedAsignarMensajero = [];
      this.selectedCambiarMensajeros=[]
      this.FacturaDomicilioMensajero = null;
      this.nuevoMensajeroSelect=null
    this.filtroAsigMensajero={
    fact:'',
    sector:'',
    caja:null
    }
    },

    // Una función que se llama cuando el usuario hace clic en el botón cancelar.
    CancelarVerAsignarMensajero() {
      this.VerFacturaDomicilioMensajero = false
      this.FacturaDomicilioMensajero = null
      this.dialogMensajero = false
      this.dialogRecibirMensajeros = false
      this.dialogAsignarMensajeros = false,
      this.dialogFacturasRutasMensajeros=false
        this.NewMensagero()
    },


    // Llama a una api para asirnarle al mensajero seleccionado la factura seleccionada
    async SalvarAsignarMensagero() {
      if (this.$refs.formAsignarMensagero.validate()) {

        if (this.selectedAsignarMensajero.length == 0) {
          this.alerta("Seleccione al menos una factura", "error");
          return;
        }


        if (this.ejecucion == true) {
          this.ejecucion = false;
          this.loading(true)

          var OBJ={
            listaDomicilio:this.selectedAsignarMensajero,
            codigoMensajero:this.MensageroSeleccionado.codigo
          }
       
          this.$axios
            .post(
              this.$hostname + this.$hName + "/Mesajeros/asignarDomicilioMensajero",
              OBJ,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {


              var _this = this;
        setTimeout(function () {
          _this.CancelarVerAsignarMensajero();
                _this.loading(false)
                _this.ejecucion = true;
        }, 500);


              var LIST_MENS =  this.selectedAsignarMensajero
              if (localStorage.printerSelect != null) {}
              var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
              
              var msg = {
                accion: "print",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                list: LIST_MENS,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
                mensajero:this.buscarMensajero
              };
              this.imprimir(msg, "list-delivery");
            
            })
            .catch((error) => {
              this.loading(false)
              this.alerta("Dato no guardado correctamente", "error")
            }
            );




       /* await Promise.all(this.selectedAsignarMensajero.map(async (d) => { 
          this.$axios
              .get(
                this.$hostname +
                this.$hName +
                "/Mesajeros/asignar/" +
                d.codigo +
                "/" +
                this.MensageroSeleccionado.codigo,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
                this.CancelarVerAsignarMensajero();
                this.loading(false)
                this.ejecucion = true;
              });

       }));*/

          /*this.selectedAsignarMensajero.forEach((d) => {
            this.$axios
              .get(
                this.$hostname +
                this.$hName +
                "/Mesajeros/asignar/" +
                d.codigo +
                "/" +
                this.MensageroSeleccionado.codigo,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
                this.CancelarVerAsignarMensajero();
                this.loading(false)
                this.ejecucion = true;
              });
          });*/
        }

        
        

      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },

imprmirFacturaRutaMensagero(){
var LIST_MENS =  this.RFacturasRutasMensajeros.filter((ee) => ee.mensajero.nombre=== this.buscarMensajero.nombre);
if (localStorage.printerSelect != null) { }
var selectImpre=impresora=JSON.parse(localStorage.printerSelect)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
              var msg = {
                accion: "print",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                list: LIST_MENS,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
                mensajero:this.buscarMensajero
              };
              this.imprimir(msg, "list-delivery");
           



},


    // Validando el formulario y si es válido está poniendo a false la variable ejecución.
    SalvarAsignadoMensagero() {
      if (this.$refs.formAsignarMensagero.validate()) {
        if (this.ejecucion == true) {
          this.ejecucion = false;
        }
      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
    },
    // Una función que se llama cuando el usuario selecciona un mensejero de un menú desplegable.
    getChangeBuscarMensajero(e) {
      if (e != null) {
        this.FacturaDomicilioMensajero = null;
        this.RFacturasDomicilio = [];
        this.RRecibirMensajeros = [];
        this.selectedRecibirMensajeros = [];
        this.selectedAsignarMensajero = [];
        this.MensageroSeleccionado = JSON.parse(JSON.stringify(e));
        if (this.dialogAsignarMensajeros == true) {
          this.CargarFacturasDomicilio();
        }
        if (this.dialogRecibirMensajeros == true) {
          this.VerFacturaDomicilioMensajero = false;
          this.FacturaDomicilioMensajero = null;
          this.CargarRecibirMensajeros();
        }

        if(this.dialogFacturasRutasMensajeros==true){
        this.CargarFacturasRutasMensajeros()
        }

        if(this.dialogCambiarMensajeros==true){
        this.CargarRecibirMensajeros()
        }



      } else {
        this.MensageroSeleccionado = {
          codigo: 0,
          nombre: "",
          telefono: "",
          cedula: "",
          metadeenvios: 0,
          sucursal: { descripcion: "" },
        };

        this.FacturaDomicilioMensajero = null;
      }

      // buscarMensajero:null,
      // Rmensajero:[],
      // RFacturasDomicilio:[],
      // MensageroSeleccionado:{
      //   codigo:0,
      //   nombre:'',
      //   telefono:'',
      //   cedula:'',
      //   metadeenvios: 0,
      //   sucursal:{descripcion:""}
      //   },
      // getChangeBuscarMensajero,SelctCbBuscarMensajero,cargarBuscarMensajero,CbFilBuscarMensajero(){headers:this.$store.getters.GetheadersAxios}
    },
    // Realización de una solicitud al servidor para actualizar el estado del pedido.
    slvarEntregado() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccMensajeroEntregado')
        return;
      }
// codicion de cambio: tarjeta a efectivo:1
//efectivo atarjeta 2
        var dom = this.selectedRecibirMensajeros[0];
        var cambioPago=null
        var CAMBIO_a_EFE_TARJ_CHE_TRANS=0
        
        if(this.cambioPagoDomicilio!=null&&this.verificarCambioPagoDomicilio(this.FacturaDomicilioMensajero)==true){
           cambioPago=this.cambioPagoDomicilio.descripcion
           CAMBIO_a_EFE_TARJ_CHE_TRANS=this.cambioPagoDomicilio.codigo
        }
 
      this.loading(true)


      var _this=this;
  this.$axios
        .get(this.$hostname +this.$hName +"/cierrecaja/ultimocierre/" +
          this.caja.codigo +"/",{ headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;
          if(this.caja.codigo==this.FacturaDomicilioMensajero.caja.codigo && res.data==this.FacturaDomicilioMensajero.numerocierre){
           
           }else{

           var OBJ={
             facturasventaPK:this.FacturaDomicilioMensajero.facturasventaPK,
             numerocierre:res.data,
             caja:this.caja
           }
           this.actualizaFacturaCierreCaja(OBJ,true)    
          }

          this.$axios.get(this.$hostname +this.$hName +
          "/Mesajeros/"+dom.codigo +"/" +
          true +"/actualizar/"+CAMBIO_a_EFE_TARJ_CHE_TRANS+"/"+res.data,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.VerFacturaDomicilioMensajero = false;
          this.selectedRecibirMensajeros = []
          this.alerta("Factura Entregada Correctamente !!!", "bien");
          this.CargarRecibirMensajeros();
          this.loading(false)
        });

 });
           
    },




    salvarNoEntregado() {
      if (this.EjecutarPermisoPv == false) {
        this.funcionValidaPermisoPv('AccMensajeroNoEntregado')
        return;
      }

      var doc=this.caja.sucursal.documentoNotaCredito
      //var doc=this.caja.sucursal.documentofacturacredito
      this.$axios.get(this.$hostname + this.$hName +"/documentocxc/find/"+doc,
          { headers: this.$store.getters.GetheadersAxios }
        ).then(resdoc => {

        if(resdoc.data.comprobante==true){
        var link = resdoc.data.codigo +"/" + "CXC"
        this.$axios.get(this.$hostname + this.$hName + "/secuenciaComprobantes/existesecuencia/" + link,
          { headers: this.$store.getters.GetheadersAxios })
          .then(res => {
            if (res.data == true) {

              var _this = this;
              setTimeout(function(){ 
                _this.continuarsalvarNoEntregado()
                          }, 600); 

            } else {
              this.alerta("No posee secuencia para la Notas de Crédito que está intentado realizar", "error")
            }
          })
          .catch(error => { 
        this.alerta(error, "error")
        this.loading(false)
      
           });

      }else{
        this.continuarsalvarNoEntregado()
      }

      
        });
      
    },


    continuarsalvarNoEntregado(){
      var dom = this.selectedRecibirMensajeros[0];
      this.loading(true)
  this.$axios
        .get(this.$hostname +this.$hName +"/cierrecaja/ultimocierre/" +
          this.caja.codigo +"/",{ headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.CierresCajas.cierrescajasPK.secuencia = res.data;
          if(this.caja.codigo==this.FacturaDomicilioMensajero.caja.codigo && res.data==this.FacturaDomicilioMensajero.numerocierre){
           
            var _this = this;
          setTimeout(function () {
            _this.contProcSalvarNoEntregado()
          }, 300);
           
          }else{

            var OBJ={
              facturasventaPK:this.FacturaDomicilioMensajero.facturasventaPK,
              numerocierre:res.data,
              caja:this.caja
            }
            this.actualizaFacturaCierreCaja(OBJ,false)
            
        }

 });

    },


    contProcSalvarNoEntregado(){
      var dom = this.selectedRecibirMensajeros[0];
       this.$axios.get(this.$hostname +this.$hName +"/Mesajeros/" +
          dom.codigo +"/" +false +"/"+this.CierresCajas.cierrescajasPK.secuencia+"/actualizar",
          { headers: this.$store.getters.GetheadersAxios })
        .then((res) => {
          this.DevolucionAdomicilioNoentregado()
          var _this = this;
          setTimeout(function () {
            _this.VerFacturaDomicilioMensajero = false;
            _this.selectedRecibirMensajeros = []
         //   _this.alerta("Factura No Entregada Correctamente !!!", "bien");
            _this.CargarRecibirMensajeros();
            _this.loading(false)
          }, 800);
        });

    },


    // Este metodo actualiza la factura con el cierre y la caja actual 
    //BOOL: si es (true) metodo domicilio entregado de lo contrario es no entregado
    actualizaFacturaCierreCaja(OBJ,BOOL){
      this.$axios.post(this.$hostname + this.$hName + "/pv/updateFacturaCierreCaja",
            OBJ,{ headers: this.$store.getters.GetheadersAxios })
            .then((res) => {
              if(BOOL==true){
                
              }else{
                this.contProcSalvarNoEntregado()
              }
              // this.alerta("Dato guardado correctamente!", "bien2");
  
            })
            .catch((error) => {
              console.log(error)
              this.loading(false)
              this.alerta("Proceso no realizado correctamente", "error")
            }
            );
    },


    // Creando un nuevo objeto y asignándolo a la variable DevolucionFacturaSeleccionada.
    DevolucionAdomicilioNoentregado() {

      this.DevolucionSelected = []
      //var df= this.selectedRecibirMensajeros[0]
      var df = JSON.parse(JSON.stringify(this.FacturaDomicilioMensajero))
      this.DevolucionSelected.push(df)
      var obj = {
        secuencia: df.facturasventaPK.secuencia,
        fecha: new Date(),
        ncf: df.ncf,
        cliente: df.cliente.nombre,
        cedula: df.cliente.cedrnc != null ? df.cliente.cedrnc : "",
        comentario: "domicilio No entregado",
        turno: null,
        formapago: "",
        DetalleList: df.facturasventaDetalleList,
        SeletDetalleList: df.facturasventaDetalleList,
        efectivo: true,
        cheques: df.cheques,
        cupones: df.cupones,
        cxc: df.cxc,
        Pagoefec: df.efectivo,
        puntos: df.puntos,
        tarjeta: df.tarjeta,
        valorNc: df.valorNc,
        transferencia: df.transferencia,
        domicilionoentregado:true
      }

      this.AceptarDevolucion = {
        nombre: df.cliente.nombre,
        cedula: df.cliente.cedrnc != null ? df.cliente.cedrnc : "",
      };
      this.DevolucionFacturaSeleccionada = JSON.parse(JSON.stringify(obj))

      var _this = this;
      setTimeout(function () {
        _this.FinalizarDevolucionFactura();
      }, 500);


    },



    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbBuscarMensajero() {
      if (this.buscarMensajero == null) {
        this.buscarMensajero = "";
      }
      if (this.buscarMensajero.codigo == undefined) {
        this.buscarMensajero = "";
        this.alerta("Seleccione un mensajero", "error");
        return;
      }
    },
    // Hacer una solicitud al servidor y devolver los datos de la factura no asignada.
    CargarFacturasDomicilio() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/Mesajeros/sin-agignar/" +
          this.caja.sucursal.codigo +
          "",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RFacturasDomicilio = res.data;
        });
    },
    // Hacer una llamada axios al servidor y devolver los datos del mensajero adomicilio.
    CargarRecibirMensajeros() {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/Mesajeros/asignados/" +
          this.caja.sucursal.codigo +
          "/" +
          this.MensageroSeleccionado.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RRecibirMensajeros = res.data;
          this.totalDomicilio.valor=0
          res.data.forEach(element => {
            this.totalDomicilio.valor+=element.valor+element.devuelta
          });
        });
    },


    CargarFacturasRutasMensajeros(){

      this.$axios.get(this.$hostname +this.$hName +
          "/Mesajeros/asignados/" +
          this.caja.sucursal.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RFacturasRutasMensajeros = res.data;
        });



     /* this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/Mesajeros/asignados/" +
          this.caja.sucursal.codigo +
          "/" +
          this.MensageroSeleccionado.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          this.RFacturasRutasMensajeros = res.data;
        });*/
    },

    // Hacer una solicitud al servidor y devolver los datos al Vue HTML.
    cargarBuscarMensajero() {

    
      if(this.dialogAsignarMensajeros==true){
        this.$axios.get(this.$hostname + this.$hName + "/Mesajeros/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.Rmensajero = res.data));
      }

      if(this.dialogRecibirMensajeros==true){
       // this.$axios.get(this.$hostname + this.$hName + "/Mesajeros/findall/recibir/"+this.caja.sucursal.codigo,
        this.$axios.get(this.$hostname + this.$hName + "/Mesajeros/findall/recibir", {
          headers: this.$store.getters.GetheadersAxios,
        }).then((res) => (this.Rmensajero = res.data));
      }

      if(this.dialogFacturasRutasMensajeros==true){
        this.$axios.get(this.$hostname + this.$hName + "/Mesajeros/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        }).then((res) => (this.Rmensajero = res.data));
      }


      if(this.dialogCambiarMensajeros==true){
        this.$axios.get(this.$hostname + this.$hName + "/Mesajeros/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        }).then((res) => (this.Rmensajero = res.data));
      }
     

    },


   

    async filterDataTableAsigMensajeroCaja(item){
  
       if(this.filtroAsigMensajero.caja!=null){
        // this.CargarFacturasDomicilioCajaFilSelect(this.filtroAsigMensajero.caja.sucursal)
      /*var ObjProRow = item.filter((ee) => ee.caja == this.filtroAsigMensajero.caja.codigo);
      return ObjProRow*/
      const result =await this.CargarFacturasDomicilioCajaFilSelect(this.filtroAsigMensajero.caja.sucursal)
      this.RFacturasDomicilio=result.filter((ee) => ee.caja == this.filtroAsigMensajero.caja.codigo)
      //return result;

      }else{

       const result = await this.CargarFacturasDomicilioCajaFilSelect(this.caja.sucursal)
      this.RFacturasDomicilio=result
       // return result;
        // return item
      }

      
    },


    
    async CargarFacturasDomicilioCajaFilSelect(SUCURSAL)
    {
      return  new Promise((resolve, reject) => {
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/Mesajeros/sin-agignar/" +
          SUCURSAL.codigo +
          "",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          resolve(res.data)
          // return res.data;
        })
        .catch(error => {
      resolve([])
    });  

      });

    },



    filterDataTableAsigMensajeroSecto(item){
      var tex=""
      if(this.filtroAsigMensajero.sector.length>0){
        tex=this.filtroAsigMensajero.sector
      }
      var textOne =""
      // typeof val === 'string'
      if(item!=null && typeof item ==='string'){
        textOne = item.toLowerCase()
       }

      const searchText = tex.toLowerCase()
      return textOne.indexOf(searchText) > -1 

    },

   

    filterDataTableAsigMensajeroFact(item){
      var tex=""
      if(this.filtroAsigMensajero.fact.length>0){
        tex=this.filtroAsigMensajero.fact
      }
      const textOne = item.toLowerCase()
      const searchText = tex.toLowerCase()
      return textOne.indexOf(searchText) > -1 

    },

    filterDataTableFacturaRutaCliente(item){
      var tex=""
      if(this.filtroFacturaRutaCliente.length>0){
        tex=this.filtroFacturaRutaCliente
      }
      const textOne = item.toLowerCase()
      // if(item!=null){ }
      const searchText = tex.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },

    

    filterDataTableFacturaRutaMensajero(item){   
      var tex=""
      if(this.buscarMensajero!=null && this.buscarMensajero.hasOwnProperty('nombre') ){
        tex=this.buscarMensajero.nombre

      }
      const textOne = item.toLowerCase()
      // if(item!=null){ }
      const searchText = tex.toLowerCase()
      return textOne.indexOf(searchText) > -1 
    },


    obtenerDatoSelectCambioMensajeroClickRow(e){
      this.selectedCambiarMensajeros=[]
      this.selectedCambiarMensajeros.push(e)
    },

    SelctCbNuevoMensajero(){
      
      var _this = this;
      setTimeout(function () {
        if (_this.nuevoMensajeroSelect == null) {
          _this.nuevoMensajeroSelect = "";
        }

        if (!_this.nuevoMensajeroSelect.hasOwnProperty('codigo')) {
          _this.nuevoMensajeroSelect = "";
          _this.nuevoMensajeroSelect = null;
          return;
        }
      }, 300);

    },

    cambiarMensajeroSelecionado(){

      if(this.nuevoMensajeroSelect!=null && this.nuevoMensajeroSelect.hasOwnProperty('nombre')){

         var MENS_SELEC=JSON.parse(JSON.stringify(this.selectedCambiarMensajeros[0]))
         MENS_SELEC.mensajero= JSON.parse(JSON.stringify(this.nuevoMensajeroSelect))

         //Mesajeros/asignar/{domicilio}/{mensajero}
  
         this.loading(true)
        
         var CODIGO_NEW_MEN=this.nuevoMensajeroSelect.codigo



  var bar = new Promise((resolve, reject) => {
    this.selectedCambiarMensajeros.forEach(elem=>{
          var CODIGO_DOM=elem.codigo
          this.$axios.get(this.$hostname +this.$hName +
          "/Mesajeros/asignar/"+CODIGO_DOM+"/"+CODIGO_NEW_MEN,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {resolve() });

          })
          
});

bar.then(() => {
  console.log('All done cambio mensajero!');

this.$axios.get(this.$hostname +this.$hName +
          "/Mesajeros/asignados/" +
          this.caja.sucursal.codigo,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          var LIST_MENS =  res.data.filter((ee) => ee.mensajero.nombre=== this.nuevoMensajeroSelect.nombre);
          if (localStorage.printerSelect != null) { }
          var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

              var msg = {
                accion: "print",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: JSON.parse(localStorage.printerSelect).nombre,
                data: this.configuraciones,
                list: LIST_MENS,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
                mensajero:this.nuevoMensajeroSelect
              };
              this.imprimir(msg, "list-delivery");
           

          this.alerta(" Domicilio asignado correctamente !!!", "bien2");
          this.selectedCambiarMensajeros=[]
          this.nuevoMensajeroSelect=null
          this.buscarMensajero=null
          this.RRecibirMensajeros=[]

        });

});
      

      }else{
        this.alerta("Seleccione un mensajero nuevo", "error");
      }

    },


    obtenerDatoSelectFactDomicilioClickRow(e){
      var ObjProRow = this.selectedAsignarMensajero.filter((ee) => ee.codigo == e.codigo );
          if (ObjProRow.length == 0) {
            this.selectedAsignarMensajero.push(e)
          } 
    },


// Obtener los datos del servidor y luego configurar los datos en la variable
// FacturaDomicilioMensajero.

    obtenerDatoSelectRecibirMensajerosClickRow(e) {
      this.FacturaDomicilioMensajero = null;
      this.selectedRecibirMensajeros = [];

      this.selectedRecibirMensajeros.push(e);
      this.cambioPagoDomicilio=null
      // if(e.value==true){}
      this.$axios
        .get(
          this.$hostname + this.$hName + "/Mesajeros/asignar/" + e.codigo + "",
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if (res.data.toString().length != 0) {
            var obj = res.data;
            obj.devuelta=e.devuelta
            obj.ComentarioLeido = false;
            // this.FacturaDomicilioMensajero=res.data,
            this.FacturaDomicilioMensajero = obj;
            this.VerFacturaDomicilioMensajero = true;

            if(this.verificarCambioPagoDomicilio(obj)==true){
              if(obj.efectivo>0){
           this.cambioPagoDomicilio=JSON.parse(JSON.stringify(this.RCambioPagoDomicilio[0]))
          }

          if(obj.tarjeta>0){
           this.cambioPagoDomicilio=JSON.parse(JSON.stringify(this.RCambioPagoDomicilio[1]))
          }

          if(obj.cheques>0){
           this.cambioPagoDomicilio=JSON.parse(JSON.stringify(this.RCambioPagoDomicilio[2]))
          }

          if(obj.transferencia>0){
           this.cambioPagoDomicilio=JSON.parse(JSON.stringify(this.RCambioPagoDomicilio[3]))
          }
         
        }

          }
        });
   
    },
     // Obteniendo los datos del servidor y asignándolos a la variable FacturaDomicilioMensajero.
    obtenerDatoSelectRecibirMensajeros(e) {
      this.FacturaDomicilioMensajero = null;
      if (e.value == true) {
      this.obtenerDatoSelectRecibirMensajerosClickRow( e.item)
      }else{

      this.obtenerDatoSelectRecibirMensajerosClickRow( e.item)
       /* var _this = this;
        setTimeout(function () {
          _this.VerFacturaDomicilioMensajero = false;
          _this.FacturaDomicilioMensajero = null;
          _this.CargarRecibirMensajeros();
        }, 500);*/
       // this.buscarMensajero=null
      }

      return
     /* if (e.value == true) {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/Mesajeros/asignar/" +
            e.item.codigo +
            "",
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            if (res.data.toString().length != 0) {
              var obj = res.data;
              obj.ComentarioLeido = false;
              // this.FacturaDomicilioMensajero=res.data,
              this.FacturaDomicilioMensajero = obj;
              this.VerFacturaDomicilioMensajero = true;
              if(obj.efectivo>0){
           this.cambioPagoDomicilio=JSON.parse(JSON.stringify(this.RCambioPagoDomicilio[0]))
          }

          if(obj.tarjeta>0){
           this.cambioPagoDomicilio=JSON.parse(JSON.stringify(this.RCambioPagoDomicilio[1]))
          }
            }
          });
      }*/
    },



calcularTotalRecibirMensajero(){

  this.selectedRecibirMensajeros=[]


},

    obtenerDatoAsignarMensajero() {
      if (e.value == true) {
        this.RDetFactura = [];
        setTimeout(this.allobtener, 200);
      }
      if (e.value == false) {
        this.RDetFactura = [];
        setTimeout(this.allobtener, 200);
      }
    },
    OpenDialogCotizacion() {
      if (this.RProductoCompra.length == 0) {
        this.alerta("Debe de Agregar al menos un producto a la lista", "error")
        return
      }
      this.dialogCotizacion = true;
      this.newnewCotizacion();

      


      if(this.cotizacionAfacturaSelect!=null){
        if(this.cotizacionAfacturaSelect.cliente!=null){
          this.CotizacionFact.cliente=this.cotizacionAfacturaSelect.cliente
        }
        this.CotizacionFact.nombrecliente = this.cotizacionAfacturaSelect.nombrecliente;
        if(this.cotizacionAfacturaSelect.provedorEstado!=null){
          this.CotizacionFact.provedorEstado = this.cotizacionAfacturaSelect.provedorEstado
        }else{ this.CotizacionFact.provedorEstado =false}
        
       this.CotizacionFact.telefono = this.cotizacionAfacturaSelect.telefono
       this.CotizacionFact.conceptoPago=this.cotizacionAfacturaSelect.conceptoPago
       this.CotizacionFact.concepto=this.cotizacionAfacturaSelect.concepto
       this.CotizacionFact.valido=this.cotizacionAfacturaSelect.valido
       this.CotizacionFact.correo=this.cotizacionAfacturaSelect.correo
       this.CotizacionFact.direccion=this.cotizacionAfacturaSelect.direccion

      }else{
        if (this.clienteFactura != null) {
        this.CotizacionFact.cliente=this.clienteFactura
        this.CotizacionFact.nombrecliente = this.clienteFactura.nombre;
        this.CotizacionFact.correo = this.clienteFactura.email;
        if (this.clienteFactura.telefonosClientesList.length > 0) {
          this.CotizacionFact.telefono =
            this.clienteFactura.telefonosClientesList[0].telefonosClientesPK.telefono;
        }
        this.CotizacionFact.direccion = this.clienteFactura.direccion;
      }else{this.CotizacionFact.cliente=null }
      }

      // this.CotizacionFact.valor = this.Totales.totalGeneral.subtotal;
      this.CotizacionFact.subtotal = this.Totales.totalGeneral.subtotal;
      this.CotizacionFact.valor = this.Totales.totalGeneral.subtotal+this.Totales.totalGeneral.itbis-(this.Totales.totalGeneral.descuento);
      this.CotizacionFact.valordescuento = this.Totales.totalGeneral.descuento;
      this.CotizacionFact.valorimpuesto = this.Totales.totalGeneral.itbis;
      this.CotizacionFact.total=this.Totales.totalGeneral.total;
      this.CotizacionFact.moneda = JSON.parse(
        JSON.stringify(this.Monedafactura)
      );
    },


  quitarItbisCotizacion(){
    var OBJTO =JSON.parse(JSON.stringify(this.CotizacionFact))

if(this.CotizacionFact.quitaritbis==true){
    this.CotizacionFact.subtotal = this.Totales.totalGeneral.subtotal;
     this.CotizacionFact.valor = this.Totales.totalGeneral.subtotal-(this.Totales.totalGeneral.descuento);
     this.CotizacionFact.valordescuento = this.Totales.totalGeneral.descuento;
     this.CotizacionFact.valorimpuesto = 0;
}else{
    this.CotizacionFact.subtotal = this.Totales.totalGeneral.subtotal;
     this.CotizacionFact.valor = this.Totales.totalGeneral.subtotal+this.Totales.totalGeneral.itbis-(this.Totales.totalGeneral.descuento);
     this.CotizacionFact.valordescuento = this.Totales.totalGeneral.descuento;
     this.CotizacionFact.valorimpuesto = this.Totales.totalGeneral.itbis;
}

    },


verificarCambioPagoDomicilio(e){
var bool=false
// FacturaDomicilioMensajero.efectivo>0 || FacturaDomicilioMensajero.tarjeta>0
/*if(e.cxc==0 && e.valorNc==0 && e.cheques==0 && e.transferencia==0){
  if(e.tarjeta==0 && e.efectivo>0 ){
    bool=true
  }

  if(e.efectivo==0 && e.tarjeta>0 ){
    bool=true
  }
} */

if(e.efectivo>0 && e.tarjeta==0 && e.valorNc==0 && e.cheques==0 && e.transferencia==0){bool=true}
if(e.tarjeta>0 && e.efectivo==0 && e.valorNc==0 && e.cheques==0 && e.transferencia==0){bool=true}
if(e.cheques>0 &&e.efectivo==0 && e.tarjeta==0 && e.valorNc==0 &&  e.transferencia==0){bool=true}
if(e.transferencia>0 && e.efectivo==0 && e.tarjeta==0 && e.valorNc==0 && e.cheques==0){bool=true}

return bool

},

    SelctCbCambioPagoDomicilio() {
var _this = this;
      setTimeout(function () {
        if (_this.cambioPagoDomicilio == null) {
          _this.cambioPagoDomicilio = "";
        }
        if (!_this.cambioPagoDomicilio.hasOwnProperty('codigo')) {
          _this.cambioPagoDomicilio = "";
          _this.cambioPagoDomicilio = null;
          if(_this.FacturaDomicilioMensajero.efectivo>0){
           _this.cambioPagoDomicilio=JSON.parse(JSON.stringify(_this.RCambioPagoDomicilio[0]))
          }

          if(_this.FacturaDomicilioMensajero.tarjeta>0){
           _this.cambioPagoDomicilio=JSON.parse(JSON.stringify(_this.RCambioPagoDomicilio[1]))
          }
          return;
        }
      }, 300);
},

TipoFacturaDomicilio(e) {
      var data = ""

if(this.verificarCambioPagoDomicilio(e)==true){ 
  if(e.efectivo>0){data="Efetivo"}
  if(e.tarjeta>0){data="Tarjeta"}
  if(e.cheques>0){data="Cheques"}
  if(e.transferencia>0){data="Transferencia"}
  if(e.cxc>0){data="Cuenta por cobrar"}
}else{
  data="Multiple: "
 var sting =""

 if(e.efectivo>0){sting="Efetivo"}
 if(e.tarjeta>0){
  if(sting.length>0){sting+=", "}
  sting+="Tarjeta"
}

if(e.cheques>0){
  if(sting.length>0){sting+=", "}
  sting+="Cheques"
}

if(e.transferencia>0){
  if(sting.length>0){sting+=", "}
  sting+="Transferencia"
}

if(e.cxc>0){
  if(sting.length>0){sting+=", "}
  sting+="Cuenta por cobrar"
}

data+=`(${sting})`

}



    /*  if (e.ars != null) {
        return data = " Sequro "
      }

      if (e.telefonoDomicilio != null) {
        return data = " Domicilio "
      }

      if (e.cxc != null && e.cxc > 0) {
        return data = " Cuenta por cobrar "
      }

      if (e.tarjeta != null && e.tarjeta > 0) {
        return data = " Tarjeta ";
      }

      if (e.efectivo != null && e.efectivo > 0) {
        return data = " Efectivo ";
      }*/


      

      return data;
    },



    RecibirDomicilioValor(e) {
      //DevolucionFacturaSeleccionada.DetalleList
      //this.RedondeoBasico(this.Facturasventa.tarjeta)
      //this.DevolucionFacturaSeleccionada.quitaritbis
      var itbisTasa = 0.00;
      var itbis = 0.00;
      var descuento = 0.00;
      var descuentoValor = 0.00;
      var precioSinItbis = 0.00;
      var valor = 0.00;
      if (e.productos.impuesto != null) {
        itbisTasa = e.productos.impuesto.tasa;
      }
      if (e.valordescuento > 0) {
          var DESCUENTOVALOR= this.RecibirDomicilioDescuento(e)
        descuentoValor = this.RedondeoValor(DESCUENTOVALOR / e.cantidad);
      }
      if (e.descuento > 0) {
        if (itbisTasa > 0) {
          if (e.productos.incluirimpuestoenprecio) {
            descuento = e.cantidad * this.RedondeoValor(((e.precio / (1 + (itbisTasa / 100))) * (e.descuento / 100)));
            precioSinItbis = e.cantidad * this.RedondeoValor((e.precio / (1 + (itbisTasa / 100))));
            itbis = this.RedondeoValor((precioSinItbis - descuento) * (itbisTasa / 100));
            valor = (precioSinItbis - descuento) + itbis;
          } else {
            descuento = e.cantidad * this.RedondeoValor((e.precio * (e.descuento / 100)));
            precioSinItbis = e.cantidad * this.RedondeoValor(e.precio);
            itbis = this.RedondeoValor((precioSinItbis - descuento) * (itbisTasa / 100));
            valor = (precioSinItbis - descuento) + itbis;
          }
        } else {
          if (descuentoValor > 0) {
            valor = (e.cantidad * e.precio) - (e.cantidad * descuentoValor);
          } else {
            valor = e.cantidad * (e.precio) * (e.descuento / 100);
          }
        }
      } else {
          if (e.valorimpuesto > 0) {
            valor = e.cantidad * (e.precio - e.valorimpuesto);
          } else {
            valor = e.cantidad * (e.precio);
          }
      }
      e.valor = valor;
      return e.valor;
    },

    RecibirDomicilioDescuento(ee){

var Cant = ee.cantidad
var PrecioVal = 0


if (this.ConfImpfiscal == true) {
  PrecioVal = ee.precio
} else {

  if (ee.productos.incluirimpuestoenprecio == true) {
    if (ee.productos.impuesto != null) {
      PrecioVal = (ee.precio) / (1 + (ee.productos.impuesto.tasa / 100))
    } else { PrecioVal = ee.precio }
  } else {

    PrecioVal = ee.precio
  }

}

// ee.DescValor = ((ee.descuento * (PrecioVal * Cant)) / 100)
var valordescuento = ((ee.descuento * (PrecioVal * Cant)) / 100)

return valordescuento;

    },




    // Creando un nuevo objeto y asignándolo a la variable CotizacionFact.
    newnewCotizacion() {
      this.CotizacionFact = {
        secuencia: 0,
        activo: false,
        caja: null,
        concepto: "",
        conceptoPago: "",
        correo: "",
        direccion: "",
        facturada: false,
        fecha: new Date(),
        moneda: null,
        nombrecliente: "",
        sucursal: null,
        tasa: 1,
        telefono: "",
        valido: "",
        valor: 0,
        valordescuento: 0,
        valorimpuesto: 0,
        cotizacionDetalleList: [],
        subtotal:0, 
        quitaritbis:false,
        provedorEstado:false
      };
      this.CotizacionImp = { CotImg: false, ProForma: false };
    },
    // Cerrar el cuadro de diálogo y restablecer el formulario.
    cancelarCotizacion() {
      this.dialogCotizacion = false;
      this.newnewCotizacion();
    },
    // Guardando los datos en la base de datos de la cotizacion.
    SalvarCotizacion() {
      this.CotizacionFact.caja = this.caja.codigo;
      // this.CotizacionFact.cajero=this.OtrasConfigPv.cajero
      this.CotizacionFact.sucursal = this.caja.sucursal;
      this.CotizacionFact.cotizacionDetalleList = [];
      if(this.CotizacionFact.correo==null){this.CotizacionFact.correo=" " }
      var bar = new Promise((resolve, reject) => {
        this.RProductoCompra.forEach(async (d) => {
        d.valorimpuesto = 0;
        d.impuestoIncluido = d.productos.incluirimpuestoenprecio;
        if (d.productos.incluirimpuestoenprecio == true) {
          if (d.productos.impuesto != null) {
            d.valorimpuesto = d.precio - d.precio / (1 + d.productos.impuesto.tasa / 100);

            if(this.CotizacionFact.quitaritbis==true){ d.precio= d.precio-d.valorimpuesto }
          }
        }
        if (d.productos.incluirimpuestoenprecio == false) {
          if (d.productos.impuesto != null) {
            d.valorimpuesto = d.precio * (d.productos.impuesto.tasa / 100);
          }
        }
        if(this.CotizacionFact.quitaritbis==true){d.valorimpuesto=0 }
        this.llenarDetalleCotizacion(d);
        resolve()
      });

});

bar.then(() => {
  console.log('All done SalvarCotizacion!');
  this.CotizacionFact.moneda = this.Monedafactura.codigo;
      this.CotizacionFact.pv=true
      this.loading(true)

      var COTIZACIONFACT=JSON.parse(JSON.stringify(this.CotizacionFact))
      if(this.cotizacionAfacturaSelect!=null){
        COTIZACIONFACT.secuencia=this.cotizacionAfacturaSelect.secuencia
      }
      COTIZACIONFACT.borrado=false

      if(this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){
        COTIZACIONFACT.vendedor=this.Facturasventa.vendedor
      }else{
        COTIZACIONFACT.vendedor=null
      }

      this.$axios
        .post(
          this.$hostname + this.$hName + "/cotizacion/save",
          COTIZACIONFACT,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          var obj = JSON.parse(JSON.stringify(res.data))
          obj.CotImg = this.CotizacionImp.CotImg
          obj.ProForma = this.CotizacionImp.ProForma
          if (localStorage.printerSelect != null) { }
          var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }
            var msg = {
              accion: "print",
              formato: this.caja.formatoimpresioncontado.trim(),
              impresora: impresora,
              data: this.configuraciones,
              fac: obj,
              caja: this.caja,
              cajero: this.$store.getters.GetOtrasConfigPv.cajero,
            };
            this.imprimir(msg, "cotizacion");
         

          this.alerta("Cotizacion Realizada Correctamente !!!", "bien");
          this.dialogCotizacion = false;
        });
});   


      
    },
    // Agregar un nuevo objeto a una cotizacion.
    llenarDetalleCotizacion(dato) {
      var detalle = {
        cotizacionDetallePK: { secuencia: 0, producto: 0, unidad: 0, id: 0 },
        cantidad: dato.cantidad,
        valordescuento: dato.valordescuento,
        valorimpuesto: dato.valorimpuesto,
        tasaImpuesto: null,
        precio: dato.precio,
        descuento: dato.descuento,
        descripcion: dato.descripcion,
        cantidadDespachada: dato.cantidadDespachada,
        borrado: false,
        productos: dato.productos,
        unidades: dato.unidades,
        impuestoIncluido: dato.impuestoIncluido,
      };
      if (dato.productos.impuesto != null) {
        detalle.tasaImpuesto = dato.productos.impuesto.tasa;
      }
      this.CotizacionFact.cotizacionDetalleList.push(detalle);
    },
    // Un método que  cambia la mascara del campo  y adacta su caracteristica 
    // de pendiendo de su forma.
    MakaraCelCambio() {
      if (this.CambioDivisa.cedulaCliente.toString().length == 9) {
        this.MakCelRncCambDiv = "###-#####-#";
      }

      if (this.CambioDivisa.cedulaCliente.toString().length >= 11) {
        this.MakCelRncCambDiv = "###-#######-#";
      }
    },
    // Un método que se llama cuando el usuario cambia el valor de la entrada.
    cambioMonedas() {

if(this.CambioDivisa.moneda==null || !this.CambioDivisa.moneda.hasOwnProperty('codigo')){
  return
}

      if (!this.CambioDivisa.operacion) {
        this.CambioDivisa.cantidadEntregada =
          this.CambioDivisa.cantidadComprada *
          this.CambioDivisa.moneda.tasaCompra;
        this.CambioDivisa.devuelta =
          this.CambioDivisa.cantidadRecivida -
          this.CambioDivisa.cantidadComprada;
      } else {
        this.CambioDivisa.cantidadEntregada =
          this.CambioDivisa.cantidadComprada *
          this.CambioDivisa.moneda.tasaVenta;
        this.CambioDivisa.devuelta =
          this.CambioDivisa.cantidadRecivida -
          this.CambioDivisa.cantidadEntregada;
      }
    },

    MakaraCelOtroIngreso() {
      if (this.OtrosIngreso.identificacion.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }

      if (this.OtrosIngreso.identificacion.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }
    },

    MakaraCel() {
      if (this.clienteSeleccionado.cedrnc.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }
      if (this.clienteSeleccionado.cedrnc.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }
    },
    // Comprobando si el valor es nulo o vacío y si lo es, lo establece en 0.
    blurCantNotnull(e) {
      var _this = this;
      setTimeout(function () {
        if (e.cantidad == null) {
          e.cantidad = 0
          return
        }
        if (e.cantidad.length == 0) {
          e.cantidad = 0
          return
        }
      }, 300);


      /*if(e==null){
        e=0
        return
      }
      console.log(e.length)
      if(e.length==0){
        e=0
        return
      }*/

    },

  blurCantidadtarjeta() {

  var _this=this;
setTimeout(function(){ 
  
  if(_this.CierresCajas.cantidadtarjeta==null){
    _this.CierresCajas.cantidadtarjeta=0
  }

  if(_this.CierresCajas.cantidadtarjeta.length == 0){
    _this.CierresCajas.cantidadtarjeta=0
  }

}, 300);
  
    },

    blurCantidadcheques() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadcheques==null){
  _this.CierresCajas.cantidadcheques=0
}

if(_this.CierresCajas.cantidadcheques.length == 0){
  _this.CierresCajas.cantidadcheques=0
}

}, 300);

  },


  blurCantidadtranferencia() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadtranferencia==null){
  _this.CierresCajas.cantidadtranferencia=0
}

if(_this.CierresCajas.cantidadtranferencia.length == 0){
  _this.CierresCajas.cantidadtranferencia=0
}

}, 300);

  },


  blurCantidadrecibo() {

var _this=this;
setTimeout(function(){ 

if(_this.CierresCajas.cantidadrecibo==null){
  _this.CierresCajas.CierresCajas.cantidadrecibo=0
}

if(_this.CierresCajas.cantidadrecibo.length == 0){
  _this.CierresCajas.cantidadrecibo=0
}

}, 300);

  },

    KeyUpCantidad(e) {
      if (e.target.value.length == 0) {
        e.target.value = 0;
      }
      if (e.key) {
        var mynumeral = require("numeral");
        e.target.value = mynumeral(e.target.value).value();
        if (e.target.value == null) {
          e.target.value = 0;
        }
      } else {
        e.key = "";
      }
      if (e.target.value.length == 0) {
        e.target.value = 0;
        e.key = 0;
      }
    },
    getColor(e) {
      if (e <= 0) {
        return "red";
      } else {
        return "green";
      }
    },

    // Establecer el foco en el campo de texto.
    InfomCaja() {
      if (this.$store.getters.GetOtrasConfigPv != null) {
        this.OtrasConfigPv = this.$store.getters.GetOtrasConfigPv;
      }

      var _this = this;
      setTimeout(function () {
        _this.$refs.RefTextFieldProducto.focus();
      }, 500);



      //  this.OtrasConfigPv={
      // caja:null,
      // terminal:null,
      // impresora:null,
      // turno:null,
      // cajero:this.$store.getters.GetdatosInfUsu.usuario.nombre,
      // moneda: this.$store.getters.GetConfiguracionGeneral.config.monedabase.descripcion,
      //  },
    },
    // Hacer una solicitud GET al servidor y devolver los datos.
    findAllTerminal() {
      this.$axios
        .get("http://localhost:15999/impresora/inicial/listaImpresora")
        .then((res) => {
          this.listTerminal = res.data;

          if (res.data.length > 0) {
            this.terminal = res.data[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // Guardando el valor de la selección en localStorage.
    updateImpresoraLocal(e) {
      localStorage.setItem(
        "printerSelect",
        JSON.stringify(this.OtrasConfigPv.impresora)
      );
    },
    // Estableciendo el valor de la variable OtrasConfigPv al valor de la variable
    UpdateConfigPv(e) {
      if (e != null) {
        var User = ""
        if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
          User = this.$store.getters.GetdatosInfUsu.usuario.username
        } else {
          User = this.$store.getters.GetdatosInfUsu.usuario.nombre
        }
        this.OtrasConfigPv.moneda =
          this.configuraciones.config.monedabase.descripcion;
        this.OtrasConfigPv.cajero = User;
        this.OtrasConfigPv.caja =
          e.descripcion.trim() + "-" + this.CierresCajas.cierrescajasPK.secuencia;
        this.$store.commit("MutationDataOtrasConfigPv", this.OtrasConfigPv);
        sessionStorage.setItem(
          "OtrasConfigPv",
          JSON.stringify(this.OtrasConfigPv)
        );
        localStorage.setItem("caja", JSON.stringify(e));
        this.loadPv();
      }
    },
    // Actualización de los datos en la configuracion.
    updateConfiguracion() {
      if (this.OtrasConfigPv != null) {
        var cond = this.configuraciones.configPv.conduce;
        var mar = this.caja.marca;
        this.OtrasConfigPv.caja =
          (cond && mar ? "*" : "") +
          this.caja.descripcion.trim() +
          "-" +
          this.CierresCajas.cierrescajasPK.secuencia;

        if (this.$store.getters.GetdatosInfUsu.usuario.nombre == null) {
          this.OtrasConfigPv.cajero = this.$store.getters.GetdatosInfUsu.usuario.username
        } else {
          this.OtrasConfigPv.cajero = this.$store.getters.GetdatosInfUsu.usuario.nombre
        }

        this.$store.commit("MutationDataOtrasConfigPv", this.OtrasConfigPv);
        sessionStorage.setItem(
          "OtrasConfigPv",
          JSON.stringify(this.OtrasConfigPv)
        );
      }
    },

    

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RCaja.
    cargarCaja() {
      this.$axios
        .get(this.$hostname + this.$hName + "/cajas/findAllActivos", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCaja = res.data));
    },
    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbCajaConfigPv() {
      if (this.caja == null) {
        this.caja = "";
      }
      if (this.caja.codigo == undefined) {
        this.alerta("Seleccione una caja", "error");
        this.caja = "";
        return;
      }
    },
    SelctCbCajaFiltAsigMensajero(){
      
      var _this = this;
      setTimeout(function () {
        if (_this.filtroAsigMensajero.caja == null) {
          _this.filtroAsigMensajero.caja = "";
        }

        if (!_this.filtroAsigMensajero.caja.hasOwnProperty('codigo')) {
          _this.filtroAsigMensajero.caja = "";
          _this.filtroAsigMensajero.caja = null;
          return;
        }
      }, 300);

    },

    // Creando un nuevo objeto y asignándolo a la variable CotizacionBusqueda.
    NewBucarCotizacion() {
      this.CotizacionBusqueda = {
        secuencia: 0,
        fechaInicial: fechaNueva(),
        fechaFinal: fechaNueva(),
        cliente: null,
      };
    },

    // Establecer el valor predeterminado del paginador en 15.
    CotizacionCargarPaginacion(e) {
      this.CotizacionPaginator = e;
      if (this.CotizacionPaginator.itemsPerPage == 1) {
        this.CotizacionPaginator.itemsPerPage = 15;
      }
      this.getCotizacionlistFactCliente();
    },

    // Hacer una llamada API al servidor y devolver los datos de la cotizacion.
    getCotizacionlistFactCliente() {
      /*console.log('Viendo Informacion klk ')
      console.log(this.$refs.RefCombClienteCosFac.internalSearch)*/
      //  this.RegistroActividades.descripcion= JSON.parse(JSON.stringify(this.$refs.RefCombAct.internalSearch)) 

      var _this = this;
      setTimeout(function () {
        _this.VerCotizacionlistFactCliente = [];
        var sec,
          fecini,
          fecfin,
          cliente = null;
        if (_this.CotizacionBusqueda.secuencia != null) {
          sec = _this.CotizacionBusqueda.secuencia;
        }
        if (_this.CotizacionBusqueda.fechaInicial != null) {
          fecini = _this.CotizacionBusqueda.fechaInicial;
        }
        if (_this.CotizacionBusqueda.fechaFinal != null) {
          fecfin = _this.CotizacionBusqueda.fechaFinal;
        }
        if (_this.CotizacionBusqueda.cliente != null) {
          cliente = _this.$refs.RefCombClienteCosFac.internalSearch;
          // cliente = this.CotizacionBusqueda.cliente;
        }

        var param=""
        if(_this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true&& this.Facturasventa.vendedor!=null && this.Facturasventa.vendedor.hasOwnProperty('codigo')){ 
          var param="/"+_this.Facturasventa.vendedor.codigo
        }

        _this.$axios
          .get(
            _this.$hostname +
            _this.$hName +
            "/reimpresion/cotizacion/" +
            sec +
            "/" +
            fecini +
            "/" +
            fecfin +
            "/" +
            cliente +
            "/" +
            _this.CotizacionPaginator.itemsPerPage +
            "/" +
            (_this.CotizacionPaginator.pageStart)+param,
            { headers: _this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            //*this.TotalRegistros = res.data.cantidad,
            _this.VerCotizacionlistFactCliente = res.data.listas;
            _this.TotalRegistros = res.data.cantidad
          });

      }, 500);

    },

    async VerCotizacionDatostabla(e) { 
      var Limpiar = new Promise((resolve, reject) => {
        this.limpiarPuntoVentaCompleto()

        setTimeout(() => {
          this.loading(true)
          resolve('resolved');
        }, 200);
      });

      Limpiar.then(() => { 
        var _this=this;
 setTimeout(function(){ _this.contVerCotizacionDatostabla(e)}, 300);
        
      });
      

    },

    // Obtener los datos del servidor y agregarlos a la tabla.
    async  contVerCotizacionDatostabla(e) { 
      this.cotizacionAfacturaSelect=JSON.parse(JSON.stringify(e))
      this.dialogBucarCotizacion = false;

      this.clienteFactura = null
      this.RProductoCompraTemp = []
      this.RProductoCompra = []
      this.Facturasventa.vendedor = null
      this.Facturasventa.tipocomprobante = null
      this.Facturasventa.telefononcf = ""
      this.Facturasventa.cedularncncf = ""
      this.Facturasventa.nombreDgii = ""
      this.Facturasventa.direccionncf = ""
      this.Facturasventa.caja = this.caja;
      this.Facturasventa.sucursal = this.caja.sucursal
      this.Facturasventa.comentario = ""

      this.configuraciones.config.monedabase.tasa
      /*this.Monedafactura=JSON.parse(JSON.stringify(this.configuraciones.config.monedabase))
      this.Facturasventa.moneda=JSON.parse(JSON.stringify(this.configuraciones.config.monedabase))*/
      if (e.objmoneda != null) {
        var monedaa = e.objmoneda
        monedaa.tasa = e.tasa
        this.Monedafactura = monedaa
        this.Facturasventa.moneda = monedaa
        this.Facturasventa.tasa = monedaa.tasa;
      }
      this.SalvarMonedaFactura()

      var _this = this;
      /*this.Descuentofactura = {
        descuento: e.valordescuento,
        ValorPorc: true,
        DescuentoFacturaGlobal: true
      }*/

      //Verifica si de la lista de producto la existencia no alcansa
      var BOOL_existencia=false
      var OBJrestrList=[];
      var bar = new Promise((resolve, reject) => {
        (async () => {

        if (e.cotizacionDetalleList.length > 0) {

          //var listFtempDetalle = JSON.parse(JSON.stringify(e.facturastemporalesDetalleList.filter((ee) => ee.precio>0)))
          var listFtempDetalle = JSON.parse(JSON.stringify(e.cotizacionDetalleList.filter((ee) => ee.precio>=0)))
          listFtempDetalle.sort(function (a, b) {
            if (a.cotizacionDetallePK.id < b.cotizacionDetallePK.id) { return 1; }
            if (a.cotizacionDetallePK.id > b.cotizacionDetallePK.id) {return -1; }
            // a must be equal to b
            return 0;
          });
          setTimeout(() => {
            (async () => {

            
          // console.log(listFtempDetalle)
          const restrList =  await this.detalleRestaurarFactTem(listFtempDetalle,e.tipocomprobante);
          OBJrestrList= JSON.parse(JSON.stringify(restrList.ListTem))
          /* console.log('--OBJrestrList---')
          console.log(OBJrestrList)

         BOOL_existencia=restrList.BOOL_existencia
          console.log('--DFBOOL_existencia--')
          console.log(BOOL_existencia)*/
          if(restrList.BOOL_existencia==true){
            _this.alerta("hay producto que intenta vender, que la cantiada es mayor a la existencia", "error");
          }    
          if (OBJrestrList.length > 0) {
          // console.log('--restrList--')
          // console.log(OBJrestrList)
          this.RProductoCompraOfercta=JSON.parse(JSON.stringify(restrList.ListTem.filter((ee) => ee.paramOfert==true)))

          await Promise.all(OBJrestrList.map(async (element) => {
           // this.BuscPropiedadProductoSelct(element, 'add')
          // this.productoSeleccionado=JSON.parse(JSON.stringify(element))
          // this.SalvarInfProducto()
          element.RecarValor=(element.hasOwnProperty("RecarValor")&& element.RecarValor!=null?element.RecarValor:0)
          element.valorRecargo=(element.hasOwnProperty("valorRecargo")&& element.valorRecargo!=null?element.valorRecargo:0)
          this.RProductoCompra.unshift(JSON.parse(JSON.stringify(element)));
          this.RProductoCompraTemp.unshift(JSON.parse(JSON.stringify(element)));
         /* //this.BuscPropiedadProductoSelct(element, 'add')*/
        
          }));
          this.loading(false)
          this.$refs.RefScroll.scrollTop = 0;
        this.$refs.RefScrollProductoCompra.scrollTop = 0;

        var DATO={
              cliente:e.cliente,
              vendedor:e.vendedor,
              tipoComp:e.tipoComprobante,
              OBJ:JSON.parse(JSON.stringify(e))
            }

          

        setTimeout(function () {
     
         // _this.LimpiaInfProducto()

         _this.LimpiaInfProducto_DatoClienteSelectRestFact(DATO);
        }, 400);
          /*OBJrestrList.forEach(async(element) => {
            this.BuscPropiedadProductoSelct(element, 'add')
          });*/
             }


   setTimeout(() => {
          resolve()
        }, 500); 


            })()
          }, 500); 
        
        }

       /* setTimeout(() => {
          resolve()
        }, 500); */


      })() 
        
      });

      
      bar.then(() => {
       
        this.ObjEjecPermisoPv = null
        
        setTimeout(function () {
          _this.Facturasventa.vendedor = e.vendedor
          _this.Facturasventa.nombreDgii = e.nombrecliente
        /*  _this.Facturasventa.cedularncncf = e.cedulaDgii
          _this.Facturasventa.telefononcf = e.telefonoDgii
          _this.Facturasventa.direccionncf = e.direccionDgii*/
          _this.Facturasventa.nombreFactTem=e.nombrecliente
         
        }, 800);
      });
    },

  
    //Organizar Y preparar detalle cotizacion 
    
async detalleContizacionFact(listFtempDetalle){
 
  /*if (e.cotizacionDetalleList.length > 0) {
        e.cotizacionDetalleList.forEach(element => {

          var Link = `${element.productos.codigo}/${element.unidades.codigo}`
          this.$axios
            .get(
              this.$hostname + this.$hName + "/unidades-productos/find/" + Link,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              var Obj = res.data
              Obj.cantidad = element.cantidad
              Obj.omitirRegla = true
              Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.valordescuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = true
              this.BuscPropiedadProductoSelct(Obj, 'add')
            });
        });
      }*/
 
  //-------------------------
  var OBJ={
    ListTem:[],
    BOOL_existencia:false
  }
  return new Promise((rresolve, reject) => { 
    var bar = new Promise((resolve, reject) => { 
  listFtempDetalle.forEach(async(element, index, array) => {
            //findUnidadProductoConExistenciaAlmacen
            (async () => { 
            var Link = `${element.productos.codigo}/${element.unidades.codigo}/${this.caja.sucursal.codigo}`
            this.$axios
              .get(
                this.$hostname + this.$hName + "/unidades-productos/findUnidadProductoConExistenciaAlmacen/" + Link,
                { headers: this.$store.getters.GetheadersAxios }
              )
              .then((res) => {
                var Obj = res.data
                Obj.cantidad = element.cantidad
                Obj.omitirRegla = true
                Obj.DescValor = element.valordescuento
                Obj.descuentoKeys = element.valordescuento
                Obj.descuento = element.descuento
                Obj.ValorPorc = true
                Obj.precio = element.precio
                Obj.precioOrig = res.data.precio
                Obj.precioDig = res.data.precio
                
      if (this.configuraciones.configInv.permitirNegativos == false) {
      if (res.data.productos.servicio==false && element.cantidad > res.data.existencias) {
        OBJ.BOOL_existencia=true
        }else{
          OBJ.ListTem.push(Obj)
          // this.BuscPropiedadProductoSelct(Obj, 'add')
        }

    }else{
      OBJ.ListTem.push(Obj)
      }
     
              });

            })()

            });
            resolve()
      });

      bar.then(() => {
        // OBJ.ListTem=ListDetalleTemp
    var _this=this;
    setTimeout(function(){  
       rresolve(OBJ)  
      }, 300);
         
    });

        });
},


    SelctCbCotizacionBuscar() {
      if (this.CotizacionBusqueda.cliente == null) {
        this.CotizacionBusqueda.cliente = "";
      }
      if (this.CotizacionBusqueda.cliente.codigo == undefined) {
        this.CotizacionBusqueda.cliente = "";
        this.alerta("Seleccione un cliente", "error");
        return;
      }
    },
    NewDatosVuelo() {
      this.Facturasventa.lineaAerea = null;
      this.Facturasventa.numeroVuelo = null;
      this.Facturasventa.rutaSalidaE = null;
      this.Facturasventa.rutaSalidaD = null;
      this.Facturasventa.rutaRetornoE = null;
      this.Facturasventa.rutaRetornoD = null;
    },
    OpenDialogDatosPagareFactura() {
      if (this.clienteFactura == null) {
        this.OpenEjecDatosPagareFactura = true;
        this.OpenDialogCrearEditarCliente();
      }
      if (this.clienteFactura != null) {
        if (this.RProductoCompra.length > 0) {
          this.dialogPagare = true;
          this.OpenEjecDatosPagareFactura = false;
          this.ObjTemPagare = JSON.parse(
            JSON.stringify(this.DatosPagareFactura)
          );
        } else {
          this.alerta(
            "Debe de agregar al menos 1 producto para ver la opción de pagaré",
            "error"
          );
        }
      }
      this.DatosPagareFactura.contrato.valorPrestamo =
        this.Totales.totalGeneral.total;
    },
    calcularCapital() {
      this.DatosPagareFactura.contrato.CapitalFinanciado =
        this.DatosPagareFactura.contrato.valorPrestamo -
        this.DatosPagareFactura.contrato.inicio;
      this.DatosPagareFactura.contrato.Capitalpagar =
        this.DatosPagareFactura.contrato.CapitalFinanciado;
    },
    calcularInteres() {
      this.DatosPagareFactura.contrato.InteresMensual =
        this.DatosPagareFactura.contrato.CapitalFinanciado *
        (this.DatosPagareFactura.contrato.interes / 100);
      this.DatosPagareFactura.contrato.Interespagar =
        this.DatosPagareFactura.contrato.CapitalFinanciado *
        (this.DatosPagareFactura.contrato.interes / 100) *
        this.DatosPagareFactura.contrato.CantidadCuotas;
      this.DatosPagareFactura.contrato.MontoCuotas =
        this.DatosPagareFactura.contrato.CapitalFinanciado /
        this.DatosPagareFactura.contrato.CantidadCuotas +
        this.DatosPagareFactura.contrato.CapitalFinanciado *
        (this.DatosPagareFactura.contrato.interes / 100);
    },
    detallePagare() {
      var fecha = this.DatosPagareFactura.contrato.fecha;
      this.DatosPagareFactura.Distribucioncuotas = [];
      var d = {
        tipo: "PAG",
        pago: "INICIAL",
        fechaVenc: fecha,
        capital: this.DatosPagareFactura.contrato.inicio,
        interes: 0.0,
        total: this.DatosPagareFactura.contrato.inicio,
      };
      this.DatosPagareFactura.Distribucioncuotas.push(d);
      for (
        var i = 1;
        i <= this.DatosPagareFactura.contrato.CantidadCuotas;
        i++
      ) {
        fecha = this.sumarFechas(
          fecha,
          this.DatosPagareFactura.contrato.TipoInteres.tipo,
          this.DatosPagareFactura.contrato.TipoInteres.dia
        );
        var det = {
          tipo: "PAG",
          pago: i + "/" + this.DatosPagareFactura.contrato.CantidadCuotas,
          fechaVenc: fecha,
          capital:
            this.DatosPagareFactura.contrato.MontoCuotas -
            this.DatosPagareFactura.contrato.InteresMensual,
          interes: this.DatosPagareFactura.contrato.InteresMensual,
          total: this.DatosPagareFactura.contrato.MontoCuotas,
        };
        fecha = this.sumarFechas(
          fecha,
          this.DatosPagareFactura.contrato.TipoInteres.tipo,
          this.DatosPagareFactura.contrato.TipoInteres.dia
        );
        //totalPagare += this.util.roundValor(parseFloat($("#montoCuotas").val()));
        this.DatosPagareFactura.Distribucioncuotas.push(det);
      }
    },
    sumarFechas(fecha, tipo, cantidad) {
      var parts = fecha.split("-");
      var fech;
      if (tipo === 1) {
        fech = new Date(parts[0], parts[1] - 1, parseInt(parts[2]) + cantidad);
      }
      if (tipo === 2) {
        fech = new Date(parts[0], parts[1], parts[2]);
      }
      if (tipo === 3) {
        fech = new Date(parseInt(parts[0]) + 1, parts[1] - 1, parts[2]);
      }
      return fech.toISOString().substring(0, 10);
    },
    cargarDetallePagare() {
      if (this.DatosPagareFactura.contrato.fecha === "") {
        this.alerta("Debe Seleccionar Una Fecha", "error");
      }
      if (this.DatosPagareFactura.contrato.CantidadCuotas === "") {
        this.alerta("Debe seleccionar la cantidad de cuotas ", "error");
      }
      this.DatosPagareFactura.contrato.Intervalosdias =
        this.DatosPagareFactura.contrato.TipoInteres.dia;
      this.detallePagare();
    },
    NewDatosPagareFactura() {
      this.DatosPagareFactura = {
        contrato: {
          fecha: new Date(),
          valorPrestamo: 0.0,
          inicio: 0,
          valorRecibido: 0,
          CapitalFinanciado: 0,
          interes: 0,
          CantidadCuotas: 0,
          Capitalpagar: 0,
          Interespagar: 0,
          MontoCuotas: 0,
          TipoInteres: null,
          Intervalosdias: null,
          InteresMora: 0,
          prorroga: "",
        },

        Distribucioncuotas: [],
      };
    },

    CancelarDatosPagareFactura() {
      this.dialogPagare = false;
      this.DatosPagareFactura = JSON.parse(JSON.stringify(this.ObjTemPagare));
      this.ObjTemPagare = null;
    },

    SalvarDatosPagareFactura() {
      if (this.$refs.formPagares.validate()) {
        this.dialogPagare = false;
        this.Facturasventa.efectivo = JSON.parse(JSON.stringify(this.DatosPagareFactura.contrato.inicio))
        this.Facturasventa.cxc = JSON.parse(JSON.stringify(this.DatosPagareFactura.contrato.Capitalpagar))
        var _this = this;
        setTimeout(function () { _this.calcularDevuelta() }, 300);


      } else {

        this.alerta("los campos en rojo son requeridos", "error");

      }





    },

    // Creando un nuevo objeto llamado ConduceBusqueda y asignándolo a la propiedad de datos
    // ConduceBusqueda.
    NewBucarConduce() {
      this.ConduceBusqueda = {
        secuencia: 0,
        fechaInicial: fechaNueva(),
        fechaFinal: fechaNueva(),
        cliente: null,
      };
    },

    // Hacer una llamada al servidor para obtener los datos.
    getConducelistFactCliente() {
      this.VerCotizacionlistFactCliente = [];
      var sec,
        fecini,
        fecfin,
        cliente = null;
      if (this.ConduceBusqueda.secuencia != null) {
        sec = this.ConduceBusqueda.secuencia;
      }
      if (this.ConduceBusqueda.fechaInicial != null) {
        fecini = this.ConduceBusqueda.fechaInicial;
      }
      if (this.ConduceBusqueda.fechaFinal != null) {
        fecfin = this.ConduceBusqueda.fechaFinal;
      }
      if (this.ConduceBusqueda.cliente != null) {
        cliente = this.ConduceBusqueda.cliente;
      }

      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reimpresion/conduce/" +
          sec +
          "/" +
          fecini +
          "/" +
          fecfin +
          "/" +
          cliente +
          "/" +
          this.ReimprecionPaginator.itemsPerPage +
          "/" +
          this.ReimprecionPaginator.pageStart,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          //*this.TotalRegistros = res.data.cantidad,
          this.VerConducelistFactCliente = res.data.listas;
        });
    },



    VerConduceDatostabla(e) {
      var Limpiar = new Promise((resolve, reject) => {
        this.limpiarPuntoVentaCompleto()

        setTimeout(() => {
          this.loading(true)
          resolve('resolved');
        }, 200);
      });

      Limpiar.then(() => { 
        var _this=this;
 setTimeout(function(){ _this.contVerConduceDatostabla(e)}, 300);
        
      });
    },

    // Obtener los datos del servidor y luego agregarlos a la tabla los datos del producto.
    async contVerConduceDatostabla(e) {

      this.dialogBucarConduce = false;
      this.conduceSelect = e.conduceInventarioPK;
      // detalleConduceFact

      this.clienteFactura = null
      this.RProductoCompraTemp = []
      this.RProductoCompra = []
      this.Facturasventa.vendedor = null
      this.Facturasventa.tipocomprobante = null
      this.Facturasventa.telefononcf = ""
      this.Facturasventa.cedularncncf = ""
      this.Facturasventa.nombreDgii = ""
      this.Facturasventa.direccionncf = ""
      this.Facturasventa.caja = this.caja;
      this.Facturasventa.sucursal = this.caja.sucursal
      this.Facturasventa.comentario = ""

      this.configuraciones.config.monedabase.tasa
     
      if (e.objmoneda != null) {
        var monedaa = e.objmoneda
        monedaa.tasa = e.tasa
        this.Monedafactura = monedaa
        this.Facturasventa.moneda = monedaa
        this.Facturasventa.tasa = monedaa.tasa;
      }
      this.SalvarMonedaFactura()

      var _this = this;

      //Verifica si de la lista de producto la existencia no alcansa
      var BOOL_existencia=false
      var OBJrestrList=[];
      var bar = new Promise((resolve, reject) => {
        (async () => {

        if (e.conduceInventarioDetalleList.length > 0) {

          //var listFtempDetalle = JSON.parse(JSON.stringify(e.facturastemporalesDetalleList.filter((ee) => ee.precio>0)))
          var listFtempDetalle = JSON.parse(JSON.stringify(e.conduceInventarioDetalleList.filter((ee) => ee.precio>=0)))

          Promise.all(listFtempDetalle.map(async (element) => { 
            element.productos= JSON.parse(JSON.stringify(element.producto)) 
            element.unidades = JSON.parse(JSON.stringify(element.unidad)) 
          }));

        
          listFtempDetalle.sort(function (a, b) {
            if (a.id < b.id) { return 1; }
            if (a.id > b.id) {return -1; }
            // a must be equal to b
            return 0;
          });
          setTimeout(() => {
            (async () => {

            
          // console.log(listFtempDetalle)
          const restrList =  await this.detalleRestaurarFactTem(listFtempDetalle,e.tipoComprobante);
          OBJrestrList= JSON.parse(JSON.stringify(restrList.ListTem))
          /* console.log('--OBJrestrList---')
          console.log(OBJrestrList)

         BOOL_existencia=restrList.BOOL_existencia
          console.log('--DFBOOL_existencia--')
          console.log(BOOL_existencia)*/
          if(restrList.BOOL_existencia==true){
            _this.alerta("hay producto que intenta vender, que la cantiada es mayor a la existencia", "error");
          }    
          if (OBJrestrList.length > 0) {
          // console.log('--restrList--')
          // console.log(OBJrestrList)
          this.RProductoCompraOfercta=JSON.parse(JSON.stringify(restrList.ListTem.filter((ee) => ee.paramOfert==true)))

          await Promise.all(OBJrestrList.map(async (element) => {
           // this.BuscPropiedadProductoSelct(element, 'add')
          // this.productoSeleccionado=JSON.parse(JSON.stringify(element))
          // this.SalvarInfProducto()
          element.RecarValor=(element.hasOwnProperty("RecarValor")&& element.RecarValor!=null?element.RecarValor:0)
          element.valorRecargo=(element.hasOwnProperty("valorRecargo")&& element.valorRecargo!=null?element.valorRecargo:0)
          this.RProductoCompra.unshift(JSON.parse(JSON.stringify(element)));
          this.RProductoCompraTemp.unshift(JSON.parse(JSON.stringify(element)));
         /* //this.BuscPropiedadProductoSelct(element, 'add')*/
        
          }));
          this.loading(false)
          this.$refs.RefScroll.scrollTop = 0;
        this.$refs.RefScrollProductoCompra.scrollTop = 0;

        var DATO={
              cliente:e.cliente,
              vendedor:e.vendedor,
              tipoComp:e.tipoComprobante,
              OBJ:JSON.parse(JSON.stringify(e))
            }

          

        setTimeout(function () {
     
         // _this.LimpiaInfProducto()

         _this.LimpiaInfProducto_DatoClienteSelectRestFact(DATO);
        }, 400);
          /*OBJrestrList.forEach(async(element) => {
            this.BuscPropiedadProductoSelct(element, 'add')
          });*/
             }


   setTimeout(() => {
          resolve()
        }, 500); 


            })()
          }, 500); 
        
        }

       /* setTimeout(() => {
          resolve()
        }, 500); */


      })() 
        
      });

      
      bar.then(() => {
       

      
        
        this.ObjEjecPermisoPv = null
        
        setTimeout(function () {
    
          _this.Facturasventa.vendedor = e.vendedor
          _this.Facturasventa.nombreDgii = e.decripcion 
   
         
        }, 800);
      });

    
      
    },

//Organizar Y preparar detalle cotizacion 
async detalleConduceFact(listFtempDetalle){
 var OBJ={
   ListTem:[],
   BOOL_existencia:false
 }
 return new Promise((rresolve, reject) => { 
   var bar = new Promise((resolve, reject) => { 
 listFtempDetalle.forEach(async(element, index, array) => {
           //findUnidadProductoConExistenciaAlmacen
           (async () => { 
           var Link = `${element.producto.codigo}/${element.unidad.codigo}/${this.caja.sucursal.codigo}`
           this.$axios
             .get(
               this.$hostname + this.$hName + "/unidades-productos/findUnidadProductoConExistenciaAlmacen/" + Link,
               { headers: this.$store.getters.GetheadersAxios }
             )
             .then((res) => {
               var Obj = res.data
               Obj.cantidad = element.cantidad
               Obj.omitirRegla = true
               Obj.DescValor = element.valordescuento
               Obj.descuentoKeys = element.valordescuento
               Obj.descuento = element.descuento
               Obj.ValorPorc = true
               Obj.precio = element.precio
               Obj.precioOrig = res.data.precio
               Obj.precioDig = res.data.precio
               
     if (this.configuraciones.configInv.permitirNegativos == false) {
     if (res.data.productos.servicio==false && element.cantidad > res.data.existencias) {
       OBJ.BOOL_existencia=true
       }else{
         OBJ.ListTem.push(Obj)
         // this.BuscPropiedadProductoSelct(Obj, 'add')
       }

   }else{
     OBJ.ListTem.push(Obj)
     }
    
             });

           })()

           });
           resolve()
     });

     bar.then(() => {
       // OBJ.ListTem=ListDetalleTemp
   var _this=this;
   setTimeout(function(){  
      rresolve(OBJ)  
     }, 300);
        
   });

       });
},





    SelctCbConduceBuscar() {
      if (this.ConduceBusqueda.cliente == null) {
        this.ConduceBusqueda.cliente = "";
      }
      if (this.ConduceBusqueda.cliente.codigo == undefined) {
        this.ConduceBusqueda.cliente = "";
        this.alerta("Seleccione un cliente", "error");
        return;
      }
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RTipoTarjeta.
    cargarTipoTarjeta() {
      this.$axios
        .get(this.$hostname + this.$hName + "/tarjeta/findall/", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RTipoTarjeta = res.data));
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoTarjeta() {
      if (this.tipoTarjeta == null) {
        this.tipoTarjeta = "";
      }

      if (this.tipoTarjeta.codigo == undefined) {
        this.alerta("Seleccione un Tipo de tarjeta", "error");
        this.tipoTarjeta = "";
        return;
      }
    },

    // Llamar a una API y obtener los datos de la API y almacenarlos en la variable RBanco.
    cargarBanco() {
      this.$axios
        .get(this.$hostname + this.$hName + "/bancos/findall", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RBanco = res.data));
    },

    // Comprobando si el valor de la selección no está definido.
    SelctCbBanco() {
      if (this.bancoCheque == null) {
        this.bancoCheque = "";
      }

      if (this.bancoCheque.codigo == undefined) {
        this.alerta("Seleccione un banco", "error");
        this.bancoCheque = "";
        return;
      }
    },

    // Establecer el valor de las variables en verdadero o falso.
    defoulFormaPagoPanel() {
      this.PanelFormaPagoColmna1 = true;
      this.PanelFormaPagoColmna2 = true;
      this.PanelFormaPagoTarjeta = false;
      this.PanelFormaPagoCheque = false;
      this.PanelMenuOpcionBotones = true;
      this.PanelFormaPagoNotaCredito = false;
    },

    // Poniendo el valor de la variable PanelFormaPagoTarjeta verdadero si los datos de la tarjeta no es correcto.
    OpenPanelFormaPagoTarjeta() {
      this.defoulFormaPagoPanel();
      this.PanelFormaPagoColmna2 = false;
      this.PanelFormaPagoTarjeta = true;
    },

    // Abriendo un panel en un formulario cheque.
    OpenPanelFormaPagoCheque(e) {
      this.defoulFormaPagoPanel();
      this.NombreFormaPagoCheqTras = e;
      this.PanelFormaPagoColmna2 = false;
      this.PanelFormaPagoCheque = true;
    },

    OpenPanelFormaPagoNotacredito() {
      this.CargarNotacreditocontado()
      this.defoulFormaPagoPanel();
      this.PanelMenuOpcionBotones = false;
      this.PanelFormaPagoNotaCredito = true;

    },


    BlurNotaCreditoPago() {
      if (this.selectedListFPagoNC.length > 0) {

        if (this.Facturasventa.valorNc > this.selectedListFPagoNC[0].pendiente) {
          this.alerta("no puede digitar un valor más alto del valor pendiente de la nota de crédito", "error");
          this.Facturasventa.valorNc = JSON.parse(JSON.stringify(this.selectedListFPagoNC[0].pendiente))
        }

      }
      this.calculostotales()

    },
    CargarNotacreditocontado() {

      if (this.PanelFormaPagoNotaCredito == false) {
        var fe = this.fechaNueva()
        this.$axios.get(this.$hostname + this.$hName +
          `/devolucion/notacreditocontado/${fe}/null`,
          { headers: this.$store.getters.GetheadersAxios }
        )
          .then((res) => {
            this.ListFormaPagoNC = res.data.ListNotacreditoContado
          });
      }

    },
    CbFilNotacreditocontado(e) {

      if (e.target.value.length == 0) {
        this.CargarNotacreditocontado()
      } else {
        this.$axios
          .get(
            this.$hostname +
            this.$hName +
            "/devolucion/notacreditocontado/null/" + e.target.value,
            { headers: this.$store.getters.GetheadersAxios }
          )
          .then((res) => {
            this.ListFormaPagoNC = res.data.ListNotacreditoContado
          });

      }

    },

    obtenerDatoSelectFormaPagoNC(e) {
      if (e.value == true) {
        this.obtenerDatoSelectFormaPagoNCClickRow(e.item);
      }
    },
    obtenerDatoSelectFormaPagoNCClickRow(e) {
      this.selectedListFPagoNC = [];
      this.selectedListFPagoNC.push(e);
      if (this.Facturasventa.valorNc == 0) {
        this.Facturasventa.valorNc = JSON.parse(JSON.stringify(e.pendiente))
      }

      /* if (this.Facturasventa.valorNc > e.valor) {
         this.Facturasventa.valorNc = JSON.parse(JSON.stringify(e.pendiente))
       }*/

      if (this.Totales.totalGeneral.total > e.valor) {
        this.Facturasventa.valorNc = JSON.parse(JSON.stringify(e.pendiente))
      } else {
        this.Facturasventa.valorNc = JSON.parse(JSON.stringify(this.Totales.totalGeneral.total))
      }


      this.calculostotales()
      //this.Facturasventa.
    },

    // Comprobando si el valor de la selección es nulo o indefinido.
    SelctCbTipoRecibo() {
      if (this.OtrosIngreso.tipoRecibo == null) {
        this.OtrosIngreso.tipoRecibo = "";
      }

      if (this.OtrosIngreso.tipoRecibo.codigo == undefined) {
        this.alerta("Seleccione un Tipo de Otros ingreso", "error");
        this.OtrosIngreso.tipoRecibo = "";
        return;
      }
    },

    // El comprobante seleccionado está guardando los datos del formulario en el objeto Facturasventa.
    SalvarComprobante() {
      if (this.$refs.formComprobante.validate()) {

        if(this.ComprobanteFisc.cedrnc!=null && this.ComprobanteFisc.cedrnc.length>0){ 
          var CEDULA = this.ComprobanteFisc.cedrnc.replace("-", "");
          CEDULA = CEDULA.replace(/-/g,'')
        if(this.validaCedRnc(CEDULA)==false){
          var NOM= CEDULA.length>10?"CEDULA":"RNC"
      this.alerta(`${NOM} Invalida `, "error");
        return;
        }
        }

        this.Facturasventa.tipocomprobante = JSON.parse(JSON.stringify(this.ComprobanteFisc.comprobante))
        this.Facturasventa.nombreDgii = JSON.parse(JSON.stringify(this.ComprobanteFisc.nombre))
        this.Facturasventa.telefononcf = JSON.parse(JSON.stringify(this.ComprobanteFisc.telefono))
        this.Facturasventa.direccionncf = JSON.parse(JSON.stringify(this.ComprobanteFisc.direccion))
        this.Facturasventa.cedularncncf = JSON.parse(JSON.stringify(this.ComprobanteFisc.cedrnc))
        this.dialogComprobante = false
        var _this = this;
        setTimeout(function () {
           _this.CalcularTotalesProductosCompra();
        }, 500);

      } else {
        this.alerta("los campos en rojo son requeridos", "error");
      }

    },

    // Establecer el valor del objeto 
    cancelarComprobante() {
      this.ComprobanteFisc = {
        comprobante: null,
        nombre: null,
        telefono: '',
        direccion: '',
        cedrnc: ""
      },
        this.dialogComprobante = false
    },


    // Comprobando si el objeto en nulo o indefinido.
    SelctCbTipoCompComprobant() {
      var _this = this;
      setTimeout(function () {
        if (_this.ComprobanteFisc.comprobante == null) {
          _this.ComprobanteFisc.comprobante = "";
        }

        if (!_this.ComprobanteFisc.comprobante.hasOwnProperty('codigo')) {
          _this.ComprobanteFisc.comprobante = "";
          _this.ComprobanteFisc.comprobante = null;
          return;
        }
      }, 300);
    },


    // Un método que se llama cuando se cambia la entrada.
    MakaraCelComprobante() {
      if (this.ComprobanteFisc.cedrnc.toString().length == 9) {
        this.MakCelRnc = "###-#####-#";
      }
      if (this.ComprobanteFisc.cedrnc.toString().length >= 11) {
        this.MakCelRnc = "###-#######-#";
      }
    },

    // Comprobando si el valor de la selección es nulo o no.
    SelctCbMotivoAnulacion() {
      var _this = this;
      setTimeout(function () {
        if (_this.TipoAnulacion == null) {
          _this.TipoAnulacion = "";
        }

        if (!_this.TipoAnulacion.hasOwnProperty('codigo')) {
          _this.TipoAnulacion = "";
          _this.TipoAnulacion = null;
          return;
        }
      }, 300);
    },


    calcularToTalRecIng() {
      var tot =
        this.ReciboIngresoFactura.efectivo +
        this.ReciboIngresoFactura.cheque +
        this.ReciboIngresoFactura.transferencia +
        this.ReciboIngresoFactura.tarjeta

      this.devueltaRecIngreso =
        Math.round((tot - this.ReciboIngreso.abono) * 100) / 100;
    },
    async AllSelcDevObtenerDatoSelect(e) {

      if (e.value == true) {

        var _this = this;
        setTimeout(function () {

          _this.DevolucionFacturaSeleccionada.SeletDetalleList.forEach(element => {

            if (element.cantidadPendiente == 0) {
              const index = _this.DevolucionFacturaSeleccionada.SeletDetalleList.indexOf(element);
              _this.DevolucionFacturaSeleccionada.SeletDetalleList.splice(index, 1);
              _this.alerta("no puede hacer una devolución a un producto con la cantidad en 0", "error");
            }

          });
        }, 200);

      }

      var _this = this;
      setTimeout(function () { _this.DevolucionValorTotal() }, 400);

    },


    SelcDevObtenerDatoSelect(e) {
      // @item-selected="DevolucionObtenerDatoSelect"
      if (e.value == true) {
        if (e.item.cantidadPendiente == 0) {
          var _this = this;
          setTimeout(function () {
            const index = _this.DevolucionFacturaSeleccionada.SeletDetalleList.indexOf(e.item);
            _this.DevolucionFacturaSeleccionada.SeletDetalleList.splice(index, 1);
          }, 200);
          this.alerta("no puede hacer una devolución a un producto con la cantidad en 0", "error");

        }


      }

      var _this = this;
      setTimeout(function () { _this.DevolucionValorTotal() }, 400);
    },



    // Creando un nuevo objeto llamado TbPaginatorHisCli para historial de cliente.
    NewHistorialClientRecIng() {
      this.ReciboIngresoFactPendHist = true
      this.listHiscliente = []
      this.TbTotalItemHisCli = 0
      this.TbPaginatorHisCli = {
        page: 1,
        itemsPerPage: 10,
        pageStart: 1,
        pageStop: 15,
        pageCount: 10,
        itemsLength: 5,
      }

    },

openFarmacoVigilancia(){

  /*if (this.RProductoCompra.length == 0) {
        this.alerta("Debe de Agregar al menos un producto a la lista", "error")
        return
      }*/


      if (this.clienteFactura == null) {
        this.OpenEjecFarmacoVigilancia = true;
        this.OpenDialogCrearEditarCliente();
        return
      }

      this.nuevoFarmacoVigilancia();
      this.dataFarmacoVigilancia();
      
  this.dialogFarmacoVigilancia=true
  this.RedireccionFocus('RefTextFieldFarViMedico')
  

},

async dataFarmacoVigilancia(){
  if (this.clienteFactura != null) {
        this.OpenEjecFarmacoVigilancia=false
        this.farmacoVigilancia.cliente = this.clienteFactura;
        this.farmacoVigilancia.telefono = this.selectTelefonoCliente(this.clienteFactura)
        this.farmacoVigilancia.direccion = this.clienteFactura.direccion;
        if(this.clienteFactura.ars!=null ){
          this.farmacoVigilancia.ars=this.clienteFactura.ars
        }
       
        this.farmacoVigilancia.nss= this.clienteFactura.hasOwnProperty('nss')?this.clienteFactura.nss:"";
        //this.farmacoVigilancia.nss

      }

      if (this.SelectMedicoFactura != null) {
        this.farmacoVigilancia.medico=this.SelectMedicoFactura.nombre 
      }
      this.farmacoVigilancia.monto = this.Totales.totalGeneral.total

   if(this.RProductoCompra.length>0){
    // this.RProductoCompra.forEach(element => {});
    await Promise.all(this.RProductoCompra.map(async (element) => { 
      var obj={
        codigo:0,
        unidadProducto:element,
        cantfac:element.cantidad,
        cantrec:0,
        cantind:0,
        indic:'',
        desprod: `${element.productos.descripcion} (${element.unidades.descripcion.trim()})`,
        codpro:element.unidadesProductosPK.producto,
        coduni:element.unidadesProductosPK.unidad,
        estatus:false

      }
      this.farmacoVigilancia.farmacoVigilanciaDetalleList.push(obj)
    }));
  }

  //  console.log(this.HoraFormatter(new Date()))
 this.HoraFarmacoVigilancia()
  //setInterval(this.HoraFarmacoVigilancia,60000);

},
  
HoraFarmacoVigilancia(){

  this.farmacoVigilancia.hora=this.HoraFormatter2(new Date())
  //setInterval(this.HoraFarmacoVigilancia,10000);

/*  var cont = 0;
var rango = document.getElementById('cont');

    var id = setInterval(function(){
        rango.innerHTML = cont;
        cont++;
        if(cont == 10) 
        {
            clearInterval(id);
        }
    }, 1000);*/


},

cancelarFarmacoVigilancia(){
this.nuevoFarmacoVigilancia()
  this.dialogFarmacoVigilancia=false
},


async SalvarFarmacoVigilancia(){

  // debe asignar al menos un producto
  var ObjProRow = JSON.parse(JSON.stringify(this.farmacoVigilancia.farmacoVigilanciaDetalleList.filter((ee) => ee.cantrec>0))) 
  await Promise.all(ObjProRow.map(async (element) => { 
    if(element.fechaReposicion!=null){
      element.fechaReposicion=element.fechaReposicion.trim().substr(0,10)+"T04:00"
      if(element.cantfac>= element.cantrec){element.estatus=true}
    }
  }));

    if(ObjProRow.length==0){
      this.alerta("debe asignar al menos un producto colocando la cantidad resetada", "error");
      return ;
    }

  // farmacoVigilancia.farmacoVigilanciaDetalleList

  if (this.$refs.formfarmacoVigilancia.validate()) {
    this.farmacoVigilancia.hora=this.HoraFormatter2(new Date())
    var obj=JSON.parse(JSON.stringify(this.farmacoVigilancia));
    obj.farmacoVigilanciaDetalleList=JSON.parse(JSON.stringify(ObjProRow));

    this.loading(true)
          this.$axios
            .post(
              this.$hostname + this.$hName + "/FarmacoVigilancia/save",
              obj,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              // this.alerta("Dato guardado correctamente!", "bien2");
              var vari=res.data.estado
              if(res.data.estado==="bien"){
                vari="bien2" 
              
              
                if (localStorage.printerSelect != null) {}
                var selectImpre=(localStorage.printerSelect!=undefined?JSON.parse(localStorage.printerSelect):null)
            var impresora=null
            if(selectImpre!=null && selectImpre.hasOwnProperty('nombre')){
              impresora=JSON.parse(localStorage.printerSelect).nombre
              }

              var msg = {
                accion: "print",
                formato: this.caja.formatoimpresioncontado.trim(),
                impresora: impresora,
                data: this.configuraciones,
                fac: res.data.datos,
                caja: this.caja,
                cajero: this.$store.getters.GetOtrasConfigPv.cajero,
              };
              this.imprimir(msg, "vigilancia");
            
              
              
              }
              this.alerta(res.data.mensage, vari);
              this.cancelarFarmacoVigilancia();
            })
            .catch((error) => {
              this.loading(false)
              this.alerta("Dato no guardado correctamente", "error")
            }
            );


}else {
        this.alerta("los campos en rojo son requeridos", "error");
      }


},


eliminarFarmacoVigilancia(){ 

if(this.farmacoVigilancia.codigo==0){
  this.alerta("seleccione una receta  para eliminar", "error");
  return 0;
}


  if (this.$refs.formfarmacoVigilancia.validate()) {
    var obj=JSON.parse(JSON.stringify(this.farmacoVigilancia));
    this.loading(true)
          this.$axios
            .post(
              this.$hostname + this.$hName + "/FarmacoVigilancia/borrado",
              obj,
              { headers: this.$store.getters.GetheadersAxios }
            )
            .then((res) => {
              this.alerta(res.data.mensage, res.data.estado);
              this.cancelarFarmacoVigilancia();
            })
            .catch((error) => {
              this.loading(false)
              this.alerta("Dato no borrado correctamente", "error")
            }
            );


}else {
        this.alerta("los campos en rojo son requeridos", "error");
      }
},


async buscarRecetaVigilancia(){
  this.consultaRecetaCreadaList=[]
  //buscarFarmVigBoolFactReceta=(false:receta / true:factura)
  if(this.buscarFarmVigBoolFactReceta==false){
  if(this.buscarFarmVigilancia==0){
    this.loading(true)
    this.$axios
        .get(this.$hostname + this.$hName + "/FarmacoVigilancia/FarmaByClienteList/"+this.farmacoVigilancia.cliente.codigo, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          this.loading(false)
          
          this.dialogConsultaRecetaCreada=true
          if(res.data.length>0){
           this.consultaRecetaCreadaList=JSON.parse(JSON.stringify(res.data))
          }else{
            this.alerta("este cliente no tiene receta", "error");
          }
        /*  if(res.data.hasOwnProperty('codigo')){
            this.farmacoVigilancia=JSON.parse(JSON.stringify(res.data))
            this.farmacoVigilancia.fecha=new Date(res.data.fecha).toISOString().substr(0, 10)
            this.farmacoVigilancia.fechavencimiento=new Date(res.data.fechavencimiento).toISOString().substr(0, 10)
            console.log(res.data)
          }else{
            this.alerta("No hay receta con este codigo", "error");
          }  */ 
        });


   
  }else{
    this.$axios
        .get(this.$hostname + this.$hName + "/FarmacoVigilancia/FarmaBycodigo/"+this.buscarFarmVigilancia, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.hasOwnProperty('codigo')){
            this.editConsultaRecetas(res.data)
            /*this.farmacoVigilancia=JSON.parse(JSON.stringify(res.data))
            this.farmacoVigilancia.fecha=new Date(res.data.fecha).toISOString().substr(0, 10)
            this.farmacoVigilancia.fechavencimiento=new Date(res.data.fechavencimiento).toISOString().substr(0, 10)*/
          }else{
            this.alerta("No hay receta con este codigo", "error");
          }   
        });
  }
}else{
 this.buscarFacturaRecetaFarmVig()

}

},


buscarFacturaRecetaFarmVig(){

  if(this.buscarFarmVigilancia==0){
    this.LimpiarFarmacoVigilancia()
    return
  }

  this.$axios
        .get(this.$hostname + this.$hName + `/FarmacoVigilancia/getrecetafarmacovigilancia/${this.farmacoVigilancia.cliente.codigo}/${this.buscarFarmVigilancia}`, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.hasOwnProperty('codigo')){
            this.buscarFarmVigBoolFactReceta=false
            this.editConsultaRecetas(res.data)
          }else{
            this.buscarFacturaTbRecetaFarmVig()
          }
          
        })
        .catch((error) => {
              this.alerta("factura no encontrada", "error")
            });       
},


buscarFacturaTbRecetaFarmVig (){
  this.selectedFactura = [];
      this.listFactCliente = [];
      var cliente = this.farmacoVigilancia.cliente.codigo,
        fecini = "null",
        fecfin = "null",
        caja = "null",
        sec = this.buscarFarmVigilancia;
      this.$axios
        .get(
          this.$hostname +
          this.$hName +
          "/reimpresion/facturasCajaCodcli/" +sec +
          "/" +fecini +"/" +fecfin +"/" +
          cliente +"/" +30 +"/" +0+"/"+caja,
          { headers: this.$store.getters.GetheadersAxios }
        )
        .then((res) => {
          if(res.data.listas.length==0){
            this.alerta("No existe factura con este numero.", "error")
          }else{
            //this.LimpiarFarmacoVigilancia()
            this.farmacoVigilancia.farmacoVigilanciaDetalleList=[]
            var _this = this;
        setTimeout(function () {
          _this.databuscarFacFarmacoVigilancia(res.data.listas[0])
        }, 500);
            
          }
         
          //this.listFactCliente = res.data.listas;
        })
        .catch((error) => {this.alerta("Factura no encontrada ", "error")});
},

async databuscarFacFarmacoVigilancia(FACTURA){
  this.buscarFarmVigilancia=FACTURA.facturasventaPK.secuencia
  if (FACTURA.cliente!= null) {
        this.OpenEjecFarmacoVigilancia=false
        this.farmacoVigilancia.cliente = FACTURA.cliente;
        this.farmacoVigilancia.telefono = this.selectTelefonoCliente(FACTURA.cliente)
        this.farmacoVigilancia.direccion = FACTURA.cliente.direccion;
        if(FACTURA.cliente.ars!=null ){
          this.farmacoVigilancia.ars=FACTURA.cliente.ars
        }
       
        this.farmacoVigilancia.nss= FACTURA.cliente.hasOwnProperty('nss')?FACTURA.cliente.nss:"";
        //this.farmacoVigilancia.nss

      }

    
      this.farmacoVigilancia.monto = FACTURA.valor
      this.farmacoVigilancia.facturasventa=FACTURA

   if(FACTURA.facturasventaDetalleList.length>0){
    // this.RProductoCompra.forEach(element => {});
    await Promise.all(FACTURA.facturasventaDetalleList.map(async (element) => { 
      var obj={
        codigo:0,
        unidadProducto:element,
        cantfac:element.cantidad,
        cantrec:0,
        cantind:0,
        indic:'',
        desprod: `${element.productos.descripcion} (${element.unidades.descripcion.trim()})`,
        codpro:element.productos.codigo,
        coduni:element.unidades.codigo,
       // codpro:element.unidadesProductosPK.producto,
       // coduni:element.unidadesProductosPK.unidad,
        estatus:false

      }
      this.farmacoVigilancia.farmacoVigilanciaDetalleList.push(obj)
    }));
  }

  //  console.log(this.HoraFormatter(new Date()))
 this.HoraFarmacoVigilancia()
  //setInterval(this.HoraFarmacoVigilancia,60000);

},



async editConsultaRecetas(e){
//this.farmacoVigilanciaDetalleList
var OBJ= JSON.parse(JSON.stringify(e))
Promise.all(OBJ.farmacoVigilanciaDetalleList.map(async (element) => { 
              element.fechaReposicion=element.nuevaFechaReposicion
              if(!element.hasOwnProperty('estatus')){element.estatus=false}
              if(element.hasOwnProperty('estatus')&&element.estatus==null){element.estatus=false}
              
              //unidadesProductosPK:{producto:229,unidad:15}         
              var ObjProRow = this.RProductoCompra.filter((ee) => ee.unidadesProductosPK.producto == element.codpro && ee.unidadesProductosPK.unidad == element.coduni);
              if(ObjProRow.length>0){
                element.cantfac= element.cantfac+ObjProRow[0].cantidad
              }

          }));

OBJ.fecha=new Date(e.fecha).toISOString().substr(0, 10)
OBJ.fechavencimiento=new Date(e.fechavencimiento).toISOString().substr(0, 10)
this.farmacoVigilancia=JSON.parse(JSON.stringify(OBJ)) 
this.buscarFarmVigilancia=JSON.parse(JSON.stringify(e.codigo))

/*this.buscarFarmVigilancia=JSON.parse(JSON.stringify(e.codigo))
this.farmacoVigilancia.fecha=new Date(e.fecha).toISOString().substr(0, 10)
this.farmacoVigilancia.fechavencimiento=new Date(e.fechavencimiento).toISOString().substr(0, 10)
this.farmacoVigilancia.hora=JSON.parse(JSON.stringify(e.hora))
this.farmacoVigilancia.medico=JSON.parse(JSON.stringify(e.medico))
this.farmacoVigilancia.monto=e.monto
this.farmacoVigilancia.padecimiento=JSON.parse(JSON.stringify(e.padecimiento))
this.farmacoVigilancia.farmacoVigilanciaDetalleList= JSON.parse(JSON.stringify(e.farmacoVigilanciaDetalleList))*/
// .substr(0,7)
/*fecha: "2023-04-04T00:00:00-04:00"
fechavencimiento: "2023-04-29T00:00:00-04:00"
hora: (...)
medico: ""
monto: 100
nss: (...)
padecimiento: ""
telefono: (...)*/

this.dialogConsultaRecetaCreada=false
},

LimpiarFarmacoVigilancia(){

  this.buscarFarmVigilancia=0
  this.farmacoVigilancia.medico=''
  this.farmacoVigilancia.codigo=0
  this.farmacoVigilancia.hora=null
  this.farmacoVigilancia.fechavencimiento=null
  this.farmacoVigilancia.padecimiento=''
  this.farmacoVigilancia.farmacoVigilanciaDetalleList=[]

  this.dataFarmacoVigilancia()

},
nuevoFarmacoVigilancia(){
  this.buscarFarmVigilancia=0
this.farmacoVigilancia={
codigo:0,
autorizacion:'',
direccion:'',
fecha:fechaNueva(),
fechavencimiento:null,
hora:null,
medico:'',
padecimiento:'',
monto:0,
nss:'',
telefono:'',
borrado:false,
ars:null,
cliente:null,
farmacoVigilanciaDetalleList:[],
facturasventa:null

}

this.consultaRecetaFarmVig={
desde:null,
hasta:null
}

},


 // Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
 BlurDesdeFarmVig() {

      if (this.consultaRecetaFarmVig.desde!=null && this.consultaRecetaFarmVig.desde.toString().length == 0) {
        this.consultaRecetaFarmVig.desde = null;
      }

      if(this.consultaRecetaFarmVig.desde==null){
        this.consultaRecetaFarmVig.desde=this.fechaNueva()
        var _this = this;
        setTimeout(function () {
          _this.consultaRecetaFarmVig.desde=null
        }, 500);
      }

    },
 // Establecer la fecha a la fecha de hoy si el usuario no ha ingresado una fecha.
    BlurHastaFarmVig() {
      if (this.consultaRecetaFarmVig.hasta!=null && this.consultaRecetaFarmVig.hasta.toString().length == 0) {
        this.consultaRecetaFarmVig.hasta = null;
      }

      if(this.consultaRecetaFarmVig.hasta==null){
        this.consultaRecetaFarmVig.hasta=this.fechaNueva()
        var _this = this;
        setTimeout(function () {
          _this.consultaRecetaFarmVig.hasta=null
        }, 500);
      }

    },

getConsultaRecetaCreadaList(LIST){

  /*if (this.datosImprecionAll.desde.toString().length == 0) {
        this.datosImprecionAll.desde = this.fechaNueva();
      }*/
      
  var BOOLREQ=this.consultaRecetaFarmVig.desde!=null && this.consultaRecetaFarmVig.desde.toString().length>9 && this.consultaRecetaFarmVig.hasta!=null && this.consultaRecetaFarmVig.hasta.toString().length>9
  if(BOOLREQ==true){
    return  LIST.filter((ee) => ee.nuevaFecha >=this.consultaRecetaFarmVig.desde && ee.nuevaFecha <=this.consultaRecetaFarmVig.hasta);
  }else{
   return LIST;
  }

  /*if(this.dialogConsultaRecetaCreada==true){
  }*/
  
},

SeachTextFilVendedorCodigo(e){
  if(e.target.value.trim().length>0){
this.$axios
        .get(this.$hostname + this.$hName + "/vendedores/findallactiv", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
        if(res.data.length>0){
          // this.caja.sucursal.codigo
          var ObjProRowSUC = res.data.filter((ee) => ee.sucursal==null || ee.sucursal!=null&& ee.sucursal.codigo==this.caja.sucursal.codigo);
          var ObjProRow = ObjProRowSUC.filter((ee) => ee.codigo2!=null &&  ee.codigo2.trim() == e.target.value.trim());
        
          if (ObjProRow.length > 0) { 
              this.Facturasventa.vendedor=ObjProRow[0]
              this.SalvarVendedores()
              this.blurTextFilVendedorCodigo()
            }else{
              this.alerta("No existe vendedor con ese código", "error");
              this.blurTextFilVendedorCodigo()
            }
        }else{
          this.alerta("No existe vendedor con ese código", "error");
          this.blurTextFilVendedorCodigo()
        }
        
        });

  }else{
    this.blurTextFilVendedorCodigo()
  }
         

},

blurTextFilVendedorCodigo(){
this.searchVendedorCodigo=""
  this.activoF7=false
    var _this = this;
        setTimeout(function () {
          _this.$refs.RefTextFieldProducto.focus();
        }, 500);

},


 // Busqueda de codigo_barra_preferencial del cliente .
SeachTextFilBarraPreferencial(e){
  if(e.trim().length>0){
this.$axios
        .get(this.$hostname + this.$hName + "/clientes/getclientebarrapreferencial/"+e, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.hasOwnProperty("codigo")){
            this.clienteFactura=res.data
            this.clienteFactura.codnom= `${res.data.codigo} - ${res.data.nombre.trim()} `
            this.alerta("Cliente Seleccionado", "bien2");

            var _this = this;
          setTimeout(function () {
            _this.CompletaDatoClienteSelectFactura()
            _this.searchProducto=""
            _this.$refs.RefTextFieldProducto.focus();
  
               }, 300);



          }else{
            this.alerta("No existe cliente preferencial con ese código", "error");
          }
          
        })
        .catch(error => { this.alerta("dato no encontrado", "error") });


  }else{

  }
         

},

 // Comprobando si el valor de la entrada es nulo o indefinido.
 SelctCbClienteBuscarCredito() {
      var _this = this;
      setTimeout(function () {
        if (_this.clienteTempCredito== null) {
          _this.clienteTempCredito= "";
        }

        if (!_this.clienteTempCredito.hasOwnProperty('codigo')) {
          _this.clienteTempCredito= "";
          _this.clienteTempCredito= null;
          //  _this.alerta("Seleccione un cliente", "error");
          return;
        }
      }, 300);
    },

//cancela la seleccion de cliente a credito con cobertura 
cancelarSeleccionClienteCredito(){
this.dialogSelectClienteTempCredito=false
this.clienteTempCredito=null
},

SalvarSeleccionClienteCredito(){
  

  if (this.$refs.formSeleccionClienteCreditoCobertura.validate()) {
this.dialogSelectClienteTempCredito = false

      if (this.clienteTempCredito.tipocomprobante != null) {
        this.Facturasventa.tipocomprobante = this.clienteTempCredito.tipocomprobante
        this.ComprobanteFisc.comprobante=this.clienteTempCredito.tipocomprobante
      } else {
        this.Facturasventa.tipocomprobante = this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
        this.ComprobanteFisc.comprobante=this.$store.getters.GetConfiguracionGeneral.configPv.comprobantefacturacion
      }

      if (this.clienteTempCredito.telefonosClientesList.length > 0) {
        this.Facturasventa.telefononcf = this.clienteTempCredito.telefonosClientesList[0].telefonosClientesPK.telefono
        this.ComprobanteFisc.telefono =this.clienteTempCredito.telefonosClientesList[0].telefonosClientesPK.telefono
      }

      if (this.clienteTempCredito.cedrnc != null && this.clienteTempCredito.cedrnc.length > 0) {
        this.Facturasventa.cedularncncf = this.clienteTempCredito.cedrnc.trim()
        this.ComprobanteFisc.cedrnc=this.clienteTempCredito.cedrnc.trim()
      }

      this.Facturasventa.nombreDgii = this.clienteTempCredito.nombre
      this.ComprobanteFisc.nombre=this.clienteTempCredito.nombre
      this.Facturasventa.direccionncf = this.clienteTempCredito.direccion
      this.ComprobanteFisc.direccion = this.clienteTempCredito.direccion
      //this.SalvarComprobante()
      this.CalcularTotalesProductosCompra();
var _this = this;
setTimeout(function () {
   _this.ContrealizarCobro();
}, 900);

} else {
this.alerta("los campos en rojo son requeridos", "error");
}

},


MakaraCelAuto(e) {

  var MakCelRnc='#############'
if(e==null || e.cedrnc==null){
  return MakCelRnc;
}


      if (e.cedrnc.toString().length == 9) {
       MakCelRnc = "###-#####-#";
      }
      if (e.cedrnc.toString().length >= 11) {
        MakCelRnc = "###-#######-#";
      }

   this.MakCelRnc= MakCelRnc
return MakCelRnc;
    },

    finalizarReimprimirKeyEsc(e){
     if (e.keyCode ==27 ) {
      this.ListReimprecion = []
      this.NewReimprecionFacturaSeleccionada()
      this.BtnReimprecionvalue = 0
      this.dialogReimprecion = false
        }
  
  
      },


      
 // Una función que quita  el descuento a  cliente a credito .
 async QuitarDescuentoClienteCredito() {
  var QUITAR_DESC=this.$store.getters.GetConfiguracionGeneral.configPv.quitarDescuentoClienteCredito
     this.dialogNotifQuitarDescuentoCliente=false
  if(QUITAR_DESC!=null && QUITAR_DESC==true){
    this.Descuentofactura.descuento=0
        this.Descuentofactura.ValorPorc=false
        await this.SalvarDescuentoFactura()
    //this.SalvarDescuentoFactura()

   /* var _this = this;
      setTimeout(function () {
        _this.SalvarDescuentoFactura()
      }, 600);*/
      
        }

         
          
    },

getTasaMonedaSelect(valor){
 var BVALOR=0
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa )
  BVALOR=((MONEDA.tasa/MONEDAFACTURA)*valor)
  return BVALOR;
},

getTasaMonedaInfo(valor,moneda){
 var BVALOR=0
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (moneda!=null?moneda.tasa:MONEDA.tasa )
  BVALOR=((MONEDA.tasa/MONEDAFACTURA)*valor)
  return BVALOR;
},

getTasaInfo(valor,tasaSelect){
 var BVALOR=0
  var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
  var MONEDAFACTURA= (tasaSelect!=null?tasaSelect:MONEDA.tasa )
  BVALOR=((MONEDA.tasa/MONEDAFACTURA)*valor)
  return BVALOR;
},


getDecimalPlaces(){
     var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
      var MONEDAFACTURA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa )
      var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)
      return CANTREDONDEO;
},

ActualizarCotizacionFActurada(COTZ){

this.$axios
        .get(this.$hostname + this.$hName + "/cotizacion/ActualizarCotizacionFActurada/"+COTZ.secuencia, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => (this.RCaja = res.data));

},


filterCotizacionAfactura(List){
      //RtipoComprobante
      return List.filter((ee) => ee.facturada==false );
      //return List.filter((ee) => ee.pv==true && ee.itbis==true );
    },

    //modalidad ruta/vendedor
    verificarVendedor(){
  if(this.$store.getters.GetConfiguracionGeneral.configPv.modalidadRutaVendedor==true && this.caja!=null){
    if( this.caja.hasOwnProperty('vendedor') &&  this.caja.vendedor!=null && this.caja.vendedor.hasOwnProperty('codigo')){
      this.Facturasventa.vendedor= this.caja.vendedor
      this.objTemp = JSON.parse(JSON.stringify(this.caja.vendedor));
      var _this = this;
          setTimeout(function () { 
              _this.cancelarVendedores()  
          }, 300)

      }
     /* else{
     this.Facturasventa.vendedor=null
     this.objTemp=null
    this.OpenVendedores()
     this.alerta("El modo ruta/vendedor esta activo, debe asignar un vendedor.","error")
    }*/
     
   }

    },


    openPendientesEntrega(){
  this.dialogMedicamentosPendientes=true
    },

    openConsultaBalancePunto(){
  this.dialogConsultaBalancePunto=true
  

  var _this = this;
        setTimeout(function () {
        //  _this.$refs.RefComponentsPvModalConsultaBalancePunto.dialogClienteBalancePunto=true
         _this.$refs.RefComponentsPvModalConsultaBalancePunto.focusRefTextFieldCodigoBarra()
        }, 500);

      
    },


 openConsultaConsumoCliente(){
  this.dialogConsultaConsumoCliente=true
  

  var _this = this;
        setTimeout(function () {
          _this.$refs.RefComponentsPvModalConsultaConsumoCliente.dialogConsultaConsumoCliente=true
         _this.$refs.RefComponentsPvModalConsultaConsumoCliente.focusRefTextFieldCodigoBarra()
        }, 500);

      
    },
 openCrearModificarCliente(){
  this.dialogCrearModificarCliente=true
  

  var _this = this;
        setTimeout(function () {
          _this.$refs.RefComponentsPvModalCrearModificarCliente.dialogCrearModificarCliente=true
          _this.$refs.RefComponentsPvModalCrearModificarCliente.dataClienteSeleccionado(_this.clienteFactura)
         _this.$refs.RefComponentsPvModalCrearModificarCliente.focusRefTextFieldBuscar()

        }, 500);

      
    },

    

    finalizarPanelInformaciónProductoEsc(e){
     if (e.keyCode ==27 ) {this.cancelarInfProducto()}
      },



    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.limpiarPuntoVentaCompleto();
        this.NewCrearProducto();
        this.NewclienteSeleccionado();
        this.newnewAceptarDevolucion();
        this.newnewValecaja();
        this.NewHistorialClientRecIng()
      }
      if (l == "bien2") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
        this.aalert.right = true
        this.aalert.top = false
        this.aalert.timeout = 3000
        this.ejecucion = true
        this.loading(false)

      }
      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);
        this.aalert.right = false
        this.aalert.top = true
        this.aalert.timeout = 120000
        this.ejecucion = true;
        this.loading(false)
      }
    },


    RedireccionFocus(e) {
      var _this = this;
      setTimeout(function () { _this.$refs[e].focus() }, 300)
    },

    loading(e) {
      EventBus.$emit("loading", e);
    },

    getImgUrl() {
      var images = require("@/assets/img/Nofoto.png");
      return images;
    },
    // Redondear el número a 2 decimales.
    RedondeoBasico,
    // RedondeoBasico(x) {
    //   return Math.round((x + Number.EPSILON) * 100) / 100;
    // },
    // Redondear el valor de x al número de lugares decimales especificado por n.
    RedondeoValor,
    // RedondeoValor(x) {
    //   var n = JSON.parse(JSON.stringify(this.CantRedondeo));
    //   return parseFloat(Math.round(x * Math.pow(10, n)) / Math.pow(10, n));
    // },
    // Tomando un valor y dividiéndolo por una tasa.
    currencyFormatter2(value) {

       /* return (
        "" +
        //parseFloat(this.RedondeoValor(value / this.TasaMoneda)).toLocaleString("en",{ minimumFractionDigits: this.CantRedondeo })
      );*/

      var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
      var MONEDAFACTURA= (this.Monedafactura!=null?this.Monedafactura.tasa:MONEDA.tasa )
      var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)

      return (
        "" +
        parseFloat(this.RedondeoValorCantRed(value / this.TasaMoneda,CANTREDONDEO)).toLocaleString("en",{ minimumFractionDigits: CANTREDONDEO })
      );

    
    },

    currencyFormatter2MonedaInf(value,moneda) {

/* return (
 "" +
 //parseFloat(this.RedondeoValor(value / this.TasaMoneda)).toLocaleString("en",{ minimumFractionDigits: this.CantRedondeo })
);*/

var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
var MONEDAFACTURA= (moneda!=null?moneda.tasa:MONEDA.tasa )
var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)

return (
 "" +
 parseFloat(this.RedondeoValorCantRed(value / this.TasaMoneda,CANTREDONDEO)).toLocaleString("en",{ minimumFractionDigits: CANTREDONDEO })
);


},

currencyFormatter2TasaInf(value,tasaSelec) {
var MONEDA=JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase))
var MONEDAFACTURA= (tasaSelec!=null?tasaSelec:MONEDA.tasa )
var CANTREDONDEO=(MONEDAFACTURA!=MONEDA.tasa?4:2)

return (
 "" +
 parseFloat(this.RedondeoValorCantRed(value / this.TasaMoneda,CANTREDONDEO)).toLocaleString("en",{ minimumFractionDigits: CANTREDONDEO })
);


},




    // Convertir una fecha del formato aaaa-mm-dd a dd-mm-aaaa.
    FormatoFecha(params0) {
      var params = null;
      if (params0 == null) {
        params = this.fechaNueva();
      } else {
        params = new Date(params0).toISOString().substr(0, 10);
      }
      if (params != null) {
        if (params.length > 0) {
          return (
            params.substring(8, 10) +
            "-" +
            params.substring(5, 7) +
            "-" +
            params.substring(0, 4)
          );
        }
      }
    },
  },
  computed: {
    GetEjecucion() {
      if (this.clienteFactura == null) {
        this.newnewCoberturaSeguro();
        this.cancelarFacturaDomicilio();
      }
      if (this.CambioDivisa.operacion) {
        this.tituloCompra = "Cantidad a vender";
      } else {
        this.tituloCompra = "Cantidad a Comprar";
      }
      //-------Modale reimprecion--------


      if (this.BtnReimprecionvalue == 0) {
        this.LabelReimprecion = "SELECCIONAR UN TIPO DE IMPRESIÓN";
      }

      if (this.BtnReimprecionvalue == 1) {
        this.LabelReimprecion = "REIMPRIMIR RECIBO";
      }
      if (this.BtnReimprecionvalue == 2) {
        this.LabelReimprecion = "REIMPRIMIR OTROS INGRESOS";
      }
      if (this.BtnReimprecionvalue == 3) {
        this.LabelReimprecion = "REIMPRIMIR FACTURA";
      }
      if (this.BtnReimprecionvalue == 4) {
        this.LabelReimprecion = "REIMPRIMIR DEVOLUCIÓN";
      }
      if (this.BtnReimprecionvalue == 5) {
        this.LabelReimprecion = "REIMPRIMIR VALE DE CAJA";
      }
      if (this.BtnReimprecionvalue == 6) {
        this.LabelReimprecion = "REIMPRIMIR COTIZACIÓN";
      }
      if (this.BtnReimprecionvalue == 7) {
        this.LabelReimprecion = "REIMPRIMIR CONDUCE";
      }
      
      if (this.searchProducto==null || this.searchProducto!=null && this.searchProducto.length == 0) {
        this.ProdCompOrSelecProd = true;
      } else {
        this.ProdCompOrSelecProd = false;
      }
      this.windowSizeCaja = JSON.parse(JSON.stringify(this.windowSize));
      this.windowSizeCaja.height = this.windowSizeCaja.height - 273;
      if (this.windowSize.width >= 382 && this.windowSize.width <= 606) {
        this.windowSizeCaja.height = this.windowSizeCaja.height - 70;
      }

      // this.windowSizeCaja.height= this.windowSizeCaja.height-315
      // if(this.windowSize.width<957){this.windowSizeCaja.height= this.windowSize.height-338}
      // if(this.windowSize.width<605){this.windowSizeCaja.height= this.windowSize.height-415}
      // if(this.windowSize.width<425){this.windowSizeCaja.height= this.windowSize.height-440}
      // if(this.windowSize.width<375){this.windowSizeCaja.height= this.windowSize.height-370}

      if (this.OtrosIngreso.tipoRecibo == null) {
        this.OtrosIngreso.tipoRecibo = this.RtipoOtroIngreso[0];
      }





    },
  },
};
</script>

<style lang="scss">

.pmargin1 {
  margin-bottom: 3px;
}


@media screen and (min-width: 1058px) {
  #bottomteclado {
    min-width: 160px;
  }
}

@media screen and (max-width: 1058px) {
  #bottomteclado {
    min-width: 100px;
  }
}

@media screen and (max-width: 910px) {
  #miniTotal {
    font-size: 28px;
  }
}

@media screen and (max-width: 805px) {
  #miniTotal {
    font-size: 23px;
  }
}

@media screen and (max-width: 680px) {
  #miniTotal {
    font-size: 20px;
  }
}

@media screen and (min-width: 360px) and (max-width: 600px) {
  #miniTotal {
    font-size: 35px;
  }
}

@media screen and (max-width: 360px) {
  #miniTotal {
    font-size: 20px;
  }
}
</style>


 