<template>
  <!-- <div style="border: 2px solid #000000;"  v-bind:style="{ 'width':'100%','height':this.windowSize.height-100+'px' }" >
<center>
     <img src="../assets/LogPrincipal.jpg" height="90%" width="90%" style="backgroud-color:white; border: 2px solid #000000;"/> 
</center>
</div> -->
  <div v-resize="onResize"
    v-bind:style="{ 'background-color': '#FFFFFF', 'position': 'absolute', 'border': '2px solid #F44336', 'width': '100%', 'height': this.windowSize.height - 5 + 'px' }">
    <!-- sin Dashboard -->
    <div
      v-show="$store.getters.GetdatosInfUsu.usuario.dashboard == null || $store.getters.GetdatosInfUsu.usuario.dashboard == false">
      <center>
        <v-col cols="12" md="12" sm="12" style="border: 2px solid #000000;">

          <div style="float:left; position:fixed; display:none; ">
            <v-avatar v-if="this.windowSize.width > 450" v-ripple="{ class: `white--text` }" color="grey darken-2"
              class="ma-2" size="400">
              <img
                v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo != null"
                id="img-mini" alt="Usuario" style=" border: 2px solid #000000;  border-radius: 10px;"
                :src="$store.getters.GetConfiguracionGeneral.config.logo" />
            </v-avatar>

            <v-avatar v-if="this.windowSize.width <= 450" v-ripple="{ class: `white--text` }" color="grey darken-2"
              class="ma-2" size="80">
              <img
                v-if="$store.getters.GetConfiguracionGeneral != null && $store.getters.GetConfiguracionGeneral.config.logo != null"
                id="img-mini" alt="Usuario" style=" border: 2px solid #000000;  border-radius: 10px;"
                :src="$store.getters.GetConfiguracionGeneral.config.logo" />
            </v-avatar>

          </div>

          <div
            v-bind:style="{ 'height': this.windowSize.height - 30 + 'px', 'max-height': this.windowSize.height - 30 + 'px', 'border': '1px solid #000000', 'background-color': '#FFFFFF' }">
            <img src="../assets/LogPrincipal.jpg" height="100%" width="100%"
              style="backgroud-color:white; border: 1px solid #000000;" />
          </div>
        </v-col>
      </center>
    </div>
    <!-- Con Dashboard -->
    <div v-show="$store.getters.GetdatosInfUsu.usuario.dashboard == true">
      <center>
        <v-col cols="12" md="12" sm="12" style="border: 2px solid #000000;">
          <div class="overflow-y-auto"
            v-bind:style="{ 'height': this.windowSize.height - 30 + 'px', 'max-height': this.windowSize.height - 30 + 'px', 'border': '1px solid #000000', 'background-color': '#e2e2e2' }">
            <b>ESTOS VALORES ESTÁN SUJETOS AL MES ACTUAL</b>
            <v-row class="pa-0 ma-0 py-0 my-0">
              <!-- panel Año -->
              <v-col class="py-0 my-0 PanelColor" cols="12" md="3" sm="3" style="border: 3px solid #e2e2e2;">
                <v-row>
                  <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                    <v-col class="py-0 my-0 ma-0 pa-0" cols="12" md="12" sm="12"> <b>AÑO FISCAL</b> </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center " cols="12" md="12" sm="12"> <b
                        class="size-13">MES</b> </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center" cols="12" md="12" sm="12"> <b
                        class="size-13">AÑO</b> </v-col>
                  </v-col>

                  <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="6" sm="6">
                    <v-col class="py-0 my-0 pa-0 ma-0" cols="12" md="12" sm="12"> <b>{{ Datos.FechaFiscal }}</b>
                    </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center  " cols="12" md="12" sm="12"> <b
                        class="fa fa-arrow-circle-right"></b> </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center" cols="12" md="12" sm="12"> <i
                        class=" fa fa-arrow-circle-right"></i> </v-col>
                  </v-col>
                </v-row>
              </v-col>



              <!-- Facturacion -->
              <v-col class="py-0 my-0 PanelColor" cols="12" md="2" sm="2" style="border: 3px solid #e2e2e2;">
                <v-row>
                  <v-col class="py-0 my-0" cols="12" md="12" sm="12"> <b>FACTURACIÓN</b> </v-col>
                  <v-col class="pa-0 ma-0 py-0 my-0 " cols="6" md="3" sm="3" style="background-color:#1b76ff;">
                    <i style="font-size:35px;" class=" fa fa-usd"></i>
                  </v-col>

                  <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="9" sm="9" style="ba">
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center " cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.facturacionPorMes) }}</b> </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center" cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.facturasPorAno) }}</b> </v-col>
                  </v-col>
                </v-row>


              </v-col>

              <!-- costo venta -->
              <v-col class="py-0 my-0 PanelColor" cols="12" md="2" sm="2" style="border: 3px solid #e2e2e2;">

                <v-row>
                  <v-col class="py-0 my-0" cols="12" md="12" sm="12"> <b>COSTO VENTA</b> </v-col>
                  <v-col class="pa-0 ma-0 py-0 my-0 " cols="6" md="3" sm="3" style="background-color:#73a12d;">
                    <i style="font-size:35px;" class=" fa fa-calendar-check-o"></i>
                  </v-col>

                  <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="9" sm="9" style="ba">
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center " cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.costoPorMes) }}</b> </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center" cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.costoVentaPorAno) }}</b> </v-col>
                  </v-col>
                </v-row>

              </v-col>

              <!-- gastos -->
              <v-col class="py-0 my-0 PanelColor" cols="12" md="2" sm="2" style="border: 3px solid #e2e2e2;">
                <v-row>
                  <v-col class="py-0 my-0" cols="12" md="12" sm="12"> <b>GASTOS</b> </v-col>
                  <v-col class="pa-0 ma-0 py-0 my-0 " cols="6" md="3" sm="3" style="background-color:#dd4b39;">
                    <i style="font-size:30px;" class="fa fa-credit-card-alt"></i>
                  </v-col>

                  <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="9" sm="9" style="ba">
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center " cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.gastoPorMes) }}</b> </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center" cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.gastoVentaPorAno) }}</b> </v-col>
                  </v-col>
                </v-row>

              </v-col>

              <!-- beneficios -->
              <v-col class="py-0 my-0 PanelColor" cols="12" md="3" sm="3" style="border: 3px solid #e2e2e2;">
                <v-row>
                  <v-col class="py-0 my-0" cols="12" md="12" sm="12"> <b>BENEFICIOS</b> </v-col>
                  <v-col class="pa-0 ma-0 py-0 my-0 " cols="6" md="3" sm="3" style="background-color:#ffbe00;">
                    <i style="font-size:35px;" class="fa fa-money"></i>
                  </v-col>

                  <v-col class="pa-0 ma-0 py-0 my-0" cols="6" md="9" sm="9" style="ba">
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center " cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.beneficiosPorMes) }}</b> </v-col>
                    <v-col class="py-0 my-0 pa-0 ma-0 d-flex justify-center" cols="12" md="12" sm="12"> <b
                        class="size-13">{{ moneda }} {{ currencyFormatter(Datos.beneficiosPorAno) }}</b> </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Dashboard-->
            <v-col class="py-0 my-0 PanelColor" cols="12" md="12" sm="12" style="border: 3px solid #e2e2e2;">
              <b>GANANCIAS Y PÉRDIDAS</b>

              <bar-example ref="BarEx" />


            </v-col>


            <v-col class="py-0 my-0" cols="12" md="12" sm="12">
              <v-row>
                <v-col class="py-0 my-0" cols="6" md="6" sm="6">
                  <b> NOTA : DEVOLUCIÓN {{ moneda }} {{ currencyFormatter(Datos.valorDevolucionMes) }}</b>
                </v-col>
                <v-col class="py-0 my-0" cols="6" md="6" sm="6">
                  <b> DESCUENTO {{ moneda }} {{ currencyFormatter(Datos.valorDescuentoMes) }}</b>
                </v-col>
              </v-row>


              <b>AVISO : EL VALOR DE FACTURACIÓN SOLO INCLUYE LAS VENTAS A PARTIR DE LA FECHA QUE SE INICIA A FACTURAR
                CON ESTE SISTEMA</b>
            </v-col>
          </div>
        </v-col>
      </center>
    </div>


<!------------otras funciones---------------->
<v-snackbar v-model="aalert.estado" :color="aalert.color"
        style="margin-top:20%;max-height:400px;display:block;overflow:auto;" :vertical="true" :timeout="120000"
        top="top">
        {{ aalert.nombre }}
        <v-btn dark text @click="aalert.estado = false">Close</v-btn>
      </v-snackbar>

<!------------ Modales---------------->
<v-dialog transition="fab-transition" label="Modal INFORMACIÓN NCF" v-model="dialogComprobantes" persistent
      max-width="90%" max-height="80%">

      <v-card>

<v-toolbar flat color="indigo" dense>

  <i style="font-size: 20px;" class=""> </i>
  <v-toolbar-title style="color:white;" class="font-weight-light"> INFORMACIÓN NCF </v-toolbar-title>
  <i style="font-size: 20px;" class=""> </i>

  <v-spacer></v-spacer>
  <v-btn color="grey darken-4" fab small @click="CloseDialogComprobantes">
    <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
  </v-btn>
</v-toolbar>

<v-card-text>
              <v-col class="pa-0 ma-0 py-0 my-0 pt-2" cols="12" md="12" sm="12" :class="`d-flex justify-center`">
                <span>
                  <h1>
                  <b>Nota:</b><span style="color: #C62828;"> Los comprobantes siguientes se están agotando:</span></h1>
                </span>
              </v-col>
  <v-data-table  dense @click:row="" :footer-props="arrayPropiedases" :headers="[
   {text: 'COMPROBANTE', value: 'comprobante' ,sortable: false, class: ['no-gutters']},
   {text: 'RESTANTE', value: 'cantidad' ,sortable: false, class: ['no-gutters']},   
  ]
  " :items="ListCompAgotado" item-key="indx"  class="elevation-1 TbSize13">

    <!-- PRODUCTO, COSTO, PRECIO, EXISTENCIA -->

    <template v-slot:item.comprobante="props">
      <span style="display:none;">{{ props.item.indx = ListCompAgotado.indexOf(props.item) }}</span>
      <span style="font-size:13px;">{{props.item[0] }}</span>
    </template>
    <template v-slot:item.cantidad="props">
      <span style="font-size:13px;"> {{props.item[1] }} </span>
    </template>
  </v-data-table>
</v-card-text>
</v-card>

    </v-dialog>
<!-- informacion pago -->
<v-dialog transition="fab-transition" label="Modal INFORMACIÓN NCF" v-model="dialogMostrarDiaPago" persistent
      max-width="90%" max-height="90%">

      <v-card>

<v-toolbar flat color="indigo" dense>

  <i style="font-size: 20px;" class=""> </i>
  <v-toolbar-title style="color:white;" class="font-weight-light"> INFORMACIÓN DE PAGO </v-toolbar-title>
  <i style="font-size: 20px;" class=""> </i>

  <v-spacer></v-spacer>
  <v-btn color="grey darken-4" fab small @click="CloseDialogComprobarDiaDepago">
    <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
  </v-btn>
</v-toolbar>

<v-card-text>
  <v-col class="pa-9 ma-6" cols="12" md="12" sm="12">
  <v-col class="pa-0 ma-0 py-0 my-0 pt-2" cols="12" md="12" sm="12" :class="`d-flex justify-center`">
    <h1 :class="`justify-center`"><b>Nota:</b></h1>
  </v-col>
              <v-col class="pa-0 ma-0 py-0 my-0 pt-2" cols="12" md="12" sm="12" :class="`d-flex justify-center`" >
                <center style="color: #C62828;">
                  <h1><b> Favor de realizar el pago mensual del sistema, si ya realizo el pago, obviar el mensaje presionando la (X) </b></h1>
                </center>
                <!-- <span style="color: #C62828; font-size: 30px;"></span> -->
              </v-col>
    </v-col>

</v-card-text>
</v-card>

</v-dialog>

<!-- Informacion de Farmaco Vigilancia -->

<v-dialog transition="fab-transition" label="Modal Informacion de Farmaco Vigilancia" v-model="dialogFarmacoVigilancia" persistent
      max-width="90%" max-height="80%">

      <v-card>

<v-toolbar flat color="indigo" dense>

  <i style="font-size: 20px;" class=""> </i>
  <v-toolbar-title style="color:white;" class="font-weight-light"> INFORMACIÓN DE FÁRMACO VIGILANCIA </v-toolbar-title>
  <i style="font-size: 20px;" class=""> </i>

  <v-spacer></v-spacer>
  <v-btn color="grey darken-4" fab small @click="CloseDialogFarmacoVigilancia">
    <i style="font-size: 20px; color:white;" class="fa fa-close"></i>
  </v-btn>
</v-toolbar>

<v-card-text>
              <v-col class="pa-0 ma-0 py-0 my-0 pt-2" cols="12" md="12" sm="12" :class="`d-flex justify-center`">
                <span>
                  <h3><b>Nota:</b><span style="color: #C62828;"> alerta con 5 días de anticipación:</span></h3>
                </span>
              </v-col>
  <v-data-table  dense @click:row="" :footer-props="arrayPropiedases" :headers="[
   {text: 'CLIENTE', value: 'cliente' ,sortable: false, class: ['no-gutters black--text']},
   {text: 'TELÉFONO', value: 'telefono' ,sortable: false, class: ['no-gutters black--text']},
   {text: 'FECHA', value: 'fecha' ,sortable: false, class: ['no-gutters black--text']},   
  ]
  " :items="ListFarmaVigilancia" item-key="indx"  class="elevation-1 TbSize13">

    <!-- PRODUCTO, COSTO, PRECIO, EXISTENCIA -->

    <template v-slot:item.cliente="props">
      <span style="display:none;">{{ props.item.indx = ListFarmaVigilancia.indexOf(props.item) }}</span>
      <span style="font-size:13px;">{{props.item[2] }}</span>
    </template>
    <template v-slot:item.telefono="props">
      <span style="font-size:13px;"> {{props.item[3] }} </span>
    </template>

    <template v-slot:item.fecha="props">
      <span style="font-size:13px;"> {{ FormatoFecha(props.item[4]) }} </span>
    </template>


  </v-data-table>
</v-card-text>
</v-card>

    </v-dialog>

<!-- fin Informacion de Farmaco Vigilancia -->

<!------------Modales---------------->



  </div>

</template>

<script>
import { EventBus } from "../event-bus.js";
import { mapGetters, mapMutations } from 'vuex'
import { currencyFormatter, FormatoFecha, HoraFormatter, RedondeoValor,fechaNueva, getParamFecha } from "@/js/Funciones.js";
import BarExample from '@/components/bar/BarExample'
export default {

  components: {
    BarExample,
  },

  mounted() {

    this.onResize()

    var json = {
      titulo: "DecaSystems",
      descripcion: "Caribbean's Systems Developers",
      save: false,
      eliminar: false,
      anular: false,
      nuevo: false,
      imprimir: false,
      vercuenta: false,
      accion: [
        "principalNew",
        "principalSave",
        "principalRemove",
        "principalprincipal",
        "principalPrintList",
        "principalVercuenta"

      ]
    };
    EventBus.$emit("anularBotonesAccion", json);
    EventBus.$on("onResize", this.onResize);


    setTimeout(this.ReactInfo, 300);

    // console.log('Informacion11111')
    // console.log(this.$store.getters.GetdatosInfUsu)


    // console.log('DatosUsuario1')
    // console.log(this.GetDataUsuario)
    var obj = {
      labels: [],
      datasets: []
    }

    this.$refs.BarEx.chartData = obj
    this.$refs.BarEx.UpdateRenderChart()
    this.VerificarPeriodosFiscales()

       this.getFarmacoVigilancia()

        var _this = this;
        setTimeout(function () {
        _this.ComprobantesFiscales()
        }, 300);

        setTimeout(function () {
          _this.ComprobarDiaDepago()
        }, 800);

   /* var Xmas95 = new Date();
var weekday = Xmas95.getDay();
console.log('el real dia')
console.log(weekday)*/
/*var fecha = new Date().getDate()
const hoy = fecha.getDate();*/

  },


  created() {

  },

  beforeDestroy() {
    // removing eventBus listener
    EventBus.$off('AbrirMenuOfDasboar')
  },

  data() {
    return {

      arrayPropiedases: {
        'items-per-page-options': [10, 15, 20, 50],
        'items-per-page-text': 'Cantidad de Registros Por Paginas',
        showFirstLastPage: false
      },
      ListCompAgotado:[],
      ListFarmaVigilancia:[],
      ListItems:[],
      dialogComprobantes:false,
      dialogMostrarDiaPago:false,
      dialogFarmacoVigilancia:false,
      aalert: {
      estado: false,
      color: null,
      nombre: 'Hola mundo'
    },
      windowSize: { width: "auto", height: "auto" },
      moneda: "",
      Datos: {

        FechaFiscal: " - ",
        facturacionPorMes: 0,
        costoPorMes: 0,
        gastoPorMes: 0,
        beneficiosPorMes: 0,

        facturasPorAno: 0,
        costoVentaPorAno: 0,
        gastoVentaPorAno: 0,
        beneficiosPorAno: 0,

        valorDescuentoMes: 0,
        valorDescuentoAno: 0,
        valorDevolucionMes: 0,
        valorDevolucionAno: 0

      }

    };
  },
  methods: {
    currencyFormatter, FormatoFecha, HoraFormatter, RedondeoValor,fechaNueva, getParamFecha,
    VerificarPeriodosFiscales(){

     // 
//this.BuscarInfDashboard()

      var fecha = new Date().toISOString().substr(0, 10)
       var year= fecha.substr(0,4)
      this.$axios.get(
        this.$hostname + this.$hName + "/periodofiscal/existe/periodo/"+year,
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {


          if(res.data==false){
            this.alerta("No tiene periodo fiscal para esta fecha", "error")
          }else{
            if(this.$store.getters.GetdatosInfUsu.usuario.dashboard != null && this.$store.getters.GetdatosInfUsu.usuario.dashboard == true){
              this.BuscarInfDashboard()
            }
          }

        });

      //$store.getters.GetdatosInfUsu.usuario.dashboard == null || $store.getters.GetdatosInfUsu.usuario.dashboard == false

    },

    BuscarInfDashboard() {


      var schema = this.$store.getters.GetheadersAxios["X-TENANT-ID"]
     /* console.log('Informacion Schema')
      console.log(this.$hostname + this.$hName)
      console.log(this.$store.getters.GetheadersAxios)*/

      // var labels= ['','Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre']
      // console.log(labels[1])


      //var bancDetCuent = ` select * from (select fecha, secuencia, db.descripcion, beneficiario,valor  deposito,0  credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.cheques ch on ch.cuenta_banco = cb.codigo inner join ${schema}.documentobancos db on db.codigo = ch.documento and db.operacion!=34 where anulado = false union select fecha, secuencia, db.descripcion, '' beneficiario,0 deposito, valor credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.notadebitobancos ndb on ndb.cuenta = cb.codigo inner join ${schema}.documentobancos db on db.codigo = ndb.documento union select fecha, secuencia, db.descripcion, '' beneficiario,valor deposito, 0 credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.notacreditobanco ncb on ncb.cuenta = cb.codigo inner join ${schema}.documentobancos db on db.codigo = ncb.documento union select fecha, secuencia, db.descripcion, '' beneficiario,0 deposito,valor credito,cb.balance, concepto, cb.codigo cuenta from ${schema}.cuentasbancos cb inner join ${schema}.depositosbanco dpb on dpb.cuenta = cb.codigo inner join ${schema}.documentobancos db on db.codigo = dpb.documento and db.operacion!=33) cb where fecha >= '  ${f1} ' and fecha <= '  ${f2}' and cuenta = ${this.MovimientosBanco.codigo} `

      //.replace(/[&\/\\#+$~%":?<>{}]/g, '')
      /*  var Obj = {
          fecha: new Date().toISOString().substr(0, 10),
         - facturacionPorMes: ` select ${schema}.valor_meses(current_date  , 2)  + ${schema}.valor_meses(current_date  , 8) + ${schema}.valor_meses(current_date  , 7)- ${schema}.valor_meses(current_date  , 6) facturas `,
          -costoPorMes: ` select ${schema}.valor_meses( current_date  , 3)  costo`,
         - gastoPorMes: ` select ${schema}.valor_meses( current_date  , 1) gasto`,
          -beneficiosPorMes: ` select ( ${schema}.valor_meses( current_date, 2) + ${schema}.valor_meses(current_date   , 8) + ${schema}.valor_meses(current_date  , 7) - ${schema}.valor_meses(current_date  , 6) - ${schema}.valor_meses(current_date  , 3) - ${schema}.valor_meses(current_date  , 1)) `,
  
          -facturasPorAno: `select ${schema}.valor_ano(2) + ${schema}.valor_ano(8) - ${schema}.valor_ano(7)+${schema}.valor_ano(6) - ${schema}.valor_ano(4)`,
          -costoVentaPorAno: ` select ${schema}.valor_ano(3) -  ${schema}.valor_ano(5) `,
          -gastoVentaPorAno: `select ${schema}.valor_ano(1)`,
          -beneficiosPorAno: `select ( ${schema}.valor_ano(2) + ${schema}.valor_ano(8) - ${schema}.valor_ano(7) + ${schema}.valor_ano(6) - ${schema}.valor_ano(4) - ${schema}.valor_ano(3) - ${schema}.valor_ano(1)) beneficio `,
  
          -valorDescuentoMes: `select ${schema}.valor_meses(current_date  , 7)  facturas `,
          valorDescuentoAno: `select ${schema}.valor_ano(7)`,
  
          -valorDevolucionMes: `select ${schema}.valor_meses(current_date  , 4)  facturas `,
          valorDevolucionAno: `select ${schema}.valor_ano(4)`,
  
          facturacionMesGrafica: `select * from  ${schema}.dashboardgraficofactura() `,
  
          costoVentaMesGrafica: ` select * from ${schema}.dashboardgraficocostoVenta() `,
  
          gastoMesGrafica: ` select * from ${schema}.dashboardgraficogasto()`,
          BeneficioMesGrafica: `select * from ${schema}.dashboardgraficobeneficios()`
  
        }*/

      /*    this.$axios
            .post(this.$hostname + this.$hName + '/ConsultaRapida/Dashboards', Obj, { headers: this.$store.getters.GetheadersAxios })
            .then(res => {
              this.Datos = res.data
              this.moneda = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim()))
              this.UpdateGrafico(res.data)
              //  EventBus.$emit("AbrirMenuOfDasboar");
            })
            .catch(error => {
              //this.alerta("Reporte no Impreso("+error+")", "error")
              console.log('Execion catch==>/ConsultaRapida/Dashboards')
            });*/

      /* this.Datos.FechaFiscal = "",
         this.Datos.facturacionPorMes = 0
       this.Datos.facturasPorAno = 0
       this.Datos.costoPorMes = 0
       this.Datos.costoVentaPorAno = 0
       this.Datos.gastoPorMes = 0
       this.Datos.gastoVentaPorAno = 0
       this.Datos.beneficiosPorMes = 0
       this.Datos.beneficiosPorAno = 0
       this.Datos.valorDevolucionMes = 0
       this.Datos.valorDescuentoMes = 0*/
      var fecha = new Date().toISOString().substr(0, 10)
      this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfPrincFFiscal/" + fecha,
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {

          console.log('Informacion Fiscal')
          //console.log(res.data)
          this.Datos.FechaFiscal = res.data.FechaFiscal

        });



      this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfPrincFact ",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          // console.log('Informacion FRactura')
          //console.log(res.data)
          this.Datos.facturacionPorMes = res.data.facturacionPorMes
          this.Datos.facturasPorAno = res.data.facturasPorAno
          /* this.Datos.FechaFiscal = res.data.FechaFiscal
           this.Datos.costoPorMes = res.data.costoPorMes
           this.Datos.costoVentaPorAno = res.data.costoVentaPorAno
           this.Datos.gastoPorMes = res.data.gastoPorMes
           this.Datos.gastoVentaPorAno = res.data.gastoVentaPorAno
           this.Datos.beneficiosPorMes = res.data.beneficiosPorMes
           this.Datos.beneficiosPorAno = res.data.beneficiosPorAno*/
          // console.log(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim())
          this.moneda = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config.monedabase.nombrecorto.trim()))
        });

      this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfPrincCosto",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          // console.log('Informacion costo')
          // console.log(res.data)

          this.Datos.costoPorMes = res.data.costoPorMes
          this.Datos.costoVentaPorAno = res.data.costoVentaPorAno
        });

      this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfPrincGasto",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          // console.log('Informacion Gasto')
          //console.log(res.data)

          this.Datos.gastoPorMes = res.data.gastoPorMes
          this.Datos.gastoVentaPorAno = res.data.gastoVentaPorAno
        });

      this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfPrincBenedicio",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          // console.log('Informacion beneficio')
          //console.log(res.data)

          this.Datos.beneficiosPorMes = res.data.beneficiosPorMes
          this.Datos.beneficiosPorAno = res.data.beneficiosPorAno
        });

      this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfFooter",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          // console.log('Informacion InfFooter')
          // console.log(res.data)

          this.Datos.valorDevolucionMes = res.data.valorDevolucionMes
          this.Datos.valorDescuentoMes =res.data.valorDescuentoMes 
        });

        //FACTURACIÓN
      this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfGraficoFact",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          //console.log('Informacion InfGraficoFact')
          //console.log(res.data)
          this.UpdateGraficoFact(res.data)
        //COSTO VENTA
          this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfGraficoCosto",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          // console.log('Informacion InfGraficoCost')
          // console.log(res.data)
          this.UpdateGraficoCosto(res.data)
          //GASTOS
          this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfGraficoGasto",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          //console.log('Informacion InfGraficogasto')
          // console.log(res.data)
          this.UpdateGraficoGasto(res.data)
          //BENEFICIOS
          this.$axios.get(
        this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/InfGraficoBeneficio",
        { headers: this.$store.getters.GetheadersAxios }
      )
        .then((res) => {
          // console.log('Informacion InfGraficobenef')
          // console.log(res.data)
          this.UpdateGraficoBeneficio(res.data)
        });

        });

        });



        });


        

    

      /*  this.$axios.get(
          this.$hostname + this.$hName + "/ConsultaRapida/Dashboards/FactPorAno",
          { headers: this.$store.getters.GetheadersAxios }
        )
          .then((res) => {
            console.log('--HOlaaa 1--')
            
          });*/




    },

    UpdateGraficoFact(e) {
      var lbls = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      var ValorGraficaFc = []

      var labels1 = []
      e.MesGraficaFc.forEach(elet => {
        labels1.push(lbls[elet])
      });

      this.$refs.BarEx.chartData.labels = labels1

      e.ValorGraficaFc.forEach(elet => {
        ValorGraficaFc.push(this.RedondeoValor(elet))
      });

      var objFac = {
        label: 'FACTURACIÓN',
        backgroundColor: '#1b76ff',
        data: JSON.parse(JSON.stringify(ValorGraficaFc))
      }

      this.$refs.BarEx.chartData.datasets.push(objFac)
      this.$refs.BarEx.UpdateRenderChart()

    },

    UpdateGraficoCosto(e) {
      var lbls = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      var ValorGraficaCos = []

      e.ValorGraficaCos.forEach(elet => {
        ValorGraficaCos.push(this.RedondeoValor(elet))
      });

      var objCos = {
        label: 'COSTO VENTA',
        backgroundColor: '#73a12d',
        data: JSON.parse(JSON.stringify(ValorGraficaCos))
      }

      this.$refs.BarEx.chartData.datasets.push(objCos)
      this.$refs.BarEx.UpdateRenderChart()

    },
    
    UpdateGraficoGasto(e) {
      // style="background-color:#dd4b39;#d40d12"
      var lbls = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      var ValorGraficaGas = []

      e.ValorGraficaGas.forEach(elet => {
        ValorGraficaGas.push(this.RedondeoValor(elet))
      });

      var objGas = {
        label: 'GASTOS',
        backgroundColor: '#dd4b39',
        data: JSON.parse(JSON.stringify(ValorGraficaGas))
      }

      this.$refs.BarEx.chartData.datasets.push(objGas)
      this.$refs.BarEx.UpdateRenderChart()

    },


    UpdateGraficoBeneficio(e) {
      var lbls = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      var ValorGraficaBen = []
      e.ValorGraficaBen.forEach(elet => {
        ValorGraficaBen.push(this.RedondeoValor(elet))
      });

      var objBen = {
        label: 'BENEFICIOS',
        backgroundColor: '#ffbe00',
        data: JSON.parse(JSON.stringify(ValorGraficaBen))
      }

      this.$refs.BarEx.chartData.datasets.push(objBen)
      this.$refs.BarEx.UpdateRenderChart()

    },


    UpdateGrafico(e) {

      var lbls = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
      // console.log(labels[1])

      var labels1 = []
      var ValorGraficaFc = []
      var ValorGraficaCos = []
      var ValorGraficaGas = []
      var ValorGraficaBen = []

      e.MesGraficaFc.forEach(elet => {
        labels1.push(lbls[elet])
      });


      e.ValorGraficaFc.forEach(elet => {
        ValorGraficaFc.push(this.RedondeoValor(elet))
      });


      e.ValorGraficaCos.forEach(elet => {
        ValorGraficaCos.push(this.RedondeoValor(elet))
      });


      e.ValorGraficaGas.forEach(elet => {
        ValorGraficaGas.push(this.RedondeoValor(elet))
      });

      e.ValorGraficaBen.forEach(elet => {
        ValorGraficaBen.push(this.RedondeoValor(elet))
      });


      var obj = {
        labels: JSON.parse(JSON.stringify(labels1)),
        datasets: [
          {
            label: 'FACTURACIÓN',
            backgroundColor: '#1b76ff',
            data: JSON.parse(JSON.stringify(ValorGraficaFc))
          },

          {
            label: 'COSTO VENTA',
            backgroundColor: '#73a12d',
            data: JSON.parse(JSON.stringify(ValorGraficaCos))
          },

          {
            label: 'GASTOS',
            backgroundColor: '#d40d12',
            data: JSON.parse(JSON.stringify(ValorGraficaGas))
          },
          {
            label: 'BENEFICIOS',
            backgroundColor: '#ffbe00',
            data: JSON.parse(JSON.stringify(ValorGraficaBen))
          }

        ]

      }


      this.$refs.BarEx.chartData = obj
      this.$refs.BarEx.UpdateRenderChart()

    },
    ComprobarDiaDepago(){
      var mostrarDiaPago = JSON.parse(sessionStorage.getItem("mostrarDiaPago"))
       var CONFIG = JSON.parse(JSON.stringify(this.$store.getters.GetConfiguracionGeneral.config))   
        var BOOLQUITARMENSAJEPAGO=false
      if(CONFIG.hasOwnProperty('quitarmensajeinicio') && CONFIG.quitarmensajeinicio!=null){
        BOOLQUITARMENSAJEPAGO=CONFIG.quitarmensajeinicio
      }

if(mostrarDiaPago!=null && mostrarDiaPago==true && BOOLQUITARMENSAJEPAGO==false){
  this.dialogMostrarDiaPago=true

      }


    },
    CloseDialogComprobarDiaDepago(){
      this.dialogMostrarDiaPago=false
      sessionStorage.setItem("mostrarDiaPago", JSON.stringify(false));
      //var ComprobanteAgotado = JSON.parse(sessionStorage.getItem("ComprobanteAgotado"))
    },
    

    ComprobantesFiscales(){
      var CompAgot = JSON.parse(sessionStorage.getItem("ComprobanteAgotado"))
      
if(CompAgot!=null && CompAgot==true){
      this.$axios
        .get(this.$hostname + this.$hName + "/secuenciaComprobantes/ComprobanteBencimiento", {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {

          if(res.data.length >0){this.dialogComprobantes=true
          this.ListCompAgotado=res.data
          }
          
        });
      }
    },


    CloseDialogComprobantes(){
      this.dialogComprobantes=false
      sessionStorage.setItem("ComprobanteAgotado", JSON.stringify(false));
      //var ComprobanteAgotado = JSON.parse(sessionStorage.getItem("ComprobanteAgotado"))
    },


    getFarmacoVigilancia(){

      //fechaNueva, getParamFecha
      var fecha=new Date(this.fechaNueva());
      fecha = fecha.setDate(fecha.getDate() + 6);
      var fechdesde=this.fechaNueva()
      var fechhasta =new Date(fecha).toLocaleDateString("en-CA");
 
      this.$axios
        .get(this.$hostname + this.$hName + `/FarmacoVigilancia/getfarmacovigilancia/${fechdesde}/${fechhasta}`, {
          headers: this.$store.getters.GetheadersAxios,
        })
        .then((res) => {
          if(res.data.length >0){
         this.dialogFarmacoVigilancia=true
         this.ListFarmaVigilancia=res.data
          }
          
        });
     
    },

    
    CloseDialogFarmacoVigilancia(){
      this.dialogFarmacoVigilancia=false
    },


    alerta(e, l) {
      if (l == "bien") {
        (this.aalert.estado = true),
          (this.aalert.color = "success"),
          (this.aalert.nombre = e);
      }


      if (l == "error") {
        (this.aalert.estado = true),
          (this.aalert.color = "error"),
          (this.aalert.nombre = e);

      }
    },

    onResize() {
      //divSelecProd
      var jsn = { width: window.innerWidth, height: window.innerHeight - 112 }
      this.windowSize = jsn
      //this.windowSize=e

    },

    ReactInfo() {


      var json = {
        titulo: "DecaSystems",
        descripcion: "Caribbean's Systems Developers",
        save: false,
        eliminar: false,
        anular: false,
        nuevo: false,
        imprimir: false,
        vercuenta: false,
        accion: [
          "principalNew",
          "principalSave",
          "principalRemove",
          "principalprincipal",
          "principalPrintList",
          "principalVercuenta"

        ]
      };
      EventBus.$emit("anularBotonesAccion", json);

    }

  },

  computed: {
    ...mapGetters({

      'GetDataUsuario': 'GetdatosInfUsu'

    })


  }



}
</script>

<style lang="scss">
.PanelColor {
  background-color: #ffffff !important;
}

.size-13 {
  font-size: 13px !important;
}
</style>